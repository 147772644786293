import { Action, Reducer } from "redux";
import { produce } from "immer";
import { DispatchActions } from "../actions/commonActions";
import { actionTypes } from "../types/commonTypes";
import { IDelegateeUser, IUserLoginHistoryResponse } from "../core/viewModels/user/UserViewModel";

export interface ICommonState {
  forceUpdate: number;
  mailboxUserData: IDelegateeUser | null;
  helpCenterUrl: string;
  MailboxUserList: IDelegateeUser[];
  isLoading: boolean,
  loginHistory: IUserLoginHistoryResponse
  loginHistoryLoading: boolean
}

const unloadedState: ICommonState = {
  forceUpdate: 0,
  mailboxUserData: null,
  helpCenterUrl: "",
  MailboxUserList: [],
  isLoading: false,
  loginHistory: { count: 0, deviceId: "", loginHistory: [] },
  loginHistoryLoading: false
};

export const reducer: Reducer<ICommonState> = produce(
  (draftState: ICommonState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_FORCE_UPDATE:
        draftState.forceUpdate++;
        break;
      case actionTypes.RECEIVE_HELP_CENTER_URL:
        draftState.helpCenterUrl = action.payload;
        break;
      case actionTypes.REQUEST_OTHERS_INBOX_LIST:
        draftState.isLoading = true;
        return draftState;
      case actionTypes.RECEIVE_OTHERS_INBOX_LIST:
        draftState.isLoading = false;
        draftState.MailboxUserList = action.payload;
        return draftState;
      case actionTypes.COMPLETE_OTHERS_INBOX_LIST:
        draftState.isLoading = false;
        return draftState;
      case actionTypes.RECEIVE_OTHER_USER_DATA:
        draftState.mailboxUserData = action.payload;
        return draftState;
      case actionTypes.REQUEST_LOGIN_HISTORY:
        draftState.loginHistoryLoading = true;
        return draftState;
      case actionTypes.RECEIVE_LOGIN_HISTORY:
        draftState.loginHistory = action.payload;
        draftState.loginHistoryLoading = false;
        return draftState;
      case actionTypes.COMPLETE_LOGIN_HISTORY:
        draftState.loginHistoryLoading = false;
        return draftState;
    }
    return draftState;
  }
);
