import axios, { AxiosResponse } from "axios";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { actionTypes } from "../types/restrictedFileTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";


export interface RequestRestrictedFileType {
  type: actionTypes.REQUEST_RESTRICTED_FILE_TYPES;
}
export interface ResponseRestrictedFileType {
  type: actionTypes.RECEIVE_RESTRICTED_FILE_TYPES;
  data: IDropOffRestrictedFilesModel;
}
export interface CompleteRestrictedFileType {
  type: actionTypes.COMPLETE_RESTRICTED_FILE_TYPES;
}

export interface IRestrictedFileModel {
  color: string;
  fileExtension: string[];
}

export interface IDropOffRestrictedFilesModel {
  video: IRestrictedFileModel;
  images: IRestrictedFileModel;
  audio: IRestrictedFileModel;
  docs: IRestrictedFileModel
  others: IRestrictedFileModel;
}

export type DispatchActions = RequestRestrictedFileType | ResponseRestrictedFileType | CompleteRestrictedFileType;

export type KnownActions = DispatchActions | NotificationAction;

export const getRestrictedFileTypes =
  (callback?: any): AppThunkAction<KnownActions> => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/DropOff/GetRestrictedFileTypes`)
      .then((response: AxiosResponse) => {
        const data = response.data;
        dispatch({ type: actionTypes.RECEIVE_RESTRICTED_FILE_TYPES, data: data });
        if (callback) {
          callback(data);
        }
      }).catch((error: Error) => {
        dispatch({
          type: notifactionAction.NOTIFICATION,
          statusMessage: error.message,
          statusType: StatusType.Error,
        });
        dispatch({ type: actionTypes.COMPLETE_RESTRICTED_FILE_TYPES });
      });
  };

