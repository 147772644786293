import { MessageOptionsConstants } from "../../../../../helper/Constants";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useEffect, useState } from "react";
import { initialCompanyNotificationSettings } from "../../../../../core/viewModels/company/settings/NotificationsSettings";
import {
    fetchNotificationsSettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { INotificationsSettings } from "../../../../../reducers/adminSettingsReducer";
import { useDispatch, useSelector } from "react-redux";
import { CompanySettingsName } from "../../../../../core/common/enums";
import { ApplicationState } from "../../../../../store";
import "../../style.scss";
import React from "react";
const MessageNotification = () => {
    const [notificationsSettingsState, setNotificationsSettingsState] = useState(initialCompanyNotificationSettings);
    const { messageOptions, isLoading } = useSelector((appState: ApplicationState) => appState.adminSettingsState);
    const dispatch = useDispatch();

    const notificationsSettings = messageOptions.notificationsSettings;
    useEffect(() => {
        dispatch(fetchNotificationsSettings());
    }, []);

    useEffect(() => {
        if (notificationsSettings) {
            setNotificationsSettingsState({ ...notificationsSettingsState, ...notificationsSettings });
        }
    }, [notificationsSettings]);

    const handleNotificationToggleChange = (e: any, name: string) => {
        const value = e.target.checked;

        setNotificationsSettingsState(prevState => ({
            ...prevState,
            [name]: value,
            isUserAllowedToChangeExpiryNotification:
                name === MessageOptionsConstants.IS_EXPIRY_NOTIFICATION_ENABLE && !value
                    ? false
                    : name === MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_EXPIRY_NOTIFICATION
                    ? value
                    : prevState.isUserAllowedToChangeExpiryNotification,
            isUserAllowedToChangeDownloadNotification:
                name === MessageOptionsConstants.IS_DOWNLOAD_NOTIFICATION_ENABLE && !value
                    ? false
                    : name === MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_DOWNLOAD_NOTIFICATION
                    ? value
                    : prevState.isUserAllowedToChangeDownloadNotification,
        }));

        const updateAdminSettingsRequestPayload: INotificationsSettings = {
            ...notificationsSettingsState,
            [name]: value,
        };
        dispatch(updateAdminSettings(CompanySettingsName.NotificationsSettings, updateAdminSettingsRequestPayload));
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{MessageOptionsConstants.MESSAGE_ACCORDION_HELPER_TEXT}</span>
                </header>
                <section className="margin-top-10-px">
                    <div className="notification-option-container">
                        <section>
                            <h3 className="heading-black-md text-bold">{MessageOptionsConstants.EXPIRATION}</h3>
                            <span className="notification-helper-text">
                                {MessageOptionsConstants.EXPIRATION_HELPER_TEXT}
                            </span>
                        </section>
                        <div className="settings-label-toggle-wrapper toggle-wrapper">
                            <CustomToggleSwitch
                                label={MessageOptionsConstants.CHANGE_NOTIFY_EXPIRY_TEXT}
                                handleChange={handleNotificationToggleChange}
                                name={MessageOptionsConstants.IS_EXPIRY_NOTIFICATION_ENABLE}
                                switched={notificationsSettingsState.isExpiryNotificationEnable}
                            />
                        </div>
                        {notificationsSettingsState.isExpiryNotificationEnable && (
                            <div className="settings-label-toggle-wrapper sub-list-settings-item">
                                <Checkbox
                                    id={"checkbox-manage-expiry-notification"}
                                    value={MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_EXPIRY_NOTIFICATION}
                                    className="primary-checkbox"
                                    text={MessageOptionsConstants.ALLOW_EMPLOYEES_TO_NOTIFY_EXPIRY_TEXT}
                                    size={CheckBoxSize.sm}
                                    checked={notificationsSettingsState.isUserAllowedToChangeExpiryNotification}
                                    onChange={e =>
                                        handleNotificationToggleChange(
                                            e,
                                            MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_EXPIRY_NOTIFICATION
                                        )
                                    }
                                />
                            </div>
                        )}
                        {/* Download notification settings */}
                        <section className="">
                            <h3 className="heading-black-md text-bold">{MessageOptionsConstants.DOWNLOAD}</h3>
                            <span className="notification-helper-text">
                                {MessageOptionsConstants.DOWNLOAD_HELPER_TEXT}
                            </span>
                        </section>
                        <div className="settings-label-toggle-wrapper toggle-wrapper">
                            <CustomToggleSwitch
                                label={MessageOptionsConstants.CHANGE_NOTIFY_DOWNLOAD_TEXT}
                                handleChange={handleNotificationToggleChange}
                                name={MessageOptionsConstants.IS_DOWNLOAD_NOTIFICATION_ENABLE}
                                switched={notificationsSettingsState.isDownloadNotificationEnable}
                            />
                        </div>
                        {notificationsSettingsState.isDownloadNotificationEnable && (
                            <div className="settings-label-toggle-wrapper sub-list-settings-item">
                                <Checkbox
                                    id={"checkbox-manage-download-notification"}
                                    value={MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_DOWNLOAD_NOTIFICATION}
                                    className="primary-checkbox"
                                    text={MessageOptionsConstants.ALLOW_EMPLOYEES_TO_NOTIFY_DOWNLOAD_TEXT}
                                    size={CheckBoxSize.sm}
                                    checked={notificationsSettingsState.isUserAllowedToChangeDownloadNotification}
                                    onChange={e =>
                                        handleNotificationToggleChange(
                                            e,
                                            MessageOptionsConstants.IS_USER_ALLOWED_TO_CHANGE_DOWNLOAD_NOTIFICATION
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                </section>
            </>

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default MessageNotification;
