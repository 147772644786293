import { Action, Reducer } from "redux";
import { produce } from "immer";

import * as SecurityQuestionActions from "../actionCreators/securityQuestionCreators";
import { actionTypes } from "../types/securityQuestionTypes";
import { IMessageSecurityQuestion } from "../core/viewModels/message/MessageSecurityQuestionModel";

export interface ISecurityQuestionState {
  securityQuestions: {
    isLoading: boolean;
    data: IMessageSecurityQuestion[];
  };
  companySecurityQuestions: {
    isLoading: boolean,
    data: IMessageSecurityQuestion[];
    isProcessing: boolean;
  }
}

const initialState: ISecurityQuestionState = {
  securityQuestions: {
    isLoading: false,
    data: [],
  },
  companySecurityQuestions: {
    isLoading: false,
    data: [],
    isProcessing: false
  }
};

export const reducer: Reducer<ISecurityQuestionState> = produce(
  (draft: ISecurityQuestionState = initialState, incomingAction: Action) => {
    const action = incomingAction as SecurityQuestionActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_SECURITY_QUESTIONS:
        draft.securityQuestions.isLoading = true;
        return draft;

      case actionTypes.REQUEST_COMPANY_SECURITY_QUESTIONS:
        draft.companySecurityQuestions.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_SECURITY_QUESTIONS:
        draft.securityQuestions.isLoading = false;
        draft.securityQuestions.data = action.payload;
        return draft;

      case actionTypes.RECEIVED_COMPANY_SECURITY_QUESTIONS:
        draft.companySecurityQuestions.isLoading = false;
        draft.companySecurityQuestions.data = action.payload;
        return draft;

      case actionTypes.COMPLETED_SECURITY_QUESTIONS:
        draft.securityQuestions.isLoading = false;
        return draft;

      case actionTypes.REQUEST_ADD_SECURITY_QUESTION:
        draft.securityQuestions.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_ADD_SECURITY_QUESTION:
        draft.securityQuestions.isLoading = false;
        return draft;

      case actionTypes.REQUEST_UPDATE_SECURITY_QUESTION:
        draft.securityQuestions.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_UPDATE_SECURITY_QUESTION:
        draft.securityQuestions.isLoading = false;
        return draft;
      
      case actionTypes.REQUEST_ADD_COMPANY_SECURITY_QUESTION:
        draft.companySecurityQuestions.isProcessing = true;
        return draft;

      case actionTypes.COMPLETED_ADD_COMPANY_SECURITY_QUESTION:
        draft.companySecurityQuestions.isProcessing = false;
        return draft;

      case actionTypes.REQUEST_UPDATE_COMPANY_SECURITY_QUESTION:
        draft.companySecurityQuestions.isProcessing = true;
        return draft;

      case actionTypes.COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION:
        draft.companySecurityQuestions.isProcessing = false;
        return draft;

      case actionTypes.REQUEST_RECIPIENT_QUESTION_ANSWER:
        return draft;

      case actionTypes.COMPLETED_RECIPIENT_QUESTION_ANSWER:
        return draft;

      default:
        return draft;
    }
  }
);
