export interface ILockLinkModel {
  sentMessageRecipientId: number,
  sentMessageGuid: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  dateSent: string,
  subject: string
  name: string
}

export const initialLockLinkObject : ILockLinkModel = {
  sentMessageRecipientId: 0,
  sentMessageGuid: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  dateSent: "",
  subject: "",
  name: ""
};