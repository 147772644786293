import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Dropdown from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { DropOffLinkTypeLabels, MessageOptionsConstants } from "../../../helper/Constants";
import { AdminSettingsSideMenuIcon } from "../../svg/AdminSettingsIconsCollection";
import { ColorConstants } from "../../../assets/custom/colors";
import { ApplicationState } from "../../../store";
import {
    fetchAuthenticationMethodSettings,
    fetchDropOffSettings,
    requestAllRetentionPeriods,
} from "../../../actionCreators/adminSettingsActionCreators";
import { actionTypes } from "../../../types/userSettingsTypes";
import { getCompanyAuthMethods, getDropOffLinkTypes } from "../../../helper/HelperFunctions";
import "./styles.scss";
import { requestDropOffRecipientsList } from "../../../actionCreators/dropOffActionCreators";

interface IRententionPeriodState {
    // loginTransfer: OptionType;
    questionAndAnswer: OptionType;
    accessCode: OptionType;
}

type OptionType = { value: string; label: string } | undefined;

const initialOptionType = {
    value: "1",
    label: "1 Day",
};

const MessageOptions: React.FC = () => {
    const dispatch = useDispatch();
    const { messageOptions: messageOptionsSettings, dropOffSettings } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const dropOffRecipients = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffRecipientsData.dropOffRecipients
    );
    const isPersonaLinkDisabled = !dropOffRecipients.find(recipient => recipient.userId === user.userId)
        ?.isDropOffEnabled;
    const { userSettings, isLoading } = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const retentionOptions = Array.isArray(messageOptionsSettings.retentionPeriodList)
        ? messageOptionsSettings.retentionPeriodList?.map(rp => {
              return { value: rp.retentionId.toString(), label: rp.description };
          })
        : [];

    const authenticationMethodOptions = messageOptionsSettings.authenticationSettings?.authenticationMethods?.map(
        value => {
            const option = getCompanyAuthMethods().filter(method => method.value === value.toString());
            return option[0];
        }
    );

    const dropOffLinkTypes = getDropOffLinkTypes();
    const [selectedAuthenticationMethod, setSelectedAuthenticationMethod] = useState("");
    const [selectedDropOffLinkType, setSelectedDropOffLinkType] = useState("");
    const [retentionPeriodState, setRetentionPeriodState] = useState<IRententionPeriodState>({
        // loginTransfer: initialOptionType,
        questionAndAnswer: initialOptionType,
        accessCode: initialOptionType,
    });

    const [updateRetentionPeriod, setUpdateRetentionPeriod] = useState(0);
    const [updateAuthenticationSettings, setUpdateAuthenticationSettings] = useState(false);

    useEffect(() => {
        dispatch(requestAllRetentionPeriods(true));
        dispatch(fetchAuthenticationMethodSettings());
        dispatch(fetchDropOffSettings());
        dispatch(requestDropOffRecipientsList());
    }, []);

    useEffect(() => {
        if (
            retentionPeriodState &&
            // retentionPeriodState.loginTransfer?.value &&
            retentionPeriodState.questionAndAnswer?.value &&
            retentionPeriodState.accessCode?.value &&
            updateRetentionPeriod > 0
        ) {
            const payloadData = {
                // loginTransfer: parseInt(retentionPeriodState.loginTransfer["value"]),
                questionAndAnswer: parseInt(retentionPeriodState.questionAndAnswer["value"]),
                accessCode: parseInt(retentionPeriodState.accessCode["value"]),
            };
            dispatch({
                type: actionTypes.UPDATE_USER_SETTINGS,
                payload: {
                    ...userSettings,
                    retentionSettings: {
                        defaultRetentionPeriods: payloadData,
                    },
                },
            });
        }
    }, [updateRetentionPeriod]);

    useEffect(() => {
        if (userSettings?.retentionSettings?.defaultRetentionPeriods) {
            const retentionPeriodObject = userSettings.retentionSettings.defaultRetentionPeriods;
            let retentionClone = { ...retentionPeriodState };
            Object.entries(retentionPeriodObject).map(([key, value]) => {
                const option = getSelectedOption(value);
                retentionClone = { ...retentionClone, [key]: option };
            });
            setRetentionPeriodState({ ...retentionClone });
        }
    }, [userSettings?.retentionSettings?.defaultRetentionPeriods, messageOptionsSettings.retentionPeriodList?.length]);

    useEffect(() => {
        if (userSettings && userSettings.authenticationMethodSettings) {
            const methodOptions = messageOptionsSettings.authenticationSettings.authenticationMethods;
            const userDefaultMethod = userSettings.authenticationMethodSettings.defaultAuthenticationMethod;
            const adminDefaultMethod = messageOptionsSettings.authenticationSettings.defaultAuthenticationMethod;
            const allowUserToChange = messageOptionsSettings.authenticationSettings.allowEmployeesToChangeDefaultMethod;
            if (methodOptions?.includes(userDefaultMethod) && allowUserToChange) {
                setSelectedAuthenticationMethod(userDefaultMethod.toString());
            } else {
                setSelectedAuthenticationMethod(adminDefaultMethod?.toString());
            }
        }
    }, [
        userSettings?.authenticationMethodSettings?.defaultAuthenticationMethod,
        messageOptionsSettings.authenticationSettings,
    ]);

    useEffect(() => {
        if (selectedAuthenticationMethod) {
            dispatch({
                type: actionTypes.UPDATE_USER_SETTINGS,
                payload: {
                    ...userSettings,
                    authenticationMethodSettings: {
                        defaultAuthenticationMethod: parseInt(selectedAuthenticationMethod),
                    },
                },
            });
        }
    }, [updateAuthenticationSettings]);

    useEffect(() => {
        userSettings &&
            userSettings?.dropOffSettings &&
            setSelectedDropOffLinkType(userSettings?.dropOffSettings?.dropOffLinkType.toString());
    }, [userSettings?.dropOffSettings?.dropOffLinkType]);

    const getSelectedOption = (value?: number) => {
        if (value) {
            const val = value.toString();
            return retentionOptions.find(rp => rp.value === val);
        }
        return "";
    };

    const handleRetentionPeriodDropDownClick = (option: any, selectedDropDown: string) => {
        setRetentionPeriodState({
            ...retentionPeriodState,
            [selectedDropDown]: option,
        });
        setUpdateRetentionPeriod(updateRetentionPeriod + 1);
    };

    const handleAuthMethodDropDownClick = (option: any) => {
        setSelectedAuthenticationMethod(option.value);
        setUpdateAuthenticationSettings(!updateAuthenticationSettings);
    };

    const handleDropOffDropDownClick = (option: any) => {
        if (option.value === DropOffLinkTypeLabels.PERSONAL && isPersonaLinkDisabled) {
            return;
        } else {
            setSelectedDropOffLinkType(option.value);
            dispatch({
                type: actionTypes.UPDATE_USER_SETTINGS,
                payload: {
                    ...userSettings,
                    dropOffSettings: {
                        ...userSettings?.dropOffSettings,
                        dropOffLinkType: parseInt(option.value),
                    },
                },
            });
        }
    };

    return (
        <LoadingOverlay>
            <div className="message-options-parent-container">
                <header>
                    <h3>{MessageOptionsConstants.MESSAGE_OPTIONS_TITLE_TEXT}</h3>
                    <span>{MessageOptionsConstants.MESSAGE_OPTIONS_HELPER_TEXT}</span>
                </header>
                <div className="settings-accordian-container">
                    <Accordion flush className="settings-accordian">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <AdminSettingsSideMenuIcon
                                    icon={"MessageOptionsAuthenticationIcon"}
                                    color={ColorConstants.light_blue}
                                    width={24}
                                    height={24}
                                />
                                <div className="accordion-title-info-container">
                                    <h4>{MessageOptionsConstants.AUTHENTICATION_METHOD}</h4>
                                    <span>{MessageOptionsConstants.AUTHENTICATION_METHOD_HELPER_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="settings-label-dropdown-wrapper user-settings-dropdown-wrapper">
                                    <h5>Default Method</h5>

                                    <Dropdown
                                        className="secondary-dropdown"
                                        onChange={option => {
                                            handleAuthMethodDropDownClick(option);
                                        }}
                                        placeholder="Select"
                                        disabled={
                                            !messageOptionsSettings.authenticationSettings
                                                .allowEmployeesToChangeDefaultMethod
                                        }
                                        options={authenticationMethodOptions}
                                        value={selectedAuthenticationMethod}
                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="second-accordian-item" eventKey="1">
                            <Accordion.Header>
                                <AdminSettingsSideMenuIcon
                                    icon={"MessageOptionsRetentionIcon"}
                                    color={ColorConstants.light_blue}
                                    width={24}
                                    height={24}
                                />

                                <div className="accordion-title-info-container">
                                    <h4>{MessageOptionsConstants.RETENTION_PERIOD}</h4>
                                    <span>{MessageOptionsConstants.RETENTION_PERIOD_HELPER_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="settings-label-dropdown-wrapper user-settings-dropdown-wrapper">
                                    <h5>{MessageOptionsConstants.RETENTION_PERIOD_QNA}</h5>

                                    <Dropdown
                                        className="setting-dropdown"
                                        onChange={option => {
                                            handleRetentionPeriodDropDownClick(option, "questionAndAnswer");
                                        }}
                                        placeholder="Select Authentication Method"
                                        options={retentionOptions}
                                        value={retentionPeriodState.questionAndAnswer}
                                    />
                                </div>

                                <div className="settings-label-dropdown-wrapper user-settings-dropdown-wrapper">
                                    <h5>{MessageOptionsConstants.RETENTION_PERIOD_ACCESS_CODE}</h5>

                                    <Dropdown
                                        className="setting-dropdown"
                                        onChange={option => {
                                            handleRetentionPeriodDropDownClick(option, "accessCode");
                                        }}
                                        placeholder="Select Authentication Method"
                                        options={retentionOptions}
                                        value={retentionPeriodState.accessCode}
                                    />
                                </div>

                                {/* <div className="settings-label-dropdown-wrapper user-settings-dropdown-wrapper">
                                    <h5>{MessageOptionsConstants.RETENTION_PERIOD_LOGIN}</h5>

                                    <Dropdown
                                        className="setting-dropdown drop-up"
                                        onChange={option => {
                                            handleRetentionPeriodDropDownClick(option, "loginTransfer");
                                        }}
                                        placeholder="Select Authentication Method"
                                        options={retentionOptions}
                                        value={retentionPeriodState.loginTransfer}
                                    />
                                </div> */}
                            </Accordion.Body>
                        </Accordion.Item>
                        <div className={!dropOffSettings?.isPersonalDropOffLinkToDisplay ? "isDisabled" : ""}>
                            <Accordion.Item className="second-accordian-item first-accordian" eventKey="2">
                                <Accordion.Header
                                    style={{
                                        pointerEvents: `${
                                            !dropOffSettings?.isPersonalDropOffLinkToDisplay ? "none" : "auto"
                                        }`,
                                    }}
                                >
                                    <AdminSettingsSideMenuIcon
                                        icon={"DropOffConfig"}
                                        color={ColorConstants.light_blue}
                                        width={24}
                                        height={24}
                                    />

                                    <div className="accordion-title-info-container">
                                        <h4>{MessageOptionsConstants.DROP_OFF_LINKS}</h4>
                                        <span>{MessageOptionsConstants.DROP_OFF_LINKS_HELPER_TEXT}</span>
                                    </div>
                                </Accordion.Header>
                                {dropOffSettings?.isPersonalDropOffLinkToDisplay && (
                                    <Accordion.Body>
                                        <div className="settings-label-dropdown-wrapper user-settings-dropdown-wrapper">
                                            <h5>{MessageOptionsConstants.SELECT_DROP_OFF_LINK}</h5>

                                            <Dropdown
                                                className={`secondary-dropdown ${
                                                    isPersonaLinkDisabled ? "dropdown-values-disabled-container" : ""
                                                }`}
                                                onChange={option => {
                                                    handleDropOffDropDownClick(option);
                                                }}
                                                placeholder="Select"
                                                disabled={!dropOffSettings.isUsersAllowedToManageDropOffLink}
                                                options={dropOffLinkTypes}
                                                value={selectedDropOffLinkType}
                                            />
                                        </div>
                                    </Accordion.Body>
                                )}
                            </Accordion.Item>
                        </div>
                    </Accordion>
                </div>
            </div>
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default MessageOptions;
