import produce from "immer";
import { Reducer, Action } from "redux";
import { DispatchActions } from "../actionCreators/rbacActionCreator";
import { actionTypes as rbacActions} from "../types/rbacTypes";

export interface IRbacState {
  isLoading: boolean;
  resourceList: string[]
}

export const initialState: IRbacState = {
  isLoading: false,
  resourceList: []
};

export const reducer: Reducer<IRbacState> = produce(
  (rbacState: IRbacState = initialState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case rbacActions.REQUEST_RBAC_RESOURCE_LIST:
        rbacState.isLoading = true;
        return rbacState;
      case rbacActions.RECEIVED_RBAC_RESOURCE_LIST:
        rbacState.resourceList = action.payload;
        return rbacState;
      case rbacActions.COMPLETED_RBAC_RESOURCE_LIST:
        rbacState.isLoading = false;
        return rbacState;
      default:
        return rbacState;
    }
  }
);
