import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IDropOffRecipient } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffDropDownConstants, DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { ApplicationState } from "../../../../../../../store";
import SearchInput from "../../../../../../common/ui/SearchInput";
import "../Sender/style.scss";

interface IRecipientsSearchProps {
    setRecipientsTableData: (recipient: IDropOffRecipient[]) => void;
    reload:boolean
}

export const RecipientsSearch: React.FC<IRecipientsSearchProps> = ({ setRecipientsTableData , reload}) => {
    const [selectedDropDownOption, setSelectedDropDownOption] = useState(
        DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS
    );
    const [searchText, setSearchText] = useState<string>("");

    const dropOffRecipients = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffRecipientsData.dropOffRecipients
    );

    useEffect(() => {
        onDropDownFilter(selectedDropDownOption);
    }, [searchText]);
    
    useEffect(() => {
        setSearchText("");
        setSelectedDropDownOption(DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS);
    }, [reload]);
    
    const onSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchText(value);
    };

    const getSearchData = (recipient: IDropOffRecipient): string => `${recipient?.emailAddress} ${recipient?.name || ""}`;

    const onDropDownFilter = (value: string) => {
        setSelectedDropDownOption(value);

        switch (value) {
            case DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS:
                const filteredList = dropOffRecipients.filter(data =>
                   getSearchData(data).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                );
             
                setRecipientsTableData(filteredList);
                break;

            case DropOffDropDownConstants.RECIPIENT_DROPDOWN_DISABLED_USERS:
                {
                    const filteredDropDownList = dropOffRecipients.filter(data => !data.isDropOffEnabled);
          
                    const filteredList = filteredDropDownList.filter(data =>
                       getSearchData(data).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                    );
             
                    setRecipientsTableData(filteredList);
                }
                break;
            case DropOffDropDownConstants.RECIPIENT_DROPDOWN_ENABLED_USERS:
                {
                    const filteredDropDownList = dropOffRecipients.filter(data => data.isDropOffEnabled);
               
                    const filteredList = filteredDropDownList.filter(data =>
                       getSearchData(data).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                    );
                    setRecipientsTableData(filteredList);
                }

                break;
            default:
                break;
        }
    };

    return (
        <div className="sender-search-wrapper">
            <SearchInput
                placeholder={DropOffUsersConstants.RECIPIENT_SEARCH_PLACEHOLDER}
                onChange={onSearchFilter}
                value={searchText}
            />
            <DropdownButton

                className="search-dropdown-button"
                title={selectedDropDownOption}
                id="input-group-dropdown-2"
                align="start"
            >
                <Dropdown.Item onClick={() => onDropDownFilter(DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS)}>
                    {DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDropDownFilter(DropOffDropDownConstants.RECIPIENT_DROPDOWN_ENABLED_USERS)}
                >
                    {DropOffDropDownConstants.RECIPIENT_DROPDOWN_ENABLED_USERS}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDropDownFilter(DropOffDropDownConstants.RECIPIENT_DROPDOWN_DISABLED_USERS)}
                >
                    {DropOffDropDownConstants.RECIPIENT_DROPDOWN_DISABLED_USERS}
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};
