import React, { FC, useEffect, useState, CSSProperties } from "react";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, IndicatorProps } from "react-select";
import { debounce } from "throttle-debounce";
import { fetchComposeRecipients } from "../../../../actionCreators/composeActionCreators";
import { RecipientType } from "../../../../core/common/enums";
import { IRecipientModel } from "../../../../core/viewModels/message/RecipientModel";
import { createOption } from "../../../../helper/HelperFunctions";

interface IMultipleRecipient {
    setValue:(data: any, type: RecipientType) => void;
    inValid: boolean;
    recipients: any;
}

interface IOption {
    label: string;
    value: string;
}

const MultipleRecipient: FC<IMultipleRecipient> = ({ setValue, inValid,recipients }) => {
    const dispatch = useDispatch();
    const [recipientOptions, setRecipientOptions] = useState<any[]>([]);
    const [toRecipients, setToRecipients] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
   
    useEffect(() => {
        updateRecipientOptions();
    }, [recipients.length]);

    const updateRecipientOptions = () => {
        const toRecipientsData = recipients?.map((item: IRecipientModel) => {
            const label =
                item.firstName || item.lastName
                    ? `${item.firstName ?? ""} ${item.lastName ?? ""} <${item.emailAddress}>`
                    : item.emailAddress;
            return createOption(`${item.emailAddress}`, label);
        });
  
        setToRecipients(toRecipientsData);
    };

    const handleInputChange = (inputValue: string) => {
        if (inputValue.length < 3) return;
        else {
            setLoading(true);
            dispatch(
                fetchComposeRecipients(inputValue, (data: IRecipientModel[]) => {
                    const options = data.map((item: IRecipientModel) =>
                        createOption(
                            `${item.emailAddress}`,
                            item.firstName || item.lastName
                                ? `${item.firstName ?? ""} ${item.lastName ?? ""} <${item.emailAddress}>`
                                : item.emailAddress
                        )
                    );
                    setRecipientOptions(options);
                    setLoading(false);
                })
            );
        }
    };

    const handleToChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        setValue(actionMeta, RecipientType.ToRecipient);
    };

    const ClearIndicator = (props: IndicatorProps<IOption, true>) => {
        const {
            children = <div className="clear-icon"><i className="fas fa-times"></i></div>,
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles("clearIndicator", props) as CSSProperties}
            >
                <div style={{ padding: "0px 5px" }}>{children}</div>
            </div>
        );
    };
    const defaultCreatableStyle = {
        control: (base: any) => ({
            ...base,
            minHeight: "29px",
            lineHeight: "normal",
            fontSize: "14px",
            borderRadius: "3px",
            borderColor: !inValid ? "#ced4da" : "#dc3545"
        }),
        menu: (base: any) => ({
            ...base,
            fontSize: "12px",
        }),
    };
    return (
        <CreatableSelect
            className="multiple-recipient-select"
            isMulti
            isClearable={false}
            cacheOptions
            placeholder=""
            blurInputOnSelect
            hideSelectedOptions={false}
            allowCreateWhileLoading={true}
            options={recipientOptions}
            onChange={handleToChange}
            onInputChange={debounce(1000, handleInputChange)}
            formatCreateLabel={(value: any) => value}
            theme={(theme) => {
                return { ...theme, borderRadius: 0 };
            }}
            components={{
                DropdownIndicator: null,
                ClearIndicator
            }}
            styles={defaultCreatableStyle}
            value={toRecipients}
            isLoading={isLoading}
        />
    );
};

export default MultipleRecipient;


