import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { MessageOptionsConstants, RequestNotificationConstants } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { fetchNotificationsSettings, fetchRequestNotificationsSettings } from "../../../actionCreators/adminSettingsActionCreators";
import { actionTypes } from "../../../types/userSettingsTypes";
import "./styles.scss";
import CustomToggleSwitch from "../../common/ui/switch/CustomToggleSwitch";
import { INotificationsSettings, IDocumentRequestNotificationSettings } from "../../../core/viewModels/user/UserViewModel";
import { initialState } from "../../../reducers/userSettingsReducer";

const MessageNotification = () => {
    const dispatch = useDispatch();
    const { messageOptions: messageOptionsSettings,
        requestNotificationSettings
     } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );
    const [notificationsSettingsState, setNotificationsSettingsState] = useState<INotificationsSettings>(
        initialState.notificationsSettings
    );
    const [requestNotificationsSettingsState, setRequestNotificationsSettingsState] = useState<IDocumentRequestNotificationSettings>(
        initialState.documentRequestNotificationsSettings
    );
    const { userSettings, isLoading } = useSelector((appState: ApplicationState) => appState.userSettingsState);

    useEffect(() => {
        dispatch(fetchNotificationsSettings());
        dispatch(fetchRequestNotificationsSettings());
    }, []);

    useEffect(() => {
        if (userSettings) {
            setNotificationsSettingsState({
                isExpiryNotificationEnable: userSettings.notificationsSettings.isExpiryNotificationEnable ?? false,
                isDownloadNotificationEnable: userSettings.notificationsSettings.isDownloadNotificationEnable ?? false,
            });
            setRequestNotificationsSettingsState({
                isDocumentRequestCompletionNotificationEnabled: userSettings.documentRequestNotificationsSettings?.isDocumentRequestCompletionNotificationEnabled ?? false,
                isDocumentRequestReopenNotificationEnabled: userSettings.documentRequestNotificationsSettings?.isDocumentRequestReopenNotificationEnabled ?? false,
            });
        }
    }, [userSettings]);

    const handleNotificationToggleChange = (e: any, name: string) => {
        const value = e.target.checked;
        const notificationsSettingsPayload = { ...notificationsSettingsState, [name]: value };
        setNotificationsSettingsState(prevState => ({
            ...prevState,
            [name]: value,
        }));
        dispatch({
            type: actionTypes.UPDATE_USER_SETTINGS,
            payload: { ...userSettings, notificationsSettings: notificationsSettingsPayload },
        });
    };

    const handleRequestNotificationToggleChange = (e: any, name: string) => {
        const value = e.target.checked;
        const requestNotificationsSettingsPayload = { ...requestNotificationsSettingsState, [name]: value };
        setRequestNotificationsSettingsState(prevState => ({
            ...prevState,
            [name]: value,
        }));
        dispatch({
            type: actionTypes.UPDATE_USER_SETTINGS,
            payload: { ...userSettings, documentRequestNotificationsSettings: requestNotificationsSettingsPayload },
        });
    };

    return (
        <LoadingOverlay>
            <div className="message-options-parent-container">
                <header>
                    <h3>{MessageOptionsConstants.NOTIFICATION_OPTION}</h3>
                    <span>{MessageOptionsConstants.NOTIFICATION_OPTION_HELPER_TEXT}</span>
                </header>
                <div className="settings-accordian-container">
                    <Accordion flush className="settings-accordian">
                        <Accordion.Item className="second-accordian-item" eventKey="3">
                            <Accordion.Header>
                                <div className="accordion-title-info-container">
                                    <h4>{MessageOptionsConstants.MESSAGE_ACCORDION_HEADER}</h4>
                                    <span>{MessageOptionsConstants.MESSAGE_ACCORDION_HELPER_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="notification-option-container">
                                    <section>
                                        <h3 className="heading-black-md text-bold">
                                            {MessageOptionsConstants.EXPIRATION}
                                        </h3>
                                        <span className="notification-helper-text">
                                            {MessageOptionsConstants.EXPIRATION_HELPER_TEXT}
                                        </span>
                                    </section>
                                    <div className="settings-label-toggle-wrapper">
                                        <CustomToggleSwitch
                                            label={MessageOptionsConstants.CHANGE_NOTIFY_EXPIRY_TEXT}
                                            handleChange={handleNotificationToggleChange}
                                            name={MessageOptionsConstants.IS_EXPIRY_NOTIFICATION_ENABLE}
                                            switched={
                                                !messageOptionsSettings.notificationsSettings.isUserAllowedToChangeExpiryNotification
                                                    ? messageOptionsSettings.notificationsSettings.isExpiryNotificationEnable
                                                    : notificationsSettingsState?.isExpiryNotificationEnable
                                            }
                                            disabled={
                                                !messageOptionsSettings.notificationsSettings.isUserAllowedToChangeExpiryNotification
                                            }
                                        />
                                    </div>

                                    {/* Download notification settings */}
                                    <section className="">
                                        <h3 className="heading-black-md text-bold">
                                            {MessageOptionsConstants.DOWNLOAD}
                                        </h3>
                                        <span className="notification-helper-text">
                                            {MessageOptionsConstants.DOWNLOAD_HELPER_TEXT}
                                        </span>
                                    </section>
                                    <div className="settings-label-toggle-wrapper">
                                        <CustomToggleSwitch
                                            label={MessageOptionsConstants.CHANGE_NOTIFY_DOWNLOAD_TEXT}
                                            name={MessageOptionsConstants.IS_DOWNLOAD_NOTIFICATION_ENABLE}
                                            handleChange={handleNotificationToggleChange}
                                            switched={
                                                !messageOptionsSettings.notificationsSettings.isUserAllowedToChangeDownloadNotification
                                                    ? messageOptionsSettings.notificationsSettings.isDownloadNotificationEnable
                                                    : notificationsSettingsState?.isDownloadNotificationEnable
                                            }
                                            disabled={
                                                !messageOptionsSettings.notificationsSettings.isUserAllowedToChangeDownloadNotification
                                            }
                                        />
                                    </div>
                                </div>
                            </Accordion.Body>
                            {isLoading && <Loader />}
                        </Accordion.Item>
                        <Accordion.Item className="second-accordian-item" eventKey="4">
                            <Accordion.Header>
                                <div className="accordion-title-info-container">
                                    <h4>{RequestNotificationConstants.REQUEST_NOTIFICATION_ACCORDIAN_HEADER}</h4>
                                    <span>{RequestNotificationConstants.REQUEST_NOTIFICATION_ACCORDIAN_HELPER_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="notification-option-container">
                                    <section>
                                        <h3 className="heading-black-md text-bold">
                                            {RequestNotificationConstants.REQUEST_COMPLETION_USER_SETTINGS}
                                        </h3>
                                        <span className="notification-helper-text">
                                            {RequestNotificationConstants.REQUEST_NOTIFICATION_COMPLETION_HELPER_TEXT}
                                        </span>
                                    </section>
                                    <div className="settings-label-toggle-wrapper">
                                        <CustomToggleSwitch
                                            label={RequestNotificationConstants.REQUEST_NOTIFICATION_NOTIFY_CPA_WHEN_REQUEST_IS_COMPLETE}
                                            handleChange={handleRequestNotificationToggleChange}
                                            name={RequestNotificationConstants.IS_REQUEST_COMPLETION_SETTING_ENABLED}
                                            switched={
                                                !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
                                                    ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
                                                    : requestNotificationsSettingsState?.isDocumentRequestCompletionNotificationEnabled
                                            }
                                            disabled={
                                                !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
                                            }
                                        />
                                    </div>

                                    {/* Reopen notification settings */}
                                    <section className="">
                                        <h3 className="heading-black-md text-bold">
                                            {RequestNotificationConstants.REQUEST_REOPEN_USER_SETTINGS}
                                        </h3>
                                        <span className="notification-helper-text">
                                            {RequestNotificationConstants.REQUEST_NOTIFICATION_REOPEN_HELPER_TEXT}
                                        </span>
                                    </section>
                                    <div className="settings-label-toggle-wrapper">
                                        <CustomToggleSwitch
                                            label={RequestNotificationConstants.REQUEST_NOTIFICATION_NOTIFY_TP_WHEN_REQUEST_IS_REOPEN}
                                            name={RequestNotificationConstants.IS_REQUEST_REOPENED_SETTING_ENABLED}
                                            handleChange={handleRequestNotificationToggleChange}
                                            switched={
                                                !requestNotificationSettings?.isUserAllowedToChangeRequestReopenNotification
                                                    ? requestNotificationSettings?.isRequestReopenNotificationEnabled
                                                    : requestNotificationsSettingsState?.isDocumentRequestReopenNotificationEnabled
                                            }
                                            disabled={
                                                !requestNotificationSettings?.isUserAllowedToChangeRequestReopenNotification
                                            }
                                        />
                                    </div>
                                </div>
                            </Accordion.Body>
                            {isLoading && <Loader />}
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default MessageNotification;
