import React from "react";
import { useParams } from "react-router-dom";
import { WithLogout, WithLogoutProps } from "../../oidcClient/withLogout";


export const RestrictedAccess: React.FC<WithLogoutProps> = ({ onLogout }) => {
    const { message } = useParams();
    return (
        <div style={{ marginLeft: "10px" }}>
            <div className="marB15">
                <h1 className="fontfamily-inital" style={{ fontSize: "2em", fontWeight: "bolder" }}>
                    Restricted access
                </h1>
            </div>

            <div className="marB15">
                <h4 className="fontfamily-inital" style={{ fontSize: "1em", fontWeight: "bolder" }}>
                    {message ?? "This Client's IP address is not allowed to access the server."}
                </h4>
            </div>

            <div>
                <a
                    className="fontfamily-inital"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={onLogout}
                >
                    Click here to login.
                </a>
            </div>
        </div>
    );
};
export default WithLogout(RestrictedAccess);
