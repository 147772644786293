export const injectPendoScript = (userAuth: any) => {
    const {
      company_id,
      company_name,
      user_id,
      given_name: firstName,
      family_name: lastName,
      email,
    } = userAuth?.profile || {};
  
    if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
      console.log("User profile not available yet");
      return;
    }
  
    const apiKey = process.env.REACT_APP_PENDO_API_KEY;
  
    (function (p: any, e: any, n: any, d: any, o: any) {
      let w: number, x: number;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
  
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m: string) {
          o[m] =
            o[m] ||
            function (...args: any[]) {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat(args));
            };
        })(v[w]);
      }
  
      const y = e.createElement(n);
      y.async = true;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      const z = e.getElementsByTagName(n)[0];
      if (z && z.parentNode) {
        z.parentNode.insertBefore(y, z);
      }
    })(window, document, "script", "pendo","");
  
    window.pendo?.initialize({
      visitor: {
        id: `${company_id}-${user_id}-${email}`,
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
      account: {
        id: company_id,
        accountName: company_name,
        payingStatus: "trial",
      },
    });
  };
     
