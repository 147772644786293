import React, { FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class AppNotifier extends React.Component<any, any> {
  static Error = (message: string) =>
    message && toast.error(message, { position: toast.POSITION.TOP_CENTER });

  static Warning = (message: string) =>
    message && toast.warn(message, { position: toast.POSITION.TOP_CENTER });

  static Success = (message: string) =>
    message && toast.success(message, { position: toast.POSITION.TOP_CENTER });

  static Info = (message: string) =>
    message && toast.info(message, { position: toast.POSITION.TOP_CENTER });
}

export const Toaster: FC<any> = () => {
  return (
    <div>
      <ToastContainer
        className="toast-container"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnHover
        style={{ fontSize: "14px" }}
      />
    </div>
  );
};
