import React from "react";
import { Col, Row } from "react-bootstrap";
import { IRecipientModel } from "../../../../core/viewModels/message/RecipientModel";
import {
  formatBytes,
} from "../../../../helper/HelperFunctions";
import { IFileUploadModel } from "./AttachmentUploader";

interface IComposePreviewProps {
  subject: string;
  messageBody: string;
  toRecipients: IRecipientModel[];
  ccRecipients: IRecipientModel[];
  attachments: IFileUploadModel[];
}

const ComposePreview: React.FC<IComposePreviewProps> = (props) => {
  return (
    <div className="compose-preview">
      <div className="compose-preview-header heading-blue-2">Message Draft</div>
      <div className="compose-preview-body">
        <p>
          To:&nbsp;
          {props.toRecipients &&
            props.toRecipients.map((x) => x.emailAddress).join("; ")}
        </p>
        <p>Subject: {props.subject}</p>
        <br />
        <p>Message:</p>
        <p className="message" dangerouslySetInnerHTML={{__html: props.messageBody}}></p>
        
      </div>
      <div className="compose-preview-header heading-blue-2">Attachments</div>
      {props.attachments && (
        <div className="compose-preview-body attachments">
          <Row>
            {props.attachments.map((item: IFileUploadModel, i: number) => {
              return (
                <Col className="attachment-wrapper" sm={6} key={"preview-attachment_" + i}>
                  <p
                    title={item.file.name}
                    className="filename">
                    {item.file.name}
                  </p>
                  <p className="file-size"> {item.fileSize
                      ? formatBytes(item.fileSize)
                      : formatBytes(item.file.size)}
                  </p>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ComposePreview;
