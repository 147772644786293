import * as UserProfileStore from "../reducers/userProfileReducer";
import * as NotificationStore from "../reducers/notificationReducer";
import * as UserAuthStore from "../reducers/authReducers";
import * as FolderStore from "../reducers/folderReducer";
import * as ReceivedMessageStore from "../reducers/receivedMessageReducers";
import * as SpamMessageStore from "../reducers/spamMessageReducers";
import * as SentMessageStore from "../reducers/sentMessageReducers";
import * as ComposeStore from "../reducers/composeReducer";
import * as CompanyStore from "../reducers/companyReducer";
import * as OneTimePasswordStore from "../reducers/otpReducer";
import * as SecurityQuestionStore from "../reducers/securityQuestionReducer";
import * as CommonStore from "../reducers/commonReducer";
import * as AdminSettingsStore from "../reducers/adminSettingsReducer";
import * as UserSettingsStore from "../reducers/userSettingsReducer";
import * as DropOffStore from "../reducers/dropOffReducer";
import * as UnlockStore from "../reducers/unlockReducer";
import * as DraftMessageStore from "../reducers/draftMessageReducer";
import * as SentItemsHistoryStore from "../reducers/sentItemsHistoryReducer";
import * as DocumentRequestHistoryStore from "../reducers/documentRequestHistoryReducer";
import * as ReceivedItemsHistoryStore from "../reducers/receivedItemsHistoryReducer";
import * as ReportsStore from "../reducers/reportsReducer";
import * as PostAuthStore from "../reducers/postAuth";
import * as SignalRStore from "../reducers/signalRReducer";
import * as rbacStore from "../reducers/rbacReducer";
import * as LayoutStore from "../reducers/layoutReducer";
import * as RequestDocumentStore from "../reducers/documentRequestReducer";
import * as TemplateStore from "../reducers/templateReducer";
import * as CompanyMfaStore from "../reducers/companyMfaReducer";
import * as PasswordPolicyStore from "../reducers/passwordPolicyReducer";
import { IMyAccountUserProfile } from "../components/navigation/parts/Profile";
import { ICompanyMfaSettings, IPasswordPolicySettings } from "../core/viewModels/company/CompanySettingsViewModel";
import { PostAuthStoreState, reducer as PostAuthStoreReducer } from "../store/PostAuth/PostAuthStore";
import { IDropOffRestrictedFilesModel } from "../actionCreators/restrictedFileTypeActionCreators";
import * as RestrictedFileTypeStore from "../reducers/restrictedFileTypeReducer";
import * as DocumentCategoryStore from "../reducers/documentCategoryReducer";

// The top-level state object
export interface ApplicationState {
    userAuth: UserAuthStore.UserAuthState;
    userProfile: IMyAccountUserProfile;
    notification: NotificationStore.NotificationState;
    receivedMessageState: ReceivedMessageStore.IReceivedMessageState;
    draftMessageState: DraftMessageStore.IDraftMessageState;
    folderState: FolderStore.IFolderState;
    spamMessageState: SpamMessageStore.ISpamMessageState;
    sentMessageState: SentMessageStore.ISentMessageState;
    composeState: ComposeStore.IComposeState;
    companyData: CompanyStore.ICompanyData;
    oneTimePasswordData: OneTimePasswordStore.IOneTimePasswordState;
    securityQuestionState: SecurityQuestionStore.ISecurityQuestionState;
    commonState: CommonStore.ICommonState;
    adminSettingsState: AdminSettingsStore.IAdminSettingsStore;
    userSettingsState: UserSettingsStore.IUserSettingsStore;
    dropOffState: DropOffStore.IDropOffStore;
    unlockState: UnlockStore.IUnlockStore;
    sentItemsReportState: SentItemsHistoryStore.ISentItemsHistoryStore;
    receivedItemsReportState: ReceivedItemsHistoryStore.ISentItemsHistoryStore;
    documentRequestReportState: DocumentRequestHistoryStore.IDocumentRequestHistoryStore;
    reportsState: ReportsStore.IReportsStore;
    postAuthState: PostAuthStore.IPostAuthState;
    signalRState: SignalRStore.ISignalRStore;
    rbacState: rbacStore.IRbacState;
    layoutState: LayoutStore.ILayoutState;
    documentState: RequestDocumentStore.IDocumentRequestState;
    templateState: TemplateStore.ITemplateState;
    companyMfaSettingState: ICompanyMfaSettings;
    passwordPolicySettingState: IPasswordPolicySettings;
    postAuthDataStore: PostAuthStoreState;
    restrictedFileTypeState: IDropOffRestrictedFilesModel;
    documentCategoryState: DocumentCategoryStore.IDocumentCategoryStore;
}

export const reducers = {
    userAuth: UserAuthStore.reducer,
    userProfile: UserProfileStore.reducer,
    notification: NotificationStore.reducer,
    receivedMessageState: ReceivedMessageStore.reducer,
    draftMessageState: DraftMessageStore.reducer,
    folderState: FolderStore.reducer,
    spamMessageState: SpamMessageStore.reducer,
    sentMessageState: SentMessageStore.reducer,
    composeState: ComposeStore.reducer,
    companyData: CompanyStore.reducer,
    oneTimePasswordData: OneTimePasswordStore.reducer,
    securityQuestionState: SecurityQuestionStore.reducer,
    commonState: CommonStore.reducer,
    adminSettingsState: AdminSettingsStore.reducer,
    userSettingsState: UserSettingsStore.reducer,
    dropOffState: DropOffStore.reducer,
    unlockState: UnlockStore.reducer,
    sentItemsReportState: SentItemsHistoryStore.reducer,
    receivedItemsReportState: ReceivedItemsHistoryStore.reducer,
    documentRequestReportState: DocumentRequestHistoryStore.reducer,
    reportsState: ReportsStore.reducer,
    postAuthState: PostAuthStore.reducer,
    signalRState: SignalRStore.reducer,
    rbacState: rbacStore.reducer,
    layoutState: LayoutStore.reducer,
    documentState: RequestDocumentStore.reducer,
    templateState: TemplateStore.reducer,
    companyMfaSettingState: CompanyMfaStore.reducer,
    passwordPolicySettingState: PasswordPolicyStore.reducer,
    postAuthDataStore: PostAuthStoreReducer,
    restrictedFileTypeState: RestrictedFileTypeStore.reducer,
    documentCategoryState: DocumentCategoryStore.reducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}
