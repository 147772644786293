import React, { Dispatch, SetStateAction, useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Col, Row } from "react-bootstrap";

import { FolderType } from "../../../core/common/enums";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import { IPagination } from "../../../core/viewModels/message/SentMessageModel";
import {
    DEFAULT_PAGE_SIZE,
    MessageListConstants,
    SortAndFilterConstants,
    initialPagination,
} from "../../../helper/Constants";
import { getMessageListHeight } from "../../../helper/HelperFunctions";
import { IResourceLocator } from "../../../helper/ResourceIdLocators";
import { receivedMessageReducer } from "../../../reducers/selectedMessageReducer";
import { ApplicationState } from "../../../store";
import DefaultPagination from "../../common/ui/DefaultPagination";
import MessageListItem from "../receivedMessage/parts/MessageListItem";
import MessageToolbar from "../receivedMessage/parts/MessageToolbar";

interface IMessageListProps {
    messages: IReceivedMessageModel[];
    totalRecords: number;
    folderId: number;
    folderType: FolderType;
    isLoading: boolean;
    resourceLocators: IResourceLocator;
    pagination: IPagination;
    selectedMessage: IReceivedMessageModel;
    folder?: IFolderModel;
    searchText?: string;
    selectedSortOption?: string;
    reload?: boolean;
    deleteMessages: (messages: IReceivedMessageModel[], callback: any) => void;
    getMessageDetail: (message: IReceivedMessageModel) => void;
    fetchFolderMessages?: (pageNo: number, pageSize: number, search: string) => void;
    pageNumberChanged?: (pageNo: number) => void;
    markAsNotSpam?: (messageIds: number[], callback?: any) => void;
    moveToInbox?: (messageIds: number[], callback?: any) => void;
    print: (messages: IReceivedMessageModel[]) => void;
    checkFolderExpiry: (folderId: number) => boolean;
    moveToFolder: (messages: IReceivedMessageModel[], folderId: number, callback?: any) => void;
    hideMessageDetailView(hide: boolean): void;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setSelectedMessage: Dispatch<SetStateAction<IReceivedMessageModel>>;
    setSelectedSortOption?: React.Dispatch<React.SetStateAction<string>>;
    resetSearchAndSort?: () => void;
}

const ArchiveMessageList: React.FC<IMessageListProps> = props => {
    const { pagination, setPagination, selectedMessage, setSelectedMessage } = props;

    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const [selectedReceivedMessage, dispatchSelectedMessageActions] = useReducer(receivedMessageReducer, {
        messages: [],
        isAllSelected: false,
    });

    const signalRState = useSelector((appState: ApplicationState) => appState.signalRState);

    useEffect(() => {
        getData();
        if (props.searchText === "") {
            handleOnClearMessages();
        }
        setPagination(initialPagination);
    }, [props.searchText, props.selectedSortOption]);

    useEffect(() => {
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
        props.resetSearchAndSort && props.resetSearchAndSort();
        if (props.searchText === "" && props.selectedSortOption === SortAndFilterConstants.NEWEST_FIRST_ORDER) {
            getData();
        }
    }, [props.reload, signalRState.refreshSharedFolderRecipient, signalRState.archiveFolderReload]);

    const getData = useCallback(
        (pageNo?: number, pageSize?: number) => {
            if (props.folderType === FolderType.ARCHIVE) {
                props.fetchFolderMessages &&
                    props.fetchFolderMessages(
                        pageNo ?? initialPagination.currentPage,
                        pageSize ?? initialPagination.pageSize,
                        props.searchText ? props.searchText : ""
                    );
            }
        },
        [
            props.reload,
            props.selectedSortOption,
            props.searchText,
            signalRState.refreshSharedFolderRecipient,
            signalRState.archiveFolderReload,
        ]
    );

    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
            totalRecords: props.totalRecords,
        });
        props.pageNumberChanged && props.pageNumberChanged(pageNo);
        getData(pageNo, pageSize);
    };

    const handleMessageDetailView = () => props.hideMessageDetailView(true);

    const handleOnSelected = (message: IReceivedMessageModel, e: any) => {
        e.stopPropagation();
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "add",
                payload: message,
                messageCount: props.messages.length,
            });
        } else {
            dispatchSelectedMessageActions({ type: "remove", payload: message });
        }
    };

    const handleOnAllSelected = (e: any) => {
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "addAll",
                payload: props.messages,
            });
        } else {
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleMarkAsNotSpam = (callback?: any) => {
        if (props.markAsNotSpam) {
            props.markAsNotSpam(
                selectedReceivedMessage.messages.map(x => x.receivedMessageId),
                callback
            );
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleMoveToInbox = (callback?: any) => {
        if (props.moveToInbox) {
            props.moveToInbox(
                selectedReceivedMessage.messages.map(x => x.receivedMessageId),
                callback
            );
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleDeleteMessages = (callback: any) => {
        props.deleteMessages(selectedReceivedMessage.messages, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handlePrintDocument = () => {
        props.print(selectedReceivedMessage.messages);
    };

    const handleMoveToFolder = (folderId: number, callback?: any) => {
        props.moveToFolder(selectedReceivedMessage.messages, folderId, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handleOnClearMessages = () => {
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handleOnMessageItemClick = (message: IReceivedMessageModel, e: any) => {
        e.stopPropagation();
        setSelectedMessage(message);
        props.getMessageDetail(message);
    };

    const resetSelection = () => {
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handleMessageReload = () => {
        // reloads messages
        getData();
    };

    return (
        <>
            <div className="message-list-wrapper">
                <Row>
                    <Col>
                        <MessageToolbar
                            folderType={props.folderType}
                            folderId={props.folderId}
                            selectedMessages={selectedReceivedMessage.messages}
                            isAllSelected={selectedReceivedMessage.isAllSelected}
                            isAnySelected={selectedReceivedMessage.messages.length > 0}
                            selectAll={handleOnAllSelected}
                            deleteMessages={handleDeleteMessages}
                            printDocument={handlePrintDocument}
                            markAsNotSpam={handleMarkAsNotSpam}
                            moveToInbox={handleMoveToInbox}
                            checkFolderExpiry={props.checkFolderExpiry}
                            moveToFolder={handleMoveToFolder}
                            hideMessageDetailView={handleMessageDetailView}
                            resetSelection={resetSelection}
                            resourceLocators={props.resourceLocators}
                            showSelectAllCheckbox={props.messages && props.messages.length > 0}
                            folder={props.folder}
                            resetSearchAndSort={props.resetSearchAndSort}
                            reloadMessages={handleMessageReload}
                        />
                    </Col>
                    <Col>
                        {props.messages && props.messages.length > 0 && (
                            <DefaultPagination
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                totalRecords={props.totalRecords}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Col>
                </Row>
                <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                    <div
                        style={{
                            height: getMessageListHeight(mailboxUserData, props.folderType),
                        }}
                        className="message-list"
                    >
                        {props.messages && props.messages.length > 0 ? (
                            props.messages.map((item: any, index: number) => {
                                const isChecked =
                                    selectedReceivedMessage.messages.includes(item) ||
                                    selectedReceivedMessage.isAllSelected;
                                const selectedMessageId =
                                    selectedMessage?.receivedMessageId === 0
                                        ? props.messages[0]?.receivedMessageId
                                        : selectedMessage?.receivedMessageId;

                                //message selection shouldn't change if new message arrived from signal R
                                if (selectedMessage?.receivedMessageId === 0) {
                                    setSelectedMessage(props.messages[0]);
                                }
                                return (
                                    <MessageListItem
                                        message={item}
                                        key={index}
                                        index={index}
                                        isChecked={isChecked}
                                        selectedMessageId={selectedMessageId}
                                        onSelected={handleOnSelected}
                                        onClick={(message, e) => handleOnMessageItemClick(message, e)}
                                        folder={props.folder}
                                    />
                                );
                            })
                        ) : (
                            <div className="vertical-middle">{MessageListConstants.NO_MESSAGE_TEXT}</div>
                        )}
                    </div>
                    <Loader loading={props.isLoading} />
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ArchiveMessageList;
