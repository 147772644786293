import { Action, Reducer } from "redux";
import produce from "immer";

import {
  ICompanySettings,
  initialCompanySettings,
} from "../core/viewModels/company/CompanySettingsViewModel";
import { DispatchActions } from "../actions/companyActions";
import { actionTypes } from "../types/companyTypes";

export interface ICompanyData {
  companySettings: ICompanySettings;
  isLoading: boolean;
}

const unloadedState: ICompanyData = {
  companySettings: initialCompanySettings,
  isLoading: false,
};

export const reducer: Reducer<ICompanyData> = produce(
  (draftState:ICompanyData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_COMPANY_SETTINGS:
        draftState.isLoading = true;
        break;
      case actionTypes.RECEIVE_COMPANY_SETTINGS:
        draftState.companySettings = action.settings;
        draftState.isLoading = false;
        break;
    }
    return draftState;
  }
);
