export enum actionTypes {
    REQUEST_ADD_NEW_FOLDER = "request_add_new_folder",
    COMPLETED_ADD_NEW_FOLDER = "completed_add_new_folder",

    REQUEST_FOLDERS = "request_folders",
    RECEIVED_FOLDERS = "received_folders",
    COMPLETED_FOLDERS = "completed_folders",

    REQUEST_FOLDERS_TREE = "request_folders_tree",
    RECEIVED_FOLDERS_TREE = "received_folders_tree",
    COMPLETED_FOLDERS_TREE = "completed_folders_tree",

    REQUEST_RETENTION_PERIODS = "request_retention_periods",
    RECEIVED_RETENTION_PERIODS = "received_retention_periods",
    COMPLETED_RETENTION_PERIODS = "completed_retention_periods",

    REQUEST_UPDATE_FOLDER_EXPIRY = "request_update_folder_expiry",
    COMPLETED_UPDATE_FOLDER_EXPIRY = "completed_update_folder_expiry",

    REQUEST_DELETE_FOLDER = "request_delete_folder",
    COMPLETED_DELETE_FOLDER = "completed_delete_folder",

    REQUEST_SUB_FOLDERS = "request_sub_folders",
    RECEIVED_SUB_FOLDERS = "received_sub_folders",
    COMPLETED_SUB_FOLDERS = "completed_sub_folders",

    REQUEST_DELETE_FOLDERS = "request_delete_folders",
    COMPLETED_DELETE_FOLDERS = "completed_delete_folders",

    REQUEST_UPDATE_FOLDERS_EXPIRY = "request_update_folders_expiry",
    COMPLETED_UPDATE_FOLDERS_EXPIRY = "completed_update_folders_expiry",

    REQUEST_UPDATE_FOLDER_NAME = "request_update_folder_name",
    RECEIVE_UPDATE_FOLDER_NAME = "receive_update_folder_name",
    COMPLETED_UPDATE_FOLDER_NAME = "completed_update_folder_name",

    REQUEST_MOVE_FOLDERS = "request_move_folders",
    COMPLETED_MOVE_FOLDERS = "completed_move_folders",

    REQUEST_CHECK_FOLDER_MESSAGES = "request_check_folder_messages",
    COMPLETED_CHECK_FOLDER_MESSAGES = "completed_check_folder_messages",

    REQUEST_MESSAGE_FILE_VIEW_DATA = "request_message_file_view_data",
    RECEIVE_MESSAGE_FILE_VIEW_DATA = "receive_message_file_view_data",
    COMPLETED_MESSAGE_FILE_VIEW_DATA = "completed_message_file_view_data",
    RESET_MESSAGE_FILE_VIEW_DATA = "reset_message_file_view_data",

    REQUEST_DOWNLOAD_NOW = "request_download_now",
    RECEIVE_DOWNLOAD_NOW = "receive_download_now",
    COMPLETE_DOWNLOAD_NOW = "complete_download_now",

    SET_RESET_BREADCRUMB = "set_reset_breadcrumb",

    REQUEST_DOWNLOAD_LATER = "request_download_later",
    RECEIVE_DOWNLOAD_LATER = "receive_download_later",
    COMPLETE_DOWNLOAD_LATER = "complete_download_later",

    REQUEST_MY_DOWNLOADS_LIST = "request_my_downloads_list",
    RECEIVE_MY_DOWNLOADS_LIST = "receive_my_downloads_list",
    COMPLETE_MY_DOWNLOADS_LIST = "complete_my_downloads_list",

    REQUEST_DELETE_MY_DOWNLOAD_FILE = "request_delete_my_download_file",
    RECEIVE_DELETE_MY_DOWNLOAD_FILE = "receive_delete_my_download_file",
    COMPLETE_DELETE_MY_DOWNLOAD_FILE = "complete_delete_my_download_file",

    REQUEST_DOWNLOAD_LATER_LINK = "request_download_later_link",
    RECEIVE_DOWNLOAD_LATER_LINK = "receive_download_later_link",
    COMPLETE_DOWNLOAD_LATER_LINK = "complete_download_later_link",

    REQUEST_SHARE_FOLDER = "request_share_folder",
    RECEIVE_SHARE_FOLDER = "receive_share_folder",
    COMPLETE_SHARE_FOLDER = "complete_share_folder",

    REQUEST_FOLDER_BREADCRUMB = "request_folder_breadcrumb",
    RECEIVE_FOLDER_BREADCRUMB = "receive_folder_breadcrumb",
    COMPLETE_FOLDER_BREADCRUMB = "complete_folder_breadcrumb",

    REQUEST_FOLDER_MAX_RETENTION = "request_folder_max_retention",
    RECEIVE_FOLDER_MAX_RETENTION = "receive_folder_max_retention",
    COMPLETE_FOLDER_MAX_RETENTION = "complete_folder_max_retention",

    REQUEST_FOLDER_DROPOFF_LINK = "request_folder_dropoff_link",
    RECEIVE_FOLDER_DROPOFF_LINK = "receive_folder_dropoff_link",
    COMPLETE_FOLDER_DROPOFF_LINK = "complete_folder_dropoff_link",

    REQUEST_FOLDER_IS_EXIST = "request_folder_is_exist",
    RECEIVE_FOLDER_IS_EXIST = "receive_folder_is_exist",
    COMPLETE_FOLDER_IS_EXIST = "complete_folder_is_exist",

    REQUEST_FOLDER_DETAIL = "request_folder_detail",
    RECEIVE_FOLDER_DETAIL = "receive_folder_detail",
    COMPLETE_FOLDER_DETAIL = "complete_folder_detail",

    REQUEST_FOLDER_DETAILS_WITH_PERMISSION = "request_folder_details_with_permission",
    RECEIVE_FOLDER_DETAILS_WITH_PERMISSION = "receive_folder_details_with_permission",
    COMPLETE_FOLDER_DETAILS_WITH_PERMISSION = "complete_folder_details_with_permission",

    REQUEST_USEROWNED_FOLDERS = "request_userowned_folders",
    RECEIVE_USEROWNED_FOLDERS = "receive_userowned_folders",
    COMPLETE_USEROWNED_FOLDERS = "complete_userowned_folders",

    REQUEST_USERS_TO_SHARE = "request_users_to_share",
    RECEIVE_USERS_TO_SHARE = "receive_users_to_share",
    COMPLETE_USERS_TO_SHARE = "complete_users_to_share",
}
