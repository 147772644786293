import { Action, Reducer } from "redux";
import produce from "immer";
import * as SignalRActions from "../actionCreators/signalRActionCreator";
import { actionTypes } from "../types/signalRTypes";
import { LogoutCause } from "../core/viewModels/signalR/SignalRModel";
import { FolderType } from "../core/common/enums";

export interface ISignalRStore {
    signalRApiBaseUrl: string;
    inboxCurrentPage: number;
    sentCurrentPage: number;
    spamCurrentPage: number;
    myDownloadsCurrentPage: number;
    userAutoLogoutCause: LogoutCause;
    showAutoLogoutModal: boolean;
    currentPage: FolderType;
    currentFolderId: number;
    archiveFolderReload: boolean;
    refreshSharedFolderRecipient: boolean;
    isMoveToFolderPopupOpen: boolean;
}

const initialState: ISignalRStore = {
    signalRApiBaseUrl: "",
    inboxCurrentPage: 1,
    sentCurrentPage: 1,
    spamCurrentPage: 1,
    myDownloadsCurrentPage: 1,
    userAutoLogoutCause: LogoutCause.None,
    showAutoLogoutModal: false,
    currentPage: FolderType.UNKNOWN,
    currentFolderId: 0,
    archiveFolderReload: false,
    refreshSharedFolderRecipient: false,
    isMoveToFolderPopupOpen: false,
};

export const reducer: Reducer<ISignalRStore> = produce(
    (draft: ISignalRStore = initialState, incomingAction: Action) => {
        const action = incomingAction as SignalRActions.DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_WEB_SOCKET_URL:
                return draft;
            case actionTypes.RECEIVED_WEB_SOCKET_URL:
                draft.signalRApiBaseUrl = action.payload;
                return draft;
            case actionTypes.COMPLETED_WEB_SOCKET_URL:
                return draft;
            case actionTypes.INBOX_PAGE_CHANGE:
                draft.inboxCurrentPage = action.pageNumber;
                return draft;
            case actionTypes.SENT_PAGE_CHANGE:
                draft.sentCurrentPage = action.pageNumber;
                return draft;
            case actionTypes.MY_DOWNLOADS_PAGE_CHANGE:
                draft.myDownloadsCurrentPage = action.pageNumber;
                return draft;
            case actionTypes.SPAM_PAGE_CHANGE:
                draft.spamCurrentPage = action.pageNumber;
                return draft;
            case actionTypes.USER_PRIVILEGES_CHANGE:
                draft.userAutoLogoutCause = action.logoutCause;
                draft.showAutoLogoutModal = action.showModal;
                return draft;
            case actionTypes.CURRENT_PAGE_CHANGE:
                return { ...draft, currentPage: action.currentPage };
            case actionTypes.CURRENT_FOLDER_ID_CHANGE:
                return { ...draft, currentFolderId: action.folderId };
            case actionTypes.ARCHIVE_FOLDER_RELOAD:
                return { ...draft, archiveFolderReload: !draft.archiveFolderReload };
            case actionTypes.REFRESH_SHARED_FOLDER_RECIPIENTS:
                return { ...draft, refreshSharedFolderRecipient: !draft.refreshSharedFolderRecipient };
            case actionTypes.MOVE_TO_FOLDER_POUP_TOGGLE:
                    return { ...draft, isMoveToFolderPopupOpen: action.isMoveToFolderPopupOpen };
            default:
                return draft;
        }
    }
);
