import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import {
    addCompanySecurityQuestion,
    addMessageSecurityQuestions,
    updateCompanySecurityQuestion,
    updateMessageSecurityQuestion,
} from "../../../../../actionCreators/securityQuestionCreators";
import { QuestionType } from "../../../../../core/common/enums";
import { IMessageSecurityQuestion } from "../../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { CompanyAuthQuestionsConstants } from "../../../../../helper/Constants";
import { validateQuestion, validateQuestionTitle } from "../../../../../helper/Validations";
import { ApplicationState } from "../../../../../store";
import { CustomModal } from "../CustomModal";
import CustomToggleSwitch from "../../switch/CustomToggleSwitch";
import { fetchUserSettings } from "../../../../../actionCreators/userSettingsActionCreators";

interface IAddQuestionModalProps {
    isUserSecurityQuestion: boolean;
    modalType: string;
    showModal: boolean;
    initialData: IMessageSecurityQuestion;
    isProcessing: boolean;
    hideModal: () => void;
    setReload: () => void;
    clearModalState: () => void;
}

export const initialAddQuestionState: IMessageSecurityQuestion = {
    questionTitle: "",
    question: "",
    questionType: QuestionType.USER,
    userId: 0,
    companyId: 0,
    messageSecurityQuestionId: 0,
    isDefault: false,
};

export const initialQuestion = {
    questionTitle: "",
    question: "",
    isDefault: false,
};

export const initialValidationMessages = {
    invalidQuestionMessage: "",
    invalidQuestionTitleMessage: "",
};

export const AddQuestionModal: React.FC<IAddQuestionModalProps> = ({
    isUserSecurityQuestion,
    modalType,
    showModal,
    hideModal,
    initialData,
    isProcessing,
    setReload,
    clearModalState,
}) => {
    const [questionModalState, setQuestionModalState] = useState(initialData);
    const [validationMessages, setValidationMessages] = useState(initialValidationMessages);
    const dispatch = useDispatch();
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Question Title should not be greater than 50 words
        if (name === "questionTitle" && validateQuestionTitle(value)) {
            setQuestionModalState({ ...questionModalState, [name]: value });
            setValidationMessages({
                ...validationMessages,
                invalidQuestionTitleMessage: "",
            });
        }

        // Question should not be greater than 100 words
        if (name === "question" && validateQuestion(value)) {
            setQuestionModalState({ ...questionModalState, [name]: value });
            setValidationMessages({
                ...validationMessages,
                invalidQuestionMessage: "",
            });
        }
    };

    const isModalDataValid = () => {
        let isValid = true;
        // Check if question is valid
        if (questionModalState.question.trim().length < 1) {
            setValidationMessages({
                ...validationMessages,
                invalidQuestionMessage: CompanyAuthQuestionsConstants.INVALID_QUESTION_TEXT,
            });
            isValid = false;
        }
        // Check if question title is valid
        if (questionModalState.questionTitle.trim().length < 1) {
            setValidationMessages({
                ...validationMessages,
                invalidQuestionTitleMessage: CompanyAuthQuestionsConstants.INVALID_QUESTION_TITLE_TEXT,
            });
            isValid = false;
        }
        return isValid;
    };

    const handleOnSubmit = () => {
        if (isModalDataValid()) {
            questionModalState.question;
            const payload = {...questionModalState, 
                            question: questionModalState.question.trim(),
                            questionTitle: questionModalState.questionTitle.trim()};
            if (modalType === "Add") {
                //dispatch add question action
                if (!isUserSecurityQuestion) {
                    dispatch(addCompanySecurityQuestion(payload, addQuestionSuccessCallback));
                } else {
                    dispatch(addMessageSecurityQuestions(payload, true, addQuestionSuccessCallback));
                }
            }

            if (modalType === "Edit") {
                //dispatch edit question action
                const selectedQuestion = initialData;
                const updateQuestionPayload = {
                    ...selectedQuestion,
                    ...questionModalState,
                    question: questionModalState.question.trim(),
                    questionTitle: questionModalState.questionTitle.trim()
                };
                if (!isUserSecurityQuestion) {
                    dispatch(updateCompanySecurityQuestion(updateQuestionPayload, editQuestionSuccessCallback));
                } else {
                    dispatch(updateMessageSecurityQuestion(updateQuestionPayload, editQuestionSuccessCallback));
                }
            }
        }
    };

    const handleToggleChange = () => {
        setQuestionModalState({
            ...questionModalState,
            isDefault: !questionModalState.isDefault,
        });
    };

    const addQuestionSuccessCallback = (result: number) => {
        if (result) {
            AppNotifier.Success(CompanyAuthQuestionsConstants.ADD_QUESTION_SUCCESS_MESSAGE);
            setReload();
            setQuestionModalState(initialData);
            clearModalState();
            dispatch(fetchUserSettings());
        }
    };
    const editQuestionSuccessCallback = (statusCode: number) => {
        if (statusCode === 204) {
            AppNotifier.Success(CompanyAuthQuestionsConstants.EDIT_QUESTION_SUCCESS_MESSAGE);
            setReload();
            clearModalState();
            dispatch(fetchUserSettings());
        }
    };

    return (
        <CustomModal
            show={showModal}
            onHide={hideModal}
            onSubmit={handleOnSubmit}
            cancelButtonName="Cancel"
            confirmButtonName="Save"
            title={
                modalType === "Add"
                    ? CompanyAuthQuestionsConstants.ADD_QUESTION_MODAL_TITLE
                    : CompanyAuthQuestionsConstants.EDIT_QUESTION_MODAL_TITLE
            }
            className="settings-modal add-question-modal"
        >
            <LoadingOverlay>
                <Form
                    className="settings-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSubmit();
                    }}
                    validated={
                        validationMessages.invalidQuestionMessage !== "" &&
                        validationMessages.invalidQuestionTitleMessage !== ""
                    }
                >
                    <Form.Group controlId="AddquestionId">
                        <Form.Label>
                            {modalType === "Add"
                                ? CompanyAuthQuestionsConstants.ADD_QUESTION_TITLE_INPUT_LABEL
                                : CompanyAuthQuestionsConstants.EDIT_QUESTION_TITLE_INPUT_LABEL}
                        </Form.Label>
                        <Form.Control
                            onChange={handleOnChange}
                            name="questionTitle"
                            value={questionModalState.questionTitle}
                            className="add-question-input"
                            type="text"
                            placeholder={CompanyAuthQuestionsConstants.ADD_QUESTION_TITLE_INPUT_PLACEHOLDER}
                        />
                        <h6 className="text-danger">{validationMessages.invalidQuestionTitleMessage}</h6>

                        <Form.Label>
                            {modalType === "Add"
                                ? CompanyAuthQuestionsConstants.ADD_QUESTION_INPUT_LABEL
                                : CompanyAuthQuestionsConstants.EDIT_QUESTION_INPUT_LABEL}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            name="question"
                            onChange={handleOnChange}
                            value={questionModalState.question}
                            className="add-question-input"
                            type="text"
                            placeholder={CompanyAuthQuestionsConstants.ADD_QUESTION_INPUT_PLACEHOLDER}
                        />
                        <h6 className="text-danger">{validationMessages.invalidQuestionMessage}</h6>
                        {!initialData.question && (
                            <div className="toggle-container">
                                <CustomToggleSwitch
                                    handleChange={handleToggleChange}
                                    switched={questionModalState.isDefault}
                                    label={CompanyAuthQuestionsConstants.ADD_QUESTION_DEFAULT_TOGGLE_LABEL}
                                    disabled={
                                        isUserSecurityQuestion &&
                                        !adminSettingsStore.messageSecuritySettings
                                            .isUserAllowedToChangeDefaultSecurityQuestion
                                    }
                                />
                            </div>
                        )}
                    </Form.Group>
                </Form>
                <Loader loading={isProcessing} text="" />
            </LoadingOverlay>
        </CustomModal>
    );
};
