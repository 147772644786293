import React, { FC } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { LogoutCause } from "../../core/viewModels/signalR/SignalRModel";
import { UserAutoLogoutConstants, UserAutoLogoutMessageConstants } from "../../helper/Constants";
import { signoutRedirect } from "../../oidcClient/userService";
import { resetUserCache } from "../../actionCreators/layoutActionCreators";
import { removeCookie, getCookie, setCookie } from "../../helper/HelperFunctions";

interface UserAutoLogoutModalProps {
    show: boolean;
    logoutCause:LogoutCause;
}
const UserAutoLogoutModal: FC<UserAutoLogoutModalProps> = (props) => {

    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.show && props.logoutCause != LogoutCause.None) {
            dispatch(resetUserCache());
            setCookie("UserPermissionChanged", "true", 1);
        }
        else { LogOutUserOnReloadIfPermissionsChanged(); }
    }, [props.show, props.logoutCause]);



    const LogOutUserOnReloadIfPermissionsChanged = () => {
        if (CheckIfUserPermissionsChanged()) {
            onCloseModal();
        }
    };

    const CheckIfUserPermissionsChanged = (): boolean => {
        if (getCookie("UserPermissionChanged")) return true;
        return false;
    };

    const onCloseModal = (): void => {
        removeCookie("UserPermissionChanged");
        dispatch(resetUserCache());
        signoutRedirect(dispatch);    
    };

return (
    <div className="margin-tbl-20">
        <Modal show={props.show} onHide={onCloseModal} className="rolechange-notification-modal">
            <Modal.Header>
                <Modal.Title className="custom-modal-header">
                    <h3 className="user-autologout-modal-title">{UserAutoLogoutConstants.PermissionChanged}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col lg={12}>
                            <Row className="user-autologout-modal-content">{UserAutoLogoutMessageConstants.PermissionChanged}</Row>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="user-autologout-modal-footer-btn btn btn-primary" variant="primary" onClick={onCloseModal}>
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
);
};
export default UserAutoLogoutModal;

