import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IDropOffSender } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffDropDownConstants, DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { ApplicationState } from "../../../../../../../store";
import SearchInput from "../../../../../../common/ui/SearchInput";
import "./style.scss";

interface ISenderSearchProps {
    setSenderTableData: (sender: IDropOffSender[]) => void;
    reload:boolean
}

export const SenderSearch: React.FC<ISenderSearchProps> = ({ setSenderTableData, reload }) => {
    const [selectedDropDownOption, setSelectedDropDownOption] = useState(
        DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS
    );
    const [searchText, setSearchText] = useState<string>("");

    const dropOffSenders = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffSendersData.dropOffSenders
    );

    useEffect(() => {
        onDropDownFilter(selectedDropDownOption);
    }, [searchText]);

    useEffect(() => {
        setSearchText("");
        setSelectedDropDownOption(DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS);
    }, [reload]);
    
    const onSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchText(value);
    };

    const onDropDownFilter = (value: string) => {
        setSelectedDropDownOption(value);

        switch (value) {
            case DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS:
                setSenderTableData(dropOffSenders);
                const filteredList = dropOffSenders.filter(data =>
                    data.emailAddress.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                );
                setSenderTableData(filteredList);
                break;

            case DropOffDropDownConstants.SENDER_DROPDOWN_BLACKLIST_USERS:
                {
                    const filteredDropDownList = dropOffSenders.filter(data => data.isBlacklisted);
                    setSenderTableData(filteredDropDownList);
                    const filteredList = filteredDropDownList.filter(data =>
                        data.emailAddress.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                    );
                    setSenderTableData(filteredList);
                }
                break;
            case DropOffDropDownConstants.SENDER_DROPDOWN_WHITELIST_USERS:
                {
                    const filteredDropDownList = dropOffSenders.filter(data => !data.isBlacklisted);
                    const filteredList = filteredDropDownList.filter(data =>
                        data.emailAddress.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                    );
                    setSenderTableData(filteredList);
                }

                break;
            default:
                break;
        }
    };

    return (
        <div className="sender-search-wrapper">
            <SearchInput
                placeholder={DropOffUsersConstants.SENDER_SEARCH_PLACEHOLDER}
                onChange={onSearchFilter}
                value={searchText}
            />
            <DropdownButton
                className="search-dropdown-button"
                title={selectedDropDownOption}
                id="input-group-dropdown-2"
                align="start"
            >
                <Dropdown.Item onClick={() => onDropDownFilter(DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS)}>
                    {DropOffDropDownConstants.SENDER_DROPDOWN_ALL_USERS}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDropDownFilter(DropOffDropDownConstants.SENDER_DROPDOWN_WHITELIST_USERS)}
                >
                    {DropOffDropDownConstants.SENDER_DROPDOWN_WHITELIST_USERS}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDropDownFilter(DropOffDropDownConstants.SENDER_DROPDOWN_BLACKLIST_USERS)}
                >
                    {DropOffDropDownConstants.SENDER_DROPDOWN_BLACKLIST_USERS}
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};
