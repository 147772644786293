import axios from "axios";

import {
    CompletedChangeExpiryReceivedMessageAction,
    CompletedDeleteMessageAction,
    CompletedMoveToFolderAction,
    CompletedPrintAction,
    CompletedReceivedMessageDetailAction,
    CompletedReceivedMessagesAction,
    ReceivedMessageArrivalAction,
    ReceivedMessagesAction,
    ReceivedMessagesDetailAction,
    RequestChangeExpiryReceivedMessageAction,
    RequestDeleteMessageAction,
    RequestMessageDetailAction,
    RequestMessagesAction,
    RequestMoveToFolderAction,
    RequestPrintAction,
    ResetMessagesAction,
} from "../actions/receivedMessageActions";
import { MessageType } from "../core/common/enums";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { IReceivedMessageModel } from "../core/viewModels/message/ReceivedMessageModel";
import { appInsightsData } from "../helper/AppInsightsData";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { logger } from "../oidcClient/authProvider";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { actionTypes as receivedMessageAction } from "../types/messageTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";

export type DispatchActions =
    | RequestMessagesAction
    | ReceivedMessagesAction
    | CompletedReceivedMessagesAction
    | RequestChangeExpiryReceivedMessageAction
    | CompletedChangeExpiryReceivedMessageAction
    | RequestMessageDetailAction
    | ReceivedMessagesDetailAction
    | CompletedReceivedMessageDetailAction
    | RequestPrintAction
    | CompletedPrintAction
    | RequestMoveToFolderAction
    | CompletedMoveToFolderAction
    | RequestDeleteMessageAction
    | CompletedDeleteMessageAction
    | ResetMessagesAction
    | ReceivedMessageArrivalAction;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchMessages =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        sortColumn = "",
        sortOrder = "",
        searchString = "",
        callback?: (result: IMessageResponseModel<IReceivedMessageModel>) => void
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            dispatch({
                type: receivedMessageAction.REQUEST_RECEIVED_MESSAGES,
            });

            axios
                .get(
                    `${process.env.REACT_APP_API_URL
                    }/api/ReceivedMessage/GetReceivedMessages?folderId=${folderId}&pageNo=${pageNo}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchString=${encodeURIComponent(
                        searchString.trim()
                    )}&mailboxUserEmailId=${getMailboxUserEmailId()}`
                )
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.RECEIVED_RECEIVED_MESSAGES,
                        payload: data,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_RECEIVED_MESSAGES,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const fetchFolderMessages =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        sortColumn = "",
        sortOrder = "",
        searchString = "",
        callback?: (result: IMessageResponseModel<IReceivedMessageModel>) => void
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            dispatch({
                type: receivedMessageAction.REQUEST_RECEIVED_MESSAGES,
            });

            axios
                .get(
                    `${process.env.REACT_APP_API_URL
                    }/api/ReceivedMessage/GetSharedFolderReceivedMessages?folderId=${folderId}&pageNo=${pageNo}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchString=${encodeURIComponent(
                        searchString.trim()
                    )}&mailboxUserEmailId=${getMailboxUserEmailId()}`
                )
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.RECEIVED_RECEIVED_MESSAGES,
                        payload: data,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_RECEIVED_MESSAGES,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const changeExpiry =
    (
        expiresDate: Date,
        messages: IReceivedMessageModel[],
        callback?: any,
        pageTitle?: string,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            const mailboxUserEmailId = getMailboxUserEmailId();
            dispatch({
                type: receivedMessageAction.REQUEST_CHANGE_EXPIRY_RECEIVED_MESSAGE,
            });
            messages.map(message => {
                logger.trackEvent({
                    name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.changeExpiry}`,
                    properties: {
                        page: pageTitle,
                        expiresDate,
                        ...message,
                    },
                });
            });
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/ReceivedMessage/ChangeExpiry`, messages, {
                    params: {
                        expiresDate,
                        mailboxUserEmailId: mailboxUserEmailId,
                    },
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                })
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const getMessageDetail =
    (messageId: number, messageType: MessageType, callback?: any): AppThunkAction<KnownActions> =>
        dispatch => {
            dispatch({
                type: receivedMessageAction.REQUEST_RECEIVED_MESSAGE_DETAIL,
            });

            axios
                .get(
                    `${process.env.REACT_APP_API_URL
                    }/api/ReceivedMessage/GetReceivedMessageDetail?messageId=${messageId}&messageType=${messageType}&mailboxUserEmailId=${getMailboxUserEmailId()}`
                )
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.RECEIVED_RECEIVED_MESSAGE_DETAIL,
                        payload: data,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_RECEIVED_MESSAGE_DETAIL,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const deleteMessage =
    (
        messages: IReceivedMessageModel[],
        callback?: any,
        pageTitle?: string,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            const mailboxUserEmailId = getMailboxUserEmailId();
            dispatch({
                type: receivedMessageAction.REQUEST_CHANGE_EXPIRY_RECEIVED_MESSAGE,
            });
            messages.map(message => {
                logger.trackEvent({
                    name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.Delete}`,
                    properties: {
                        page: pageTitle,
                        ...message,
                    },
                });
            });

            axios
                .delete(`${process.env.REACT_APP_API_URL}/api/ReceivedMessage/DeleteReceivedMessages`, {
                    params: {
                        mailboxUserEmailId: mailboxUserEmailId,
                    },
                    data: messages,
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                })
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const deleteSharedMessage =
    (
        messages: IReceivedMessageModel[],
        callback?: any,
        pageTitle?: string,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            const mailboxUserEmailId = getMailboxUserEmailId();
            dispatch({
                type: receivedMessageAction.REQUEST_CHANGE_EXPIRY_RECEIVED_MESSAGE,
            });
            messages.map(message => {
                logger.trackEvent({
                    name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.Delete}`,
                    properties: {
                        page: pageTitle,
                        ...message,
                    },
                });
            });

            axios
                .delete(`${process.env.REACT_APP_API_URL}/api/ReceivedMessage/DeleteSharedReceivedMessages`, {
                    params: {
                        mailboxUserEmailId: mailboxUserEmailId,
                    },
                    data: messages,
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                })
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const printMessages = (): AppThunkAction<KnownActions> => dispatch => {
    setTimeout(() => {
        dispatch({
            type: receivedMessageAction.COMPLETED_PRINT_MESSAGES,
        });
    }, 3000);
};

export const moveToFolder =
    (
        messages: IReceivedMessageModel[],
        sourceFolderId: number,
        destinationFolderId: number,
        callback?: any,
        pageTitle?: string,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            const mailboxUserEmailId = getMailboxUserEmailId();
            dispatch({
                type: receivedMessageAction.REQUEST_MOVE_TO_FOLDER,
            });

            messages.map(message => {
                logger.trackEvent({
                    name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.MoveTo}`,
                    properties: {
                        page: pageTitle,
                        sourceFolderId,
                        destinationFolderId,
                        ...message,
                    },
                });
            });

            axios
                .post(`${process.env.REACT_APP_API_URL}/api/ReceivedMessage/MoveToFolder`, messages, {
                    params: {
                        sourceFolderId,
                        destinationFolderId,
                        mailboxUserEmailId: mailboxUserEmailId,
                    },
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                })
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: receivedMessageAction.COMPLETED_MOVE_TO_FOLDER,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: receivedMessageAction.COMPLETED_MOVE_TO_FOLDER,
                    });
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        };

export const inboxMessageArrived =
    (recievedMessage: IReceivedMessageModel): AppThunkAction<DispatchActions> =>
        dispatch => {
            dispatch({
                type: receivedMessageAction.RECEIVED_MESSAGE_ARRIVAL,
                newMessage: recievedMessage,
            });
        };
