import { LinkStatus, MethodOfTransfer } from "../../common/enums";
import { IFilterCondition } from "./reportViewModel";

export interface ISentItemHistoryModel {
  activityLog: string,
  count: number,
  files: string,
  linkStatus: LinkStatus,
  methodOfTransfer: MethodOfTransfer,
  recipients: string,
  sender: string,
  senderEmail: string,
  senderFirstName: string,
  senderLastName: string,
  sentDate: Date,
  sentMessageId: number
}

export interface IReportItemsHistoryRequestModel {
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortOrder: string,
  currentUserEmail?: string,
  filterId: number,
  filterParameters?: IFilterCondition
}

export const initialSentItemsRequestObject: IReportItemsHistoryRequestModel = {
  pageNumber: 1,
  pageSize: 20,
  sortColumn: "",
  sortOrder: "desc",
  filterId: -1
};

export interface ISIHMetricsViewModel
{
    totalMessagesSent: number,
    totalMessagesSentViaAccessCode: number,
    totalMessagesSentViaQA: number
}