
  export interface IDropOffRecipient {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    name: string;
    isDropOffEnabled: boolean;
    isDefault: boolean;
}
  
export interface IDropOffSender{
  dropOffSenderId: number;
  firstName :string
  lastName: string;
  emailAddress: string;
  isBlacklisted: boolean;
  isDeleted: boolean;
}
  
  export interface IDropOffSettings {
    isEmailAddressLookupAllowed: boolean;
    isPersonalDropOffLinkToDisplay: boolean;
    isUsersAllowedToManageDropOffLink: boolean;
    isDownloadNotificationToSend: boolean;
    isSpamFilteringRequired: boolean;
    restrictedFileTypes: string[];
    dropOffRecipients : IDropOffRecipient[];
  }

  export const initialDropOffSettings : IDropOffSettings = {
    isEmailAddressLookupAllowed: false,
    isPersonalDropOffLinkToDisplay: false,
    isUsersAllowedToManageDropOffLink: false,
    isDownloadNotificationToSend: false,
    isSpamFilteringRequired: false,
    restrictedFileTypes: [],
    dropOffRecipients : []
  };