import produce from "immer";
import { Reducer, Action } from "redux";
import { DispatchActions } from "../actionCreators/templateActionCreators";
import {
    IDocumentRequestTemplate,
    IDocumentRequestTemplateModel,
    initialTemplateFormState,
} from "../core/viewModels/template/TemplateModel";
import { actionTypes as templateActions } from "../types/templateTypes";

export interface ITemplateState {
    isLoading: boolean;
    isModalLoading: boolean;
    templateList: IDocumentRequestTemplate[];
    templateDetail: IDocumentRequestTemplateModel;
}

export const initialState: ITemplateState = {
    isLoading: false,
    templateList: [],
    isModalLoading:false,
    templateDetail: initialTemplateFormState,
};

export const reducer: Reducer<ITemplateState> = produce(
    (draft: ITemplateState = initialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case templateActions.REQUEST_TEMPLATE_LIST:
                draft.isLoading = true;
                return draft;
            case templateActions.RECEIVED_TEMPLATE_LIST:
                draft.templateList = action.payload;
                return draft;
            case templateActions.COMPLETED_TEMPLATE_LIST:
                draft.isLoading = false;
                return draft;
            case templateActions.REQUEST_TEMPLATE_DETAIL:
                draft.isLoading = true;
                return draft;
            case templateActions.RECEIVED_TEMPLATE_DETAIL:
                draft.templateDetail = action.payload;
                return draft;
            case templateActions.COMPLETED_TEMPLATE_DETAIL:
                draft.isLoading = false;
                return draft;
            case templateActions.DELETE_TEMPLATE_REQUEST:
                draft.isLoading = true;
                return draft;
            case templateActions.DELETE_TEMPLATE_COMPLETED:
                draft.isLoading = false;
                return draft;
            case templateActions.ADD_EDIT_TEMPLATE_REQUEST:
                draft.isModalLoading = true;
                return draft;
            case templateActions.ADD_EDIT_TEMPLATE_REQUEST_COMPLETED:
                draft.isModalLoading = false;
                return draft;

            default:
                return draft;
        }
    }
);
