import produce from "immer";
import { RecipientType } from "../core/common/enums";
import { IRecipientModel } from "../core/viewModels/message/RecipientModel";

export const initialRecipientQuestionAnswer: IRecipientModel = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  recipientType: RecipientType.ToRecipient,
  questionId: 0,
  question: "",
  answer: "",
  isSaved: false,
  index: 0,
};

export enum QnAActions {
  ADD = "add",
  EDIT = "edit",
  REMOVE = "remove",
  SAVE = "save",
  SET = "set",
  REMOVE_LAST_RECIPIENT ="remove_recipient",
  UPDATE = "update"
}

export const recipientQuestionAnswerReducer = produce(
  (draft: IRecipientModel[], action: any) => {
    let index = -1;
    switch (action.type) {
      case QnAActions.SET:
        draft = action.payload;
        break;
      case QnAActions.ADD:
        const indexes: number[] = draft.map((x) => x.index ?? 0);
        const lastIndex = indexes.length === 0 ? 0 : Math.max(...indexes);
        draft.push({ ...action.payload, index: lastIndex + 1 });
        break;
      case QnAActions.REMOVE:
        draft.splice(
          draft.findIndex(
            (x) =>
              x.emailAddress === action.payload.emailAddress ||
              x.index === action.payload.index
          ),
          1
        );
        break;
      case QnAActions.EDIT:
        index = getRecipientIndex(draft, action.payload.index);
        if (index > -1) draft[index].isSaved = false;
        break;
      case QnAActions.SAVE:
        index = getRecipientIndex(draft, action.payload.index);
        if (index > -1) {
          draft[index].emailAddress = action.payload.data.emailAddress;
          draft[index].question = action.payload.data.question;
          draft[index].questionId = action.payload.data.questionId;
          draft[index].answer = action.payload.data.answer;
          draft[index].isSaved = action.payload.data.isSaved;
          draft[index].isModified = action.payload.data.isModified;
        }
        break;
        case QnAActions.UPDATE : 
        const email  =  action.payload.emailAddress;
        index  = draft.findIndex(x=>x.emailAddress == email);
        if (index > -1) {
          draft[index].emailAddress = action.payload.emailAddress;
          draft[index].question = action.payload.question;
          draft[index].questionId = action.payload.questionId;
          draft[index].answer = action.payload.answer;
          draft[index].isSaved = action.payload.isSaved;
          draft[index].isModified = action.payload.isModified;
        }

        break;
      case QnAActions.REMOVE_LAST_RECIPIENT:  
        draft=draft.slice(0, -1)  ;
        break;
    }
    return draft;
  }
);

const getRecipientIndex = (draft: any, index: number) => {
  const recipient = draft.find((x: any) => x.index === index);
  const result = recipient ? draft.indexOf(recipient) : -1;
  return result;
};
