import { Action, Reducer } from "redux";
import produce from "immer";
import * as reportsActions from "../actionCreators/reportsActionCreator";
import { actionTypes } from "../types/reportsActionsTypes";
import { IFilterModel, IReportSenderModel } from "../core/viewModels/report/reportViewModel";

export interface IReportsStore {
 filters: {
  isLoading: boolean;
  data: IFilterModel[];
  sendersList: IReportSenderModel[]
 }
}
const initialState: IReportsStore = {
  filters: {
    isLoading: false,
    data: [],
    sendersList: []
  }
};

export const reducer: Reducer<IReportsStore> = produce(
  (draft: IReportsStore = initialState, incomingAction: Action) => {
    const action = incomingAction as reportsActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_REPORT_FILTERS:
        draft.filters.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_REPORT_FILTERS:
        draft.filters.data = action.payload;
        return draft;
      case actionTypes.COMPLETED_REPORT_FILTERS:
        draft.filters.isLoading = false;
        return draft;
      case actionTypes.REQUEST_SAVE_FILTERS:
        draft.filters.isLoading = true;
        return draft;
      case actionTypes.COMPLETED_SAVE_FILTERS:
        draft.filters.isLoading = false;
        return draft;
      case actionTypes.REQUEST_DELETE_FILTERS:
        draft.filters.isLoading = true;
        return draft;
      case actionTypes.COMPLETED_DELETE_FILTERS:
        draft.filters.isLoading = false;
        return draft;
      case actionTypes.REQUEST_SENDERS_LIST:
        draft.filters.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_SENDERS_LIST:
        draft.filters.sendersList = action.payload;
        return draft;
      case actionTypes.COMPLETED_SENDERS_LIST:
        draft.filters.isLoading = false;
        return draft;
      default:
        return draft;
    }
  }
);