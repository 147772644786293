export enum actionTypes {
  REQUEST_RECEIVED_MESSAGES = "request_messages",
  RECEIVED_RECEIVED_MESSAGES = "received_received_messages",
  COMPLETED_RECEIVED_MESSAGES = "completed_received_messages",

  REQUEST_CHANGE_EXPIRY_RECEIVED_MESSAGE = "request_change_expiry_received_message",
  COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE = "completed_change_expiry_received_message",

  REQUEST_RECEIVED_MESSAGE_DETAIL = "request_received_message_detail",
  RECEIVED_RECEIVED_MESSAGE_DETAIL = "received_received_message_detail",
  COMPLETED_RECEIVED_MESSAGE_DETAIL = "completed_received_message_detail",

  REQUEST_PRINT_MESSAGES = "request_print_messages",
  COMPLETED_PRINT_MESSAGES = "completed_print_messages",

  REQUEST_MOVE_TO_FOLDER = "request_move_to_folder",
  COMPLETED_MOVE_TO_FOLDER = "completed_move_to_folder",

  REQUEST_DELETE_MESSAGES = "request_delete_messages",
  COMPLETED_DELETE_MESSAGES = "completed_delete_messages",

  RESET_MESSAGES_ACTION = "reset_messages",

  RECEIVED_MESSAGE_ARRIVAL="received_message_arrival", 
}
