import { Action, Reducer } from "redux";
import produce from "immer";
import * as SentItemsHistoryActions from "../actionCreators/sentItemsHistoryActionCreator";
import { actionTypes } from "../types/sentItemsHistoryTypes";
import { ISentItemHistoryModel } from "../core/viewModels/report/SentItemsHistoryModel";
import { IMetricsCardModel } from "../core/viewModels/report/reportViewModel";

export interface ISentItemsHistoryStore {
  isLoading: boolean;
  data: ISentItemHistoryModel[];
  isDownloading: boolean;
  metricsData: IMetricsCardModel[]
}
const initialState: ISentItemsHistoryStore = {
  isLoading: false,
  data: [],
  isDownloading: false,
  metricsData: []
};

export const reducer: Reducer<ISentItemsHistoryStore> = produce(
  (draft: ISentItemsHistoryStore = initialState, incomingAction: Action) => {
    const action = incomingAction as SentItemsHistoryActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_SENT_ITEMS_HISTORY:
        draft.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_SENT_ITEMS_HISTORY:
        draft.isLoading = false;
        draft.data = action.payload;
        return draft;
      case actionTypes.COMPLETED_SENT_ITEMS_HISTORY:
        draft.isLoading = false;
        return draft;
      case actionTypes.REQUEST_SENT_ITEMS_HISTORY_DOWNLOAD:
        draft.isDownloading = true;
        return draft;
      case actionTypes.RECEIVE_SENT_ITEMS_HISTORY_DOWNLOAD:
        draft.isDownloading = false;
        return draft;
      case actionTypes.COMPLETED_SENT_ITEMS_HISTORY_DOWNLOAD:
        draft.isDownloading = false;
        return draft;
      case actionTypes.REQUEST_SENT_ITEMS_HISTORY_METRICS:
        draft.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_SENT_ITEMS_HISTORY_METRICS:
        draft.metricsData = action.payload;
        return draft;
      case actionTypes.COMPLETED_SENT_ITEMS_HISTORY_METRICS:
        return draft;
      default:
        return draft;
    }
  }
);