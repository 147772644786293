import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { ApplicationState } from "../../../store";
import SentMessageList from ".././sentMessage/parts/SentMessageList";
import {
    fetchSentMessages,
    deleteMessages,
    cancelTransfer,
    changeExpiry,
    printMessages,
    moveToFolder,
    resendAccessLink,
    resendQAAuthAnswer,
    getMessageDetail,
} from "../../../actionCreators/sentMessageActionCreators";
import { ISentMessageModel } from "../../../core/viewModels/message/SentMessageModel";
import { downloadAllAttachments, downloadSingleAttachment } from "../../../actions/attachmentActions";
import { DownloadedFrom, IFileModel } from "../../../core/viewModels/file/FileModel";
import { FolderType, MessageSource } from "../../../core/common/enums";
import MessageDetail from "../../common/message/MessageDetail";
import { IMessageResponseModel } from "../../../core/viewModels/message/MessageResponseModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import {
    initialPagination,
    MessageListConstants,
    MessageToolbarConstants,
    viewingOthersInboxLabel,
} from "../../../helper/Constants";
import { IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import { fetchSentMessageHistorySettings } from "../../../actionCreators/adminSettingsActionCreators";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";
import { currentPageChanged } from "../../../actionCreators/signalRActionCreator";

const Sent: React.FC = () => {
    const [isMessageDetailShow, toggleMessageDetailShow] = useState(false);
    const [pagination, setPagination] = useState(initialPagination);

    const dispatch = useDispatch();
    const messageState = useSelector((state: ApplicationState) => state.sentMessageState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatch(currentPageChanged(FolderType.SENT));
        dispatch(fetchSentMessageHistorySettings());
        dispatch(getRestrictedFileTypes());
        logger.trackPageView(appInsightsData.MailBox.Sent.PageTitle);
    }, []);

    const fetchMessages = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string,
        messageListReload?: boolean,
        selectedMessage?: ISentMessageModel | IReceivedMessageModel
    ) => {
        if (messageListReload) {
            dispatch(fetchSentMessages(FolderType.SENT, pageNo, pageSize, sortColumn, sortOrder, search));
            selectedMessage && handleGetMessageDetail(selectedMessage); 
        } else {
            dispatch(
                fetchSentMessages(
                    FolderType.SENT,
                    pageNo,
                    pageSize,
                    sortColumn,
                    sortOrder,
                    search,
                    onFetchMessagesComplete
                )
            );
        }
    };

    const onFetchMessagesComplete = (result: IMessageResponseModel<ISentMessageModel>) => {
        if (result && result.messages && result.messages.length > 0) {
            dispatch(getMessageDetail(result.messages[0].sentMessageId, FolderType.SENT, messageDetailCallback));
        }
    };

    const messageDetailCallback = (result: any) => {
        if (result.messageDetail) toggleMessageDetailShow(true);
        else toggleMessageDetailShow(false);
    };

    const handleOnDeleteMessage = (messages: ISentMessageModel[], callback: any) => {
        dispatch(deleteMessages(messages, callback, ResourceIdLocators.Sent.MsgListBtnDelete));
    };

    const handleOnCancelTransfer = (messageIds: number[], callback: any) => {
        dispatch(cancelTransfer(messageIds, ResourceIdLocators.Sent.MsgListBtnCancelTransfer, callback));
    };

    const handleOnChangeExpiry = (
        expiresDate: Date,
        message: ISentMessageModel | IReceivedMessageModel,
        callback?: any
    ): void => {
        const sentMessage = message as ISentMessageModel;
        dispatch(changeExpiry(expiresDate, [sentMessage], callback));
    };

    const handleOnPrint = (): void => {
        dispatch(printMessages());
    };

    const handleResendAccessLink = (messages: ISentMessageModel[]): void => {
        dispatch(
            resendAccessLink(
                messages,
                ResourceIdLocators.Sent.MsgListBtnResendAccessLink,
                (result: boolean) => result && AppNotifier.Success(MessageToolbarConstants.RESEND_LINK_SUCCESS)
            )
        );
    };

    const handleResendQAAuthAnswer = (messages: ISentMessageModel[]): void => {
        dispatch(
            resendQAAuthAnswer(
                messages,
                (result: boolean) => result && AppNotifier.Success(MessageToolbarConstants.RESEND_AUTH_ANSWER_SUCCESS)
            )
        );
    };

    const checkFolderExpiry = (folderId: number): boolean => {
        // let destinationFolderExpiryDays: number | undefined;
        // if (!folderState.foldersData.isLoading) {
        //   destinationFolderExpiryDays = folderState.foldersData.folders?.find(
        //     (f) => f.folderId === folderId
        //   )?.expiresDays;

        // }
        // //To do
        if (folderId) {
            return true;
        }
        return true;
    };

    const handleOnMoveToFolder = (messages: ISentMessageModel[], folderId: number, callback?: any): void => {
        dispatch(moveToFolder(messages, folderId, callback));
    };

    const handleGetMessageDetail = (message: ISentMessageModel | IReceivedMessageModel) => {
        const sentMessage = message as ISentMessageModel;
        sentMessage.sentMessageId>0 && dispatch(getMessageDetail(sentMessage.sentMessageId, FolderType.SENT, messageDetailCallback));
    };

    const handleMessageDetailView = (result: boolean) => {
        if (result) toggleMessageDetailShow(false);
    };

    const handleDownloadSingleAttachment = (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string
    ) => {
        dispatch(
            downloadSingleAttachment(
                fileId,
                physicalPath,
                messageId,
                fileName,
                "",
                "",
                MessageSource.MAILBOX,
                DownloadedFrom.MPI_Download_SentItem_Single_File,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Sent.PageTitle,
                ResourceIdLocators.Sent.MsgDtlBtnDownloadFile
            )
        );
    };

    const handleDownloadAllAttachments = (files: IFileModel[], messageId: number) => {
        dispatch(
            downloadAllAttachments(
                files,
                messageId,
                "",
                "",
                MessageSource.MAILBOX,
                DownloadedFrom.MPI_Download_SentItem_Multiple_File,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Sent.PageTitle,
                ResourceIdLocators.Sent.MsgDtlBtnDownloadFiles
            )
        );
    };

    const handleResetSearchAndSort = () => {
        setReload(value => !value);
    };

    return (
        <Row className="height-100-percent width-100-percent margin-left-0">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <Col sm={4} className="no-padding-left">
                <SentMessageList
                    isLoading={messageState.isLoading}
                    messages={messageState.data.messages}
                    totalRecords={messageState.data.totalRecords}
                    fetchMessages={fetchMessages}
                    deleteMessages={handleOnDeleteMessage}
                    cancelTransfer={handleOnCancelTransfer}
                    print={handleOnPrint}
                    checkFolderExpiry={checkFolderExpiry}
                    moveToFolder={handleOnMoveToFolder}
                    resendAccessLink={handleResendAccessLink}
                    resendQAAuthAnswer={handleResendQAAuthAnswer}
                    getMessageDetail={handleGetMessageDetail}
                    hideMessageDetailView={handleMessageDetailView}
                    pagination={pagination}
                    setPagination={setPagination}
                    reload={reload}
                />
            </Col>
            <Col sm={8}>
                <LoadingOverlay style={{ height: "100%" }}>
                    {isMessageDetailShow && messageState.data.messages.length > 0 ? (
                        <MessageDetail
                            message={messageState.mailData.data}
                            downloadSingleAttachment={handleDownloadSingleAttachment}
                            downloadAllAttachments={handleDownloadAllAttachments}
                            messageId={messageState.mailData.data.messageDetail.sentMessageId}
                            folderType={FolderType.SENT}
                            cancelTransfer={(messageId: number, callback: any) => {
                                handleOnCancelTransfer([messageId], callback);
                            }}
                            hideMessageDetailView={handleMessageDetailView}
                            getMessageDetail={handleGetMessageDetail}
                            changeExpiry={handleOnChangeExpiry}
                            pagination={pagination}
                            fetchFolderMessages={fetchMessages}
                            resourceLocators={ResourceIdLocators.Sent}
                            onSuccessCallback={fetchMessages}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    ) : (
                        messageState.mailData.isLoading || (
                            <div className="no-message-selected">{MessageListConstants.NO_MESSAGE_SELECTED}</div>
                        )
                    )}
                    <Loader loading={messageState.mailData.isLoading} />
                </LoadingOverlay>
            </Col>
        </Row>
    );
};

export default Sent;
