export enum actionTypes {
  REQUEST_SPAM_MESSAGES = "request_spam_messages",
  RECEIVED_SPAM_MESSAGES = "received_spam_messages",
  COMPLETED_SPAM_MESSAGES = "completed_spam_messages",
  REQUEST_MARK_AS_NOT_SPAM = "request_mark_as_not_spam",
  COMPLETED_MARK_AS_NOT_SPAM = "completed_mark_as_not_spam",
  REQUEST_MOVE_TO_INBOX = "request_move_to_inbox",
  COMPLETED_MOVE_TO_INBOX = "completed_move_to_inbox",

  REQUEST_SPAM_MESSAGE_DETAIL = "request_spam_message_detail",
  RECEIVED_SPAM_MESSAGE_DETAIL = "received_spam_message_detail",
  COMPLETED_SPAM_MESSAGE_DETAIL = "completed_spam_message_detail",

  REQUEST_DELETE_MESSAGES = "request_delete_messages",
  COMPLETED_DELETE_MESSAGES = "completed_delete_messages",
}
