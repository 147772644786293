import React, { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import MyAccountLayout from "../parts/MyAccountLayout";
import "./style.scss";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

const MyAccountModal: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    useEffect(() => {
        if (show) logger.trackPageView(appInsightsData.MyAccount.PageTitle);
    }, [show]);
    return <Modal dialogClassName="custom-bootstrap-modal my-account-modal"
        show={show} onHide={() => onHide()}>
        <Modal.Header className="padding-4" closeButton closeVariant="white" data-test-auto={AutomationIdConstants.reportProblem.MyAccountModalTitle}>My Account</Modal.Header>
        <Modal.Body className="padding-0-px">
            <MyAccountLayout onHide={onHide} />
        </Modal.Body>
    </Modal>;
};

export default MyAccountModal;