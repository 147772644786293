import React from  "react";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

interface SecurityModalProps {
    openModal: boolean;
}

export default class SecurityModal extends React.Component<SecurityModalProps, any>{
    constructor(props:any) {
        super(props);
        
    }

    private onCloseModal = () => {
        location.reload();
    };

    render() {
        const { openModal } = this.props;
        return (
            <div className="margin-tbl-20">
                <Modal show={openModal} onHide={this.onCloseModal} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className='modalIcon text-secondary fas fa-info-circle'>
                            </span>Security
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col lg={3}>
                                </Col>
                                <Col lg={9}>
                                    <h2 style={{ color: "#337ab7", margin: "0" }}>Session Expired</h2>
                                    <Row className="margin-top-10">
                                       Your session has expired. Please login again.
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-white"
                            variant='default'
                            onClick={this.onCloseModal}
                        >
                            <i className='fas fa-redo-alt'></i>
                            Login
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

