import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IRecipientModel } from "../../../../core/viewModels/message/RecipientModel";

import {
    IMessageSecurityQuestion,
    ISecurityQuestionAnswer,
} from "../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { RecipientQnAPlaceholder } from "../../../../helper/Constants";
import { MinusCircleIcon } from "../../../svg/IconCollection";
import { MessageActions } from "../../../../core/common/enums";
import { useDispatch } from "react-redux";
import {
    getRecipientDefaultQuestionAndAnswer,
    getRecipientQuestionAnswer,
} from "../../../../actionCreators/securityQuestionCreators";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { debounce } from "throttle-debounce";

interface RecipientQuestionAnswerRowProps {
    data: IRecipientModel;
    questions: IMessageSecurityQuestion[];
    isLastRow: boolean;
    totalRows: number;
    messageAction: MessageActions;
    onAddRow: () => void;
    onRemoveRow: (recipient: IRecipientModel) => void;
    onEditRow: (recipient: IRecipientModel) => void;
    onSaveRow: (recipient: IRecipientModel, data: IRecipientModel) => void;
    validateDuplicateRecipient: (email: string, index?: number) => boolean;
}

const RecipientQuestionAnswerRow: React.FC<RecipientQuestionAnswerRowProps> = props => {
    const [dropdownTitle, setDropdownTitle] = useState(getUsersDefaultQuestion().questionTitle);
    const [isQuestionAnswerLoading, setQuestionAnswerLoading] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState("");

    const dispatch = useDispatch();
    useEffect(() => {
        const defaultQuestion = getUsersDefaultQuestion();
        if (!props.data.questionId || props.data.questionId === defaultQuestion.messageSecurityQuestionId) {
            props.onSaveRow(props.data, {
                ...props.data,
                question: defaultQuestion.question,
                questionId: defaultQuestion.messageSecurityQuestionId,
            });
        }
    }, []);

    useEffect(() => {
        setDropdownTitle(
            props.questions.find(x => x.messageSecurityQuestionId === props.data.questionId)?.questionTitle ||
                getUsersDefaultQuestion().questionTitle
        );
    }, [props.data.questionId, props.questions]);

    function getUsersDefaultQuestion() {
        return props.questions.find(x => x.isDefault) || props.questions[0] || {};
    }
    useEffect(() => {
        setEmailAddress(props.data.emailAddress);
    }, [props.data.emailAddress]);

    const handleOnQuestionChange = (e: any) => {
        const questionId = +e.target.value;
        const question: string = props.questions.find(x => x.messageSecurityQuestionId === questionId)?.question ?? "";
        const questionTitle: string =
            props.questions.find(x => x.messageSecurityQuestionId === questionId)?.questionTitle ?? "";

        setDropdownTitle(questionTitle);
        setQuestionAnswerLoading(true);
        dispatch(
            getRecipientQuestionAnswer(props.data.emailAddress, questionId, (result: ISecurityQuestionAnswer) => {
                setQuestionAnswerLoading(false);
                const answer = !result.answer ? "" : result.answer;
                const isModified = false;
                props.onSaveRow(props.data, { ...props.data, question, answer, questionId, isModified });
            })
        );
    };

    const onEmailAddressChange = (value: string) => {
        setQuestionAnswerLoading(true);
        const defaultQuestion: IMessageSecurityQuestion | undefined = props.questions.find(x => x.isDefault);
        dispatch(
            getRecipientDefaultQuestionAndAnswer(
                value,
                defaultQuestion?.messageSecurityQuestionId ?? 0,
                (result: ISecurityQuestionAnswer) => {
                    setQuestionAnswerLoading(false);
                    const answer = !result.answer ? "" : result.answer;
                    const question: string =
                        props.questions.find(x => x.messageSecurityQuestionId === result.securityQuestionId)
                            ?.question ?? getUsersDefaultQuestion().question;
                    const questionTitle: string =
                        props.questions.find(x => x.messageSecurityQuestionId === result.securityQuestionId)
                            ?.questionTitle ?? getUsersDefaultQuestion().questionTitle;
                    const questionId =
                        props.questions.find(x => x.messageSecurityQuestionId === result.securityQuestionId)
                            ?.messageSecurityQuestionId ?? getUsersDefaultQuestion().messageSecurityQuestionId;
                    setDropdownTitle(questionTitle);

                    const isModified = false;
                    props.onSaveRow(props.data, {
                        ...props.data,
                        question,
                        emailAddress: value,
                        answer,
                        questionId,
                        isModified,
                    });
                }
            )
        );
    };
    const debounceFn = useCallback(debounce(1000, onEmailAddressChange), []);

    const onChangeTextFieldValue = (event: any) => {
        const target = event.target;
        const value = event.target.value;
        const name = target.name;
        if (name === "emailAddress") {
            setEmailAddress(value);
            debounceFn(value);
        }

        const data = {
            ...props.data,
            [name]: value,
            isModified: props.data.isModified || name === "question",
        };
        props.onSaveRow(props.data, data);
    };

    const renderRemoveButton = () => {
        return (
            <a onClick={() => props.onRemoveRow(props.data)} title="Remove">
                <MinusCircleIcon color="#606060" height={20} width={20} />
            </a>
        );
    };

    const renderActionButtons = () => {
        if (!props.isLastRow) return renderRemoveButton();
    };

    return (
        <LoadingOverlay style={{ height: "100%" }}>
            <Row className="set-qa-form">
                <Col xs={3}>
                    <Form.Control
                        type="text"
                        className="input-rounded"
                        name="emailAddress"
                        disabled={props.data.isSaved}
                        value={emailAddress}
                        onChange={onChangeTextFieldValue}
                    />
                </Col>
                <Col xs={2}>
                    <Form.Select
                        className="underlined-input default-select"
                        onChange={handleOnQuestionChange}
                        title={dropdownTitle}
                        disabled={props.data.isSaved}
                        value={props.data.questionId || getUsersDefaultQuestion().messageSecurityQuestionId}
                    >
                        {props.questions.map((item, index) => (
                            <option key={index} value={item.messageSecurityQuestionId}>
                                {item.questionTitle}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Control
                        type="text"
                        className="underlined-input"
                        name="question"
                        disabled={props.data.isSaved}
                        onChange={onChangeTextFieldValue}
                        value={props.data.question}
                        title={props.data.question}
                    />
                </Col>
                <Col xs={2}>
                    <Form.Control
                        type="text"
                        className="underlined-input"
                        name="answer"
                        disabled={props.data.isSaved}
                        onChange={onChangeTextFieldValue}
                        value={props.data.answer}
                        title={props.data.answer}
                        placeholder={RecipientQnAPlaceholder.ANSWER}
                    />
                </Col>
                <span className="actions">{renderActionButtons()}</span>
            </Row>
            <Loader classNamePrefix="sm" text="" loading={isQuestionAnswerLoading} />
        </LoadingOverlay>
    );
};

export default RecipientQuestionAnswerRow;
