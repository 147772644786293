import React, { useEffect, useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/esm/Form";
import Recipient from "./parts/Recipient";
import ComposeOptions from "./parts/ComposeOptions";
import { ComposeIcon, ComposeSettingIcon } from "./../../svg/ComposeIconsCollection";
import TinyMCEComponent from "./../ui/tinymce/TinyMCE";
import { IRecipientModel } from "../../../core/viewModels/message/RecipientModel";
import AttachmentUploader, { IFileUploadModel } from "./parts/AttachmentUploader";
import {
    isValidEmailAddress,
    IValidationMessage,
    validateAllFilesUploaded,
    validateComposeMessageModel,
    validateRecipientQnA,
    isMaxUploadSizeLimitExceeded,
    validateFileSize,
} from "../../../helper/Validations";
import { useDispatch, useSelector } from "react-redux";
import { fetchUploadAttachmentSasLink, removeAttachment } from "../../../actionCreators/composeActionCreators";
import AttachmentList from "./parts/AttachmentList";
import {
    AuthenticationMethod,
    MessageTab,
    MessageActions,
    RecipientType,
    SettingMode,
    ValidationType,
} from "../../../core/common/enums";
import { sendMessage } from "../../../actionCreators/composeActionCreators";
import { replySentMessage } from "../../../actionCreators/sentMessageActionCreators";
import { ISentMailMessage } from "../../../core/viewModels/message/SentMailMessageModel";
import {
    IDraftMailMessage,
    IDraftMessageRecipientModel,
    initialDraftMessage,
    intialDraftMessageRecipientModel,
} from "../../../core/viewModels/message/DraftMailMessage";
import { initialSentMessage, ISentMessageModel } from "../../../core/viewModels/message/SentMessageModel";
import {
    initialSentMessageRecipientModel,
    ISentMessageRecipientModel,
} from "../../../core/viewModels/message/SentMessageRecipientModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import { ApplicationState } from "../../../store";
import { IFileModel } from "../../../core/viewModels/file/FileModel";
import {
    initialRecipientQuestionAnswer,
    QnAActions,
    recipientQuestionAnswerReducer,
} from "../../../reducers/recipientQuestionAnswerReducer";
import {
    addMessageSecurityQuestions,
    fetchMessageSecurityQuestions,
    getRecipientDefaultQuestionAndAnswer,
} from "../../../actionCreators/securityQuestionCreators";
import {
    IMessageSecurityQuestion,
    ISecurityQuestionAnswer,
} from "../../../core/viewModels/message/MessageSecurityQuestionModel";
import ComposePreview from "./parts/ComposePreview";
import { ComposeValidations, DraftMessageConstants, MAX_SUBJECT_LENGTH } from "../../../helper/Constants";
import LoadingModal from "../ui/Modals/LoadingModal";
import {
    fetchAuthenticationMethodSettings,
    fetchMessageSecurityQuestionSettings,
} from "../../../actionCreators/adminSettingsActionCreators";
import { fetchUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import { CustomModal } from "../ui/Modals/CustomModal";
import { saveDraftMessage, updateDraftMessage } from "../../../actionCreators/draftMessageActionCreator";
import { logger } from "../../../oidcClient/authProvider";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { getFileName, getUserIdFromEmail } from "../../../helper/HelperFunctions";
import {
    fetchUploadAttachmentSasLink as fetchUploadAttachmentSasLinkForDraft,
    removeAttachment as removeAttachmentForDraft,
} from "../../../actionCreators/draftMessageActionCreator";
import { IResourceLocator, ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

interface IComposeModalProps {
    show: boolean;
    draftMessage?: IDraftMailMessage;
    title: string;
    messageBody?: string;
    subject?: string;
    toRecipients?: IRecipientModel[];
    ccRecipients?: any[];
    messageAction: MessageActions;
    attachments: IFileUploadModel[];
    resourceLocators?: IResourceLocator;
    onHide: () => void;
    setReloadAction?: React.Dispatch<React.SetStateAction<string>>;
    onMessageForward?: (sentMailMessage: ISentMailMessage, callback?: any, errorCallback?: () => void) => void;
}

const ComposeModal: React.FC<IComposeModalProps> = props => {
    const dependencies = [props.messageBody, props.subject, props.toRecipients, props.ccRecipients, props.attachments];
    const [openTab, toggleTab] = useState<MessageTab>(MessageTab.Compose);
    const userSettingsStore = useSelector((state: ApplicationState) => state.userSettingsState);
    const [isInvalidData, setIsInvalidData] = useState(false);
    const [showDraftModal, setShowDraftModal] = useState(false);
    const [confirmSend, setConfirmSend] = useState({
        show: false,
        sendAnyway: false,
        message: "",
    });
    const showSaveDraftOption =
        props.messageAction === MessageActions.New || props.messageAction === MessageActions.Draft;
    const [messageBody, setMessageBody] = useState("");
    const [subject, setSubject] = useState("");
    const [attachments, setAttachments] = useState<IFileUploadModel[]>([]);
    const [showDragAndDrop, setShowDragAndDrop] = useState(false);
    const [isAuthMethodUpdated, setIsAuthMethodUpdated] = useState(false);
    const [isRetentionUpdated, setIsRetentionUpdated] = useState(false);
    const [sentMessage, setSentMessage] = useState<ISentMessageModel>(initialSentMessage);

    const [recipients, dispatchRecipient] = useReducer(recipientQuestionAnswerReducer, []);

    const profile = useSelector((appState: ApplicationState) => appState.userSettingsState);

    const fileUploadUtilities = new UploadFunctions();

    const composeState = useSelector((state: ApplicationState) => state.composeState);
    const securityQuestionState = useSelector((state: ApplicationState) => state.securityQuestionState);
    const draftMessageState = useSelector((state: ApplicationState) => state.draftMessageState);
    const documentsRef = React.useRef<IFileUploadModel[]>([]);
    documentsRef.current = attachments;

    const getSelectedOption = (value?: number) => {
        if (value) {
            const result = composeState.authRetentions.data.find(rp => rp.retentionId === value);

            if (result) return result.days;
            else return 0;
        }
        return 0;
    };

    function getUsersDefaultQuestion() {
        return (
            securityQuestionState.securityQuestions.data.find(x => x.isDefault) ||
            securityQuestionState.securityQuestions.data[0]
        );
    }

    const dispatch = useDispatch();

    useEffect(() => {
        composeInitiate();
    }, dependencies);

    useEffect(() => {
        setShowDragAndDrop(false);
    }, [attachments]);

    useEffect(() => {
        let questionId = 0;
        if (securityQuestionState.securityQuestions?.data?.length > 0) {
            questionId = getUsersDefaultQuestion().messageSecurityQuestionId ?? 0;
        }

        if (recipients.length > 0 && recipients[recipients.length - 1].emailAddress !== "") {
            questionId = recipients[recipients.length - 1].questionId || questionId;
            const rec: IRecipientModel | undefined = recipients.find(
                x => x.isModified == undefined || x.isModified == true
            );
            if (rec)
                dispatch(
                    getRecipientDefaultQuestionAndAnswer(
                        rec.emailAddress,
                        questionId,
                        (result: ISecurityQuestionAnswer) => {
                            props.messageAction !== MessageActions.Draft && handleRecipientQuestionAnswer(result);
                            props.messageAction === MessageActions.Draft && handleDraftRecipientQuestionAnswer(result);
                        }
                    )
                );
        }
    }, [securityQuestionState.securityQuestions.data.length, recipients.length]);

    const composeInitiate = () => {
        toggleTab(MessageTab.Compose);
        setMessageBody(props.messageBody ? props.messageBody : "");
        setSubject(props.subject ? props.subject : "");
        setAttachments(props.attachments.length > 0 ? props.attachments : []);
        setSentMessage({
            ...sentMessage,
            message: props.messageBody ?? "",
            subject: props.subject ?? "",
        });
        let tempRecipients = props.toRecipients ? props.toRecipients : [];
        tempRecipients = props.ccRecipients ? tempRecipients.concat(props.ccRecipients) : tempRecipients;
        const emailAddresses = tempRecipients.map(item => item.emailAddress);
        tempRecipients = tempRecipients.filter((item, index) => emailAddresses.indexOf(item.emailAddress) === index);

        let i = 0;
        tempRecipients = tempRecipients.map(item => {
            if (item.index === undefined) item.index = i;
            i++;
            return item;
        });

        if (props.messageAction !== MessageActions.New) {
            props.draftMessage?.messageDetail.expiresDays &&
                setSentMessage({
                    ...sentMessage,
                    message: props.draftMessage.messageDetail.message,
                    expiresDays: props.draftMessage?.messageDetail.expiresDays,
                    authenticationMethod: props.draftMessage.messageDetail.authenticationMethod,
                });
        }
        dispatchRecipient({
            type: QnAActions.SET,
            payload: tempRecipients,
        });

        reloadMessageSecurityQuestions();
        dispatch(fetchAuthenticationMethodSettings());
        dispatch(fetchMessageSecurityQuestionSettings());

        tempRecipients.forEach(recipient => {
            dispatch(
                getRecipientDefaultQuestionAndAnswer(
                    recipient.emailAddress,
                    getUsersDefaultQuestion()?.messageSecurityQuestionId ?? 0,
                    (result: ISecurityQuestionAnswer) => {
                        let toData: IRecipientModel;
                        if (result && result?.securityQuestionId != null) {
                            const securityQuestion = securityQuestionState.securityQuestions.data.find(
                                x => x.messageSecurityQuestionId == result.securityQuestionId
                            );
                            toData = {
                                ...initialRecipientQuestionAnswer,
                                ...recipient,
                                questionId: securityQuestion?.messageSecurityQuestionId,
                                answer: result.answer,
                                question: securityQuestion?.question,
                                isModified: false,
                            };
                            if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                                setIsInvalidData(false);
                            }
                            dispatchRecipient({ type: QnAActions.UPDATE, payload: toData });
                        } else {
                            if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                                setIsInvalidData(true);
                            }
                        }
                    }
                )
            );
        });
    };

    useEffect(() => {
        dispatch(fetchUserSettings());

        const dropArea = document.getElementsByClassName("modal-content")[0];

        if (dropArea) {
            dropArea.addEventListener("dragenter", e => {
                e.preventDefault();
                e.stopPropagation();
                setShowDragAndDrop(true);
            });

            dropArea.addEventListener("dragleave", e => {
                e.preventDefault();
                e.stopPropagation();
                setShowDragAndDrop(false);
            });

            dropArea.addEventListener("dragover", e => {
                e.preventDefault();
                e.stopPropagation();
                setShowDragAndDrop(true);
            });

            dropArea.addEventListener("drop", (e: any) => {
                if (e?.target?.className === "dragging") {
                    setShowDragAndDrop(true);
                } else {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDragAndDrop(false);
                }
            });
        }
    }, [props.show]);

    useEffect(() => {
        if (userSettingsStore.userSettings && userSettingsStore.userSettings.authenticationMethodSettings) {
            if (props.messageAction !== MessageActions.Draft) {
                setSentMessage({
                    ...sentMessage,
                    authenticationMethod:
                        userSettingsStore?.userSettings?.authenticationMethodSettings.defaultAuthenticationMethod || 0,
                    expiresDays: getRetentionPeriodFromUserSettings(
                        userSettingsStore.userSettings.authenticationMethodSettings.defaultAuthenticationMethod
                    ),
                });
            }
        }
    }, [userSettingsStore?.userSettings?.authenticationMethodSettings]);

    useEffect(() => {
        handleSubjectChange(subject);
    }, [subject]);

    useEffect(() => {
        attachments.length > 0 && onUploadFile(attachments);
    }, [documentsRef.current.length]);

    useEffect(() => {
        validateOnChangeTab();
    }, [openTab, sentMessage.expiresDays]);

    useEffect(() => {
        confirmSend.sendAnyway && handleMessageSend();
    }, [confirmSend]);

    const reloadMessageSecurityQuestions = () => dispatch(fetchMessageSecurityQuestions(SettingMode.BOTH));

    const handleTabClick = (e: any, tab: MessageTab) => {
        e.stopPropagation();
        toggleTab(tab);
        if (tab === MessageTab.Compose) {
            logger.trackPageView(appInsightsData.Compose.ComposeTabTitle);
        } else if (tab === MessageTab.Options) {
            logger.trackPageView(appInsightsData.Compose.MessageOptionTabTitle);
        } else if (tab === MessageTab.Preview) {
            logger.trackPageView(appInsightsData.Compose.PreviewTabTitle);
        }
    };

    const handleMessageBodyChange = (value: any) => {
        setMessageBody(value);
        setSentMessage({
            ...sentMessage,
            message: value,
        });
    };

    const handleSubjectChange = (value: string) => {
        setSubject(value);
        setSentMessage({
            ...sentMessage,
            subject: value.trim(),
        });
    };

    const handleRecipientChange = (data: any, type: RecipientType) => {
        const email = data.option?.value?.trim();

        if (recipients.find((x: IRecipientModel) => x.emailAddress === email)) {
            return;
        }
        switch (data.action) {
            case "select-option":
            case "create-option":
                if (isValidEmailAddress(email)) {
                    const label =
                        isValidEmailAddress(data.option.label) && email.includes(data.option.label)
                            ? ""
                            : data.option.label;
                    let toData: IRecipientModel;
                    dispatch(
                        getRecipientDefaultQuestionAndAnswer(
                            email,
                            getUsersDefaultQuestion()?.messageSecurityQuestionId ?? 0,
                            (result: ISecurityQuestionAnswer) => {
                                if (result && result?.securityQuestionId != null) {
                                    const securityQuestion = securityQuestionState.securityQuestions.data.find(
                                        x => x.messageSecurityQuestionId == result.securityQuestionId
                                    );
                                    toData = {
                                        ...initialRecipientQuestionAnswer,
                                        firstName: label.split(" ")[0] ?? "",
                                        lastName: label.split(" ")[1] ?? "",
                                        emailAddress: email,
                                        recipientType: type,
                                        userId: getUserIdFromEmail(composeState.recipients.data, email),
                                        questionId: securityQuestion?.messageSecurityQuestionId,
                                        answer: result.answer,
                                        question: securityQuestion?.question,
                                        isModified: false,
                                    };
                                    if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                                        setIsInvalidData(false);
                                    }
                                    dispatchRecipient({ type: QnAActions.UPDATE, payload: toData });
                                } else {
                                    if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                                        setIsInvalidData(true);
                                    }
                                }
                            }
                        )
                    );

                    toData = {
                        ...initialRecipientQuestionAnswer,
                        firstName: label.split(" ")[0] ?? "",
                        lastName: label.split(" ")[1] ?? "",
                        emailAddress: email,
                        recipientType: type,
                        userId: getUserIdFromEmail(composeState.recipients.data, email),
                        questionId: getUsersDefaultQuestion().messageSecurityQuestionId,
                        isModified: false,
                        answer: "",
                        question: getUsersDefaultQuestion().question,
                    };
                    dispatchRecipient({ type: QnAActions.ADD, payload: toData });
                } else {
                    AppNotifier.Warning(ComposeValidations.INVALID_EMAIL_FORMAT);
                }
                break;
            case "remove-value":
            case "pop-value":
                if (data && data.removedValue) {
                    const emailAddress = data.removedValue.value;
                    dispatchRecipient({
                        type: QnAActions.REMOVE,
                        payload: { emailAddress },
                    });
                }
                break;
        }
    };

    const handleRecipientQuestionAnswer = (result: ISecurityQuestionAnswer) => {
        const recipient = recipients.find(x => x.emailAddress === result.recipientEmail);
        if (recipient && recipient.index) {
            const data: IRecipientModel = {
                firstName: recipient.firstName,
                lastName: recipient.lastName,
                emailAddress: recipient.emailAddress,
                questionId: result.securityQuestionId,
                question: securityQuestionState.securityQuestions.data.find(
                    x => x.messageSecurityQuestionId === result.securityQuestionId
                )?.question,
                answer: result.answer,
                recipientType: RecipientType.ToRecipient,
            };

            dispatchRecipient({
                type: QnAActions.SAVE,
                payload: { data, index: recipient.index },
            });
        }
    };

    const handleDraftRecipientQuestionAnswer = (result: ISecurityQuestionAnswer) => {
        const index = props.draftMessage?.recipients.findIndex(x => x.email === result.recipientEmail) || 0;
        const recipient = props.draftMessage?.recipients[index];
        if (recipient && recipient.email) {
            const data: IRecipientModel = {
                firstName: recipient.firstName,
                lastName: recipient.lastName,
                emailAddress: recipient.email,
                questionId: recipient.securityQuestionId,
                question: securityQuestionState.securityQuestions.data.find(
                    x => x.messageSecurityQuestionId === recipient.securityQuestionId
                )?.question,
                answer: recipient.securityAnswer,
                recipientType: RecipientType.ToRecipient,
            };

            dispatchRecipient({
                type: QnAActions.SAVE,
                payload: { data, index: index },
            });
        }
    };

    const handleOnDropFiles = (e: any, callback?: any) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles: IFileUploadModel[] = [...attachments];
        const files: File[] = e.target.files || e.dataTransfer.files;

        let totalSize = 0;
        Array.from(files).forEach((item: File) => {
            totalSize += item.size;
        });

        attachments.forEach(attachment => {
            totalSize += attachment.file.size;
        });

        const filesArray = Array.from(files);
        for(let i=0;i<filesArray.length;i++){
            const item = filesArray[i];
            if(!validateFileSize(item)){
                break;
            }
            if (!isMaxUploadSizeLimitExceeded(totalSize)) {
                break;
            }
            const guid = uuidv4();
            newFiles.push({
                file: item,
                uploadProgress: 0,
                guid,
                isUploaded: false,
                displayName: getFileName(item.name),
            });
            logger.trackEvent({
                name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.AttachmentAdded}`,
                properties: {
                    page: appInsightsData.Compose.ComposeTabTitle,
                    file: item,
                    guid,
                },
            });
        }
       
        setAttachments(newFiles);
        if (callback && typeof callback === "function") {
            callback();
        }
    };

    const onUploadFile = (documents: IFileUploadModel[]) => {
        if (props.messageAction == MessageActions.Draft && props.draftMessage!.messageDetail.createdDate) {
            onDraftUploadFile(documents);
            return;
        }
        for (let i = 0; i < documents.length; i++) {
            if (!documents[i].isUploaded) {
                const fileName = getFileName(documents[i].file.name);
                dispatch(
                    fetchUploadAttachmentSasLink(documents[i].guid, (data: any) => {
                        return fileUploadUtilities.uploadFile(
                            documents[i].file,
                            data,
                            fileName,
                            uploadProgressCallback,
                            uploadCompleteCallback
                        );
                    })
                );
            }
        }
    };

    const onDraftUploadFile = (documents: IFileUploadModel[]) => {
        for (let i = 0; i < documents.length; i++) {
            if (!documents[i].isUploaded) {
                const fileName = getFileName(documents[i].file.name);
                dispatch(
                    fetchUploadAttachmentSasLinkForDraft(
                        documents[i].guid,
                        props.draftMessage!.messageDetail.createdDate,
                        (data: any) => {
                            return fileUploadUtilities.uploadFile(
                                documents[i].file,
                                data,
                                fileName,
                                uploadProgressCallback,
                                uploadCompleteCallback
                            );
                        }
                    )
                );
            }
        }
    };

    const uploadProgressCallback = (percent: number, fileToUpload: any) => {
        const updatedState = documentsRef.current.map(item => {
            if (item.guid === fileToUpload.fileGUID) {
                item.uploadProgress = (percent == 100 ? percent - 10 : percent) / 100;
            }
            return item;
        });
        setAttachments(updatedState);
    };

    const uploadCompleteCallback = (fileToUpload: any, errorMessage?: string) => {
        if (fileToUpload.uploadFailed) {
            const attachments = documentsRef.current.filter(file => file.guid != fileToUpload.fileGUID);
            if (errorMessage && props.show && attachments.length < documentsRef.current.length) {
                AppNotifier.Warning(errorMessage);
            }
            setAttachments(attachments);
            return;
        }
        const updatedState = documentsRef.current.map(item => {
            if (item.guid === fileToUpload.fileGUID) {
                item.uploadProgress = 1;
                item.isUploaded = true;
            }
            return item;
        });
        setAttachments(updatedState);
    };

    const removeAttachmentFromState = (guid: string) => {
        let updatedFiles: IFileUploadModel[] = [...attachments];
        updatedFiles = updatedFiles.filter(x => x.guid !== guid);
        setAttachments(updatedFiles);
    };

    const handleOnRemoveAttachment = (guid: string, fileId?: number) => {
        if (!fileId) {
            //for files just uploaded for Draft, remove from draft location
            if (props.messageAction == MessageActions.Draft) {
                dispatch(
                    removeAttachmentForDraft(
                        guid,
                        props.draftMessage!.messageDetail.createdDate,
                        removeAttachmentFromState
                    )
                );
                return;
            }
            //for files just uploaded for Forward/Compose, remove from uploaded location
            dispatch(removeAttachment(guid, removeAttachmentFromState));
        }
        //for files that have been uploaded before and have an entry in DB (forward/draft), just remove from state
        else {
            removeAttachmentFromState(guid);
        }
    };

    const handleOnChangeRetention = (option: any) => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.RetentionChanged}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
                retention: option.value,
            },
        });
        const tempMessage: ISentMessageModel = {
            ...sentMessage,
            expiresDays: +option.value,
        };
        setSentMessage(tempMessage);
        setIsRetentionUpdated(true);
    };

    const handleOnChangeAuthentication = (e: any) => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.AuthenticationChanged}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
                value: e.value,
            },
        });
        let tempMessage: ISentMessageModel = {
            ...sentMessage,
        };
        if (!isRetentionUpdated) {
            tempMessage = {
                ...sentMessage,
                authenticationMethod: +e.value,
                expiresDays: getRetentionPeriodFromUserSettings(+e.value),
            };
        } else {
            tempMessage = {
                ...sentMessage,
                authenticationMethod: +e.value,
            };
        }
        setSentMessage(tempMessage);
    };

    const getRetentionPeriodFromUserSettings = (authenticationMethod: AuthenticationMethod) => {
        if (!profile) return 0;

        switch (authenticationMethod) {
            case AuthenticationMethod.ACCESSCODE:
                return getSelectedOption(profile?.userSettings?.retentionSettings.defaultRetentionPeriods.accessCode);
            case AuthenticationMethod.QUESTIONANSWER:
                return getSelectedOption(
                    profile?.userSettings?.retentionSettings.defaultRetentionPeriods.questionAndAnswer
                );
            // case AuthenticationMethod.LOGINPASSWORD:
            //   return getSelectedOption(
            //     profile?.userSettings?.retentionSettings.defaultRetentionPeriods
            //       .loginTransfer
            //   );
            default:
                return 0;
        }
    };

    const handleMessageSend = () => {
        const sentRecepients: ISentMessageRecipientModel[] = [];
        const questionAnswersForRecipients: ISecurityQuestionAnswer[] = [];

        recipients.map((item: IRecipientModel) => {
            sentRecepients.push({
                ...initialSentMessageRecipientModel,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.emailAddress,
                recipientType: item.recipientType,
                expiresDays: sentMessage.expiresDays,
                recipientId: item.userId || undefined,
                securityQuestion: item.question?.trim() ?? "",
                securityAnswer: item.answer?.trim() ?? "",
            });

            if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                questionAnswersForRecipients.push({
                    securityQuestionId: item.questionId ?? 0,
                    answer: item.answer?.trim() ?? "",
                    recipientEmail: item.emailAddress,
                    question: item.question?.trim() ?? "",
                });
            }
        });

        const tempAttachments: IFileModel[] = attachments.map(item => ({
            fileId: item.fileId,
            fileGuid: item.guid,
            isUploadComplete: item.isUploaded,
            name: getFileName(item.file.name),
            size: item.fileSize ?? item.file.size,
            uploadProgress: item.uploadProgress * 100,
        }));

        const message: ISentMailMessage = {
            messageDetail: sentMessage,
            recipients: sentRecepients,
            attachments: tempAttachments,
            recipientSecurityQuestions: questionAnswersForRecipients,
        };

        validateMessage(message);
    };

    const sendMailMessage = (message: ISentMailMessage) => {
        switch (props.messageAction) {
            case MessageActions.Reply:
            case MessageActions.ReplyAll:
                dispatch(
                    replySentMessage(
                        message,
                        "",
                        handleSuccess,
                        handleError,
                        props.messageAction === MessageActions.Reply
                            ? props.resourceLocators?.MsgDtlBtnReply
                            : props.resourceLocators?.MsgDtlBtnReplyAll
                    )
                );
                break;
            case MessageActions.Forward:
                props.onMessageForward && props.onMessageForward(message, handleSuccess, handleForwardMessageError);
                break;
            case MessageActions.New:
            case MessageActions.Draft:
                dispatch(
                    sendMessage(
                        message,
                        props.draftMessage?.messageDetail.draftedMessageId || 0,
                        handleSuccess,
                        handleError,
                        MessageActions.New
                            ? ResourceIdLocators.Global.NewMessageBtn
                            : props.resourceLocators?.MsgDtlSendMessage
                    )
                );
                break;
        }
    };

    const validateMessage = (message: ISentMailMessage) => {
        const validationMessage: IValidationMessage = validateComposeMessageModel(message, recipients);
        switch (validationMessage.validationType) {
            case ValidationType.WARNING:
                if (!confirmSend.sendAnyway) {
                    setConfirmSend({
                        show: true,
                        sendAnyway: false,
                        message: validationMessage.message,
                    });
                } else {
                    sendMailMessage(message);
                }
                break;
            case ValidationType.INVALID:
                AppNotifier.Warning(validationMessage.message);
                break;
            case ValidationType.VALID:
                sendMailMessage(message);
                break;
        }
    };

    const handleOnHideConfirmSend = () => {
        setConfirmSend({ show: false, sendAnyway: false, message: "" });
    };

    const handleSuccess = (messageId: number) => {
        if (messageId) {
            AppNotifier.Success(ComposeValidations.MESSAGE_SENT_SUCCESSFULLY);

            props.setReloadAction && props.setReloadAction(DraftMessageConstants.SEND_MESSAGE);
            props.onHide();
            setMessageBody("");
            setSubject("");
            dispatchRecipient({
                type: QnAActions.SET,
                payload: [],
            });
        }
    };

    const handleError = () => {
        AppNotifier.Error(ComposeValidations.MESSAGE_SEND_FAILED);
        setAttachments([]);
    };

    const handleForwardMessageError = () => {
        AppNotifier.Error(ComposeValidations.MESSAGE_SEND_FAILED);
        setAttachments(attachments => attachments.filter(a => a.fileId != undefined));
    };

    const handleAddRow = () => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.RecipientAddedInQA}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
            },
        });
        dispatchRecipient({
            type: QnAActions.ADD,
            payload: initialRecipientQuestionAnswer,
        });
    };

    const handleRemoveRow = (recipient: IRecipientModel) => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.RecipientAddedInQA}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
            },
        });
        dispatchRecipient({
            type: QnAActions.REMOVE,
            payload: { emailAddress: recipient.emailAddress, index: recipient.index },
        });
    };

    const handleEditRow = (recipient: IRecipientModel) => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.RecipientEditedInQA}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
            },
        });
        dispatchRecipient({
            type: QnAActions.EDIT,
            payload: { index: recipient.index },
        });
    };

    const handleSaveRow = (recipient: IRecipientModel, data: IRecipientModel) => {
        dispatchRecipient({
            type: QnAActions.SAVE,
            payload: { data, index: recipient.index },
        });
    };

    const handleAddSecurityQuestion = (
        securityQuestion: IMessageSecurityQuestion,
        isUserSecurityQuestion: boolean,
        callback?: any
    ) => {
        dispatch(addMessageSecurityQuestions(securityQuestion, isUserSecurityQuestion, callback));
    };

    const validateOnChangeTab = () => {
        if (openTab === MessageTab.Compose) {
            let isValid = true;
            if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                for (let i = 0; i < recipients.length; i++) {
                    isValid = validateRecipientQnA(recipients[i], false);
                }
            }
            if (sentMessage.expiresDays < 0) {
                isValid = false;
            }
            setIsInvalidData(!isValid);
        }
    };

    const getSelectedRetention = (): number => {
        let selectedRetention: number | undefined = sentMessage.expiresDays;
        if (!selectedRetention) {
            selectedRetention = composeState.authRetentions.data.find(x => x.isDefault)?.days;
        }

        return selectedRetention ?? 0;
    };

    const hideComposeModal = () => {
        if (getDraftModalVisibility()) {
            setShowDraftModal(true);
        } else {
            props.onHide();
        }
        setIsRetentionUpdated(false);
    };

    const clearState = () => {
        setMessageBody(props.messageBody ?? "");
        setSubject("");
        setSentMessage(initialSentMessage);
        dispatchRecipient({
            type: QnAActions.SET,
            payload: [],
        });
    };
    const getDraftModalVisibility = () => {
        if (
            props.messageAction === MessageActions.New &&
            (sentMessage.subject || sentMessage.message || recipients.length > 0 || attachments.length > 0)
        ) {
            return true;
        }
        if (props.messageAction === MessageActions.Draft) {
            if (
                props.draftMessage?.attachments.length !== attachments.length ||
                props.draftMessage?.recipients.length !== recipients.length
            ) {
                return true;
            } else if (
                sentMessage.subject !== props.draftMessage?.messageDetail.subject ||
                sentMessage.message !== props.draftMessage?.messageDetail.message
            ) {
                return true;
            } else {
                const show = recipients.every(recipient => {
                    const foundRecipient = props.draftMessage?.recipients.find(r => r.email === recipient.emailAddress);
                    return foundRecipient ? false : true;
                });
                return show;
            }
        } else {
            return false;
        }
    };

    const handleSaveToDraft = () => {
        const tempAttachments: IFileModel[] = attachments.map(item => ({
            fileId: item.fileId,
            fileGuid: item.guid,
            isUploadComplete: item.isUploaded,
            name: item.file.name,
            size: item.fileSize ?? item.file.size,
            uploadProgress: item.uploadProgress * 100,
        }));

        const allFilesUploaded = validateAllFilesUploaded(tempAttachments);
        if (!allFilesUploaded) {
            AppNotifier.Warning(ComposeValidations.ATTACHMENT_UPLOAD_INCOMPLETE_WARNING);
            return;
        }

        const draftRecipients: IDraftMessageRecipientModel[] = [];
        const questionAnswersForRecipients: ISecurityQuestionAnswer[] = [];

        recipients.map((item: IRecipientModel) => {
            draftRecipients.push({
                ...intialDraftMessageRecipientModel,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.emailAddress,
                recipientId: item.userId,
                securityQuestion: item.question ?? "",
                securityAnswer: item.answer ?? "",
                securityQuestionId: item.questionId ?? 0,
            });

            if (sentMessage.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
                questionAnswersForRecipients.push({
                    securityQuestionId: item.questionId ?? 0,
                    answer: item.answer ?? "",
                    recipientEmail: item.emailAddress,
                    question: "",
                });
            }
        });

        let initialMessage = initialDraftMessage;
        if (props.messageAction === MessageActions.Draft) {
            if (props.draftMessage && props.draftMessage.messageDetail) {
                initialMessage = { ...props.draftMessage.messageDetail };
            }
        }
        const draftMessage = {
            ...initialMessage,
            authenticationMethod: sentMessage.authenticationMethod,
            emailAddress: sentMessage.senderEmail,
            expiresDays: sentMessage.expiresDays,
            firstName: sentMessage.senderFirstName,
            lastName: sentMessage.senderLastName,
            message: sentMessage.message,
            subject: sentMessage.subject,
        };

        const message: IDraftMailMessage = {
            messageDetail: draftMessage,
            recipients: draftRecipients,
            attachments: tempAttachments,
            recipientSecurityQuestions: questionAnswersForRecipients,
        };

        if (props.messageAction === MessageActions.New) {
            // dipatch save draft action
            dispatch(
                saveDraftMessage(message, (res: number) => {
                    if (res) {
                        AppNotifier.Success(DraftMessageConstants.SUCCESS_MESSAGE);
                        props.setReloadAction && props.setReloadAction(DraftMessageConstants.SAVE_TO_DRAFT);
                        setShowDraftModal(false);
                        props.onHide();
                    }
                })
            );
        } else {
            // Dispatch update draft message
            dispatch(
                updateDraftMessage(message, (res: number) => {
                    if (res) {
                        AppNotifier.Success(DraftMessageConstants.SUCCESS_MESSAGE);
                        props.setReloadAction && props.setReloadAction(DraftMessageConstants.SAVE_TO_DRAFT);
                        setShowDraftModal(false);
                        props.onHide();
                    }
                })
            );
        }
    };

    const hideDraftModal = () => {
        // close modal
        setShowDraftModal(false);
    };

    const handleDiscardButtonClick = () => {
        setShowDraftModal(false);
        clearState();
        props.onHide();
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.DiscardDraftButtonClick}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
            },
        });

        // Delete the files uploaded when modal is closed and message is not sent or drafted
        attachments &&
            attachments.length > 0 &&
            attachments.map((attachment: IFileUploadModel) => {
                const attachmentFound = props.draftMessage?.attachments.find(a => a.fileGuid === attachment.guid);
                !attachmentFound && dispatch(removeAttachment(attachment.guid));
            });
    };

    return (
        <>
            <Modal className="overlayPopup compose-modal" show={props.show} onHide={hideComposeModal} size="lg">
                <Modal.Header closeButton closeVariant="white">
                    {props.title}
                </Modal.Header>
                <Modal.Body id="droppable">
                    <div className="compose-left-panel">
                        <div
                            className={`side-menu-button ${openTab === MessageTab.Compose ? "active" : ""}`}
                            title="Compose Options"
                            onClick={e => handleTabClick(e, MessageTab.Compose)}
                        >
                            <ComposeIcon
                                width={26}
                                height={26}
                                color={openTab === MessageTab.Compose ? "#88c656" : ""}
                            />
                        </div>
                        <div
                            className={`side-menu-button ${openTab === MessageTab.Options ? "active" : ""}`}
                            title="Message Options"
                            onClick={e => handleTabClick(e, MessageTab.Options)}
                        >
                            <ComposeSettingIcon
                                width={36}
                                height={36}
                                color={openTab === MessageTab.Options ? "#88c656" : ""}
                            />
                            {isInvalidData && <span className="fas fa-exclamation compose-tab-invalid"></span>}
                        </div>
                    </div>
                    <div className="compose-content">
                        <div className="padding-10-px">
                            {openTab === MessageTab.Compose && (
                                <>
                                    <div className="heading-black-1 margin-top-5-px">Compose Message</div>
                                    <div className="margin-top-20-px">
                                        <Recipient
                                            setRecipient={handleRecipientChange}
                                            toRecipients={recipients.filter(
                                                x =>
                                                    x.recipientType === RecipientType.ToRecipient &&
                                                    isValidEmailAddress(x.emailAddress)
                                            )}
                                            ccRecipients={recipients.filter(
                                                x => x.recipientType === RecipientType.CcRecipient
                                            )}
                                        />
                                    </div>
                                    <Form.Group className="subject-wrapper">
                                        <Form.Label
                                            htmlFor="subject"
                                            aria-label="Subject"
                                            data-testid={"subject-label"}
                                        >
                                            Subject
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="subject"
                                            value={subject}
                                            data-testid={"subject-input"}
                                            maxLength={MAX_SUBJECT_LENGTH}
                                            onChange={event => handleSubjectChange(event.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="compose-body-message drop-area">
                                        {showDragAndDrop && (
                                            <div
                                                className="dragging"
                                                onDrop={e => {
                                                    handleOnDropFiles(e);
                                                }}
                                            >
                                                Drop file(s) here
                                            </div>
                                        )}
                                        {!showDragAndDrop && (
                                            <TinyMCEComponent
                                                messageBody={messageBody}
                                                changeStateTinymceBody={e => handleMessageBodyChange(e)}
                                                readOnly={0}
                                                onDragOver={() => setShowDragAndDrop(true)}
                                            />
                                        )}
                                        <AttachmentUploader onDropFiles={handleOnDropFiles} />
                                        <div className="compose-body-attachments">
                                            <AttachmentList
                                                attachments={attachments}
                                                onRemoveAttachment={handleOnRemoveAttachment}
                                                isRemovingAttachment={composeState.isRemovingAttachment}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {openTab === MessageTab.Options && (
                                <>
                                    <div className="heading-black-1 margin-top-5-px">Message Options</div>
                                    <ComposeOptions
                                        authRetentions={composeState.authRetentions}
                                        messageAction={props.messageAction}
                                        isAuthMethodLoading={composeState.isAuthMethodLoading}
                                        securityQuestions={securityQuestionState.securityQuestions}
                                        onChangeRetention={handleOnChangeRetention}
                                        onChangeAuthentication={handleOnChangeAuthentication}
                                        recipientQuestionAnswer={recipients}
                                        selectedAuthMethod={sentMessage.authenticationMethod}
                                        fromComposeMessage={props.messageAction === MessageActions.New ? true : false}
                                        selectedRetention={getSelectedRetention()}
                                        isAuthMethodUpdated={isAuthMethodUpdated}
                                        setIsAuthMethodUpdated={setIsAuthMethodUpdated}
                                        onAddRow={handleAddRow}
                                        onRemoveRow={handleRemoveRow}
                                        onEditRow={handleEditRow}
                                        onSaveRow={handleSaveRow}
                                        addSecurityQuestion={handleAddSecurityQuestion}
                                        reloadMessageSecurityQuestions={reloadMessageSecurityQuestions}
                                    />
                                </>
                            )}
                            {openTab === MessageTab.Preview && (
                                <div className="compose-preview-wrapper">
                                    <div className="compose-preview-title">
                                        <div className="heading-black-1">Preview</div>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-auto-test={AutomationIdConstants.addFolderModal.HeaderCloseBtn}
                                            aria-label="Close"
                                            onClick={e => handleTabClick(e, MessageTab.Compose)}
                                        ></button>
                                    </div>
                                    <ComposePreview
                                        subject={subject}
                                        messageBody={messageBody}
                                        toRecipients={recipients.filter(
                                            x => x.recipientType === RecipientType.ToRecipient
                                        )}
                                        ccRecipients={recipients.filter(
                                            x => x.recipientType === RecipientType.CcRecipient
                                        )}
                                        attachments={attachments}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="button-primary-blue-2"
                        data-resource-id={props.resourceLocators?.MsgDtlSendMessage}
                        onClick={handleMessageSend}
                        disabled={composeState.isSending}
                    >
                        Send
                    </button>
                    <div>
                        {showSaveDraftOption && (
                            <button className="button-primary-blue-2 save-draft-button" onClick={handleSaveToDraft}>
                                Save draft
                            </button>
                        )}

                        {openTab !== MessageTab.Preview && (
                            <button
                                className="button-primary-blue-2-outlined"
                                onClick={e => handleTabClick(e, MessageTab.Preview)}
                            >
                                Preview
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
            <CustomModal
                show={confirmSend.show}
                onSubmit={() => setConfirmSend({ show: false, sendAnyway: true, message: "" })}
                confirmButtonName="Send anyway"
                cancelButtonName="Don't Send"
                onHide={handleOnHideConfirmSend}
            >
                {confirmSend.message}
            </CustomModal>
            <LoadingModal show={composeState.isSending}>
                <div className="loading-message">Sending Message...</div>
            </LoadingModal>
            <LoadingModal show={draftMessageState.isSending}>
                <div className="loading-message">{DraftMessageConstants.SAVING_MESSAGE}</div>
            </LoadingModal>
            {
                <CustomModal
                    confirmButtonName="Save"
                    cancelButtonName="Discard"
                    title={DraftMessageConstants.DRAFT_MODAL_TITLE}
                    show={showDraftModal}
                    onSubmit={handleSaveToDraft}
                    onHide={hideDraftModal}
                    onCancelButtonClick={handleDiscardButtonClick}
                >
                    {DraftMessageConstants.DRAFT_MODAL_TEXT}
                </CustomModal>
            }
        </>
    );
};

export default ComposeModal;
