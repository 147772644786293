export const appInsightsData = {
    MailBox: {
        CommonOperations: {
            Delete: "Deleted Message",
            MoveTo: "Move To",
            MoveToInbox: "Move To Inbox",
            DownloadSingleAttachment: "Download Single Attachment",
            DownloadAllAttachments: "Download All Attachments",
            cancelTransfer: "Cancel Transfer",
            resendAccessLink: "Resend Access link",
            markAsNotSpam: "Mark as not a Spam",
            changeExpiry: "Change Expiry",
        },

        Inbox: {
            PageTitle: "Inbox Page",
        },
        Draft: {
            PageTitle: "Draft Page",
        },
        Sent: {
            PageTitle: "Sent Page",
        },
        Spam: {
            PageTitle: "Spam Page",
        },
    },
    Folders: {
        MyFiles: {
            PageTitle: "My Files page",
            Operations: {
                DeleteFolder: "Deleted Folder",
                updateFolderRetention: "Update Folder Retention",
                updateFolderName: "Update Folder Name",
                addNewFolder: "Add New Folder",
                shareFolder: "Share Folder With Users",
                removeSharedFolderUser: "Remove Shared Folder User",
                updateSharedFolderUserPermission: "Update Shared Folder User Permission",
            },
        },
    },
    Unlock: {
        PageTitle: "Unlock Page",
        UnlockLinkClicked: "Unlock Link Button Clicked",
    },
    MessageHistory: {
        SentItems: {
            PageTitle: "Sent Items History Page",
        },
        ReceivedItems: {
            PageTitle: "Received Items History Page",
        },
        DocumentRequest: {
            PageTitle: "Document Request History Page",
        },
        Operation: {
            TableRefreshed: "Table Refreshed",
            ColumnVisibilityChanged: "Column Visibility Changed",
            ColumnOrderChanged: "Column Order Changed",
            ColumnUpdateApplyClicked: "Column Update Apply Clicked",
            SavedFilterSelected: "Saved Filter Selected",
            FilterCleared: "Filter Cleared",
            FilterDeleted: "Filter Deleted",
            FilterSenderListUpdated: "Filter Sender List Updated",
            FilterDateUpdated: "Filter Date Updated",
            FilterLinkStatusUpdated: "Filter Link Status Updated",
            FilterTransferModeUpdated: "Filter Transfer Mode Updated",
            FilterActivityLogDateUpdated: "Filter Activity Log Date Updated",
            FilterCustomMessageFromDateUpdated: "Filter Custom Message From Date Updated",
            FilterCustomMessageToDateUpdated: "Filter Custom Message To Date Updated",
            FilterCustomActivityLogFromDateUpdated: "Filter Custom Activity Log From Date Updated",
            FilterCustomActivityLogToDateUpdated: "Filter Custom Activity Log To Date Updated",
            FilterSaved: "Filter Saved",
            FilterApplied: "Filter Applied",
            FilterUpdated: "Saved Filter Updated",
        },
    },
    AdminSettings: {
        AuthenticationQuestions: {
            PageTitle: "Admin Authentication Questions Page",
        },
        MessageOptions: {
            PageTitle: "Admin Message Options page",
        },
        SupportContact: {
            PageTitle: "Admin Support Contact Page",
        },
        MessageHistory: {
            PageTitle: "Admin Message History Page",
        },
        DropOff: {
            DropOffConfig: {
                PageTitle: "Admin Drop Off Config Page",
            },
            DropOffUsers: {
                PageTitle: "Admin Drop Off Users Page",
            },
        },
    },
    Help: {
        PageTitle: "Help Page",
    },
    Compose: {
        PageTitle: "Compose",
        ComposeTabTitle: "Compose Tab",
        MessageOptionTabTitle: "Compose Message Option Tab",
        PreviewTabTitle: "Compose Preview Tab",
        Operation: {
            SendButtonClick: "Send Button Clicked",
            SaveDraftButtonClick: "Save Draft Button Clicked",
            AttachmentAdded: "Attachment Added",
            AttachmentRemoved: "Attachment Removed",
            RetentionChanged: "Retention Changed",
            AuthenticationChanged: "Authentication Changed",
            RecipientAddedInQA: "Recipient Added in QA Auth method",
            RecipientRemovedInQA: "Recipient Removed in QA Auth method",
            RecipientEditedInQA: "Recipient Edited in QA Auth method",
            AuthMethodDefaultButtonClicked: "Auth Method Default Button Clicked",
            MessageSecurityQuestionAdded: "Message Security Question Added",
            DiscardDraftButtonClick: "Discard Draft Button Clicked",
        },
    },
    Forward: {
        PageTitle: "Forward",
    },
    Reply: {
        PageTitle: "Reply",
    },
    ViewInbox: {
        PageTitle: "View Others Inbox Dropdown",
        Operation: {
            UserClick: "User Clicked",
        },
    },
    ReportProblem: {
        PageTitle: "Report a Problem",
        Operation: {
            AttachmentAdded: "Attachment Added",
            AttachmentRemoved: "Attachment Removed",
            SubmitButtonClick: "Submit Button Clicked",
        },
    },
    MySettings: {
        PageTitle: "My Settings",
        AuthQuestions: {
            PageTitle: "Authentication Questions",
        },
        MessageOption: {
            PageTitle: "Message Option",
        },
        MessageDelegation: {
            PageTitle: "Message Delegation",
        },
    },
    MyAccount: {
        PageTitle: "My Account",
        ProfilePageTitle: "Profile Page",
        PasswordPageTitle: "Password Page",
        Operation: {
            ProfileFormSaveClick: "Profile Form Save Button Clicked",
            UpdatePasswordClick: "Update Password Button Clicked",
            VerifyMobileNumberClick: "Verify Mobile Number Button Clicked",
        },
    },
    LoginHistory: {
        PageTitle: "Login History",
        Operation: {
            ReloadClicked: "Reload Clicked",
            PromptToggleEnabled: "Prompt Toggle Enabled",
            PromptToggleDisabled: "Prompt Toggle Disabled",
        },
    },
    DropOFf: {
        PageTitle: "Drop Off Links",
        Operation: {
            CompanyLinkCopied: "Company Link Copied",
            PersonalLinkCopied: "Personal Link Copied",
            CompanyLinkClicked: "Company Link Clicked",
            PersonalLinkClicked: "Personal Link Clicked",
        },
    },
    FileUploader: {
        PageTitle: "File Uploader",
    },

    RequestDocument: {
        PageTitle: "Request Document",
        NewRequest: "New Request Button",
        ExitRequest: "Exit Request Button",
        RequestMore: "RequestMore Button",
        Operation: {
            To: "Recipent Name",
            NewRequestSendRequestClick: "New Send Request Clicked",
            RequestMoreSendRequestClick: "Request More Clicked",
            Cancel: "Cancel Button Clicked",
            RequestListDelete: "Request List Delete clicked",
            RequestDetailDelete: "Request Delete Button clicked",
            RetentionPeriod: "Changing Retention Period",
            SendReminder: "Sending Reminder",
            Status: "Changing Status",
            Filter: "Sort By and Filter By",
            Serach: "Search By Email",
            FileSpecicficReminder: "File Reminder",
            DocumentSendReminder: "Document Reminder",
            DocumentDownload: "Download Clicked",
            Preview: "Preview button Clicked",
            ResendRequest: "Resend Request Icon Clicked",
            ResendDocumentAccesLink: "Resend Document Access Link Clicked",
            DownloadAll: "Download files",
            DownloadFile: "Download Single File",
            DeleteFile: "Delete Uploaded File",
            DocumentRequestViewed: "Document Request detail viewed",
            NotifyOnComplete: "Notify on complete",
        },
    },
};
