import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import { DocumentCategoryModalConstants, ValidationContants } from "../../../../../helper/Constants";
import { IDocumentCategoryModel } from "../../../../../core/viewModels/settings/DocumentCategory";
import { LoadingOverlay } from "react-overlay-loader";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";

type AddCategoryModalProps = {
    data?: IDocumentCategoryModel;
    show: boolean;
    onClose: () => void;
    isUpdate: boolean;
    handleCategoryChange: (data: IDocumentCategoryModel) => void;
};

const AddCategoryModal = ({ show, onClose, isUpdate, handleCategoryChange, data }: AddCategoryModalProps) => {
    const [documentCategory, setDocumentCategory] = React.useState("");
    const [validationMessage, setValidationMessage] = React.useState("");
    const { userId } = useSelector((state: ApplicationState) => state.userProfile);
    const modalTitle = isUpdate
        ? DocumentCategoryModalConstants.EDIT_CATEGORY_MODAL_TITLE
        : DocumentCategoryModalConstants.ADD_CATEGORY_MODAL_TITLE;

    useEffect(() => {
        if (isUpdate && data) {
            setDocumentCategory(data.name);
        }
    }, []);

    const onSubmit = () => {
        if (documentCategory.trim().length < 1) {
            setValidationMessage(ValidationContants.DocumentCategoryWarning);
            return;
        }
        const category: IDocumentCategoryModel = {
            documentCategoryId: data?.documentCategoryId || 0,
            name: documentCategory.trim(),
            createdDate: new Date(),
            createdBy: userId,
            count: 0,
        };
        handleCategoryChange(category);
    };

    const closeModal = () => {
        setDocumentCategory("");
        onClose();
    };

    return (
        <CustomModal
            show={show}
            onHide={closeModal}
            onSubmit={onSubmit}
            cancelButtonName="Cancel"
            confirmButtonName="Ok"
            title={modalTitle}
            className="settings-modal"
        >
            <LoadingOverlay>
                <Form className="settings-form" onSubmit={e => e.preventDefault()} validated={validationMessage !== ""}>
                    <Form.Group className="marB15" controlId="AddSenderId">
                        <Form.Label>Document Category Name</Form.Label>
                        <Form.Control
                            onChange={e => setDocumentCategory(e.target.value)}
                            value={documentCategory}
                            className="add-sender-input"
                            type="text"
                            placeholder="Enter Category Name"
                            minLength={1}
                            maxLength={50}
                        />
                        <h6 className="text-danger">{validationMessage}</h6>
                    </Form.Group>
                </Form>
            </LoadingOverlay>
        </CustomModal>
    );
};

export default AddCategoryModal;
