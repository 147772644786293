import { Action, Reducer } from "redux";
import { produce } from "immer";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { initialReceivedMessage, IReceivedMessageModel } from "../core/viewModels/message/ReceivedMessageModel";
import * as SpamMessageActions from "../actionCreators/spamMessageActionCreators";
import { actionTypes } from "../types/spamMessageTypes";
import { IReceivedMailMessage } from "../core/viewModels/message/ReceivedMailMessageModel";

export interface ISpamMessageState {
  data: IMessageResponseModel<IReceivedMessageModel>;
  isLoading: boolean;
  mailData: {
    data: IReceivedMailMessage;
    isLoading: boolean;
  };
}

const initialState: ISpamMessageState = {
  isLoading: false,
  data: {
    messages: [],
    totalRecords: 0,
  },
  mailData: {
    data: {
      messageDetail: initialReceivedMessage,
      attachments: [],
      recipients: [],
      recipientSecurityQuestions: []
    },
    isLoading: false,
  },
};

export const reducer: Reducer<ISpamMessageState> = produce(
  (draft: ISpamMessageState = initialState, incomingAction: Action) => {
    const action = incomingAction as SpamMessageActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_SPAM_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_SPAM_MESSAGES:
        draft.isLoading = false;
        draft.data = action.payload;
        return draft;

      case actionTypes.REQUEST_MARK_AS_NOT_SPAM:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_MARK_AS_NOT_SPAM:
        draft.isLoading = false;
        return draft;

      case actionTypes.REQUEST_MOVE_TO_INBOX:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_MOVE_TO_INBOX:
        draft.isLoading = false;
        return draft;

        case actionTypes.REQUEST_SPAM_MESSAGE_DETAIL:
          draft.mailData.isLoading = true;
          return draft;
  
        case actionTypes.RECEIVED_SPAM_MESSAGE_DETAIL:
          draft.mailData.isLoading = false;
          draft.mailData.data = action.payload;
          return draft;
  
        case actionTypes.COMPLETED_SPAM_MESSAGE_DETAIL:
          draft.mailData.isLoading = false;
          return draft;

      default:
        return draft;
    }
  }
);
