import React from "react";
import Modal from "react-bootstrap/Modal";
import { LoadingOverlay, Loader } from "react-overlay-loader";

interface ILoadingModalProps {
  show: boolean;
  title?: string;
  children?: React.ReactNode;
  size?: any;
  className?: string;
}

const LoadingModal: React.FC<ILoadingModalProps> = (props) => {
  return (
    <Modal
      className={"overlayPopup " + props.className}
      show={props.show}
      size={props.size || "sm"}
    >
      {props.title && (
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <LoadingOverlay style={{ height: "70px" }}>
          <Loader classNamePrefix="sm" text="" loading={true} />
        </LoadingOverlay>
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
