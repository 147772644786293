import React, { useEffect } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import {
    addDocumentCategory,
    deleteDocumentCategory,
    getDocumentCategoriesDropdown,
    getDocumentCategory,
    updateDocumentCategory,
} from "../../../../../actionCreators/documentCategoryActionCreator";
import {
    IDocumentCategoryModel,
    IDocumentCategoryRequest,
    initialCategoryRequest,
} from "../../../../../core/viewModels/settings/DocumentCategory";
import { DocumentCategoryConstants } from "../../../../../helper/Constants";
import { ApplicationState } from "../../../../../store";
import { DeleteIcon, EnableDeleteIcon } from "../../../../svg/IconCollection";
import AddCategoryModal from "./AddCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import DocumentCategoryTable from "./DocumentCategoryTable";
import { DocumentCategorySortColumn, SortOrder } from "../../../../../core/common/enums";
import { SortOrder as TableSortOrder } from "react-bootstrap-table-next";
const DocumentCategory = () => {
    const [showAddModal, setShowAddModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const { requestCategoriesTableData, isLoading } = useSelector((state: ApplicationState) => state.documentCategoryState);
    const [selectedItems, setSelectedItems] = React.useState<IDocumentCategoryModel[]>([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [sortOrder, setSortOrder] = React.useState<TableSortOrder>("desc");
    const [sortField, setSortField] = React.useState<string|null>(null);

    const dispatch = useDispatch();

    const getCategory = (payload: IDocumentCategoryRequest) => {
        dispatch(getDocumentCategory(payload));
    };
    useEffect(() => {
        getCategory(initialCategoryRequest);
        dispatch(getDocumentCategoriesDropdown());
    }, []);

    const onModalClose = () => {
        setShowAddModal(false);
        setShowDeleteModal(false);
        setShowEditModal(false);
        setSelectedItems([]);
    };

    const onCategoryAdd = (data: IDocumentCategoryModel) => {
        onModalClose();
        dispatch(
            addDocumentCategory(data, () => {
                getCategory({...initialCategoryRequest,
                    sortColumn: sortField === "name" ? DocumentCategorySortColumn.Name : DocumentCategorySortColumn.CreatedDate,
                    sortOrder: sortOrder === "asc"? SortOrder.ASC : SortOrder.DESC,
                    pageNumber});
            })
        );
    };

    const onCategoryUpdate = (data: IDocumentCategoryModel) => {
        onModalClose();
        dispatch(
            updateDocumentCategory(data, () => {
                getCategory({...initialCategoryRequest,
                    sortColumn: sortField === "name" ? DocumentCategorySortColumn.Name : DocumentCategorySortColumn.CreatedDate,
                    sortOrder: sortOrder === "asc"? SortOrder.ASC : SortOrder.DESC,
                    pageNumber});
                setSelectedItems([]);
            })
        );
    };

    const onCategoryDelete = () => {
        onModalClose();
        const payload = selectedItems.map(x => x.documentCategoryId);
        dispatch(
            deleteDocumentCategory(payload, () => {
                getCategory({...initialCategoryRequest,
                    sortColumn: sortField === "name" ? DocumentCategorySortColumn.Name : DocumentCategorySortColumn.CreatedDate, 
                    sortOrder: sortOrder === "asc"? SortOrder.ASC : SortOrder.DESC,
                    pageNumber});
            })
        );
    };

    const handleTableSorting = (sortField: DocumentCategorySortColumn, sortOrder: SortOrder, page: number) => {
        dispatch(getDocumentCategory({ ...initialCategoryRequest, sortColumn: sortField, sortOrder: sortOrder, pageNumber: page }));
    };
    return (
        <LoadingOverlay className="category-table-container">
            <>
                <header>
                    <span>{DocumentCategoryConstants.DOCUMENT_CATEGORY_HELPER_TEXT}</span>
                </header>
                <div className="category-wrapper">
                    <div className="btn-container">
                        <button
                            className={selectedItems.length === 0 ? "delete-btn-disabled" : "delete-btn"}
                            onClick={() => setShowDeleteModal(true)}
                        >
                            {selectedItems.length == 0 ? <DeleteIcon /> : <EnableDeleteIcon />}
                        </button>
                        <button
                            className="btn add-btn"
                            onClick={() => {
                                setSelectedItems([]);
                                setShowAddModal(true);
                            }}
                        >
                            {DocumentCategoryConstants.ADD_DOCUMENT_CATEGORY}
                        </button>
                    </div>
                </div>
                <DocumentCategoryTable
                    data={requestCategoriesTableData}
                    isLoading={isLoading}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    count={requestCategoriesTableData[0]?.count}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowEditModal={setShowEditModal}
                    handleTableSorting={handleTableSorting}
                    fetchCategories={getCategory}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    sortField={sortField}
                    setSortField={setSortField}
                />

                {showAddModal && (
                    <AddCategoryModal
                        show={showAddModal}
                        onClose={onModalClose}
                        isUpdate={false}
                        handleCategoryChange={onCategoryAdd}
                    />
                )}

                {selectedItems && selectedItems.length > 0 && showDeleteModal && (
                    <DeleteCategoryModal
                        show={showDeleteModal}
                        onClose={onModalClose}
                        handleSubmit={onCategoryDelete}
                        isMultiple={selectedItems.length > 1}
                    />
                )}

                {selectedItems && selectedItems.length == 1 && showEditModal && (
                    <AddCategoryModal
                        show={showEditModal}
                        onClose={onModalClose}
                        isUpdate={true}
                        handleCategoryChange={onCategoryUpdate}
                        data={selectedItems[0]}
                    />
                )}
                <Loader loading={isLoading} />
            </>
        </LoadingOverlay>
    );
};

export default DocumentCategory;
