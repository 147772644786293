import React, { useState } from "react";
import { DocumentToolbarConstants, MessageToolbarConstants } from "../../../helper/Constants";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import ConfirmModal from "../../common/ui/Modals/ConfirmModal";
import { DeleteIcon, SendLinkIcon } from "../../svg/ToolbarIcons";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { BellIcon } from "../../svg/IconCollection";
import { IDocumentRequestListModel } from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { DetailedStatus } from "../../../core/common/enums";
import { isDelegatedUser } from "../../../helper/HelperFunctions";

interface IDocumentToolbarProps {
    isAllSelected: boolean;
    isAnySelected: boolean;
    showSelectAllCheckbox: boolean;
    selectedDocumentList: IDocumentRequestListModel[];
    handleOnSelectAll: (isSelect: boolean) => void;
    resetSelection: () => void;
    handleDeleteDocumentRequest: () => void;
    handleDocumentResendAccessLink: () => void;
    handleDocumentSendReminder: () => void;
}

export const DocumentToolbar: React.FC<IDocumentToolbarProps> = ({
    isAllSelected,
    isAnySelected,
    selectedDocumentList,
    handleOnSelectAll,
    showSelectAllCheckbox,
    handleDeleteDocumentRequest,
    handleDocumentResendAccessLink,
    handleDocumentSendReminder,
}) => {
    const [showDelete, toggleShowDelete] = useState(false);
    const isSendReminderEnabled = isAnySelected
        ? selectedDocumentList.length === 1
            ? selectedDocumentList[0].status !== DetailedStatus.COMPLETED
            : true
        : false;
    const checkBoxChangeHandler = (e: any) => {
        handleOnSelectAll(e.target.checked);
    };
    const deleteDocumentRequestSubmit = () => {
        handleDeleteDocumentRequest();
        toggleShowDelete(false);
    };
    return (
        <div className="message-toolbar">
            <div
                style={{
                    visibility: showSelectAllCheckbox ? "inherit" : "hidden",
                }}
                className="message-toolbar-action"
            >
                <Checkbox
                    id="mpiCheckbox"
                    text=""
                    size={CheckBoxSize.sm}
                    className="message-toolbar-checkbox"
                    checked={isAllSelected || isAnySelected}
                    onChange={checkBoxChangeHandler}
                    indeterminate={isAllSelected ? false : isAnySelected}
                />
            </div>
            {!isDelegatedUser() && (
                <div className="message-toolbar-action">
                    <span
                        className={!isAnySelected ? "toolbar-icon-disabled" : ""}
                        data-resource-id={ResourceIdLocators.DRL.DRLListBtnDeleteRequest}
                        title={DocumentToolbarConstants.TITLE_DELETE}
                        onClick={() => isAnySelected && toggleShowDelete(true)}
                    >
                        <DeleteIcon width={17} height={17} color="#606060" />
                    </span>
                </div>
            )}

            <div className="message-toolbar-action">
                <span
                    className={!isAnySelected ? "toolbar-icon-disabled" : ""}
                    data-resource-id={ResourceIdLocators.DRL.DRLListBtnResendAccess}
                    title={DocumentToolbarConstants.TITLE_RESEND_ACCESS_LINK}
                    onClick={() => isAnySelected && handleDocumentResendAccessLink()}
                >
                    <SendLinkIcon width={22} height={17} color="#606060" />
                </span>
            </div>

            <div className="message-toolbar-action">
                <span
                    className={!isSendReminderEnabled ? "toolbar-icon-disabled" : ""}
                    title={DocumentToolbarConstants.TITLE_SEND_REMINDER}
                    data-resource-id={ResourceIdLocators.DRL.DRLListBtnSendReminder}
                    onClick={() => isSendReminderEnabled && handleDocumentSendReminder()}
                >
                    <BellIcon width={22} height={17} color="#606060" />
                </span>
            </div>

            <ConfirmModal
                className="delete-message-modal"
                show={showDelete}
                title={DocumentToolbarConstants.DELETE_REQUEST}
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                onSubmit={deleteDocumentRequestSubmit}
                onHide={() => toggleShowDelete(false)}
                size="sm"
            >
                <span>{DocumentToolbarConstants.LISTING_DELETE_REQUEST}</span>
            </ConfirmModal>
        </div>
    );
};
