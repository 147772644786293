import * as React from "react";
import { ColorConstants } from "../../assets/custom/colors";

export type MenuIcon = "DropOffLink";

export interface IMenuIconProps {
    height?: number;
    width?: number;
    color?: string;
    disabled?: boolean;
    icon?: string;
}

export const HeaderMenuIcons: React.FC<IMenuIconProps> = props => {
    let MenuIcon;
    switch (props.icon) {
        case "DropOffLink":
            MenuIcon = DropOffLinkIcon;
            break;
        case "UserIcon":
            MenuIcon = UserIcon;
            break;
        case "LogOutIcon":
            MenuIcon = LogOutIcon;
            break;
        case "MySettingsIcon":
            MenuIcon = MySettingsIcon;
            break;
        case "LoginHistoryIcon":
            MenuIcon = LoginHistoryIcon;
            break;
        case "MyDownloads":
            MenuIcon = DownloadIcon;
            break;
        default:
            MenuIcon = UserIcon;
    }
    return <MenuIcon {...props}></MenuIcon>;
};

export const DownloadIcon: React.FC<IMenuIconProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7 7-7Z" fill={props.color} />
        </svg>
    );
};

export const DropOffLinkIcon: React.FC<IMenuIconProps> = props => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={props.width}
            height={props.height}
            viewBox="0 0 485 485"
            enableBackground="new 0 0 485 485;"
            xmlSpace="preserve"
            fill={props.color}
        >
            <g>
                <path
                    d="M425,199.546V27.514H0v315.542h285.592v14.726c0,54.977,44.728,99.704,99.704,99.704S485,412.759,485,357.782v-87.686
                    C485,234.592,458.975,205.051,425,199.546z M345.1,249.458l-71.294-64.173L395,76.197v124.865
                    C371.164,207.466,352.213,225.918,345.1,249.458z M151.195,185.285L30,294.373V76.197L151.195,185.285z M54.086,57.514h316.828
                    L212.5,200.104L54.086,57.514z M455,357.782c0,38.435-31.27,69.704-69.704,69.704s-69.704-31.269-69.704-69.704v-44.726H54.086
                    l119.53-107.589l38.884,35l38.885-35l90.676,81.618v70.698c0,23.839,19.396,43.234,43.235,43.234s43.234-19.395,43.234-43.234
                    v-87.687h-30v87.687c0,7.297-5.937,13.234-13.234,13.234s-13.235-5.937-13.235-13.234v-87.687c0-22.866,18.604-41.469,41.47-41.469
                    S455,247.23,455,270.096V357.782z"
                />
            </g>
        </svg>
    );
};

export const UserIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 285.5 285.5"
        enableBackground="new 0 0 285.5 285.5"
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        fill={props.color}
    >
        <path d="M142.75 125.5c34.601 0 62.751-28.149 62.751-62.75S177.351 0 142.75 0 79.999 28.149 79.999 62.75s28.15 62.75 62.751 62.75zm0-95.5c18.059 0 32.751 14.691 32.751 32.75S160.809 95.5 142.75 95.5s-32.751-14.691-32.751-32.75S124.691 30 142.75 30zM142.75 155.5c-63.411 0-115 51.589-115 115 0 8.284 6.716 15 15 15h200c8.284 0 15-6.716 15-15 0-63.411-51.589-115-115-115zm-83.675 100c7.106-39.739 41.923-70 83.675-70s76.569 30.261 83.675 70H59.075z" />
    </svg>
);

export const LogOutIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 471.2 471.2"
        enableBackground="new 0 0 471.2 471.2"
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        fill={props.color}
    >
        <path d="M227.619 444.2h-122.9c-33.4 0-60.5-27.2-60.5-60.5V87.5c0-33.4 27.2-60.5 60.5-60.5h124.9c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5h-124.9c-48.3 0-87.5 39.3-87.5 87.5v296.2c0 48.3 39.3 87.5 87.5 87.5h122.9c7.5 0 13.5-6 13.5-13.5s-6.1-13.5-13.5-13.5z" />
        <path d="m450.019 226.1-85.8-85.8c-5.3-5.3-13.8-5.3-19.1 0-5.3 5.3-5.3 13.8 0 19.1l62.8 62.8h-273.9c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h273.9l-62.8 62.8c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.4-5.4 5.4-14 .1-19.2z" />
    </svg>
);

export const MySettingsIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 485 485"
        enableBackground="new 0 0 485 485"
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        fill={props.color}
    >
        <path d="M485 287.5v-100h-66.946a180.525 180.525 0 0 0-16.818-40.096l44.558-44.558-70.711-70.711-45.141 45.142a180.262 180.262 0 0 0-42.441-16.684V0h-100v63.446a180.41 180.41 0 0 0-41.364 19.05l-43.29-43.29-70.711 70.711 46.716 46.716a180.382 180.382 0 0 0-14.45 40.866H0v100h70.103a180.882 180.882 0 0 0 17.394 36.363l-48.291 48.291 70.711 70.711 51.717-51.717a180.71 180.71 0 0 0 35.865 13.257V485h100v-73.449a180.898 180.898 0 0 0 35.095-15.317l49.559 49.56 70.711-70.711-50.143-50.143a180.697 180.697 0 0 0 15.332-37.439H485zm-74.562 87.583-28.285 28.284-45.379-45.38-10.22 6.766a151.272 151.272 0 0 1-47.624 20.859l-11.43 2.801V455h-40v-65.214l-12.331-2.23a151.084 151.084 0 0 1-48.775-17.875l-9.967-5.752-46.509 46.509-28.284-28.284 44.859-44.859-7.517-10.362c-10.678-14.721-18.552-31.023-23.401-48.453l-3.056-10.98H30v-40h59.781l1.913-12.78a150.926 150.926 0 0 1 18.625-53.326l5.752-9.967-41.509-41.509 28.284-28.284 39.859 39.859 10.362-7.517c16.024-11.624 33.856-19.896 53.002-24.587l11.43-2.801V30h40v55.214l12.331 2.23c19.427 3.513 37.743 10.684 54.44 21.314l10.152 6.463 40.659-40.659 28.284 28.284-40.379 40.38 6.765 10.22c10.565 15.96 17.935 33.514 21.902 52.174l2.526 11.88H455v40h-60.82l-2.525 11.881c-3.775 17.758-10.643 34.544-20.413 49.891l-6.462 10.152 45.658 45.659z" />
        <path d="M242.5 135C185.981 135 140 180.981 140 237.5S185.981 340 242.5 340 345 294.019 345 237.5 299.019 135 242.5 135zm0 175c-39.977 0-72.5-32.523-72.5-72.5s32.523-72.5 72.5-72.5 72.5 32.523 72.5 72.5-32.523 72.5-72.5 72.5z" />
    </svg>
);

export const ViewInboxIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 22}
        height={props.height || 22}
        fill={props.color || "#565A5E"}
        viewBox="0 0 22 18"
    >
        <path d="M20.625 17.25S22 17.25 22 15.875s-1.375-5.5-6.875-5.5-6.875 4.125-6.875 5.5 1.375 1.375 1.375 1.375h11zm-10.97-1.375a.345.345 0 01-.03-.005c.001-.363.23-1.417 1.045-2.365.759-.89 2.093-1.755 4.455-1.755 2.36 0 3.695.866 4.455 1.755.815.948 1.042 2.003 1.045 2.364l-.011.003a.597.597 0 01-.02.003H9.656zm5.47-8.25a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zm4.125-2.75a4.125 4.125 0 11-8.25 0 4.125 4.125 0 018.25 0zM9.537 10.76a8.08 8.08 0 00-1.691-.34c-.323-.03-.647-.046-.971-.045-5.5 0-6.875 4.125-6.875 5.5 0 .917.458 1.375 1.375 1.375h5.797c-.204-.43-.305-.9-.297-1.375 0-1.389.518-2.808 1.499-3.993a7.273 7.273 0 011.163-1.122zm-2.772.99A7.553 7.553 0 005.5 15.875H1.375c0-.357.226-1.416 1.045-2.37.75-.875 2.051-1.728 4.345-1.754v-.001zM2.063 5.562a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zm4.124-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"></path>
    </svg>
);
export const ViewInboxIconFilled: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 22}
        height={props.height || 22}
        fill="none"
        viewBox="0 0 22 22"
    >
        <path
            fill="#669440"
            d="M9.625 19.25s-1.375 0-1.375-1.375 1.375-5.5 6.875-5.5S22 16.5 22 17.875s-1.375 1.375-1.375 1.375h-11zm5.5-8.25a4.125 4.125 0 100-8.25 4.125 4.125 0 000 8.25z"
        ></path>
        <path
            fill="#669440"
            fillRule="evenodd"
            d="M7.172 19.25c-.204-.43-.305-.9-.297-1.375 0-1.863.935-3.781 2.662-5.115a8.696 8.696 0 00-2.662-.385c-5.5 0-6.875 4.125-6.875 5.5s1.375 1.375 1.375 1.375h5.797z"
            clipRule="evenodd"
        ></path>
        <path fill="#669440" d="M6.188 11a3.438 3.438 0 100-6.875 3.438 3.438 0 000 6.875z"></path>
    </svg>
);

export const LoginHistoryIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width={props.width}
        height={props.height}
        fill={props.color}
    >
        <path d="M12 21a5 5 0 0 0-2.52-4.32 3 3 0 1 0-5 0A5 5 0 0 0 2 21v1h10Z" />
        <path d="M12 2A10 10 0 0 0 2 12h2a8 8 0 1 1 9.57 7.85l.39 2A10 10 0 0 0 12 2Z" />
        <path d="M11 6v6.41l3.29 3.3 1.42-1.42-2.71-2.7V6h-2z" />
    </svg>
);
export const ReportProblemIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "20"}
        height={props.height || "20"}
        fill={"none"}
        viewBox="0 0 12 15"
    >
        <path
            fill={props.color || ColorConstants.grey_600}
            d="M6 1a5 5 0 00-5 5v1h1a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V6a6 6 0 1112 0v6a2.5 2.5 0 01-2.5 2.5H7.366a1 1 0 01-.866.5h-1a1 1 0 010-2h1a1 1 0 01.866.5H9.5A1.5 1.5 0 0011 12h-1a1 1 0 01-1-1V8a1 1 0 011-1h1V6a5 5 0 00-5-5z"
        ></path>
    </svg>
);
