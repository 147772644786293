import { DEFAULT_PAGE_SIZE } from "../../../helper/Constants";
import { AuthenticationMethod, DetailedStatus, ListingStatus, MessageSource } from "../../common/enums";
import { IDocumentRequestTemplateFile } from "../template/TemplateModel";

export interface INewDocumentRequestModel {
    subject: string;
    message: string;
    documentRequestTemplateId: number;
    status: DetailedStatus;
    year: number;
    updatedDate: Date;
    sentDate: Date;
    dueDate: Date | null;
    isDeleted: boolean;
    senderId: number;
    expiryDays: number;
    documentRequestGuid?: number;
    authenticationMethod?: AuthenticationMethod;
    recipient: IDocumentRequestRecipient;
    notifyOnCompletion: boolean;
    requestedFiles: IDocumentRequestTemplateFile[];
    messageSource: MessageSource;
}

export interface INewMultipleDocumentRequestModel {
    documentRequest: INewDocumentRequestModel;
    recipients: IDocumentRequestRecipient[];
}

export interface IDocumentRequestListResponseModel {
    totalRecords: number;
    documentRequests: IDocumentRequestListModel[];
}

export interface IDocumentRequestListModel {
    documentRequestId: number;
    status: DetailedStatus;
    ListingStatus: ListingStatus;
    sentDate: Date;
    totalDocs: number;
    uploadedDocs: number;
    count: number;
    recipientEmail: string;
}

export interface IDocumentRequestModel {
    documentRequestId: number;
    subject: string;
    message: string;
    status: DetailedStatus;
    year: number;
    updatedDate: Date;
    sentDate: Date;
    dueDate: Date;
    senderId: number;
    expiryDays: number;
    documentRequestGuid: number;
    authenticationMethod: AuthenticationMethod;
    recipient?: IDocumentRequestRecipient;
    requestedFiles?: IDocumentRequestFile[];
    documentRequestTemplateId: number;
    recipientEmail: string;
}
export interface IDocumentRequestRecipient {
    documentRequestRecipientId: number;
    documentRequestId: number;
    email: string;
    question?: string;
    answer?: string;
    questionId?: number;
}
export interface IDocumentRequestFile {
    documentRequestFileId: number;
    name: string;
    description: string;
    documentRequestId: number;
    documentCategoryId?: number;
    documentCategoryName?: string;
    createdDate: Date;
    uploadedFiles: IDocumentRequestFileDetails[];
}

export interface IDocumentRequestFileDetails {
    fileName: string;
    fileType: string;
    fileSize: number;
    fileGuid: string;
    isUploaded: boolean;
    uploadedDate: Date;
    fileId: number;
}

export interface IDocumentRequestSearchModel {
    pageNumber: number;
    pageSize: number;
    sortColumn: string;
    sortOrder: string;
    taxpayerEmail?: string;
    listingStatus?: ListingStatus;
}

export const initialDocumentSearchState: IDocumentRequestSearchModel = {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    sortColumn: "sentDate",
    sortOrder: "desc",
    listingStatus: ListingStatus.NONE,
};

export const initialDocumentState: IDocumentRequestModel = {
    documentRequestId: 0,
    documentRequestTemplateId: 0,
    subject: "",
    message: "",
    status: 0,
    year: 2023,
    sentDate: new Date(),
    updatedDate: new Date(),
    dueDate: new Date(),
    expiryDays: 0,
    senderId: 0,
    documentRequestGuid: 0,
    authenticationMethod: AuthenticationMethod.ACCESSCODE,
    recipientEmail: "",
};
export const initialDocumentListState: IDocumentRequestListModel = {
    documentRequestId: 0,
    status: 1,
    ListingStatus: 1,
    sentDate: new Date(),
    totalDocs: 0,
    uploadedDocs: 0,
    count: 0,
    recipientEmail: "",
};

export const initialNewDocumentState: INewDocumentRequestModel = {
    subject: "",
    message: "",
    documentRequestTemplateId: 0,
    status: 0,
    year: new Date().getFullYear(),
    updatedDate: new Date(),
    sentDate: new Date(),
    dueDate: null,
    isDeleted: false,
    senderId: 0,
    requestedFiles: [{ name: "", description: "" }],
    expiryDays: 0,
    notifyOnCompletion: true,
    authenticationMethod: AuthenticationMethod.ACCESSCODE,
    recipient: {
        email: "",
        documentRequestRecipientId: 0,
        documentRequestId: 0,
    },
    messageSource: MessageSource.MAILBOX,
};
export interface IDocumentRequestModels {
    subject: string;
    documentRequestGuid?: number;
    authenticationMethod?: AuthenticationMethod;
    recipient: IDocumentRequestRecipient;
    requestedFiles: IDocumentRequestTemplateFile[];
}
export interface IDocumentRequestMoreModel {
    documentRequestModel: IDocumentRequestFile[];
    documentRequestId: number;
    subject: string;
    dueDate: Date;
    recipientEmail: string | undefined;
    documentRequestGuid: number;
}
export const initialDocumentRequestMoreModel: IDocumentRequestMoreModel = {
    documentRequestModel: [],
    documentRequestId: 0,
    subject: "",
    dueDate: new Date(),
    recipientEmail: "",
    documentRequestGuid: 0,
};

export const initialNewMultipleDocumentRequestModel: INewMultipleDocumentRequestModel = {
    documentRequest: initialNewDocumentState,
    recipients: [],
};
