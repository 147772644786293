export enum actionTypes{
    REQUEST_FORCE_UPDATE="request_force_update",

    REQUEST_HELP_CENTER_URL= "request_help_center_url",
    RECEIVE_HELP_CENTER_URL= "receive_help_center_url",
    COMPLETE_HELP_CENTER_URL= "complete_help_center_url",

    REQUEST_SUBMIT_PROBLEM= "request_submit_problem",
    COMPLETE_SUBMIT_PROBLEM= "complete_submit_problem",

    REQUEST_OTHERS_INBOX_LIST = "request_others_inbox_list",
    RECEIVE_OTHERS_INBOX_LIST = "receive_others_inbox_list",
    COMPLETE_OTHERS_INBOX_LIST = "complete_others_inbox_list",

    RECEIVE_OTHER_USER_DATA = "receive_other_user_data",

    REQUEST_LOGIN_HISTORY= "request_login_history",
    RECEIVE_LOGIN_HISTORY= "receive_login_history",
    COMPLETE_LOGIN_HISTORY= "complete_login_history"
}