import produce from "immer";
import { IReceivedMessageModel } from "../core/viewModels/message/ReceivedMessageModel";

/**
 * * To handle message selection in common place
 * @param state array of selected message iDs
 * @param action select or unselect messages
 * @returns state and dispatch action method
 */
export const receivedMessageReducer = produce(
  (
    draft: { messages: IReceivedMessageModel[]; isAllSelected: boolean },
    action: any
  ) => {
    switch (action.type) {
      case "add":
        draft.messages.push(action.payload);
        draft.isAllSelected = action.messageCount === draft.messages.length;
        break;
      case "remove":
        const index = draft.messages.findIndex(
          (item) => item.receivedMessageId === action.payload.receivedMessageId
        );
        if (index > -1) {
          draft.messages.splice(index, 1);
          draft.isAllSelected = false;
        }
        break;
      case "addAll":
        draft.messages = [...action.payload];
        draft.isAllSelected = true;
        break;
      case "removeAll":
        draft.messages = [];
        draft.isAllSelected = false;
        break;
    }
    return draft;
  }
);
