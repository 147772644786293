import {
  USER_SIGNED_OUT,
  STORE_USER_ERROR,
  USER_EXPIRED,
  STORE_USER,
  LOADING_USER,
  RECEIVED_SESSION_IDLE_MINUTES
} from "../types/authTypes";

export interface UserAuthState {
  user: any;
  isLoadingUser: boolean;
  sessionIdleMinutes: number;
}

const initialState: UserAuthState = {
  user: null,
  isLoadingUser: false,
  sessionIdleMinutes: 0,
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING_USER:
      return {
        ...state,
        isLoadingUser: true,
      };
    case STORE_USER:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    case USER_EXPIRED:
    case STORE_USER_ERROR:
    case USER_SIGNED_OUT:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
      };
    case RECEIVED_SESSION_IDLE_MINUTES:
      return {
        ...state,
        sessionIdleMinutes: action.payload,
      };
    default:
      return state;
  }
};
