import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDelegateeUser } from "../../../core/viewModels/user/UserViewModel";
import { userSettingsConstant } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { actionTypes } from "../../../types/userSettingsTypes";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import SearchInput from "../../common/ui/SearchInput";

const DelegationList: FC = () => {
    const { userSettings } = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const [searchText, setSearchText] = useState<string>("");
    const [list, setList] = useState<IDelegateeUser[]>([]);
    const defaultSelectedDelegatee = userSettings?.mailboxDelegationSettings || [];
    const [selectedDelegatee, setSelectedDelegatee] = useState<number[]>(defaultSelectedDelegatee);

    const { delegateeList } = useSelector((state: ApplicationState) => state.userSettingsState);

    const dispatch = useDispatch();

    const getName = (data: IDelegateeUser) => {
        return `${data.firstName} ${data.lastName} ${data.emailAddress}`;
    };
    const getAllFilteredIds = () => list.map((data: IDelegateeUser) => data.userId);

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        setSearchText(value);
        const filteredList = delegateeList.filter((data: IDelegateeUser) =>
            `${getName(data).toLocaleLowerCase()}`.includes(value)
        );
        setList(filteredList);
    };
    const handleSelect = (e: Event, id: number) => {
        (e.target as HTMLInputElement).checked
            ? setSelectedDelegatee([...selectedDelegatee, id])
            : setSelectedDelegatee(selectedDelegatee.filter(data => data !== id));
    };
    const selectAll = (e: Event) => {
        const allIds = getAllFilteredIds();
        const remainingUsers = selectedDelegatee.filter((data: number) => !allIds.includes(data));
        (e.target as HTMLInputElement).checked ? setSelectedDelegatee(allIds) : setSelectedDelegatee(remainingUsers);
    };

    useEffect(() => {
        setList(delegateeList);
    }, [delegateeList]);

    useEffect(() => {
        dispatch({
            type: actionTypes.UPDATE_USER_SETTINGS,
            payload: { ...userSettings, mailboxDelegationSettings: selectedDelegatee },
        });
    }, [selectedDelegatee]);

    const getIndeterminateValue = () => !getAllFilteredIds().every((data: number) => selectedDelegatee.includes(data));

    const getCheckedValue = () => selectedDelegatee.some((data: number) => getAllFilteredIds().includes(data));

    return (
        <div className="delegation-list-wrapper">
            <SearchInput
                value={searchText}
                onChange={onFilter}
                placeholder={userSettingsConstant.DELEGATEE_SEARCH_PLACEHOLDER}
            />
            <hr />
            {list.length ? (
                <>
                    <div className="delegatee-item">
                        <Checkbox
                            id={"chkMessage_all"}
                            text=""
                            size={CheckBoxSize.sm}
                            indeterminate={getIndeterminateValue()}
                            checked={getCheckedValue()}
                            onChange={e => selectAll(e)}
                        />
                        <span className="margin-left-2">{!searchText ? "All" : "All (select all search results)"}</span>
                    </div>
                    <div className="scrollable-list">
                        {list.map((data, index) => (
                            <div key={index} className="delegatee-item">
                                <Checkbox
                                    id={`chkMessage_${data.firstName}_${index}`}
                                    text=""
                                    size={CheckBoxSize.sm}
                                    checked={selectedDelegatee.includes(data.userId)}
                                    onChange={e => handleSelect(e, data.userId)}
                                />
                                <p
                                    title={`${data.firstName} ${data.lastName} (${data.emailAddress})`}
                                    className="margin-left-2 overflowText"
                                >
                                    {data.firstName} {data.lastName} ({data.emailAddress})
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div>{userSettingsConstant.NO_DELEGATEE_TEXT}</div>
            )}
        </div>
    );
};

export default DelegationList;
