import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/esm/Accordion";
import Form from "react-bootstrap/esm/Form";
import { useSelector } from "react-redux";

import { QuestionType } from "../../../../core/common/enums";
import { IMessageSecurityQuestion } from "../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { AppNotifier } from "../../../../helper/AppNotifier";
import { CompanyAuthQuestionsConstants, ComposeSecurityQuestionValidations, RecipientQnAPlaceholder } from "../../../../helper/Constants";
import { ApplicationState } from "../../../../store";
import { CheckCircleIcon, MinusCircleIcon } from "../../../svg/IconCollection";
import CustomToggleSwitch from "../../ui/switch/CustomToggleSwitch";

interface IAddSecurityQuestion {
  questions: any;
  addSecurityQuestion?: (
    securityQuestion: IMessageSecurityQuestion,
    isUserSecurityQuestion: boolean,
    callback?: any
  ) => void;
  reloadMessageSecurityQuestions: () => void;
}

export const initialAddQuestionState: IMessageSecurityQuestion = {
  questionTitle: "",
  question: "",
  questionType: QuestionType.USER,
  userId: 0,
  companyId: 0,
  messageSecurityQuestionId: 0,
  isDefault: false,
};

const AddSecurityQuestion: React.FC<IAddSecurityQuestion> = (props) => {
  const [state, setState] = useState<IMessageSecurityQuestion>(
    initialAddQuestionState
  );
  const {messageSecuritySettings} = useSelector((state: ApplicationState) => state.adminSettingsState);

  const [isDefault, setDefaultToggle] = useState(false);

  const handleSetDefaultChange = (e: any) => {
    if(messageSecuritySettings.isUserAllowedToChangeDefaultSecurityQuestion){
      setDefaultToggle(e.target.checked);
    }
  };

  const onChangeInputValue = (e: any) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    //This is to put the character limit for question title and question
    if (name === "questionTitle" && value.length > 50) {
      return;
    }
    if (name === "question" && value.length > 100) {
      return;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSaveClick = () => {
    const formGroupValue: IMessageSecurityQuestion = {
      ...state,
      isDefault,
      question : state.question.trim(),
      questionTitle: state.questionTitle.trim()
    };
    if (!formGroupValue.questionTitle.trim()) {
      AppNotifier.Warning(ComposeSecurityQuestionValidations.REQUIRE_QUESTION_NAME);
    } else if (!formGroupValue.question.trim()) {
      AppNotifier.Warning(ComposeSecurityQuestionValidations.REQUIRE_QUESTION);
    } else {
      const isExist = props.questions.data.find(
        (x: any) =>
          x.questionTitle.toLowerCase() ===
          formGroupValue.questionTitle.toLowerCase()
      );
      if (isExist) {
        AppNotifier.Warning(ComposeSecurityQuestionValidations.DUPLICATE_QUESTION_ERROR_MESSAGE);
      } else {
        formGroupValue.questionType = QuestionType.USER;
        props.addSecurityQuestion &&
          props.addSecurityQuestion(formGroupValue, true, (result: any) => {
            if (result > 0) {
              setState(initialAddQuestionState);
              setDefaultToggle(false);
              props.reloadMessageSecurityQuestions();
              AppNotifier.Success(ComposeSecurityQuestionValidations.SECURITY_QUESTION_ADDED_SUCCESS);
            }
          });
      }
    }
  };

  const handleOnResetClick = () => {
    setState(initialAddQuestionState);
    setDefaultToggle(false);
  };

  return (
    <Accordion className="accordion-with-crud">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span className="heading-blue-2">Add New Question</span>
        </Accordion.Header>
        <Accordion.Body>
          <Row className="add-qa-form">
            <Col xs={3}>
              <Form.Control
                type="text"
                name="questionTitle"
                placeholder={RecipientQnAPlaceholder.QUESTION_NAME}
                onChange={onChangeInputValue}
                value={state.questionTitle}
                title={state.questionTitle}
              />
            </Col>
            <Col xs={4}>
              <Form.Control
                type="text"
                name="question"
                placeholder={RecipientQnAPlaceholder.QUESTION}
                className="width-240"
                onChange={onChangeInputValue}
                value={state.question}
                title={state.question}
              />
            </Col>
            <Col>
              <CustomToggleSwitch 
                label={CompanyAuthQuestionsConstants.ADD_QUESTION_DEFAULT_TOGGLE_LABEL}
                switched={isDefault} 
                handleChange={handleSetDefaultChange}/>
            </Col>
            <Col xs={2} className="actions">
              <a
                onClick={onSaveClick}
                title="Save"
              >
                <CheckCircleIcon color="#88c656" height={20} width={20} />
              </a>

              <a
                title="Reset"
                onClick={handleOnResetClick}
              >
                <MinusCircleIcon color="#606060" height={20} width={20} />
              </a>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AddSecurityQuestion;
