import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { BREADCRUMB_MAX_FOLDER_LEVEL, BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE } from "../../../helper/Constants";

interface IBreadCrumbProps {
    breadCrumbArray: IFolderModel[];
    goToFolder: (folderId: number, breadcrumbIndex: number, isArchiveRisTitleBreadcrumbequest: boolean, breadCrumbArray: IFolderModel[]) => void;
    isTitleBreadcrumb: boolean;
}

const FolderBreadCrumb: React.FC<IBreadCrumbProps> = ({ breadCrumbArray, goToFolder, isTitleBreadcrumb }) => {
    const getBreadCrumbArray = () => {
        if (isTitleBreadcrumb ? breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL : breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) {
            const trimmedArray = breadCrumbArray.slice(-((isTitleBreadcrumb ? BREADCRUMB_MAX_FOLDER_LEVEL : BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) - 1));

            return trimmedArray;
        } else {
            return breadCrumbArray;
        }
    };

    const getDropDownArray = () => {
        const arrayCopy = [...breadCrumbArray];
        return arrayCopy.splice(0, arrayCopy.length - ((isTitleBreadcrumb ? BREADCRUMB_MAX_FOLDER_LEVEL : BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) - 1));
    };

    const getDropDownArrayLength = () => {
        const arrayCopy = [...breadCrumbArray];
        const dropDownArray = arrayCopy.splice(0, arrayCopy.length - ((isTitleBreadcrumb ? BREADCRUMB_MAX_FOLDER_LEVEL : BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) - 1));
        return dropDownArray.length;
    };

    const getIndexForbreadCrumbOnClick = (index: number) => {
        if (isTitleBreadcrumb ? breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL : breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) {
            const returnindex = getDropDownArrayLength() + index;
            return returnindex;
        } else {
            return index;
        }
    };

    const DropDownComponent = () => {
        return (
            <Dropdown drop="down" className="inline-Actions-Wrapper">
                <DropdownToggle as={Button}>
                    ...
                </DropdownToggle>
                <DropdownMenu>
                    {getDropDownArray().map((folder, index) => (
                        <Dropdown.Item
                            className="overflowText"
                            eventKey={index}
                            onClick={() =>
                                goToFolder(folder.folderId,
                                    index,
                                    isTitleBreadcrumb,
                                    breadCrumbArray)}
                            key={index}
                            title={folder.folderName}
                        >
                            {folder.folderName}
                        </Dropdown.Item>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    return (
        <div className="row breadcrumb-container" style={isTitleBreadcrumb ? {} : { maxWidth: "100%" }}>
            <ul>
                {(isTitleBreadcrumb ? breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL : breadCrumbArray.length > BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE) && DropDownComponent()}
                {getBreadCrumbArray().map((folder, index) => (
                    <React.Fragment key={`${folder.folderName}-${index}`}>
                        <li
                            title={folder.folderName}
                            onClick={() => goToFolder(folder.folderId, getIndexForbreadCrumbOnClick(index), isTitleBreadcrumb, breadCrumbArray)}
                        >
                            {folder.folderName}
                        </li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default FolderBreadCrumb;
