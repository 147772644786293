import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "react-dropdown";
import { OverlayTrigger, Popover, PopoverBody, Col, FormControl, Row } from "react-bootstrap";
import { debounce } from "throttle-debounce";

import {
    fetchRetentionPeriods,
    fetchSubFolders,
    requestDownloadLater,
    fetchSharedFolderMessageFileViewData,
    isFolderExist,
    fetchFolderDetail,
    fetchFolderDetailsWithPermission,
} from "../../../actionCreators/folderActionCreator";
import MessageExplorer from "./MessageExplorer";
import {
    ArchiveFolderType,
    FolderColumnName,
    FolderType,
    MessageSource,
    MessageViewType,
    SortOrder,
} from "../../../core/common/enums";
import { IFolderModel, rootFolder } from "../../../core/viewModels/folder/FolderModel";
import { IPagination } from "../../../core/viewModels/message/SentMessageModel";
import { ApplicationState } from "../../../store";
import {
    ArchiveConstants,
    initialPagination,
    MyDownloadConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
    viewingOthersInboxLabel,
} from "../../../helper/Constants";
import ArchiveMessage from "./ArchiveMessage";
import FolderActionsPopUp from "./FolderActionPopUp";
import { getMessagePayload, getRetentionOptions } from "../../../helper/HelperFunctions";
import MessageFileView from "./MessageFileView";
import { downloadSingleAttachment } from "../../../actions/attachmentActions";
import { DownloadedFrom } from "../../../core/viewModels/file/FileModel";
import FolderBreadCrumb from "./FolderBreadCrumb";
import { FolderTypeIcon } from "../../svg/FolderIcons";
import "./style.scss";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import SwitchButtonGroup from "../../common/ui/switch/SwitchButtonGroup";
import { DownloadIcon } from "../../svg/ToolbarIcons";
import { IMessageFileViewModel } from "../../../core/viewModels/message/MessageFileViewModel";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { RefreshButtonIcon, SearchIcon } from "../../svg/IconCollection";
import { FiltersCustomComponent } from "../../common/ui/Filters/FiltersCustomComponent";
import { ColorConstants } from "../../../assets/custom/colors";
import { currentFolderIdChanged, currentPageChanged } from "../../../actionCreators/signalRActionCreator";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";
import { AppNotifier } from "../../../helper/AppNotifier";

export const ArchiveContext = React.createContext("");
const messageView = ["Mail View", "File View"];

const sortingData = [
    {
        title: SortAndFilterConstants.SORT_BY_TEXT,
        menus: [
            SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
            SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
            SortAndFilterConstants.NEWEST_FIRST_ORDER,
            SortAndFilterConstants.OLDEST_FIRST_ORDER,
        ],
    },
];

const defaultFileViewSort = {
    dataField: "sentDate",
    order: "desc",
};

const Archive: React.FC = () => {
    const [breadcrumbArray, setBreadcrumbArray] = useState<IFolderModel[]>([rootFolder]);
    const [showArchiveMessages, toggleArchiveMessages] = useState<boolean>(false);
    const [messageViewType, setMessageViewType] = useState<string>(messageView[MessageViewType.Mail]);
    const [showMessageLoader, toggleMessageLoader] = useState<boolean>(false);
    const [selectedFolderId, setSelectedFolderId] = useState<number>(0);
    const [pagination, setPagination] = useState<IPagination>(initialPagination);
    const [showFolderActionPopUp, setShowFolderActionPopUp] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const [reload, setReload] = useState(false);
    const [folderName, setFolderName] = useState<string>(breadcrumbArray[breadcrumbArray.length - 1].folderName);
    const [description, setDescription] = useState<string>(breadcrumbArray[breadcrumbArray.length - 1].description);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [searchTextBox, setSearchTextBox] = useState<string>("");
    const [searchFolderText, setSearchFolderText] = useState<string>("");
    const [selectedFiles, setSelectedFiles] = useState<IMessageFileViewModel[]>([]);
    const [folderCountUnderRoot, setFolderCountUnderRoot] = useState(0);

    /** For search and sort mailview */
    const [searchText, setSearchText] = useState<string>("");
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);

    /** Reset Fileview Sort */
    const [fileViewSort, setFileViewSort] = useState(defaultFileViewSort);

    const dispatch = useDispatch();
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);
    const isFileDownloading = useSelector(
        (appState: ApplicationState) => appState.folderState.fileViewData.isDownloading
    );
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const resetBreadcrumb = useSelector(
        (appState: ApplicationState) => appState.folderState.foldersData.resetBreadcrumb
    );
    const folderData = useSelector((appState: ApplicationState) => appState.folderState.foldersData.subFolders);

    const signalRState = useSelector((appState: ApplicationState) => appState.signalRState);

    const currentFolder: IFolderModel = breadcrumbArray[breadcrumbArray.length - 1];

    useEffect(() => {
        dispatch(currentPageChanged(FolderType.ARCHIVE));
        dispatch(currentFolderIdChanged(currentFolder.folderId));
        dispatch(fetchRetentionPeriods());
        dispatch(fetchSubFolders(currentFolder.folderId, FolderColumnName.FOLDERNAME, SortOrder.ASC));
        dispatch(fetchFolderDetail(currentFolder.folderId));
        dispatch(getRestrictedFileTypes());

        logger.trackPageView(appInsightsData.Folders.MyFiles.PageTitle);

        // clean up code goes here
        return () => {
            goToFolder(0, 0);
        };
    }, []);

    useEffect(() => {
        if (searchText === "") {
            setSelectedFiles([]);
        }
    }, [searchText]);

    useEffect(() => {
        goToFolder(0, 0);
    }, [resetBreadcrumb]);

    useEffect(() => {
        if (messageViewType && showArchiveMessages) {
            dispatch(
                fetchSharedFolderMessageFileViewData(
                    selectedFolderId,
                    pagination.currentPage,
                    pagination.pageSize,
                    "",
                    searchText
                )
            );
        }
    }, [messageViewType, searchText]);

    const parentfolder: IFolderModel =
        breadcrumbArray.length > 1
            ? breadcrumbArray[breadcrumbArray.length - 2]
            : breadcrumbArray[breadcrumbArray.length - 1];

    const currentFolderPath = breadcrumbArray.map(folder => folder.folderName).join("/");

    useEffect(() => {
        dispatch(
            isFolderExist(currentFolder.folderId, (result: boolean) => {
                if (!result) {
                    AppNotifier.Warning(ArchiveConstants.FolderPermissionChanged);
                    goToFolder(0, 0);
                } else {
                    dispatch(
                        fetchSubFolders(
                            currentFolder.folderId,
                            FolderColumnName.FOLDERNAME,
                            SortOrder.ASC,
                            () => {
                                setPagination(initialPagination);
                                setSelectedFiles([]);
                            },
                            searchFolderText
                        )
                    );
                    if (currentFolder.folderId > 0) {
                        dispatch(
                            fetchFolderDetailsWithPermission(currentFolder.folderId, result => {
                                setFolderName(result.folderName);
                                setDescription(result.description);
                                result.folderType === ArchiveFolderType.MESSAGE && toggleArchiveMessages(true);
                                currentFolder.folderType = result.folderType;
                                setBreadcrumbArray(
                                    breadcrumbArray.map(data =>
                                        data.folderId === currentFolder.folderId
                                            ? {
                                                  ...data,
                                                  folderName: result.folderName,
                                                  permissionGroupId: result.permissionGroupId,
                                                  expiresDays: result.expiresDays,
                                                  description: result.description,
                                              }
                                            : data
                                    )
                                );
                            })
                        );
                    }

                    setSelectedFolderId(currentFolder.folderId);
                }
            })
        );

        if (messageViewType === messageView[MessageViewType.File]) {
            setSearchText("");
            setSearchTextBox("");
            setFileViewSort(defaultFileViewSort);
            dispatch(
                fetchSharedFolderMessageFileViewData(
                    selectedFolderId,
                    pagination.currentPage,
                    pagination.pageSize,
                    "",
                    ""
                )
            );
        }
    }, [reload, signalRState.archiveFolderReload]);

    useEffect(() => {
        setShowFolderActionPopUp(false);
        dispatch(currentFolderIdChanged(currentFolder.folderId));
    }, [currentFolder.folderId]);

    useEffect(() => {
        let retentionDays;
        if (parentfolder.folderId === 0) {
            retentionDays = retentionData.retentionPeriods;
        } else {
            retentionDays = retentionData.retentionPeriods.filter(rp => rp.days <= parentfolder.expiresDays);
        }
        setRetentionPeriods(getRetentionOptions(retentionDays));
    }, [parentfolder, retentionData]);

    useEffect(() => {
        setBreadcrumbArray(
            breadcrumbArray.map(data =>
                data.folderId == currentFolder.folderId ? { ...data, folderName, description } : data
            )
        );
    }, [folderName, description]);

    const goToFolder = (
        folderId: number,
        breadcrumbIndex: number,
        isTitleBreadcrumb = true,
        breadCrumbArrayArchiveTable: IFolderModel[] = []
    ) => {
        if (folderId !== rootFolder.folderId && folderId == currentFolder.folderId) return;

        // Reset search text

        setSearchFolderText("");
        setSearchText("");
        setSearchTextBox("");

        // Reset sorting to default
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);

        //fetch sub folders;
        isTitleBreadcrumb
            ? setBreadcrumbArray(value => value.filter((folder, index) => index <= breadcrumbIndex))
            : setBreadcrumbArray(() => {
                  breadcrumbArray.pop();
                  return breadcrumbArray.concat(
                      breadCrumbArrayArchiveTable.filter((folder, index) => index <= breadcrumbIndex)
                  );
              });

        setMessageViewType(messageView[MessageViewType.Mail]);
        setPagination(initialPagination);
        dispatch(fetchSubFolders(folderId, FolderColumnName.FOLDERNAME, SortOrder.ASC));
        setSelectedFiles([]);
        let showMessageView = false;
        if (!isTitleBreadcrumb) {
            const folderType = breadCrumbArrayArchiveTable[breadcrumbIndex].folderType;
            showMessageView = folderType === ArchiveFolderType.MESSAGE;
            toggleMessageLoader(folderType === ArchiveFolderType.MESSAGE);
            setSelectedFolderId(folderId);
        }
        toggleArchiveMessages(showMessageView);
        setFolderName(
            isTitleBreadcrumb
                ? breadcrumbArray[breadcrumbIndex].folderName
                : breadCrumbArrayArchiveTable[breadcrumbIndex].folderName
        );
        setDescription(
            isTitleBreadcrumb
                ? breadcrumbArray[breadcrumbIndex].description
                : breadCrumbArrayArchiveTable[breadcrumbIndex].description
        );
    };

    const handleFolderClick = (
        folder: IFolderModel,
        isTitleBreadcrumb = true,
        breadCrumbArrayArchiveTable: IFolderModel[] = []
    ) => {
        // Map the breadcrumb array to the folders api response
        const tempBreadcrumbArray: IFolderModel[] = [];
        breadCrumbArrayArchiveTable.forEach((breadCrumbFolder, index) => {
            if (index === 0) {
                tempBreadcrumbArray.push(breadCrumbFolder);
                return;
            }
            const newFolder = folderData && folderData.folders.find(f => f.folderId === breadCrumbFolder.folderId);
            if (newFolder) {
                tempBreadcrumbArray.push(newFolder);
            }
        });

        // Reset folder search textbox
        setSearchFolderText("");

        isTitleBreadcrumb
            ? setBreadcrumbArray(value => [...value, folder])
            : setBreadcrumbArray(() => {
                  breadcrumbArray.pop();
                  return breadcrumbArray.concat(tempBreadcrumbArray);
              });
        setFolderName(
            isTitleBreadcrumb ? folder.folderName : tempBreadcrumbArray[tempBreadcrumbArray.length - 1].folderName
        );
        setDescription(
            isTitleBreadcrumb ? folder.description : tempBreadcrumbArray[tempBreadcrumbArray.length - 1].description
        );

        if (folder.folderType === ArchiveFolderType.MESSAGE) {
            toggleArchiveMessages(true);
            setSelectedFolderId(folder.folderId);
        } else {
            toggleMessageLoader(false);
            toggleArchiveMessages(false);
        }
    };

    const handlePopUpActionSubmission = (value: boolean, updatedRetention?: number) => {
        setReload(value);
        setShowFolderActionPopUp(false);
        if (updatedRetention) {
            const breadcrumbDataCopy = [...breadcrumbArray];
            const currentFolderIndex = breadcrumbDataCopy.findIndex(item => item.folderId === currentFolder.folderId);
            breadcrumbDataCopy[currentFolderIndex].expiresDays = updatedRetention;
            setBreadcrumbArray(breadcrumbDataCopy);
        }
    };

    const goToParentFolder = () => {
        const parentIndex = breadcrumbArray.length > 1 ? breadcrumbArray.length - 2 : breadcrumbArray.length - 1;
        return goToFolder(parentfolder.folderId, parentIndex);
    };

    const handleResetSearchAndSort = () => {
        setSearchText("");
        setSearchTextBox("");
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    const headerActionPopover = (
        <Popover
            style={{
                zIndex: 1000,
            }}
        >
            <PopoverBody
                style={{
                    padding: 0,
                }}
            >
                <span>
                    <FolderActionsPopUp
                        onHide={() => {
                            setShowFolderActionPopUp(false);
                        }}
                        selectedFolders={[currentFolder]}
                        retentionOptions={retentionPeriods}
                        setReload={handlePopUpActionSubmission}
                        goToParentFolder={goToParentFolder}
                        setFolderName={setFolderName}
                        setDescription={setDescription}
                        setShowFolderActionPopup={setShowFolderActionPopUp}
                    />
                </span>
            </PopoverBody>
        </Popover>
    );

    const getMessageView = () => {
        switch (messageViewType) {
            case messageView[MessageViewType.Mail]:
                return (
                    <ArchiveMessage
                        downloadSingleAttachment={handleDownloadSingleAttachment}
                        pagination={pagination}
                        setPagination={setPagination}
                        folderId={selectedFolderId}
                        currentfolder={currentFolder}
                        searchText={searchText}
                        selectedSortOption={selectedSortOption}
                        resetSearchAndSort={handleResetSearchAndSort}
                        reload={reload}
                    />
                );
            case messageView[MessageViewType.File]:
                return (
                    <MessageFileView
                        downloadSingleAttachment={handleDownloadSingleAttachment}
                        showDownloadModal={showDownloadModal}
                        setShowDownloadModal={setShowDownloadModal}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        currentFolder={currentFolder}
                        sortBy={fileViewSort}
                    />
                );
            default:
                return;
        }
    };

    const handleDownloadSingleAttachment = (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => {
        dispatch(
            downloadSingleAttachment(
                fileId,
                physicalPath,
                messageId,
                fileName,
                senderEmail,
                receivedMessageGuid,
                messageSource,
                DownloadedFrom.MPI_Download_Inbox_Single_File,
                (result: any) => {
                    if (result) {
                        //reload UI after download
                        dispatch(
                            fetchSharedFolderMessageFileViewData(
                                selectedFolderId,
                                pagination.currentPage,
                                pagination.pageSize,
                                "",
                                ""
                            )
                        );
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.Folders.MyFiles.PageTitle,
                ResourceIdLocators.MyFiles.MyFilesBtnDownloadFile
            )
        );
    };

    const handleMessageViewToogle = () => {
        // Reset sorting to default
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);

        if (messageViewType === messageView[MessageViewType.File]) {
            setMessageViewType(messageView[MessageViewType.Mail]);
        } else {
            setMessageViewType(messageView[MessageViewType.File]);
        }
    };

    const getTotalFileSize = () => {
        if (selectedFiles?.length) {
            let totalSize = 0;
            selectedFiles.map(file => (totalSize += file.size));
            return totalSize;
        } else return 0;
    };

    const handleDownloadButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (selectedFiles.length == 1) {
            const file = selectedFiles[0];
            handleDownloadSingleAttachment(
                file.fileId,
                file.fileGuid,
                file.receivedMessageId,
                file.name,
                file.senderEmail,
                file.receivedMessageGuid,
                file.messageSource
            );
        } else if (getTotalFileSize() > MyDownloadConstants.MAX_FILE_SIZE) {
            dispatch(
                requestDownloadLater(getMessagePayload(selectedFiles), folderName, () => {
                    //reload UI after download
                    dispatch(
                        fetchSharedFolderMessageFileViewData(
                            selectedFolderId,
                            pagination.currentPage,
                            pagination.pageSize,
                            "",
                            ""
                        )
                    );
                    setSelectedFiles([]);
                })
            );
        } else {
            setShowDownloadModal(true);
        }
    };

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        //Set only search box
        setSearchTextBox(value);
        debounceFn(value);
    };

    const getData = (value: string) => {
        //setSearchtext to re-rendering components
        setSearchText(value);
    };

    const debounceFn = useCallback(debounce(1000, getData), []);

    const onFolderFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        //number of folders under root before applying filter
        if (currentFolder.folderId == rootFolder.folderId && searchFolderText == "") {
            setFolderCountUnderRoot(folderData?.folders.length ?? 0);
        }
        setSearchFolderText(value);
        debounceFolderSearchFn(value);
    };

    const getFolderData = (value: string) => {
        dispatch(
            fetchSubFolders(
                currentFolder.folderId,
                FolderColumnName.FOLDERNAME,
                SortOrder.ASC,
                () => {
                    setReload(false);
                },
                value ?? ""
            )
        );
    };

    const debounceFolderSearchFn = useCallback(debounce(1000, getFolderData), [currentFolder]);

    const handleResetSearchFolderTextAndSort = () => {
        setSearchFolderText("");
        setSelectedSortOption && setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    const handleRefreshButtonClick = () => {
        setSearchFolderText("");
        setReload(value => !value);
        setSelectedFiles([]);
    };

    console.log("CurrentFolder", currentFolder);
    return (
        <div id="archive-container">
            <header>
                {mailboxUserData && (
                    <p className="others-inbox-info-label">
                        {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                    </p>
                )}

                <FolderBreadCrumb breadCrumbArray={breadcrumbArray} goToFolder={goToFolder} isTitleBreadcrumb={true} />

                <div className="folder-main-title-container">
                    <div className="folder-name">
                        <FolderTypeIcon height={40} width={40} folderType={currentFolder.folderType} />

                        <span title={folderName}>{folderName}</span>
                        {currentFolder.folderId !== rootFolder.folderId && (
                            <OverlayTrigger
                                placement="bottom-start"
                                overlay={headerActionPopover}
                                trigger="click"
                                rootClose
                                onToggle={(show: boolean) => setShowFolderActionPopUp(show)}
                                show={showFolderActionPopUp}
                            >
                                <i className="fas fa-ellipsis-h cursor-pointer" />
                            </OverlayTrigger>
                        )}
                    </div>
                </div>

                {showArchiveMessages ? (
                    //Message explorer title view
                    <div className="file-search-wrapper">
                        <Row className="search-header-wrapper">
                            <Col sm={5} md={4} className="search-input-wrapper-archive padding-left width-27p">
                                <div className="search-input-wrapper">
                                    <FormControl
                                        placeholder={ArchiveConstants.ShareFolder.PLACEHOLDER_SEARCH_TEXT}
                                        value={searchTextBox}
                                        onChange={onFilter}
                                        className="search-input search-textbox"
                                    />
                                    <div className="search-icon">
                                        <SearchIcon height={18} width={18} />
                                    </div>
                                </div>
                            </Col>
                            {messageViewType === messageView[MessageViewType.Mail] && (
                                <Col sm={1} md={1} className="filter-icon-container">
                                    <div>
                                        <FiltersCustomComponent
                                            groups={sortingData}
                                            selectedSortOption={selectedSortOption}
                                            iconType={SortAndFilterIconType.Sort}
                                            setSelectedSortOption={setSelectedSortOption}
                                        />
                                    </div>
                                </Col>
                            )}
                            <Col sm={1} md={1} className="refresh-button-wrapper-col">
                                <div
                                    className="refresh-button-wrapper"
                                    onClick={handleRefreshButtonClick}
                                    title={ArchiveConstants.REFRESH_TITLE}
                                >
                                    <RefreshButtonIcon width={19} height={19} color={ColorConstants.grey} />
                                </div>
                            </Col>
                        </Row>
                        <div className="file-view-toggle-container">
                            {messageViewType === messageView[MessageViewType.File] && (
                                <button
                                    className="button-secondary-blue-default"
                                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFiles}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        !isFileDownloading && handleDownloadButtonClick(event);
                                    }}
                                    disabled={!selectedFiles.length || isFileDownloading}
                                >
                                    <DownloadIcon width={18} height={18} color="#fff" /> Download
                                </button>
                            )}
                            <SwitchButtonGroup
                                selected={messageViewType}
                                onSwitchChange={handleMessageViewToogle}
                                option2ResourceDataId={ResourceIdLocators.MyFiles.MyFilesBtnFileView}
                                option1Text={messageView[MessageViewType.Mail]}
                                option2Text={messageView[MessageViewType.File]}
                            />
                        </div>
                    </div>
                ) : (
                    //Folder explorer title view
                    <Row className="search-header-wrapper">
                        <Col sm={5} md={5} className="search-input-wrapper-col">
                            <div className="search-input-wrapper" style={{ marginLeft: "-16px" }}>
                                <FormControl
                                    placeholder={`${ArchiveConstants.PLACEHOLDER_SEARCH_TEXT} ${currentFolder.folderName}`}
                                    value={searchFolderText}
                                    onChange={onFolderFilter}
                                    className="search-input search-textbox"
                                />
                                <div className="search-icon">
                                    <SearchIcon height={18} width={18} />
                                </div>
                            </div>
                        </Col>
                        <Col sm={1} md={1} className="refresh-button-wrapper-col">
                            <div
                                className="refresh-button-wrapper"
                                onClick={handleRefreshButtonClick}
                                title={ArchiveConstants.REFRESH_TITLE}
                            >
                                <RefreshButtonIcon width={19} height={19} color={ColorConstants.grey} />
                            </div>
                        </Col>
                    </Row>
                )}
            </header>
            <main>
                {showArchiveMessages ? (
                    getMessageView()
                ) : (
                    // Folder explorer Table
                    <ArchiveContext.Provider value={currentFolderPath}>
                        <MessageExplorer
                            handleFolderClick={handleFolderClick}
                            currentFolder={currentFolder}
                            retentionPeriods={
                                currentFolder.folderId === 0
                                    ? retentionData.retentionPeriods
                                    : retentionData.retentionPeriods.filter(rp => rp.days <= currentFolder.expiresDays)
                            }
                            isRetentionLoading={retentionData.isLoading}
                            isMessageLoading={showMessageLoader}
                            setReload={setReload}
                            resetSearchFolderTextAndSort={handleResetSearchFolderTextAndSort}
                            isSearched={!!searchFolderText}
                            searchFolderText={searchFolderText}
                            reload={reload}
                            goToFolder={goToFolder}
                            foldersCountUnderRoot={folderCountUnderRoot}
                        />
                    </ArchiveContext.Provider>
                )}
            </main>
        </div>
    );
};

Archive.displayName = Archive.name;
export default Archive;
