import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchReceivedMessageHistorySettings,
    fetchSentMessageHistorySettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { CompanySettingsName, MessageHistoryConstants } from "../../../../../helper/Constants";
import { ApplicationState } from "../../../../../store";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import { AdminSettingsSideMenuIcon } from "../../../../svg/AdminSettingsIconsCollection";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ColorConstants } from "../../../../../assets/custom/colors";
import {
    IReceivedItemsHistorySettingsValues,
    ISentItemsHistorySettingsValues,
} from "../../../../../reducers/adminSettingsReducer";
import { CompanySettingsName as CompanySettingsNameEnum } from "../../../../../core/common/enums";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";

export const MessageHistory: React.FC = () => {
    const dispatch = useDispatch();
    const { isLoading, messageHistorySettings } = useSelector((state: ApplicationState) => state.adminSettingsState);
    const initialSentValues = {
        isUserAllowedToViewReports: false,
        isUserAllowedToViewTransferOfAllUsers: false,
        isUserAllowedToResendDocumentAccessLinks: false,
        isUserAllowedToViewAuthenticationQnA: false,
    };

    const initialReceivedValues = {
        isUserAllowedToViewReceivedMessageHistoricalReport: false,
        isUserAllowedToViewTransferOfAllUsers: false,
    };
    const { sentItemsSettings, receivedItemsSettings } = messageHistorySettings;
    const [messageHistoryState, setMessageHistoryState] = useState({
        sentItemsSettingsValues: initialSentValues,
        receivedItemsSettingsValues: initialReceivedValues,
    });
    const {
        IS_USER_ALLOWED_TO_VIEW_REPORTS,
        IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS,
        IS_USER_ALLOWED_TO_RESEND_DOCUMENT_ACCESS_LINKS,
    } = MessageHistoryConstants.MESSAGE_HISTORY_SENT_ITEMS_PAYLOAD;
    const {
        IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS_FOR_RECEIVED_ITEMS,
        IS_USER_ALLOWED_TO_VIEW_RECEIVED_MESSAGE_HISTORICAL_REPORT,
    } = MessageHistoryConstants.MESSAGE_HISTORY_RECEIVED_ITEMS_PAYLOAD;

    useEffect(() => {
        dispatch(fetchSentMessageHistorySettings());
        dispatch(fetchReceivedMessageHistorySettings());
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.MessageHistory.PageTitle);
    }, []);

    useEffect(() => {
        if (sentItemsSettings || receivedItemsSettings) {
            sentItemsSettings &&
                receivedItemsSettings &&
                setMessageHistoryState({
                    sentItemsSettingsValues: { ...sentItemsSettings },
                    receivedItemsSettingsValues: { ...receivedItemsSettings },
                });
        }
    }, [sentItemsSettings, receivedItemsSettings]);

    const handleSentItemsToggleSwitchChange = (e: any, name: string) => {
        const value = e.target.checked;

        setMessageHistoryState({
            ...messageHistoryState,
            sentItemsSettingsValues: {
                ...messageHistoryState.sentItemsSettingsValues,
                [name]: value,
                isUserAllowedToViewTransferOfAllUsers:
                    name ===
                        MessageHistoryConstants.MESSAGE_HISTORY_SENT_ITEMS_PAYLOAD.IS_USER_ALLOWED_TO_VIEW_REPORTS &&
                    !value
                        ? false
                        : name ===
                          MessageHistoryConstants.MESSAGE_HISTORY_SENT_ITEMS_PAYLOAD
                              .IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS
                        ? value
                        : messageHistoryState.sentItemsSettingsValues.isUserAllowedToViewTransferOfAllUsers,
            },
        });
        const updateAdminSettingsRequestPayload: ISentItemsHistorySettingsValues = {
            ...messageHistoryState.sentItemsSettingsValues,
            [name]: value,
        };
        dispatch(
            updateAdminSettings(CompanySettingsNameEnum.SentMessageHistorySettings, updateAdminSettingsRequestPayload)
        );
    };

    const handleReceivedItemsToggleSwitchChange = (e: any, name: string) => {
        const value = e.target.checked;

        // Also toggle dependent sublist options in local state when parent is toggeled
        setMessageHistoryState({
            ...messageHistoryState,
            receivedItemsSettingsValues: {
                ...messageHistoryState.receivedItemsSettingsValues,
                [name]: value,
                isUserAllowedToViewTransferOfAllUsers:
                    name ===
                        MessageHistoryConstants.MESSAGE_HISTORY_RECEIVED_ITEMS_PAYLOAD
                            .IS_USER_ALLOWED_TO_VIEW_RECEIVED_MESSAGE_HISTORICAL_REPORT && !value
                        ? false
                        : name ===
                          MessageHistoryConstants.MESSAGE_HISTORY_RECEIVED_ITEMS_PAYLOAD
                              .IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS_FOR_RECEIVED_ITEMS
                        ? value
                        : messageHistoryState.sentItemsSettingsValues.isUserAllowedToViewTransferOfAllUsers,
            },
        });

        const updateAdminSettingsRequestPayload: IReceivedItemsHistorySettingsValues = {
            ...messageHistoryState.receivedItemsSettingsValues,
            [name]: value,
        };
        dispatch(
            updateAdminSettings(
                CompanySettingsNameEnum.ReceivedMessageHistorySettings,
                updateAdminSettingsRequestPayload
            )
        );
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{MessageHistoryConstants.MESSAGE_HISTORY_HELPER_TEXT}</span>
                </header>
                <div className="margin-top-10-px settings-accordian-container">
                    <Accordion flush className="settings-accordian">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id={CompanySettingsName.SentMessageHistorySettings}>
                                <AdminSettingsSideMenuIcon
                                    icon={"MessageHistorySentItemsIcon"}
                                    color={ColorConstants.light_blue}
                                    width={24}
                                    height={24}
                                />
                                <div className="accordion-title-info-container">
                                    <h4>{MessageHistoryConstants.SENT_ITEMS_HISTORY}</h4>
                                    <span>{MessageHistoryConstants.SENT_ITEMS_HISTORY_HELP_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="settings-label-toggle-wrapper">
                                    <CustomToggleSwitch
                                        label={MessageHistoryConstants.VIEW_SENT_ITEMS}
                                        name={IS_USER_ALLOWED_TO_VIEW_REPORTS}
                                        handleChange={handleSentItemsToggleSwitchChange}
                                        switched={
                                            messageHistoryState.sentItemsSettingsValues?.isUserAllowedToViewReports
                                        }
                                    />
                                </div>

                                {messageHistoryState?.sentItemsSettingsValues?.isUserAllowedToViewReports && (
                                    <div className="settings-label-toggle-wrapper sub-list-settings-item">
                                        <Checkbox
                                            id={"checkbox-sent-items"}
                                            value={IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS}
                                            className="primary-checkbox"
                                            text={MessageHistoryConstants.VIEW_TRANSFER_OF_USERS}
                                            size={CheckBoxSize.sm}
                                            checked={
                                                messageHistoryState.sentItemsSettingsValues
                                                    ?.isUserAllowedToViewTransferOfAllUsers
                                            }
                                            onChange={e => {
                                                handleSentItemsToggleSwitchChange(
                                                    e,
                                                    IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS
                                                );
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="settings-label-toggle-wrapper">
                                    <CustomToggleSwitch
                                        label={MessageHistoryConstants.RESEND_DOCUMENT_ACCESS_LINKS}
                                        name={IS_USER_ALLOWED_TO_RESEND_DOCUMENT_ACCESS_LINKS}
                                        handleChange={handleSentItemsToggleSwitchChange}
                                        switched={
                                            messageHistoryState.sentItemsSettingsValues
                                                ?.isUserAllowedToResendDocumentAccessLinks
                                        }
                                    />
                                </div>

                                {/* hide the option "View Authentication Questions and Answers" in Sent Items History - (if required , we will enhance in the upcoming sprints) 
								<Row>
									<Col sm={8}>
										<h5>{MessageHistoryConstants.VIEW_QNA}</h5>
									</Col>
									<Col sm={4}>
										<CustomToggleSwitch
											name={IS_USER_ALLOWED_TO_VIEW_AUTHENTICATION_QNA}
											handleChange={handleSentItemsToggleSwitchChange}
											switched={messageHistoryState.sentItemsSettingsValues?.isUserAllowedToViewAuthenticationQnA} />
									</Col>
								</Row> */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion flush className="settings-accordian">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header id={CompanySettingsName.ReceivedMessageHistorySettings}>
                                <AdminSettingsSideMenuIcon
                                    icon={"MessageHistoryReceivedItemsIcon"}
                                    color={ColorConstants.light_blue}
                                    width={24}
                                    height={24}
                                />

                                <div className="accordion-title-info-container">
                                    <h4>{MessageHistoryConstants.RECEIVED_ITEMS_HISTORY}</h4>
                                    <span>{MessageHistoryConstants.RECEIVED_ITEMS_HISTORY_HELP_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="settings-label-toggle-wrapper">
                                    <CustomToggleSwitch
                                        label={MessageHistoryConstants.VIEW_RECEIVED_ITEMS}
                                        name={IS_USER_ALLOWED_TO_VIEW_RECEIVED_MESSAGE_HISTORICAL_REPORT}
                                        handleChange={handleReceivedItemsToggleSwitchChange}
                                        switched={
                                            messageHistoryState.receivedItemsSettingsValues
                                                .isUserAllowedToViewReceivedMessageHistoricalReport
                                        }
                                    />
                                </div>

                                {messageHistoryState?.receivedItemsSettingsValues
                                    ?.isUserAllowedToViewReceivedMessageHistoricalReport && (
                                    <div className="settings-label-toggle-wrapper sub-list-settings-item">
                                        <Checkbox
                                            id={"checkbox-received-item"}
                                            value={IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS_FOR_RECEIVED_ITEMS}
                                            className="primary-checkbox"
                                            text={MessageHistoryConstants.VIEW_TRANSFER_OF_USERS}
                                            size={CheckBoxSize.sm}
                                            checked={
                                                messageHistoryState.receivedItemsSettingsValues
                                                    .isUserAllowedToViewTransferOfAllUsers
                                            }
                                            onChange={e => {
                                                handleReceivedItemsToggleSwitchChange(
                                                    e,
                                                    IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS_FOR_RECEIVED_ITEMS
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </>
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};
