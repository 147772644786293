import React, { CSSProperties } from "react";
import { Form } from "react-bootstrap";
import Select, { ActionMeta, components } from "react-select";
import { useSelector } from "react-redux";

import { ColorConstants } from "../../../../../assets/custom/colors";
import { TemplateListingConstants, TemplateValidationConstants } from "../../../../../helper/Constants";
import { MinusCircleIcon } from "../../../../svg/IconCollection";
import { ApplicationState } from "../../../../../store";

interface Options {
    value: number;
    label: string;
}

interface IAddFilesRowProps {
    name: string;
    description: string;
    documentCategory: { documentCategoryId: number; name: string } | null;
    fileIndex: number;
    filesLength: number;
    descriptionRequired?: boolean;
    onClickRemoveRow: (index: number) => void;
    onFilesFormChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    onCategoryChange: (value: any, index: number) => void;
}

export const AddFilesRow: React.FC<IAddFilesRowProps> = ({
    name,
    description,
    documentCategory,
    fileIndex,
    filesLength,
    descriptionRequired,
    onFilesFormChange,
    onClickRemoveRow,
    onCategoryChange,
}) => {
    const { data: categories, isLoading } = useSelector((state: ApplicationState) => state.documentCategoryState);

    const categoryOption = documentCategory
        ? categories
              .map(category =>
                  category.documentCategoryId === documentCategory.documentCategoryId
                      ? { value: category.documentCategoryId, label: category.name }
                      : null
              )
              .filter(Boolean)[0]
        : null;

    const renderActionButtons = (index: number, filesLength: number) => {
        return filesLength > 1 ? (
            <a onClick={() => onClickRemoveRow(index)} title="Remove">
                <MinusCircleIcon color={ColorConstants.brand_pimary_blue_1} height={18} width={18} />
            </a>
        ) : null;
    };

    const getCategoryOptions = () => {
        if (!categories || categories.length === 0) {
            return [];
        }
        return categories.map(category => {
            return {
                value: category.documentCategoryId,
                label: category.name,
            };
        });
    };

    const Option = (props: any) => {
        return (
            <span title={props.data.label}>
                <components.Option {...props} />
            </span>
        );
    };

    const ClearIndicator = (props: any) => {
        const {
            children = (
                <div className="clear-icon">
                    <i className="fas fa-times"></i>
                </div>
            ),
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props) as CSSProperties}>
                <div style={{ padding: "0px 5px" }}>{children}</div>
            </div>
        );
    };

    const handleCategoryChange = (value: Options | null, data: ActionMeta<Options>) => {
        switch (data.action) {
            case "select-option":
                if (value) {
                    const payload = { documentCategoryId: value.value, name: value.label };
                    onCategoryChange(payload, fileIndex);
                }
                break;
            case "clear":
                onCategoryChange(null, fileIndex);
                break;
        }
    };

    return (
        <div key={fileIndex} className="file-request-wrapper">
            <Form.Group controlId="category" title={categoryOption?.label}>
                <Select
                    options={getCategoryOptions()}
                    onChange={handleCategoryChange}
                    value={categoryOption}
                    placeholder={TemplateListingConstants.NO_CATEGORY_TEXT}
                    isLoading={isLoading}
                    isClearable={true}
                    classNamePrefix="category-dropdown"
                    theme={theme => {
                        return { ...theme, borderRadius: 0 };
                    }}
                    components={{
                        Option,
                        IndicatorSeparator: null,
                        ClearIndicator,
                    }}
                    closeMenuOnScroll={false}
                    menuPortalTarget={document.querySelector("body")}
                />
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Control
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilesFormChange(e, fileIndex)}
                    value={name}
                    className="template-file-input"
                    type="text"
                    placeholder={TemplateListingConstants.MODAL_FILE_NAME_PLACEHOLDER}
                    required={true}
                    maxLength={TemplateListingConstants.FILE_NAME_MAX_LENGTH}
                />
                <Form.Control.Feedback type="invalid">
                    {TemplateValidationConstants.DOCUMENT_NAME_TEXT}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Control
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilesFormChange(e, fileIndex)}
                    value={description}
                    className="template-file-input"
                    type="text"
                    placeholder={TemplateListingConstants.MODAL_FILE_DESCRIPTION_PLACEHOLDER}
                    required={descriptionRequired}
                    maxLength={TemplateListingConstants.FILE_DESCRIPTION_MAX_LENGTH}
                />
                <Form.Control.Feedback type="invalid">
                    {TemplateValidationConstants.DESCRIPTION_REQUIRED_TEXT}
                </Form.Control.Feedback>
            </Form.Group>

            <span className="actions pointer">{renderActionButtons(fileIndex, filesLength)}</span>
        </div>
    );
};
