export interface IFileModel {
  fileId?: number;
  fileGuid: string;
  name: string;
  size: number;
  isUploadComplete: boolean;
  uploadProgress: number;
}

export enum DownloadedFrom {
  MailBox_Download_SingleFile = 1,
  MailBox_Download_MultipleFile = 2,
  MPI_Download_Inbox_Single_File = 3,
  MPI_Download_Inbox_Multiple_File = 4,
  MPI_Download_SentItem_Single_File = 5,
  MPI_Download_SentItem_Multiple_File = 6,
  MPI_Download_Archive_Single_File = 7,
  MPI_Download_Archive_Multiple_File = 8,
  Send_Link_Download_Single_File = 9,
  Send_Link_Download_Multiple_File = 10,
  Drafts_Download = 11,
}
