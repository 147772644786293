import React, { useEffect, useRef } from "react";
import { Form, Row } from "react-bootstrap";

import { AddFilesRow } from "../../common/ui/Modals/TemplateModal/AddFilesRow";
import { NewDRConstants } from "../../../helper/Constants";
import { ColorConstants } from "../../../assets/custom/colors";
import { PlusCircleIcon } from "../../svg/IconCollection";
import { IDocumentRequestTemplateFile } from "../../../core/viewModels/template/TemplateModel";
import { useDispatch } from "react-redux";
import {
    getDocumentCategoriesDropdown,
    getDocumentCategory,
} from "../../../actionCreators/documentCategoryActionCreator";
import { initialCategoryRequest } from "../../../core/viewModels/settings/DocumentCategory";

interface IDocumentRequestMore {
    documentRequestId: number;
    filesData: IDocumentRequestTemplateFile[];
    validated: boolean;
    onClickRemoveRow: (index: number) => void;
    onClickAddFiles: () => void;
    onFilesFormChange: (e: any, index: number) => void;
    onCategoryChange: (value: any, index: number) => void;
}

export const DocumentRequestMore: React.FC<IDocumentRequestMore> = ({
    filesData,
    validated,
    onClickRemoveRow,
    onClickAddFiles,
    onFilesFormChange,
    onCategoryChange,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        getDocumentCategory(initialCategoryRequest);
        dispatch(getDocumentCategoriesDropdown());
    }, []);
    const ref = useRef<HTMLFormElement>(null);
    return (
        <Form
            ref={ref}
            noValidate
            validated={validated}
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            <Row>
                <Form.Label>{NewDRConstants.FILE_REQUEST_LIST_LABEL}</Form.Label>

                <div tabIndex={0} className="add-files-btn" onClick={onClickAddFiles}>
                    <PlusCircleIcon color={ColorConstants.brand_pimary_blue_1} />
                    <p className="margin-0-px">{NewDRConstants.ADD_FILES_TEXT}</p>
                </div>
                <div className="add-files-wrapper">
                    {filesData.length > 0 &&
                        filesData.map((file, fileIndex, files) => {
                            return (
                                <AddFilesRow
                                    key={fileIndex}
                                    name={file.name}
                                    description={file.description}
                                    documentCategory={file.documentCategory || null}
                                    fileIndex={fileIndex}
                                    onClickRemoveRow={onClickRemoveRow}
                                    filesLength={files.length}
                                    onFilesFormChange={onFilesFormChange}
                                    onCategoryChange={onCategoryChange}
                                    descriptionRequired={false}
                                />
                            );
                        })}
                </div>
            </Row>
        </Form>
    );
};
