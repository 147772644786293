import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { ApplicationState } from "../../../store";
import { downloadAllAttachments, downloadSingleAttachment } from "../../../actions/attachmentActions";
import { DownloadedFrom, IFileModel } from "../../../core/viewModels/file/FileModel";
import { FolderType, MessageSource } from "../../../core/common/enums";
import MessageDetail from "../../common/message/MessageDetail";

import { MessageListConstants, initialPagination, viewingOthersInboxLabel } from "../../../helper/Constants";
import { fetchSentMessageHistorySettings } from "../../../actionCreators/adminSettingsActionCreators";
import {
    deleteDraftMessage,
    fetchDraftMessages,
    getDraftMessageDetail,
} from "../../../actionCreators/draftMessageActionCreator";
import DraftMessageList from "./parts/DraftMessageList";
import { IMessageResponseModel } from "../../../core/viewModels/message/MessageResponseModel";
import { IDraftMailMessage, IDraftMessageModel } from "../../../core/viewModels/message/DraftMailMessage";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";

const Draft: React.FC = () => {
    const [isMessageDetailShow, toggleMessageDetailShow] = useState(false);
    const [pagination, setPagination] = useState(initialPagination);

    const dispatch = useDispatch();
    const messageState = useSelector((state: ApplicationState) => state.draftMessageState);
    const draftMessageState = useSelector((state: ApplicationState) => state.draftMessageState);
    const selectedDraftMessage = draftMessageState.mailData.data;
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatch(fetchSentMessageHistorySettings());
        dispatch(getRestrictedFileTypes());
        logger.trackPageView(appInsightsData.MailBox.Draft.PageTitle);
    }, []);

    useEffect(() => {
        if (
            selectedDraftMessage &&
            selectedDraftMessage.messageDetail.draftedMessageId > 0 &&
            draftMessageState.data.totalRecords > 0
        ) {
            toggleMessageDetailShow(true);
        } else {
            toggleMessageDetailShow(false);
        }
    }, [selectedDraftMessage, draftMessageState.data.totalRecords]);

    const fetchMessages = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string,
        message?: IDraftMailMessage
    ) => {
        dispatch(
            fetchDraftMessages(
                pageNo,
                pageSize,
                sortColumn,
                sortOrder,
                search,
                (result: IMessageResponseModel<IDraftMessageModel>) => {
                    if (message && message.messageDetail && message.messageDetail.draftedMessageId > 0) {
                        handleGetMessageDetail(message.messageDetail);
                    } else {
                        onFetchMessagesComplete(result);
                    }
                }
            )
        );
    };

    const onFetchMessagesComplete = (result: IMessageResponseModel<IDraftMessageModel>) => {
        if (result && result.messages && result.messages.length > 0) {
            dispatch(getDraftMessageDetail(result.messages[0].draftedMessageId, messageDetailCallback));
        }
    };

    const messageDetailCallback = (result: IDraftMailMessage) => {
        if (result.messageDetail) toggleMessageDetailShow(true);
        else toggleMessageDetailShow(false);
    };

    const handleOnDeleteMessage = (messages: IDraftMessageModel[], callback: any) => {
        // Dispatch delete draft message
        const messageIds = messages.map(message => message.draftedMessageId);
        dispatch(
            deleteDraftMessage(
                messageIds,
                (result: number) => {
                    if (result) {
                        callback(result);
                    }
                },
                ResourceIdLocators.Drafts.MsgListBtnDelete
            )
        );
    };

    const handleGetMessageDetail = (message: IDraftMessageModel) => {
        dispatch(getDraftMessageDetail(message.draftedMessageId, messageDetailCallback));
    };

    const handleMessageDetailView = (result: boolean) => {
        if (result) toggleMessageDetailShow(false);
    };

    const handleDownloadSingleAttachment = (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string
    ) => {
        dispatch(
            downloadSingleAttachment(
                fileId,
                physicalPath,
                messageId,
                fileName,
                "",
                "",
                MessageSource.MAILBOX,
                DownloadedFrom.Drafts_Download,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Draft.PageTitle,
                ResourceIdLocators.Drafts.MsgDtlBtnDownloadFile
            )
        );
    };

    const handleDownloadAllAttachments = (files: IFileModel[], messageId: number) => {
        dispatch(
            downloadAllAttachments(
                files,
                messageId,
                "",
                "",
                MessageSource.MAILBOX,
                DownloadedFrom.Drafts_Download,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Draft.PageTitle,
                ResourceIdLocators.Drafts.MsgDtlBtnDownloadFiles
            )
        );
    };

    const handleResetSearchAndSort = () => {
        setReload(value => !value);
    };

    return (
        <div className="row height-100-percent width-100-percent margin-left-0">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <div className="col-sm-4 no-padding-left">
                <DraftMessageList
                    folderId={FolderType.DRAFT}
                    folderType={FolderType.DRAFT}
                    isLoading={messageState.isLoading}
                    messages={messageState.data.messages}
                    totalRecords={messageState.data.totalRecords}
                    fetchMessages={fetchMessages}
                    deleteMessages={handleOnDeleteMessage}
                    getMessageDetail={handleGetMessageDetail}
                    hideMessageDetailView={handleMessageDetailView}
                    pagination={pagination}
                    setPagination={setPagination}
                    reload={reload}
                />
            </div>
            <div className="col-sm-8">
                <LoadingOverlay style={{ height: "100%" }}>
                    {isMessageDetailShow && selectedDraftMessage && messageState.data.messages.length > 0 ? (
                        <MessageDetail
                            message={selectedDraftMessage}
                            downloadSingleAttachment={handleDownloadSingleAttachment}
                            downloadAllAttachments={handleDownloadAllAttachments}
                            messageId={selectedDraftMessage.messageDetail.draftedMessageId}
                            folderType={FolderType.DRAFT}
                            hideMessageDetailView={handleMessageDetailView}
                            getMessageDetail={handleGetMessageDetail}
                            pagination={pagination}
                            fetchFolderMessages={fetchMessages}
                            resourceLocators={ResourceIdLocators.Drafts}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    ) : (
                        messageState.mailData.isLoading || (
                            <div className="no-message-selected">{MessageListConstants.NO_MESSAGE_SELECTED}</div>
                        )
                    )}
                    <Loader loading={messageState.mailData.isLoading} />
                </LoadingOverlay>
            </div>
        </div>
    );
};

export default Draft;
