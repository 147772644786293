import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import "popper.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "rc-tree/assets/index.css";
import "./App.css";
import "./assets/custom/site.css";
import "./assets/custom/custom.css";
import "./assets/custom/utilities.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-overlay-loader/styles.css";
import SigninOidc from "./oidcClient/signin-oidc";
import SignoutOidc from "./oidcClient/signout-oidc";
import SilentSigninOidc from "./oidcClient/silent-signin-oidc";
import { Login } from "./components/Login";
import Inbox from "./components/pages/receivedMessage/Inbox";
import Spam from "./components/pages/receivedMessage/Spam";
import store from "./configureStore";
import userManager from "./oidcClient/userService";
import AuthProvider from "./oidcClient/authProvider";
import PrivateRoute from "./protectedRoutes";
import { Toaster } from "./helper/AppNotifier";
import Sent from "./components/pages/sentMessage/Sent";
import "react-dropdown/style.css";
import { Unlock } from "./components/pages/Unlock/Unlock";
import Draft from "./components/pages/Draft/Draft";
import SentItemHistory from "./components/pages/reports/SentItemHistory";
import ReceiveItemHistory from "./components/pages/reports/ReceiveItemHistory";
import Archive from "./components/pages/folders/Archive";
import RestrictedAccess from "./components/navigation/RestrictedAccess";
import AxiosInterceptorsSetup from "./axios-interceptor";
import { DocumentRequest } from "./components/pages/requestDocuments/DocumentRequest";
import { PageNotFound } from "./components/common/PageNotFound";
import "awesome-pdf-viewer/dist/index.css";
import AdminSettings from "./components/pages/adminSettings/AdminSettings";
import { adminSettingsRoute } from "./core/viewModels/settings/Settings";
import DocumentRequestHistory from "./components/pages/reports/DocumentRequestHistory";
import React from "react";

function AxiosInterceptorNavigate() {
    const navigate = useNavigate();
    AxiosInterceptorsSetup(navigate);
    return <></>;
}

export const App: FC<any> = () => {
    return (
        <Provider store={store}>
            <AuthProvider userManager={userManager} store={store}>
                <Router>
                    <AxiosInterceptorNavigate />
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signout-callback-oidc" element={<SignoutOidc />} />
                        <Route path="/signin-oidc" element={<SigninOidc />} />
                        <Route path="/silent-signin-oidc" element={<SilentSigninOidc />} />
                        <Route path="/" element={<PrivateRoute component={Inbox} />} />
                        <Route path="/inbox" element={<PrivateRoute component={Inbox} />} />
                        <Route path="/draft" element={<PrivateRoute component={Draft} />} />
                        <Route path="/sent" element={<PrivateRoute component={Sent} />} />
                        <Route path="/spam" element={<PrivateRoute component={Spam} />} />
                        <Route path="/request-documents" element={<PrivateRoute component={DocumentRequest} />} />
                        <Route path="/MyFiles" element={<PrivateRoute component={Archive} />} />
                        <Route path="/unlock" element={<PrivateRoute component={Unlock} />} />
                        <Route path="/sent-item-history" element={<PrivateRoute component={SentItemHistory} />} />
                        <Route path="/receive-item-history" element={<PrivateRoute component={ReceiveItemHistory} />} />
                        <Route
                            path="/document-request-history"
                            element={<PrivateRoute component={DocumentRequestHistory} />}
                        />
                        <Route path="/restricted-access/:message" element={<RestrictedAccess />} />

                        {adminSettingsRoute.map((path, index) => (
                            <Route key={index} path={path} element={<PrivateRoute component={AdminSettings} />} />
                        ))}

                        <Route path="*" element={<PrivateRoute component={PageNotFound} />} />
                    </Routes>
                    <Toaster />
                </Router>
            </AuthProvider>
        </Provider>
    );
};

export default App;
