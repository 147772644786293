import { actionTypes } from "../types/companyTypes";
import { ICompanySettings } from "../core/viewModels/company/CompanySettingsViewModel";
import { AppThunkAction } from "../store";
import { CompanySettingsConstants } from "../helper/Constants";
import axios from "axios";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";

export interface RequestCompanySettingsAction {
  type: actionTypes.REQUEST_COMPANY_SETTINGS;
  message: string;
}

export interface ReceiveCompanySettingsAction {
  type: actionTypes.RECEIVE_COMPANY_SETTINGS;
  settings: ICompanySettings;
}

export type DispatchActions =
  | RequestCompanySettingsAction
  | ReceiveCompanySettingsAction;

export type KnownActions = DispatchActions | NotificationAction;

export const requestCompanySettings =
  (): AppThunkAction<KnownActions> => (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_COMPANY_SETTINGS,
      message: CompanySettingsConstants.OverlayMessage.ApplicationLoading,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Company/GetCompanySettingsModel`
      )
      .then((response: any) => {
        const data = response.data;
        dispatch({
          type: actionTypes.RECEIVE_COMPANY_SETTINGS,
          settings: data,
        });
      })
      .catch(function () {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage:
            CompanySettingsConstants.StatusMessage.CompanySettingsError,
          statusType: StatusType.Error,
        });
      });
  };
