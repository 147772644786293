import React, { FC } from "react";
import { FormControl } from "react-bootstrap";
import { SearchIcon } from "../../svg/IconCollection";

interface ISearchInputProps {
    placeholder: string;
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: FC<ISearchInputProps> = ({ placeholder, value, onChange }) => {
    return <div className="search-input-wrapper">
        <SearchIcon height={18} width={18} />
        <FormControl
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="search-input" />
    </div>;
};

export default SearchInput;