import { Action, Reducer } from "redux";
import { produce } from "immer";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import {
  initialReceivedMessage,
  IReceivedMessageModel,
} from "../core/viewModels/message/ReceivedMessageModel";
import { IReceivedMailMessage } from "../core/viewModels/message/ReceivedMailMessageModel";
import * as MessageActions from "../actionCreators/receivedMessageActionCreators";
import { actionTypes } from "../types/messageTypes";

export interface IReceivedMessageState {
  data: IMessageResponseModel<IReceivedMessageModel>;
  isLoading: boolean;
  mailData: {
    data: IReceivedMailMessage;
    isLoading: boolean;
  };
}

 export const initialState: IReceivedMessageState = {
  isLoading: false,
  data: {
    messages: [],
    totalRecords: 0,
  },
  mailData: {
    data: {
      messageDetail: initialReceivedMessage,
      attachments: [],
      recipients: [],
      recipientSecurityQuestions: []
    },
    isLoading: false,
  },
};

export const reducer: Reducer<IReceivedMessageState> = produce(
  (draft: IReceivedMessageState = initialState, incomingAction: Action) => {
    const action = incomingAction as MessageActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_RECEIVED_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_RECEIVED_MESSAGES:
        draft.isLoading = false;
        draft.data = action.payload;
        return draft;

      case actionTypes.REQUEST_CHANGE_EXPIRY_RECEIVED_MESSAGE:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_CHANGE_EXPIRY_RECEIVED_MESSAGE:
        draft.isLoading = false;
        return draft;

      case actionTypes.REQUEST_RECEIVED_MESSAGE_DETAIL:
        draft.mailData.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_RECEIVED_MESSAGE_DETAIL:
        draft.mailData.isLoading = false;
        draft.mailData.data = action.payload;
        return draft;

      case actionTypes.COMPLETED_RECEIVED_MESSAGE_DETAIL:
        draft.mailData.isLoading = false;
        return draft;

      case actionTypes.RESET_MESSAGES_ACTION:
        draft.data = {
          messages: [],
          totalRecords: 0,
        };
        return draft;   
      case actionTypes.RECEIVED_MESSAGE_ARRIVAL:
        draft.data.messages.unshift(action.newMessage);
        return draft;       
      default:
        return draft;
    }
  }
);
