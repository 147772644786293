import { ArchiveFolderType, MessageSource, ShareFolderTypes, ShareFolderUserType } from "../../common/enums";
import { IMessageFileViewModel } from "../message/MessageFileViewModel";

export interface IFolderModel {
    folderId: number;
    folderName: string;
    parentFolderIds: string;
    description: string;
    createdBy: number;
    expiresDays: number;
    createdDate: Date;
    parentId: number;
    path?: string;
    folderType?: ArchiveFolderType;
    subFolders?: IFolderModel[];
    owner: string;
    ownerFirstName?: string;
    sharedWith?: string;
    sharedWithFirstName?: string;
    permissionGroupId?: ShareFolderTypes;
    isPermissiomInherited?: boolean;
    isPrivate: boolean;
}

export const initialFolder: IFolderModel = {
    folderId: 0,
    folderName: "My Files",
    parentFolderIds: "0 ",
    description: "My Files Description",
    createdBy: 0,
    createdDate: new Date(),
    expiresDays: 0,
    parentId: 0,
    owner: "Only You",
    permissionGroupId: ShareFolderTypes.NONE,
    isPermissiomInherited: false,
    isPrivate: false,
};

export const rootFolder: IFolderModel = {
    ...initialFolder,
    folderName: "My Files",
    description: "",
    path: "My Files",
};

export interface IFolderModelEx extends IFolderModel {
    path: string;
}

export interface IDownloadNowPayload {
    messageGuid: string;
    messageId: number;
    files: IMessageFileViewModel[];
    senderEmail: string;
    messageSource: MessageSource;
}

export interface IMyDownloadFile {
    myDownloadsId: number;
    downloadedOn: string;
    fileSize: number;
    fileName: string;
    filePath: string;
    parentFolder: string;
    fileGuid: string;
    requestedDocumentsCount: number;
    downloadedDocumentsCount: number;
    userId: number;
    status: number;
    isDeleted: boolean;
    count: number;
}

export enum ZipDownloadStatus {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3,
    Downloaded = 4,
}

export interface ISharedFolderPermissionViewModel {
    folderId: number,
    userId: number,
    permissionGroupId: ShareFolderTypes,
    sharedFolderId?: number,
}

export interface ISharedFolderGroupPermissionViewModel {
    folderId: number;
    groupId: number;
    permissionGroupId: ShareFolderTypes;
    sharedFolderGroupId?: number;
}

export interface IFolderPermissionViewModel {
    folderId: number;
    userPermissions: ISharedFolderPermissionViewModel[];
    groupPermissions: ISharedFolderGroupPermissionViewModel[];
}

export const initialFolderPermissionViewModel: IFolderPermissionViewModel = {
    folderId:0,
    userPermissions: [],
    groupPermissions: []
};

export interface ISharedFolderUserModel {
    userId: number;
    shareFolderUserType: ShareFolderUserType;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export interface ISharedFolderPermissionDetailViewModel {
    folderId: number;
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    permissionGroupId: ShareFolderTypes;
    sharedFolderId: number;
}

export interface ISharedFolderGroupPermissionDetailViewModel extends ISharedFolderGroupPermissionViewModel {
    groupName: string;
}