import { AuthenticationMethod } from "../../common/enums";
import { IFileModel } from "../file/FileModel";
import { ISecurityQuestionAnswer } from "./MessageSecurityQuestionModel";

export interface IDraftMailMessage {
  messageDetail: IDraftMessageModel
  recipients: IDraftMessageRecipientModel[];
  attachments: IFileModel[];
  recipientSecurityQuestions: ISecurityQuestionAnswer[];
}

export interface IDraftMessageRecipientModel {
  draftedMessageRecipientId: number,
  draftedMessageId: number,
  recipientId?: number,
  email: string,
  firstName: string,
  lastName: string
  securityQuestionId: number,
  securityQuestion: string,
  securityAnswer: string
  companyId: number
}

export const intialDraftMessageRecipientModel: IDraftMessageRecipientModel = {
  draftedMessageRecipientId: 0,
  draftedMessageId: 0,
  email: "",
  firstName: "",
  lastName: "",
  securityQuestionId: 0,
  securityQuestion: "",
  securityAnswer: "",
  companyId: 0
};

export interface IDraftMessageModel {
  authenticationMethod: AuthenticationMethod,
  companyId: number,
  count: number,
  createdDate: Date,
  draftedMessageId: number,
  emailAddress: string,
  expiresDays: number,
  firstName: string,
  lastName: string
  message: string
  modifiedDate: Date,
  notifyWhenDownloaded: boolean,
  subject: string,
  userId: number,
  filteredMessage: string
}

export const initialDraftMessage: IDraftMessageModel = {
  authenticationMethod: 0,
  companyId: 0,
  count: 0,
  createdDate: new Date(),
  draftedMessageId: 0,
  emailAddress: "",
  expiresDays: 1,
  firstName: "",
  lastName: "",
  message: "",
  modifiedDate: new Date(),
  notifyWhenDownloaded: false,
  subject: "",
  userId: 0,
  filteredMessage: ""
};
