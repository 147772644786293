import React from "react";
import Checkbox, { CheckBoxSize } from "../../../common/ui/Checkbox";
import { ISentMessageModel } from "../../../../core/viewModels/message/SentMessageModel";
import { getFormattedSentDate } from "../../../../helper/DateHelperFunctions";
import { MessageType } from "../../../../core/common/enums";
import { convertHtmlStringToText } from "../../../../helper/HelperFunctions";

interface ISentMessageListItemProps {
    message: ISentMessageModel;
    selectedMessageId: number;
    index: number;
    isChecked: boolean;
    onSelected: (message: ISentMessageModel, e: any) => void;
    onClick: (message: ISentMessageModel, e: any) => void;
}

const SentMessageListItem: React.FC<ISentMessageListItemProps> = props => {
    const handleCheckboxOnClick = (e: any) => {
        e.stopPropagation();
    };

    const getRecipientList = (value: string) => {
        const cell = (value && value.split(", ")) || [];
        return cell.length > 1 ? (
            <div className="pointer padding-right-20-px with-badge">
                <span className="ellipsis">{cell[0].length > 45 ? cell[0].substring(0, 30) + "..." : cell[0]}</span>
                <span className="margin-left-5-px message-item-datetime" style={{ fontWeight: "normal" }}>
                    +{cell.length - 1}{" "}
                </span>
            </div>
        ) : (
            <span title={cell[0]}>{cell[0] || "-"}</span>
        );
    };

    const handleCheckboxOnChange = (e: any) => {
        e.stopPropagation();
        props.onSelected(props.message, e);
    };
    return (
        <div
            className={`card message-item ${
                props.selectedMessageId === props.message.sentMessageId && "message-item-selected"
            }`}
            key={props.index}
            onClick={e => props.selectedMessageId !== props.message.sentMessageId && props.onClick(props.message, e)}
        >
            <div className="card-body message-item-body">
                <div className="message-item-checkbox">
                    <Checkbox
                        id={`chkMessage_${props.message.sentMessageId}_${MessageType.SENT}`}
                        text=""
                        size={CheckBoxSize.sm}
                        checked={props.isChecked}
                        onChange={handleCheckboxOnChange}
                        onClick={handleCheckboxOnClick}
                    />
                </div>
                <div className="message-item-group">
                    <div className="row">
                        <div className="col-9">
                            <span className="message-item-title text-ellipsis ellipsis">
                                {getRecipientList(props.message.recipientList)}
                            </span>
                        </div>
                        <div className="col-3 text-end">
                            <span className="message-item-datetime">
                                {getFormattedSentDate(props.message.sentDate)}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div
                                className="message-item-subject ellipsis"
                                style={{ maxWidth: "250px", maxHeight: "20px" }}
                            >
                                {props.message.subject || <span>&nbsp;</span>}
                            </div>
                            <div
                                className="message-item-messagebody ellipsis"
                                style={{ maxWidth: "250px", maxHeight: "20px" }}
                            >
                                {convertHtmlStringToText(props.message.message)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SentMessageListItem;
