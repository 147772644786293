export enum actionTypes {
  REQUEST_COMPOSE_RECIPIENTS = "request_get_recipients",
  RECEIVED_COMPOSE_RECIPIENTS = "received_compose_recipients",
  COMPLETED_COMPOSE_RECIPIENTS = "completed_compose_recipients",
  REQUEST_UPLOAD_ATTACHMENT_SAS = "request_upload_attachment_sas",

  REQUEST_SEND_MESSAGE = "request_send_message",
  RECEIVED_SEND_MESSAGE = "received_send_message",
  COMPLETED_SEND_MESSAGE = "completed_send_message",

  REQUEST_AUTH_RETENTIONS = "request_auth_retentions",
  RECEIVED_AUTH_RETENTIONS = "received_auth_retentions",
  COMPLETED_AUTH_RETENTIONS = "completed_auth_retentions",

  REQUEST_REMOVE_ATTACHMENT = "request_remove_attachment",
  COMPLETED_REMOVE_ATTACHMENT = "completed_remove_attachment",

  REQUEST_SET_DEFAULT_AUTHENTICATION = "request_set_default_authentication",
  COMPLETED_SET_DEFAULT_AUTHENTICATION = "completed_set_default_authentication",

}
