import { Log, UserManager } from "oidc-client";
import config from "./config";
Log.logger = console;
Log.level = Log.ERROR;

export const createUserManager = () => new UserManager({ ...config, monitorSession: false });
export const createUserManagerWithRedirect = (redirect_uri: string) => new UserManager({ ...config, monitorSession: false, redirect_uri: redirect_uri });
const tokenElementId = "RequestVerificationToken";
/**
 * @deprecated remove all usages of this function during the tech debt sprint, instead get token from auth store or oidc-client's userManager
 * @param token access token to render on document root
 */
export const renderTokenElement = (token: string) => {
    const inputNode = document.getElementById(tokenElementId) as HTMLInputElement;
    if (inputNode) {
        inputNode.value = token;
    } else {
        const node = document.createElement("input") as HTMLInputElement;
        node.setAttribute("type", "hidden");
        node.setAttribute("id", tokenElementId);
        node.setAttribute("name", tokenElementId);
        node.value = token;
        document.getElementById("root")?.appendChild(node);
    }
};
export function getRequestVerificationToken() {
    return (document.getElementById("RequestVerificationToken") as HTMLInputElement).value;
}