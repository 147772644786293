import produce from "immer";
import { Reducer, Action } from "redux";
import { DispatchActions } from "../actionCreators/companyMfaSettingActionCreators";
import { ICompanyMfaSettings, initialMfaSetting } from "../core/viewModels/company/CompanySettingsViewModel";
import { actionTypes as templateActions } from "../types/mfaTypes";

export const reducer: Reducer<ICompanyMfaSettings> = produce(
    (draft: ICompanyMfaSettings = initialMfaSetting, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case templateActions.REQUEST_COMPANY_MFA_SETTINGS:
                return draft;
            case templateActions.RECEIVE_COMPANY_MFA_SETTINGS:
                return action.settings;
            default:
                return draft;
        }
    }
);