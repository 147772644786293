import { Action, Reducer } from "redux";
import { IDocumentCategoryModel } from "../core/viewModels/settings/DocumentCategory";
import produce from "immer";
import * as documentCategory from "../actionCreators/documentCategoryActionCreator";
import { actionTypes } from "../types/documentCategoryTypes";

export interface IDocumentCategoryStore {
    isLoading: boolean;
    data: IDocumentCategoryModel[];
    requestCategoriesTableData: IDocumentCategoryModel[];
}

const initialState: IDocumentCategoryStore = {
    isLoading: false,
    data: [],
    requestCategoriesTableData: []
};

export const reducer: Reducer<IDocumentCategoryStore> = produce(
    (state: IDocumentCategoryStore = initialState, incomingAction: Action) => {
        const action = incomingAction as documentCategory.KnownActions;
        switch (action.type) {
            case actionTypes.REQUEST_DOCUMENT_CATEGORY:
            case actionTypes.CREATE_DOCUMENT_CATEGORY:
            case actionTypes.DELETE_DOCUMENT_CATEGORY:
            case actionTypes.UPDATE_DOCUMENT_CATEGORY:
            case actionTypes.REQUEST_DOCUMENT_CATEGORY_DROPDOWN:
                return { ...state, isLoading: true };
            case actionTypes.RECEIVE_DOCUMENT_CATEGORY:
                return { ...state, isLoading: false, requestCategoriesTableData: action.data };
            case actionTypes.RECEIVE_DOCUMENT_CATEGORY_DROPDOWN:
                return { ...state, isLoading: false, data: action.data };
            case actionTypes.COMPLETED_DOCUMENT_CATEGORY:
            case actionTypes.COMPLETED_DOCUMENT_CATEGORY_DROPDOWN:
                return { ...state, isLoading: false };
            default:
                return state;
        }
    }
);
