export const AutomationIdConstants = {
    commonSihRih: {
        ReportHeaderManCol: "SSE_MH_BtnManageColumns",
        ReportHeaderFilterIcon: "SSE_MH_BtnFilter",
        ReportHeaderTitle: "SSE_MH_TitleHeader",
        ReportHeaderDwldIcon: "SSE_MH_BtnDownloadTitle",
        ReportTableTotCount: "SSE_MH_TotalCount",
        ReportHeaderRefreshIcon: "SSE_MH_RefreshIcon",
        FiltersSaveFilter: "SSE_MH_SaveFilter",
        ReportHeaderFilterBadge: "SSE_MH_FilterBadge",
        DrawerCancelBtn: "SSE_MH_CancelBtn",
        DrawerSaveBtn: "SSE_MH_SaveBtn",
        FiltersClearBtn: "SSE_MH_ClearBtn",
        FiltersDeleteFilterBtn: "SSE_MH_DeleteFilterBtn",
        CustomModalCancelBtn: "SSE_MH_FilterCancelTextBtn",
        FiltersCancelledText: "SSE_MH_CancelledText",
        DocReqDetailedDropdownMenu: "SSE_MH_DropdownMenu",
        FilterDropDownFilterOptions: "SSE_MH_DropDownFiltOptions",
        DrawerCloseBtn: "SSE_MH_DrawerClose",
        DrawerTitle: "SSE_MH_DrawerTitle",
        DrawerSaveAndApplyBtn: "SSE_MH_SaveAndApply",
        CustomModalSaveBtn: "SSE_MH_SaveTextBtn",
        CustomModalSaveAndApplyTextBtn: "SSE_MH_SaveAndApplyBtn",
        FilterDeleteFilterTxt: "SSE_MH_DeleteFilterTxt",
        CustomModalTitel: "SSE_MH_ModalTitle", 
        CustomModalClose: "SSE_MH_ModalClose", 
        FilterPlaceHolderFilterName: "SSE_MH_PlaceHolderFilterName",
        FilterDropDownPlaceHolder: "SSE_MH_DropDownPlaceHolder",
        RecipientsPopOverTitle: "SSE_MH_PopOverHeader",
        RecipientsPopOverBody: "SSE_MH_PopOverBody",
        ReportHeaderMessage: "SSE_MH_Message",
        ReportTableRCPopup: "SSE_MH_RightClickPopUp",
        FiltersTextDanger: "SSE_MH_TextDanger",
        FilterRadioBtn: "SSE_MH_RadioBtn"
    },

    userSettingsMyAccount: {
        MyAccountProfiletitle: "SSE_MA_ProfileTitle",
        ProfileInformationTitle: "SSE_MA_ProfileInformationTitle",
        ProfileTitleFirstName: "SSE_MA_TitleFirstName",
        ProfileTitleEmailAddress: "SSE_MA_TitleEmailAddress",
        ProfileTitlePhoneNumber: "SSE_MA_TitlePhoneNumber",
        ProfileTitleLastName: "SSE_MA_TitleLastName",
        ProfileTitleExtension: "SSE_MA_TitleExtension",
        ProfileTitleFaxNumber: "SSE_MA_TitleFaxNumber",
        ProfileTitleCancel: "SSE_MA_TitleCancel",
        ProfileTitleSave: "SSE_MA_Titlesave",
        PasswordTitleChangePassword: "SSE_MA_TitleChangePassword",
        PasswordTitleCurrentPassword: "SSE_MA_TitleCurrentPassword",
        PasswordTitleNewPassword: "SSE_MA_TitleNewPassword",
        TitleConfirmPasswordText: "SSE_MA_TitleConfirmPassword",
        PasswordChangeOkBtn: "SSE_MA_TitleUpdatePassword",
        PasswordTitleEnterMobileNo: "SSE_MA_TitleMobileNumber"
    },
    reportProblem: {
        ReportProblemBtn: "SSE_RP_ReportProblemBtn",
        ReportModalHeader: "SSE_RP_ReportProblemTitle",
        ReportModalCancleBtnText: "SSE_RP_CancleBtn",
        ReportModalSubmitBtnText: "SSE_RP_SubmitBtn",
        ReportModalNoDescription: "SSE_RP_NoDescription",
        ReportModalEmailInvalid: "SSE_RP_InvalidEmail",
        ReportModalEmailLabel: "SSE_RP_EmailLabel",
        ReportModalDescription: "SSE_RP_Description",
        MyAccountModalTitle: "SSE_RP_MyAccountTitle",
        ProfileEmailAddress: "SSE_RP_EmailAddress",
        ProfilePhoneNumber: "SSE_RP_PhoneNumber",
        MessageListItem:"SSE_RP_MessageListItem"
    },
    logoutModal :{
        LogoutSubmitBtn:"SSE_LM_SubmitBtn",
        LogoutCancelBtn:"SSE_LM_CancelBtn"
    },
    addFolderModal:{
        AddFolderBtn:"SSE_AF_AddFolderBtn",
        FolderName:"SSE_AF_FolderName",
        Private:"SSE_AF_Private",
        Shared:"SSE_AF_Shared",
        Description:"SSE_AF_Description",
        RetentionPeriod:"SSE_AF_Retention",
        CreateFolderBtn:"SSE_AF_CreateBtn",
        CancelBtn:"SSE_AF_CancelBtn",
        NextBtn:"SSE_AF_NextBtn",
        EditFolderName:"SSE_EF_EditFolderName",
        EditDescription:"SSE_EF_Descritpion",
        SaveBtn:"SSE_EF_SaveBtn",
        HeaderCloseBtn:"SSE_AF_HeaderCloseBtn",
    },
    shareFolderModal:{
        ShareBtn:"SSE_SF_ShareBtn",
    },
    retentionPeriodModal:{
        CancelBtn:"SSE_CRP_CancelBtn",
        CloseBtn:"SSE_CRP_CloseBtn",
        OkBtn:"SSE_CRP_OkBtn",
        RetentionPeriod:"SSE_CRP_Retention",
    },
    deleteBtnModal:{
        CancelBtn:"SSE_DF_CancelBtn",
        ConfirmBtn:"SSE_DF_ConfirmBtn",
    },
    moveToModal: {
        MoveToCancelBtn:"SSE_MF_CancelBtn",
        MoveToOkBtn:"SSE_MF_OkBtn",
    },
};
