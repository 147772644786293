import { connect } from "react-redux";
import { SignalRWebSocket } from "./SignalRWebSocket";
import { ApplicationState } from "../../store";
import {
    addToGroup,
    archiveFolderReload,
    getWebSocketConnectionUrl,
    initiateSignalRConnection,
    refreshSharedFolderRecipient,
    userPrivilegesChanged,
} from "../../actionCreators/signalRActionCreator";
import { fetchSentMessages } from "../../actionCreators/sentMessageActionCreators";
import { fetchMessages } from "../../actionCreators/receivedMessageActionCreators";
import { fetchDraftMessages, getDraftMessageDetail } from "../../actionCreators/draftMessageActionCreator";
import { requestMyDownloadsList } from "../../actionCreators/folderActionCreator";
import { fetchSpamMessages } from "../../actionCreators/spamMessageActionCreators";
export default connect(
    (state: ApplicationState) => ({
        profile: state.userAuth.user,
        showAutoLogoutModal: state.signalRState.showAutoLogoutModal,
    }),
    {
        addToGroup,
        getWebSocketConnectionUrl,
        initiateSignalRConnection,
        fetchDraftMessages,
        getDraftMessageDetail,
        requestMyDownloadsList,
        fetchSentMessages,
        fetchMessages,
        fetchSpamMessages,
        userPrivilegesChanged,
        archiveFolderReload,
        refreshSharedFolderRecipient,
    }
)(SignalRWebSocket as any);
