import axios, { AxiosResponse } from "axios";
import { CompletedRbacResourceList, ReceivedRbacResourceList, RequestRbacResourceList } from "../actions/rbacActions";
import { AppThunkAction } from "../store";
import { actionTypes as rbacActions} from "../types/rbacTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "../reducers/notificationReducer";
import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { ResourceValidator } from "@sssuite-component-ui/resource-id-validator";
import { RBACPermission } from "../helper/ResourceIdLocators";


export type DispatchActions = RequestRbacResourceList |
ReceivedRbacResourceList |
CompletedRbacResourceList

export type KnownActions = DispatchActions | NotificationAction;

export const fetchRbacResourceList =
  (
   
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: rbacActions.REQUEST_RBAC_RESOURCE_LIST,
      });

      axios
        .get(
          `${process.env.REACT_APP_API_URL_SUITE}/api/resource/${ProductType.Exchange}`
        )
        .then((response: AxiosResponse<string[]>) => {
          const data = response.data;

          const validator = new ResourceValidator(data, "disable", "click", RBACPermission.operation);
          validator.start();
          dispatch({
            type: rbacActions.RECEIVED_RBAC_RESOURCE_LIST,
            payload: data
          });
         
          
        })
        .catch((error) => {
          dispatch({
            type: notificationAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: rbacActions.COMPLETED_RBAC_RESOURCE_LIST,
          });
        });
    };