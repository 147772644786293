import React, { useState, useEffect } from "react";
import { Option } from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";

import { ChangeExpiryIcon, DeleteIcon, DropOffFolderGridIcon, MoveToFolderIcon } from "../../svg/ToolbarIcons";
import { AppNotifier } from "../../../helper/AppNotifier";
import { FolderEventType, FolderToolbarConstants, MyDownloadsModalConstants } from "../../../helper/Constants";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import {
    deleteFolders,
    fetchFoldersTree,
    fetchMaxRetentionPeriodLimit,
    fetchSharedFolderUsers,
} from "../../../actionCreators/folderActionCreator";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { ColorConstants } from "../../../assets/custom/colors";
import { ArchiveFolderType, FolderType, ShareFolderTypes } from "../../../core/common/enums";
import { MoveFolderModal } from "../../common/ui/Modals/FolderModal/MoveFolderModal";
import { ChangeRetentionPeriodModal } from "../../common/ui/Modals/FolderModal/ChangeRetentionPeriodModal";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { ApplicationState } from "../../../store";
import { getRetentionOptions, isFolderOwner } from "../../../helper/HelperFunctions";
import { ShareFolderModal } from "./ShareFolderModal";
import { ShareFolderGridIcon } from "../../svg/ToolbarIcons";
import { CreateFolderModal } from "../../common/ui/Modals/FolderModal/CreateFolderModal";
import FolderDropOffModal from "../../common/ui/Modals/FolderModal/FolderDropOffModal";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

interface IFolderToolbarProps {
    isAnySelected: boolean;
    selectedFolders: IFolderModel[];
    reset: boolean;
    currentfolder: IFolderModel;
    isHeaderToolbar: boolean;
    isMoveToFolderEnabled: boolean;
    isSearched?: boolean;
    setReload: (value: boolean) => void;
    resetSelection: () => void;
    resetSearchFolderTextAndSort: () => void;
}

const FolderToolbar: React.FC<IFolderToolbarProps> = props => {
    const dispatch = useDispatch();
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDelete, toggleShowDelete] = useState(false);
    const [showMoveFolderModal, setShowMoveFolderModal] = useState(false);
    const [showChangeRetentionModal, setShowChangeRetentionModal] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const [showShareFolderModal, setShowShareFolderModal] = useState(false);
    const [showFolderDropOffModal, setShowFolderDropOffModal] = useState(false);
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);
    const user = useSelector((state: ApplicationState) => state.userProfile);

    useEffect(() => {
        if (props.selectedFolders[0] && props.selectedFolders[0]?.parentId !== 0) {
            dispatch(fetchSharedFolderUsers(props.selectedFolders[0]?.parentId));
        }
    }, [props.selectedFolders]);

    useEffect(() => {
        props.currentfolder.folderId === 0
            ? setRetentionPeriods(getRetentionOptions(retentionData.retentionPeriods))
            : setRetentionPeriods(
                  getRetentionOptions(
                      retentionData.retentionPeriods.filter(rp => rp.days <= props.currentfolder.expiresDays)
                  )
              );
    }, [retentionData.retentionPeriods.length, props.currentfolder.folderId]);

    const deleteFolderBodyMssg =
        props.selectedFolders.length > 1 ? FolderToolbarConstants.DELETE_FOLDERS : FolderToolbarConstants.DELETE_FOLDER;

    const deleteFolderTitle =
        props.selectedFolders.length > 1
            ? FolderToolbarConstants.TITLE_DELETE_FOLDERS
            : FolderToolbarConstants.TITLE_DELETE_FOLDER;

    useEffect(() => {
        toggleShowDelete(false);
    }, [props.reset]);

    const handleOnClickDelete = () => {
        toggleShowDelete(true);
    };

    const handleOnClickChangeRetention = () => {
        dispatch(
            fetchMaxRetentionPeriodLimit(props.selectedFolders[0].folderId, (result: number) => {
                setRetentionPeriods(
                    getRetentionOptions(retentionData.retentionPeriods.filter(rp => rp.days <= result))
                );
                setShowChangeRetentionModal(true);
            })
        );
    };

    const handleOnClickMoveToFolder = () => {
        dispatch(fetchFoldersTree([], [FolderType.INBOX]));
        setShowMoveFolderModal(true);
    };

    const handleMoveFolderSubmit = () => {
        setShowMoveFolderModal(false);
        props.resetSelection();
        props.resetSearchFolderTextAndSort();
        props.setReload(true);
    };

    const handleChangeRetentionSubmit = () => {
        setShowChangeRetentionModal(false);
        props.resetSelection();
        props.resetSearchFolderTextAndSort();
        props.setReload(true);
    };

    const handleOnSubmitDelete = () => {
        dispatch(
            deleteFolders(
                props.selectedFolders.map(f => f.folderId),
                result => {
                    if (result) {
                        AppNotifier.Success(
                            props.selectedFolders.length > 1
                                ? FolderToolbarConstants.FOLDERS_DELETED_SUCCESS
                                : FolderToolbarConstants.FOLDER_DELETED_SUCCESS
                        );
                    }
                    toggleShowDelete(false);
                    props.resetSelection();
                    props.resetSearchFolderTextAndSort();
                    props.setReload(true);
                },
                props.isHeaderToolbar
                    ? ResourceIdLocators.MyFiles.MyFilesBtnDeleteFolders
                    : ResourceIdLocators.MyFiles.MyFilesBtnDeleteFolder
            )
        );
        props.setReload(false);
    };

    const handleRenameModalSubmition = () => {
        setShowRenameModal(false);
        props.resetSearchFolderTextAndSort();
        props.setReload(true);
        props.resetSelection();
    };

    const onHideShareFolderModal = () => {
        setShowShareFolderModal(false);
        props.resetSearchFolderTextAndSort();
        props.setReload(true);
        props.resetSelection();
    };

    const handleHideFolderDropOffModal = () => {
        setShowFolderDropOffModal(false);
        props.resetSelection();
    };

    const isModificationAllowed = () => {
        if (
            isFolderOwner(props.selectedFolders[0], user) ||
            (props.selectedFolders?.length > 0 &&
                props.selectedFolders[0].permissionGroupId === ShareFolderTypes.EDIT &&
                props.selectedFolders[0].isPermissiomInherited)
        ) {
            return true;
        } else if (
            props.selectedFolders[0].permissionGroupId === ShareFolderTypes.NONE &&
            !props.selectedFolders[0].isPermissiomInherited
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="inline-actions-bar-container">
                {!props.isHeaderToolbar && (
                    <>
                        <div className="folder-toolbar__action">
                            <div
                                className={
                                    props.selectedFolders[0].folderType === ArchiveFolderType.FOLDER
                                        ? "pointer toolbar-icon-disabled"
                                        : "pointer"
                                }
                                title={
                                    props.selectedFolders[0].folderType === ArchiveFolderType.FOLDER
                                        ? FolderToolbarConstants.TITLE_FOLDER_DROPOFF_DISABLED
                                        : FolderToolbarConstants.TITLE_FOLDER_DROPOFF
                                }
                                onClick={() => {
                                    props.selectedFolders[0].folderType !== ArchiveFolderType.FOLDER &&
                                        setShowFolderDropOffModal(true);
                                }}
                                data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnCopyFolder}
                            >
                                <DropOffFolderGridIcon
                                    width={16}
                                    height={16}
                                    disabled={props.selectedFolders[0].folderType === ArchiveFolderType.FOLDER}
                                    color={ColorConstants.grey}
                                />
                            </div>
                        </div>

                        {isFolderOwner(props.selectedFolders[0], user) && (
                            <div className="folder-toolbar__action">
                                <div
                                    className={"pointer"}
                                    title={FolderToolbarConstants.TITLE_SHARE}
                                    onClick={() => {
                                        setShowShareFolderModal(true);
                                    }}
                                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnShareFolder}
                                >
                                    <ShareFolderGridIcon
                                        width={16}
                                        height={16}
                                        disabled={false}
                                        color={ColorConstants.grey}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="folder-toolbar__action">
                            {isModificationAllowed() ? (
                                <div
                                    className={"pointer"}
                                    title={FolderToolbarConstants.TITLE_EDIT}
                                    onClick={() => {
                                        setShowRenameModal(true);
                                    }}
                                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnRenameFolder}
                                >
                                    <i
                                        style={{
                                            color: ColorConstants.grey,
                                        }}
                                        className="fas fa-pen"
                                    ></i>
                                </div>
                            ) : (
                                <div className="toolbar-icon-disabled" title={FolderToolbarConstants.TITLE_EDIT}>
                                    <i
                                        style={{
                                            color: ColorConstants.grey_disabled,
                                        }}
                                        className="fas fa-pen"
                                    ></i>
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div className="folder-toolbar__action">
                    <span
                        onClick={() =>
                            props.isAnySelected &&
                            props.isMoveToFolderEnabled &&
                            !props.isSearched &&
                            isFolderOwner(props.selectedFolders[0], user) &&
                            handleOnClickMoveToFolder()
                        }
                        title={FolderToolbarConstants.TITLE_MOVE_TO_FOLDER}
                        data-resource-id={
                            props.isHeaderToolbar
                                ? ResourceIdLocators.MyFiles.MyFilesBtnMoveFolders
                                : ResourceIdLocators.MyFiles.MyFilesBtnMoveFolder
                        }
                        className={`${
                            !props.isAnySelected ||
                            !props.isMoveToFolderEnabled ||
                            props.isSearched ||
                            !isFolderOwner(props.selectedFolders[0], user)
                                ? "toolbar-icon-disabled"
                                : ""
                        }`}
                    >
                        <MoveToFolderIcon
                            width={props.isHeaderToolbar ? 18 : 16}
                            height={props.isHeaderToolbar ? 18 : 16}
                            color={ColorConstants.grey}
                            disabled={
                                !props.isAnySelected ||
                                !props.isMoveToFolderEnabled ||
                                props.isSearched ||
                                !isFolderOwner(props.selectedFolders[0], user)
                            }
                        />
                    </span>
                </div>

                <div className="folder-toolbar__action">
                    <span
                        onClick={() =>
                            props.isAnySelected &&
                            !props.isSearched &&
                            isModificationAllowed() &&
                            handleOnClickChangeRetention()
                        }
                        title={FolderToolbarConstants.TITLE_CHANGE_RETENTION}
                        data-resource-id={
                            props.isHeaderToolbar
                                ? ResourceIdLocators.MyFiles.MyFilesBtnChangeRetentions
                                : ResourceIdLocators.MyFiles.MyFilesBtnChangeRetention
                        }
                        className={`${
                            !props.isAnySelected || props.isSearched || !isModificationAllowed()
                                ? "toolbar-icon-disabled"
                                : ""
                        }`}
                        data-auto-test={AutomationIdConstants.retentionPeriodModal.RetentionPeriod}
                    >
                        <ChangeExpiryIcon
                            width={props.isHeaderToolbar ? 18 : 16}
                            height={props.isHeaderToolbar ? 18 : 16}
                            color={ColorConstants.grey}
                            disabled={!props.isAnySelected || props.isSearched || !isModificationAllowed()}
                        />
                    </span>
                </div>

                <div className="folder-toolbar__action">
                    <span
                        onClick={() => props.isAnySelected && isModificationAllowed() && handleOnClickDelete()}
                        title={FolderToolbarConstants.TITLE_DELETE}
                        data-resource-id={
                            props.isHeaderToolbar
                                ? ResourceIdLocators.MyFiles.MyFilesBtnDeleteFolders
                                : ResourceIdLocators.MyFiles.MyFilesBtnDeleteFolder
                        }
                        className={`${!props.isAnySelected || !isModificationAllowed() ? "toolbar-icon-disabled" : ""}`}
                        data-auto-test={AutomationIdConstants.deleteBtnModal.CancelBtn}
                    >
                        <DeleteIcon
                            width={props.isHeaderToolbar ? 18 : 16}
                            height={props.isHeaderToolbar ? 18 : 16}
                            color={ColorConstants.grey}
                            disabled={!props.isAnySelected || !isModificationAllowed()}
                        />
                    </span>
                </div>
            </div>
            {showRenameModal && (
                <CreateFolderModal
                    show={showRenameModal}
                    currentFolder={props.selectedFolders[0]}
                    onHide={() => {
                        setShowRenameModal(false);
                        props.resetSelection();
                    }}
                    renameBtnResourceId={ResourceIdLocators.MyFiles.MyFilesBtnRenameFolder}
                    onSubmit={handleRenameModalSubmition}
                    modalType={FolderEventType.Edit}
                />
            )}
            {showMoveFolderModal && (
                <MoveFolderModal
                    isMovingFolder={true}
                    folderType={FolderType.ARCHIVE}
                    selectedFolders={props.selectedFolders}
                    showState={showMoveFolderModal}
                    onHide={() => {
                        setShowMoveFolderModal(false);
                        props.resetSelection();
                    }}
                    onSubmit={handleMoveFolderSubmit}
                    moveFolderResourceId={
                        props.isHeaderToolbar
                            ? ResourceIdLocators.MyFiles.MyFilesBtnMoveFolders
                            : ResourceIdLocators.MyFiles.MyFilesBtnMoveFolder
                    }
                    onError={() => {
                        props.resetSearchFolderTextAndSort();
                        props.setReload(true);
                        props.resetSelection && props.resetSelection();
                    }}
                />
            )}

            {showChangeRetentionModal && (
                <ChangeRetentionPeriodModal
                    selectedFolders={props.selectedFolders}
                    showState={showChangeRetentionModal}
                    retentionOptions={retentionPeriods}
                    onHide={() => {
                        setShowChangeRetentionModal(false);
                        props.resetSelection();
                    }}
                    onSubmit={handleChangeRetentionSubmit}
                    changeRtnResourceId={
                        props.isHeaderToolbar
                            ? ResourceIdLocators.MyFiles.MyFilesBtnChangeRetentions
                            : ResourceIdLocators.MyFiles.MyFilesBtnChangeRetention
                    }
                />
            )}

            {showDelete && (
                <CustomModal
                    show={showDelete}
                    cancelButtonName="Cancel"
                    confirmButtonName="Confirm"
                    title={deleteFolderTitle}
                    onSubmit={handleOnSubmitDelete}
                    onHide={() => {
                        toggleShowDelete(false);
                        props.resetSelection();
                    }}
                >
                    <div>
                        <span>{deleteFolderBodyMssg}</span>
                    </div>
                    <div className="delete-warning">
                        <i className="fas fa-exclamation-triangle margin-right-10-px" />
                        <span>{MyDownloadsModalConstants.DELETE_WARNING}</span>
                    </div>
                </CustomModal>
            )}
            {showShareFolderModal && (
                <ShareFolderModal
                    show={showShareFolderModal}
                    onSubmit={() => {
                        // Don't have Submit button in Share Folder Modal so this function will be empty
                    }}
                    folder={props.selectedFolders[0]}
                    onHide={onHideShareFolderModal}
                />
            )}
            {showFolderDropOffModal && (
                <FolderDropOffModal
                    show={showFolderDropOffModal}
                    folderId={props.selectedFolders[0]?.folderId}
                    onHide={handleHideFolderDropOffModal}
                />
            )}
        </>
    );
};

FolderToolbar.displayName = FolderToolbar.name;
export default FolderToolbar;
