import axios from "axios";
import {
    completedDocumentRequestHistory,
    completedDocumentRequestHistoryMetrics,
    receiveDocumentRequestHistoryMetrics,
    recieveDocumentRequestHistory,
    requestDocumentRequestHistory,
    requestDocumentRequestHistoryMetrics,
} from "../actions/documentRequestHistoryAction";
import { AppThunkAction } from "../store";
import { actionTypes } from "../types/documentRequestHistoryType";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { IMetricsCardModel } from "../core/viewModels/report/reportViewModel";
import { ReportConstants } from "../helper/Constants";
import { IReportItemsHistoryRequestModel } from "../core/viewModels/report/SentItemsHistoryModel";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";

export type KnownActions =
    | requestDocumentRequestHistory
    | recieveDocumentRequestHistory
    | completedDocumentRequestHistory
    | requestDocumentRequestHistoryMetrics
    | receiveDocumentRequestHistoryMetrics
    | completedDocumentRequestHistoryMetrics
    | NotificationAction;

export const getDocumentReportHistory =
    (
        payload: IReportItemsHistoryRequestModel,
        callback?: () => void,
        mailboxUserEmailId = getMailboxUserEmailId()
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_DOCUMENT_REQUEST_HISTORY });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/DocumentRequestHistory/GetDocumentRequestHistory?mailboxUserEmailId=${mailboxUserEmailId}`,
                payload
            )
            .then(response => {
                const data = response.data;
                dispatch({ type: actionTypes.RECEIVE_DOCUMENT_REQUEST_HISTORY, payload: data });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_REQUEST_HISTORY });
            });
    };

export const getDocumentHistoryMetrics = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({ type: actionTypes.REQUEST_DOCUMENT_REQUEST_HISTORY_METRICS });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/DocumentRequestHistory/GetDRMetrics`)
        .then(response => {
            const data = response.data;
            const metrics: IMetricsCardModel[] = [];
            data &&
                Object.keys(data).map(id => {
                    let title = "";
                    switch (id) {
                        case ReportConstants.DRH_METRICS_IDS[0]:
                            title = ReportConstants.DRH_METRICS_TITLES[0];
                            break;
                        case ReportConstants.DRH_METRICS_IDS[1]:
                            title = ReportConstants.DRH_METRICS_TITLES[1];
                            break;
                    }
                    metrics.push({
                        title,
                        count: data[id],
                    });
                });
            dispatch({ type: actionTypes.RECEIVE_DOCUMENT_REQUEST_HISTORY_METRICS, payload: metrics });
        })
        .catch(error => {
            dispatch({
                type: notificationActions.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({ type: actionTypes.COMPLETED_DOCUMENT_REQUEST_HISTORY_METRICS });
        });
};
