import axios, { AxiosResponse } from "axios";

import { AppThunkAction } from "../store";
import { actionTypes as securityQuestionActionTypes } from "../types/securityQuestionTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { SettingMode } from "../core/common/enums";
import {
    RequestSecurityQuestions,
    ReceivedSecurityQuestions,
    ReceivedCompanySecurityQuestions,
    CompletedSecurityQuestions,
    RequestAddSecurityQuestionAction,
    CompletedAddSecurityQuestionAction,
    RequestAddCompanySecurityQuestionAction,
    CompletedAddCompanySecurityQuestionAction,
    RequestUpdateCompanySecurityQuestionAction,
    CompletedUpdateCompanySecurityQuestionAction,
    RequestCompanySecurityQuestions,
    RequestRecipientQuestionAnswer,
    CompletedRecipientQuestionAnswer,
    RequestUpdateUserSecurityQuestionAction,
    CompletedUpdateUserSecurityQuestionAction,
} from "../actions/securityQuestionActions";
import { StatusType, NotificationAction } from "../reducers/notificationReducer";
import {
    IMessageSecurityQuestion,
    ISecurityQuestionAnswer,
} from "../core/viewModels/message/MessageSecurityQuestionModel";
import { logger } from "../oidcClient/authProvider";
import { appInsightsData } from "../helper/AppInsightsData";

export type DispatchActions =
    | RequestSecurityQuestions
    | RequestCompanySecurityQuestions
    | ReceivedSecurityQuestions
    | ReceivedCompanySecurityQuestions
    | CompletedSecurityQuestions
    | RequestAddSecurityQuestionAction
    | CompletedAddSecurityQuestionAction
    | RequestUpdateUserSecurityQuestionAction
    | CompletedUpdateUserSecurityQuestionAction
    | RequestAddCompanySecurityQuestionAction
    | CompletedAddCompanySecurityQuestionAction
    | RequestUpdateCompanySecurityQuestionAction
    | CompletedUpdateCompanySecurityQuestionAction
    | RequestRecipientQuestionAnswer
    | CompletedRecipientQuestionAnswer;

export type KnownActions = DispatchActions | NotificationAction;

export interface IAuthenticationQuestion {
    questionTitle: string;
    question: string;
    isDefault: boolean;
}

export const fetchMessageSecurityQuestions =
    (settingMode: SettingMode, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        if (settingMode === SettingMode.COMPANY) {
            dispatch({
                type: securityQuestionActionTypes.REQUEST_COMPANY_SECURITY_QUESTIONS,
            });
        } else {
            dispatch({
                type: securityQuestionActionTypes.REQUEST_SECURITY_QUESTIONS,
            });
        }

        axios
            .get(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion?settingMode=${settingMode}`)
            .then((response: any) => {
                const data = response.data;
                if (settingMode == SettingMode.COMPANY) {
                    dispatch({
                        type: securityQuestionActionTypes.RECEIVED_COMPANY_SECURITY_QUESTIONS,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: securityQuestionActionTypes.RECEIVED_SECURITY_QUESTIONS,
                        payload: data,
                    });
                }

                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_SECURITY_QUESTIONS,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const addMessageSecurityQuestions =
    (
        securityQuestion: IMessageSecurityQuestion,
        isUserSecurityQuestion: boolean,
        callback?: any
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_ADD_SECURITY_QUESTION,
        });
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.MessageSecurityQuestionAdded}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
            }
        });

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/SecurityQuestion?isUserSecurityQuestion=${isUserSecurityQuestion}`,
                securityQuestion
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_ADD_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_ADD_SECURITY_QUESTION,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const deleteMessageSecurityQuestions =
    (questionIds: number[], callback?: (result: number, ids: number[]) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_UPDATE_SECURITY_QUESTION,
        });

        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion`, {
                data: questionIds,
            })
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(data, questionIds);
                }
            })
            .catch(error => {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_SECURITY_QUESTION,
                });
            });
    };

export const updateMessageSecurityQuestion =
    (questionPayload: IMessageSecurityQuestion, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_UPDATE_SECURITY_QUESTION,
        });

        axios
            .put(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion`, questionPayload)
            .then((response: AxiosResponse) => {
                const statusCode = response.status;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(statusCode);
                }
            })
            .catch(error => {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_SECURITY_QUESTION,
                });
            });
    };

export const addCompanySecurityQuestion =
    (questionPayload: IAuthenticationQuestion, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_ADD_COMPANY_SECURITY_QUESTION,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion/company`, questionPayload)
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_ADD_COMPANY_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_ADD_COMPANY_SECURITY_QUESTION,
                });
            });
    };

export const deleteCompanySecurityQuestions =
    (questionIds: number[], callback?: (result: number, ids: number[]) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_UPDATE_COMPANY_SECURITY_QUESTION,
        });

        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion/company`, {
                data: questionIds,
            })
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(data, questionIds);
                }
            })
            .catch(error => {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION,
                });
            });
    };

export const updateCompanySecurityQuestion =
    (questionPayload: IMessageSecurityQuestion, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_UPDATE_COMPANY_SECURITY_QUESTION,
        });

        axios
            .put(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion/company`, questionPayload)
            .then((response: AxiosResponse) => {
                const statusCode = response.status;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION,
                });
                if (callback) {
                    callback(statusCode);
                }
            })
            .catch(error => {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION,
                });
            });
    };

export const getRecipientQuestionAnswer =
    (recipientEmail: string, questionId : number , callback?: (data: ISecurityQuestionAnswer) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_RECIPIENT_QUESTION_ANSWER,
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion/GetRecipientSpecificQuestionAnswer`, 
            {
                "recipientEmail" : recipientEmail,
                "questionId" : (questionId > 0 ? questionId :0)
            }
                , {
                headers: { "Content-Type": "application/json" },
            })
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_RECIPIENT_QUESTION_ANSWER,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_RECIPIENT_QUESTION_ANSWER,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

    export const getRecipientDefaultQuestionAndAnswer =
    (
        recipientEmail: string,
        defaultQuestionId :number,
        callback?: (data: ISecurityQuestionAnswer) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: securityQuestionActionTypes.REQUEST_RECIPIENT_QUESTION_ANSWER,
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SecurityQuestion/GetRecipientDefaultQuestionWithAnswer`,
            {
                "recipientEmail" : recipientEmail,
                "questionId" : (defaultQuestionId > 0 ? defaultQuestionId :0)
            },
            {
                headers: { "Content-Type": "application/json" },
            })
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_RECIPIENT_QUESTION_ANSWER,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: securityQuestionActionTypes.COMPLETED_RECIPIENT_QUESTION_ANSWER,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };
