import { RecipientType } from "../../common/enums";

export interface ISentMessageRecipientModel {
  sentMessageRecipientId: number;
  sentMessageId: number;
  recipientId?: number;
  companyId: number;
  email: string;
  firstName: string;
  lastName: string;
  expiresDays: number;
  deletedByRecipient: number;
  isFilesDownloaded: boolean;
  folderId: number;
  fileRetryCount: number;
  accessDate: Date;
  securityAnswer: string;
  securityQuestion: string;
  isLinkLocked: number;
  recipientType: RecipientType;
}

export const initialSentMessageRecipientModel: ISentMessageRecipientModel = {
  sentMessageRecipientId: 0,
  sentMessageId: 0,
  recipientId: 0,
  companyId: 0,
  email: "",
  firstName: "",
  lastName: "",
  expiresDays: 0,
  deletedByRecipient: 0,
  isFilesDownloaded: false,
  folderId: 2,
  fileRetryCount: 0,
  accessDate: new Date(),
  securityAnswer: "",
  securityQuestion: "",
  isLinkLocked: 0,
  recipientType: RecipientType.ToRecipient,
};
