
export enum actionTypes {
  REQUEST_SENT_ITEMS_HISTORY = "request_sent_items_history",
  RECEIVE_SENT_ITEMS_HISTORY = "receive_sent_items_history",
  COMPLETED_SENT_ITEMS_HISTORY = "complete_sent_items_history",

  REQUEST_SENT_ITEMS_HISTORY_DOWNLOAD = "request_sent_items_history_download",
  RECEIVE_SENT_ITEMS_HISTORY_DOWNLOAD = "receive_sent_items_history_download",
  COMPLETED_SENT_ITEMS_HISTORY_DOWNLOAD = "complete_sent_items_history_download",

  REQUEST_SENT_ITEMS_HISTORY_METRICS = "request_sent_items_history_metrics",
  RECEIVE_SENT_ITEMS_HISTORY_METRICS = "receive_sent_items_history_metrics",
  COMPLETED_SENT_ITEMS_HISTORY_METRICS = "completed_sent_items_history_metrics",

}