export enum actionTypes {
    REQUEST_DROP_OFF_LINKS = "request_drop_off_links",
    RECEIVE_DROP_OFF_LINKS = "receive_drop_off_links",
    COMPLETE_DROP_OFF_LINKS = "complete_drop_off_links",
    RESET_DROP_OFF_LINKS = "reset_drop_off_links",
    
    REQUEST_DROP_OFF_SENDERS = "request_drop_off_senders",
    RECEIVE_DROP_OFF_SENDERS = "receive_drop_off_senders",
    COMPLETE_DROP_OFF_SENDERS = "complete_drop_off_senders",

    REQUEST_DROP_OFF_RECIPIENTS = "request_drop_off_recipients",
    RECEIVE_DROP_OFF_RECIPIENTS = "receive_drop_off_recipients",
    COMPLETE_DROP_OFF_RECIPIENTS = "complete_drop_off_recipients",
    
    REQUEST_UPDATE_DROP_OFF_SENDER = "request_update_drop_off_sender",
    COMPLETE_UPDATE_DROP_OFF_SENDER="complete_update_drop_off_sender",
  
    REQUEST_UPDATE_DROP_OFF_RECIPIENT = "request_update_drop_off_recipient",
    COMPLETE_UPDATE_DROP_OFF_RECIPIENT="complete_update_drop_off_recipient",
   
    REQUEST_DELETE_DROP_OFF_SENDER="request_delete_drop_off_senders",
    COMPLETE_DELETE_DROP_OFF_SENDER="complete_delete_drop_off_senders",

  }
  