import React, { useState } from "react";
import Checkbox, { CheckBoxSize } from "../../../common/ui/Checkbox";
import { FolderType, ShareFolderTypes } from "./../../../../core/common/enums";
import { DeleteIcon, MoveToFolderIcon, MoveToInboxIcon, SendLinkIcon, SpamIcon } from "./../../../svg/ToolbarIcons";
import ConfirmModal from "../../../common/ui/Modals/ConfirmModal";
import { AppNotifier } from "../../../../helper/AppNotifier";
import { MessageToolbarConstants, MyDownloadsModalConstants } from "../../../../helper/Constants";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { fetchFoldersTree } from "../../../../actionCreators/folderActionCreator";
import { ISentMessageModel } from "../../../../core/viewModels/message/SentMessageModel";
import { IDraftMessageModel } from "../../../../core/viewModels/message/DraftMailMessage";
import { CustomModal } from "../../../common/ui/Modals/CustomModal";
import { MoveFolderModal } from "../../../common/ui/Modals/FolderModal/MoveFolderModal";
import { IResourceLocator } from "../../../../helper/ResourceIdLocators";
import { CancelRequestIcon } from "../../../svg/IconCollection";
import { isFolderOwner } from "../../../../helper/HelperFunctions";
import { isMoveToFolderPopupOpen } from "../../../../actionCreators/signalRActionCreator";

interface IMessageToolbarProps {
    folderId: number;
    folderType: FolderType;
    isAllSelected: boolean;
    isAnySelected: boolean;
    resourceLocators: IResourceLocator;
    selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[] | IDraftMessageModel[];
    showSelectAllCheckbox: boolean;
    folder?: IFolderModel;
    deleteMessages: (callback?: any) => void;
    selectAll: (e: any) => void;
    markAsNotSpam?: (callback?: any) => void;
    moveToInbox?: (callback?: any) => void;
    printDocument?: () => void;
    cancelTransfer?: (callback?: any) => void;
    getFolders?: () => any;
    checkFolderExpiry?: (folderId: number) => boolean;
    moveToFolder?: (folderId: number, callback?: any) => void;
    resendAccessLink?: () => void;
    resendQAAuthAnswer?: () => void;
    reloadMessages?: () => void;
    hideMessageDetailView?: () => void;
    resetSelection?: () => void;
    resetSearchAndSort?: () => void;
}

const MessageToolbar: React.FC<IMessageToolbarProps> = props => {
    const dispatch = useDispatch();
    const [showDelete, toggleShowDelete] = useState(false);
    const [showSentMessageDelete, toggleShowSentMessageDelete] = useState(false);
    const [showCancelTransfer, toggleShowCancelTransfer] = useState(false);
    const [showFolder, toggleShowFolder] = useState(false);
    const [moveToFolder, setMoveToFolder] = useState<IFolderModel | undefined>();
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const { resourceLocators } = props;
    const userOwnedFolders = useSelector((appState: ApplicationState) => appState.folderState.userOwnedFolders.data);
    let isMoveToFolderEnabled =
        userOwnedFolders &&
        userOwnedFolders.some(folder => {
            return isFolderOwner(folder, user);
        });

    const { messageHistorySettings } = useSelector((state: ApplicationState) => state.adminSettingsState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);

    const handleOnHideDelete = () => {
        toggleShowDelete(false);
    };

    const handleOnSubmitDelete = () => {
        props.deleteMessages((result: any) => {
            if (result) {
                AppNotifier.Success(MessageToolbarConstants.DELETED_SUCCESS);
                toggleShowDelete(false);
                toggleShowSentMessageDelete(false);
                props.reloadMessages && props.reloadMessages();
                props.hideMessageDetailView && props.hideMessageDetailView();
                props.resetSearchAndSort && props.resetSearchAndSort();
            }
        });
    };

    const handleOnClickDelete = (e: any) => {
        e.stopPropagation();
        toggleShowDelete(true);
    };

    const handleOnClickMarkAsNotSpam = (e: any) => {
        e.stopPropagation();
        if (props.markAsNotSpam) {
            props.markAsNotSpam((result: any) => {
                if (result) {
                    AppNotifier.Success(MessageToolbarConstants.MARK_AS_SPAM_SUCCESS);
                    props.reloadMessages && props.reloadMessages();
                    props.hideMessageDetailView && props.hideMessageDetailView();
                    props.resetSearchAndSort && props.resetSearchAndSort();
                }
            });
        }
    };

    const handleOnClickMoveToInbox = (e: any) => {
        e.stopPropagation();
        if (props.moveToInbox) {
            props.moveToInbox((result: any) => {
                if (result) {
                    AppNotifier.Success(MessageToolbarConstants.MOVE_TO_INBOX_SUCCESS);
                    props.reloadMessages && props.reloadMessages();
                    props.hideMessageDetailView && props.hideMessageDetailView();
                    props.resetSearchAndSort && props.resetSearchAndSort();
                }
            });
        }
    };

    const handleOnHideSentMessageDelete = () => {
        toggleShowSentMessageDelete(false);
    };

    const HandleOnHideCancelTransfer = () => {
        toggleShowCancelTransfer(false);
    };

    const handleOnCancelTransfer = () => {
        if (props.cancelTransfer) {
            props.cancelTransfer((result: any) => {
                AppNotifier.Success(MessageToolbarConstants.CANCEL_MESSAGES_SUCCESS_MSG);
                toggleShowCancelTransfer(false);
                result && props.reloadMessages && props.reloadMessages();
                props.hideMessageDetailView && props.hideMessageDetailView();
                props.resetSearchAndSort && props.resetSearchAndSort();
            });
        }
    };

    const handleOnClickSentMessageDelete = (e: any) => {
        e.stopPropagation();
        if (mailboxUserData) {
            toggleShowDelete(true);
        } else {
            toggleShowSentMessageDelete(true);
        }
    };
    const handleOnClickCancelTransfer = (e: any) => {
        e.stopPropagation();
        toggleShowCancelTransfer(true);
    };
    const handleOnClickMoveToFolder = (e: any) => {
        let folderIds: number[] = [];
        if (props.selectedMessages) {
            switch (props.folderId) {
                case FolderType.SENT:
                    folderIds = [FolderType.SENT, FolderType.INBOX];
                    break;
                case FolderType.ARCHIVE:
                    folderIds = [];
                    break;
                case FolderType.INBOX:
                    folderIds = [FolderType.INBOX];
                    break;
                default:
                    break;
            }
        }
        dispatch(fetchFoldersTree([], folderIds));
        e.stopPropagation();
        toggleShowFolder(true);
        dispatch(isMoveToFolderPopupOpen(true));
    };

    const handleOnSubmitMoveToFolder = () => {
        if (moveToFolder && props.moveToFolder) {
            props.moveToFolder(moveToFolder.folderId, (result: any) => {
                if (result) {
                    toggleShowFolder(false);
                    AppNotifier.Success(MessageToolbarConstants.MOVE_TO_FOLDER_SUCCESS);
                    props.reloadMessages && props.reloadMessages();
                    props.hideMessageDetailView && props.hideMessageDetailView();
                    props.resetSearchAndSort && props.resetSearchAndSort();
                }
            });
        }
    };

    const handleResendAccessLink = (e: any) => {
        e.stopPropagation();
        if (props.resendAccessLink) {
            props.resendAccessLink();
        }
    };

    const getSelectedMessages = () => {
        if (props.folderType === FolderType.DRAFT) {
            return [];
        } else {
            return props.selectedMessages;
        }
    };

    const enableEditPermission = (folder?: IFolderModel) => {
        if (!folder || isFolderOwner(folder, user) || folder.permissionGroupId == ShareFolderTypes.EDIT) {
            return true;
        } else if (folder.permissionGroupId == ShareFolderTypes.VIEW) {
            return false;
        }
    };
    const isMoveEnabled = (folder?: IFolderModel) => {
        if (!folder || isFolderOwner(folder, user)) {
            return true;
        }
        return false;
    };
    return (
        <>
            {(isMoveToFolderEnabled = isMoveToFolderEnabled && isMoveEnabled(props.folder))}
            <div className="message-toolbar">
                <div
                    style={{
                        visibility: props.showSelectAllCheckbox ? "inherit" : "hidden",
                    }}
                    className="message-toolbar-action"
                >
                    <Checkbox
                        id="mpiCheckbox"
                        text=""
                        size={CheckBoxSize.sm}
                        className="message-toolbar-checkbox"
                        checked={props.isAllSelected || props.isAnySelected}
                        onChange={props.selectAll}
                        indeterminate={props.isAllSelected ? false : props.isAnySelected}
                        disabled={!enableEditPermission(props.folder)}
                        onClick={e => {
                            if (!enableEditPermission(props.folder)) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
                {props.folderType !== FolderType.SENT ? (
                    <div className="message-toolbar-action">
                        <span
                            onClick={e => props.isAnySelected && handleOnClickDelete(e)}
                            data-resource-id={resourceLocators.MsgListBtnDelete}
                            title={MessageToolbarConstants.TITLE_DELETE}
                            className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                        >
                            <DeleteIcon width={17} height={17} color="#606060" disabled={!props.isAnySelected} />
                        </span>
                    </div>
                ) : (
                    <>
                        <div className="message-toolbar-action">
                            <span
                                onClick={e => props.isAnySelected && handleOnClickSentMessageDelete(e)}
                                data-resource-id={resourceLocators.MsgListBtnDelete}
                                title={MessageToolbarConstants.TITLE_DELETE}
                                className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                            >
                                <DeleteIcon width={17} height={17} color="#606060" disabled={!props.isAnySelected} />
                            </span>
                        </div>
                        <div className="message-toolbar-action">
                            <span
                                onClick={e => props.isAnySelected && handleOnClickCancelTransfer(e)}
                                data-resource-id={resourceLocators.MsgListBtnCancelTransfer}
                                title={MessageToolbarConstants.TITLE_CANCEL_TRANSFER}
                                className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                            >
                                <CancelRequestIcon
                                    width={25}
                                    height={25}
                                    color="#606060"
                                    disabled={!props.isAnySelected}
                                />
                            </span>
                        </div>
                    </>
                )}
                {props.folderType !== FolderType.SPAM &&
                    props.folderType !== FolderType.SENT &&
                    props.folderType !== FolderType.DRAFT && (
                        <div className="message-toolbar-action">
                            <span
                                onClick={e =>
                                    props.isAnySelected && isMoveToFolderEnabled && handleOnClickMoveToFolder(e)
                                }
                                data-resource-id={resourceLocators.MsgListBtnMoveMessage}
                                title={MessageToolbarConstants.TITLE_MOVE_TO_FOLDER}
                                className={
                                    !props.isAnySelected || !isMoveToFolderEnabled ? "toolbar-icon-disabled" : ""
                                }
                            >
                                <MoveToFolderIcon
                                    width={17}
                                    height={17}
                                    color="#606060"
                                    disabled={!props.isAnySelected || !isMoveToFolderEnabled}
                                />
                            </span>
                        </div>
                    )}

                {!mailboxUserData && props.folderType === FolderType.SPAM && (
                    <div className="message-toolbar-action">
                        <span
                            onClick={e => props.isAnySelected && handleOnClickMarkAsNotSpam(e)}
                            data-resource-id={resourceLocators.MsgListBtnMarkNotSpam}
                            title={MessageToolbarConstants.TITLE_NOT_SPAM}
                            className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                        >
                            <SpamIcon width={16} height={16} color="#606060" disabled={!props.isAnySelected} />
                        </span>
                    </div>
                )}
                {!mailboxUserData && props.folderType === FolderType.SPAM && (
                    <div className="message-toolbar-action">
                        <span
                            onClick={e => props.isAnySelected && handleOnClickMoveToInbox(e)}
                            data-resource-id={resourceLocators.MsgListBtnMoveToInbox}
                            title={MessageToolbarConstants.TITLE_MOVE_TO_INBOX}
                            className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                        >
                            <MoveToInboxIcon width={17} height={17} color="#606060" disabled={!props.isAnySelected} />
                        </span>
                    </div>
                )}
                {!mailboxUserData &&
                    props.folderType === FolderType.SENT &&
                    messageHistorySettings.sentItemsSettings?.isUserAllowedToResendDocumentAccessLinks && (
                        <div className="message-toolbar-action">
                            <span
                                onClick={e => props.isAnySelected && handleResendAccessLink(e)}
                                data-resource-id={resourceLocators.MsgListBtnResendAccessLink}
                                title={MessageToolbarConstants.TITLE_RESEND_ACCESS_LINK}
                                className={!props.isAnySelected ? "toolbar-icon-disabled" : ""}
                            >
                                <SendLinkIcon width={22} height={17} color="#606060" disabled={!props.isAnySelected} />
                            </span>
                        </div>
                    )}
            </div>
            <CustomModal
                show={showDelete}
                title={MessageToolbarConstants.TITLE_DELETE_POPUP}
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                cancelButtonName={MessageToolbarConstants.TITLE_CANCEL}
                onSubmit={handleOnSubmitDelete}
                onHide={handleOnHideDelete}
                size="sm"
                className="modal-confirm-delete"
            >
                <span>{MessageToolbarConstants.DELETE_MESSAGE}</span>
                {props.folderType === FolderType.ARCHIVE && (
                    <div className="delete-warning">
                        <i className="fas fa-exclamation-triangle margin-right-10-px" />
                        <span>{MyDownloadsModalConstants.DELETE_WARNING}</span>
                    </div>
                )}
            </CustomModal>

            <CustomModal
                show={showSentMessageDelete}
                title={MessageToolbarConstants.TITLE_DELETE_POPUP}
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                cancelButtonName={MessageToolbarConstants.TITLE_CANCEL}
                onSubmit={handleOnSubmitDelete}
                onHide={handleOnHideSentMessageDelete}
            >
                <span>{MessageToolbarConstants.DELETE_MESSAGE}</span>
            </CustomModal>

            <ConfirmModal
                className="delete-message-modal"
                show={showCancelTransfer}
                title={MessageToolbarConstants.TITLE_CANCEL_TRANSFER}
                confirmButtonName={MessageToolbarConstants.TITLE_CANCEL_TRANSFER}
                additionalActionResourceId={resourceLocators.MsgListBtnCancelTransfer}
                onSubmit={handleOnCancelTransfer}
                onHide={HandleOnHideCancelTransfer}
            >
                <span>{MessageToolbarConstants.CANCEL_TRANSFER_MESSAGE}</span>
            </ConfirmModal>

            {showFolder && (
                <MoveFolderModal
                    selectedFolders={[]}
                    folderType={props.folderType}
                    selectedMessages={getSelectedMessages()}
                    isMovingFolder={false}
                    setMoveToFolder={setMoveToFolder}
                    showState={showFolder}
                    onHide={() => {
                        toggleShowFolder(false);
                        props.resetSelection && props.resetSelection();
                        dispatch(isMoveToFolderPopupOpen(false));
                    }}
                    onSubmit={handleOnSubmitMoveToFolder}
                    onError={() => {
                        props.resetSelection && props.resetSelection();
                    }}
                />
            )}
        </>
    );
};

export default MessageToolbar;
