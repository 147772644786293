import { UserManager } from "oidc-client";
import Config from "./config";
import { storeUserError, storeUser, updateLogoutDate } from "../actions/authActions";
import { createUserManagerWithRedirect } from "./helpers";
import { resetUserCache } from "../actionCreators/layoutActionCreators";
import store from "../configureStore";
import { createForethoughtCookie } from "../helper/HelperFunctions";

export let userManager = new UserManager(Config);

export async function loadUserFromStorage(store: any) {
  try {
    const user = await userManager.getUser();
    if (!user) {
      return store.dispatch(storeUserError());
    }
    store.dispatch(storeUser(user));
  } catch (e) {
    console.error(`User not found: ${e}`);
    store.dispatch(storeUserError());
  }
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export const signinRedirectWithCallbackArgs = (args: string) => {
  if (args) {
    const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args);
    userManager = createUserManagerWithRedirect(redirectUri);
  }
  userManager.signinRedirect();
};

export async function signoutRedirect(dispatch?: any) {
  createForethoughtCookie("isForethoughtWidgetVisible", "false");
  const user = await userManager.getUser();
  userManager.clearStaleState();
  userManager.removeUser();
  if (dispatch) dispatch(resetUserCache());
  await updateLogoutDate();
  return userManager.signoutRedirect({ id_token_hint: user?.id_token });
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}
export async function getAccessToken() {
  const token = store.getState().userAuth?.user?.access_token;
  return token;
}

export default userManager;
