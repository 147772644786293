import React, { FC, useRef, useState, useEffect, CSSProperties, useReducer } from "react";
import moment from "moment";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { ActionMeta, components } from "react-select";
const _ = require("lodash");

import { fetchTemplateTableData, getTemplateDetail } from "../../../actionCreators/templateActionCreators";
import { ColorConstants } from "../../../assets/custom/colors";
import { AuthenticationMethod, MessageSource, RecipientType, SettingMode } from "../../../core/common/enums";
import {
    IDocumentRequestRecipient,
    INewDocumentRequestModel,
    INewMultipleDocumentRequestModel,
    initialNewDocumentState,
    initialNewMultipleDocumentRequestModel,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import {
    IDocumentRequestTemplateFile,
    IDocumentRequestTemplateModel,
    initialTemplateTableRequestObject,
} from "../../../core/viewModels/template/TemplateModel";
import {
    ComposeValidations,
    DateFormatConstants,
    MAX_ALLOWED_RECIPIENTS,
    MAX_PAGE_SIZE,
    MAX_SUBJECT_LENGTH,
    NewDRConstants,
    TemplateValidationConstants,
} from "../../../helper/Constants";
import { getTaxYears, isDateEqual } from "../../../helper/DateHelperFunctions";
import { getCompanyAuthMethods, getRetentionOptions, getUserIdFromEmail } from "../../../helper/HelperFunctions";
import { ApplicationState } from "../../../store";
import CustomDatePicker from "../../common/ui/CustomDatePicker";
import ConfirmModal from "../../common/ui/Modals/ConfirmModal";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { AddFilesRow } from "../../common/ui/Modals/TemplateModal/AddFilesRow";
import TinyMCEComponent from "../../common/ui/tinymce/TinyMCE";
import { PlusCircleIcon } from "../../svg/IconCollection";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { sendNewDocumentRequest } from "../../../actionCreators/documentRequestActionCreators";
import {
    fetchAuthenticationMethodSettings,
    fetchMessageSecurityQuestionSettings,
    fetchRequestNotificationsSettings,
} from "../../../actionCreators/adminSettingsActionCreators";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import {
    fetchMessageSecurityQuestions,
    getRecipientDefaultQuestionAndAnswer,
    getRecipientQuestionAnswer,
} from "../../../actionCreators/securityQuestionCreators";
import { fetchUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import CustomToggleSwitch from "../../common/ui/switch/CustomToggleSwitch";
import { AppNotifier } from "../../../helper/AppNotifier";
import {
    QnAActions,
    initialRecipientQuestionAnswer,
    recipientQuestionAnswerReducer,
} from "../../../reducers/recipientQuestionAnswerReducer";
import { IRecipientModel } from "../../../core/viewModels/message/RecipientModel";
import { isValidEmailAddress } from "../../../helper/Validations";
import MultipleRecipient from "../../common/compose/parts/MultipleRecipient";
import { ISecurityQuestionAnswer } from "../../../core/viewModels/message/MessageSecurityQuestionModel";

interface IComposeDocumentRequestModal {
    show: boolean;
    onHide: () => void;
    resetSelection: () => void;
}
interface Options {
    value: number;
    label: string;
}
interface IPrevRecipientProps {
    question?: string;
    answer?: string;
}
const ComposeDocumentRequestModal: FC<IComposeDocumentRequestModal> = ({ show, onHide, resetSelection }) => {
    const ref = useRef<HTMLFormElement>(null);
    const clearDatePicker = useRef(null);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);
    const [documentRequestData, setDocumentRequestData] = useState<INewDocumentRequestModel>(initialNewDocumentState);
    const [documentRequestRecipients, setdocumentRequestRecipients] = useState<IDocumentRequestRecipient[]>(
        initialNewMultipleDocumentRequestModel.recipients
    );
    const [filesData, setFilesData] = useState<IDocumentRequestTemplateFile[]>(initialNewDocumentState.requestedFiles);
    const [isDueDateInvalid, setIsDueDateInvalid] = useState<boolean>(false);
    const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);
    const { userId } = useSelector((state: ApplicationState) => state.userProfile);
    const { authRetentions } = useSelector((state: ApplicationState) => state.composeState);
    const { messageOptions: messageOptionsSettings, requestNotificationSettings } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );
    const profile = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const composeState = useSelector((state: ApplicationState) => state.composeState);
    const userSettingsStore = useSelector((state: ApplicationState) => state.userSettingsState);
    const securityQuestionState = useSelector((state: ApplicationState) => state.securityQuestionState);
    const { templateList, isLoading, isModalLoading } = useSelector((state: ApplicationState) => state.templateState);
    const { isComposeLoading } = useSelector((state: ApplicationState) => state.documentState);

    const defaultQuestion = securityQuestionState.securityQuestions.data.find(question => question.isDefault === true);
    const [selectedQuestiondefault] = useState("");
    const authenticationMethodOptions = messageOptionsSettings.authenticationSettings.authenticationMethods.map(
        value => {
            const option = getCompanyAuthMethods().filter(method => method.value === value.toString());
            return option[0];
        }
    );
    const [defaultRecipientQuestionId, setDefaultRecipientQuestionId] = useState(0);
    const [isAccessCodeDefault, setAccesCodeDefault] = useState<boolean>(false);
    const [recipientCount, setRecipientCount] = useState(0);
    const isAuthMethodDisabled = !messageOptionsSettings.authenticationSettings.allowEmployeesToChangeAuthMethod;
    const [recipients, dispatchRecipient] = useReducer(recipientQuestionAnswerReducer, []);
    const [prevRecipients, setPrevRecipients] = useState<IPrevRecipientProps>({
        question: selectedQuestiondefault,
        answer: "",
    });
    const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
    const [isQuestionAnswerLoading, setIsQuestionAnswerLoading] = useState<boolean>(false);
    const { data: categories } = useSelector((state: ApplicationState) => state.documentCategoryState);
    const userDefaultMethod = userSettingsStore.userSettings?.authenticationMethodSettings.defaultAuthenticationMethod;
    useEffect(() => {
        const userDefaultMethod =
            userSettingsStore.userSettings?.authenticationMethodSettings.defaultAuthenticationMethod;
        const adminDefaultMethod = messageOptionsSettings.authenticationSettings.defaultAuthenticationMethod;
        const isAuthmethodChangeAllowed =
            messageOptionsSettings.authenticationSettings.allowEmployeesToChangeAuthMethod;
        const isDefaultMthodChangeAllowed =
            messageOptionsSettings.authenticationSettings.allowEmployeesToChangeDefaultMethod;
        const accessCodeEnabled = authenticationMethodOptions.some(obj => obj.key === AuthenticationMethod.ACCESSCODE);
        //when added recipients are in range and if( change of authentication method is enabled or if user default method is accesscode) and if access code is not disabled by admin we allow multiple recipients and will be defaulted to access code
        if (
            recipientCount > 1 &&
            recipientCount <= MAX_ALLOWED_RECIPIENTS &&
            (isAuthmethodChangeAllowed || userDefaultMethod === AuthenticationMethod.ACCESSCODE) &&
            accessCodeEnabled
        ) {
            const documentRequestDataCopy = {
                ...documentRequestData,
                authenticationMethod: AuthenticationMethod.ACCESSCODE,
            };
            setAccesCodeDefault(true);
            setDocumentRequestData(documentRequestDataCopy);
            // when added recipients count not greater than 1 then default auth method is given
        } else if (recipientCount <= 1) {
            const authMethod = isDefaultMthodChangeAllowed ? userDefaultMethod : adminDefaultMethod;
            const documentRequestDataCopy = { ...documentRequestData, authenticationMethod: authMethod };
            setDocumentRequestData(documentRequestDataCopy);
            setAccesCodeDefault(false);
            // when added recipients count is more than 20 then we show warning and  and will be defaulted to access code
        } else if (recipientCount > MAX_ALLOWED_RECIPIENTS) {
            AppNotifier.Warning(ComposeValidations.MAXIMUM_RECIPIENTS_REACHED);
            dispatchRecipient({ type: QnAActions.REMOVE_LAST_RECIPIENT });
            setAccesCodeDefault(true);
            // shows warning when default method is qa and more than one recipients are added ,we restrict those
        } else {
            AppNotifier.Warning(ComposeValidations.DEFAULT_QA_METHOD);
            dispatchRecipient({ type: QnAActions.REMOVE_LAST_RECIPIENT });
        }
    }, [recipientCount]);

    useEffect(() => {
        const payload = {
            ...initialTemplateTableRequestObject,
            pageSize: MAX_PAGE_SIZE, //load all templates in the dropdown in single call
        };
        dispatch(fetchTemplateTableData(payload));
        dispatch(fetchAuthenticationMethodSettings());
        dispatch(fetchMessageSecurityQuestionSettings());
        dispatch(fetchRequestNotificationsSettings());
        dispatch(fetchMessageSecurityQuestions(SettingMode.BOTH));
        dispatch(fetchUserSettings());
    }, []);

    useEffect(() => {
        const updatedRecipient = documentRequestRecipients.map(recipientItem => ({
            ...recipientItem,
            question: defaultQuestion?.question,
        }));
        setdocumentRequestRecipients(updatedRecipient);

        if (defaultQuestion?.messageSecurityQuestionId)
            setSelectedQuestionId(defaultQuestion?.messageSecurityQuestionId);
    }, [securityQuestionState]);

    useEffect(() => {
        if (userSettingsStore.userSettings) {
            const methodOptions = messageOptionsSettings.authenticationSettings.authenticationMethods;
            const userDefaultMethod =
                userSettingsStore.userSettings.authenticationMethodSettings.defaultAuthenticationMethod;
            const adminDefaultMethod = messageOptionsSettings.authenticationSettings.defaultAuthenticationMethod;
            const allowUserToChange = messageOptionsSettings.authenticationSettings.allowEmployeesToChangeDefaultMethod;
            if (methodOptions.includes(userDefaultMethod) && allowUserToChange) {
                setDocumentRequestData({
                    ...documentRequestData, 
                    authenticationMethod: userDefaultMethod, 
                    expiryDays: getRetentionPeriodFromUserSettings(
                        userSettingsStore.userSettings.authenticationMethodSettings.defaultAuthenticationMethod
                    ),
                });
            } else {
                setDocumentRequestData({
                    ...documentRequestData, 
                    authenticationMethod: adminDefaultMethod, 
                    expiryDays: getRetentionPeriodFromUserSettings(
                        userSettingsStore.userSettings.authenticationMethodSettings.defaultAuthenticationMethod
                    ),
                });
            }
        }
    }, [userSettingsStore.userSettings?.authenticationMethodSettings, messageOptionsSettings.authenticationSettings]);

    const getSelectedOption = (value?: number) => {
        if (value) {
            const result = composeState.authRetentions.data.find(rp => rp.retentionId === value);

            if (result) return result.days;
            else return 0;
        }
        return 0;
    };

    useEffect(() => {
        if (documentRequestData.dueDate) {
            if (!checkIsDueDateValid(documentRequestData.dueDate)) {
                setIsDueDateInvalid(true);
                clearDatePicker && clearDatePicker.current;
            }
        }
    }, [documentRequestData.documentRequestTemplateId]);

    useEffect(() => {
        if (documentRequestRecipients.length == 1) {
            const updatedRecipient = documentRequestRecipients.map(recipientItem => ({
                ...recipientItem,
                question: prevRecipients.question,
                answer: prevRecipients.answer,
            }));
            setdocumentRequestRecipients(updatedRecipient);
        }
    }, [prevRecipients]);
    console.log("documentRequestData", documentRequestData, userSettingsStore);

    useEffect(() => {
        const notifyOnCompletion = !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
            ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
            : userSettingsStore?.userSettings?.documentRequestNotificationsSettings
                ?.isDocumentRequestCompletionNotificationEnabled ?? false;
        setDocumentRequestData({
            ...documentRequestData,
            notifyOnCompletion: !!notifyOnCompletion,
        });
    }, [requestNotificationSettings, userSettingsStore?.userSettings?.documentRequestNotificationsSettings]);

    const getRetentionPeriodFromUserSettings = (authenticationMethod: AuthenticationMethod) => {
        if (!profile) return 0;

        switch (authenticationMethod) {
            case AuthenticationMethod.ACCESSCODE:
                return getSelectedOption(profile?.userSettings?.retentionSettings.defaultRetentionPeriods.accessCode);
            case AuthenticationMethod.QUESTIONANSWER:
                return getSelectedOption(
                    profile?.userSettings?.retentionSettings.defaultRetentionPeriods.questionAndAnswer
                );
            default:
                return 0;
        }
    };

    const handleOnSubmit = () => {
        const form = ref.current;
        if (
            !!form &&
            form.checkValidity() &&
            !!documentRequestData.message &&
            !isDueDateInvalid &&
            documentRequestRecipients.length > 0
        ) {
            const recipientData = documentRequestRecipients.map(recipientItem => {
                return {
                    ...recipientItem,
                    question: recipientItem.question?.trim(),
                    answer: recipientItem.answer?.trim(),
                    questionId: selectedQuestionId,
                };
            });
            const completeFormData = {
                ...documentRequestData,
                senderId: userId,
                messageSource: MessageSource.MAILBOX,
                subject: documentRequestData.subject.trim(),
                requestedFiles: filesData.map(file => {
                    return { ...file, name: file.name.trim(), description: file.description.trim() };
                }),
            };
            const completeData: INewMultipleDocumentRequestModel = {
                documentRequest: completeFormData,
                recipients: recipientData,
            };

            setValidated(false);
            dispatch(sendNewDocumentRequest(completeData, ResourceIdLocators.DRL.DRLBtnNewRequest, resetSelection));
        }
        setValidated(true);
    };

    const getSelectedRetention = (): number => {
        let selectedRetention: number | undefined = documentRequestData.expiryDays;
        if (!selectedRetention) {
            selectedRetention = composeState.authRetentions.data.find(x => x.isDefault)?.days;
        }

        return selectedRetention ?? 0;
    };

    const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line prefer-const
        let { id, value } = e.target;
        if (value.trim().length === 0) value = value.trim();

        const documentRequestDataCopy = { ...documentRequestData, [id]: value };
        setDocumentRequestData(documentRequestDataCopy);
    };

    const checkIsDueDateValid = (date: Date | undefined) => {
        date = moment(date).toDate();
        const maxDate = moment().add(NewDRConstants.MAX_DUE_DATE, "year").toDate();
        const currentDate = new Date();
        return (date < maxDate && date > currentDate) || isDateEqual(date, currentDate);
    };

    const handleDueDateChange = (date: Date | undefined) => {
        date = moment(date).toDate();

        if (!checkIsDueDateValid(date)) {
            setIsDueDateInvalid(true);
            clearDatePicker && clearDatePicker.current;
        } else {
            setIsDueDateInvalid(false);
            const data = { ...documentRequestData, dueDate: date };
            setDocumentRequestData(data);
        }
    };

    const onClickRemoveRow = (index: number) => {
        const filesDataCopy = [...filesData];
        filesDataCopy.splice(index, 1);
        setFilesData(filesDataCopy);
    };

    const onClickAddFiles = () => {
        const filesDataCopy = [...filesData];
        filesDataCopy.push({
            name: "",
            description: "",
        });
        setFilesData(filesDataCopy);
    };

    const handleFilesFormChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        // eslint-disable-next-line prefer-const
        let { id, value } = e.target;
        if (value.trim().length === 0) value = value.trim();

        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index][id as keyof IDocumentRequestTemplateFile] = value as any;
        setFilesData(filesDataCopy);
    };

    const handleCategoryChange = (value: any, index: number) => {
        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index].documentCategory = value as any;
        setFilesData(filesDataCopy);
    };

    const hideModalHandler = () => {
        setDocumentRequestData(initialNewDocumentState);
        setFilesData(initialNewDocumentState.requestedFiles);
        setValidated(false);
        onHide();
        setShowCloseConfirmation(false);
        setIsDueDateInvalid(false);
    };

    const decodeHtml = (html: string) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        const value = txt.value.replace("<p>", "").replace("</p>", "");
        return value;
    };

    const handleMessageBodyChange = (message: string): void => {
        const val = decodeHtml(message);
        if (val.trim().length === 0) {
            message = val.trim();
        }
        setDocumentRequestData({ ...documentRequestData, message });
    };

    const onClearTemplate = () => {
        const { year, dueDate, message } = initialNewDocumentState;
        setDocumentRequestData({
            ...documentRequestData,
            documentRequestTemplateId: 0,
            year,
            dueDate,
            message,
        });
        setFilesData([{ name: "", description: "" }]);
        clearDatePicker && clearDatePicker.current;
        setIsDueDateInvalid(false);
    };
    const retentionOptions = !authRetentions.isLoading ? getRetentionOptions(authRetentions.data) : [];

    const handleQuestionSelect: React.ChangeEventHandler<HTMLSelectElement> = e => {
        const selectedQuestion = securityQuestionState.securityQuestions.data.find(
            q => q.messageSecurityQuestionId === Number(e.target.value)
        );

        if (selectedQuestion) {
            const selectedQuestionId = selectedQuestion?.messageSecurityQuestionId;
            setSelectedQuestionId(selectedQuestion?.messageSecurityQuestionId);
            setDefaultRecipientQuestionId(selectedQuestion?.messageSecurityQuestionId);

            if (recipients.length == 1) {
                const email = recipients[0].emailAddress;
                setIsQuestionAnswerLoading(true);
                dispatch(
                    getRecipientQuestionAnswer(email, selectedQuestionId, (result: ISecurityQuestionAnswer) => {
                        const updatedValue = {
                            ...prevRecipients,
                            question: selectedQuestion?.question,
                            answer: !result.answer ? "" : result.answer,
                        };
                        setPrevRecipients(updatedValue);
                        setSelectedQuestionId(selectedQuestionId);
                        setDefaultRecipientQuestionId(selectedQuestionId);
                        setIsQuestionAnswerLoading(false);
                    })
                );
            } else {
                const updatedValue = { ...prevRecipients, question: selectedQuestion?.question, answer: "" };
                setPrevRecipients(updatedValue);
                setIsQuestionAnswerLoading(false);
            }
        }
    };

    const handleQAChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        let value = e.target.value;
        if (value.trim().length === 0) value = value.trim();

        const updatedValue = e.target.id === "question" ? { question: value } : { answer: value };
        const prevValue = { ...prevRecipients, ...updatedValue };
        setPrevRecipients(prevValue);
    };

    const handleRecipientChange = (payload: IDocumentRequestRecipient[]) => {
        setdocumentRequestRecipients(payload);
        setRecipientCount(payload.length);
    };

    useEffect(() => {
        const recipientPayload = recipients.map(recipient => ({
            email: recipient?.emailAddress || "",
            documentRequestRecipientId: getUserIdFromEmail(composeState.recipients.data, recipient?.emailAddress || ""),
            documentRequestId: 0,
            question: recipient.question,
            answer: recipient.answer,
            questionId: recipient.questionId,
        }));
        handleRecipientChange(recipientPayload);

        const questionId = recipients[0]?.questionId ?? getUsersDefaultQuestion()?.messageSecurityQuestionId;

        setDefaultRecipientQuestionId(questionId);
        setSelectedQuestionId(questionId);

        const updatedValue = {
            ...prevRecipients,
            question: securityQuestionState.securityQuestions.data.find(x => x.messageSecurityQuestionId == questionId)
                ?.question,
            answer: !recipients[0]?.answer ? "" : recipients[0]?.answer,
        };
        setPrevRecipients(updatedValue);
    }, [recipients]);

    function getUsersDefaultQuestion() {
        return (
            securityQuestionState.securityQuestions.data.find(x => x.isDefault) ||
            securityQuestionState.securityQuestions.data[0]
        );
    }

    const handleChangeRecipient = (data: any, type: RecipientType) => {
        const email = data.option?.value?.trim();
        if (recipients.find((x: IRecipientModel) => x.emailAddress === email)) {
            return;
        }
        switch (data.action) {
            case "select-option":
            case "create-option":
                if (isValidEmailAddress(email)) {
                    const label =
                        isValidEmailAddress(data.option.label) && email.includes(data.option.label)
                            ? ""
                            : data.option.label;
                    let toData: IRecipientModel;
                    dispatch(
                        getRecipientDefaultQuestionAndAnswer(
                            email,
                            getUsersDefaultQuestion()?.messageSecurityQuestionId ?? 0,
                            (result: ISecurityQuestionAnswer) => {
                                if (result && result?.securityQuestionId != null) {
                                    const securityQuestion = securityQuestionState.securityQuestions.data.find(
                                        x => x.messageSecurityQuestionId == result.securityQuestionId
                                    );
                                    toData = {
                                        ...initialRecipientQuestionAnswer,
                                        firstName: label.split(" ")[0] ?? "",
                                        lastName: label.split(" ")[1] ?? "",
                                        emailAddress: email,
                                        recipientType: type,
                                        userId: getUserIdFromEmail(composeState.recipients.data, email),
                                        questionId:
                                            result.securityQuestionId ?? securityQuestion?.messageSecurityQuestionId,
                                        answer: result.answer,
                                        question: result.question ?? securityQuestion?.question,
                                        isModified: false,
                                    };
                                    const questionId =
                                        result.securityQuestionId ?? securityQuestion?.messageSecurityQuestionId;
                                    setDefaultRecipientQuestionId(questionId);
                                    setSelectedQuestionId(questionId);

                                    const updatedValue = {
                                        ...prevRecipients,
                                        question: securityQuestionState.securityQuestions.data.find(
                                            x => x.messageSecurityQuestionId == questionId
                                        )?.question,
                                        answer: !recipients[0]?.answer ? "" : recipients[0]?.answer,
                                    };
                                    setPrevRecipients(updatedValue);
                                    dispatchRecipient({ type: QnAActions.UPDATE, payload: toData });
                                }
                            }
                        )
                    );

                    toData = {
                        ...initialRecipientQuestionAnswer,
                        firstName: label.split(" ")[0] ?? "",
                        lastName: label.split(" ")[1] ?? "",
                        emailAddress: email,
                        recipientType: type,
                        userId: getUserIdFromEmail(composeState.recipients.data, email),
                        questionId: getUsersDefaultQuestion().messageSecurityQuestionId,
                        isModified: false,
                        answer: "",
                        question: getUsersDefaultQuestion().question,
                    };
                    dispatchRecipient({ type: QnAActions.ADD, payload: toData });
                } else {
                    AppNotifier.Warning(ComposeValidations.INVALID_EMAIL_FORMAT);
                }
                break;
            case "remove-value":
            case "clear":
                const emailAddress = data.removedValue.value;
                dispatchRecipient({
                    type: QnAActions.REMOVE,
                    payload: { emailAddress },
                });
                break;
        }
    };

    const isEdited = () => {
        const isNotifyOnCompletionEnabled =
            !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
                ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
                : userSettingsStore?.userSettings?.documentRequestNotificationsSettings
                      ?.isDocumentRequestCompletionNotificationEnabled ?? false;
        let initialValue = {
            ...initialNewDocumentState,
            authenticationMethod: documentRequestData.authenticationMethod,
            notifyOnCompletion: isNotifyOnCompletionEnabled,
        };

        if (documentRequestData.expiryDays > 0) {
            const defaultPeriod = getRetentionPeriodFromUserSettingStore(userDefaultMethod);
            initialValue = {
                ...initialValue,
                authenticationMethod: documentRequestData.authenticationMethod,
                expiryDays: defaultPeriod,
            };
        }

        const isRecipientsModified =
            documentRequestRecipients && documentRequestRecipients.length === 0 && !filesData.some(obj => obj["name"]);
        return JSON.stringify(initialValue) === JSON.stringify(documentRequestData) && isRecipientsModified;
    };

    const getRetentionPeriodFromUserSettingStore = (authenticationMethod?: AuthenticationMethod) => {
        switch (authenticationMethod) {
            case AuthenticationMethod.ACCESSCODE:
                return getSelectedOption( userSettingsStore?.userSettings?.retentionSettings.defaultRetentionPeriods.accessCode);
            case AuthenticationMethod.QUESTIONANSWER:
                return getSelectedOption(
                    userSettingsStore?.userSettings?.retentionSettings.defaultRetentionPeriods.questionAndAnswer
                );
            default:
                return 0;
        }
    };

    const handleTemplateChange = (value: Options | null, data: ActionMeta<Options>) => {
        switch (data.action) {
            case "select-option":
                if (value) dispatch(getTemplateDetail(value?.value, templateDetailCallback));
                break;
            case "clear":
                onClearTemplate();
                break;
        }
    };

    const templateDetailCallback = (data: IDocumentRequestTemplateModel) => {
        if (data) {
            getDocumentCategories(data);
            setDocumentRequestData({
                ...documentRequestData,
                documentRequestTemplateId: data.documentRequestTemplate.documentRequestTemplateId,
                message: data.documentRequestTemplate.description,
                year: data.documentRequestTemplate.year,
                dueDate: data.documentRequestTemplate.dueDate ?? null,
            });
        }
    };

    const getDocumentCategories = (data: IDocumentRequestTemplateModel) => {
        const updatedFilesData = data.files.map(file => {
            const matchingCategory = categories.find(
                category => category.documentCategoryId === file.documentCategory?.documentCategoryId
            );
            if (matchingCategory) {
                return {
                    ...file,
                    documentCategory: {
                        documentCategoryId: matchingCategory.documentCategoryId,
                        name: matchingCategory.name,
                    },
                };
            } else {
                return { ...file, documentCategory: undefined };
            }
        });
        setFilesData(updatedFilesData);
    };

    const onClearDueDate = () => {
        clearDatePicker && clearDatePicker.current;
        setDocumentRequestData({ ...documentRequestData, dueDate: null });
        setIsDueDateInvalid(false);
    };

    const getTemplateOptions = () => {
        const options: Options[] = [];
        if (templateList && templateList.length > 0) {
            templateList.map(item => options.push({ value: item.documentRequestTemplateId, label: item.name }));
        }
        return options;
    };

    const Option = (props: any) => {
        return (
            <span title={props.data.label}>
                <components.Option {...props} />
            </span>
        );
    };

    const ClearIndicator = (props: any) => {
        const {
            children = (
                <div className="clear-icon">
                    <i className="fas fa-times"></i>
                </div>
            ),
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props) as CSSProperties}>
                <div style={{ padding: "0px 5px" }}>{children}</div>
            </div>
        );
    };

    const NotifyCompletion = (
        <div className="notify-container">
            <CustomToggleSwitch
                label={NewDRConstants.NOTIFY_ON_COMPLETION}
                handleChange={() => {
                    setDocumentRequestData(data => {
                        return {
                            ...data,
                            notifyOnCompletion: !data.notifyOnCompletion,
                        };
                    });
                }}
                switched={
                    !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
                        ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
                        : documentRequestData.notifyOnCompletion
                }
                disabled={!requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification}
            />
        </div>
    );

    const getDisplayDueDate = (): string => {
        let displayDate = moment(documentRequestData.dueDate).utc().format(DateFormatConstants.DEFAULT_DATE_FORMAT);
        if (!(documentRequestData.dueDate instanceof Date)) {
            displayDate = moment(documentRequestData.dueDate!.toString()).format(
                DateFormatConstants.DEFAULT_DATE_FORMAT
            );
        }
        return displayDate;
    };

    return (
        <CustomModal
            show={show}
            onHide={() => (isEdited() ? hideModalHandler() : setShowCloseConfirmation(true))}
            cancelButtonName={NewDRConstants.CANCEL_BUTTON_TEXT}
            confirmButtonName={NewDRConstants.OK_BUTTON_TEXT}
            onSubmit={handleOnSubmit}
            additionalFooterElement={NotifyCompletion}
            title={NewDRConstants.MODAL_TITLE}
            className="new-request-dr"
        >
            <LoadingOverlay>
                <Form
                    ref={ref}
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <Form.Group>
                        <Form.Label>{NewDRConstants.TO_LABEL}</Form.Label>
                        <MultipleRecipient
                            inValid={validated && documentRequestRecipients && documentRequestRecipients.length === 0}
                            setValue={handleChangeRecipient}
                            recipients={recipients}
                        />
                        {validated && documentRequestRecipients && recipientCount === 0 && (
                            <div className="input-error-message">{NewDRConstants.RECIPIENT_ERROR_TEXT}</div>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{NewDRConstants.SUBJECT_LABEL}</Form.Label>
                        <Form.Control
                            type="text"
                            id="subject"
                            name="subject"
                            value={documentRequestData.subject}
                            onChange={onFormChange}
                            required
                            maxLength={MAX_SUBJECT_LENGTH}
                        />
                        <Form.Control.Feedback type="invalid">
                            {NewDRConstants.SUBJECT_ERROR_TEXT}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="select-with-clear">
                        <Form.Label>{NewDRConstants.APPLY_TEMPLATE_LABEL}</Form.Label>
                        <Select
                            options={getTemplateOptions()}
                            onChange={handleTemplateChange}
                            placeholder={NewDRConstants.NO_TEMPLATE_TEXT}
                            isLoading={isLoading}
                            isClearable={Boolean(documentRequestData?.documentRequestTemplateId)}
                            classNamePrefix="template-dropdown"
                            theme={theme => {
                                return { ...theme, borderRadius: 0 };
                            }}
                            components={{
                                Option,
                                IndicatorSeparator: null,
                                ClearIndicator,
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="input-with-editor">
                        <Form.Label>{NewDRConstants.MESSAGE_LABEL}</Form.Label>
                        <div className={`editor-wrapper${validated && !documentRequestData.message ? " error" : ""}`}>
                            <TinyMCEComponent
                                messageBody={documentRequestData.message}
                                changeStateTinymceBody={e => handleMessageBodyChange(e)}
                                height={150}
                            />
                        </div>
                        {validated && !documentRequestData.message && (
                            <div className="input-error-message">{NewDRConstants.MESSAGE_ERROR_TEXT}</div>
                        )}
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} xs={2} controlId="year">
                            <Form.Label>{NewDRConstants.YEAR_LABEL}</Form.Label>
                            <Form.Select
                                onChange={onFormChange}
                                value={documentRequestData.year}
                                placeholder={NewDRConstants.YEAR_PLACEHOLDER_TEXT}
                                required
                            >
                                {getTaxYears().map((year, index) => (
                                    <option key={index}>{year}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {TemplateValidationConstants.YEAR_REQUIRED_TEXT}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="dueDate">
                            <Form.Label>{NewDRConstants.DUE_DATE_LABEL}</Form.Label>

                            <CustomDatePicker
                                value={documentRequestData?.dueDate ? getDisplayDueDate() : undefined}
                                minDate={new Date()}
                                className={isDueDateInvalid ? "warning-highlight" : ""}
                                clearDatePicker={clearDatePicker}
                                onChange={handleDueDateChange}
                                maxDate={moment().add(NewDRConstants.MAX_DUE_DATE, "year").toDate()}
                                resetDateValues={!documentRequestData.dueDate}
                                clearFilter={onClearDueDate}
                                onSubmit={() => {
                                    //
                                }}
                            />
                            {isDueDateInvalid && <p className="text-danger">Please enter a valid date</p>}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="expiryDays">
                            <Form.Label>{NewDRConstants.RETENTION_PERIOD_LABEL}</Form.Label>
                            <Form.Select value={getSelectedRetention()} onChange={onFormChange}>
                                {retentionOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId="authenticationMethod">
                            <Form.Label>{NewDRConstants.AUTHENTICATION_METHOD_LABEL}</Form.Label>
                            <Form.Select
                                value={documentRequestData.authenticationMethod}
                                disabled={isAuthMethodDisabled || isAccessCodeDefault}
                                onChange={onFormChange}
                            >
                                {authenticationMethodOptions.map((option, index) =>
                                    recipientCount > 1 &&
                                        messageOptionsSettings.authenticationSettings.allowEmployeesToChangeAuthMethod &&
                                        option.value !== AuthenticationMethod.ACCESSCODE.toString() ? null : (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    {Number(documentRequestData.authenticationMethod) === AuthenticationMethod.QUESTIONANSWER && (
                        <LoadingOverlay style={{ height: "100%" }}>
                            <Row>
                                <Form.Group as={Col} xs={4} controlId="questionTitle">
                                    <Form.Label>{NewDRConstants.QUESTION_LABEL}</Form.Label>

                                    <Form.Select onChange={handleQuestionSelect} value={defaultRecipientQuestionId}>
                                        {securityQuestionState.securityQuestions.data.map((option, index) => (
                                            <option
                                                title={option.questionTitle}
                                                key={index}
                                                value={option.messageSecurityQuestionId}
                                            >
                                                {option.questionTitle}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} xs={4} controlId="question">
                                    <Form.Label>{NewDRConstants.QUESTION_DESCRIPTION_LABEL}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        defaultValue={selectedQuestiondefault}
                                        value={prevRecipients.question}
                                        onChange={handleQAChange}
                                        title={prevRecipients.question}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {NewDRConstants.QUESTION_ERROR_TEXT}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} xs={4}>
                                    <Form.Label>{NewDRConstants.ANSWER_LABEL}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="answer"
                                        value={prevRecipients.answer}
                                        required
                                        onChange={handleQAChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {NewDRConstants.ANSWER_ERROR_TEXT}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Loader classNamePrefix="sm" text="" loading={isQuestionAnswerLoading} />
                        </LoadingOverlay>
                    )}
                    <Row className="add-files-wrapper">
                        <Form.Label>{NewDRConstants.FILE_REQUEST_LIST_LABEL}</Form.Label>
                        {filesData.length > 0 &&
                            filesData.map((file, fileIndex, files) => {
                                return (
                                    <AddFilesRow
                                        key={fileIndex}
                                        name={file.name}
                                        description={file.description}
                                        documentCategory={file.documentCategory || null}
                                        fileIndex={fileIndex}
                                        onClickRemoveRow={onClickRemoveRow}
                                        filesLength={files.length}
                                        onFilesFormChange={handleFilesFormChange}
                                        onCategoryChange={handleCategoryChange}
                                        descriptionRequired={false}
                                    />
                                );
                            })}
                        <div tabIndex={0} className="add-files-btn" onClick={onClickAddFiles}>
                            <PlusCircleIcon color={ColorConstants.brand_pimary_blue_1} />
                            <p className="margin-0-px">{NewDRConstants.ADD_FILES_TEXT}</p>
                        </div>
                    </Row>
                </Form>
                <ConfirmModal
                    className="for-new-dr-modal"
                    show={showCloseConfirmation}
                    onHide={() => setShowCloseConfirmation(false)}
                    onSubmit={() => hideModalHandler()}
                    title={NewDRConstants.CLOSE_CONFIRMATION_TITLE}
                    confirmButtonName="Ok"
                >
                    <div className="margin-bottom-10-px">{NewDRConstants.WARNING_TEXT}</div>
                    <div>
                        {NewDRConstants.CLOSE_CONFIRMATION_TEXT_1}
                        <br />
                        {NewDRConstants.CLOSE_CONFIRMATION_TEXT_2}
                    </div>
                </ConfirmModal>
                <Loader loading={isLoading || isModalLoading || isComposeLoading} text="" />
            </LoadingOverlay>
        </CustomModal>
    );
};

export default ComposeDocumentRequestModal;
