import React, { FC } from "react";
import { PageDisabledTypes } from "../../core/viewModels/user/UserViewModel";
import { AccessDeniedConstants, FeatureDisabledConstants } from "../../helper/Constants";
import { AccessDeniedIcon, DisabledByTierIcon, NoPermissionIcon, ProductDisabledIcon } from "../svg/IconCollection";
import "./style.scss";
import { signoutRedirect } from "../../oidcClient/userService";
import Button from "react-bootstrap/esm/Button";

interface IFeatureDisabledProps {
    type: number;
}

const FeatureDisabled: FC<IFeatureDisabledProps> = ({ type }) => {
    const getTitle = () => {
        switch (type) {
            case PageDisabledTypes.NoPermission:
                return FeatureDisabledConstants.DENIED_TITLE;
            case PageDisabledTypes.PageAccessDenied:
                return AccessDeniedConstants.ACCESS_DENIED_TITLE;
            default:
                return FeatureDisabledConstants.DISABLED_TITLE;
        }
    };
    const getDescription = () => {
        switch (type) {
            case PageDisabledTypes.NoPermission:
                return (
                    <div>
                        {FeatureDisabledConstants.NO_PERMISSION_DESCRIPTION}
                        <span style={{ fontWeight: 500 }}>{FeatureDisabledConstants.PRODUCT_NAME}</span>
                        {FeatureDisabledConstants.NO_PERMISSION_CONTACT_ADMINISTRATOR}
                    </div>
                );
            case PageDisabledTypes.ProductDisabled:
                return FeatureDisabledConstants.PRODUCT_DISABLED_IN_SETTINGS_DESCRIPTION;
            case PageDisabledTypes.SubscriptionTier:
                return (
                    <div>
                        {FeatureDisabledConstants.DISABLED_BY_TIER_DESCRIPTION}
                        <span style={{ fontWeight: 500 }}>{FeatureDisabledConstants.DISABLED_BY_TIER_CUSTOMER_SUCCESS_REPRESENTATIVE}</span>
                        {FeatureDisabledConstants.DISABLED_BY_TIER_ENDING}
                    </div>
                );
            case PageDisabledTypes.PageAccessDenied:
                return AccessDeniedConstants.ACCESS_DENIED_DESCRIPTION;
            default:
                return "";
        }
    };
    
    const getIcon = (height: number, width: number) => {
        switch (type) {
            case PageDisabledTypes.NoPermission:
                return <NoPermissionIcon height={height} width={width} />;
            case PageDisabledTypes.ProductDisabled:
                return <ProductDisabledIcon height={height} width={width} />;
            case PageDisabledTypes.SubscriptionTier:
                return <DisabledByTierIcon height={height} width={width} />;
            case PageDisabledTypes.PageAccessDenied:
                return <AccessDeniedIcon height={height} width={width} />;
            default:
                return null;
        }
    };

    const renderAccessDeniedButton = () => {
        if (type === PageDisabledTypes.NoPermission) {
            return (
                <div className='featureDisabled-btn'>
                    <Button
                        onClick={handleLogout}
                    >
                        {AccessDeniedConstants.ACCESS_DENIED_BUTTON_TEXT}
                    </Button>
                </div>
            );
        }
        return null;
    };

    const handleLogout = () => {
        signoutRedirect();
    };

    return <div className="featureDisabled-body">
        {getIcon(200, 200)}
        <div>
            <h5>{getTitle()}</h5>
            <p className="featureDisabled-body-text">
                {getDescription()}
            </p>
            <br/>
            {renderAccessDeniedButton()}
        </div>
    </div>;
};

export default FeatureDisabled;