import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";

interface ICustomModalProps {
    children: ReactNode;
    show: boolean;
    cancelButtonName?: string;
    confirmButtonName?: string;
    additionalActionButtonName?: string;
    cancelButtonAutomationId?: string;
    conformButtonAutomationId?: string;
    title?: string;
    hideFooter?: boolean;
    onSubmit: () => void;
    onAdditionalButtonClick?: () => void;
    onHide: () => void;
    onCancelButtonClick?: () => void;
    size?: any;
    className?: string;
    additionalFooterElement?: ReactNode;
}

export const CustomModal: React.FC<ICustomModalProps> = props => {
    const onHideModal = () => {
        if (props.onCancelButtonClick) {
            props.onCancelButtonClick();
            return;
        } else {
            props.onHide();
        }
    };
    return (
        <Modal
            className={"custom-modal-container " + props.className}
            show={props.show}
            onHide={props.onHide}
            onClick={(e: any) => {
                e.stopPropagation();
            }}
        >
            <ModalHeader
                closeButton
                closeVariant="white"
                data-test-auto={AutomationIdConstants.commonSihRih.CustomModalClose}
            >
                <Modal.Title data-test-auto={AutomationIdConstants.commonSihRih.CustomModalTitel}>
                    {props.title}
                </Modal.Title>
            </ModalHeader>
            <Modal.Body>{props.children}</Modal.Body>
            {!props.hideFooter && (
                <Modal.Footer className={props.additionalFooterElement ? "d-flex justify-content-between" : ""}>
                    {props.additionalFooterElement && (
                        <div className="additional-action">{props.additionalFooterElement}</div>
                    )}
                    <div>
                        <button
                            type="button"
                            className="btn btn-light"
                            data-test-auto={props?.cancelButtonAutomationId}
                            onClick={() => {
                                onHideModal();
                            }}
                        >
                            {props.cancelButtonName}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-test-auto={props?.conformButtonAutomationId}
                            onClick={props.onSubmit}
                        >
                            {props.confirmButtonName}
                        </button>
                        {props.additionalActionButtonName && (
                            <button
                                type="button"
                                onClick={props.onAdditionalButtonClick}
                                className="btn btn-primary"
                                data-test-auto={AutomationIdConstants.commonSihRih.CustomModalSaveAndApplyTextBtn}
                            >
                                {props.additionalActionButtonName}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};
