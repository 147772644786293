import { Action, Reducer } from "redux";
import { DispatchActions, IDropOffRestrictedFilesModel } from "../actionCreators/restrictedFileTypeActionCreators";
import produce from "immer";
import { actionTypes } from "../types/restrictedFileTypes";

const unloadedState : IDropOffRestrictedFilesModel = 
{
    audio : { color : "", fileExtension : []},
    docs : { color : "", fileExtension : []},
    images : { color : "", fileExtension : []},
    others: { color : "", fileExtension : []},
    video : { color : "", fileExtension : []}
};
export const reducer: Reducer<IDropOffRestrictedFilesModel> = produce(
    (draftState: IDropOffRestrictedFilesModel = unloadedState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.RECEIVE_RESTRICTED_FILE_TYPES:
                draftState = action.data;
                break;
            case actionTypes.REQUEST_RESTRICTED_FILE_TYPES:
                break;
            case actionTypes.COMPLETE_RESTRICTED_FILE_TYPES:
                break;
            }
            return draftState;
        }
);