import { actionTypes } from "../types/passwordPolicyTypes";
import { IPasswordPolicySettings } from "../core/viewModels/company/CompanySettingsViewModel";
import { AppThunkAction } from "../store";
import { CompanySettingsConstants } from "../helper/Constants";
import axios from "axios";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import {
    NotificationAction,
    StatusType,
} from "../reducers/notificationReducer";

export interface RequestPasswordPolicyAction {
    type: actionTypes.REQUEST_PASSWORD_POLICY_SETTINGS;
    message: string;
}

export interface ReceivePasswordPolicyAction {
    type: actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS;
    settings: IPasswordPolicySettings;
}

export type DispatchActions =
    | RequestPasswordPolicyAction
    | ReceivePasswordPolicyAction;

export type KnownActions = DispatchActions | NotificationAction;

export const requestPasswordPolicySettings =
    (): AppThunkAction<KnownActions> => (dispatch) => {
        dispatch({
            type: actionTypes.REQUEST_PASSWORD_POLICY_SETTINGS,
            message: CompanySettingsConstants.OverlayMessage.ApplicationLoading,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL_SUITE}/api/settings/password-policy`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS,
                    settings: data,
                });
            })
            .catch(function () {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage:
                        CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error,
                });
            });
    };