import * as React from "react";
import { ThemeConstants } from "../../helper/Constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";

export interface IFileIconProps {
  extension: string;
  height?: number;
  width?: number;
  disabled?: boolean;
  color?: string;
}

export const FileFormatIcon: React.FC<IFileIconProps> = (props) => {
  
  const fileFormatsSupported = useSelector((appState : ApplicationState)=>appState.restrictedFileTypeState );
  
 const getFileFormatData = (props:any) => {
    let response = {
      isSupported: false,
      color: ThemeConstants.ICON_DISABLE_COLOR
    };
  
    for (const key in fileFormatsSupported) {
      const data = fileFormatsSupported[key as keyof typeof fileFormatsSupported];
     
      if(data.fileExtension.includes(props.extension.toLowerCase())){
       response = {
         ...response,
         isSupported: true,
         color: data.color
       };
      }
    }
    return response;
  };

const file = getFileFormatData(props);
const {isSupported, color} = file;

if(isSupported && fileFormatsSupported){
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://boxy-svg.com"
      viewBox="247.475 -28.06 527.497 548.824"
      width={props.width}
      height={props.height}
    >
      <path
        fill="#E2E5E7"
        d="M378.173-6c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V122l-128-128h-224z"
      ></path>
      <path
        fill="#B0B7BD"
        d="M634.173 122h96l-128-128v96c0 17.6 14.4 32 32 32z"
      ></path>
      <path
        fill="#CAD1D8"
        d="M480 224L384 128 480 128z"
        transform="translate(250.173 -6)"
      ></path>
      <path
        fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : color}
        d="M666.173 410c0 8.8-7.2 16-16 16h-352c-8.8 0-16-7.2-16-16V250c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z"
      ></path>
      <path
        fill="#CAD1D8"
        d="M650.173 426h-304v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z"
      ></path>
      <text
        style={{ whiteSpace: "pre" }}
        x="360.831"
        y="315.376"
        fill="#FFF"
        dx="-30.865 6.734 6.734"
        dy="56.677 0 0.562"
        fontFamily="Baloo 2"
        fontSize="101"
        fontWeight="700"
      >
        {props.extension.toUpperCase()}
      </text>
      <text
        style={{ whiteSpace: "pre" }}
        x="405.163"
        y="313.693"
        fill="#333"
        fontFamily="Arial, sans-serif"
        fontSize="15.7"
      ></text>
    </svg>
  );
 }else{
   return(
    <UnknownFileIcon {...props} color={color}/>
   );
 }
};

export const UnknownFileIcon: React.FC<IFileIconProps> = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="-4 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M5.113.007c-2.803 0-5.074 2.271-5.074 5.074v53.84c0 2.803 2.271 5.074 5.074 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.606l-18.903-20.308h-31.945z"
          fill={props.color}
        />
        <path
          d="M55.976 20.352v1h-12.799s-6.312-1.26-6.129-6.707c0 0 .208 5.707 6.004 5.707h12.924z"
          fill="#617F9B"
        />
        <path
          d="M37.074 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.799l-18.903-20.352z"
          opacity=".5"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
