import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "../store";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import { actionTypes as unlockActions } from "../types/unlockTypes";
import { RequestUnlockLinks, ReceiveUnlockLinks, CompleteUnlockLinks, UpdateLinkLock } from "../actions/unlockActions";
import { ILockLinkModel } from "../core/viewModels/unlock/UnlockModel";
import { AppNotifier } from "../helper/AppNotifier";
import { UnlockLinkConstants } from "../helper/Constants";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { UnlockType } from "../core/common/enums";

export type DispatchActions =
  | RequestUnlockLinks
  | ReceiveUnlockLinks
  | CompleteUnlockLinks
  | UpdateLinkLock

export type KnownActions = DispatchActions | NotificationAction;

export const getUnlockLinks = (unlockType:UnlockType): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: unlockActions.REQUEST_UNLOCK_LINKS,
      });

      const url:string = unlockType == UnlockType.Requests ?
      `${process.env.REACT_APP_API_URL}/api/DocumentRequest/GetLockedLinks`:
      `${process.env.REACT_APP_API_URL}/api/LockedLinks/GetLockedLinks`;
      

      axios
        .get(url)
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: unlockActions.RECEIVE_UNLOCK_LINKS,
            payload: data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        }).finally(() => {
          dispatch({
            type: unlockActions.COMPLETE_UNLOCK_LINKS,
          });
        });
    };


    export const updateUnlockLinks =
    (
      unlockLinks: ILockLinkModel[],
      unlockType?:UnlockType ,
      callback?: (reload: boolean) => void   
    ): AppThunkAction<KnownActions> =>
      (dispatch) => {
        dispatch({
          type: unlockActions.UPDATE_LINK_LOCK,
        });

        // Track Unlock link click event
        unlockLinks && unlockLinks.map(linkData => {
          logger.trackEvent({
            name: `${appInsightsData.Unlock.PageTitle} - ${appInsightsData.Unlock.UnlockLinkClicked}`,
            properties: {
              page: appInsightsData.Unlock.PageTitle,
              ...linkData  
            }
          }); 
        });
        
        const url =  unlockType == UnlockType.Requests ?
        `${process.env.REACT_APP_API_URL}/api/DocumentRequest/UnlockLockedRequestLinks`:
        `${process.env.REACT_APP_API_URL}/api/LockedLinks/UnlockLockedLinks`;
        
        axios
          .put(url, unlockLinks)
          .then(() => {
            AppNotifier.Success(UnlockLinkConstants.UNLOCK_SUCCESS_MESSAGE);
            if(callback){
              callback(true);
            }
          })
          .catch(function (error: any) {
            dispatch({
              type: notifactionAction.NOTIFICATION,
              statusMessage: error.message,
              statusType: StatusType.Error,
            });
          }).finally(() => {
            dispatch({
              type: unlockActions.COMPLETE_UNLOCK_LINKS,
            });
          });
      };
