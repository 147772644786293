import { Action, Reducer } from "redux";
import { produce } from "immer";

import { IRetentionPeriod } from "../core/viewModels/company/settings/RetentionSettings";
import { DispatchActions } from "../actionCreators/folderActionCreator";
import { actionTypes } from "../types/folderTypes";
import {
    IFolderModel,
    IMyDownloadFile,
    ISharedFolderGroupPermissionDetailViewModel,
    ISharedFolderPermissionDetailViewModel,
    initialFolder,
} from "../core/viewModels/folder/FolderModel";
import { IMessageFileViewModel } from "../core/viewModels/message/MessageFileViewModel";

export interface IFolderState {
    foldersData: {
        folders?: IFolderModel[];
        subFolders?: {
            isLoading: boolean;
            parentFolderId: number;
            folders: IFolderModel[];
            isMessageLoading: boolean;
        };
        sharedFolderData?: {
            isLoading: boolean;
            data: {
                users: ISharedFolderPermissionDetailViewModel[],
                groups: ISharedFolderGroupPermissionDetailViewModel[]
            };
        };
        isLoading: boolean;
        resetBreadcrumb: number;
    };
    foldersTreeData: {
        isLoading: boolean;
        treeFolders: IFolderModel[];
    };
    fileViewData: {
        isLoading: boolean;
        fileData: IMessageFileViewModel[];
        isDownloading: boolean;
    };
    retentionPeriodsData: {
        retentionPeriods: IRetentionPeriod[];
        isLoading: boolean;
    };
    addNewFolder: {
        isProcessing: boolean;
    };
    updateFolderExpiry: {
        isProcessing: boolean;
    };
    deleteFolder: {
        isProcessing: boolean;
    };
    moveFolders: {
        isProcessing: boolean;
    };
    renameFolder: {
        isProcessing: boolean;
    };
    myDownloads: {
        isLoading: boolean;
        myDownloadList: IMyDownloadFile[];
    };
    breadcrumb: {
        isLoading: boolean;
        folders: IFolderModel[];
    };
    folderDropOff: {
        isLoading: boolean;
        dropOffUrl: string;
    };
    currentFolder: {
        isLoading: boolean;
        data: IFolderModel;
    };
    userOwnedFolders: {
        isLoading: boolean;
        data: IFolderModel[];
    };
}

const unloadedState: IFolderState = {
    foldersData: {
        folders: [],
        isLoading: false,
        resetBreadcrumb: 0,
    },
    foldersTreeData: {
        isLoading: false,
        treeFolders: [],
    },
    fileViewData: {
        isLoading: false,
        fileData: [],
        isDownloading: false,
    },
    retentionPeriodsData: {
        retentionPeriods: [],
        isLoading: false,
    },
    addNewFolder: {
        isProcessing: false,
    },
    updateFolderExpiry: {
        isProcessing: false,
    },
    deleteFolder: {
        isProcessing: false,
    },
    moveFolders: {
        isProcessing: false,
    },
    renameFolder: {
        isProcessing: false,
    },
    myDownloads: {
        isLoading: false,
        myDownloadList: [],
    },
    breadcrumb: {
        isLoading: false,
        folders: [],
    },
    folderDropOff: {
        isLoading: false,
        dropOffUrl: "",
    },
    currentFolder: {
        isLoading: false,
        data: initialFolder,
    },
    userOwnedFolders: {
        isLoading: false,
        data: [],
    },
};

export const reducer: Reducer<IFolderState> = produce(
    (draftState: IFolderState = unloadedState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_FOLDERS:
                draftState.foldersData.isLoading = true;
                break;
            case actionTypes.RECEIVED_FOLDERS:
                draftState.foldersData.isLoading = false;
                draftState.foldersData.folders = action.folders;
                break;
            case actionTypes.COMPLETED_FOLDERS:
                draftState.foldersData.isLoading = false;
                draftState.foldersData.folders = [];
                break;
            case actionTypes.REQUEST_FOLDERS_TREE:
                draftState.foldersData.isLoading = true;
                break;
            case actionTypes.RECEIVED_FOLDERS_TREE:
                draftState.foldersTreeData.isLoading = false;
                draftState.foldersTreeData.treeFolders = action.folders;
                break;
            case actionTypes.COMPLETED_FOLDERS_TREE:
                draftState.foldersTreeData.isLoading = false;
                break;
            case actionTypes.REQUEST_RETENTION_PERIODS:
                draftState.retentionPeriodsData.isLoading = true;
                break;
            case actionTypes.RECEIVED_RETENTION_PERIODS:
                draftState.retentionPeriodsData.isLoading = false;
                draftState.retentionPeriodsData.retentionPeriods = action.data;
                break;
            case actionTypes.COMPLETED_RETENTION_PERIODS:
                draftState.retentionPeriodsData.isLoading = false;
                break;
            case actionTypes.REQUEST_UPDATE_FOLDER_EXPIRY:
                draftState.updateFolderExpiry.isProcessing = true;
                break;
            case actionTypes.COMPLETED_UPDATE_FOLDER_EXPIRY:
                draftState.updateFolderExpiry.isProcessing = false;
                //TODO: uncomment when change folder retention implemented
                // if (
                //   draftState.foldersData.folders &&
                //   draftState.foldersData.folders.length > 0
                // ) {
                //   const folder = getArchiveFolderViewModel(
                //     draftState.foldersData.folders,
                //     action.folder.folderId
                //   );
                //   if (folder) {
                //     folder.expiresDays = action.folder.expiresDays;
                //   }
                // }
                break;
            case actionTypes.REQUEST_ADD_NEW_FOLDER:
                draftState.addNewFolder.isProcessing = true;
                break;
            case actionTypes.COMPLETED_ADD_NEW_FOLDER:
                draftState.addNewFolder.isProcessing = false;
                break;
            case actionTypes.REQUEST_DELETE_FOLDER:
                draftState.deleteFolder.isProcessing = true;
                break;
            case actionTypes.COMPLETED_DELETE_FOLDER:
                draftState.deleteFolder.isProcessing = false;
                //TODO: uncomment when delete folder implemented
                // draftState.foldersData.folders = deleteFolder(
                //   draftState.foldersData.folders
                //     ? JSON.parse(
                //         JSON.stringify(original(draftState.foldersData.folders))
                //       )
                //     : [],
                //   action.folder
                // );
                break;
            case actionTypes.REQUEST_SUB_FOLDERS:
                if (draftState.foldersData.subFolders) {
                    draftState.foldersData.subFolders.isLoading = true;
                    draftState.foldersData.subFolders.parentFolderId = action.folderId;
                } else {
                    draftState.foldersData.subFolders = {
                        isLoading: true,
                        folders: [],
                        parentFolderId: action.folderId,
                        isMessageLoading: false,
                    };
                }
                break;
            case actionTypes.RECEIVED_SUB_FOLDERS:
                if (draftState.foldersData.subFolders) {
                    draftState.foldersData.subFolders.isLoading = true;
                    draftState.foldersData.subFolders.folders = action.folders;
                }
                break;
            case actionTypes.COMPLETED_SUB_FOLDERS:
                if (draftState.foldersData.subFolders) {
                    draftState.foldersData.subFolders.isLoading = false;
                }
                break;
            case actionTypes.REQUEST_UPDATE_FOLDERS_EXPIRY:
                draftState.updateFolderExpiry.isProcessing = true;
                break;
            case actionTypes.COMPLETED_UPDATE_FOLDERS_EXPIRY:
                draftState.updateFolderExpiry.isProcessing = false;
                if (action.result) {
                    draftState.foldersData.subFolders?.folders.map(f => {
                        if (action.folderIds.includes(f.folderId)) f.expiresDays = action.expiresDays;
                        return f;
                    });
                }
                break;
            case actionTypes.REQUEST_DELETE_FOLDERS:
                draftState.deleteFolder.isProcessing = true;
                break;
            case actionTypes.COMPLETED_DELETE_FOLDERS:
                draftState.deleteFolder.isProcessing = false;
                if (action.result && draftState.foldersData.subFolders?.folders) {
                    draftState.foldersData.subFolders.folders = draftState.foldersData.subFolders?.folders.filter(
                        f => !action.folderIds.includes(f.folderId)
                    );
                }
                break;
            case actionTypes.REQUEST_MOVE_FOLDERS:
                draftState.moveFolders.isProcessing = true;
                break;
            case actionTypes.COMPLETED_MOVE_FOLDERS:
                draftState.moveFolders.isProcessing = false;
                if (action.result && draftState.foldersData.subFolders?.folders) {
                    draftState.foldersData.subFolders.folders = draftState.foldersData.subFolders?.folders.filter(
                        f => !action.folderIds.includes(f.folderId)
                    );
                }
                break;
            case actionTypes.REQUEST_CHECK_FOLDER_MESSAGES:
                if (draftState.foldersData.subFolders) draftState.foldersData.subFolders.isMessageLoading = true;
                break;
            case actionTypes.COMPLETED_CHECK_FOLDER_MESSAGES:
                if (draftState.foldersData.subFolders) draftState.foldersData.subFolders.isMessageLoading = false;
                break;
            case actionTypes.REQUEST_UPDATE_FOLDER_NAME:
                draftState.renameFolder.isProcessing = true;
                break;
            case actionTypes.COMPLETED_UPDATE_FOLDER_NAME:
                draftState.renameFolder.isProcessing = false;
                break;
            case actionTypes.RECEIVE_UPDATE_FOLDER_NAME:
                draftState.renameFolder.isProcessing = false;
                if (draftState.foldersData?.subFolders?.folders) {
                    draftState.foldersData.subFolders.folders = draftState.foldersData.subFolders?.folders.map(f => {
                        if (action.payload.folderId === f.folderId) f = action.payload;
                        return f;
                    });
                }
                break;
            case actionTypes.REQUEST_MESSAGE_FILE_VIEW_DATA:
                draftState.fileViewData.isLoading = true;
                break;
            case actionTypes.RECEIVE_MESSAGE_FILE_VIEW_DATA:
                draftState.fileViewData.isLoading = false;
                draftState.fileViewData.fileData = action.payload;
                break;
            case actionTypes.COMPLETED_MESSAGE_FILE_VIEW_DATA:
                draftState.fileViewData.isLoading = false;
                break;
            case actionTypes.RESET_MESSAGE_FILE_VIEW_DATA:
                draftState.fileViewData.fileData = [];
                draftState.fileViewData.isLoading = false;
                break;
            case actionTypes.REQUEST_DOWNLOAD_NOW:
                draftState.fileViewData.isDownloading = true;
                break;
            case actionTypes.RECEIVE_DOWNLOAD_NOW:
                draftState.fileViewData.isDownloading = false;
                break;
            case actionTypes.COMPLETE_DOWNLOAD_NOW:
                draftState.fileViewData.isDownloading = false;
                break;
            case actionTypes.REQUEST_DOWNLOAD_LATER:
                draftState.fileViewData.isDownloading = true;
                break;
            case actionTypes.RECEIVE_DOWNLOAD_LATER:
                draftState.fileViewData.isDownloading = false;
                break;
            case actionTypes.COMPLETE_DOWNLOAD_LATER:
                draftState.fileViewData.isDownloading = false;
                break;
            case actionTypes.SET_RESET_BREADCRUMB:
                if (action.payload) {
                    ++draftState.foldersData.resetBreadcrumb;
                } else {
                    draftState.foldersData.resetBreadcrumb = 0;
                }
                break;
            case actionTypes.REQUEST_MY_DOWNLOADS_LIST:
                draftState.myDownloads.isLoading = true;
                break;
            case actionTypes.RECEIVE_MY_DOWNLOADS_LIST:
                draftState.myDownloads.isLoading = false;
                draftState.myDownloads.myDownloadList = action.payload;
                break;
            case actionTypes.COMPLETE_MY_DOWNLOADS_LIST:
                draftState.myDownloads.isLoading = false;
                break;
            case actionTypes.REQUEST_SHARE_FOLDER:
                draftState.foldersData.sharedFolderData = {
                    isLoading: true,
                    data: draftState.foldersData.sharedFolderData?.data ?? {
                        users: [],
                        groups: []
                    },
                };
                break;
            case actionTypes.RECEIVE_SHARE_FOLDER:
                draftState.foldersData.sharedFolderData = {
                    isLoading: false,
                    data: action.sharedUsersData,
                };
                break;
            case actionTypes.COMPLETE_SHARE_FOLDER:
                draftState.foldersData.sharedFolderData = {
                    isLoading: false,
                    data: draftState.foldersData.sharedFolderData?.data ?? {
                        users: [],
                        groups: []
                    },
                };
                break;
            case actionTypes.REQUEST_FOLDER_BREADCRUMB:
                draftState.breadcrumb.isLoading = true;
                break;
            case actionTypes.RECEIVE_FOLDER_BREADCRUMB:
                draftState.breadcrumb.isLoading = false;
                draftState.breadcrumb.folders = action.folders;
                break;
            case actionTypes.COMPLETE_FOLDER_BREADCRUMB:
                draftState.breadcrumb.isLoading = false;
                break;
            case actionTypes.REQUEST_FOLDER_DROPOFF_LINK:
                draftState.folderDropOff.isLoading = true;
                break;
            case actionTypes.RECEIVE_FOLDER_DROPOFF_LINK:
                draftState.folderDropOff.isLoading = false;
                draftState.folderDropOff.dropOffUrl = action.dropOffUrl;
                break;
            case actionTypes.COMPLETE_FOLDER_DROPOFF_LINK:
                draftState.folderDropOff.isLoading = false;
                break;
            case actionTypes.REQUEST_FOLDER_DETAIL:
                draftState.currentFolder.isLoading = true;
                break;
            case actionTypes.RECEIVE_FOLDER_DETAIL:
                draftState.currentFolder.isLoading = false;
                draftState.currentFolder.data = action.data;
                break;
            case actionTypes.COMPLETE_FOLDER_DETAIL:
                draftState.currentFolder.isLoading = false;
                break;
            case actionTypes.REQUEST_USEROWNED_FOLDERS:
                draftState.userOwnedFolders.isLoading = true;
                break;
            case actionTypes.RECEIVE_USEROWNED_FOLDERS:
                draftState.userOwnedFolders.isLoading = false;
                draftState.userOwnedFolders.data = action.data;
                break;
            case actionTypes.COMPLETE_USEROWNED_FOLDERS:
                draftState.userOwnedFolders.isLoading = false;
                break;
            default:
                break;
        }
        return draftState;
    }
);
