import React, { FC, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { fetchLoginHistory } from "../../actionCreators/commonActionCreators";
import { updateLastLoginEnable } from "../../actionCreators/userSettingsActionCreators";
import { IUserLoginHistory } from "../../core/viewModels/user/UserViewModel";
import { DateFormatConstants, LoginHistoryConstants } from "../../helper/Constants";
import { getBrowserDetails } from "../../helper/HelperFunctions";
import { ApplicationState } from "../../store";
import CustomToggleSwitch from "../common/ui/switch/CustomToggleSwitch";
import { LoginHistoryIcon } from "../svg/HeaderMenuIcons";
import { logger } from "../../oidcClient/authProvider";
import { appInsightsData } from "../../helper/AppInsightsData";
import { getFormattedLocalDateTime } from "../../helper/DateHelperFunctions";

interface ILastLoginProps {
  show: boolean;
  onHide: React.Dispatch<React.SetStateAction<boolean>>;
}

const LastLoginModal: FC<ILastLoginProps> = ({ show, onHide }) => {
  const [enablePrompt, setEnablePrompt] = useState(false);
  const [lastLogin, setLastLogin] = useState<IUserLoginHistory>();
  const {
    loginHistoryLoading,
    loginHistory: { loginHistory, deviceId },
  } = useSelector((state: ApplicationState) => state.commonState);
  const dispatch = useDispatch();
  const handleCancel = () => {
    onHide(false);
  };
  const handleToggleChange = (value: boolean) => {
    dispatch(updateLastLoginEnable(!value));
    setEnablePrompt(value);
    if (value) {
      logger.trackEvent({
        name: `${appInsightsData.LoginHistory.PageTitle} - ${appInsightsData.LoginHistory.Operation.PromptToggleEnabled}`,
        properties: {
          page: appInsightsData.LoginHistory.PageTitle
        }
      });
    } else {
      logger.trackEvent({
        name: `${appInsightsData.LoginHistory.PageTitle} - ${appInsightsData.LoginHistory.Operation.PromptToggleDisabled}`,
        properties: {
          page: appInsightsData.LoginHistory.PageTitle
        }
      });
    }
  };

  useEffect(() => {
    dispatch(fetchLoginHistory(1, LoginHistoryConstants.COUNT_PER_PAGE));
  }, []);

  useEffect(() => {
    if (loginHistory?.length > 1) {
      loginHistory[0].deviceId === deviceId
        ? setLastLogin(loginHistory[1])
        : setLastLogin(loginHistory[0]);
    }
  }, [loginHistory]);

  return (
    <Modal
      size="sm"
      show={show && (lastLogin ? true : false)}
      dialogClassName="custom-bootstrap-modal last-login-modal"
      onHide={handleCancel}
    >
      <Modal.Header closeButton closeVariant="white">
        {LoginHistoryConstants.LAST_LOGIN_MODAL_TITLE}
      </Modal.Header>
      <Modal.Body>
        <div className="last-login-content padding-left-right-20-px">
          <div className="title-wrapper margin-bottom-20-px">
            <LoginHistoryIcon width={24} height={24} color="#737373" />
            <span className="heading-blue-1 margin-left-10-px">
              {LoginHistoryConstants.LAST_LOGIN}
            </span>
          </div>
          <LoadingOverlay>
            <Row>
              <Col sm={3}>Log In On</Col>
              <Col>
                {getFormattedLocalDateTime(lastLogin?.loggedInOn ?? "", DateFormatConstants.LAST_LOGIN_DATE_FORMAT)}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>IP Address</Col>
              <Col>{lastLogin?.IPAddress}</Col>
            </Row>
            <Row>
              <Col sm={3}>Browser</Col>
              <Col>{lastLogin && getBrowserDetails(lastLogin.clientApp)}</Col>
            </Row>
            <Loader
              classNamePrefix="sm"
              text=""
              loading={loginHistoryLoading}
            />
          </LoadingOverlay>
        </div>
      </Modal.Body>
      <Modal.Footer className="space-between">
        <div className="prompt-control">
          <CustomToggleSwitch
            label={LoginHistoryConstants.LAST_LOGIN_PROMPT_TEXT}
            switched={enablePrompt}
            handleChange={() => handleToggleChange(!enablePrompt)}
          />
           <i
            className="fas fa-exclamation-circle margin-left-5-px"
            title={LoginHistoryConstants.LAST_LOGIN_PROMPT_INFO}
          />
        </div>
        <Button
          size="lg"
          className="margin-right-10-px"
          variant="outline-secondary"
          onClick={handleCancel}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LastLoginModal;
