export enum actionTypes {
    REQUEST_SUPPORT_CONTACT_LIST = "request_support_contact_list",
    RECEIVE_SUPPORT_CONTACT_LIST = "receive_support_contact_list",
    COMPLETE_SUPPORT_CONTACT_LIST = "complete_support_contact_list",

    REQUEST_UPDATE_ADMIN_SETTINGS = "request_update_admin_settings",
    COMPLETE_UPDATE_ADMIN_SETTINGS = "complete_update_admin_settings",

    REQUEST_SUPPORT_CONTACT_SETTINGS = "request_support_contact_settings",
    RECEIVE_SUPPORT_CONTACT_SETTINGS = "receive_support_contact_settings",

    REQUEST_FETCH_ADMIN_SETTINGS = "request_fetch_admin_settings",
    COMPLETE_ADMIN_SETTINGS = "compelte_admin_settings",

    REQUEST_ALL_RETENTION_PERIODS = "request_all_retention_periods",
    RECEIVE_ALL_RETENTION_PERIODS = "receive_all_retention_periods",

    REQUEST_RETENTION_SETTINGS = "request_retention_settings",
    RECEIVE_RETENTION_SETTINGS = "receive_retention_settings",

    REQUEST_PRIMARY_ADMIN = "request_primary_admin",
    RECEIVE_PRIMARY_ADMIN = "receive_primary_admin",
    COMPLETE_PRIMARY_ADMIN = "complete_primary_admin",

    RECEIVE_MESSAGE_HISTORY_SENT_ITEMS_SETTINGS = "receive_message_history_sent_items_settings",
    RECEIVE_MESSAGE_HISTORY_RECEIVED_ITEMS_SETTINGS = "receive_message_history_received_items_settings",
    RECEIVE_REQUEST_HISTORY_SETTINGS = "receive_request_history_settings",

    RECEIVE_AUTHENTICATION_SETTINGS = "receive_authentication_settings",
    RECEIVE_MESSAGE_SECURITY_QUESTIONS_SETTINGS = "receive_message_security_questions_settings",

    RECEIVE_DROP_OFF_SETTINGS = "receive_drop_off_settings",

    REQUEST_NOTIFICATIONS_SETTINGS = "request_notifications_settings",
    RECEIVE_NOTIFICATIONS_SETTINGS = "receive_notifications_settings",

    REQUEST_REQUESTNOTIFICATIONS_SETTINGS = "request_requestnotifications_settings",
    RECEIVE_REQUESTNOTIFICATIONS_SETTINGS = "receive_requestnotifications_settings",
}
