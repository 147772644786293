import React from "react";

import { IconImageProps } from "./IconCollection";

export const ComposeIcon: React.FC<IconImageProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 24 24"
      width={props.width || 24}
      height={props.height || 24}
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="Action-/-8---Action,-edit,-compose,-new,-pencil-icon"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path
          d="M22,16 L22,21.2 C22,21.6418278 21.6418278,22 21.2,22 L2.8,22 C2.3581722,22 2,21.6418278 2,21.2 L2,2.8 C2,2.3581722 2.3581722,2 2.8,2 L8,2"
          id="Path"
          stroke={props.color || "#5b5b5b"}
          strokeWidth="2"
        />
        <path
          d="M19.9981337,8.99633418 L11.0527503,17.999822 L6.409568,17.999822 C6.1900421,18.0063958 6.00675181,17.8337639 6.00004437,17.614238 C6.00004437,17.6063045 6.00004437,17.5983655 6.00004437,17.590432 L6.00004437,13 L15.0005937,3.99950542 L19.9981337,8.99633418 Z M21.5887575,7.41064406 L20,9.00496321 L20,9.00496321 L15.0017879,3.99829297 L16.5863759,2.41371468 C17.3674908,1.63281439 18.6337398,1.63277127 19.4149079,2.41361835 L21.586188,4.58458946 C22.3663666,5.36465701 22.3675163,6.62915912 21.5887575,7.41064406 Z"
          id="Path"
          stroke={props.color || "#5b5b5b"}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const ComposeSettingIcon: React.FC<IconImageProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.width || 24}
      height={props.height || 24}
    >
      <defs></defs>
      <g data-name="Layer 2" id="Layer_2">
        <g id="Workspace">
          <rect fill="none" />
          <path
            fill="none"
            stroke={props.color || "#5b5b5b"}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.22,7l.28-2h3l.28,2a5.53,5.53,0,0,1,1.69,1l1.84-.74,1.5,2.6L17.25,11a5.33,5.33,0,0,1,0,2l1.56,1.22-1.5,2.6-1.84-.74a4.58,4.58,0,0,1-.8.56,4.73,4.73,0,0,1-.89.41l-.28,2h-3l-.28-2a5.53,5.53,0,0,1-1.69-1l-1.84.74-1.5-2.6L6.75,13a5.33,5.33,0,0,1,0-2L5.19,9.8l1.5-2.6,1.84.74a4.58,4.58,0,0,1,.8-.56A4.73,4.73,0,0,1,10.22,7Z"
          />
          <circle
            fill="none"
            stroke={props.color || "#5b5b5b"}
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="12"
            cy="12"
            r="2.5"
          />
        </g>
      </g>
    </svg>
  );
};
