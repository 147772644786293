import { DocumentCategorySortColumn, SortOrder } from "../../common/enums";

export interface IDocumentCategoryModel {
    documentCategoryId: number;
    name: string;
    createdDate?: Date;
    createdBy?: number;
    count: number;
}

export interface IDocumentCategoryRequest {
    sortColumn: DocumentCategorySortColumn;
    sortOrder: SortOrder;
    pageNumber: number;
    pageSize: number;
}

export const initialCategoryRequest: IDocumentCategoryRequest = {
    sortColumn: DocumentCategorySortColumn.CreatedDate,
    sortOrder: SortOrder.DESC,
    pageNumber: 1,
    pageSize: 20,
};
