import * as React from "react";

export interface ISwitchButtonGroupProps {
  option1Text: string;
  option2Text: string;
  option1ResourceDataId?: string;
  option2ResourceDataId?: string;
  onSwitchChange?: (option: string) => void;
  selected: string;
  option1Disabled?: boolean;
  option2Disabled?: boolean;
  className?: string;
}

export const SwitchButtonGroup: React.FC<ISwitchButtonGroupProps> = (props) => {
  return (
    <ul className={`switch-button-group ${props.className}`}>
      <li
        className={
          (props.selected === props.option1Text ? "active" : "") +
          (props.option1Disabled ? " disabled " : "")
        }
        data-resource-id={props?.option1ResourceDataId}
        onClick={() => {
          !props.option1Disabled &&
            props.onSwitchChange &&
            props.onSwitchChange(props.option1Text);
        }}
      >
        {props.option1Text}
      </li>
      <li
        className={
          (props.selected === props.option2Text ? "active" : "") +
          (props.option2Disabled ? " disabled " : "")
        }
        data-resource-id={props?.option2ResourceDataId}
        onClick={() => {
          !props.option2Disabled &&
            props.onSwitchChange &&
            props.onSwitchChange(props.option2Text);
        }}
      >
        {props.option2Text}
      </li>
    </ul>
  );
};

SwitchButtonGroup.displayName = SwitchButtonGroup.name;
export default SwitchButtonGroup;
