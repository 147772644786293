import { Action, Reducer } from "redux";
import { produce } from "immer";
import { DispatchActions } from "../actions/otpActions";
import { actionTypes } from "../types/otpTypes";
import { IOTPState } from "../core/viewModels/profile/ProfileViewModel";

export interface IOneTimePasswordState {
  otpState: IOTPState;
  isLoading: boolean;
}

const unloadedState: IOneTimePasswordState = {
  otpState: {
    isOTPValid: false,
    mfaOTPLength: 0,
  },
  isLoading: false,
};

export const reducer: Reducer<IOneTimePasswordState> = produce(
  (
    draftState: IOneTimePasswordState = unloadedState,
    incomingAction: Action
  ) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_ONETIMEPASSWORD:
        draftState.isLoading = true;
        break;
      case actionTypes.RECEIVE_ONETIMEPASSWORD:
        draftState.isLoading = false;
        break;
      case actionTypes.REQUEST_VALIDATE_ONETIMEPASSWORD:
        draftState.isLoading = true;
        break;
      case actionTypes.COMPLETED_VALIDATE_ONETIMEPASSWORD:
        draftState.isLoading = false;
        draftState.otpState.isOTPValid = action.otpState.isOTPValid;
        break;
      case actionTypes.REQUEST_MFA_ONETIMEPASSWORD_LENGTH:
        draftState.isLoading = true;
        break;
      case actionTypes.RECEIVE_MFA_ONETIMEPASSWORD_LENGTH:
        draftState.isLoading = false;
        draftState.otpState = action.otpState;
        break;
    }
    return draftState;
  }
);
