import React, { ChangeEventHandler, FC } from "react";

interface IFileAttachmentProps {
    onChange: ChangeEventHandler<HTMLInputElement>;
    label?: string;
    fileFormat?: string;
    infoText?: string;
}

const FileAttachment: FC<IFileAttachmentProps> = ({onChange, label, fileFormat, infoText}) => {
    return <div className="file-attachment">
        <i className="fas fa-paperclip" />
        <label className="text-primary" htmlFor="attachment">{label}</label>
        <input id="attachment" type="file" accept={fileFormat || ""} onChange={onChange}/>
        <div className="info-text">{infoText}</div>
    </div>;
};

export default FileAttachment;