export enum actionTypes {
  REQUEST_DRAFT_MESSAGES = "request_draft_messages",
  RECEIVED_DRAFT_MESSAGES = "received_draft_messages",
  COMPLETED_DRAFT_MESSAGES = "completed_draft_messages",

  REQUEST_DRAFT_MESSAGE_DETAIL = "request_draft_message_detail",
  RECEIVED_DRAFT_MESSAGE_DETAIL = "received_draft_message_detail",
  COMPLETED_DRAFT_MESSAGE_DETAIL = "completed_draft_message_detail",

  REQUEST_UPDATE_DRAFT_MESSAGE = "request_update_draft_message",
  COMPLETED_UPDATE_DRAFT_MESSAGE = "completed_update_draft_message",

  REQUEST_DELETE_DRAFT_MESSAGE = "request_delete_draft_message",
  COMPLETED_DELETE_DRAFT_MESSAGE = "completed_delete_draft_message",

  DRAFT_MESSAGE_DELETED = "draft_message_deleted"
}