import React, { useEffect } from "react";
import { ColorConstants } from "../../../assets/custom/colors";
import { DetailedStatus } from "../../../core/common/enums";
import { IDocumentRequestFile } from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { getFormattedDueDate } from "../../../helper/DateHelperFunctions";
import { BellIcon, CheckCircleFill, ExclamationTriangleFill, MinusIcon, PlusIcon } from "../../svg/IconCollection";
import { NewDeleteIcon, NewDownloadIcon, PreviewIcon } from "../../svg/ToolbarIcons";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { NewDRConstants } from "../../../helper/Constants";
import { isDelegatedUser } from "../../../helper/HelperFunctions";

interface IDocumentRowProps {
    id: number;
    status: DetailedStatus;
    file: IDocumentRequestFile;
    activeDocuments: number[];
    isExpanded: boolean;
    setActiveDocument: (documentRequestId: number) => void;
    onClickDeleteDocument: (fileId: number, isFileLevelOperation?: boolean) => void;
    onClickSpecificFileSendReminder: (fileName: string) => void;
    onClickDownloadFile: (fileName: string, fileGuid: string) => void;
    onClickDownloadAllFiles: (documentRequest: IDocumentRequestFile) => void;
    onClickPreviewUploadedDocument: (fileName: string, fileGuid: string, fileType: string) => void;
}

export const DocumentRow: React.FC<IDocumentRowProps> = ({
    id,
    file,
    activeDocuments,
    isExpanded,
    setActiveDocument,
    onClickDeleteDocument,
    onClickDownloadFile,
    onClickDownloadAllFiles,
    onClickSpecificFileSendReminder,
    onClickPreviewUploadedDocument,
    status,
}) => {
    const [isActive, setIsActive] = React.useState(false);

    useEffect(() => {
        if (activeDocuments && activeDocuments.length > 0 && activeDocuments.includes(file.documentRequestFileId)) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [activeDocuments.length, isExpanded]);

    const handleRowClick = () => {
        if (file.uploadedFiles && file.uploadedFiles?.length > 0) {
            setIsActive(!isActive);
            setActiveDocument(file.documentRequestFileId);
        }
    };

    return (
        <div key={id} className="document-row-container">
            <div
                className={`document-row-wrapper accordion ${isActive && "active"} ${
                    file?.uploadedFiles?.length === 0 && "disabled-accordion"
                }`}
                onClick={handleRowClick}
            >
                <div className="name-icon-wrapper">
                    {file.uploadedFiles?.length > 0 ? <CheckCircleFill /> : <ExclamationTriangleFill />}
                    <span title={file.name} className="overflowText">
                        {file.name}
                        {file.documentCategoryName && (
                            <span title={file.documentCategoryName} className="document-category-name overflowText">
                                {file.documentCategoryName}
                            </span>
                        )}
                    </span>
                </div>

                <div className="inline-button-group">
                    <>
                        {file.uploadedFiles && file.uploadedFiles.length > 0 ? (
                            <>
                                <p className="inline-button">
                                    {getFormattedDueDate(
                                        file.uploadedFiles[file.uploadedFiles.length - 1].uploadedDate
                                    )}
                                </p>

                                <button
                                    type="button"
                                    title={NewDRConstants.DOWNLOAD_ALL}
                                    className="inline-button"
                                    data-resource-id={ResourceIdLocators.DRL.DRLBtnDownloadFile}
                                    onClick={() => onClickDownloadAllFiles(file)}
                                >
                                    <NewDownloadIcon
                                        width={17}
                                        height={17}
                                        color={ColorConstants.brand_pimary_blue_1}
                                    />
                                    {NewDRConstants.DOWNLOAD_ALL}
                                </button>
                                {!isDelegatedUser() && (
                                    <button
                                        disabled={status === DetailedStatus.COMPLETED}
                                        type="button"
                                        title={NewDRConstants.DELETE_ALL}
                                        className="inline-button"
                                        data-resource-id={ResourceIdLocators.DRL.DRLBtnDeleteDocument}
                                        onClick={() => onClickDeleteDocument(file.documentRequestFileId, false)}
                                    >
                                        <NewDeleteIcon
                                            width={15}
                                            height={15}
                                            color={ColorConstants.brand_pimary_blue_1}
                                        />
                                        {NewDRConstants.DELETE_ALL}
                                    </button>
                                )}
                            </>
                        ) : (
                            status !== DetailedStatus.COMPLETED && (
                                <button
                                    type="button"
                                    className="inline-button"
                                    title={NewDRConstants.SEND_REMINDER}
                                    data-resource-id={ResourceIdLocators.DRL.DRLDtlBtnDocSendReminder}
                                    onClick={() => onClickSpecificFileSendReminder(file.name)}
                                >
                                    <BellIcon width={15} height={15} color={ColorConstants.brand_pimary_blue_1} />
                                    {NewDRConstants.SEND_REMINDER}
                                </button>
                            )
                        )}

                        <span className="accordion-toggle">
                            {isActive && file.uploadedFiles && file.uploadedFiles?.length > 0 ? (
                                <MinusIcon />
                            ) : (
                                <PlusIcon
                                    color={file.uploadedFiles && file.uploadedFiles?.length > 0 ? "#05386B" : "#C4C6C8"}
                                />
                            )}
                        </span>
                    </>
                </div>
            </div>
            {file.uploadedFiles && file.uploadedFiles.length > 0 && (
                <div className="panel" style={{ display: isActive ? "flex" : "none" }}>
                    {file.uploadedFiles?.length > 0 &&
                        file.uploadedFiles.map(uploadedFile => (
                            <div key={uploadedFile.fileGuid} className="file-wrapper">
                                <div className="name-icon-wrapper file-name">
                                    <span title={uploadedFile.fileName} className="overflowText">
                                        {uploadedFile.fileName}
                                    </span>
                                </div>

                                <div className="inline-button-group">
                                    <>
                                        <button
                                            disabled={uploadedFile.fileName.slice(-3) !== "pdf"}
                                            type="button"
                                            title={
                                                uploadedFile.fileName.slice(-3) !== "pdf"
                                                    ? NewDRConstants.DISABLED_PREVIEW_BUTTON
                                                    : ""
                                            }
                                            className="inline-button"
                                            data-test-auto={"SSE_DRD_BtnPreview"} // To be replaced with variable from AutomationId constants
                                            onClick={() =>
                                                onClickPreviewUploadedDocument(
                                                    uploadedFile.fileName,
                                                    uploadedFile.fileGuid,
                                                    uploadedFile.fileName.slice(-3)
                                                )
                                            }
                                        >
                                            <PreviewIcon
                                                width={19}
                                                height={18}
                                                color={ColorConstants.brand_pimary_blue_1}
                                            />
                                            Preview
                                        </button>

                                        <button
                                            type="button"
                                            title={NewDRConstants.DOWNLOAD}
                                            className="inline-button"
                                            data-resource-id={ResourceIdLocators.DRL.DRLBtnDownloadFile}
                                            onClick={() =>
                                                onClickDownloadFile(uploadedFile.fileName, uploadedFile.fileGuid)
                                            }
                                        >
                                            <NewDownloadIcon
                                                width={17}
                                                height={17}
                                                color={ColorConstants.brand_pimary_blue_1}
                                            />
                                            {NewDRConstants.DOWNLOAD}
                                        </button>
                                        {!isDelegatedUser() && (
                                            <button
                                                disabled={status === DetailedStatus.COMPLETED}
                                                type="button"
                                                title={NewDRConstants.DELETE}
                                                className="inline-button"
                                                data-resource-id={ResourceIdLocators.DRL.DRLBtnDeleteDocument}
                                                onClick={() => onClickDeleteDocument(uploadedFile.fileId, true)}
                                            >
                                                <NewDeleteIcon
                                                    width={15}
                                                    height={15}
                                                    color={ColorConstants.brand_pimary_blue_1}
                                                />
                                                {NewDRConstants.DELETE}
                                            </button>
                                        )}
                                    </>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};
