import React from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import AttachmentListItem from "./AttachmentListItem";
import { IFileUploadModel } from "./AttachmentUploader";

interface IAttachmentListProps {
  attachments: IFileUploadModel[];
  isRemovingAttachment: boolean;
  onRemoveAttachment: (guid: string,fileId?:number) => void;
}

const AttachmentList: React.FC<IAttachmentListProps> = (props) => {
  return (
    <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
      <div className="attachments">
        {props.attachments &&
          props.attachments.map((item: IFileUploadModel, index: number) => {
            return (
              <AttachmentListItem
                key={index}
                attachment={item}
                onRemoveAttachment={props.onRemoveAttachment}
              />
            );
          })}
      </div>
      <Loader loading={props.isRemovingAttachment} />
    </LoadingOverlay>
  );
};

export default AttachmentList;
