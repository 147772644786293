import React from "react";
import Checkbox, { CheckBoxSize } from "../../../common/ui/Checkbox";
import { getFormattedSentDate } from "../../../../helper/DateHelperFunctions";
import { convertHtmlStringToText } from "../../../../helper/HelperFunctions";
import { IDraftMessageModel } from "../../../../core/viewModels/message/DraftMailMessage";

interface IMessageListItemProps {
    message: IDraftMessageModel;
    selectedMessageId: number;
    index: number;
    isChecked: boolean;
    onSelected: (message: IDraftMessageModel, e: any) => void;
    onClick: (message: IDraftMessageModel, e: any) => void;
}

const DraftMessageListItem: React.FC<IMessageListItemProps> = props => {
    const handleCheckboxOnClick = (e: any) => {
        e.stopPropagation();
    };

    const handleCheckboxOnChange = (e: any) => {
        e.stopPropagation();
        props.onSelected(props.message, e);
    };
    return (
        <div
            className={`card message-item ${
                props.selectedMessageId === props.message.draftedMessageId && "message-item-selected"
            }`}
            key={props.index}
            onClick={e => props.selectedMessageId !== props.message.draftedMessageId && props.onClick(props.message, e)}
        >
            <div className="card-body message-item-body">
                <div className="message-item-checkbox">
                    <Checkbox
                        id={`chkMessage_${props.message.draftedMessageId}`}
                        text=""
                        size={CheckBoxSize.sm}
                        checked={props.isChecked}
                        onChange={handleCheckboxOnChange}
                        onClick={handleCheckboxOnClick}
                    />
                </div>
                <div className="message-item-group">
                    <div className="row message-item-group-row">
                        <div className="message-item-header">
                            <div className="col-lg-7 col-xs-5 col-sm-3 col-md-5">
                                <span className="message-item-title text-ellipsis ellipsis">{`${props.message.firstName} ${props.message.lastName}`}</span>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-7 col-lg-5 text-end message-item-date-div">
                                <span className="message-item-datetime ">
                                    {getFormattedSentDate(props.message.createdDate)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="message-item-subject ellipsis" style={{ maxWidth: "250px", maxHeight: "20px" }}>
                        {props.message.subject}
                    </div>
                    <div className="message-item-messagebody ellipsis" style={{ maxWidth: "250px", maxHeight: "20px" }}>
                        {convertHtmlStringToText(props.message.message)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraftMessageListItem;
