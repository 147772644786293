export enum actionTypes {
  REQUEST_SECURITY_QUESTIONS = "request_security_questions",
  RECEIVED_SECURITY_QUESTIONS = "received_security_questions",
  REQUEST_COMPANY_SECURITY_QUESTIONS = "request_company_security_questions",
  RECEIVED_COMPANY_SECURITY_QUESTIONS = "received_company_security_questions",
  COMPLETED_SECURITY_QUESTIONS = "completed_security_questions",

  REQUEST_ADD_SECURITY_QUESTION = "request_add_security_question",
  COMPLETED_ADD_SECURITY_QUESTION = "completed_add_security_question",

  REQUEST_UPDATE_SECURITY_QUESTION = "request_update_security_question",
  COMPLETED_UPDATE_SECURITY_QUESTION = "completed_update_security_question",

  REQUEST_ADD_COMPANY_SECURITY_QUESTION = "request_add_company_security_question",
  COMPLETED_ADD_COMPANY_SECURITY_QUESTION = "completed_add_company_security_question",

  REQUEST_UPDATE_COMPANY_SECURITY_QUESTION = "request_update_company_security_question",
  COMPLETED_UPDATE_COMPANY_SECURITY_QUESTION = "completed_update_company_security_question",

  REQUEST_RECIPIENT_QUESTION_ANSWER = "request_recipient_question_answer",
  COMPLETED_RECIPIENT_QUESTION_ANSWER = "completed_recipient_question_answer",

}
