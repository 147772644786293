import axios from "axios";
import { logger } from "../oidcClient/authProvider";



export const addWalkMeScript = () => {
    axios.
    get(`${process.env.REACT_APP_API_URL}/api/WalkMe/GetWalkMeScript`)
    .then(function (response: any) {
        if(response.status == 200 ){
            if(!(window as any)._walkmeConfig?.smartLoad){
                if (response && response.data.length > 0) {
                    const scriptElement = document.createElement("script");
                    scriptElement.type = "text/javascript";
                    scriptElement.async = true;
                    scriptElement.text = response.data;
                    document.head.appendChild(scriptElement);
                    (window as any).loadWalkMe && (window as any).loadWalkMe();
                }
            }}
        })
        .catch(function (error: any) {
            logger.trackError(`Error while executing walk me script ${error}`);
        });
};
