import { Action, Reducer } from "redux";
import { produce } from "immer";

import { IRecipientModel } from "../core/viewModels/message/RecipientModel";
import * as ComposeActions from "../actionCreators/composeActionCreators";
import { actionTypes } from "../types/composeTypes";
import { IRetentionPeriod } from "../core/viewModels/company/settings/RetentionSettings";

export interface IComposeState {
  recipients: {
    isLoading: boolean;
    data: IRecipientModel[];
  };
  authRetentions: {
    isLoading: boolean;
    data: IRetentionPeriod[];
  };
  isRemovingAttachment: boolean;
  isSending: boolean;
  isAuthMethodLoading: boolean;
}

const initialState: IComposeState = {
  recipients: {
    isLoading: false,
    data: [],
  },
  authRetentions: {
    isLoading: false,
    data: [],
  },
  isRemovingAttachment: false,
  isSending: false,
  isAuthMethodLoading: false,
};

export const reducer: Reducer<IComposeState> = produce(
  (draft: IComposeState = initialState, incomingAction: Action) => {
    const action = incomingAction as ComposeActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_COMPOSE_RECIPIENTS:
        draft.recipients.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_COMPOSE_RECIPIENTS:
        draft.recipients.isLoading = false;
        draft.recipients.data = action.payload;
        return draft;

      case actionTypes.REQUEST_AUTH_RETENTIONS:
        draft.authRetentions.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_AUTH_RETENTIONS:
        draft.authRetentions.isLoading = false;
        draft.authRetentions.data = action.payload;
        return draft;

      case actionTypes.COMPLETED_AUTH_RETENTIONS:
        draft.authRetentions.isLoading = false;
        return draft;

      case actionTypes.REQUEST_SEND_MESSAGE:
        draft.isSending = true;
        return draft;

      case actionTypes.COMPLETED_SEND_MESSAGE:
      case actionTypes.RECEIVED_SEND_MESSAGE:
        draft.isSending = false;
        return draft;

      case actionTypes.REQUEST_REMOVE_ATTACHMENT:
        draft.isRemovingAttachment = true;
        return draft;

      case actionTypes.COMPLETED_REMOVE_ATTACHMENT:
        draft.isRemovingAttachment = false;
        return draft;

      case actionTypes.REQUEST_SET_DEFAULT_AUTHENTICATION:
        draft.isAuthMethodLoading = true;
        return draft;

      case actionTypes.COMPLETED_SET_DEFAULT_AUTHENTICATION:
        draft.isAuthMethodLoading = false;
        return draft;

      default:
        return draft;
    }
  }
);
