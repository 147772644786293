import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CompanySettingsName, DropOffUsersConstants } from "../../../../../helper/Constants";
import { ColorConstants } from "../../../../../assets/custom/colors";
import { RecipientIcon, SenderIcon } from "../../../../svg/AdminSettingsIconsCollection";
import { DropOffSender } from "./parts/Sender/DropOffSender";
import { DropOffRecipients } from "./parts/Recipients/DropOffRecipients";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { fetchDropOffSettings } from "../../../../../actionCreators/adminSettingsActionCreators";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";

export const DropOffUsers: React.FC = () => {
    const dispatch = useDispatch();
    const isSenderLoading = useSelector((state: ApplicationState) => state.dropOffState.dropOffSendersData.isLoading);

    useEffect(() => {
        dispatch(fetchDropOffSettings());
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.DropOff.DropOffUsers.PageTitle);
    }, []);
    const isRecipientLoading = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffSendersData.isLoading
    );
    const isSpamFilteringEnabled = useSelector(
        (state: ApplicationState) => state.adminSettingsState.dropOffSettings.isSpamFilteringRequired
    );
    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{DropOffUsersConstants.DROP_OFF_HELPER_TEXT}</span>
                </header>
                <div className="margin-top-10-px settings-accordian-container">
                    <Accordion flush className="settings-accordian">
                        <div className={!isSpamFilteringEnabled ? "isDisabled" : ""}>
                            <Accordion.Item eventKey="0" className="first-accordian">
                                <Accordion.Header
                                    style={{
                                        pointerEvents: `${!isSpamFilteringEnabled ? "none" : "auto"}`,
                                    }}
                                    id={CompanySettingsName.DropOffUsersSenders}
                                >
                                    <SenderIcon color={ColorConstants.light_blue} width={24} height={24} />

                                    <div className="accordion-title-info-container">
                                        <h4>{DropOffUsersConstants.ACCORDIAN_TITLE_SENDERS}</h4>
                                        <span className="sender_accordian_help_text">
                                            {DropOffUsersConstants.SENDER_ACCORDIAN_HELP_TEXT}
                                        </span>
                                    </div>
                                </Accordion.Header>
                                {isSpamFilteringEnabled && (
                                    <Accordion.Body>
                                        <DropOffSender />
                                    </Accordion.Body>
                                )}
                            </Accordion.Item>
                        </div>
                        <Accordion.Item className="second-accordian-item" eventKey="1">
                            <Accordion.Header id={CompanySettingsName.DropOffUsersRecipients}>
                                <RecipientIcon color={ColorConstants.light_blue} width={24} height={24} />
                                <div className="accordion-title-info-container">
                                    <h4>{DropOffUsersConstants.ACCORDIAN_TITLE_RECIPIENTS}</h4>
                                    <span>{DropOffUsersConstants.RECIPIENTS_ACCORDIAN_HELP_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <DropOffRecipients />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </>
            <Loader loading={isSenderLoading || isRecipientLoading} />
        </LoadingOverlay>
    );
};
