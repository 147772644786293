
import React, { useEffect, useRef, useState } from "react";
import { ArchiveFolderType, FolderType } from "../../../../core/common/enums";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { ISentMessageModel } from "../../../../core/viewModels/message/SentMessageModel";
import { FolderCloseIcon, FolderOpenIcon } from "../../../svg/FolderIcons";
import Tree from "../Tree/Tree";
import { getRetentionOptions } from "../../../../helper/HelperFunctions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

interface TreeNodeProps {
  node: IFolderModel,
  index: number,
  folderType: FolderType,
  selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[],
  selectedFolders?: IFolderModel[],
  isExpanded: boolean,
  destinationFolder: IFolderModel | undefined,
  handleFolderSelection: (folder: IFolderModel) => void
}

const TreeNode: React.FC<TreeNodeProps> = (props) => {

  const { node, isExpanded, handleFolderSelection, destinationFolder,
    selectedMessages, folderType, selectedFolders } = props;
  const [childVisible, setChildVisiblity] = useState(isExpanded);
  const hasChild = node && node.subFolders && node.subFolders.length > 0 ? true : false;
  const [expiryDays, setExpiryDays] = useState<string>("");
  const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);
  const folderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChildVisiblity(isExpanded);
  },
    [isExpanded]);

  useEffect(() => {
    if (destinationFolder && node.folderId === destinationFolder.folderId && folderRef.current) {
      folderRef.current.scrollIntoView({ behavior: "smooth" });
      setChildVisiblity(true);
    }
    if (destinationFolder && node.folderId === destinationFolder.parentId && !childVisible) {
      setChildVisiblity(true);
    }
  }, [destinationFolder, node.folderId]);

  useEffect(() => {
    if (node && node.expiresDays && retentionData?.retentionPeriods) {
      const label = getRetentionOptions(retentionData?.retentionPeriods)?.find(retention => retention.value.toString() === node.expiresDays.toString())?.label || "";
      setExpiryDays(label.toString());
    }
  }, [node, retentionData]);

  const handleFolderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, node: IFolderModel) => {
    e.stopPropagation();
    handleFolderSelection(node);
  };

  const checkHighlightOption = () => {

    // For Sent Folder id is of My Files so dont grey out
    if (folderType == FolderType.SENT) {
      return "";
    }

    // Highlight selected folder
    if (destinationFolder?.folderId === node.folderId) {
      return "highlight-list-item";
    }

    // Grey out the current selected folders while moving messages
    if ((selectedMessages && selectedMessages[0]?.folderId == node.folderId)) {
      return "greyed-out-item";
    }

    if (selectedFolders && !selectedMessages) {
      // Grey out folders with messages while moving folders
      if (node.folderType == ArchiveFolderType.MESSAGE) {
        return "greyed-out-item";
      }

      // Grey out sub folders of selected folder while moving folders
      if (selectedFolders.some(folder => folder.path && node.path?.includes(folder.path))) {
        return "greyed-out-item";
      }
    }
    return "";
  };



  return (
    <li className="d-tree-node border-0">
      <div
        className={`d-flex folder-list-item ${checkHighlightOption()}`}
        ref={folderRef}
        onClick={(e) => { handleFolderClick(e, node); }}>
        {hasChild && (
          <div
            onClick={(e) => { e.stopPropagation(); setChildVisiblity((v) => !v); }}
            className={`d-inline d-tree-toggler ${childVisible ? "active" : ""
              }`}
          >
            {childVisible ? <FolderOpenIcon
              width={10}
              height={10}
            ></FolderOpenIcon> : <FolderCloseIcon
              width={10}
              height={10}
            ></FolderCloseIcon>}
          </div>
        )}

        <div className={`d-tree-head ${hasChild ? "marL10" : "marL23"}`}>
          {node.folderName} {expiryDays && `(${expiryDays})`}
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <Tree
              isMovingFolder={false}
              selectedFolders={selectedFolders}
              folderType={folderType}
              isExpanded={isExpanded}
              selectedMessages={selectedMessages}
              handleFolderSelection={handleFolderSelection}
              destinationFolder={destinationFolder || node}
              data={node.subFolders || []} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default TreeNode;
