import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { requestDropOffLinks } from "../../../actionCreators/dropOffActionCreators";
import { DropOffMenuModalConstants } from "../../../helper/Constants";
import { copyToClipboard } from "../../../helper/HelperFunctions";
import { ApplicationState } from "../../../store";
import { actionTypes as dropOffActions } from "../../../types/dropOffTypes";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import CustomToggleSwitch from "../../common/ui/switch/CustomToggleSwitch";
import { requestDropOffSettings, updateDropOffSettings } from "../../../actionCreators/userSettingsActionCreators";
import { IDropOffSettings } from "../../../core/viewModels/user/UserViewModel";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { CopyIcon } from "../../svg/ToolbarIcons";

export const DropoffMenuModal: React.FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const dispatch = useDispatch();
    const dropOffState = useSelector((state: ApplicationState) => state.dropOffState);
    const userState = useSelector((state: ApplicationState) => state.userSettingsState);

    useEffect(() => {
        dispatch(requestDropOffLinks());
        dispatch(requestDropOffSettings());
        logger.trackPageView(appInsightsData.DropOFf.PageTitle);

        return () => {
            dispatch({ type: dropOffActions.RESET_DROP_OFF_LINKS });
        };
    }, []);

    const handleDropOffNotificationToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const data: IDropOffSettings = { ...userState.dropOffSettings, isMailNotificationToSend: value };
        dispatch(updateDropOffSettings(data));
    };

    return (
        <CustomModal
            className="dropoff-modal-container"
            show={show}
            hideFooter={true}
            onHide={() => {
                onHide();
            }}
            onSubmit={() => {
                //No submit action
            }}
            title={DropOffMenuModalConstants.MODAL_TITLE}
        >
            <div>
                <LoadingOverlay>
                    {dropOffState.dropOffLinks.companyDropOffLink && (
                        <div className="dropoff-modal-links-container">
                            <span>{DropOffMenuModalConstants.COMPANY_DROPOFF_TEXT}</span>
                            <a
                                href={dropOffState.dropOffLinks.companyDropOffLink}
                                onClick={() => {
                                    logger.trackEvent({
                                        name: `${appInsightsData.DropOFf.PageTitle} - ${appInsightsData.DropOFf.Operation.CompanyLinkClicked}`,
                                        properties: {
                                            page: appInsightsData.DropOFf.PageTitle,
                                        },
                                    });
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {dropOffState.dropOffLinks.companyDropOffLink}
                            </a>
                            <Button
                                className="btn-copy"
                                title="Copy"
                                variant="link"
                                onClick={() => {
                                    logger.trackEvent({
                                        name: `${appInsightsData.DropOFf.PageTitle} - ${appInsightsData.DropOFf.Operation.CompanyLinkCopied}`,
                                        properties: {
                                            page: appInsightsData.DropOFf.PageTitle,
                                        },
                                    });
                                    copyToClipboard(dropOffState.dropOffLinks.companyDropOffLink);
                                }}
                            >
                                <CopyIcon/>
                            </Button>
                        </div>
                    )}
                    {dropOffState.dropOffLinks.personalDropOffLink && (
                        <div className="dropoff-modal-links-container">
                            <span>{DropOffMenuModalConstants.PERSONAL_DROPOFF_TEXT}</span>
                            <a
                                href={dropOffState.dropOffLinks.personalDropOffLink}
                                onClick={() => {
                                    logger.trackEvent({
                                        name: `${appInsightsData.DropOFf.PageTitle} - ${appInsightsData.DropOFf.Operation.PersonalLinkClicked}`,
                                        properties: {
                                            page: appInsightsData.DropOFf.PageTitle,
                                        },
                                    });
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {dropOffState.dropOffLinks.personalDropOffLink}
                            </a>
                            <Button
                                className="btn-copy"
                                title="Copy"
                                variant="link"
                                onClick={() => {
                                    logger.trackEvent({
                                        name: `${appInsightsData.DropOFf.PageTitle} - ${appInsightsData.DropOFf.Operation.PersonalLinkCopied}`,
                                        properties: {
                                            page: appInsightsData.DropOFf.PageTitle,
                                        },
                                    });
                                    copyToClipboard(dropOffState.dropOffLinks.personalDropOffLink);
                                }}
                            >
                                <CopyIcon/>
                            </Button>
                        </div>
                    )}

                    <div className="prompt-control mt-1">
                        <CustomToggleSwitch
                            label={DropOffMenuModalConstants.RECEIVE_NOTIFICATION_TOGGLE_INFO}
                            name=""
                            handleChange={handleDropOffNotificationToggle}
                            switched={userState.dropOffSettings.isMailNotificationToSend}
                        />
                        <i
                            className="fas fa-exclamation-circle margin-left-5-px"
                            title={
                                userState.dropOffSettings.isMailNotificationToSend
                                    ? DropOffMenuModalConstants.RECEIVE_NOTIFICATION_TOGGLE_ENABLE_TOOLTIP
                                    : DropOffMenuModalConstants.RECEIVE_NOTIFICATION_TOGGLE_DISABLE_TOOLTIP
                            }
                        />
                    </div>

                    <Loader loading={dropOffState.isLoading || userState.isLoading} />
                </LoadingOverlay>
            </div>
        </CustomModal>
    );
};
