import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "../store";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import {
  CompleteDropOffLinks, ReceiveDropOffLinks, RequestDropOffLinks, ResetDropOffLinks, RequestDropOffSenders, ReceiveDropOffSenders, CompleteDropOffSenders,
  RequestDropOffRecipients, ReceiveDropOffRecipients, CompleteDropOffRecipients, RequestUpdateDropOffSender, CompleteUpdateDropOffSender, RequestUpdateDropOffRecipient, CompleteUpdateDropOffRecipient, RequestDeleteDropOffSender, CompleteDeleteDropOffSender
} from "../actions/dropOffActions";
import { actionTypes as dropOffActions } from "../types/dropOffTypes";
import { IDropOffRecipient, IDropOffSender } from "../core/viewModels/company/settings/DropOffSettings";
import { UpdateDropOffRecipientType } from "../core/common/enums";

export type DispatchActions =
  | RequestDropOffLinks
  | ReceiveDropOffLinks
  | CompleteDropOffLinks
  | ResetDropOffLinks
  | RequestDropOffSenders
  | ReceiveDropOffSenders
  | CompleteDropOffSenders
  | RequestDropOffRecipients
  | ReceiveDropOffRecipients
  | CompleteDropOffRecipients
  | RequestUpdateDropOffSender
  | CompleteUpdateDropOffSender
  | RequestUpdateDropOffRecipient
  | CompleteUpdateDropOffRecipient
  | RequestDeleteDropOffSender
  | CompleteDeleteDropOffSender
 
export type KnownActions = DispatchActions | NotificationAction;

export const requestDropOffLinks =
  (): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: dropOffActions.REQUEST_DROP_OFF_LINKS,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/DropOff/GetDropOffLinks`)
        .then((response: any) => {
          const data = response.data;
          dispatch({
            type: dropOffActions.RECEIVE_DROP_OFF_LINKS,
            payload: data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
         
        }).finally(() => {
          dispatch({
            type: dropOffActions.COMPLETE_DROP_OFF_LINKS,
          });
        });
    };

export const requestDropOffSendersList =
  (resourceId:string): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: dropOffActions.REQUEST_DROP_OFF_SENDERS,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/DropOff/GetDropOffSenders`,{
          headers:{
            "X-Resource-Id": resourceId ?? ""
          }
        })
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: dropOffActions.RECEIVE_DROP_OFF_SENDERS,
            payload: data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
         
        }).finally(() => {
          dispatch({
            type: dropOffActions.COMPLETE_DROP_OFF_SENDERS,
          });
        });
    };

export const requestDropOffRecipientsList =
  (): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: dropOffActions.REQUEST_DROP_OFF_RECIPIENTS,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/DropOff/GetDropOffRecipients`)
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: dropOffActions.RECEIVE_DROP_OFF_RECIPIENTS,
            payload: data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
         
        }).finally(() => {
          dispatch({
            type: dropOffActions.COMPLETE_DROP_OFF_RECIPIENTS,
          });
        });
    };


    export const updateDropOffSender =
    (
      senderPayload: IDropOffSender[],
      resourceId: string,
      callback?: any
    ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: dropOffActions.REQUEST_UPDATE_DROP_OFF_SENDER,
      });
  
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/DropOff/UpdateDropOffSenders`,
         senderPayload,{headers:{
          "X-Resource-Id": resourceId ?? ""
         }}
        )
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: dropOffActions.COMPLETE_UPDATE_DROP_OFF_SENDER,
          });
          if (callback) {
            callback(data);
          }
        })
        .catch((error) => {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.response.data.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: dropOffActions.COMPLETE_UPDATE_DROP_OFF_SENDER,
          });
        })
        ;
    };

    export const updateDropOffRecipient = 
      (recipientPayload: IDropOffRecipient[], 
        disableRecipients: UpdateDropOffRecipientType, 
        resourceId: string,
        callback?: any): AppThunkAction<KnownActions> => 
       dispatch => {
          dispatch({
              type: dropOffActions.REQUEST_UPDATE_DROP_OFF_RECIPIENT,
          });
  
          axios
          .put(
            `${process.env.REACT_APP_API_URL}/api/DropOff/UpdateDropOffRecipients`, recipientPayload,{
              params: {
                disableRecipients,
              },
              headers:{
                "X-Resource-Id":resourceId ?? ""
              }
          })
          .then((response: AxiosResponse) => {
                  const data = response.data;
                  dispatch({
                      type: dropOffActions.COMPLETE_UPDATE_DROP_OFF_RECIPIENT,
                  });
                  if (callback) {
                      callback(data);
                  }
              })
              .catch(error => {
                  dispatch({
                      type: notifactionAction.NOTIFICATION,
                      statusMessage: error.response.data.message,
                      statusType: StatusType.Error,
                  });
              })
              .finally(() => {
                  dispatch({
                      type: dropOffActions.COMPLETE_UPDATE_DROP_OFF_RECIPIENT,
                  });
              });
      };

    export const deleteDropOffSender =
    (
      senderIds: number[],
      resourceId: string,
      callback?: any
    ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: dropOffActions.REQUEST_DELETE_DROP_OFF_SENDER,
      });
  
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/DropOff/DeleteDropOffSenders`,
        {  data:senderIds,headers:{"X-Resource-Id": resourceId ?? ""}},
        )
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: dropOffActions.COMPLETE_DELETE_DROP_OFF_SENDER,
          });
          if (callback) {
            callback(data,senderIds);
          }
        })
        .catch((error) => {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.response.data.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: dropOffActions.COMPLETE_DELETE_DROP_OFF_SENDER,
          });
        })
        ;
    };