import produce from "immer";
import { Reducer, Action } from "redux";
import { DispatchActions } from "../actionCreators/passwordPolicySettingActionCreator";
import { IPasswordPolicySettings, initialPasswordPolicySetting } from "../core/viewModels/company/CompanySettingsViewModel";
import { actionTypes as templateActions } from "../types/passwordPolicyTypes";

export const reducer: Reducer<IPasswordPolicySettings> = produce(
    (draft: IPasswordPolicySettings = initialPasswordPolicySetting, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case templateActions.REQUEST_PASSWORD_POLICY_SETTINGS:
                return draft;
            case templateActions.RECEIVE_PASSWORD_POLICY_SETTINGS:
                return action.settings;
            default:
                return draft;
        }
    }
);