import React from "react";
import Pagination from "react-js-pagination";
import PagingInfo from "./PagingInfo";

interface IDefaultPaginationProps {
  pageSize: number;
  currentPage: number;
  totalRecords: number;
  onPageChange: (pageNo: number) => void;
}

const DefaultPagination: React.FC<IDefaultPaginationProps> = (props) => {
  return (
    <div className="message-pagination">
      <PagingInfo
        pageSize={props.pageSize}
        currentPage={props.currentPage}
        totalRecords={props.totalRecords}
      />
      <Pagination
        hideFirstLastPages
        prevPageText={<i className="fas fa-chevron-left"></i>}
        nextPageText={<i className="fas fa-chevron-right"></i>}
        pageRangeDisplayed={-1}
        activePage={props.currentPage}
        itemsCountPerPage={props.pageSize}
        totalItemsCount={props.totalRecords}
        onChange={props.onPageChange}
        innerClass="pagination message-pagination-arrows"
        disabledClass="disable-pagination"
      />
    </div>
  );
};

export default DefaultPagination;
