import { IOTPState } from "../core/viewModels/profile/ProfileViewModel";
import { actionTypes } from "../types/otpTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import axios from "axios";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { OTPVerify } from "../helper/Constants";
import { logger } from "../oidcClient/authProvider";
import { appInsightsData } from "../helper/AppInsightsData";

export interface RequestOneTimePasswordAction {
  type: actionTypes.REQUEST_ONETIMEPASSWORD;
}

export interface ReceiveOneTimePasswordAction {
  type: actionTypes.RECEIVE_ONETIMEPASSWORD;
}

export interface RequestMFAOTPLengthAction {
  type: actionTypes.REQUEST_MFA_ONETIMEPASSWORD_LENGTH;
}

export interface ReceiveMFAOTPLengthAction {
  type: actionTypes.RECEIVE_MFA_ONETIMEPASSWORD_LENGTH;
  otpState: IOTPState;
}

export interface RequestOneTimePasswordValidateAction {
  type: actionTypes.REQUEST_VALIDATE_ONETIMEPASSWORD;
}

export interface ValidateOneTimePasswordCompletedAction {
  type: actionTypes.COMPLETED_VALIDATE_ONETIMEPASSWORD;
  otpState: IOTPState;
}

export type DispatchActions =
  | ReceiveOneTimePasswordAction
  | RequestOneTimePasswordAction
  | RequestOneTimePasswordValidateAction
  | ValidateOneTimePasswordCompletedAction
  | RequestMFAOTPLengthAction
  | ReceiveMFAOTPLengthAction;

export type KnownActions = DispatchActions | NotificationAction;

export const saveOneTimePassword =
  (mobileNumber: string, countryCode: string): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_ONETIMEPASSWORD,
    });
    logger.trackEvent({
      name: `${appInsightsData.MyAccount.ProfilePageTitle} - ${appInsightsData.MyAccount.Operation.VerifyMobileNumberClick}`,
      properties: {
          page: appInsightsData.MyAccount.ProfilePageTitle
      }
  });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_SUITE}/api/otp/send-otp`, {mobileNumber: mobileNumber, countryCode: countryCode}
      )
      .then(() => {
        dispatch({
          type: actionTypes.RECEIVE_ONETIMEPASSWORD,
        });
      })
      .catch(function (error) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: error.statusText,
          statusType: StatusType.Error,
        });
      });
  };

export const requestMFAOTPLength =
  (): AppThunkAction<KnownActions> => (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_MFA_ONETIMEPASSWORD_LENGTH });

    axios
      .get(
        `${process.env.REACT_APP_API_URL_SUITE}/api/helper/mfa-otp-length`
      )
      .then((response: any) => {
        const data = response.data;
        dispatch({
          type: actionTypes.RECEIVE_MFA_ONETIMEPASSWORD_LENGTH,
          otpState: { ...data, mfaOTPLength: data },
        });
      })
      .catch(function (error) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: error.statusText,
          statusType: StatusType.Error,
        });
      });
  };

export const validateOTP =
  (
    otp: string,
    mobileNumber: string,
    callback: (isOTPValid: boolean) => void
  ): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_VALIDATE_ONETIMEPASSWORD });

    axios
      .post(
        `${process.env.REACT_APP_API_URL_SUITE}/api/otp/verify-otp`, {mobileNumber, otp}
      )
      .then((response: any) => {
        const data = response.data;
        dispatch({
          type: actionTypes.COMPLETED_VALIDATE_ONETIMEPASSWORD,
          otpState: { ...data, isOTPValid: data },
        });
        callback(data);
        if (data) {
          dispatch({
            type: notificationTypes.NOTIFICATION,
            statusMessage: OTPVerify.validOTP,
            statusType: StatusType.Success,
          });
        } else {
          dispatch({
            type: notificationTypes.NOTIFICATION,
            statusMessage: OTPVerify.InvalidOTP,
            statusType: StatusType.Warning,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: error.statusText,
          statusType: StatusType.Error,
        });
      });
  };
