import React from "react";
import { Accordion } from "react-bootstrap";
import Select from "react-select";
import { ColorConstants } from "../../../../../../../assets/custom/colors";
import { DropOffConfigConstants } from "../../../../../../../helper/Constants";
import { AdminSettingsSideMenuIcon } from "../../../../../../svg/AdminSettingsIconsCollection";
import { IgroupedOptionsInterface } from "../../DropOffConfig";
import { components } from "react-select";

interface IDropOffConfigAccrodianProps {
    handleFileSubmission: () => void;
    handleFileTypeChange: (value: any, data: any) => void;
    fileTypesGroupedOptions: IgroupedOptionsInterface[];
    restrictedFileTypes: any[];
}

export const DropOffConfigAccrodian: React.FC<IDropOffConfigAccrodianProps> = ({
    handleFileSubmission,
    handleFileTypeChange,
    fileTypesGroupedOptions,
    restrictedFileTypes,
}) => {
    const GroupHeading = (props: any) => (
        <div style={{ backgroundColor: props.data.options[0].color }} className="dropdown-group-heading">
            <components.GroupHeading {...props} />
        </div>
    ); 
    return (
        <div className="margin-top-15-px">
            <Accordion flush className="settings-accordian">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <AdminSettingsSideMenuIcon
                            icon={"DropOffConfigFileTypeRestriction"}
                            color={ColorConstants.light_blue}
                            width={24}
                            height={24}
                        />
                        <div className="accordion-title-info-container">
                            <h4>{DropOffConfigConstants.FILE_TYPE_RESTRICTION_TITLE}</h4>
                            <span>{DropOffConfigConstants.FILE_TYPE_RESTRICTION_INFO}</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <h5 className="heading-black-md">{DropOffConfigConstants.FILE_TYPE_RESTRICTION_HELPER_TEXT}</h5>
                            <div className="select-container drop-off-config-dropdown">
                                <Select
                                    options={fileTypesGroupedOptions}
                                    isMulti
                                    menuPlacement="bottom"
                                    placeholder="select the file extenstions..."
                                    components={{
                                        GroupHeading,
                                        DropdownIndicator: null,
                                    }}
                                    value={restrictedFileTypes}
                                    onChange={handleFileTypeChange}
                                />
                                <button className="button-primary-blue-2" onClick={handleFileSubmission}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};
