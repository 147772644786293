import React, { Dispatch, useEffect } from "react";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import { getFileExtension } from "../../../helper/Validations";
import { ApplicationState } from "../../../store";
import { FileFormatIcon } from "../../svg/FileIconCollection";
import { ArchiveConstants, DateFormatConstants } from "../../../helper/Constants";
import { actionTypes } from "../../../types/folderTypes";
import { MessageSource } from "../../../core/common/enums";
import DownloadModal from "./DownloadModal";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import { IMessageFileViewModel } from "../../../core/viewModels/message/MessageFileViewModel";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { formatBytes } from "../../../helper/HelperFunctions";

interface IMessageFileViewProps {
    downloadSingleAttachment?: (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => void;
    setShowDownloadModal: Dispatch<React.SetStateAction<boolean>>;
    showDownloadModal: boolean;
    selectedFiles: IMessageFileViewModel[];
    setSelectedFiles: Dispatch<React.SetStateAction<IMessageFileViewModel[]>>;
    currentFolder: IFolderModel;
    sortBy?: any;
}
const MessageFileView: React.FC<IMessageFileViewProps> = props => {
    const {
        downloadSingleAttachment,
        showDownloadModal,
        setShowDownloadModal,
        selectedFiles,
        setSelectedFiles,
        currentFolder,
    } = props;
    const dispatch = useDispatch();
    const { isLoading, fileData } = useSelector((state: ApplicationState) => state.folderState.fileViewData);

    useEffect(() => {
        return () => {
            // clean up code
            dispatch({
                type: actionTypes.RESET_MESSAGE_FILE_VIEW_DATA,
            });
        };
    }, []);

    const fileNameFormatter = (cell: string, row: any): JSX.Element => {
        return (
            <div
                className="file-icon-name-container"
                title={row.name}
                data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFile}
                onClick={() => {
                    downloadSingleAttachment &&
                        downloadSingleAttachment(
                            row.fileId,
                            row.fileGuid,
                            row.receivedMessageId,
                            row.name,
                            row.senderEmail,
                            row.receivedMessageGuid,
                            row.messageSource
                        );
                }}
            >
                <FileFormatIcon extension={getFileExtension(cell)} width={40} height={40}></FileFormatIcon>
                <p className="overflowText">{row.name}</p>
            </div>
        );
    };

    const subjectFormatter = (cell: string): JSX.Element => {
        return (
            <span className="subject-cell overflowText" title={cell}>
                {cell}
            </span>
        );
    };

    const sentDateFormatter = (cell: Date): JSX.Element => {
        return (
            <span className="sent-date-cell">
                {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
            </span>
        );
    };

    const downloadDateFormatter = (cell: Date): JSX.Element => {
        return (
            <span className="sent-date-cell">
                {cell != null ? getFormattedLocalDateTime(cell, DateFormatConstants.REPORTS_DATE_FORMAT) : "NA"}
            </span>
        );
    };

    const senderNameFormatter = (cell: string, row: any): JSX.Element => {
        return (
            <span
                title={`${row.senderFirstName} ${row.senderLastName}`}
                className="sender-name-cell overflowText"
            >{`${row.senderFirstName} ${row.senderLastName}`}</span>
        );
    };

    const fileSizeFormatter = (cell: string): JSX.Element => {
        return <span className="filesize-cell">{formatBytes(Number(cell))}</span>;
    };

    const columns: ColumnDescription[] = [
        {
            text: "File Name",
            dataField: "name",
            formatter: fileNameFormatter,
            sort: true,
        },
        {
            text: "Subject",
            dataField: "subject",
            formatter: subjectFormatter,
            sort: true,
        },
        {
            text: "Last Download",
            dataField: "downloadDate",
            formatter: downloadDateFormatter,
            sort: true,
        },
        {
            text: "Size",
            dataField: "size",
            formatter: fileSizeFormatter,
            sort: true,
        },
        {
            text: "Sender",
            dataField: "senderFirstName",
            formatter: senderNameFormatter,
            sort: true,
        },
        {
            text: "Sent/Received Date",
            dataField: "sentDate",
            formatter: sentDateFormatter,
            sort: true,
        },
    ];

    const setNoContent = () => {
        if (!isLoading && fileData.length === 0) {
            return ArchiveConstants.MessageView.NO_DATA_MESSAGE;
        }
    };

    const handleOnSelect = (row: IMessageFileViewModel, isSelect: boolean) => {
        let selectedFilesCopy = [...selectedFiles];
        if (isSelect) {
            selectedFilesCopy.push(row);
        } else {
            selectedFilesCopy = selectedFilesCopy.filter(file => file.fileId != row.fileId);
        }
        setSelectedFiles(selectedFilesCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IMessageFileViewModel[]) => {
        if (isSelect) {
            setSelectedFiles(rows);
        } else {
            setSelectedFiles([]);
        }
    };

    const selectRow: SelectRowProps<any> = {
        mode: "checkbox",
        clickToSelect: true,
        nonSelectable: [],
        selected: selectedFiles.map(file => file.fileId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedFiles.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={""}
            />
        ),
    };

    return (
        <LoadingOverlay className="file-view-loader">
            <div className="archive-table-container">
                <BootstrapTable
                    classes="settings-table"
                    keyField="fileId"
                    columns={columns}
                    data={fileData}
                    noDataIndication={setNoContent()}
                    bordered={false}
                    selectRow={selectRow}
                    sort={props.sortBy}
                />
            </div>
            <DownloadModal
                show={showDownloadModal}
                onHide={() => setShowDownloadModal(false)}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                folder={currentFolder}
                resourceId={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFiles}
            />

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default MessageFileView;
