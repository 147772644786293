import React, { FC, useEffect, useRef, useState } from "react";
import BootstrapTable, { TableChangeHandler, ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Overlay, Popover, } from "react-bootstrap";
import { SendLinkIcon } from "../../../svg/ToolbarIcons";
import { ColorConstants } from "../../../../assets/custom/colors";
import { CloseIcon } from "../../../svg/IconCollection";
import { FilterType, LinkStatus } from "../../../../core/common/enums";
import { DEFAULT_PAGE_SIZE, ReportConstants } from "../../../../helper/Constants";
import { initialSentItemsRequestObject, IReportItemsHistoryRequestModel, ISentItemHistoryModel } from "../../../../core/viewModels/report/SentItemsHistoryModel";
import { AppNotifier } from "../../../../helper/AppNotifier";
import { useDispatch, useSelector } from "react-redux";
import { cancelTransfer, resendAccessLinkToSingleRecipient } from "../../../../actionCreators/sentMessageActionCreators";
import { ApplicationState } from "../../../../store";
import { IFilterModel } from "../../../../core/viewModels/report/reportViewModel";
import { ResourceIdLocators } from "../../../../helper/ResourceIdLocators";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";
import { IDocumentRequestHistoryModel } from "../../../../core/viewModels/report/documentRequestHistoryModel";

interface IReportTable {
	data: ISentItemHistoryModel[] | IDocumentRequestHistoryModel[],
	count?: number,
	isClickable?: boolean,
	columns: ColumnDescription[],
	filterType: FilterType,
	selectedFilter: IFilterModel,
	currentPageNumber: number,
	fetchReportsData: (payload?: IReportItemsHistoryRequestModel, callback?: () => void) => void,
	handleTableSorting?: (sortField: string, sortOrder: string) => void,
	setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const ReportTable: FC<IReportTable> = (props) => {
	const { data, columns, filterType, isClickable, count, selectedFilter, currentPageNumber, setCurrentPageNumber,
		fetchReportsData, handleTableSorting } = props;
	const resendLinkDivRef = useRef(null);
	const [target, setTarget] = useState();
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [showResendLinkPopover, setResendLinkPopover] = useState(false);
	const [targetResendDiv, setTargetResendDiv] = useState(null);
	const [sortOrder, setSortOrder] = useState("desc");
	const [sortColumn, setSortColumn] = useState("");
	const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
	const [hightlightedRow, setHighlightedRow] = useState<number>(-1);
	const { messageHistorySettings } = useSelector((state: ApplicationState) => state.adminSettingsState);
	const isContextOptionsEnabled = filterType === FilterType.SIH ? true : false;
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentPageNumber) {
			getData();
		}
	}, [currentPageNumber]);

	useEffect(() => {
		if (showContextMenu && filterType === FilterType.SIH) {
			setHighlightedRow(selectedRowIndex);
		} else {
			setHighlightedRow(-1);
		}
	}, [showContextMenu]);

	const getData = () => {
		const payload = {
			...initialSentItemsRequestObject,
			pageNumber: currentPageNumber,
			sortColumn: sortColumn,
			sortOrder: sortOrder
		};

		// For non saved filter we need to pass filter condition
		if (selectedFilter.id === 0) {
			fetchReportsData({
				...payload,
				filterId: 0,
				filterParameters: selectedFilter.filterCondition
			});
		} else {
			fetchReportsData({
				...payload,
			});
		}
	};

	const handleTableChange: TableChangeHandler<any> | undefined = (type, { sortField, sortOrder, page }) => {
		// Remote sorting will be handled here
		if (type === "sort") {
			setSortColumn(sortField);
			setSortOrder(sortOrder);
			handleTableSorting && handleTableSorting(sortField, sortOrder);
		}
		setCurrentPageNumber(page);
	};

	const rowEvents = {
		onContextMenu: (e: any, row: any, rowIndex: number) => { //any will be replaced by dataModel here
			e.preventDefault();
			setTarget(e.target);
			setSelectedRowIndex(rowIndex);
			setShowContextMenu(!showContextMenu);
		},

	};

	const checkIsActive = (index: number) => {
		const item=data[index] as ISentItemHistoryModel;
		const isActive = item && item.linkStatus === LinkStatus.Active;
		if (isActive && messageHistorySettings.sentItemsSettings?.isUserAllowedToResendDocumentAccessLinks) {
			return true;
		}
		return false;
	};

	const handleResendLink = (email: string, messageId: number) => {
		// resend access link api call
		dispatch(resendAccessLinkToSingleRecipient(messageId, email, ResourceIdLocators.SIH.SIHBtnResendAcsLink, (status: number) => {
			if (status) {
				AppNotifier.Success(ReportConstants.RESEND_LINK_SUCCESS);
			}
		}));
		setShowContextMenu(false);
	};

	const resendLinkPopOver = () => {
		const item=data[selectedRowIndex] as ISentItemHistoryModel;
		const recipients = item && item?.recipients?.split(",");
		const messageId = item && item.sentMessageId;
		return (
			<Popover id="resend-link-popover">
				<Popover.Body className="reports-popover-body">
					<ul>
						{recipients && recipients.map((item: string) => <li
							key={item}
							onClick={() => handleResendLink(item, messageId)}
						>
							{item}
						</li>)}
					</ul>
				</Popover.Body>
			</Popover>
		);
	};

	const handleMouseEnterOnLink = (e: any) => { // to replace e with valid event (currently cannot find the event type)
		if (checkIsActive(selectedRowIndex)) {
			setTargetResendDiv(e.target);
			setResendLinkPopover(!showResendLinkPopover);
		}
	};

	const handleCancelTransfer = () => {
		const item=data[selectedRowIndex] as ISentItemHistoryModel;
		const messageId = item && item.sentMessageId;
		const isActive = item && item.linkStatus === LinkStatus.Active;

		if (isActive) {
			dispatch(cancelTransfer([messageId], ResourceIdLocators.SIH.SIHBtnCancelTransfer, (status: number) => {
				if (status === 200) {
					AppNotifier.Success(ReportConstants.CANCEL_MESSAGES_SUCCESS);
					getData();
				}
			}));
			setShowContextMenu(false);
		}
	};

	const ContextMenuPopOver = () => {
		const isResendAccessLinkEnabled = checkIsActive(selectedRowIndex);
		const item=data[selectedRowIndex] as ISentItemHistoryModel;
		const isCancelTransferEnabled = item && item.linkStatus === LinkStatus.Active;
		return (
			<Popover id="context-menu-popover">
				<Popover.Body className="reports-popover-body">
					<div ref={resendLinkDivRef}
						className={`menu-item-container ${isResendAccessLinkEnabled ? "" : "disabled "}`}
						data-resource-id={ResourceIdLocators.SIH.SIHBtnResendAcsLink}
						data-test-auto={AutomationIdConstants.commonSihRih.ReportTableRCPopup}
						data-resource-events="mouseover"
						onMouseOver={handleMouseEnterOnLink}
					>
						<SendLinkIcon
							width={15}
							height={15}
							color={isResendAccessLinkEnabled ? ColorConstants.light_blue : ColorConstants.sidemenu_light_grey}
						/>
						<span className="menu-option-text">{ReportConstants.CONTEXT_MENU_RESEND_LINK}</span>
						<span className="right-arrow">{">"}</span>
					</div>
					<div
						className={`menu-item-container ${isCancelTransferEnabled ? "" : "disabled "}`}
						data-resource-id={ResourceIdLocators.SIH.SIHBtnCancelTransfer}
						data-test-auto={AutomationIdConstants.commonSihRih.ReportTableRCPopup}

						onClick={handleCancelTransfer}
					>
						<CloseIcon
							width={15}
							height={12}
							color={isCancelTransferEnabled ? ColorConstants.light_blue : ColorConstants.sidemenu_light_grey}
						/>
						<span className="menu-option-text">{ReportConstants.CONTEXT_MENU_CANCEL_TRANSFER}</span>
					</div>
					{targetResendDiv && <Overlay
						target={targetResendDiv}
						show={showResendLinkPopover}
						container={resendLinkDivRef}
						placement="right-end"
						onHide={() => setResendLinkPopover(false)}
						rootClose
					>
						{resendLinkPopOver()}
					</Overlay>}
				</Popover.Body>
			</Popover>
		);
	};

	const setNoContent = () => {
		if (data.length === 0) {
			return ReportConstants.NO_DATA_MESSAGE;
		}
	};

	const customTotal = (from: number, to: number) => (
    <span className="total-count" data-test-auto={AutomationIdConstants.commonSihRih.ReportTableTotCount}>
      Showing <b>{from}</b> to <b>{to}</b> of <b>{count}</b> entries
    </span>
  );
	
	const options = {
		totalSize: count ?? 0,
		sizePerPage: DEFAULT_PAGE_SIZE,
		page: currentPageNumber,
		showTotal: true,
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		hideSizePerPage: true,
		paginationTotalRenderer: customTotal,
	};

	// the below params typing is ANY because the bootstrap table defined this callback like that
	const trClassFormat = (row: any, rowIndex: any) => rowIndex === hightlightedRow ? "tr-hightlight" : "";

	return <div className={`report-table-container ${isClickable ? "clickable" : ""}`}>
		<BootstrapTable
			id="reportTable"
			classes="report-table"
			keyField="sentMessageId"
			columns={columns}
			data={data}
			rowEvents={rowEvents}
			bordered={false}
			noDataIndication={setNoContent()}
			pagination={paginationFactory(options)}
			remote
			onTableChange={handleTableChange}
			rowClasses={trClassFormat}
		/>;
		{isContextOptionsEnabled && target && <Overlay
			target={target}
			show={showContextMenu}
			placement="right-end"
			onHide={() => setShowContextMenu(false)}
			rootClose
		>
			{ContextMenuPopOver()}
		</Overlay>}
	</div>;
};

export default ReportTable;