import axios, { AxiosResponse } from "axios";
import {
    CompleteSupportContactList,
    CompleteAdminSettings,
    CompleteUpdateAdminSettings,
    ReceiveSupportContactList,
    ReceiveSupportContactSettings,
    RequestSupportContactList,
    RequestUpdateAdminSettings,
    RequestSupportContactSettings,
    RequestAllRetentionPeriodsAction,
    ReceiveAllRetentionPeriodsAction,
    RequestRetentionSettings,
    ReceiveRetentionSettings,
    RequestPrimaryAdmin,
    CompletePrimaryAdmin,
    ReceivePrimaryAdmin,
    ReceiveMessageHistorySentItemsSettings,
    RequestFetchAdminSettings,
    ReceiveMessageHistoryReceivedItemsSettings,
    ReceiveAuthenticationSettings,
    ReceiveAuthenticationQuestionsSettings,
    ReceiveDropOffSettings,
    ReceiveNotificationsSettings,
    ReceiveRequestHistorySettings,
    ReceiveRequestNotificationSettings,
} from "../actions/adminSettingsActions";
import {
    INotificationsSettings,
    IReceivedItemsHistorySettingsValues,
    ISentItemsHistorySettingsValues,
    ISupportContactSettingsValues,
    IRequestHistorySettingsValues,
    IRequestNotificationsSettings,
} from "../reducers/adminSettingsReducer";
import { AppThunkAction } from "../store";
import { actionTypes as adminSettingsActions } from "../types/adminSettingsTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { ICompanyRetentionSettings } from "../core/viewModels/company/settings/RetentionSettings";
import { CompanySettingsName } from "../core/common/enums";
import { IAuthenticationMethodSettings } from "../core/viewModels/company/settings/AuthenticationSettings";
import { IMessageSecuritySettings } from "../core/viewModels/message/MessageSecurityQuestionModel";
import { IDropOffSettings } from "../core/viewModels/company/settings/DropOffSettings";
import { AppNotifier } from "../helper/AppNotifier";
import { CompanySettingsConstants } from "../helper/Constants";

export type DispatchActions =
    | RequestSupportContactList
    | CompleteSupportContactList
    | ReceiveSupportContactList
    | RequestUpdateAdminSettings
    | ReceiveSupportContactSettings
    | CompleteAdminSettings
    | RequestFetchAdminSettings
    | CompleteUpdateAdminSettings
    | RequestSupportContactSettings
    | RequestAllRetentionPeriodsAction
    | ReceiveAllRetentionPeriodsAction
    | RequestSupportContactSettings
    | ReceiveSupportContactList
    | RequestRetentionSettings
    | ReceiveRetentionSettings
    | RequestPrimaryAdmin
    | ReceivePrimaryAdmin
    | CompletePrimaryAdmin
    | ReceiveMessageHistorySentItemsSettings
    | ReceiveMessageHistoryReceivedItemsSettings
    | ReceiveAuthenticationSettings
    | ReceiveAuthenticationQuestionsSettings
    | ReceiveDropOffSettings
    | ReceiveNotificationsSettings
    | ReceiveRequestHistorySettings
    | ReceiveRequestNotificationSettings;

export type KnownActions = DispatchActions | NotificationAction;

export type UpdateAdminSettingsPayload =
    | ISupportContactSettingsValues
    | ICompanyRetentionSettings
    | ISentItemsHistorySettingsValues
    | IReceivedItemsHistorySettingsValues
    | IRequestHistorySettingsValues
    | IAuthenticationMethodSettings
    | IMessageSecuritySettings
    | IDropOffSettings
    | INotificationsSettings
    | IRequestNotificationsSettings;

export const fetchContactList =
    (callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: adminSettingsActions.REQUEST_SUPPORT_CONTACT_LIST,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Contact/GetSupportContactList`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: adminSettingsActions.RECEIVE_SUPPORT_CONTACT_LIST,
                    payload: data,
                });
                callback();
            })
            .catch(function (error: any) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: adminSettingsActions.COMPLETE_SUPPORT_CONTACT_LIST,
                });
            });
    };
export const updateAdminSettings =
    (
        name: CompanySettingsName,
        payload: UpdateAdminSettingsPayload,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: adminSettingsActions.REQUEST_UPDATE_ADMIN_SETTINGS,
        });

        axios
            .put(`${process.env.REACT_APP_API_URL}/api/Company/UpdateExchangeCompanySettings`, {
                name: name,
                value: JSON.stringify(payload),
            })
            .then(() => {
                dispatch({
                    type: adminSettingsActions.COMPLETE_UPDATE_ADMIN_SETTINGS,
                });
                if (callback) {
                    callback();
                }
                name === CompanySettingsName.SentMessageHistorySettings && dispatch(fetchSentMessageHistorySettings());
                name === CompanySettingsName.ReceivedMessageHistorySettings &&
                    dispatch(fetchReceivedMessageHistorySettings());
                name === CompanySettingsName.RequestHistorySettings &&
                    dispatch(fetchRequestHistorySettings());
                (name !== CompanySettingsName.DropOffSettings && name!== CompanySettingsName.RequestHistorySettings) &&
                    AppNotifier.Success(CompanySettingsConstants.APPLY_CHANGES_SUCCESS);
            })
            .catch(function (error: any) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: adminSettingsActions.COMPLETE_UPDATE_ADMIN_SETTINGS,
                });
            });
    };

export const fetchSupportContactSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetSupportContactSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_SUPPORT_CONTACT_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchSentMessageHistorySettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetSentMessageHistorySettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_MESSAGE_HISTORY_SENT_ITEMS_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchReceivedMessageHistorySettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetReceivedMessageHistorySettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_MESSAGE_HISTORY_RECEIVED_ITEMS_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchRequestHistorySettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetDocumentRequestHistorySettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_REQUEST_HISTORY_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchRetentionSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetRetentionSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_RETENTION_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchAuthenticationMethodSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetAuthenticationMethodSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_AUTHENTICATION_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchMessageSecurityQuestionSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetMessageSecurityQuestionSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_MESSAGE_SECURITY_QUESTIONS_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchNotificationsSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetNotificationsSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_NOTIFICATIONS_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};
export const fetchRequestNotificationsSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetDocumentRequestNotificationSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_REQUESTNOTIFICATIONS_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};

export const fetchPrimaryAdmin =
    (resourceId: string, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: adminSettingsActions.REQUEST_PRIMARY_ADMIN,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Contact/GetPrimaryAdminForCompany`, {
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: adminSettingsActions.RECEIVE_PRIMARY_ADMIN,
                });
                callback(data);
            })
            .catch(function (error: any) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: adminSettingsActions.COMPLETE_PRIMARY_ADMIN,
                });
            });
    };

export const requestAllRetentionPeriods =
    (reload = false): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: adminSettingsActions.REQUEST_ALL_RETENTION_PERIODS,
        });
        if (reload) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/api/Company/GetAllRetentionPeriodList`)
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: adminSettingsActions.RECEIVE_ALL_RETENTION_PERIODS,
                        retentionPeriods: data,
                    });
                })
                .catch(function (error) {
                    dispatch({
                        type: notifactionAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                });
        }
    };

export const fetchDropOffSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: adminSettingsActions.REQUEST_FETCH_ADMIN_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/GetDropOffSettings`)
        .then((response: AxiosResponse) => {
            const data = response.data;
            dispatch({
                type: adminSettingsActions.RECEIVE_DROP_OFF_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: adminSettingsActions.COMPLETE_ADMIN_SETTINGS,
            });
        });
};
