import { OverlayTrigger, Popover } from "react-bootstrap";
import {
    DateType,
    DocumentRequestStatusHistoryReport,
    LinkStatus,
    MethodOfTransfer,
} from "../../../../core/common/enums";
import {
    activityLogTableColumns,
    activityLogTableColumnsDR,
    IFileActivity,
    IFileActivityDR,
    IUploadedFile,
    uploadedFilesTableColumns,
} from "../../../../core/viewModels/report/reportViewModel";
import { DateFormatConstants, ReportConstants, ReportsFilterConstants } from "../../../../helper/Constants";
import { getFormattedLocalDateTime } from "../../../../helper/DateHelperFunctions";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";
import { IDocumentModel, IDocumentRequestFileModel } from "../../../../core/viewModels/report/documentRequestHistoryModel";
import React from "react";

const gePopOvertTitle = (isFile: boolean) => {
    return isFile == true ? ReportConstants.FILE_POPOVER_TITLE : ReportConstants.DOCUMENT_POPOVER_TITLE;
};
const popoverFiles = (cell: string[], isFile = true) => (
    <Popover id="popover-files">
        <Popover.Header as="h3">{gePopOvertTitle(isFile)}</Popover.Header>
        <Popover.Body className="reports-popover-body">
            {cell.map((data, index) => (
                <div key={index}>
                    <ul>
                        <li title={data}>{data}</li>
                    </ul>
                </div>
            ))}
        </Popover.Body>
    </Popover>
);
export const uploadedFilesFormatter = (data: string) => {
    let value: IDocumentRequestFileModel[] = [];
    if (data) value = JSON.parse(data) as IDocumentRequestFileModel[];
    const fileData: IUploadedFile[] = value.flatMap(doc => {
        if (doc.UploadedFiles) {
            return doc.UploadedFiles.map(file => {
                return {
                    fileName: file.FileName,
                    document: doc.Name,
                    uploadedDate: file.UploadedDate,
                };
            });
        } else {
            return [];
        }
    });

    const latestUpload = fileData.sort(
        (a, b) => new Date(b.uploadedDate).getTime() - new Date(a.uploadedDate).getTime()
    )[0];
    return fileData.length > 0 ? (
        <OverlayTrigger trigger="click" placement="auto" overlay={popoverUploadedFiles(fileData)} rootClose>
            <div className="pointer padding-right-10-px with-badge">
                <span className="ellipsis">{latestUpload.fileName}</span>
                {fileData.length > 1 && <span className="badge">+{fileData.length - 1} </span>}
            </div>
        </OverlayTrigger>
    ) : (
        <span className="no-data">NA</span>
    );
};

export const popoverUploadedFiles = (cell: IUploadedFile[]) => {
    return (
        <Popover id="popover-activity">
            <Popover.Header as="h3">{ReportConstants.UPLOADED_FILES_POPOVER_TITLE}</Popover.Header>
            <Popover.Body className="reports-popover-body">
                <table>
                    <thead>
                        <tr>
                            {uploadedFilesTableColumns.map(item => (
                                <th key={item}>
                                    <span>{item}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {cell.map((cell, index) => (
                            <tr key={index}>
                                <td title={cell.document}>{cell.document || "-"}</td>
                                <td title={cell.fileName}>{cell.fileName}</td>
                                <td title={dataFormatter(cell.uploadedDate.toString())}>
                                    {dataFormatter(cell.uploadedDate.toString()) || "-"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Popover.Body>
        </Popover>
    );
};
export const fileNameFormatter = (value: string, isFile = true) => {
    const cell = (value && value.split(",")) || [];
    return cell.length > 1 ? (
        <OverlayTrigger trigger="click" overlay={popoverFiles(cell, isFile)} rootClose>
            <div className="pointer padding-right-10-px with-badge">
                <span className="ellipsis">{cell[0]}</span>
                <span className="badge">+{cell.length - 1} </span>
            </div>
        </OverlayTrigger>
    ) : (
        <span title={cell[0]}>{cell[0] || "-"}</span>
    );
};

export const documentNameFormatter = (data: string) =>{
    let value: IDocumentModel[] = [];
    if (data) {value = JSON.parse(data) as IDocumentModel[];
    const documentNames = value.map(doc => doc.Name);
    const firstDocumentName = documentNames[0];
    const additionalDocumentsCount = documentNames.length - 1;

    return documentNames.length > 1 ? (
        <OverlayTrigger trigger="click" overlay={popoverFiles(documentNames, false)} rootClose>
            <div className="pointer padding-right-10-px with-badge">
                <span className="ellipsis">{firstDocumentName}</span>
                {additionalDocumentsCount > 0 && <span className="badge">+{additionalDocumentsCount}</span>}
            </div>
        </OverlayTrigger>
    ) : (
        <span title={firstDocumentName}>{firstDocumentName || "-"}</span>
    );
}
};

export const dataFormatter = (cell: string) => getFormattedLocalDateTime(cell, DateFormatConstants.REPORTS_DATE_FORMAT);

const popoverRecipient = (cell: string[]) => (
    <Popover id="popover-recipients">
        <Popover.Header as="h3" data-test-auto={AutomationIdConstants.commonSihRih.RecipientsPopOverTitle}>
            {ReportConstants.RECIPIENTS_POPOVER_TITLE}
        </Popover.Header>
        <Popover.Body className="reports-popover-body">
            {cell.map((data, index) => (
                <div key={index}>
                    <ul>
                        <li title={data} data-test-auto={AutomationIdConstants.commonSihRih.RecipientsPopOverBody}>
                            {data}
                        </li>
                    </ul>
                </div>
            ))}
        </Popover.Body>
    </Popover>
);
export const recipientFormatter = (value: string) => {
    const cell = (value && value.split(",")) || [];
    return cell.length > 1 ? (
        <OverlayTrigger trigger="click" placement="right" overlay={popoverRecipient(cell)} rootClose>
            <div className="pointer padding-right-20-px with-badge">
                <span className="ellipsis">{cell[0]}</span>
                <span className="badge margin-left-5-px">+{cell.length - 1} </span>
            </div>
        </OverlayTrigger>
    ) : (
        <span title={cell[0]}>{cell[0]}</span>
    );
};

const popoverActivityLog = (cell: IFileActivity[] | IFileActivityDR[], columns: string[], isDocument = false) => (
    <Popover id="popover-activity">
        <Popover.Header as="h3">{ReportConstants.ACTIVITY_LOG_POPOVER_TITLE}</Popover.Header>
        <Popover.Body className="reports-popover-body">
            <table>
                <thead>
                    <tr>
                        {columns.map(item => (
                            <th key={item}>
                                <span>{item}</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {cell.map(cell => (
                        <tr key={Date.now()+cell.FileName+cell.EmailAddress}>
                            <td title={cell.EmailAddress}>{cell.EmailAddress || "-"}</td>
                            {isDocument && "Document" in cell && <td title={cell.Document}>{cell.Document}</td>}
                            <td title={cell.FileName}>{cell.FileName}</td>
                            <td title={dataFormatter(cell.AccessDate)}>{dataFormatter(cell.AccessDate) || "-"}</td>
                            <td>{cell.IPAddress || "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Popover.Body>
    </Popover>
);
export const activityLogFormatter = (cell: string, isDocumentRequest = false) => {
    let parsedData: any;
    if (cell !== "NA") {
        parsedData = isDocumentRequest
            ? (JSON.parse(cell) as IFileActivityDR[])
            : (JSON.parse(cell) as IFileActivity[]);
    }

    const columns = isDocumentRequest ? activityLogTableColumnsDR : activityLogTableColumns;
    return parsedData && parsedData.length > 0 ? (
        <OverlayTrigger
            trigger="click"
            placement="auto"
            overlay={popoverActivityLog(parsedData, columns, isDocumentRequest)}
            rootClose
        >
            <div className="pointer padding-right-20-px with-badge">
                <span className="ellipsis">{dataFormatter(parsedData[0].AccessDate)}</span>
            </div>
        </OverlayTrigger>
    ) : (
        <span className="no-data">NA</span>
    );
};

export const linkStatusFormatter = (cell: LinkStatus) => {
    return (
        <div className="pointer padding-right-20-px">
            <span className="ellipsis">{getLinkStatusName(cell)}</span>
        </div>
    );
};

export const requestStatusFormatter = (cell: DocumentRequestStatusHistoryReport) => {
    return (
        <div className="pointer padding-right-20-px">
            <span className="ellipsis">{getRequestStatusName(cell)}</span>
        </div>
    );
};

export const transferModeFormatter = (cell: MethodOfTransfer) => {
    return (
        <div className="pointer padding-right-20-px">
            <span className="ellipsis" title={getTransferModeName(cell)}>
                {getTransferModeName(cell)}
            </span>
        </div>
    );
};

export const getLinkStatusName = (value: LinkStatus) => {
    switch (value) {
        case LinkStatus.Active:
            return ReportsFilterConstants.FILTER_LINK_STATUS.ACTIVE;
        case LinkStatus.Cancelled:
            return ReportsFilterConstants.FILTER_LINK_STATUS.CANCELLED;
        case LinkStatus.Expired:
            return ReportsFilterConstants.FILTER_LINK_STATUS.EXPIRED;
        default:
            return "";
    }
};
export const getRequestStatusName = (value: DocumentRequestStatusHistoryReport) => {
    const { NONE, PENDING_UPLOAD, PARTIALLY_UPLOADED, OVERDUE, COMPLETED, Expired, Cancelled } =
        ReportsFilterConstants.FILTER_REQUEST_STATUS;
    switch (value) {
        case DocumentRequestStatusHistoryReport.None:
            return NONE;
        case DocumentRequestStatusHistoryReport.PendingUpload:
            return PENDING_UPLOAD;
        case DocumentRequestStatusHistoryReport.PartiallyUploaded:
            return PARTIALLY_UPLOADED;
        case DocumentRequestStatusHistoryReport.Overdue:
            return OVERDUE;
        case DocumentRequestStatusHistoryReport.Completed:
            return COMPLETED;
        case DocumentRequestStatusHistoryReport.Expired:
            return Expired;
        case DocumentRequestStatusHistoryReport.Canceled:
            return Cancelled;
    }
};
export const getTransferModeName = (value: MethodOfTransfer) => {
    switch (value) {
        case MethodOfTransfer.DropOff:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.DROP_OFF;
        case MethodOfTransfer.PortalAccessCode:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.PORTAL_ACCESS_CODE;
        case MethodOfTransfer.PortalQA:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.PORTAL_QA;
        case MethodOfTransfer.WebAddInAccessCode:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.WEB_ADD_IN_ACCESS_CODE;
        case MethodOfTransfer.WebAddInQA:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.WEB_ADD_IN_QA;
        case MethodOfTransfer.FolderDropOff:
            return ReportsFilterConstants.FILTER_TRANSFER_MODES.Folder_DROP_OFF;
        default:
            return "";
    }
};

export const getDateTypeNames = (value: DateType) => {
    switch (value) {
        case DateType.All:
            return ReportsFilterConstants.DATE_TYPES.ALL;
        case DateType.Last30Days:
            return ReportsFilterConstants.DATE_TYPES.LAST_30_DAYS;
        case DateType.Last12Months:
            return ReportsFilterConstants.DATE_TYPES.LAST_12_MONTHS;
        default:
            return "";
    }
};
