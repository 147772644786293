import React, { useState } from "react";
import { OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";

import { FilterIcon, SortIcon } from "../../../svg/IconCollection";
import { ColorConstants } from "../../../../assets/custom/colors";
import { SortAndFilterConstants, SortAndFilterIconType } from "../../../../helper/Constants";
import "./FiltersCustomComponent.scss";

/** Sort and Filter props made optional because either parent component supports only sorting or filter feature */
export interface IFiltersCustomComponentProps {
    groups: IFilterGroup[];
    selectedFilterOption?: string;
    selectedSortOption?: string;
    iconType: SortAndFilterIconType;
    setSelectedFilterOption?: React.Dispatch<React.SetStateAction<string>>;
    setSelectedSortOption?: React.Dispatch<React.SetStateAction<string>>;
    setCurrentSelectedOption?: (value: string) => void;
}

export interface IFilterGroup {
    title: string;
    menus: string[];
}

export const FiltersCustomComponent: React.FC<IFiltersCustomComponentProps> = ({
    groups,
    selectedFilterOption,
    selectedSortOption,
    iconType,
    setSelectedFilterOption,
    setSelectedSortOption,
    setCurrentSelectedOption,
}) => {
    const [showDropDown, setShowDropDown] = useState(false);

    const sortData = (event: any) => {
        const selectedElement = event.target as HTMLElement;

        if (
            (selectedFilterOption && selectedElement.title.includes(selectedFilterOption)) ||
            (selectedSortOption && selectedElement.title.includes(selectedSortOption))
        ) {
            return;
        }

        if (selectedElement.parentElement?.firstChild?.textContent?.includes(SortAndFilterConstants.SORT_BY_TEXT)) {
            setSelectedSortOption && setSelectedSortOption(selectedElement.title);
        } else {
            setSelectedFilterOption && setSelectedFilterOption(selectedElement.title);
        }

        setCurrentSelectedOption && setCurrentSelectedOption(selectedElement.title);
    };

    const getIconVisibility = (item: string) => {
        if (selectedSortOption?.includes(item) || selectedFilterOption?.includes(item)) return "visible";
        return "hidden";
    };

    const getFilterIconBackgroundColor = () => {
        if (
            selectedSortOption !== SortAndFilterConstants.NEWEST_FIRST_ORDER ||
            (selectedFilterOption && selectedFilterOption !== SortAndFilterConstants.ALL_STATUSES_ORDER)
        )
            return ColorConstants.brand_pimary_blue_2;
        return "";
    };

    const getIcon = (type: SortAndFilterIconType) => {
        switch (type) {
            case SortAndFilterIconType.Filter:
                return <FilterIcon width={19} height={19} color={ColorConstants.grey} />;
            case SortAndFilterIconType.Sort:
                return <SortIcon width={19} height={19} color={ColorConstants.grey} />;
        }
    };

    const filterPopOver = (
        <Popover
            style={{
                zIndex: 1000,
            }}
        >
            <PopoverBody className="p-0">
                <div className="filter-dropdown-container">
                    {groups.map((group, index) => (
                        <div key={group.title + index} className="groups-container">
                            <span className="group-title">{group.title}</span>
                            {group.menus.map((menu, menuIndex) => (
                                <span key={menu + menuIndex} className="menu-item" onClick={sortData} title={menu}>
                                    <i
                                        className="fa fa-check"
                                        style={{ visibility: getIconVisibility(menu) }}
                                        title={menu}
                                    ></i>
                                    {menu}
                                </span>
                            ))}
                            {index < groups.length - 1 && <hr />}
                        </div>
                    ))}
                </div>
            </PopoverBody>
        </Popover>
    );
    return (
        <div className="filters-custom-component" style={{ borderColor: getFilterIconBackgroundColor() }}>
            <OverlayTrigger
                placement="bottom-start"
                overlay={filterPopOver}
                trigger="click"
                rootClose
                onToggle={(show: boolean) => setShowDropDown(show)}
                show={showDropDown}
            >
                <span className="filter-icon-wrapper" onClick={() => setShowDropDown(true)}>
                    {getIcon(iconType)}
                    <i className="fa fa-caret-down basic-arrow-down" style={{ color: ColorConstants.grey }}></i>
                </span>
            </OverlayTrigger>
        </div>
    );
};
