import axios from "axios";
import { DownloadedFrom, IFileModel } from "../core/viewModels/file/FileModel";
import { AppThunkAction } from "../store";
import { actionTypes as attachmentAction } from "../types/attachmentTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { MessageSource } from "../core/common/enums";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { ReportDownloadContansts } from "../helper/Constants";
import moment from "moment";

interface RequestDownloadSingleAttachmentAction {
  type: attachmentAction.REQUEST_DOWNLOAD_SINGLE_ATTACHMENT;
}

interface ReceivedDownloadSingleAttachmentAction {
  type: attachmentAction.RECEIVED_DOWNLOAD_SINGLE_ATTACHMENT;
}

interface CompletedDownloadSingleAttachmentAction {
  type: attachmentAction.COMPLETED_DOWNLOAD_SINGLE_ATTACHMENT;
}

interface RequestDownloadAllAttachmentsAction {
  type: attachmentAction.REQUEST_DOWNLOAD_ALL_ATTACHMENTS;
}

interface ReceivedDownloadAllAttachmentsAction {
  type: attachmentAction.RECEIVED_DOWNLOAD_ALL_ATTACHMENTS;
}

interface CompletedDownloadAllAttachmentsAction {
  type: attachmentAction.COMPLETED_DOWNLOAD_ALL_ATTACHMENTS;
}

interface RequestDownloadWebAddInManifestAction {
  type: attachmentAction.REQUEST_DOWNLOAD_WEB_ADD_IN_MANIFEST;
}

interface ReceivedDownloadWebAddInManifestAction {
  type: attachmentAction.RECEIVED_DOWNLOAD_WEB_ADD_IN_MANIFEST
}

interface CompletedDownloadWebAddInManifestAction {
  type: attachmentAction.COMPLETED_DOWNLOAD_WEB_ADD_IN_MANIFEST;
}

export type DispatchActions =
  | RequestDownloadSingleAttachmentAction
  | ReceivedDownloadSingleAttachmentAction
  | CompletedDownloadSingleAttachmentAction
  | RequestDownloadAllAttachmentsAction
  | ReceivedDownloadAllAttachmentsAction
  | CompletedDownloadAllAttachmentsAction
  | RequestDownloadWebAddInManifestAction
  | ReceivedDownloadWebAddInManifestAction
  | CompletedDownloadWebAddInManifestAction;

export type KnownActions = DispatchActions | NotificationAction;

export const downloadSingleAttachment =
  (
    fileId: number,
    physicalPath: string,
    messageId: number,
    fileName: string,
    senderEmail: string,
    receivedMessageGuid: string,
    messageSource: MessageSource,
    downloadedFrom: DownloadedFrom,
    callback: any,
    pageTitle?: string,
    resourceId?: string
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: attachmentAction.REQUEST_DOWNLOAD_SINGLE_ATTACHMENT,
      });

      logger.trackEvent({
        name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.DownloadSingleAttachment}`,
        properties: {
          page: pageTitle,
          fileId,
          physicalPath,
          messageId,
          fileName,
          senderEmail,
          receivedMessageGuid,
          messageSource,
          downloadedFrom,
        }
      });

      
      axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Download/File?physicalPath=${physicalPath}&fileName=${encodeURIComponent(fileName)}&fileId=${fileId}` +
        `&messageId=${messageId}&mailboxUserEmailId=${getMailboxUserEmailId()}` +
        `&downloadedFrom=${downloadedFrom}&senderEmail=${senderEmail}&messageGuid=${receivedMessageGuid}
         &messageSource=${messageSource}`,{},
        {
          headers: {
            "X-Resource-Id": resourceId ?? ""
          }
        }
      )
      .then((response: any) => {
        const data = response.data;
        dispatch({
          type: attachmentAction.RECEIVED_DOWNLOAD_SINGLE_ATTACHMENT,
        });
        callback(data);
      })
      .catch((error) => {
        dispatch({
          type: attachmentAction.COMPLETED_DOWNLOAD_SINGLE_ATTACHMENT,
        });
        dispatch({
          type: notificationAction.NOTIFICATION,
          statusMessage: error.message,
          statusType: StatusType.Error,
        });
      });
  };

    export const downloadAllAttachments =
    (
      files: IFileModel[],
      messageId: number,
      senderEmail: string,
      receivedMessageGuid: string,
      messageSource: MessageSource,
      downloadedFrom: DownloadedFrom,
      callback: any,
      pageTitle?: string,
      resourceId?: string
    ): AppThunkAction<KnownActions> =>
      (dispatch) => {
        dispatch({
          type: attachmentAction.REQUEST_DOWNLOAD_ALL_ATTACHMENTS,
        });
  
        files.map(file => {
          logger.trackEvent({
            name: `${pageTitle} - ${appInsightsData.MailBox.CommonOperations.DownloadAllAttachments}`,
            properties: {
              page: pageTitle,
              senderEmail,
              receivedMessageGuid,
              messageSource,
              downloadedFrom,
              ...file
            }
          });
        });
        const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
        const filename = `${ReportDownloadContansts.MULTIPLE_ATTACHMENT_FILE_NAME}${date}`;
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/Download/Files?mailboxUserEmailId=${getMailboxUserEmailId()}` +
            `&messageId=${messageId}&downloadedFrom=${downloadedFrom}&senderEmail=${senderEmail}&messageGuid=${receivedMessageGuid}
            &messageSource=${messageSource}&friendlyFileNameForZip=${filename}`, files,
            {
              headers: {
                "X-Resource-Id": resourceId ?? ""
              }
            }
          )
          .then((response: any) => {
            const data = response.data;
            dispatch({
              type: attachmentAction.RECEIVED_DOWNLOAD_ALL_ATTACHMENTS,
            });
            callback(data);
          })
          .catch((error) => {
            dispatch({
              type: attachmentAction.COMPLETED_DOWNLOAD_ALL_ATTACHMENTS,
            });
            dispatch({
              type: notificationAction.NOTIFICATION,
              statusMessage: error.message,
              statusType: StatusType.Error,
            });
          });
      };

export const downloadWebAddInManifest =
  (
    callback: any
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: attachmentAction.REQUEST_DOWNLOAD_WEB_ADD_IN_MANIFEST,
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/Download/GetWebAddInManifest?mailboxUserEmailId=${getMailboxUserEmailId()}`
        )
        .then((response: any) => {
          const data = response.data;
          dispatch({
            type: attachmentAction.RECEIVED_DOWNLOAD_WEB_ADD_IN_MANIFEST,
          });
          callback(data);
        })
        .catch((error) => {
          dispatch({
            type: attachmentAction.COMPLETED_DOWNLOAD_WEB_ADD_IN_MANIFEST,
          });
          dispatch({
            type: notificationAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        });
    };
