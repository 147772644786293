import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { FileViewer } from "./FileViewer";

interface PreviewUplodedFileModalProps {
    url: string;
    fileName: string | undefined;
    fileType: string | undefined;
    fileGuid: string | undefined;
    handleClose: () => void;
    onClickDownloadUploadedDocument: (fileName: string, fileGuid: string) => void;
    handleShow: () => void;
}

export const PreviewUplodedFileModal: React.FC<PreviewUplodedFileModalProps> = (props: any) => {
    return (
        <div>
            <Modal className="preview-modal" size="xl" show={props.handleShow} onHide={props.handleClose}>
                <Modal.Header className="preview-header" closeButton closeVariant="white">
                    <Modal.Title>{props.fileName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileViewer
                        url={props.url}
                        fileType={props.fileType}
                        onClickDownloadUploadedDocument={props.onClickDownloadUploadedDocument}
                        fileGuid={props.fileGuid}
                        fileName={props.fileName}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};
