export enum actionTypes {
    REQUEST_USER_SETTINGS = "request_user_settings",
    RECEIVE_USER_SETTINGS = "receive_user_settings",
    COMPLETE_USER_SETTINGS = "complete_user_settings",

    REQUEST_DELEGATEE_LIST = "request_delegatee_list",
    RECEIVE_DELEGATEE_LIST = "receive_delegatee_list",
    COMPLETE_DELEGATEE_LIST = "complete_delegatee_list",

    REQUEST_UPDATE_USER_SETTINGS = "request_update_user_settings",
    RECEIVE_UPDATE_USER_SETTINGS = "receive_update_user_settings",
    COMPLETE_UPDATE_USER_SETTINGS = "complete_update_user_settings",

    UPDATE_USER_SETTINGS = "update_user_settings",

    REQUEST_LAST_LOGIN_ENABLE = "request_last_login_enable",
    RECEIVE_LAST_LOGIN_ENABLE = "receive_last_login _enable",
    COMPLETE_LAST_LOGIN_ENABLE = "complete_last_login_enable",

    REQUEST_UPDATE_LAST_LOGIN_ENABLE = "request_update_last_login_enable",
    RECEIVE_UPDATE_LAST_LOGIN_ENABLE = "receive_update_last_login_enable",
    COMPLETE_UPDATE_LAST_LOGIN_ENABLE = "complete_update_last_login_enable",

    REQUEST_DROPOFF_SETTINGS = "request_dropoff_settings",
    RECEIVED_DROPOFF_SETTINGS = "received_dropoff_settings",
    COMPLETED_DROPOFF_SETTINGS = "compeleted_dropoff_settings",

    REQUEST_UPDATE_DROPOFF_SETTINGS = "request_update_dropoff_settings",
    RECEIVED_UPDATE_DROPOFF_SETTINGS = "received_update_dropoff_settings",
    COMPLETED_UPDATE_DROPOFF_SETTINGS = "completed_update_dropoff_settings",
}
