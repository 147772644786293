export interface ISignalRConnectionInfo {
    url: string,
    accessToken: string,
  }
  
  export const initialLockLinkObject : ISignalRConnectionInfo = {
    url: "",
    accessToken: "",
  };

  export interface INotificationMessage {
    companyId: number;
    userId: number[];
    jobId: string;
    notificationType: NotificationType;
    data: string;
}

export enum NotificationType {
    None = "None",
    BulkDownloadSuccess = "BulkDownloadSuccess",
    BulkDownloadFailed = "BulkDownloadFailed",
}

export enum LogoutCause {
    None = 0,
    UserPermissionChange = 1,
    UserDelete = 2,
    ProductTierChange = 3,
    CompanyDisable = 4
}

export interface IUserAutoLogoutNotificationMessage {
    users: number[],
    logoutCause: LogoutCause
}