import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { DropOffModalConstants, ValidationContants } from "../../../../../helper/Constants";
import { isValidEmailAddress } from "../../../../../helper/Validations";
import { CustomModal } from "../CustomModal";

interface IEditSenderModalProps {
    showEditSenderModal: boolean;
    clearModalState: () => void;
    handleEditModalSubmission: (emailId: string) => void;
    intialValue: string;
}

export const EditSenderModal: React.FC<IEditSenderModalProps> = ({
    showEditSenderModal,
    clearModalState,
    handleEditModalSubmission,
    intialValue,
}) => {
    const [emailId, setEmailId] = useState(intialValue);
    const [validationMessage, setValidationMessage] = useState("");

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value);
        if (validationMessage) {
            setValidationMessage("");
        }
    };

    const isModalDataValid = () => {
        let isValid = true;

        if (emailId.trim().length < 1) {
            setValidationMessage(ValidationContants.EmailAdrressWarning);
            isValid = false;
        } else if (!isValidEmailAddress(emailId)) {
            setValidationMessage(ValidationContants.ValidEmailAddressWarning);
            isValid = false;
        }
        return isValid;
    };

    const handleOnSubmit = () => {
        if (isModalDataValid()) {
            handleEditModalSubmission(emailId);
        }
    };

    return (
        <CustomModal
            show={showEditSenderModal}
            onHide={() => {
                clearModalState();
            }}
            onSubmit={handleOnSubmit}
            cancelButtonName="Cancel"
            confirmButtonName="Ok"
            title={DropOffModalConstants.DROP_OFF_EDIT_MODAL_TITLE}
        >
            <Form validated={validationMessage !== ""}>
                <Form.Group className="mb-3" controlId="EditSenderId">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        onChange={handleOnChange}
                        value={emailId}
                        style={{ padding: "10px" }}
                        type="email"
                        placeholder={DropOffModalConstants.EDIT_SENDER_EMAIL_PLACEHOLDER}
                    />
                    <h6 className="text-danger">{validationMessage}</h6>
                </Form.Group>
            </Form>
        </CustomModal>
    );
};
