import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import {
    fetchContactList,
    fetchPrimaryAdmin,
    fetchSupportContactSettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { ISupportContact } from "../../../../../reducers/adminSettingsReducer";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CompanySettingsName } from "../../../../../core/common/enums";
import SearchInput from "../../../../common/ui/SearchInput";
import { SupportContactsConstants, userSettingsConstant } from "../../../../../helper/Constants";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";
import { ResourceIdLocators } from "../../../../../helper/ResourceIdLocators";

const ContactSupport: React.FC = () => {
    const [selectedContact, setSelectedContact] = useState<ISupportContact>({
            firstName: "",
            lastName: "",
            emailAddress: "",
            userId: 0,
        }),
        [showPhone, setShowPhone] = useState<boolean>(true),
        [searchText, setSearchText] = useState<string>(""),
        [filteredList, setFilteredList] = useState<ISupportContact[]>([]),
        dispatch = useDispatch(),
        { supportContactList, supportContactSettings, isLoading } = useSelector(
            (state: ApplicationState) => state.adminSettingsState
        );
    useEffect(() => {
        const fetchSettingsFunction = () => dispatch(fetchSupportContactSettings());
        dispatch(fetchContactList(fetchSettingsFunction));
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.SupportContact.PageTitle);
    }, []);
    useEffect(() => {
        if (supportContactSettings) {
            const defaultContact = supportContactList.find(
                    data => data.userId === supportContactSettings.supportContactId
                ),
                displayPhone = !!supportContactSettings?.isDisplayPhoneEnabled;
            setShowPhone(displayPhone);
            if (!defaultContact) getPrimaryAdminForCompany();
            else setSelectedContact(defaultContact);
        }
    }, [supportContactSettings]);
    useEffect(() => {
        setFilteredList(supportContactList);
    }, [supportContactList]);

    const getName = (contact: ISupportContact): string => `${contact?.firstName} ${contact?.lastName || ""}`,
        getContactPayload = (contact: ISupportContact, displayPhone: boolean) => {
            return {
                supportContactId: contact.userId,
                isDisplayPhoneEnabled: displayPhone,
            };
        },
        onContactChange = (e: any) => {
            const contact = supportContactList.find(
                (data: ISupportContact) => `contact-${data.userId}` === e.target.id
            );
            if (contact) {
                setSelectedContact(contact);
                const contactPayload = getContactPayload(contact, showPhone);
                dispatch(updateAdminSettings(CompanySettingsName.SupportContactSettings, contactPayload));
            }
        },
        onShowPhoneChange = (e: any) => {
            setShowPhone(e.target.checked);
            const contact = selectedContact || supportContactList[0];
            const contactPayload = getContactPayload(contact, e.target.checked);
            dispatch(updateAdminSettings(CompanySettingsName.SupportContactSettings, contactPayload));
        },
        onFilter = (e: any) => {
            const value = e.target.value;
            setSearchText(value);
            const filteredList = supportContactList.filter(data =>
                `${getName(data).toLocaleLowerCase()}`.includes(value.toLocaleLowerCase())
            );
            setFilteredList(filteredList);
        },
        getPrimaryAdminForCompany = () => {
            dispatch(
                fetchPrimaryAdmin(ResourceIdLocators.AdminSetting.DropOff, (admin: ISupportContact) =>
                    setSelectedContact(admin)
                )
            );
        };
    return (
        <LoadingOverlay>
            <header>
                <span>{SupportContactsConstants.SUPPORT_CONTACT_HELP_TEXT}</span>
            </header>

            <section className="margin-top-10-px">
                <div className="support-contact-wrapper contact-label-dropdown-wrapper">
                    <h4 className="heading-black-md">{SupportContactsConstants.SUPPORT_CONTACT_DROP_DOWN_TEXT}</h4>

                    <Dropdown className="contact-list">
                        <Dropdown.Toggle>
                            <p
                                title={selectedContact ? getName(selectedContact) : getName(supportContactList[0])}
                                className="overflowText"
                            >
                                {selectedContact ? getName(selectedContact) : getName(supportContactList[0])}
                            </p>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="support-contact-dropdown-menu">
                            <div className="padding-top-bottom-2 padding-left-right-3 mb-2 dropdown-search">
                                <SearchInput placeholder="Search" value={searchText} onChange={onFilter} />
                            </div>
                            <div>
                                {filteredList.length > 0 ? (
                                    filteredList.map(data => (
                                        <Dropdown.Item
                                            title={getName(data)}
                                            id={`contact-${data.userId}`}
                                            eventKey={data.userId}
                                            key={data.userId}
                                            className={
                                                (selectedContact?.userId === data.userId ? "selected-contact" : "") +
                                                " overflowText"
                                            }
                                            onClick={e => onContactChange(e)}
                                            data-email={data.emailAddress}
                                        >
                                            {getName(data)}
                                        </Dropdown.Item>
                                    ))
                                ) : (
                                    <Dropdown.Item>{userSettingsConstant.NO_DELEGATEE_TEXT}</Dropdown.Item>
                                )}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </section>
            <div className="settings-label-dropdown-wrapper contact-label-dropdown-wrapper">
                <CustomToggleSwitch
                    label={SupportContactsConstants.SUPPORT_CONTACT_TOOGLE_SW_TEXT}
                    handleChange={onShowPhoneChange}
                    switched={showPhone}
                />
            </div>
            <hr />
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default ContactSupport;
