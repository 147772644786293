import BootstrapTable, { ColumnDescription, SelectRowProps, TableChangeHandler, SortOrder as TableSortOrder } from "react-bootstrap-table-next";
import { DEFAULT_PAGE_SIZE, DateFormatConstants, DocumentCategoryConstants } from "../../../../../helper/Constants";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getFormattedLocalDateTime } from "../../../../../helper/DateHelperFunctions";
import {
    IDocumentCategoryModel,
    IDocumentCategoryRequest,
    initialCategoryRequest,
} from "../../../../../core/viewModels/settings/DocumentCategory";
import "./style.scss";
import React, { useEffect } from "react";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import { DocumentCategorySortColumn, SortOrder } from "../../../../../core/common/enums";
import { AutomationIdConstants } from "../../../../../helper/AutomationConstants";

type CategoryTableProps = {
    data: IDocumentCategoryModel[];
    isLoading: boolean;
    count: number;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    handleTableSorting?: (sortField: DocumentCategorySortColumn, sortOrder: SortOrder, page:number) => void;
    selectedItems: IDocumentCategoryModel[];
    setSelectedItems: (items: IDocumentCategoryModel[]) => void;
    setShowDeleteModal: (show: boolean) => void;
    setShowEditModal: (show: boolean) => void;
    fetchCategories: (payload: IDocumentCategoryRequest) => void;
    sortOrder: TableSortOrder;
    setSortOrder: (SortOrder: TableSortOrder) => void;
    sortField: string | null;
    setSortField: (sortField: string|null) => void;
};

const DocumentCategoryTable = ({
    data,
    count,
    selectedItems,
    pageNumber,
    sortOrder,
    sortField,
    setSortField,
    setSortOrder,
    setPageNumber,
    setSelectedItems,
    setShowDeleteModal,
    setShowEditModal,
    handleTableSorting,
    fetchCategories,
}: CategoryTableProps) => {

    useEffect(() => {
        const payload = { ...initialCategoryRequest,
            sortColumn: sortField === "name" ? DocumentCategorySortColumn.Name : DocumentCategorySortColumn.CreatedDate,
            sortOrder: sortOrder === "asc"? SortOrder.ASC : SortOrder.DESC,
            pageNumber: pageNumber };
        fetchCategories(payload);
    }, [pageNumber]);

    const nameFormatter = (cell: string): JSX.Element => {
        return (
            <span title={cell} className={"category-text"}>
                {cell}
            </span>
        );
    };

    const createdOnFormatter = (cell: Date): JSX.Element => {
        return (
            <div className="category-text">
                <span>
                    {cell == null ? "NA" : getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
                </span>
            </div>
        );
    };

    const actionFormatter = (cell: number): JSX.Element => {
        const category = data.filter(x => x.documentCategoryId === cell)[0];
        return (
            <div className={`${category.createdBy === null ? " disabled-action" : ""}`}>
                <span
                    className={`action-btn  ${category.createdBy === null ? " disabled-span" : ""}`}
                    onClick={() => {
                        setSelectedItems([category]);
                        setShowEditModal(true);
                    }}
                >
                    {DocumentCategoryConstants.EDIT_BUTTON_TEXT} |{" "}
                </span>
                <span
                    className={`action-btn  ${category.createdBy === null ? " disabled-span" : ""}`}
                    onClick={() => {
                        setSelectedItems([category]);
                        setShowDeleteModal(true);
                    }}
                >
                    {DocumentCategoryConstants.DELETE_BUTTON_TEXT}
                </span>
            </div>
        );
    };
    const columns: ColumnDescription[] = [
        {
            text: "Document Category",
            dataField: "name",
            sort: true,
            headerStyle: { width: "150px" },
            formatter: nameFormatter,
        },
        {
            text: "Created On",
            dataField: "createdDate",
            sort: true,
            headerStyle: { width: "50px" },

            formatter: createdOnFormatter,
        },
        {
            text: "Action",
            dataField: "documentCategoryId",
            headerStyle: { width: "50px" },

            formatter: actionFormatter,
        },
    ];

    const handleOnSelect = (row: IDocumentCategoryModel, isSelect: boolean) => {
        let data = [...selectedItems];

        if (isSelect) {
            data.push(row);
        } else {
            data = data.filter(x => x.documentCategoryId != row.documentCategoryId);
        }

        setSelectedItems(data);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IDocumentCategoryModel[]) => {
        const data = rows.filter(x => x.createdBy !== null);
        if (isSelect) {
            setSelectedItems(data);
        } else {
            setSelectedItems([]);
        }
    };

    const selectRow: SelectRowProps<IDocumentCategoryModel> = {
        mode: "checkbox",
        clickToSelect: true,
        selected: selectedItems.map((data: IDocumentCategoryModel) => data.documentCategoryId),
        nonSelectable: data && Array.isArray(data) && data?.length > 0 ? [...data.filter(x => x.createdBy === null).map(x => x.documentCategoryId as number)] : [],
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedItems.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text=""
            />
        ),
    };

    const handleTableChange: TableChangeHandler<any> | undefined = (type, { sortField: newSortField, sortOrder: newSortOrder, page }) => {
        // Remote sorting will be handled here
            if (type === "sort") {
                if (newSortField !== sortField || newSortOrder !== sortOrder) {
                    setSortField(newSortField);
                    setSortOrder(newSortOrder);
                    const field = newSortField === "name" ? DocumentCategorySortColumn.Name : DocumentCategorySortColumn.CreatedDate;
                    const sort = newSortOrder === "asc" ? SortOrder.ASC : SortOrder.DESC;
                    handleTableSorting && handleTableSorting(field, sort, page);
                }
            }
        
            if (page !== pageNumber) {
                setPageNumber(page);
            }
    };

    const customTotal = (from: number, to: number) => (
        <span
            style={{ display: count <= DEFAULT_PAGE_SIZE ? "none" : "block" }}
            className="total-count"
            data-test-auto={AutomationIdConstants.commonSihRih.ReportTableTotCount}
        >
            Showing <b>{from}</b> to <b>{to}</b> of <b>{count}</b> entries
        </span>
    );
    const options = {
        totalSize: count ?? 0,
        sizePerPage: DEFAULT_PAGE_SIZE,
        page: pageNumber,
        showTotal: true,
        withFirstAndLast: true,
        alwaysShowAllBtns: true,
        hideSizePerPage: true,
        paginationTotalRenderer: customTotal,
        hidePageListOnlyOnePage: true,
    };

    return (
        <div className="category-table-container">
           <BootstrapTable
                classes="category-table"
                keyField="documentCategoryId"
                columns={columns}
                data={data}
                remote={{ sort: true, pagination: true }}
                selectRow={selectRow}
                noDataIndication={DocumentCategoryConstants.NO_DATA_MESSAGE}
                bordered={false}
                onTableChange={handleTableChange}
                sort={{ dataField: sortField, order: sortOrder}}
                pagination={paginationFactory(options)}
            />
        </div>
    );
};

export default DocumentCategoryTable;
