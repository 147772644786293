export enum actionTypes {
    REQUEST_DOCUMENT_CATEGORY = "request_document_category",
    RECEIVE_DOCUMENT_CATEGORY = "received_document_category",
    COMPLETED_DOCUMENT_CATEGORY = "completed_document_category",
    CREATE_DOCUMENT_CATEGORY = "create_document_category",
    UPDATE_DOCUMENT_CATEGORY = "update_document_category",
    DELETE_DOCUMENT_CATEGORY = "delete_document_category",
    REQUEST_DOCUMENT_CATEGORY_DROPDOWN = "request_document_category_dropdown",
    RECEIVE_DOCUMENT_CATEGORY_DROPDOWN = "received_document_category_dropdown",
    COMPLETED_DOCUMENT_CATEGORY_DROPDOWN = "completed_document_category_dropdown",
}
