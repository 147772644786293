import React, { useEffect, useState } from "react";
import { DocumentRequestStatusHistoryReport, FilterType, MethodOfTransfer } from "../../../core/common/enums";
import { ReportConstants } from "../../../helper/Constants";
import ReportHeader from "./parts/ReportHeader";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
    recipientFormatter,
    dataFormatter,
    activityLogFormatter,
    transferModeFormatter,
    requestStatusFormatter,
    uploadedFilesFormatter,
    documentNameFormatter,
} from "./parts/Formatter";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { IFilterModel, ISenderList, initialFilterValues } from "../../../core/viewModels/report/reportViewModel";
import {
    IReportItemsHistoryRequestModel,
    initialSentItemsRequestObject,
} from "../../../core/viewModels/report/SentItemsHistoryModel";
import ReportTable from "./parts/ReportTable";
import { fetchRequestHistorySettings } from "../../../actionCreators/adminSettingsActionCreators";
import {
    getDocumentHistoryMetrics,
    getDocumentReportHistory,
} from "../../../actionCreators/documentRequestHistoryActionCreator";

const columns = [
    {
        text: "Sender",
        dataField: "senderName",
        sort: true,
        title: (cell: string) => cell,
        classes: "ellipsis",
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Recipient(s)",
        dataField: "recipients",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => recipientFormatter(cell),
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Documents Requested",
        dataField: "documentNames",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => documentNameFormatter(cell),
        hidden: false,
        headerStyle: { width: "140px" },
    },
    {
        text: "Uploaded File(s)",
        dataField: "requestedDocuments",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => uploadedFilesFormatter(cell),
        hidden: false,
        headerStyle: { width: "115px" },
    },
    {
        text: "Sent Date",
        dataField: "sentDate",
        sort: true,
        formatter: (cell: string) => dataFormatter(cell),
        hidden: false,
        headerStyle: { width: "130px" },
    },
    {
        text: "Activity log",
        dataField: "activityLog",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => activityLogFormatter(cell, true),
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Request Status",
        dataField: "requestStatus",
        formatter: (cell: DocumentRequestStatusHistoryReport) => requestStatusFormatter(cell), //TODO: Add request status formatter
        sort: true,
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Transfer Mode",
        dataField: "methodOfTransfer",
        classes: "ellipsis",
        formatter: (cell: MethodOfTransfer) => transferModeFormatter(cell),
        sort: true,
        hidden: false,
        headerStyle: { width: "100px" },
    },
];

const DocumentRequestHistory = () => {
    const { data, isLoading } = useSelector((state: ApplicationState) => state.documentRequestReportState);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    //const [columnList, setColumnList] = useState<ColumnDescription[]>(columns);

    const userProfile = useSelector((state: ApplicationState) => state.userProfile);
    const [selectedFilter, setSelectedFilter] = useState<IFilterModel>({
        ...initialFilterValues,
        userId: userProfile.userId,
        filterSource: FilterType.DRH,
        filterCondition: {
            ...initialFilterValues.filterCondition,
            transferMode: [MethodOfTransfer.DropOff, ...initialFilterValues.filterCondition.transferMode],
        },
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRequestHistorySettings());
        dispatch(getDocumentReportHistory({ ...initialSentItemsRequestObject }));
        dispatch(getDocumentHistoryMetrics());
    }, []);

    const fetchReportsData = (payload?: IReportItemsHistoryRequestModel, callback?: () => void) => {
        if (payload) {
            dispatch(
                getDocumentReportHistory(payload, () => {
                    if (callback) {
                        callback();
                    }
                })
            );
        } else {
            dispatch(getDocumentReportHistory(initialSentItemsRequestObject));
        }
    };
    const recipients: ISenderList[] = [];

    const handleTableSorting = (sortField: string, sortOrder: string) => {
        const payload = {
            ...initialSentItemsRequestObject,
            sortColumn: sortField,
            sortOrder: sortOrder,
            filterId: selectedFilter.id === 0 ? 0 : -1,
            filterParameters: selectedFilter.id === 0 ? selectedFilter.filterCondition : undefined,
        };
        dispatch(getDocumentReportHistory(payload));
    };

    const handleDownload = () => {
        console.log("Download");
    };
    const handleDraw = () => {
        console.log("Drawer");
    };
    return (
        <LoadingOverlay className="reports-container">
            <ReportHeader
                title={ReportConstants.DR_TITLE}
                filterType={FilterType.DRH}
                sendersList={recipients}
                selectedFilter={selectedFilter}
                fetchReportsData={fetchReportsData}
                setSelectedFilter={setSelectedFilter}
                handleColumnDrawer={handleDraw}
                handleDownload={handleDownload}
                isDownloading={false}
                setCurrentPageNumber={setCurrentPageNumber}
            />
            <ReportTable
                filterType={FilterType.DRH}
                data={Array.isArray(data) ? data : []}
                columns={columns}
                count={data[0]?.Count || 0}
                isClickable={false}
                currentPageNumber={currentPageNumber}
                selectedFilter={selectedFilter}
                setCurrentPageNumber={setCurrentPageNumber}
                fetchReportsData={fetchReportsData}
                handleTableSorting={handleTableSorting}
            />
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default DocumentRequestHistory;
