import React from "react";
import { ThemeConstants } from "../../helper/Constants";

export interface IToolbarIconsProps {
    height?: number;
    width?: number;
    className?: string;
    color?: string;
    disabled?: boolean;
}

export const ChangeExpiryIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 122.88 108.12"
            width={props.width || 100}
            height={props.height || 100}
        >
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M28.45,55.88c0,.11,0,.22,0,.32l4.44-4.46a6.68,6.68,0,1,1,9.48,9.42L27.14,76.51a6.69,6.69,0,0,1-9.32.15L2.28,63A6.68,6.68,0,0,1,11.08,53l4,3.54v0a54.33,54.33,0,0,1,8-31,52.56,52.56,0,0,1,24-20.73,60.17,60.17,0,0,1,11-3.51,52.58,52.58,0,0,1,60.1,31.09,58.07,58.07,0,0,1,3.47,11,52.47,52.47,0,0,1-1.31,26.95A53.16,53.16,0,0,1,105.8,93a57.11,57.11,0,0,1-22.56,13.1,48.52,48.52,0,0,1-40.51-5.89A6.68,6.68,0,0,1,50,89a35.12,35.12,0,0,0,5.53,3,34.21,34.21,0,0,0,5.7,1.86,35.43,35.43,0,0,0,18.23-.54A43.77,43.77,0,0,0,96.74,83.19a39.7,39.7,0,0,0,10.93-17.06,39,39,0,0,0,1-20.08,46.38,46.38,0,0,0-2.68-8.5,39.19,39.19,0,0,0-45-23.22,45,45,0,0,0-8.52,2.72A39,39,0,0,0,34.5,32.49a40.94,40.94,0,0,0-6.05,23.39ZM60.83,34a6.11,6.11,0,0,1,12.22,0V53l14.89,8.27A6.09,6.09,0,1,1,82,71.93L64.43,62.16a6.11,6.11,0,0,1-3.6-5.57V34Z"
            />
        </svg>
    );
};

export const DeleteIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            viewBox="0 0 512 512"
            enableBackground="new 0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 100}
            width={props.width || 100}
        >
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M360.129 499.188H159.942c-27.622 0-50.042-24.238-50.042-54.166V201.256c0-14.948 11.207-27.084 25.021-27.084H385.15c13.813 0 25.026 12.136 25.026 27.084v243.766c0 29.928-22.398 54.166-50.047 54.166Zm75.068-406.27c0 14.951-11.212 27.083-25.021 27.083H109.9c-19.269 0-31.306-22.56-21.672-40.625 4.477-8.37 12.736-13.541 21.672-13.541h75.068V38.747c0-14.947 11.207-27.083 25.021-27.083h100.093c13.814 0 25.021 12.136 25.021 27.083v27.088h75.073c13.809 0 25.021 12.132 25.021 27.083Z"
            />
        </svg>
    );
};

export const NewDeleteIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 16}
            width={props.width || 16}
            fill="none"
            viewBox="0 0 16 16"
        >
            <g clipPath="url(#clip0_1007_1390)">
                <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
                <g clipPath="url(#clip1_1007_1390)">
                    <path
                        fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                        d="M6.5 1h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zM11 2.5v-1A1.5 1.5 0 009.5 0h-3A1.5 1.5 0 005 1.5v1H1.5a.5.5 0 100 1h.538l.853 10.66A2 2 0 004.885 16h6.23a2 2 0 001.993-1.84l.853-10.66h.538a.5.5 0 000-1H11zm1.957 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.996-.92L3.042 3.5h9.915zm-7.486 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.03a.5.5 0 01.47-.53zm5.057 0a.5.5 0 01.47.53l-.5 8.5a.503.503 0 01-.53.478.5.5 0 01-.467-.539l.5-8.5a.5.5 0 01.527-.47zM8 4.5a.5.5 0 01.5.5v8.5a.5.5 0 11-1 0V5a.5.5 0 01.5-.5z"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1007_1390">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
                <clipPath id="clip1_1007_1390">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};
export const CopyIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M13 0H6a2 2 0 00-2 2 2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2 2 2 0 002-2V2a2 2 0 00-2-2zm0 13V4a2 2 0 00-2-2H5a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1zM3 4a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V4z"
            ></path>

            <defs>
                <clipPath id="clip0_2203_448664">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export const MoveToFolderIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            viewBox="-1 44.875 644.0347030543597 552.25"
            width={props.width}
            height={props.height}
        >
            <defs>
                <path
                    d="M320.5 136.63C320.5 109.4 320.5 94.28 320.5 91.25C320.5 60.63 306.75 45.88 275.12 45.88C252.15 45.88 68.35 45.88 45.38 45.88C19.75 45.88 0 66.63 0 91.25C0 137 0 503 0 548.75C0 574.38 20.75 594.13 45.38 594.13C100.3 594.13 539.7 594.13 594.63 594.13C620.25 594.13 640 573.38 640 548.75C640 512.16 640 219.46 640 182.88C641 157.25 620.25 136.63 595.63 136.63C558.94 136.63 467.23 136.63 320.5 136.63ZM274.13 91.25L274.13 136.63C136.88 136.63 60.63 136.63 45.38 136.63C45.38 136.63 45.38 91.25 45.38 91.25C75.88 91.25 152.13 91.25 274.13 91.25ZM320.5 411.63C276.13 410.63 236.62 421.5 204.12 443.12C171.62 464.75 149.87 500.37 138 548.63C139 473.63 155.75 416.5 189.25 378C222.75 339.5 266.25 320 320.5 320C320.5 313.88 320.5 283.3 320.5 228.25L503.87 365.38L320.5 503.38L320.5 503.38C320.5 448.32 320.5 417.74 320.5 411.63Z"
                    id="a7tM2AMlE"
                ></path>
            </defs>
            <g>
                <g>
                    <use
                        xlinkHref="#a7tM2AMlE"
                        opacity="1"
                        fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                        fillOpacity="1"
                    ></use>
                    <g>
                        <use
                            xlinkHref="#a7tM2AMlE"
                            opacity="1"
                            fillOpacity="0"
                            stroke="#000000"
                            strokeWidth="1"
                            strokeOpacity="0"
                        ></use>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const MoveToInboxIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            viewBox="-1 44.875 644.0347030543597 552.25"
            width={props.width}
            height={props.height}
        >
            <defs>
                <path
                    d="M320.5 136.63C320.5 109.4 320.5 94.28 320.5 91.25C320.5 60.63 306.75 45.88 275.12 45.88C252.15 45.88 68.35 45.88 45.38 45.88C19.75 45.88 0 66.63 0 91.25C0 137 0 503 0 548.75C0 574.38 20.75 594.13 45.38 594.13C100.3 594.13 539.7 594.13 594.63 594.13C620.25 594.13 640 573.38 640 548.75C640 512.16 640 219.46 640 182.88C641 157.25 620.25 136.63 595.63 136.63C558.94 136.63 467.23 136.63 320.5 136.63ZM274.13 91.25L274.13 136.63C136.88 136.63 60.63 136.63 45.38 136.63C45.38 136.63 45.38 91.25 45.38 91.25C75.88 91.25 152.13 91.25 274.13 91.25ZM320.5 411.63C276.13 410.63 236.62 421.5 204.12 443.12C171.62 464.75 149.87 500.37 138 548.63C139 473.63 155.75 416.5 189.25 378C222.75 339.5 266.25 320 320.5 320C320.5 313.88 320.5 283.3 320.5 228.25L503.87 365.38L320.5 503.38L320.5 503.38C320.5 448.32 320.5 417.74 320.5 411.63Z"
                    id="a7tM2AMlE"
                ></path>
            </defs>
            <g>
                <g>
                    <use
                        xlinkHref="#a7tM2AMlE"
                        opacity="1"
                        fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                        fillOpacity="1"
                    ></use>
                    <g>
                        <use
                            xlinkHref="#a7tM2AMlE"
                            opacity="1"
                            fillOpacity="0"
                            stroke="#000000"
                            strokeWidth="1"
                            strokeOpacity="0"
                        ></use>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const PrintDocumentIcon: React.FC<IToolbarIconsProps> = (props) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.width ?? "16"}
        height={props?.width ?? "14"}
        fill="none"
        viewBox="0 0 16 14"
      >
        <path fill="#929292" d="M2.5 7a.5.5 0 100-1 .5.5 0 000 1z"></path>
        <path
          fill="#929292"
          d="M5 0a2 2 0 00-2 2v2H2a2 2 0 00-2 2v3a2 2 0 002 2h1v1a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2V6a2 2 0 00-2-2h-1V2a2 2 0 00-2-2H5zM4 2a1 1 0 011-1h6a1 1 0 011 1v2H4V2zm1 5a2 2 0 00-2 2v1H2a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1h-1V9a2 2 0 00-2-2H5zm7 2v3a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h6a1 1 0 011 1z"
        ></path>
      </svg>
    );
};

export const SendLinkIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="145.667 101.956 235.333 118.218"
            width={props.width}
            height={props.height}
        >
            <path
                id="XMLID_6_"
                d="M 131.319 207.992 C 131.319 187.768 147.59 171.497 167.814 171.497 L 214.1 171.497 L 214.1 149.067 L 167.814 149.067 C 135.272 149.067 108.889 175.45 108.889 207.992 C 108.889 240.534 135.272 266.918 167.814 266.918 L 214.1 266.918 L 214.1 244.487 L 167.814 244.487 C 147.59 244.487 131.319 228.216 131.319 207.992 Z M 176.134 219.575 L 276.978 219.575 L 276.978 196.455 L 176.134 196.455 L 176.134 219.575 Z M 285.297 149.435 L 239.012 149.435 L 239.012 171.865 L 285.297 171.865 C 305.521 171.865 321.792 188.136 321.792 208.36 C 321.792 228.584 305.521 244.855 285.297 244.855 L 239.012 244.855 L 239.012 267.285 L 285.297 267.285 C 317.839 267.285 344.222 240.902 344.222 208.36 C 344.222 175.818 317.472 149.435 285.297 149.435 Z"
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                transform="matrix(1, 0, 0, 1, 36.777782, -47.111092)"
            />
        </svg>
    );
};

export const SpamIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width={props.width || 24}
            height={props.height || 24}
        >
            <g data-name="Layer 57" id="Layer_57">
                <path
                    fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                    d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"
                />
                <path
                    fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                    d="M16,24a2,2,0,1,1,2-2A2,2,0,0,1,16,24Zm0-2Z"
                />
                <path
                    fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                    d="M16,18a1,1,0,0,1-1-1V8a1,1,0,0,1,2,0v9A1,1,0,0,1,16,18Z"
                />
            </g>
        </svg>
    );
};

export const SendQAIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 422.853 422.853"
            enableBackground="new 0 0 422.853 422.853;"
        >
            <g>
                <path
                    d="M355.142,217.766l33.828,26.008V37.36c0-2.337-1.892-4.23-4.222-4.23H4.224C1.894,33.13,0,35.023,0,37.36v262.125
		c0,2.342,1.894,4.235,4.224,4.235h167.849v-29.462c0-1.488,0.196-2.933,0.437-4.36H33.821V83.417l158.163,115.99
		c1.503,1.086,3.516,1.086,5.005,0l158.167-115.99v134.349H355.142z M194.49,159.286L68.578,66.949h251.817L194.49,159.286z
		 M422.853,303.72c0,1.335-0.624,2.615-1.686,3.437l-106.236,81.674c-0.784,0.597-1.727,0.893-2.644,0.893
		c-0.919,0-1.84-0.29-2.617-0.87c-1.552-1.158-2.135-3.239-1.412-5.051l21.353-54.085H204.415c-2.399,0-4.332-1.932-4.332-4.328
		v-43.333c0-2.391,1.937-4.328,4.332-4.328h125.196l-21.353-54.083c-0.723-1.812-0.14-3.882,1.412-5.053
		c1.554-1.171,3.71-1.171,5.261,0.027l106.242,81.675C422.229,301.105,422.853,302.363,422.853,303.72z"
                    fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                />
            </g>
        </svg>
    );
};

export const DownloadIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path
                d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7 7-7Z"
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
            />
        </svg>
    );
};

export const NewDownloadIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            fill="none"
            viewBox="0 0 16 16"
        >
            <g clipPath="url(#clip0_1007_1375)">
                <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
                <g fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                    clipPath="url(#clip1_1007_1375)">
                    <path d="M.5 9.9a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 011 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z"></path>
                    <path d="M7.646 11.853a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.292V1.5a.5.5 0 00-1 0v8.793L5.354 8.145a.5.5 0 10-.708.708l3 3z"></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1007_1375">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
                <clipPath id="clip1_1007_1375">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>


    );
};

export const VerticalEllipsisIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path d="M12 16a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2 2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2 2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2 2 2 0 0 1 2-2Z" />
        </svg>
    );
};

export const ClearFilterIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill={props.color}
            viewBox="0 0 16 16"
        >
            <path
                fill={props.color}
                d="M10 12.6l.7.7 1.6-1.6 1.6 1.6.8-.7L13 11l1.7-1.6-.8-.8-1.6 1.7-1.6-1.7-.7.8 1.6 1.6-1.6 1.6zM1 4h14V3H1v1zm0 3h14V6H1v1zm8 2.5V9H1v1h8v-.5zM9 13v-1H1v1h8z"
            ></path>
        </svg>
    );
};

export const PreviewIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            width={props.width}
            height={props.height}
            fill={props.color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M16 8C16 8 13 2.5 8 2.5C3 2.5 0 8 0 8C0 8 3 13.5 8 13.5C13 13.5 16 8 16 8ZM1.173 8C1.65651 7.26512 2.21264 6.58069 2.833 5.957C4.12 4.668 5.88 3.5 8 3.5C10.12 3.5 11.879 4.668 13.168 5.957C13.7884 6.58069 14.3445 7.26512 14.828 8C14.77 8.087 14.706 8.183 14.633 8.288C14.298 8.768 13.803 9.408 13.168 10.043C11.879 11.332 10.119 12.5 8 12.5C5.88 12.5 4.121 11.332 2.832 10.043C2.21165 9.41931 1.65652 8.73487 1.173 8Z"
            ></path>
            <path
                d="M8 5.5C7.33696 5.5 6.70107 5.76339 6.23223 6.23223C5.76339 6.70107 5.5 7.33696 5.5 8C5.5 8.66304 5.76339 9.29893 6.23223 9.76777C6.70107 10.2366 7.33696 10.5 8 10.5C8.66304 10.5 9.29893 10.2366 9.76777 9.76777C10.2366 9.29893 10.5 8.66304 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5ZM4.5 8C4.5 7.07174 4.86875 6.1815 5.52513 5.52513C6.1815 4.86875 7.07174 4.5 8 4.5C8.92826 4.5 9.8185 4.86875 10.4749 5.52513C11.1313 6.1815 11.5 7.07174 11.5 8C11.5 8.92826 11.1313 9.8185 10.4749 10.4749C9.8185 11.1313 8.92826 11.5 8 11.5C7.07174 11.5 6.1815 11.1313 5.52513 10.4749C4.86875 9.8185 4.5 8.92826 4.5 8Z"
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
            ></path>
        </svg>
    );
};

export const ShareFolderGridIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M13.5 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM11 2.5a2.5 2.5 0 11.603 1.628l-6.718 3.12a2.499 2.499 0 010 1.504l6.718 3.12a2.5 2.5 0 11-.488.876l-6.718-3.12a2.5 2.5 0 110-3.256l6.718-3.12A2.5 2.5 0 0111 2.5zm-8.5 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm11 5.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
            ></path>
        </svg>
    );
};

export const DropOffFolderGridIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={props.disabled ? ThemeConstants.ICON_DISABLE_COLOR : props.color}
                d="M3.75 0a1 1 0 00-.8.4L.1 4.2a.5.5 0 00-.1.3V15a1 1 0 001 1h14a1 1 0 001-1V4.5a.5.5 0 00-.1-.3L13.05.4a1 1 0 00-.8-.4h-8.5zM15 4.667V5H1v-.333L1.5 4h6V1h1v3h6l.5.667z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.5"
                d="M7.823 12.177a.25.25 0 00.354 0l1.5-1.5a.25.25 0 00-.354-.354L8.25 11.396V7a.25.25 0 00-.5 0v4.396l-1.073-1.073a.25.25 0 00-.354.354l1.5 1.5z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export const AddFolderIcon: React.FC<IToolbarIconsProps> = props => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.width ?? "16"}
        height={props?.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
      >
        <g fill={props?.color ?? "#0973ba"} clipPath="url(#clip0_2203_448500)">
          <path d="M.5 3l.04.87a1.99 1.99 0 00-.342 1.311l.637 7A2 2 0 002.826 14H9v-1H2.826a1 1 0 01-.995-.91l-.637-7A1 1 0 012.19 4h11.62a1 1 0 01.996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0013.81 3H9.828a2 2 0 01-1.414-.586l-.828-.828A2 2 0 006.172 1H2.5a2 2 0 00-2 2zm5.672-1a1 1 0 01.707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 011-.98h3.672z"></path>
          <path d="M13.5 10a.5.5 0 01.5.5V12h1.5a.5.5 0 010 1H14v1.5a.5.5 0 01-1 0V13h-1.5a.5.5 0 010-1H13v-1.5a.5.5 0 01.5-.5z"></path>
        </g>
        <defs>
          <clipPath id="clip0_2203_448500">
            <path fill="#fff" d="M0 0H16V16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
};
