export enum actionTypes {
    REQUEST_TEMPLATE_LIST = "request_template_list",
    RECEIVED_TEMPLATE_LIST = "received_template_list",
    COMPLETED_TEMPLATE_LIST = "completed_template_list",

    DELETE_TEMPLATE_REQUEST = "delete_template_request",
    DELETE_TEMPLATE_COMPLETED = "delete_template_completed",

    REQUEST_TEMPLATE_DETAIL = "request_template_detail",
    RECEIVED_TEMPLATE_DETAIL = "received_template_detail",
    COMPLETED_TEMPLATE_DETAIL = "completed_template_detail",

    ADD_EDIT_TEMPLATE_REQUEST = "add_edit_template_request",
    ADD_EDIT_TEMPLATE_REQUEST_COMPLETED = "add_edit_template_request_completed",
}
