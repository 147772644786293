import React from "react";

import { IFileUploadModel } from "./AttachmentUploader";
import { FileFormatIcon } from "../../../svg/FileIconCollection";
import { getFileExtension } from "../../../../helper/Validations";
import { formatBytes } from "../../../../helper/HelperFunctions";

interface IAttachmentListItemProps {
  attachment: IFileUploadModel;
  onRemoveAttachment: (guid: string, fileId?: number) => void;
}
const AttachmentListItem: React.FC<IAttachmentListItemProps> = (props) => {
  const fileName = props.attachment.displayName ?? props.attachment.file.name;
  
  return (
    <div className="attachment-card" style={{ width: "49%" }}>
      <div className="attachment-icon">
        <FileFormatIcon
          extension={getFileExtension(props.attachment.file.name)}
          width={40}
          height={40}
        ></FileFormatIcon>
      </div>
      <div
        className="attachment-detail ellipsis"
        title={fileName}
      >
        {fileName}
        <div>
          {props.attachment.fileSize
            ? formatBytes(props.attachment.fileSize)
            : formatBytes(props.attachment.file.size)}
        </div>
      </div>
      <div className="attachment-remove">
        {props.attachment.isUploaded ? (
          <span
            className="fas fa-times"
            onClick={() => props.onRemoveAttachment(props.attachment.guid, props.attachment?.fileId)}
          ></span>
        ) : (
          <span className="fas fa-spin fa-circle-notch"></span>
        )}
      </div>
      {props.attachment.uploadProgress < 1 && (
        <div
          className="upload-progress-bar"
          style={{ width: `${props.attachment.uploadProgress * 100}%` }}
        ></div>
      )}
    </div>
  );
};

export default AttachmentListItem;
