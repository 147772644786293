import axios from "axios";
import {
    completedDocumentCategoryAction,
    completedDocumentCategoryDropdownAction,
    createDocumentCategoryAction,
    deleteDocumentCategoryAction,
    receivedDocumentCategoryAction,
    receivedDocumentCategoryDropdownAction,
    requestDocumentCategoryAction,
    requestDocumentCategoryDropdownAction,
    updateDocumentCategoryAction,
} from "../actions/documentCategoryAction";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { IDocumentCategoryModel, IDocumentCategoryRequest } from "../core/viewModels/settings/DocumentCategory";
import { actionTypes } from "../types/documentCategoryTypes";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { AppNotifier } from "../helper/AppNotifier";
import { DocumentCategoryConstants } from "../helper/Constants";

export type KnownActions =
    | requestDocumentCategoryAction
    | receivedDocumentCategoryAction
    | createDocumentCategoryAction
    | completedDocumentCategoryAction
    | updateDocumentCategoryAction
    | deleteDocumentCategoryAction
    | requestDocumentCategoryDropdownAction
    | receivedDocumentCategoryDropdownAction
    | completedDocumentCategoryDropdownAction
    | NotificationAction;

export const getDocumentCategory =
    (payload: IDocumentCategoryRequest, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_DOCUMENT_CATEGORY });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentCategory/All`, payload)
            .then(response => {
                const data = response.data as IDocumentCategoryModel[];
                dispatch({ type: actionTypes.RECEIVE_DOCUMENT_CATEGORY, data });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_CATEGORY });
            });
    };

export const getDocumentCategoriesDropdown =
    (callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_DOCUMENT_CATEGORY_DROPDOWN });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/DocumentCategory/dropdown`)
            .then(response => {
                const data = response.data as IDocumentCategoryModel[];
                dispatch({ type: actionTypes.RECEIVE_DOCUMENT_CATEGORY_DROPDOWN, data });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_CATEGORY_DROPDOWN });
            });
    };

export const addDocumentCategory =
    (payload: IDocumentCategoryModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.CREATE_DOCUMENT_CATEGORY });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentCategory`, payload)
            .then(() => {
                AppNotifier.Success(DocumentCategoryConstants.CATEGORY_ADD_SUCCESS_MESSAGE);
                if (callback) callback();
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(DocumentCategoryConstants.CATEGORY_ALREADY_EXSITS)) {
                    AppNotifier.Error(DocumentCategoryConstants.CATEGORY_ALREADY_EXSIST_MESSAGE);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_CATEGORY });
            });
    };

export const deleteDocumentCategory =
    (payload: number[], callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.DELETE_DOCUMENT_CATEGORY });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/DocumentCategory/Delete`, { data: payload })
            .then(() => {
                const message =
                    payload.length > 1
                        ? DocumentCategoryConstants.CATEGORIES_DELETE_SUCCESS_MESSAGE
                        : DocumentCategoryConstants.CATEGORY_DELETE_SUCCESS_MESSAGE;
                AppNotifier.Success(message);
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_CATEGORY });
            });
    };

export const updateDocumentCategory =
    (payload: IDocumentCategoryModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.UPDATE_DOCUMENT_CATEGORY });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/DocumentCategory`, payload)
            .then(() => {
                AppNotifier.Success(DocumentCategoryConstants.CATEGORY_EDIT_SUCCESS_MESSAGE);
                if (callback) callback();
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(DocumentCategoryConstants.CATEGORY_ALREADY_EXSITS)) {
                    AppNotifier.Error(DocumentCategoryConstants.CATEGORY_ALREADY_EXSIST_MESSAGE);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DOCUMENT_CATEGORY });
            });
    };
