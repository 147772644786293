import React, { useEffect, useState } from "react";
import { Modal, Alert } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { IFolderModel } from "../../../../../core/viewModels/folder/FolderModel";
import { FolderEventType, FolderToolbarConstants, MessageToolbarConstants } from "../../../../../helper/Constants";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { fetchFoldersTree, fetchRetentionPeriods, moveFolders } from "../../../../../actionCreators/folderActionCreator";
import TreeList from "../../../../common/ui/TreeList/TreeList";
import { ArchiveFolderType, FolderType } from "../../../../../core/common/enums";
import { IReceivedMessageModel } from "../../../../../core/viewModels/message/ReceivedMessageModel";
import { ISentMessageModel } from "../../../../../core/viewModels/message/SentMessageModel";
import { AutomationIdConstants } from "../../../../../helper/AutomationConstants";
import { CreateFolderModal } from "./CreateFolderModal";
import { getRetentionOptions } from "../../../../../helper/HelperFunctions";
import { Option } from "react-dropdown";

interface IMoveFolderModalProps {
    showState: boolean;
    selectedFolders: IFolderModel[];
    folderType: FolderType;
    isMovingFolder: boolean;
    selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[] | any;
    onHide: () => void;
    onSubmit: () => void;
    setMoveToFolder?: (folder: IFolderModel) => void;
    onError: (isReload: boolean) => void;
    moveFolderResourceId?: string;
}

export const MoveFolderModal: React.FC<IMoveFolderModalProps> = props => {
    const { isMovingFolder, folderType, selectedFolders, moveFolderResourceId } = props;
    const dispatch = useDispatch();
    const [moveToFolder, setMoveToFolder] = useState<IFolderModel | undefined>();
    const [showMoveToFolderWarning, toggleMoveToFolderWarning] = useState(false);
    const [allowFormSubmit, toggleAllowFormSubmit] = useState(true);
    const [warningMessageText, setWarningMessageText] = useState("");
    const [showAddFolderModal, setShowAddFolderModal] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const foldersTreeData = useSelector((appState: ApplicationState) => appState.folderState.foldersTreeData);
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);

    useEffect(() => {
        dispatch(fetchRetentionPeriods());
    }, []);

    useEffect(() => {
        if (moveToFolder) {
            moveToFolder?.folderId === 0
                ? setRetentionPeriods(getRetentionOptions(retentionData.retentionPeriods))
                : setRetentionPeriods(
                    getRetentionOptions(
                        retentionData.retentionPeriods.filter(rp => rp.days <= moveToFolder.expiresDays)
                    )
                );
        }
    }, [retentionData.retentionPeriods.length, moveToFolder?.folderId]);

    const checkFolderExpiry = (destinationFolder: IFolderModel): boolean => {
        if (isMovingFolder) {
            // Check retention period of the folders
            if (!props.selectedFolders.every(f => f.expiresDays <= destinationFolder.expiresDays)) {
                setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_EXPIRY_WARNING);
                toggleMoveToFolderWarning(true);
                toggleAllowFormSubmit(false);
                return true;
            }
            toggleAllowFormSubmit(false);
            toggleMoveToFolderWarning(false);
        } else {
            // Check retention period of the messages
            if (props.selectedMessages) {
                let isMessageExpired = false;
                let isFolderExpired = false;
                props.selectedMessages.some((f: any) => {
                    if (destinationFolder.expiresDays > f.expiresDays) {
                        isMessageExpired = true;
                        setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_GREATER_EXPIRY_WARNING_FOR_MESSAGE);
                        toggleMoveToFolderWarning(true);
                        toggleAllowFormSubmit(true);
                        return true;
                    }
                    if (destinationFolder.expiresDays < f.expiresDays) {
                        isFolderExpired = false;
                        setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_EXPIRY_WARNING_FOR_MESSAGE);
                        toggleMoveToFolderWarning(true);
                        toggleAllowFormSubmit(false);
                    }
                    if (destinationFolder.expiresDays == f.expiresDays) {
                        toggleAllowFormSubmit(false);
                        toggleMoveToFolderWarning(false);
                    }

                });

                return (isMessageExpired ? true : isFolderExpired ? false : true);
            }
        }
        return false;
    };

    const handleFolderSelection = (folder: IFolderModel) => {
        let continueSelection = true;

        if (!isMovingFolder) {
            // Scenarios while moving messages

            // Allow if folder is empty
            if (folder.subFolders?.length === 0) {
                continueSelection = true;
            }

            // Allow if folder has messages
            if (folder.folderType == ArchiveFolderType.MESSAGE) {
                continueSelection = true;
            }

            // Don't Allow for folder with multiple sub folders
            if (folder?.subFolders && folder?.subFolders?.length > 0) {
                toggleMoveToFolderWarning(true);
                setWarningMessageText(MessageToolbarConstants.MESSAGE_MOVING_MULTIPLE_FOLDERS_WARNING);
                continueSelection = false;
            }

            // Don't Allow for moving to same folder
            if (props.selectedMessages && folder.folderId == props.selectedMessages[0].folderId) {
                continueSelection = false;
                return;
            }
        }

        if (isMovingFolder) {
            // Scenarios while moving folder

            // Allow if folder is empty
            if (folder.subFolders?.length === 0) {
                continueSelection = true;
            }

            // Don't allow moving to folders containing messages
            if (folder.folderType == ArchiveFolderType.MESSAGE) {
                return;
            }

            // Don't Allow for moving to same folder
            if (
                props.selectedFolders &&
                props.selectedFolders.length > 0 &&
                props.selectedFolders[0].folderId == folder.folderId
            ) {
                continueSelection = false;
                return;
            }

            // Don't Allow for moving to parent folder
            if (props.selectedFolders.length > 0 && props.selectedFolders[0].parentId === folder.folderId) {
                toggleMoveToFolderWarning(true);
                setWarningMessageText(FolderToolbarConstants.FOLDER_MOVING_TO_PARENT_FOLDER_WARNING);
                continueSelection = false;
            }

            // Don't allow for moving to sub folders of selected folder
            if (
                selectedFolders &&
                selectedFolders.length > 0 &&
                selectedFolders.some(node => node.path && folder.path?.includes(node.path))
            ) {
                continueSelection = false;
                return;
            }
        }


        if (!continueSelection) {
            toggleAllowFormSubmit(true);
        }

        if (continueSelection) {
            checkFolderExpiry(folder!);
        }

        const moveFolder = { ...folder };
        delete moveFolder.subFolders;
        setMoveToFolder(moveFolder);
        if (props.setMoveToFolder) {
            props.setMoveToFolder(moveFolder);
        }
    };

    const resetMoveToFolderPopup = () => {
        toggleMoveToFolderWarning(false);
        setMoveToFolder(undefined);
        props.onHide();
    };
    const handleOnSubmitMoveToFolder = () => {
        if (isMovingFolder && moveToFolder) {
            dispatch(
                moveFolders(
                    props.selectedFolders.map(f => f.folderId),
                    props.selectedFolders,
                    moveToFolder,
                    (result: boolean) => {
                        if (result) {
                            const path = moveToFolder.path || "";
                            AppNotifier.Success(
                                props.selectedFolders.length > 1
                                    ? FolderToolbarConstants.MOVE_TO_FOLDERS_SUCCESS.replace(
                                        "<destination folder>",
                                        path
                                    )
                                    : FolderToolbarConstants.MOVE_TO_FOLDER_SUCCESS.replace(
                                        "<destination folder>",
                                        path
                                    )
                            );
                        } else {
                            props.onError(true);
                        }

                        resetMoveToFolderPopup();
                        props.onSubmit();
                    },
                    moveFolderResourceId
                )
            );
        } else {
            if (props.setMoveToFolder && moveToFolder) {
                props.setMoveToFolder(moveToFolder);
                props.onSubmit();
            }
        }
    };

    const handleOnCreateFolder = (folder?: IFolderModel) => {
        dispatch(fetchFoldersTree([], [folderType]));
        setShowAddFolderModal(false);
        if (folder) {
            handleFolderSelection(folder);
        }
    };

    return (
        <>
            <Modal
                className="move-folder-modal-container"
                show={props.showState}
                onHide={resetMoveToFolderPopup}
                onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                }}
            >
                <ModalHeader closeButton closeVariant="white">
                    <Modal.Title>Move To</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <div>
                        <TreeList
                            isMovingFolder={isMovingFolder}
                            folderType={folderType}
                            handleFolderSelection={handleFolderSelection}
                            setShowAddFolderModal={setShowAddFolderModal}
                            selectedMessages={props.selectedMessages}
                            selectedFolders={props.selectedFolders}
                            destinationFolder={moveToFolder}
                            foldersTreeData={foldersTreeData.treeFolders}
                        />
                    </div>
                    <Alert show={showMoveToFolderWarning} variant="warning">
                        {warningMessageText}
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-light"
                        data-auto-test={AutomationIdConstants.moveToModal.MoveToCancelBtn}
                        onClick={resetMoveToFolderPopup}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={allowFormSubmit}
                        data-auto-test={AutomationIdConstants.moveToModal.MoveToOkBtn}
                        onClick={handleOnSubmitMoveToFolder}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
            {showAddFolderModal &&
                <CreateFolderModal
                    show={showAddFolderModal}
                    onHide={() => setShowAddFolderModal(false)}
                    onSubmit={handleOnCreateFolder}
                    currentFolder={moveToFolder!}
                    modalType={FolderEventType.Create}
                    retentionOptions={retentionPeriods}
                    isSharedFolderDisabled={true}
                />
            }
        </>
    );
};
