import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { debounce } from "throttle-debounce";

import DefaultPagination from "../../common/ui/DefaultPagination";
import { DocumentToolbar } from "./DocumentToolbar";
import { IPagination } from "../../../core/viewModels/message/SentMessageModel";
import {
    ArchiveConstants,
    DEFAULT_PAGE_SIZE,
    DocumentRequestConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
    initialPagination,
} from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { DetailedStatus } from "../../../core/common/enums";
import { DocumentRequestListItem } from "./DocumentRequestListItem";
import {
    IDocumentRequestListModel,
    IDocumentRequestSearchModel,
    initialDocumentSearchState,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { listingDeleteDocumentRequest } from "../../../actionCreators/documentRequestActionCreators";
import { RefreshButtonIcon, SearchIcon } from "../../svg/IconCollection";
import { FiltersCustomComponent } from "../../common/ui/Filters/FiltersCustomComponent";
import { getDRSortAndFilterOptions } from "../../../helper/HelperFunctions";
import { ColorConstants } from "../../../assets/custom/colors";

interface IDocumentRequestListProps {
    documents: IDocumentRequestListModel[];
    selectedDocumentList: IDocumentRequestListModel[];
    selectedDocument: IDocumentRequestListModel;
    totalDocuments: number;
    pagination: IPagination;
    resetSortAndFilterOptions: boolean;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setSelectedDocument: Dispatch<SetStateAction<IDocumentRequestListModel>>;
    getDocumentDetail: (documentRequestId: number) => void;
    handleDocumentResendAccessLink: (documentRequestIds: number[], isListingAction?: boolean) => void;
    handleDocumentSendReminder: (documentRequestIds: number[], isListingAction: boolean, resourceId: string) => void;
    fetchDocumentRequests: (documentRequestSearchModel: IDocumentRequestSearchModel) => void;
    updateSelectedDocumentList: Dispatch<SetStateAction<IDocumentRequestListModel[]>>;
    resetSelection: (drlRefresh?:boolean) => void;
}

export const DocumentRequestList: React.FC<IDocumentRequestListProps> = ({
    documents,
    totalDocuments,
    selectedDocument,
    setSelectedDocument,
    selectedDocumentList,
    updateSelectedDocumentList,
    pagination,
    setPagination,
    getDocumentDetail,
    fetchDocumentRequests,
    resetSelection,
    handleDocumentResendAccessLink,
    handleDocumentSendReminder,
    resetSortAndFilterOptions,
}) => {
    const dispatch = useDispatch();

    const documentState = useSelector((state: ApplicationState) => state.documentState);
    const [searchText, setSearchText] = useState<string>("");
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>(SortAndFilterConstants.ALL_STATUSES_ORDER);
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    const [currentSelectedOption, setCurrentSelectedOption] = useState<string>("");
    const [timeOut ,setActionTimeout] = useState<boolean>(false);
    const resetSortAndFilter = () => {
        setSearchText("");
        setSelectedFilterOption(SortAndFilterConstants.ALL_STATUSES_ORDER);
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    useEffect(() => {
        getData(searchText);
    }, [currentSelectedOption]);

    const getData = (value: string) => {
        const sortAndFilterOptions = getDRSortAndFilterOptions(
            currentSelectedOption,
            selectedFilterOption,
            selectedSortOption
        );

        const payload = {
            ...initialDocumentSearchState,
            taxpayerEmail: value.trim(),
            sortOrder: sortAndFilterOptions.sortOrder,
            sortColumn: sortAndFilterOptions.sortColumn,
            listingStatus: sortAndFilterOptions.listingStatus,
        };
        setPagination(initialPagination);
        fetchDocumentRequests(payload);
    };

    const debounceFn = useCallback(debounce(1000, getData), [selectedFilterOption, selectedSortOption]);

    useEffect(() => {
        const payload = {
            ...initialDocumentSearchState,
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
        };
        fetchDocumentRequests(payload);
    }, []);

    useEffect(() => {
        resetSortAndFilter();
    }, [resetSortAndFilterOptions]);

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        setSearchText(value);
        debounceFn(value);
    };

    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
        });
        const sortAndFilterOptions = getDRSortAndFilterOptions(
            currentSelectedOption,
            selectedFilterOption,
            selectedSortOption
        );
        const payload = {
            ...initialDocumentSearchState,
            pageNumber: pageNo,
            pageSize: pageSize,
            taxpayerEmail: searchText.trim(),
            sortOrder: sortAndFilterOptions.sortOrder,
            sortColumn: sortAndFilterOptions.sortColumn,
            listingStatus: sortAndFilterOptions.listingStatus,
        };
        fetchDocumentRequests(payload);
    };
    const handleOnSelect = (document: IDocumentRequestListModel, e: any) => {
        const selectedDocumentListCopy = [...selectedDocumentList];
        if (e.target.checked) {
            selectedDocumentListCopy.push(document);
        } else {
            const index = selectedDocumentListCopy.findIndex(
                item => item.documentRequestId === document.documentRequestId
            );
            if (index > -1) {
                selectedDocumentListCopy.splice(index, 1);
            }
        }
        updateSelectedDocumentList(selectedDocumentListCopy);
    };

    const handleOnSelectAll = (isSelect: boolean) => {
        if (isSelect) {
            updateSelectedDocumentList(documents);
        } else {
            updateSelectedDocumentList([]);
        }
    };
    const handleDocumentItemClick = (document: IDocumentRequestListModel) => {
        setSelectedDocument(document);
        getDocumentDetail(document.documentRequestId);
    };

    const handleDeleteDocumentRequest = () => {
        dispatch(
            listingDeleteDocumentRequest(
                selectedDocumentList,
                ResourceIdLocators.DRL.DRLListBtnDeleteRequest,
                resetSelection
            )
        );
    };

    const onClickDocumentResendAccessLink = () => {
        const documentRequestIds = selectedDocumentList.map(item => item.documentRequestId);
        handleDocumentResendAccessLink(documentRequestIds, true);
    };

    const onClickDocumentSendReminder = () => {
        const documentRequestIds: number[] = [];
        selectedDocumentList.map(item => {
            if (item.status !== DetailedStatus.COMPLETED && item.documentRequestId) {
                documentRequestIds.push(item.documentRequestId);
            }
        });
        handleDocumentSendReminder(documentRequestIds, true, ResourceIdLocators.DRL.DRLListBtnSendReminder);
    };

    const handleDRListRelaod = () => {
        if (!timeOut) {
            resetSelection(true);
            setActionTimeout(true);
            setTimeout(() => {
                setActionTimeout(false);
            }, 3000);
        }
    };

    const filtersData = [
        {
            title: SortAndFilterConstants.SORT_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
                SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
                SortAndFilterConstants.NEWEST_FIRST_ORDER,
                SortAndFilterConstants.OLDEST_FIRST_ORDER,
            ],
        },
        {
            title: SortAndFilterConstants.FILTER_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALL_STATUSES_ORDER,
                SortAndFilterConstants.COMPLETED_ORDER,
                SortAndFilterConstants.PENDING_UPLOAD_ORDER,
                SortAndFilterConstants.PARTIALLY_UPLOAD_ORDER,
                SortAndFilterConstants.OVERDUE_ORDER,
            ],
        },
    ];

    return (
        <>
            <Row className="search-header-wrapper">
                <Col sm={9} md={10} className="search-input-wrapper-col">
                    <div className="search-input-wrapper">
                        <FormControl
                            placeholder={DocumentRequestConstants.PLACEHOLDER_SEARCH_TEXT}
                            value={searchText}
                            onChange={onFilter}
                            className="search-input search-textbox"
                        />
                        <div className="search-icon">
                            <SearchIcon height={18} width={18} />
                        </div>
                    </div>
                </Col>
                <Col sm={3} md={2} className="filter-icon-container">
                    <div>
                        <FiltersCustomComponent
                            groups={filtersData}
                            selectedFilterOption={selectedFilterOption}
                            selectedSortOption={selectedSortOption}
                            iconType={SortAndFilterIconType.Filter}
                            setSelectedFilterOption={setSelectedFilterOption}
                            setSelectedSortOption={setSelectedSortOption}
                            setCurrentSelectedOption={setCurrentSelectedOption}
                        />
                    </div>
                </Col>
               <Col sm={2} md={2} className="list-refresh-icon-container">
                    <div
                        className={`list-refresh-button ${timeOut && "disabled"}`}
                        onClick={handleDRListRelaod}
                        title={ArchiveConstants.REFRESH_TITLE}   
                    >
                        <RefreshButtonIcon width={19} height={19} color={ColorConstants.grey} disabled={timeOut}/>
                    </div>
                </Col>
            </Row>

            <div className="message-list-wrapper">
                <Row>
                    <Col>
                        <DocumentToolbar
                            isAllSelected={selectedDocumentList.length === documents?.length}
                            isAnySelected={selectedDocumentList.length > 0}
                            showSelectAllCheckbox={documents && documents?.length > 0}
                            selectedDocumentList={selectedDocumentList}
                            handleOnSelectAll={handleOnSelectAll}
                            resetSelection={resetSelection}
                            handleDeleteDocumentRequest={handleDeleteDocumentRequest}
                            handleDocumentResendAccessLink={onClickDocumentResendAccessLink}
                            handleDocumentSendReminder={onClickDocumentSendReminder}
                        />
                    </Col>
                    <Col>
                        {documents && documents?.length > 0 && (
                            <DefaultPagination
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                totalRecords={totalDocuments}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Col>
                </Row>
                <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                    <div className="message-list document-list">
                        {documents && documents?.length > 0 ? (
                            documents.map((document, index) => {
                                const isChecked =
                                    selectedDocumentList.includes(document) ||
                                    selectedDocumentList.length === documents?.length;
                                const selectedDocumentId =
                                    selectedDocument.documentRequestId === 0
                                        ? documents[0].documentRequestId
                                        : selectedDocument.documentRequestId;
                                return (
                                    <DocumentRequestListItem
                                        key={index}
                                        onClickDocumentItem={handleDocumentItemClick}
                                        onClickCheckBox={handleOnSelect}
                                        document={document}
                                        index={index}
                                        isChecked={isChecked}
                                        selectedDocumentId={selectedDocumentId}
                                    />
                                );
                            })
                        ) : (
                            <div className="vertical-middle">{DocumentRequestConstants.NO_DOCUMENT_REQUESTS}</div>
                        )}
                    </div>
                    <Loader loading={documentState.isLoading} />
                </LoadingOverlay>
            </div>
        </>
    );
};
