import React, { useEffect, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Accordion from "react-bootstrap/Accordion";
import Dropdown, { Option } from "react-dropdown";

import { IRetentionPeriod } from "../../../../core/viewModels/company/settings/RetentionSettings";
import {
  getCompanyAuthMethods,
  getRetentionOptions,
} from "../../../../helper/HelperFunctions";
import { AuthenticationMethod, MessageActions } from "../../../../core/common/enums";
import RecipientQuestionAnswerRow from "./RecipientQuestionAnswerRow";
import { IMessageSecurityQuestion } from "../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { IRecipientModel } from "../../../../core/viewModels/message/RecipientModel";
import AddSecurityQuestion from "./AddQuestionAnswer";
import { Col, Row } from "react-bootstrap";
import { setDefaultAuthMethod } from "../../../../actionCreators/composeActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { fetchUserSettings } from "../../../../actionCreators/userSettingsActionCreators";
import { AdminSettingsSideMenuIcon } from "../../../svg/AdminSettingsIconsCollection";
import { Colors } from "../../../../styles/colors";

interface IComposeOptionsProps {
  authRetentions: { isLoading: boolean; data: IRetentionPeriod[] };
  securityQuestions: { isLoading: boolean; data: IMessageSecurityQuestion[] };
  isAuthMethodLoading: boolean;
  recipientQuestionAnswer: IRecipientModel[];
  selectedAuthMethod: AuthenticationMethod;
  fromComposeMessage: boolean;
  selectedRetention: number;
  messageAction: MessageActions;
  isAuthMethodUpdated: boolean;
  setIsAuthMethodUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeRetention: (e: any) => void;
  onChangeAuthentication: (e: any) => void;
  onAddRow: () => void;
  onRemoveRow: (recipient: IRecipientModel) => void;
  onEditRow: (recipient: IRecipientModel) => void;
  onSaveRow: (recipient: IRecipientModel, data: IRecipientModel) => void;
  addSecurityQuestion?: (
    securityQuestion: IMessageSecurityQuestion,
    isUserSecurityQuestion: boolean,
    callback?: any
  ) => void;
  reloadMessageSecurityQuestions: () => void;
}

const ComposeOptions: React.FC<IComposeOptionsProps> = (props) => {
  const userSettingsStore = useSelector((state: ApplicationState) => state.userSettingsState);
  const { messageOptions: messageOptionsSettings } = useSelector(
    (appState: ApplicationState) => appState.adminSettingsState
  );
  const isUserAllowedToAddSecurityQuestion = useSelector(
    (state: ApplicationState) =>
      state.adminSettingsState.messageSecuritySettings
        .isUserAllowedToAddSecurityQuestion
  );
  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    props.selectedAuthMethod
  );
  const dispatch = useDispatch();
  const showDefault = userSettingsStore && userSettingsStore.userSettings ? selectedAuthMethod == userSettingsStore?.userSettings?.authenticationMethodSettings?.defaultAuthenticationMethod : "";
  const authenticationMethodOptions = messageOptionsSettings.authenticationSettings.authenticationMethods.map((value) => {
    const option = getCompanyAuthMethods().filter(method => method.value === value.toString());
    return option[0];
  });
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    if (isComponentMounted) {
      props.onChangeAuthentication({ value: selectedAuthMethod });
    }
  }, [selectedAuthMethod]);

  useEffect(() => {
    if (userSettingsStore.userSettings && !props.isAuthMethodUpdated
      && props.messageAction !== MessageActions.Draft) {
      const methodOptions = messageOptionsSettings.authenticationSettings.authenticationMethods;
      const userDefaultMethod = userSettingsStore.userSettings.authenticationMethodSettings.defaultAuthenticationMethod;
      const adminDefaultMethod = messageOptionsSettings.authenticationSettings.defaultAuthenticationMethod;
      const allowUserToChange = messageOptionsSettings.authenticationSettings.allowEmployeesToChangeDefaultMethod;
      if (methodOptions.includes(userDefaultMethod) && allowUserToChange) {
        setSelectedAuthMethod(userDefaultMethod);
      } else {
        setSelectedAuthMethod(adminDefaultMethod);
      }
    }
  }, [userSettingsStore.userSettings?.authenticationMethodSettings,
  messageOptionsSettings.authenticationSettings]);

  const handleSetAsDefault = (event: any) => {
    event.preventDefault();
    dispatch(
      setDefaultAuthMethod(selectedAuthMethod, callbackSelectedAuthMethod)
    );
  };

  const callbackSelectedAuthMethod = (
  ) => {
    dispatch(fetchUserSettings());
  };

  const handleOnAuthChange = (e: any) => {
    setSelectedAuthMethod(+e.value);
    props.setIsAuthMethodUpdated(true);
  };

  const handleValidateDuplicateRecipient = (
    email: string,
    index?: number
  ): boolean => {
    return props.recipientQuestionAnswer.find(
      (x) => x.emailAddress === email && x.index !== index
    )
      ? true
      : false;
  };

  const retentionOptions: Option[] = !props.authRetentions.isLoading
    ? getRetentionOptions(props.authRetentions.data)
    : [];

  const getSelectedOption = (): Option | string => {
    const selectedValue = props.selectedRetention.toString();
    return retentionOptions.find((ro) => ro.value == selectedValue) ?? "";
  };

  return (
    <div className="compose-options padding-top-15-px">
      <div className="compose-options-field">
        <div className="form-label-blue">
          <AdminSettingsSideMenuIcon
            icon={"MessageOptionsRetentionIcon"}
            color={Colors.white}
            width={18}
            height={18}
          />
          <span>Retention Period
          </span>
        </div>
        <LoadingOverlay>
          <Dropdown
            className="simple-dropdown"
            options={retentionOptions}
            onChange={props.onChangeRetention}
            placeholder="Select Retention Period"
            value={getSelectedOption()}
          />
          <Loader
            classNamePrefix="sm"
            text=""
            loading={props.authRetentions.isLoading}
          />
        </LoadingOverlay>
      </div>
      <br />
      <div className="div-flex">
        <div className="compose-options-field">
          <div className="form-label-blue">
            <AdminSettingsSideMenuIcon
              icon={"MessageOptionsAuthenticationIcon"}
              color={Colors.white}
              width={18}
              height={18}
            />
            <span>Authentication Method</span>
          </div>
          <LoadingOverlay>
            <Dropdown
              className={`simple-dropdown ${!messageOptionsSettings.authenticationSettings.allowEmployeesToChangeAuthMethod ? "disabled" : ""
                }`}
              disabled={!messageOptionsSettings.authenticationSettings.allowEmployeesToChangeAuthMethod}
              options={authenticationMethodOptions}
              onChange={handleOnAuthChange}
              placeholder="Select Authentication Method"
              value={selectedAuthMethod.toString()}
            />
            <Loader
              classNamePrefix="sm"
              text=""
              loading={props.isAuthMethodLoading}
            />
          </LoadingOverlay>
        </div>
        {showDefault ? (
          <button
            className="button-primary-green-rounded margin-left-4"
            disabled={true}
          >
            Default
          </button>
        ) : (
          <button
            className="button-primary-blue-2-rounded margin-left-4"
            disabled={!messageOptionsSettings.authenticationSettings.allowEmployeesToChangeDefaultMethod}
            onClick={(event) => handleSetAsDefault(event)}
          >
            Set as Default
          </button>
        )}
      </div>
      <br />
      {selectedAuthMethod === AuthenticationMethod.QUESTIONANSWER && (
        <>
          {isUserAllowedToAddSecurityQuestion && (
            <AddSecurityQuestion
              questions={props.securityQuestions}
              addSecurityQuestion={props.addSecurityQuestion}
              reloadMessageSecurityQuestions={
                props.reloadMessageSecurityQuestions
              }
            />
          )}
          <Accordion
            defaultActiveKey="0"
            className="accordion-with-crud"
            defaultChecked={false}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className="heading-blue-2">Set Questions &amp; Answers</span>
              </Accordion.Header>
              <Accordion.Body>
                <LoadingOverlay style={{ height: "100%" }}>
                  <div className="qna-wrapper">
                    {props.recipientQuestionAnswer.map((item, i, arr) => {
                      return (
                        <RecipientQuestionAnswerRow
                          questions={props.securityQuestions.data}
                          messageAction={props.messageAction}
                          data={item}
                          isLastRow={arr.length === 1}
                          totalRows={arr.length}
                          key={"recipient_" + i}
                          onAddRow={props.onAddRow}
                          onRemoveRow={props.onRemoveRow}
                          onEditRow={props.onEditRow}
                          onSaveRow={props.onSaveRow}
                          validateDuplicateRecipient={
                            handleValidateDuplicateRecipient
                          }
                        />
                      );
                    })}
                    <Row className="margin-0-px">
                      <Col xs={3}>
                        <a onClick={props.onAddRow} className="heading-blue-2 add-recipient">
                          Add New Recipient&nbsp;
                          <span className="fas fa-user-plus"></span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                  <Loader
                    loading={
                      props.securityQuestions.isLoading ||
                      props.securityQuestions.data.length == 0
                    }
                  />
                </LoadingOverlay>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </div>
  );
};

export default ComposeOptions;
