import { SortOrder, TemplateColumnName } from "../../common/enums";

export interface IDocumentRequestTemplateModel {
    documentRequestTemplate: IDocumentRequestTemplate;
    files: IDocumentRequestTemplateFile[];
}

export interface IDocumentRequestTemplate {
    documentRequestTemplateId: number;
    name: string;
    description: string;
    isDeleted: boolean;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: number;
    year: number;
    dueDate: Date | null;
    count: number;
}

export interface IDocumentRequestTemplateFile {
    name: string;
    description: string;
    documentCategory?: {
        documentCategoryId: number;
        name: string;
    };
}

export interface ITemplateTableDataRequestModel {
    sortColumn: TemplateColumnName;
    sortOrder: SortOrder;
    pageNumber: number;
    pageSize: number;
    searchString: string;
}

export const initialTemplateTableRequestObject: ITemplateTableDataRequestModel = {
    sortColumn: TemplateColumnName.CREATEDDATE,
    sortOrder: SortOrder.DESC,
    pageNumber: 1,
    pageSize: 20,
    searchString: "",
};

export const initialTemplateFormState: IDocumentRequestTemplateModel = {
    documentRequestTemplate: {
        documentRequestTemplateId: 0,
        name: "",
        description: "",
        isDeleted: false,
        createdDate: new Date(),
        modifiedDate: new Date(),
        createdBy: 0,
        year: new Date().getFullYear(),
        count: 0,
        dueDate: null,
    },
    files: [
        {
            name: "",
            description: "",
        },
    ],
};
