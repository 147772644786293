import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import axios, { AxiosResponse } from "axios";
import { IWidgetData, KnownActions } from "../actions/layoutActions";
import { StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { actionTypes } from "../types/layoutTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";

export const getHelpUrl = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: actionTypes.REQUEST_HELP_URL,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Helper/GetHelpUrl`)
        .then((response: AxiosResponse<string>) => {
            const { data } = response;
            dispatch({
                type: actionTypes.RECEIVE_HELP_URL,
                payload: data,
            });
            window.open(data);
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: actionTypes.COMPLETE_HELP_URL,
            });
        });
};

export const getWidgetData = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: actionTypes.REQUEST_PRODUCT_URLS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL_SUITE}/api/menu/widget-menu/${ProductType.Exchange}`)
        .then((response: AxiosResponse<IWidgetData[]>) => {
            const { data } = response;

            dispatch({
                type: actionTypes.RECEIVE_PRODUCT_URLS,
                payload: data,
            });
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: actionTypes.COMPLETE_PRODUCT_URLS,
            });
        });
};

export const getLeftSideMenuData = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: actionTypes.REQUEST_SIDE_MENU_DATA,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL_SUITE}/api/menu/product-left-menu/${ProductType.Exchange}`)
        .then((response: AxiosResponse<[IMenuSection]>) => {
            const { data } = response;

            dispatch({
                type: actionTypes.RECEIVE_SIDE_MENU_DATA,
                payload: data,
            });
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: actionTypes.COMPLETE_SIDE_MENU_DATA,
            });
        });
};

export const resetUserCache = (callback?: any): AppThunkAction<KnownActions> => () => {
    axios
        .get(
            `${process.env.REACT_APP_API_URL_SUITE}/api/user-management/reset-user-status-cache/${ProductType.Exchange}`
        )
        .then((response: AxiosResponse) => {
            console.log(response.data);
            if (callback) callback();
        })
        .catch(function (error) {
            console.log(error.data);
        });

};

export const getSuiteUrl = (): AppThunkAction<KnownActions> => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL_SUITE}/api/helper/suite-url`)
        .then(response => {
            dispatch({ 
                type: actionTypes.RECEIVE_SUITE_URL, 
                url: response.data 
            });
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        });
};