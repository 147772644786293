import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { fetchSubFolders } from "../../../actionCreators/folderActionCreator";
import { FolderColumnName, MessageExplorerViewType, SortOrder } from "../../../core/common/enums";
import { ApplicationState } from "../../../store";
import { ArchiveFolderTable } from "./ArchiveFolderTable";
import { IRetentionPeriod } from "../../../core/viewModels/company/settings/RetentionSettings";
import { IFolderModel, rootFolder } from "../../../core/viewModels/folder/FolderModel";
import { ArchiveContext } from "./Archive";
import { CreateFolderModal } from "../../common/ui/Modals/FolderModal/CreateFolderModal";
import { getRetentionOptions } from "../../../helper/HelperFunctions";
import { ShareFolderModal } from "./ShareFolderModal";
import { FolderEventType } from "../../../helper/Constants";

interface IMessageExplorerProps {
    retentionPeriods: IRetentionPeriod[];
    isRetentionLoading: boolean;
    isMessageLoading: boolean;
    isSearched?: boolean;
    currentFolder: IFolderModel;
    searchFolderText?: string;
    reload?: boolean;
    setReload: (value: boolean) => void;
    resetSearchFolderTextAndSort: () => void;
    handleFolderClick: (folder: IFolderModel, isTitleBreadcrumb: boolean, breadCrumbArrayArchiveTable: IFolderModel[]) => void;
    goToFolder: (folderId: number, breadcrumbIndex: number, isTitleBreadcrumb: boolean) => void;
    foldersCountUnderRoot: number;

}

export const MessageExplorer: React.FC<IMessageExplorerProps> = props => {
    const viewType = MessageExplorerViewType.ListView;
    const [isToolbarReset, resetToolbar] = useState<boolean>(false);
    const [showCreateFolder, toggleShowCreateFolder] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState<IFolderModel[]>([]);
    const [isMoveToFolderEnabled, setIsMoveToFolderEnabled] = useState(false);
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const dispatch = useDispatch();
    const currentFolderPath = useContext(ArchiveContext);
    const folderData = useSelector((appState: ApplicationState) => appState.folderState.foldersData.subFolders);
    const [showShareFolderPopup, setShowShareFolderPopup] = useState(false);
    const [newlyCreatedFolder, setNewlyCreatedFolder] = useState<IFolderModel>();

    useEffect(() => {
        dispatch(
            fetchSubFolders(folderData ? folderData.parentFolderId : 0, FolderColumnName.FOLDERNAME, SortOrder.ASC)
        );
    }, []);

    useEffect(() => {
        handleFolderSelection([]);
    }, [props.isSearched, props.reload]);

    const handleFolderClick = (
        folder: IFolderModel,
        sortOptions: { sortColumn: FolderColumnName; sortOrder: SortOrder },
        isTitleBreadcrumb: boolean,
        breadCrumbArrayArchiveTable: IFolderModel[]
    ) => {
        dispatch(fetchSubFolders(folder.folderId, sortOptions.sortColumn, sortOptions.sortOrder));
        props.handleFolderClick(folder, isTitleBreadcrumb, breadCrumbArrayArchiveTable);
    };

    const onRequestFolders = (sortOptions: { sortColumn: FolderColumnName; sortOrder: SortOrder }) => {
        if (folderData && folderData.folders?.length > 0) {
            dispatch(
                fetchSubFolders(
                    folderData.parentFolderId,
                    // otherUserId,
                    sortOptions.sortColumn,
                    sortOptions.sortOrder,
                    null,
                    props.searchFolderText
                )
            );
        }
    };

    const handleFolderSelection = (folders: IFolderModel[]) => {
        const isOwnerFolders = folders?.every(folder => folder.createdBy === user?.userId);
        if (folders.length === folderData?.folders?.length) {
           const showMoveToFolders = folders.some(folder => folder.parentId !== rootFolder.folderId);
            const res = isOwnerFolders && showMoveToFolders;
            setIsMoveToFolderEnabled(res);
        } else {
            setIsMoveToFolderEnabled(isOwnerFolders);
        }
        folders = folders?.map(folder => {
            folder = { ...folder, path: currentFolderPath + "/" + folder.folderName };
            return folder;
        });
        setSelectedFolders(folders);
        resetToolbar(true);
    };

    const handleOnClickCreateFolder = () => {
        toggleShowCreateFolder(true);
    };

    const handleCreateFolderSubmit = (folder: IFolderModel | undefined, showSharedFolderPopUp?: boolean) => {
        if (folder) {
            if (folder.parentId === (props.currentFolder?.folderId || 0)) {
                props.resetSearchFolderTextAndSort();
                props.setReload(true);
            }
            setNewlyCreatedFolder(folder);
        }
        props.setReload(false);
        toggleShowCreateFolder(false);
        showSharedFolderPopUp && setShowShareFolderPopup(showSharedFolderPopUp);
    };

    const getFolderView = (): JSX.Element => {
        switch (viewType) {
            case MessageExplorerViewType.ListView:
                return (
                    <div className="archive-table-container">
                        <ArchiveFolderTable
                            handleOnClickCreateFolder={handleOnClickCreateFolder}
                            folderData={folderData?.folders ?? []}
                            selectedFolders={selectedFolders}
                            updateFolderSelection={handleFolderSelection}
                            isContentLoading={props.isMessageLoading}
                            requestFolders={onRequestFolders}
                            handleFolderClick={handleFolderClick}
                            retentionPeriods={props.retentionPeriods}
                            setReload={props.setReload}
                            isMoveToFolderEnabled={isMoveToFolderEnabled}
                            isToolbarReset={isToolbarReset}
                            currentfolder={props.currentFolder}
                            resetSearchFolderTextAndSort={props.resetSearchFolderTextAndSort}
                            isSearched={props.isSearched}
                            goToFolder={props.goToFolder}
                            foldersCountUnderRoot={props.foldersCountUnderRoot}
                        ></ArchiveFolderTable>
                    </div>
                );
            default:
                return <div>Grid view</div>;
        }
    };

    return (
        <>
            <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                {getFolderView()}
                <Loader loading={folderData?.isLoading} />
            </LoadingOverlay>

            {showCreateFolder && (
                <CreateFolderModal
                    show={showCreateFolder}
                    retentionOptions={getRetentionOptions(props.retentionPeriods)}
                    onSubmit={(folder, showSharedFolderPopUp) =>
                        handleCreateFolderSubmit(folder, showSharedFolderPopUp)
                    }
                    currentFolder={props.currentFolder}
                    onHide={() => {
                        toggleShowCreateFolder(false);
                        setSelectedFolders([]);
                    }}
                    modalType={FolderEventType.Create}
                />
            )}

            {showShareFolderPopup && (
                <ShareFolderModal
                    show={showShareFolderPopup}
                    onSubmit={() => {
                        // Don't have Submit button in Share Folder Modal so this function will be empty
                    }}
                    folder={newlyCreatedFolder}
                    onHide={() => {
                        setShowShareFolderPopup(false);
                        props.setReload(true);
                    }}
                />
            )}
        </>
    );
};

MessageExplorer.displayName = MessageExplorer.name;

export default MessageExplorer;
