import React, { FC, useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { requestDeleteMyDownloadFile, requestDownloadLaterLink, requestMyDownloadsList } from "../../../actionCreators/folderActionCreator";
import { DateFormatConstants, MyDownloadsModalConstants } from "../../../helper/Constants";
import { formatBytes } from "../../../helper/HelperFunctions";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { DeleteIcon, DownloadIcon } from "../../svg/ToolbarIcons";
import { ApplicationState } from "../../../store";
import { PendingIcon, ZipperIcon } from "../../svg/IconCollection";
import { IMyDownloadFile, ZipDownloadStatus } from "../../../core/viewModels/folder/FolderModel";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import moment from "moment";
import { myDownloadsPageChanged } from "../../../actionCreators/signalRActionCreator";

const MyDownloadFileTable: FC = () => {

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<IMyDownloadFile>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { isLoading, myDownloadList } = useSelector((state: ApplicationState) => state.folderState.myDownloads);
  const [count, setCount] = useState<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if(myDownloadList.length) {
      setCount(myDownloadList[0].count);
    } else {
      setCount(0);
    }
  }, [myDownloadList]);

  const handleFileDownload = (row: IMyDownloadFile) => {
    dispatch(requestDownloadLaterLink(row));
  };

  const isDownloadable = (row: IMyDownloadFile) => row.status === ZipDownloadStatus.Ready || row.status === ZipDownloadStatus.Downloaded;

  const folderNameFormatter = (cell: string): JSX.Element => {
    return (
      <div className="folder-name-cell" title={cell}>
        <ZipperIcon />
        <span className="ellipsis margin-left-10-px">{cell}</span>
      </div>
    );
  };
  const downloadDateFormatter = (cell: string): JSX.Element => {
    const date = getFormattedLocalDateTime(cell, DateFormatConstants.LOGIN_TABLE_DATE_FORMAT);
    return (
      <span className="ellipsis date-cell" title={date}>{date}</span>
    );
  };
  const expiryDateFormatter = (cell: string): JSX.Element => {
    const date = getFormattedLocalDateTime(cell, DateFormatConstants.LOGIN_TABLE_DATE_FORMAT);
    const expiryDate = moment(date).add(30, "d").format(DateFormatConstants.LOGIN_TABLE_DATE_FORMAT);
    return (
      <span className="ellipsis date-cell" title={expiryDate}>{expiryDate}</span>
    );
  };
  const fileSizeFormatter = (cell: string): JSX.Element => {
    return <span className="ellipsis filesize-cell">{formatBytes(Number(cell))}</span>;
  };
  const actionFormatter = (cell: string, row: IMyDownloadFile): JSX.Element => {
    return <div className="action-cell">
      <div className={isDownloadable(row) ? "margin-top-5-px" : ""} title={isDownloadable(row) ? "Download" : "Pending"} onClick={() => isDownloadable(row) && handleFileDownload(row)}>
        {isDownloadable(row) ? <DownloadIcon height={22} width={22} /> : <PendingIcon height={20} width={20} />}
      </div>
      <div onClick={() => {
        setShowDeleteModal(true);
        setToBeDeleted(row);
      }}
      title="Delete">
        {isDownloadable(row) && <DeleteIcon height={17} width={17} />}
      </div>
    </div>;
  };

  useEffect(() => {
    dispatch(requestMyDownloadsList(1, MyDownloadsModalConstants.PAGE_SIZE));
  }, []);

  const columns: ColumnDescription[] = [
    {
      text: "File(s) From",
      dataField: "parentFolder",
      headerStyle: { width: "30%" },
      formatter: folderNameFormatter
    },
    {
      text: "Downloaded On",
      dataField: "createdOn",
      formatter: downloadDateFormatter
    },
    {
      text: "Expiry Date",
      dataField: "createdOn",
      formatter: expiryDateFormatter
    },
    {
      text: "Size",
      dataField: "fileSize",
      headerStyle: { width: "10%" },
      formatter: fileSizeFormatter
    },
    {
      text: "",
      dataField: "myDownloadsId",
      headerStyle: { width: "100px" },
      formatter: actionFormatter
    }
  ];
  const handlePageChange = (page: number) => {
    setCurrentPageNumber(page);
    dispatch(requestMyDownloadsList(page, MyDownloadsModalConstants.PAGE_SIZE));
    dispatch(myDownloadsPageChanged(page));
  };
  const options = {
    totalSize: count,
    sizePerPage: MyDownloadsModalConstants.PAGE_SIZE,
    page: currentPageNumber,
    onPageChange: (page: number) => handlePageChange(page),
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
  };
  const getTableData = () => {
    return Array.isArray(myDownloadList) ? myDownloadList : [];
  };
  const handleOnSubmitDelete = () => {
    if (toBeDeleted) {
      setShowDeleteModal(false);
      dispatch(requestDeleteMyDownloadFile(toBeDeleted, () => dispatch(requestMyDownloadsList(currentPageNumber, MyDownloadsModalConstants.PAGE_SIZE))));
    }
  };
  return <>
    <LoadingOverlay>
      <BootstrapTable
        classes="settings-table my-downloads"
        keyField="myDownloads"
        columns={columns}
        data={getTableData()}
        remote
        noDataIndication={"No data"}
        bordered={false}
        pagination={paginationFactory(options)}
      />
      <Loader
        classNamePrefix="sm"
        text=""
        loading={isLoading}
      />
    </LoadingOverlay>
    <CustomModal
      show={showDeleteModal}
      cancelButtonName="Cancel"
      confirmButtonName="Confirm"
      title={MyDownloadsModalConstants.DELETE_TITLE}
      onSubmit={handleOnSubmitDelete}
      onHide={() => setShowDeleteModal(false)}
    >
      <div>
        <span>{MyDownloadsModalConstants.DELETE_FILE_DESCRIPTION}</span>
      </div>
    </CustomModal>
  </>;
};

export default MyDownloadFileTable;