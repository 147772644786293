import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { MyDownloadsModalConstants } from "../../../helper/Constants";
import MyDownloadFileTable from "../parts/MyDownloadFileTable";

const MyDownloadsModal: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    return (
        <Modal dialogClassName="custom-bootstrap-modal my-downloads-modal" show={show} onHide={() => onHide()}>
            <Modal.Header className="padding-4" closeButton closeVariant="white">
                {MyDownloadsModalConstants.TITLE}
            </Modal.Header>
            <Modal.Body>
                <div className="description margin-bottom-20-px">
                    <i className="fas fa-info-circle margin-right-5-px" />
                    <span>{MyDownloadsModalConstants.DESCRIPTION}</span>
                </div>
                <MyDownloadFileTable />
            </Modal.Body>
            <Modal.Footer>
                <Button size="lg" className="margin-right-10-px" variant="outline-secondary" onClick={() => onHide()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MyDownloadsModal;
