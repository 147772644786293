
export enum actionTypes {
  REQUEST_RECEIVED_ITEMS_HISTORY = "request_received_items_history",
  RECEIVE_RECEIVED_ITEMS_HISTORY = "receive_received_items_history",
  COMPLETED_RECEIVED_ITEMS_HISTORY = "complete_received_items_history",

  REQUEST_RECEIVED_ITEMS_HISTORY_DOWNLOAD = "request_received_items_history_download",
  RECEIVE_RECEIVED_ITEMS_HISTORY_DOWNLOAD = "receive_received_items_history_download",
  COMPLETED_RECEIVED_ITEMS_HISTORY_DOWNLOAD = "complete_received_items_history_download",

  REQUEST_RECEIVED_ITEMS_HISTORY_METRICS = "request_received_items_history_metrics",
  RECEIVE_RECEIVED_ITEMS_HISTORY_METRICS = "receive_received_items_history_metrics",
  COMPLETED_RECEIVED_ITEMS_HISTORY_METRICS = "completed_received_items_history_metrics",
}