import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Col, Row } from "react-bootstrap";

import {
    changeExpiry,
    deleteSharedMessage,
    getMessageDetail,
    moveToFolder,
    printMessages,
    fetchFolderMessages,
} from "../../../actionCreators/receivedMessageActionCreators";
import { FolderType, MessageSource } from "../../../core/common/enums";
import { ApplicationState } from "../../../store";
import { initialReceivedMessage, IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import { IFolderState } from "../../../reducers/folderReducer";
import MessageDetail from "../../common/message/MessageDetail";
import { DownloadedFrom, IFileModel } from "../../../core/viewModels/file/FileModel";
import { downloadAllAttachments } from "../../../actions/attachmentActions";
import { IMessageResponseModel } from "../../../core/viewModels/message/MessageResponseModel";
import { IPagination, ISentMessageModel } from "../../../core/viewModels/message/SentMessageModel";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { MessageListConstants } from "../../../helper/Constants";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { getFolderMessageSortOptions } from "../../../helper/HelperFunctions";
import ArchiveMessageList from "./ArchiveMessageList";

interface IArchiveMessageProps {
    currentfolder?: IFolderModel;
    searchText?: string;
    folderId: number;
    pagination: IPagination;
    selectedSortOption?: string;
    reload?: boolean;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    downloadSingleAttachment?: (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => void;
    resetSearchAndSort?: () => void;
}

const ArchiveMessage: React.FC<IArchiveMessageProps> = props => {
    const { pagination, setPagination } = props;
    const [isMessageDetailShow, toggleMessageDetailShow] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(initialReceivedMessage);

    const dispatch = useDispatch();
    const messageState = useSelector((state: ApplicationState) => state.receivedMessageState);
    const folderState: IFolderState = useSelector((appState: ApplicationState) => appState.folderState);

    const fetchFolderInboxMessages = (pageNo: number, pageSize: number, search: string) => {
        const sort = getFolderMessageSortOptions(props.selectedSortOption ?? "");
        dispatch(
            fetchFolderMessages(
                props.folderId,
                pageNo,
                pageSize,
                sort.sortColumn,
                sort.sortOrder,
                search,
                onFetchInboxMessagesComplete
            )
        );
    };

    const onFetchInboxMessagesComplete = (result: IMessageResponseModel<IReceivedMessageModel>) => {
        setSelectedMessage(result.messages[0]);
        if (result && result.messages && result.messages.length > 0) {
            dispatch(
                getMessageDetail(
                    result.messages[0].receivedMessageId,
                    result.messages[0].messageType,
                    messageDetailCallback
                )
            );
        }
    };

    const messageDetailCallback = (result: any) => {
        if (result.messageDetail) toggleMessageDetailShow(true);
        else toggleMessageDetailShow(false);
    };

    const onSuccessCallback = (pageNo: number, pageSize: number, search: string) => {
        const sort = getFolderMessageSortOptions(props.selectedSortOption ?? "");
        dispatch(
            fetchFolderMessages(
                props.folderId,
                pageNo,
                pageSize,
                sort.sortColumn,
                sort.sortOrder,
                search,
                onFetchInboxMessagesCallBack
            )
        );
    };

    const onFetchInboxMessagesCallBack = () => {
        dispatch(
            getMessageDetail(selectedMessage.receivedMessageId, selectedMessage.messageType, messageDetailCallback)
        );
    };

    const handleOnChangeExpiry = (
        expiresDate: Date,
        message: ISentMessageModel | IReceivedMessageModel,
        callback?: any
    ): void => {
        const receivedMessage = message as IReceivedMessageModel;
        dispatch(changeExpiry(expiresDate, [receivedMessage], callback, appInsightsData.Folders.MyFiles.PageTitle));
    };

    const handleDownloadAllAttachments = (
        files: IFileModel[],
        messageId: number,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => {
        dispatch(
            downloadAllAttachments(
                files,
                messageId,
                senderEmail,
                receivedMessageGuid,
                messageSource,
                DownloadedFrom.MPI_Download_Inbox_Multiple_File,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.Folders.MyFiles.PageTitle,
                ResourceIdLocators.MyFiles.MsgDtlBtnDownloadFiles
            )
        );
    };

    const handleOnDeleteMessage = (messages: IReceivedMessageModel[], callback: any) => {
        dispatch(
            deleteSharedMessage(
                messages,
                callback,
                appInsightsData.Folders.MyFiles.PageTitle,
                ResourceIdLocators.MyFiles.MsgListBtnDelete
            )
        );
    };

    const handleGetMessageDetail = (message: IReceivedMessageModel) => {
        dispatch(getMessageDetail(message.receivedMessageId, message.messageType, messageDetailCallback));
    };

    const handleMessageDetailView = (result: boolean) => {
        if (result) toggleMessageDetailShow(false);
    };

    const handleOnPrint = (): void => {
        dispatch(printMessages());
    };

    const checkFolderExpiry = (folderId: number): boolean => {
        let destinationFolderExpiryDays: number | undefined;
        let sourceFolderExpiryDays: number | undefined;
        if (!folderState.foldersData.isLoading) {
            destinationFolderExpiryDays = folderState.foldersData.folders?.find(
                f => f.folderId === folderId
            )?.expiresDays;
            sourceFolderExpiryDays = folderState.foldersData.folders?.find(
                f => f.folderId === props.folderId
            )?.expiresDays;
            if (destinationFolderExpiryDays && sourceFolderExpiryDays) {
                return destinationFolderExpiryDays >= sourceFolderExpiryDays;
            }
        }
        return true;
    };

    const handleOnMoveToFolder = (messages: IReceivedMessageModel[], folderId: number, callback?: any): void => {
        dispatch(
            moveToFolder(
                messages,
                props.folderId,
                folderId,
                callback,
                appInsightsData.Folders.MyFiles.PageTitle,
                ResourceIdLocators.MyFiles.MsgListBtnMoveMessage
            )
        );
    };

    return (
        <Row className="height-100-percent">
            <Col sm={4} className="no-padding-left">
                <ArchiveMessageList
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                    folderId={props.folderId}
                    folderType={FolderType.ARCHIVE}
                    isLoading={messageState.isLoading}
                    messages={messageState.data.messages}
                    totalRecords={messageState.data.totalRecords}
                    fetchFolderMessages={fetchFolderInboxMessages}
                    deleteMessages={handleOnDeleteMessage}
                    getMessageDetail={handleGetMessageDetail}
                    print={handleOnPrint}
                    checkFolderExpiry={checkFolderExpiry}
                    moveToFolder={handleOnMoveToFolder}
                    hideMessageDetailView={handleMessageDetailView}
                    pagination={pagination}
                    setPagination={setPagination}
                    resourceLocators={ResourceIdLocators.MyFiles}
                    folder={props.currentfolder}
                    searchText={props.searchText}
                    selectedSortOption={props.selectedSortOption}
                    resetSearchAndSort={props.resetSearchAndSort}
                    reload={props.reload}
                />
            </Col>
            <Col sm={8}>
                <LoadingOverlay style={{ height: "100%" }}>
                    {isMessageDetailShow && messageState.data.totalRecords > 0 ? (
                        <MessageDetail
                            message={messageState.mailData.data}
                            downloadSingleAttachment={props.downloadSingleAttachment}
                            downloadAllAttachments={handleDownloadAllAttachments}
                            messageId={messageState?.mailData?.data?.messageDetail?.receivedMessageId}
                            folderType={FolderType.ARCHIVE}
                            hideMessageDetailView={handleMessageDetailView}
                            changeExpiry={handleOnChangeExpiry}
                            pagination={pagination}
                            fetchFolderMessages={fetchFolderInboxMessages}
                            resourceLocators={ResourceIdLocators.MyFiles}
                            onSuccessCallback={onSuccessCallback}
                            currentfolder={props.currentfolder}
                            resetSearchAndSort={props.resetSearchAndSort}
                        />
                    ) : (
                        messageState.mailData.isLoading || (
                            <div className="no-message-selected">{MessageListConstants.NO_MESSAGE_SELECTED}</div>
                        )
                    )}
                    <Loader loading={messageState.mailData.isLoading} />
                </LoadingOverlay>
            </Col>
        </Row>
    );
};

export default ArchiveMessage;
