import * as React from "react";
import { useEffect } from "react";
import PdfViewer from "awesome-pdf-viewer";
import Main from "awesome-pdf-viewer/dist/layout/Main";
import Zoom from "awesome-pdf-viewer/dist/toolbar/Zoom";
import Header from "awesome-pdf-viewer/dist/layout/Header";
import Toolbar from "awesome-pdf-viewer/dist/toolbar/Toolbar";
import ViewPanel from "awesome-pdf-viewer/dist/layout/ViewPanel";
import Pagination from "awesome-pdf-viewer/dist/toolbar/Pagination";
import { PdfSource } from "awesome-pdf-viewer/dist/viewer/ViewerBase";
import { DownloadIcon } from "../svg/ToolbarIcons";
import { ColorConstants } from "../../assets/custom/colors";

interface FileViewerProps {
    url: string;
    fileType: string | undefined;
    onClickDownloadUploadedDocument: (fileName: string, fileGuid: string) => void;
    fileGuid: string;
    fileName: string;
}

export const FileViewer: React.FC<FileViewerProps> = props => {
    let _viewPanel: any;
    let _toolbar: any;

    useEffect(() => {
        props.fileType && props.fileType.toLowerCase() === "pdf" ? setToolbarReferences() : null;
    }, [props.fileType]);

    const setToolbarReferences = () => {
        _toolbar.setViewerReference(_viewPanel);
        _viewPanel.setToolbarReference(_toolbar);
    };

    return props.fileType && props.fileType.toLowerCase() === "pdf" ? (
        <PdfViewer>
            <Header>
                <Toolbar ref={(ref: any) => (_toolbar = ref)} hideLeftPanel hideRightPanel>
                    <Pagination />
                    <Zoom />
                    <i
                        className="fa fa-downloads"
                        aria-hidden="true"
                        onClick={() => {
                            props.onClickDownloadUploadedDocument(props.fileName, props.fileGuid);
                        }}
                    >
                        {" "}
                        <DownloadIcon width={21} height={30} color={ColorConstants.button_black} />
                    </i>
                </Toolbar>
            </Header>
            <Main>
                <ViewPanel
                    ref={(ref: any) => (_viewPanel = ref)}
                    pdfSource={PdfSource.createFromUrl(props.url)}
                ></ViewPanel>
            </Main>
        </PdfViewer>
    ) : null;
};
