import React, { FC, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { saveOneTimePassword, validateOTP } from "../../../actions/otpActions";
import { countryCodeDisplay, GetCountryCode, phoneNumberDisplay } from "../../../helper/HelperFunctions";
import { ApplicationState } from "../../../store";
import { PhoneNumber } from "../../common/PhoneNumber";
import * as Validation from "../../../helper/Validations";
import { MyAccountConstants, ProfileInformation, ValidationContants } from "../../../helper/Constants";
import { requestUserProfile, saveMyAccount } from "../../../actions/userProfileActions";
import { AppNotifier } from "../../../helper/AppNotifier";
import { IMyAccountLayout } from "./MyAccountLayout";
import { isControlDisabled } from "../../../helper/Validations";
import { logger } from "../../../oidcClient/authProvider";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { AuthenticationProvider } from "../../../core/viewModels/company/CompanySettingsViewModel";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

interface IMyAccountForm extends IMyAccountUserProfile {
    existingEmailAddress: string;
    showChangePassword: boolean;
    showOtpVerification: boolean;
    saving: boolean;
    otpValue: string;
    disableVerifyLink: boolean;
    ptin: string;
    title: string;
}
export interface IMyAccountUserProfile {
    userId: number,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phone: string,
    extension: string,
    fax: string,
    title: string,
    countryCode: string,
    mobileNumber: string,
    isMobileVerify: boolean,
    ptin: string,
    createdOn: string,
    isDeleted: number,
    metadata: string,
    authenticationProvider: AuthenticationProvider[],
    deviceId: string,
    isMFAEnabled: boolean,
    modifiedBy: number,
    readonlyFields: string[],
    revokeStatus: number
}
export const initialUserFormState = {
    userId: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    extension: "",
    fax: "",
    title: "",
    countryCode: "",
    mobileNumber: "",
    isMobileVerify: false,
    ptin: "",
    createdOn: "",
    isDeleted: 0,
    metadata: "",
    authenticationProvider: [AuthenticationProvider.AzureAD],
    deviceId: "",
    isMFAEnabled: false,
    modifiedBy: 0,
    readonlyFields: ["0"],
    revokeStatus: 0,
    existingEmailAddress: "",
    showChangePassword: false,
    showOtpVerification: false,
    saving: false,
    otpValue: "",
    disableVerifyLink: false,
};

const Profile: FC<IMyAccountLayout> = props => {
    const ref = useRef<HTMLFormElement>(null);
    const [formDetail, setFormDetail] = useState<IMyAccountForm>(initialUserFormState);
    const [validated, setValidated] = useState<boolean>(false);
    const [mobileValidation, setMobileValidation] = useState<{ message: string; error: boolean }>({
        message: "",
        error: false,
    });
    const profile = useSelector((state: ApplicationState) => state.userProfile);
    const otpData = useSelector((state: ApplicationState) => state.oneTimePasswordData.otpState);
    
    const companyMfaSetting = useSelector((state: ApplicationState) => state.companyMfaSettingState);
    const dispatch = useDispatch();

    const onFormChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        const data = { ...formDetail, [element.id]: element.value };
        setFormDetail(data);
    };
    const onFaxChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, fax: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const onExtChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, extension: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const onChangeCountryCode: React.ChangeEventHandler<HTMLSelectElement> = e => {
        const { value } = e.target;
        setFormDetail({
            ...formDetail,
            countryCode: value,
            disableVerifyLink: false,
            isMobileVerify: false,
        });
    };
    const onChangeMobileNumber = (value: string) =>
        setFormDetail({
            ...formDetail,
            mobileNumber: value,
            disableVerifyLink: false,
            isMobileVerify: false,
        });

    const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        e.stopPropagation();
        const validatedMobileNumberData = validateMobileNumber();
        setMobileValidation(validatedMobileNumberData);
        const validPtin = Validation.isValidatePTIN(formDetail.ptin);
        const validFax =
            Validation.validateFax(formDetail.fax, true) || !Validation.NullandEmptyCheck(formDetail.fax);
        const validPhone =
            formDetail.phone.length === ProfileInformation.PHONE_NUMBER_LENGTH ||
            (!formDetail.phone && !formDetail.extension);
        const validExt = formDetail.extension.length < ProfileInformation.EXTENSION_LENGTH;

        const allValid = !validatedMobileNumberData.error && validPtin && validFax && validPhone && validExt;

        const form = ref.current;

        if (!!form && form.checkValidity() && allValid) {
            setFormDetail({
                ...formDetail,
                saving: true,
                showOtpVerification: false,
                otpValue: "",
                disableVerifyLink: false,
            });
            logger.trackEvent({
                name: `${appInsightsData.MyAccount.ProfilePageTitle} - ${appInsightsData.MyAccount.Operation.ProfileFormSaveClick}`,
                properties: {
                    page: appInsightsData.MyAccount.ProfilePageTitle,
                    email: formDetail.existingEmailAddress,
                },
            });
            const formDataPayload=()=> {
               return{ ...formDetail,
                firstName:formDetail.firstName.trim(),
                lastName: formDetail.lastName.trim(),
                title: formDetail.title.trim()};
            };
            dispatch(
                saveMyAccount(formDataPayload(), false, (response: any, error: any) => {
                    setFormDetail({ ...formDetail, saving: false });
                    dispatch(requestUserProfile(true));
                    if (error) {
                        AppNotifier.Error(error.response.data || MyAccountConstants.UserUpdateFailedMessage);
                    } else {
                        setFormDetail({
                            ...formDetail,
                        });
                        if (response) {
                            AppNotifier.Success(MyAccountConstants.APIResponse.UserUpdateSuccess);
                            props.onHide();
                        } else {
                            AppNotifier.Error(response);
                        }
                    }
                })
            );
            setValidated(false);
        } else {
            setValidated(true);
        }
    };

    const onVerifyClick = () => {
        const validationData = validateMobileNumber(true);
        setMobileValidation(validationData);
        if (!validationData.error) {
            setFormDetail({ ...formDetail, disableVerifyLink: true });
            const DISABLE_VERIFY_LINK_TIMEOUT = 108000;
            setTimeout(() => {
                setFormDetail({ ...formDetail, disableVerifyLink: false });
            }, DISABLE_VERIFY_LINK_TIMEOUT);
            const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
            const countryCode = formDetail.countryCode;
            setFormDetail({
                ...formDetail,
                showOtpVerification: true,
                otpValue: "",
            });
            dispatch(saveOneTimePassword(mobileNumber, countryCode));
        }
    };

    const onChangeOtpValue = (event: any) => {
        if (Validation.ValidateTenDigitNumber(event)) {
            const value = event.target.value;
            setFormDetail({ ...formDetail, otpValue: value });
            if (otpData.mfaOTPLength === value.length) {
                const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
                dispatch(validateOTP(value, mobileNumber,hideVerifyLink));
            }
        }
    };

    const hideVerifyLink = (isOTPValid: boolean) => {
        if (isOTPValid) {
            setFormDetail({
                ...formDetail,
                showOtpVerification: false,
                isMobileVerify: true,
            });
        }
    };

    const onChangePhoneNumber = (value: string) => setFormDetail({ ...formDetail, phone: value });

    useEffect(() => {
        setFormDetail({
            ...formDetail,
            existingEmailAddress: profile.emailAddress,
            ...profile,
            emailAddress: profile.emailAddress,
            countryCode: profile.countryCode || GetCountryCode()[0].value,
        });
    }, [profile]);

    const validateMobileNumber = (isVerify?: boolean) => {
        const enabledMfa = isVerify || companyMfaSetting?.isMfaEnabled;
        if (enabledMfa && !Validation.NullandEmptyCheck(formDetail.mobileNumber)) {
            return {
                message: ValidationContants.MobileNumberWarning,
                error: true,
            };
        } else if (
            Validation.NullandEmptyCheck(formDetail.mobileNumber) &&
            !Validation.validatePhoneLength(formDetail.mobileNumber)
        ) {
            return {
                message: ValidationContants.MobileNumberLengthWarning,
                error: true,
            };
        } else if (formDetail.countryCode === "" || formDetail.countryCode === undefined) {
            return {
                message: ValidationContants.CountryCodeWarning,
                error: true,
            };
        } else {
            return {
                message: "",
                error: false,
            };
        }
    };

    const onHide = () => {
        props.onHide();
    };
    return (
        <div>
            <h3 className="heading-blue-1" data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileInformationTitle}>{ProfileInformation.TITLE}</h3>
            <p className="text-black-50">{ProfileInformation.DESCRIPTION}</p>
            <LoadingOverlay style={{ height: "100%" }}>
                <Form ref={ref} id="profile-form" noValidate validated={validated}>
                    <Row>
                        <Form.Group as={Col} controlId="title">
                            <Form.Label>{ProfileInformation.LABEL_TITLE}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={onFormChange}
                                value={formDetail?.title}
                                placeholder={ProfileInformation.PLACEHOLDER_TITLE}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="emailAddress">
                            <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleEmailAddress} data-test-auto={AutomationIdConstants.reportProblem.ProfileEmailAddress}>{ProfileInformation.LABEL_EMAIL}</Form.Label>
                            <Form.Control
                                type="email"
                                onChange={onFormChange}
                                value={formDetail?.emailAddress}
                                maxLength={ProfileInformation.MAX_CHARACTER_LENGTH}
                                required
                                disabled={isControlDisabled("emailAddress", profile)}
                                placeholder={ProfileInformation.PLACEHOLDER_EMAIL}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!formDetail?.emailAddress
                                    ? ValidationContants.EmailAdrressWarning
                                    : ValidationContants.ValidEmailAddressWarning}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleFirstName}>{ProfileInformation.LABEL_FIRST_NAME}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={onFormChange}
                                maxLength={ProfileInformation.MAX_CHARACTER_LENGTH}
                                value={formDetail?.firstName}
                                required
                                disabled={isControlDisabled("firstName", profile)}
                                placeholder={ProfileInformation.PLACEHOLDER_FIRST_NAME}
                            />
                            <Form.Control.Feedback type="invalid">
                                {ValidationContants.FirstNameWarning}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="lastName">
                            <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleLastName}>{ProfileInformation.LABEL_LAST_NAME}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={onFormChange}
                                value={formDetail?.lastName}
                                maxLength={ProfileInformation.MAX_CHARACTER_LENGTH}
                                required
                                disabled={isControlDisabled("lastName", profile)}
                                placeholder={ProfileInformation.PLACEHOLDER_LAST_NAME}
                            />
                            <Form.Control.Feedback type="invalid">
                                {ValidationContants.LastNameWarning}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group
                            as={Col}
                            className={
                                validated &&
                                formDetail.phone.length > 0 &&
                                formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH
                                    ? "input-error"
                                    : ""
                            }
                        >
                            <Form.Label data-auto-test={AutomationIdConstants.reportProblem.ProfilePhoneNumber}>{ProfileInformation.LABEL_PHONE_NUMBER}</Form.Label>
                            <PhoneNumber
                                phoneNumber={formDetail?.phone}
                                handleChangePhoneNumber={onChangePhoneNumber}
                                disabled={isControlDisabled("phone", profile)}
                            />
                            {validated &&
                                formDetail.phone.length > 0 &&
                                formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH && (
                                    <div className="input-error-message">
                                        {!formDetail.phone && formDetail.extension
                                            ? ValidationContants.PhoneNumberWarning
                                            : ValidationContants.PhoneNumberLengthWarning}
                                    </div>
                                )}
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            controlId="extension"
                            className={
                                validated && formDetail.extension.length > ProfileInformation.EXTENSION_LENGTH
                                    ? "input-error"
                                    : ""
                            }
                        >
                            <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleExtension}>{ProfileInformation.LABEL_EXTENSION}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={onExtChange}
                                value={formDetail?.extension}
                                disabled={isControlDisabled("extension", profile)}
                                placeholder={ProfileInformation.PLACEHOLDER_EXTENSION}
                                isInvalid={
                                    validated && formDetail.extension.length >= ProfileInformation.EXTENSION_LENGTH
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {ValidationContants.ExtensionWarning}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <div className="width-100-percent">
                        <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.PasswordTitleEnterMobileNo}>{ProfileInformation.LABEL_MOBILE_NUMBER}</Form.Label>
                        <Row>
                            <Col xs={4}>
                                <Form.Select
                                    onChange={onChangeCountryCode}
                                    value={formDetail?.countryCode}
                                    required={companyMfaSetting.isMfaEnabled}
                                >
                                    {GetCountryCode().map((code, index) => (
                                        <option value={code.value} key={`country-${index}`}>
                                            {code.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Group as={Row}>
                                    <Col className={mobileValidation.error ? "input-error" : ""}>
                                        <PhoneNumber
                                            phoneNumber={formDetail?.mobileNumber}
                                            handleChangePhoneNumber={onChangeMobileNumber}
                                            disabled={false}
                                        />
                                    </Col>
                                    {!formDetail.isMobileVerify && (
                                        <Col xs={4} className="d-flex align-items-center">
                                            <span
                                                onClick={() => !formDetail.disableVerifyLink && onVerifyClick()}
                                                className="margin-right-10-px heading-blue-2 cursor-pointer"
                                            >
                                                {ProfileInformation.VERIFY_TITLE}
                                            </span>
                                            <i
                                                className="fas fa-question-circle help-icon"
                                                title={ProfileInformation.VERIFY_HELP_TEXT}
                                            ></i>
                                        </Col>
                                    )}
                                    {mobileValidation.error && (
                                        <span className="input-error-message">{mobileValidation.message}</span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    {formDetail.showOtpVerification && !formDetail.isMobileVerify && (
                        <Row className="margin-top-15-px align-items-center">
                            <Col xs={6}>
                                {`${ProfileInformation.LABEL_ACCESS_CODE} ${countryCodeDisplay(
                                    formDetail.countryCode
                                )} ${phoneNumberDisplay(formDetail.mobileNumber)}`}
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    onChange={onChangeOtpValue}
                                    value={formDetail.otpValue}
                                    placeholder={ProfileInformation.PLACEHOLDER_ACCESS_CODE}
                                />
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Form.Group as={Col} controlId="fax">
                            <Form.Label data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleFaxNumber}>{ProfileInformation.LABEL_FAX_NUMBER}</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={onFaxChange}
                                value={formDetail?.fax}
                                disabled={isControlDisabled("fax", profile)}
                                placeholder={ProfileInformation.PLACEHOLDER_FAX_NUMBER}
                                minLength={ProfileInformation.FAX_LENGTH}
                                maxLength={ProfileInformation.FAX_LENGTH}
                            />
                            <Form.Control.Feedback type="invalid">
                                {ValidationContants.FaxWarning}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Col />

                        {/* The PTIN field will be added later */}
                        {/* <Form.Group as={Col} controlId="ptin" className={(validated && !Validation.isValidatePTIN(formDetail.ptin)) ? "input-error" : ""}>
                        <Form.Label>{ProfileInformation.LABEL_PTIN}</Form.Label>
                        <Form.Control type="text" onChange={onFormChange} value={formDetail?.ptin} placeholder={ProfileInformation.PLACEHOLDER_PTIN}
                            isInvalid={validated && !Validation.isValidatePTIN(formDetail.ptin)} maxLength={ProfileInformation.PTIN_LENGTH} />
                        <Form.Control.Feedback type="invalid">{ValidationContants.PTINWarning}</Form.Control.Feedback>
                    </Form.Group> */}
                    </Row>
                    <div className="margin-top-15-px padding-top-15-px d-flex justify-content-end">
                        <button type="button" className="btn btn-light margin-right-4" data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleCancel}onClick={onHide}>
                            {ProfileInformation.CANCEL_BUTTON}
                        </button>
                        <button type="button" className="btn btn-primary" form="report-form" data-auto-test={AutomationIdConstants.userSettingsMyAccount.ProfileTitleSave}onClick={handleSubmit}>
                            {ProfileInformation.OK_BUTTON}
                        </button>
                    </div>
                </Form>
                <Loader loading={formDetail.saving} />
            </LoadingOverlay>
        </div>
    );
};

export default Profile;
