export enum actionTypes {
    REQUEST_SENT_MESSAGES = "request_sent_messages",
    RECEIVED_SENT_MESSAGES = "received_sent_messages",
    COMPLETED_SENT_MESSAGES = "completed_sent_messages",
    REQUEST_DELETE_MESSAGES = "request_delete_messages",
    COMPLETED_DELETE_MESSAGES = "completed_delete_messages",
    REQUEST_CANCEL_TRANSFER_MESSAGES = "request_cancel_transfer_messages",
    COMPLETED_CANCEL_TRANSFER_MESSAGES = "completed_cancel_transfer_messages",
    REQUEST_CHANGE_EXPIRY_SENT_MESSAGE = "request_change_expiry_sent_message",
    COMPLETED_CHANGE_EXPIRY_SENT_MESSAGE = "completed_change_expiry_sent_message",
    REQUEST_PRINT_MESSAGES = "request_print_messages",
    COMPLETED_PRINT_MESSAGES = "completed_print_messages",
    REQUEST_MOVE_TO_FOLDER = "request_move_to_folder",
    COMPLETED_MOVE_TO_FOLDER = "completed_move_to_folder",
    REQUEST_RESEND_ACCESS_LINK = "request_resend_access_link",
    COMPLETED_RESEND_ACCESS_LINK = "completed_resend_access_link",
    REQUEST_RESEND_QA_AUTH_ANSWER = "request_resend_qa_auth_answer",
    COMPLETED_RESEND_QA_AUTH_ANSWER = "completed_resend_qa_auth_answer",

    REQUEST_SENT_MESSAGE_DETAIL = "request_sent_message_detail",
    RECEIVED_SENT_MESSAGE_DETAIL = "received_sent_message_detail",
    COMPLETED_SENT_MESSAGE_DETAIL = "completed_sent_message_detail",

    REQUEST_REPLY_SENT_MESSAGE = "request_reply_sent_message",
    RECEIVED_REPLY_SENT_MESSAGE = "received_reply_sent_message",
    COMPLETED_REPLY_SENT_MESSAGE = "completed_reply_sent_message",

    REQUEST_FORWARD_SENT_MESSAGE = "request_forward_sent_message",
    RECEIVED_FORWARD_SENT_MESSAGE = "received_forward_sent_message",
    COMPLETED_FORWARD_SENT_MESSAGE = "completed_forward_sent_message",

    SENT_MESSAGE_ARRIVAL="sent_message_arrival", 
    REQUEST_SENT_MESSAGE_ACTIVITY_LOG = "request_sent_message_activity_log",
    RECEIVED_SENT_MESSAGE_ACTIVITY_LOG= "received_sent_message_activity_log",
    COMPLETED_SENT_MESSAGE_ACTIVITY_LOG = "completed_sent_message_activity_log",
  }
  