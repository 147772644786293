import React from "react";
import { ColorConstants } from "../../../../../assets/custom/colors";
import { IDocumentRequestTemplate } from "../../../../../core/viewModels/template/TemplateModel";
import { CopyIcon, DeleteIcon } from "../../../../svg/ToolbarIcons";

interface ITemplateInlineActionsProps {
    selectedTemplate: IDocumentRequestTemplate;
    handleOnDelete: (selectedTemplate: IDocumentRequestTemplate[]) => void;
    handleOnEditTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
    handleOnDuplicateTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
}

export const TemplateInlineActions: React.FC<ITemplateInlineActionsProps> = ({
    selectedTemplate,
    handleOnDelete,
    handleOnEditTemplate,
    handleOnDuplicateTemplate
}) => {
    return (
        <div className="inline-actions-bar-container">
            <div className="folder-toolbar__action">
                <div className={"pointer"} title={"Edit"} onClick={() => handleOnEditTemplate(selectedTemplate)}>
                    <i
                        style={{
                            color: ColorConstants.grey,
                        }}
                        className="fas fa-pen"
                    ></i>
                </div>
            </div>

            <div className="folder-toolbar__action">
                <span onClick={() => handleOnDelete([selectedTemplate])} title={"Delete"} className="pointer">
                    <DeleteIcon width={16} height={16} color={ColorConstants.grey} disabled={false} />
                </span>
            </div>

            <div className="folder-toolbar__action">
                <span title={"Duplicate"} className="pointer" onClick={()=>handleOnDuplicateTemplate(selectedTemplate)}>
                    <CopyIcon color={ColorConstants.grey} disabled={false} />
                </span>
            </div>
        </div>
    );
};
