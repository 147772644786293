export enum actionTypes {
  REQUEST_REPORT_FILTERS = "request_report_filters",
  RECEIVE_REPORT_FILTERS = "receive_report_filters",
  COMPLETED_REPORT_FILTERS = "completed_report_filters",

  REQUEST_SAVE_FILTERS = "request_save_filters",
  RECEIVE_SAVE_FILTERS = "receive_save_filters",
  COMPLETED_SAVE_FILTERS = "completed_save_filters",

  REQUEST_DELETE_FILTERS = "request_delete_filters",
  RECEIVE_DELETE_FILTERS = "receive_delete_filters",
  COMPLETED_DELETE_FILTERS = "completed_delete_filters",

  REQUEST_SENDERS_LIST = "request_senders_list",
  RECEIVE_SENDERS_LIST = "receive_senders_list",
  COMPLETED_SENDERS_LIST = "completed_senders_list",
}