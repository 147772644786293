import * as React from "react";
import { useSelector } from "react-redux";
import { StatusType } from "../../reducers/notificationReducer";
import { ApplicationState } from "../../store";
import { AppNotifier } from "../../helper/AppNotifier";

export const Notification: React.FunctionComponent = () => {
  const notification = useSelector(
    (state: ApplicationState) => state.notification
  );

  React.useEffect(() => {
    if (notification.type == StatusType.Success) {
      AppNotifier.Success(notification.message);
    } else if (notification.type == StatusType.Error) {
      AppNotifier.Error(notification.message);
    } else if (notification.type == StatusType.Warning) {
      AppNotifier.Warning(notification.message);
    } else {
      AppNotifier.Info(notification.message);
    }

  },[notification.identifier]);

  return <div />;
};
