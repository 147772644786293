import axios, { AxiosResponse, AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { userPrivilegesChanged } from "./actionCreators/signalRActionCreator";
import store from "./configureStore";

type ErrorResponse = {
    message: any;
    logoutCause: any
}

const AxiosInterceptorsSetup = (navigate: any) => {
    const dispatch = useDispatch();
    // Add a request interceptor
    axios.interceptors.request.use(function (config: any) {
        const token = store.getState().userAuth.user?.access_token;
        if (process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL_SUITE) {
            const isApiUrl =
                (config.url && config.url.startsWith(process.env.REACT_APP_API_URL)) ||
                config.url?.includes("signalr") ||
                (config.url && config.url.startsWith(process.env.REACT_APP_API_URL_SUITE));

            if (token && isApiUrl) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
        }
        return config;
    });

    axios.interceptors.response.use(
        function (response: AxiosResponse) {
            if (response.status == 419) {
                throw Promise.reject(response);
            }
            return response;
        },
        function (error: AxiosError<ErrorResponse>) {
            const errorResponseStatus = error.response?.status;
            if (errorResponseStatus == 512) {
                if (location.pathname !== "/restricted-access") {
                    navigate("/restricted-access/" + error.response?.data.message);
                }
            } else if (errorResponseStatus == 419) {
                dispatch(userPrivilegesChanged(error.response?.data.logoutCause));
                return error.response;
            } else {
                if (window.location.href.includes("restricted-access")) {
                    navigate("/");
                }
            }
            return Promise.reject(error);
        }
    );
};

export default AxiosInterceptorsSetup;
