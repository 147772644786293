import {
  AuthenticationMethod,
  MessageSource,
  MessageStatus,
  MessageType,
} from "../../common/enums";
import { IReceivedMessageRecipientModel } from "./ReceivedMessageRecipientModel";

export interface IReceivedMessageModel {
  type?: MessageType.INBOX; //discriminator
  receivedMessageId: number;
  receivedMessageGuid: string;
  senderId: number;
  senderEmail: string;
  senderFirstName: string;
  senderLastName: string;
  subject: string;
  message: string;
  notifyWhenDownloaded: boolean;
  sentDate: Date;
  expiresDays: number;
  isFileDownloaded: boolean;
  messageType: MessageType;
  authenticationMethod: AuthenticationMethod;
  linkId: string;
  folderId: number;
  messageStatus: MessageStatus;
  messageSource: MessageSource;
  secureMessage: string;
  passcode: string;
  isSendPasscodeEmail: boolean;
  receivedMessageRecipients: IReceivedMessageRecipientModel[];
  filteredMessage: string;
}

export const initialReceivedMessage: IReceivedMessageModel = {
  receivedMessageId: 0,
  receivedMessageGuid: "",
  senderId: 0,
  senderEmail: "",
  senderFirstName: "",
  senderLastName: "",
  subject: "",
  message: "",
  notifyWhenDownloaded: false,
  sentDate: new Date(),
  expiresDays: 0,
  isFileDownloaded: false,
  messageType: MessageType.UNKNOWN,
  authenticationMethod: AuthenticationMethod.UNKNOWN,
  linkId: "",
  folderId: 0,
  messageStatus: MessageStatus.Pending,
  messageSource: MessageSource.UNKNOWN,
  secureMessage: "",
  passcode: "",
  isSendPasscodeEmail: false,
  receivedMessageRecipients: [],
  filteredMessage: ""
};
