import React from "react";
interface IAuthMethodCardProps {
  cardTitle: string
  cardImage: any
  isDefault: boolean
  onMethodClick: (type: string) => void
  isActive: boolean,
  allowEmployeesToChangeDefaultMethod: boolean,
  allowEmployeesToChangeAuthMethod: boolean
}

const AuthMethodCard: React.FC<IAuthMethodCardProps> = props => {
  return (
    <div
      className={`AuthMethodCard-wrapper flex-coloumn-centred ${props.isActive ? "active-card" : " "}`}
      onClick={() => props.onMethodClick("isEnabled")}
    >
      <p className={!props.isActive ? "special-title-text" : ""}>{props.cardTitle}</p>
      <img className="auth_method_img" src={props.cardImage} alt="Auth Method Icon" />
      {(!props.isDefault && props.isActive) && <a 
        href="#" 
        onClick={(e) => {e.stopPropagation(); props.onMethodClick("isDefault");}}
        className="link-primary mark-default-link">
        Set as default
      </a>}
      {
        props.isDefault && <p className="link-primary mark-default-link always-show">Default</p>
      }
    </div>
  );
};

export default AuthMethodCard;
