import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { FolderType } from "../../../core/common/enums";
import { ApplicationState } from "../../../store";
import MessageList from "./parts/MessageList";
import { initialReceivedMessage, IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import {
    fetchSpamMessages,
    markAsNotSpam,
    moveToInbox,
    getMessageDetail,
    deleteMessage,
} from "../../../actionCreators/spamMessageActionCreators";
import MessageDetail from "../../common/message/MessageDetail";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { moveToFolder, printMessages } from "../../../actionCreators/receivedMessageActionCreators";
import { IMessageResponseModel } from "../../../core/viewModels/message/MessageResponseModel";
import { MessageListConstants, initialPagination, viewingOthersInboxLabel } from "../../../helper/Constants";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { currentPageChanged, spamPageChanged } from "../../../actionCreators/signalRActionCreator";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";

const Spam: React.FC = () => {
    const [isMessageDetailShow, toggleMessageDetailShow] = useState(false);
    const [pagination, setPagination] = useState(initialPagination);
    const [selectedMessage, setSelectedMessage] = useState(initialReceivedMessage);
    const [reload, setReload] = useState(false);

    const dispatch = useDispatch();
    const messageState = useSelector((state: ApplicationState) => state.spamMessageState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);

    useEffect(() => {
        dispatch(getRestrictedFileTypes());
        dispatch(currentPageChanged(FolderType.SPAM));
        logger.trackPageView(appInsightsData.MailBox.Spam.PageTitle);
    }, []);

    const fetchMessages = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string
    ) => {
        dispatch(
            fetchSpamMessages(FolderType.SPAM, pageNo, pageSize, sortColumn, sortOrder, search, onFetchMessagesComplete)
        );
    };

    const pageNumberChanged = (pageNo: number) => {
        dispatch(spamPageChanged(pageNo));
    };

    const onFetchMessagesComplete = (result: IMessageResponseModel<IReceivedMessageModel>) => {
        if (result && result.messages && result.messages.length > 0) {
            dispatch(
                getMessageDetail(
                    result.messages[0].receivedMessageId,
                    result.messages[0].messageType,
                    messageDetailCallback
                )
            );
        }
    };

    const messageDetailCallback = (result: any) => {
        if (result.messageDetail) toggleMessageDetailShow(true);
        else toggleMessageDetailShow(false);
    };

    const handleOnDeleteMessage = (messages: IReceivedMessageModel[], callback: any) => {
        dispatch(deleteMessage(messages, callback, ResourceIdLocators.Spam.MsgListBtnDelete));
    };

    const handleGetMessageDetail = (message: IReceivedMessageModel) => {
        dispatch(getMessageDetail(message.receivedMessageId, message.messageType, messageDetailCallback));
    };

    const handleMessageDetailView = (result: boolean) => {
        if (result) toggleMessageDetailShow(false);
    };

    const handleMarkAsNotSpam = (messages: number[], callback?: any) => {
        dispatch(markAsNotSpam(messages, callback));
    };

    const handleMoveToInbox = (messageIds: number[], callback?: any) => {
        dispatch(moveToInbox(messageIds, callback));
    };

    const handleOnPrint = (): void => {
        dispatch(printMessages());
    };

    const checkFolderExpiry = (folderId: number): boolean => {
        // let destinationFolderExpiryDays: number | undefined;
        // if (!folderState.foldersData.isLoading) {
        //   destinationFolderExpiryDays = folderState.foldersData.folders?.find(
        //     (f) => f.folderId === folderId
        //   )?.expiresDays;
        // }
        if (folderId) {
            return true;
        }
        return true;
    };

    const handleOnMoveToFolder = (messages: IReceivedMessageModel[], folderId: number, callback?: any): void => {
        dispatch(
            moveToFolder(
                messages,
                folderId,
                callback,
                appInsightsData.MailBox.Spam.PageTitle,
                ResourceIdLocators.Spam.MsgListBtnMoveToInbox
            )
        );
    };

    const resetSearchAndSort = () => {
        setReload(value => !value);
    };

    const onMessageListReload = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string
    ) => {
        dispatch(
            fetchSpamMessages(FolderType.SPAM, pageNo, pageSize, sortColumn, sortOrder, search, onFetchSpamMessagesCallBack)
        );
    };

    const onFetchSpamMessagesCallBack = () => {
        selectedMessage.receivedMessageId > 0 &&
            dispatch(
                getMessageDetail(selectedMessage.receivedMessageId, selectedMessage.messageType, messageDetailCallback)
            );
    };
    
    return (
        <Row className="height-100-percent width-100-percent margin-left-0">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <Col sm={4} className="no-padding-left">
                <MessageList
                    folderId={FolderType.SPAM}
                    folderType={FolderType.SPAM}
                    isLoading={messageState.isLoading}
                    messages={messageState.data.messages}
                    totalRecords={messageState.data.totalRecords}
                    fetchMessages={fetchMessages}
                    pageNumberChanged={pageNumberChanged}
                    deleteMessages={handleOnDeleteMessage}
                    getMessageDetail={handleGetMessageDetail}
                    markAsNotSpam={handleMarkAsNotSpam}
                    moveToInbox={handleMoveToInbox}
                    print={handleOnPrint}
                    checkFolderExpiry={checkFolderExpiry}
                    moveToFolder={handleOnMoveToFolder}
                    hideMessageDetailView={handleMessageDetailView}
                    pagination={pagination}
                    setPagination={setPagination}
                    resourceLocators={ResourceIdLocators.Spam}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                    isSearchEnabled={true}
                    reload={reload}
                    reloadMessageList={onMessageListReload}
                />
            </Col>
            <Col sm={8}>
                <LoadingOverlay style={{ height: "100%" }}>
                    {isMessageDetailShow && messageState.data.messages.length > 0 ? (
                        <MessageDetail
                            message={messageState.mailData.data}
                            messageId={messageState?.mailData?.data?.messageDetail?.receivedMessageId}
                            folderType={FolderType.SPAM}
                            hideMessageDetailView={handleMessageDetailView}
                            pagination={pagination}
                            fetchFolderMessages={fetchMessages}
                            resourceLocators={ResourceIdLocators.Spam}
                            resetSearchAndSort={resetSearchAndSort}
                        />
                    ) : (
                        messageState.mailData.isLoading || (
                            <div className="no-message-selected">{MessageListConstants.NO_MESSAGE_SELECTED}</div>
                        )
                    )}
                    <Loader loading={messageState.mailData.isLoading} />
                </LoadingOverlay>
            </Col>
        </Row>
    );
};

export default Spam;
