import React, { useRef } from "react";

export interface IFileUploadModel {
  file: File;
  uploadProgress: number;
  guid: string;
  isUploaded: boolean;
  fileSize?: number;
  fileId?: number;
  displayName?: string;
}

interface IAttachmentUploaderProps {
  onDropFiles: (e: any, callback?: any) => void;
}

const AttachmentUploader: React.FC<IAttachmentUploaderProps> = (props) => {
  const inputFileRef: any = useRef();

  return (
    <>
      <label
        className="button-primary-blue-2 attach-button"
        htmlFor="uploadFile"
      >
        Attach
      </label>
      <input
        ref={inputFileRef}
        type="file"
        id="uploadFile"
        onChange={(e) =>
          props.onDropFiles(e, () => (inputFileRef.current.value = ""))
        }
        multiple
      />
    </>
  );
};

export default AttachmentUploader;
