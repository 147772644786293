import { Action, Reducer } from "redux";
import { produce } from "immer";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { initialSentMessage, ISentMessageModel } from "../core/viewModels/message/SentMessageModel";
import * as SentMessageActionCreators from "../actionCreators/sentMessageActionCreators";
import { actionTypes } from "../types/sentMessageTypes";
import { ISentMailMessage } from "../core/viewModels/message/SentMailMessageModel";
import { IActivityLogData } from "../core/viewModels/report/reportViewModel";

export interface ISentMessageState {
  data: IMessageResponseModel<ISentMessageModel>;
  isLoading: boolean;
  mailData: {
    data: ISentMailMessage;
    isLoading: boolean;
    activityLogData: IActivityLogData[]
    isActivityDataLoading: boolean;
  };
}

const initialState: ISentMessageState = {
  isLoading: false,
  data: {
    messages: [],
    totalRecords: 0,
  },
  mailData: {
    data: {
      messageDetail: initialSentMessage,
      attachments: [],
      recipients: [],
      recipientSecurityQuestions: []
    },
    isLoading: false,
    activityLogData: [],
    isActivityDataLoading:false,
  },
};

export const reducer: Reducer<ISentMessageState> = produce(
  (draft: ISentMessageState = initialState, incomingAction: Action) => {
    const action = incomingAction as SentMessageActionCreators.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_SENT_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.RECEIVED_SENT_MESSAGES:
        draft.isLoading = false;
        draft.data = action.payload;
        return draft;

      case actionTypes.REQUEST_DELETE_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_DELETE_MESSAGES:
        draft.isLoading = false;
        return draft;

      case actionTypes.REQUEST_CANCEL_TRANSFER_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_CANCEL_TRANSFER_MESSAGES:
        draft.isLoading = false;
        return draft;

      case actionTypes.REQUEST_PRINT_MESSAGES:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_PRINT_MESSAGES:
        draft.isLoading = false;
        return draft;

      case actionTypes.REQUEST_MOVE_TO_FOLDER:
        draft.isLoading = true;
        return draft;

      case actionTypes.COMPLETED_MOVE_TO_FOLDER:
        draft.isLoading = false;
        return draft;

        case actionTypes.REQUEST_RESEND_ACCESS_LINK:
          draft.isLoading = true;
          return draft;
  
        case actionTypes.COMPLETED_RESEND_ACCESS_LINK:
          draft.isLoading = false;
          return draft;

          case actionTypes.REQUEST_RESEND_QA_AUTH_ANSWER:
            draft.isLoading = true;
            return draft;
    
          case actionTypes.COMPLETED_RESEND_QA_AUTH_ANSWER:
            draft.isLoading = false;
            return draft;

            case actionTypes.REQUEST_SENT_MESSAGE_DETAIL:
              draft.mailData.isLoading = true;
              return draft;
      
            case actionTypes.RECEIVED_SENT_MESSAGE_DETAIL:
              draft.mailData.isLoading = false;
              draft.mailData.data = action.payload;
              return draft;
      
            case actionTypes.COMPLETED_SENT_MESSAGE_DETAIL:
              draft.mailData.isLoading = false;
              return draft;
            case actionTypes.SENT_MESSAGE_ARRIVAL:
              draft.data.messages.unshift(action.newMessage);
              return draft;   
              
            case actionTypes.REQUEST_SENT_MESSAGE_ACTIVITY_LOG:
              draft.mailData.isActivityDataLoading= true;
              return draft;

            case actionTypes.RECEIVED_SENT_MESSAGE_ACTIVITY_LOG:
              draft.mailData.isActivityDataLoading = false;
              draft.mailData.activityLogData = action.payload;
              return draft;

            case actionTypes.COMPLETED_SENT_MESSAGE_ACTIVITY_LOG:
              draft.mailData.isActivityDataLoading = false;
              return draft;
 
      default:
        return draft;
    }
  }
);

export const sentMessageReducer = produce(
  (
    draft: { messages: ISentMessageModel[]; isAllSelected: boolean },
    action: any
  ) => {
    switch (action.type) {
      case "add":
        draft.messages.push(action.payload);
        draft.isAllSelected = action.messageCount === draft.messages.length;
        break;
      case "remove":
        const index = draft.messages.findIndex(
          (item) => item.sentMessageId === action.payload.sentMessageId
        );
        if (index > -1) {
          draft.messages.splice(index, 1);
          draft.isAllSelected = false;
        }
        break;
      case "addAll":
        draft.messages = [...action.payload];
        draft.isAllSelected = true;
        break;
      case "removeAll":
        draft.messages = [];
        draft.isAllSelected = false;
        break;
    }
    return draft;
  }
);
