import axios from "axios";
import { actionTypes as spamMessageAction } from "../types/spamMessageTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { IReceivedMessageModel } from "../core/viewModels/message/ReceivedMessageModel";
import { AppThunkAction } from "../store";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import {
    CompletedMarkAsNotSpamAction,
    CompletedMessageDetailAction,
    CompletedMoveToInboxAction,
    CompletedSpamMessagesAction,
    ReceivedMessagesDetailAction,
    ReceivedSpamMessagesAction,
    RequestMarkAsNotSpamAction,
    RequestMessageDetailAction,
    RequestMoveToInboxAction,
    RequestSpamMessagesAction,
    RequestDeleteMessageAction,
    CompletedDeleteMessageAction,
} from "../actions/spamMessageActions";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { MessageType } from "../core/common/enums";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";

export type DispatchActions =
    | RequestSpamMessagesAction
    | ReceivedSpamMessagesAction
    | CompletedSpamMessagesAction
    | RequestMarkAsNotSpamAction
    | CompletedMarkAsNotSpamAction
    | RequestMoveToInboxAction
    | CompletedMoveToInboxAction
    | RequestMessageDetailAction
    | ReceivedMessagesDetailAction
    | CompletedMessageDetailAction
    | RequestDeleteMessageAction
    | CompletedDeleteMessageAction;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchSpamMessages =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        sortColumn = "",
        sortOrder = "",
        searchString = "",
        callback?: (result: IMessageResponseModel<IReceivedMessageModel>) => void
    ): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        dispatch({
            type: spamMessageAction.REQUEST_SPAM_MESSAGES,
        });
        axios.defaults.headers.common["Authorization"] = getState().userAuth.user.access_token
            ? "Bearer " + getState().userAuth.user.access_token
            : "";
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Spam/GetSpamMessages?folderId=${folderId}&pageNo=${pageNo}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchString=${encodeURIComponent(searchString.trim())}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: spamMessageAction.RECEIVED_SPAM_MESSAGES,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: spamMessageAction.COMPLETED_SPAM_MESSAGES,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const markAsNotSpam =
    (messageIds: number[], callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: spamMessageAction.REQUEST_MARK_AS_NOT_SPAM,
        });
        messageIds.map(messageId => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Spam.PageTitle} - ${appInsightsData.MailBox.CommonOperations.markAsNotSpam}`,
                properties: {
                    page: appInsightsData.MailBox.Spam.PageTitle,
                    messageId,
                },
            });
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/Spam/MarkMessagesAsNotSpam`, messageIds)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: spamMessageAction.COMPLETED_MARK_AS_NOT_SPAM,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: spamMessageAction.COMPLETED_MARK_AS_NOT_SPAM,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const moveToInbox =
    (messageIds: number[], callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: spamMessageAction.REQUEST_MOVE_TO_INBOX,
        });

        messageIds.map(messageId => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Spam.PageTitle} - ${appInsightsData.MailBox.CommonOperations.MoveToInbox}`,
                properties: {
                    page: appInsightsData.MailBox.Spam.PageTitle,
                    messageId,
                },
            });
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/Spam/MoveSpamMessagesToInbox`, messageIds, {
                params: {
                    mailboxUserEmailId,
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: spamMessageAction.COMPLETED_MOVE_TO_INBOX,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: spamMessageAction.COMPLETED_MOVE_TO_INBOX,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const getMessageDetail =
    (
        messageId: number,
        messageType: MessageType,

        callback?: any
    ): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        dispatch({
            type: spamMessageAction.REQUEST_SPAM_MESSAGE_DETAIL,
        });
        axios.defaults.headers.common["Authorization"] = getState().userAuth.user.access_token
            ? "Bearer " + getState().userAuth.user.access_token
            : "";
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/ReceivedMessage/GetReceivedMessageDetail?messageId=${messageId}&messageType=${messageType}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: spamMessageAction.RECEIVED_SPAM_MESSAGE_DETAIL,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: spamMessageAction.COMPLETED_SPAM_MESSAGE_DETAIL,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const deleteMessage =
    (messages: IReceivedMessageModel[], callback?: any, resourceId?: string): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: spamMessageAction.REQUEST_DELETE_MESSAGES,
        });

        messages.map(message => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Spam.PageTitle} - ${appInsightsData.MailBox.CommonOperations.Delete}`,
                properties: {
                    page: appInsightsData.MailBox.Spam.PageTitle,
                    ...message,
                },
            });
        });

        axios.defaults.headers.common["Authorization"] = getState().userAuth.user.access_token
            ? "Bearer " + getState().userAuth.user.access_token
            : "";
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/Spam/DeleteSpamMessages`, {
                params: {
                    mailboxUserEmailId,
                },
                data: messages,
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: spamMessageAction.COMPLETED_DELETE_MESSAGES,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: spamMessageAction.COMPLETED_DELETE_MESSAGES,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };
