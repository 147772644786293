// import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import axios from "axios";


export interface PostAuthStoreState {
    exchangeEnabled: boolean;
    loading: boolean;
    loggedOut: boolean;
}

const initalPostAuthStoreState: PostAuthStoreState = {
    exchangeEnabled: false,
    loading: false,
    loggedOut: false
};

type KnownAction = LoaderAction | RecieveDataAction | LoggedOutAction;

export interface LoaderAction {
    type: "PostAuthStoreLoader";
    loading: boolean;
}

export interface RecieveDataAction {
    type: "PostAuthStoreReceiveData";
    exchangeEnabled: boolean;
}

export interface LoggedOutAction {
    type: "LoggedOut";
    loggedOut: boolean;
}

let authDataExists = false;

export const actionCreators = {
    requestPostAuthData: (): AppThunkAction<KnownAction> => (dispatch) => {

        if (authDataExists) {
            return;
        }
        dispatch({ type: "PostAuthStoreLoader", loading: true });

        axios.get(`${process.env.REACT_APP_API_URL}Company/IsProductEnabledInCompany`)
            .then((data:any) => {
                console.log("Company/IsProductEnabledInCompany", data);
                authDataExists = true;
                dispatch({ type: "PostAuthStoreReceiveData", exchangeEnabled: data.exchangeEnabled });
                dispatch({ type: "PostAuthStoreLoader", loading: false });
            }).catch(() => {
                dispatch({ type: "PostAuthStoreLoader", loading: false });
            });
    },
    loggedOut: () : AppThunkAction<KnownAction> => (dispatch) => {
        console.log("loggedOut called Automatically");
        dispatch({ type: "LoggedOut", loggedOut: true });
    }
};

export const reducer: Reducer<PostAuthStoreState> = (
    state: PostAuthStoreState = initalPostAuthStoreState,
    incomingAction: Action) => {

    const action = incomingAction as KnownAction;

    switch (action.type) {

        case "PostAuthStoreLoader":
            return { ...state, loading: action.loading };

        case "PostAuthStoreReceiveData":
            return { ...state, exchangeEnabled: action.exchangeEnabled };

        case "LoggedOut":
            return { ...state, loggedOut: action.loggedOut };

        default: return state || initalPostAuthStoreState;
    }
};