import { FolderType } from "../core/common/enums";
import { DownloadedFrom } from "../core/viewModels/file/FileModel";
import { IPagination } from "../core/viewModels/message/SentMessageModel";

export const LocalStorageConstants = {
    IsAdminKey: "isAdmin",
    UserIdKey: "userId",
    otherUser: "mailboxUserData",
};

export const SortAndFilterConstants = {
    SORT_ORDER_ASC: "asc",
    SORT_ORDER_DESC: "desc",
    SORT_BY_TEXT: "Sort By",
    FILTER_BY_TEXT: "Filter By",
    ALPHABETICAL_ORDER_ASC: "A-Z",
    ALPHABETICAL_ORDER_DESC: "Z-A",
    NEWEST_FIRST_ORDER: "Newest First",
    OLDEST_FIRST_ORDER: "Oldest First",
    ALL_STATUSES_ORDER: "All Statuses",
    COMPLETED_ORDER: "Completed",
    PENDING_UPLOAD_ORDER: "Pending Upload",
    PARTIALLY_UPLOAD_ORDER: "Partially Uploaded",
    OVERDUE_ORDER: "Overdue",
};

export enum SortAndFilterIconType {
    Sort = 1,
    Filter = 2,
}

export const ValidationContants = {
    FirstNameWarning: "Please enter first name.",
    LastNameWarning: "Please enter last name.",
    EmailAdrressWarning: "Please enter email address.",
    ValidEmailAddressWarning: "Please enter valid  email address.",
    ValidIdentityServerEmailAddressWarning:
        "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
    EmailNotMatching: "Email and Confirm Email did not match",
    PhoneNumberWarning: "Please enter the phone number.",
    PhoneNumberLengthWarning: "Please enter a 10 digit phone number.",
    MobileNumberWarning: "Please enter the mobile number.",
    MobileNumberLengthWarning: "Please enter a 10 digit mobile number.",
    CountryCodeWarning: "Please select the country code.",
    PTINWarning: "PTIN should start with P followed by 8 digit number.",
    EnterPasswordWarning: "Please enter password and confirm password.",
    PasswordNotMatchedWarning: "Password and confirmed password did not match.",
    NewPasswordWarning: "Please enter new password.",
    OldPasswordWarning: "Please enter old password",
    ConfirmPasswordWarning: "Please enter confirm password",
    PasswordLengthWarning: "Password must be 8 characters.",
    PasswordWithoutSpaceWarning: "Please enter password without space.",
    ZipWarning: "Please enter zip.",
    ZipLengthWarning: "Please enter a 5 digit zip.",
    ExtensionWarning: "Please enter less than 7 digit extension number.",
    FaxWarning: "Please enter a 10 digit fax number.",
    StateWarning: "Please select state.",
    CityWarning: "Please enter city.",
    ValidCityWarning: "Please enter valid city name.",
    PdfFileValidation: "Please upload Pdf files only.",
    PaymentURLValidation: "Please enter a valid payment URL.",
    FirmKeyWarning: "Please enter valid firm key.",
    AzureGroupIdWarning: "Please enter valid Azure Group Id.",
    PasswordWarning: "Please enter password.",
    K1InstructionNotAVailable: "K1 instruction is not available in the uploaded file",
    NoContentAvailable: "No content available",
    EINWarning: "Please enter valid EIN.",
    FileFormatNotSupported: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
    FileSizeExceeded:
        "Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.",
    UpperCaseValidationError: "Password should contain at least one uppercase character.",
    LowerCaseValidationError: "Password should contain at least one lowercase character.",
    NumbersValidationError: "Password should contain at least one number.",
    SpecialCharactersValidationError: "Password should contain at least one special character.",
    CsvFileValidation: "Please upload Csv files only.",
    SameEmailValidation: "Email Already selected.",
    NotSelectedAnyMessageWarning: "Please select any message.",
    EmptyInputFieldWarning: "This field can't be empty",
    DateInvalidWarning: "Please enter valid date",
    DocumentCategoryWarning: "Please enter document category name",
};
export const BREADCRUMB_MAX_FOLDER_LEVEL = 5;
export const BREADCRUMB_MAX_FOLDER_LEVEL_ARCHIVE_TABLE = 3;
export const MyAccountConstants = {
    //API response
    APIResponse: {
        UserUpdateSuccess: "User updated successfully.",
    },
    UserUpdateFailedMessage: "Updating user details failed, please re-login",
};
export const viewingOthersInboxLabel = (firstName: string, lastName: string) =>
    `You are viewing the mailbox of ${firstName ? firstName : ""} ${lastName ? lastName : ""}`;

export const Version = {
    VersionNumber: "V 1.0",
};

export const CATEGORY_DEFAULT_PAGE_SIZE = 8;

export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 9999;
export const MAX_ALLOWED_RECIPIENTS = 50;

export const ChangeSecurityConstants = {
    SECURITY_CHANGE_MODAL_TITLE: "Change Password",
    CONFIRM_BUTTON_NAME: "Confirm",
    SECURITY_CHANGE_LOGOUT_WARNING: "Changing your Password will require you to login again. Make the change?",
    SECURITY_UPDATE_SUCCESS: "Password updated successfully.",
    SECURITY_UPDATE_FAILED: "Updating password failed, please re-login",
};

export const MessageTitleConstants = {
    TITLE_SENT: "SENT MESSAGE",
    TITLE_INBOX: "INBOX MESSAGE",
    TITLE_ARCHIVE: "FOLDER MESSAGE",
    TITLE_SPAM: "SPAM MESSAGE",
};
export const ArchiveConstants = {
    TITLE_SELECT_FOLDER: "Select Folder",
    ChangeExpiry: {
        CONFIRMATION_MSG:
            "Changing the expiry of this folder will also change the expiry of all messages in this folder.",
        CONFIRMATION_TITLE: "Confirmation",
        BTN_OK: "OK",
        BTN_CANCEL: "Cancel",
        CHANGE_EXPIRY_SUCCESS_MSG:
            "The expiry of the selected folder and all messages in this folder has been changed successfully.",
    },
    AddNewFolder: {
        TITLE: "Add New Folder",
        FOLDER_NAME_CANNOT_BE_EMPTY_ERROR_MSG: "Please enter folder name.",
        // eslint-disable-next-line quotes
        FOLDER_NAME_CANNOT_HAVE_SPECIAL_CHARS_ERROR_MSG: 'Folder name cannot have special characters /\\:*?"<>|',
        FOLDER_NAME_ALREADY_EXISTS_ERROR_MSG: "Folder name already exists.",
        SUB_FOLDER_MUST_HAVE_PARENT: "Please select location.",
        SUB_FOLDER_RETENTION_ERROR_MSG: "Please select retention period.",
        SUCCESS_MSG: "New folder created successfully.",
        FOLDER_TYPE_SUBFOLDER: "Sub folder",
        FOLDER_TYPE_PRIMARY: "Primary",
        FOLDER_NAME_MAXLENGTH: 50,
        FOLDER_DESCRIPTION_MAXLENGTH: 100,
    },
    MessageView: {
        MAIL_VIEW_BUTTON_TEXT: "Mail View",
        FILE_VIEW_BUTTON_TEXT: "File View",
        NO_DATA_MESSAGE: "There are no items to show here",
    },
    ShareFolder: {
        NO_DATA_MESSAGE: "There are no items to show here.",
        SHARE_FOLDER_MODAL_TITLE: "Share Folder",
        SHARE_FOLDER_TEXT: "You are sharing <foldername> with:",
        SHARE_FOLDER_BUTTON_TEXT: "Share",
        SHARE_FOLDER_SUCCESS_MESSAGE: "Folder shared successfully.",
        REMOVE_FOLDER_SUCCESS_MESSAGE: "User removed successfully.",
        UPDATE_FOLDER_SUCCESS_MESSAGE: "Permission updated successfully.",
        PLACEHOLDER_SEARCH_TEXT: "Search",
    },
    NotSharedFolderText: "Only You",
    SharedWithShowLimit: 3,
    PLACEHOLDER_SEARCH_TEXT: "Search in",
    REFRESH_TITLE: "Refresh",
    FolderPermissionChanged:
        "The owner has made changes to this folder. The page will automatically refresh to display these updates.",
};

export const CreateFolderModalConstants = {
    CREATE_FOLDER_MODAL_TITLE: "Create Folder",
    EDIT_FOLDER_MODAL_TITLE: "Edit Folder",
    FOLDER_NAME_LABEL: "Folder Name",
    FOLDER_NAME_PLACEHOLDER: "Enter folder name",
    FOLDER_DESCRIPTION_LABEL: "Description",
    FOLDER_DESCRIPTION_PLACEHOLDER: "Add description",
    FOLDER_RETENTION_PERIOD_LABEL: "Retention Period",
    FOLDER_RETENTION_PLACEHOLDER: "Select Retention Period",
};

export const DownloadType = {
    single: "S",
    multiple: "M",
};

export const MessageToolbarConstants = {
    TITLE_CHANGE_EXPIRY: "Change Expiry",
    TITLE_DELETE: "Delete",
    TITLE_CANCEL: "Cancel",
    TITLE_MOVE_TO_FOLDER: "Move To",
    TITLE_NOT_SPAM: "Mark as Not a Spam",
    TITLE_MOVE_TO_INBOX: "Move To Inbox",
    TITLE_PRINT: "Print",
    TITLE_CANCEL_TRANSFER: "Cancel Transfer",
    TITLE_RESEND_ACCESS_LINK: "Resend Access Link",
    TITLE_RESEND_QA_AUTH_ANSWER: "Resend QA Authentication Answer",
    DELETE_MESSAGE: "Are you sure you want to delete the selected message(s)?",
    DELETE_SINGLE_MESSAGE: "Are you sure you want to delete the selected message?",
    SENT_DELETE_MESSAGE: `You are about to delete the selected Message(s) which has not yet
  reached the expiration date. Doing so will not remove the file or
  disable the link you sent. To do that you must Cancel the Transfer,
  which will delete all copies of the message, invalidate any download
  links and delete the files. Would you like to Cancel the Transfer or
  just the Delete the Message?`,
    CANCEL_TRANSFER_MESSAGE:
        "This action will delete all message copies and prevent the recipient from accessing the link or downloading the files. Are you sure you want to cancel transfer for the selected message(s)?",
    TITLE_DELETE_POPUP: "Delete Message",
    DELETED_SUCCESS: "The selected message(s) have been deleted successfully",
    CHANGE_EXPIRY_SUCCESS: "Message expiry updated successfully.",
    MOVE_TO_FOLDER_SUCCESS: "Message(s) moved successfully",
    MARK_AS_SPAM_SUCCESS: "Messages(s) has been marked as not spam",
    MOVE_TO_INBOX_SUCCESS: "Messages(s) has been moved to inbox",
    CANCEL_MESSAGES_SUCCESS_MSG: "The selected message(s) cancelled successfully.",
    RESEND_LINK_SUCCESS: "Access link resent successfully",
    RESEND_AUTH_ANSWER_SUCCESS: "Authentication answer resent successfully",
    DESTINATION_FOLDER_EXPIRY_WARNING:
        "The retention period of the selected folder is less than the current folder. Moving to this folder will change retention period of this item(s).",
    TITLE_DOWNLOAD_ALL: "Download All",
    MESSAGE_MOVING_MULTIPLE_FOLDERS_WARNING: "Message can't be moved here as it contains subfolders",
};

export function getDownloadedFrom(downloadType: string, folderId: FolderType): DownloadedFrom {
    let from: DownloadedFrom = DownloadedFrom.MPI_Download_Inbox_Single_File;
    switch (folderId) {
        case FolderType.INBOX:
            from =
                downloadType === DownloadType.single
                    ? DownloadedFrom.MPI_Download_Inbox_Single_File
                    : DownloadedFrom.MPI_Download_Inbox_Multiple_File;
            break;
        case FolderType.SENT:
            from =
                downloadType === DownloadType.single
                    ? DownloadedFrom.MPI_Download_SentItem_Single_File
                    : DownloadedFrom.MPI_Download_SentItem_Multiple_File;
            break;
        case FolderType.ARCHIVE:
            from =
                downloadType === DownloadType.single
                    ? DownloadedFrom.MPI_Download_Archive_Single_File
                    : DownloadedFrom.MPI_Download_Archive_Multiple_File;
            break;
    }
    return from;
}

export const UserManagementConstants = {
    SavingUserError: "Saving user details failed, please re-login",
    UserUpdationSuccess: "User details has been updated.",
    UserLogoutWarnigMessage:
        "Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite.",
};

export const OTPVerify = {
    InvalidOTP: "Invalid access code",
    validOTP: "Access code verified successfully",
};

export const CompanySettingsConstants = {
    OverlayMessage: {
        ApplicationLoading: "Loading, please wait...",
    },
    StatusMessage: {
        CompanySettingsError: "Error occured while fetching company settings",
    },
    APPLY_CHANGES_SUCCESS: "Admin settings have been updated.",
};

export const FileConstants = {
    MaximumFileSize: "3221225472",
    MaximumTotalFileSize: 3221225472,
};

export const MAX_SUBJECT_LENGTH = 250;

export const MessageListConstants = {
    NO_MESSAGE_TEXT: "No message(s) here.",
    NO_MESSAGE_SELECTED: "No message selected.",
};

export const ThemeConstants = {
    ICON_DISABLE_COLOR: "#939393",
    SIDEMENU_ICON_COLOR: "#ffffffcc",
    SELECT_DROPDOWN_STYLES: {
        input: {
            border: "none",
        },
    },
};

export const AuthMethodLabels = {
    ACCESSCODE: "Access Code",
    QUESTIONANSWER: "Question & Answer",
    LOGINPASSWORD: "Login",
};

export const DropOffLinkTypeLabels = {
    COMPANY: "Company",
    PERSONAL: "Personal",
    NONE: "None",
};

export const ShareFolderTypeLabels = {
    VIEW: "view",
    EDIT: "edit",
    REMOVE: "remove",
};

export const ComposeValidations = {
    EMAIL_REQUIRED: "Recipient email is required",
    EMAIL_DUPLICATE: "Recipient already exists",
    INVALID_EMAIL_FORMAT: "Email is invalid",
    QUESTION_REQUIRED: "Question is required",
    ANSWER_REQUIRED: "Answer is required",
    RECIPIENT_INVALID: "No email found",

    RECIPIENT_REQUIRED: "Please add at least one recipient",
    WITHOUT_SUBJECT: "Do you want to send this message without Subject?",
    WITHOUT_ATTACHMENT_WARNING: "Do you want to send this message without attachment(s)?",
    ATTACHMENT_UPLOAD_INCOMPLETE_WARNING: "Upload in progress. Please wait.",
    ATTACHMENT_UPLOAD_FAILED_ERROR:"Sorry! Upload failed. Please try again.",
    UNSAVED_OPTION: "Message options are unsaved",
    RECIPIENT_QUESTIONANSWER_REQUIRED: "Please fill out the fields in Message Options",
    MESSAGE_SENT_SUCCESSFULLY: "Message Sent Successfully!",
    MESSAGE_SEND_FAILED: "There was an error while sending the message. Please try again.",
    UPLOAD_IN_PROGRESS: "Please wait until attachment finishes uploading",
    MAXIMUM_RECIPIENTS_REACHED: "Exceeded the maximum number of recipients",
    DEFAULT_QA_METHOD: "To add more recipients, switch to access code authentication mode",
};

export const ComposeSecurityQuestionValidations = {
    REQUIRE_QUESTION_NAME: "Question name is required",
    REQUIRE_QUESTION: "Question is required",
    DUPLICATE_QUESTION_ERROR_MESSAGE: "Question Title already exists.",
    SECURITY_QUESTION_ADDED_SUCCESS: "Security question saved successfully",
};

export const RetentionPeriodsDescription: { [days: number]: string } = {
    1: "1 Day",
    7: "7 Days",
    14: "14 Days",
    21: "21 Days",
    30: "30 Days",
    90: "90 Days",
    180: "180 Days",
    365: "1 Year",
    1095: "3 Years",
    2555: "7 Years",
};

export const FolderToolbarConstants = {
    TITLE_MOVE_TO_FOLDER: "Move To",
    TITLE_CHANGE_RETENTION: "Change Retention Period",
    TITLE_DELETE: "Delete",
    TITLE_SHARE: "Share",
    TITLE_EDIT: "Edit",
    TITLE_CREATE_FOLDER: "Create Folder",
    FOLDERS_DELETED_SUCCESS: "The selected folder(s) deleted successfully.",
    FOLDER_DELETED_SUCCESS: "The selected folder deleted successfully.",
    RENAMED_FOLDER_SUCCESS: "Folder updated successfully.",
    TITLE_DELETE_FOLDERS: "Delete Folder(s)",
    TITLE_DELETE_FOLDER: "Delete Folder",
    TITLE_FOLDER_DROPOFF: "Folder Link",
    TITLE_FOLDER_DROPOFF_DISABLED: "This action is restricted as the folder contains subfolders.",
    DELETE_FOLDERS: "Are you sure you want to delete the selected folder(s)?",
    DELETE_FOLDER: "Are you sure you want to delete the selected folder?",
    CHANGE_RETENTIONS_SUCCESS_MSG: "Retention period of the selected folder(s) updated successfully.",
    CHANGE_RETENTION_SUCCESS_MSG: "Retention period of the selected folder updated successfully.",
    DESTINATION_FOLDER_EXPIRY_WARNING:
        "The retention period of the selected folder is less than the current folder. Moving to this folder will change retention period of this item(s).",
    DESTINATION_FOLDER_EXPIRY_WARNING_FOR_MESSAGE:
        "The retention period of the selected folder is less than the current message(s). Moving to this folder will change the retention period of those message(s).",
    DESTINATION_FOLDER_GREATER_EXPIRY_WARNING_FOR_MESSAGE:
        "The retention period of the destination folder is greater than the expiry of the selected message(s). Choose another folder.",
    MOVE_TO_FOLDERS_SUCCESS: "The selected folder(s) moved to <destination folder> successfully.",
    MOVE_TO_FOLDER_SUCCESS: "The selected folder moved to <destination folder> successfully.",
    FOLDER_MOVING_TO_PARENT_FOLDER_WARNING: "Already in same location. Select different Path",
    ADD_FOLDER_BUTTON_TEXT: "Add Folder",
};

export const FolderDropOffModalConstants = {
    MODAL_TITLE: "Folder Link",
    MODEL_TITLE_COPY: "Copy",
};

export const DropOffMenuModalConstants = {
    MODAL_TITLE: "Drop Off Links",
    COMPANY_DROPOFF_TEXT: "Company Drop Off Link",
    PERSONAL_DROPOFF_TEXT: "Personal Drop Off Link",
    RECEIVE_NOTIFICATION_TOGGLE_INFO: "Receive notification of new Secure Mail to your inbox",
    RECEIVE_NOTIFICATION_TOGGLE_ENABLE_TOOLTIP:
        "Will receive New Mail notification irrespective of blacklisted/whitelisted sender to your email address.",
    RECEIVE_NOTIFICATION_TOGGLE_DISABLE_TOOLTIP:
        "Emails originating from blacklisted users will be notified of a Spam alert to your email address.",
};

export const ChangeExpiryConstants = {
    CHANGE_EXPIRY_REQUIRED: "Please select Expiry Date.",
};

export const MessageOptionsConstants = {
    MESSAGE_OPTIONS_TITLE_TEXT: "Message Options",
    MESSAGE_OPTIONS_HELPER_TEXT:
        "You can set your default authentication mode and retention period. It will reflect in composing, replying, or forwarding a message.",
    AUTHENTICATION_METHOD: "Authentication Method",
    AUTHENTICATION_METHOD_HELPER_TEXT: "Set the default authentication mode.",
    AUTHENTICATION_METHOD_BODY_HELPER_TEXT:
        "Choose which of the following modes should be made available to users, and set a default among them.",
    CHANGE_DEFAULT_METHOD_TEXT: "Allow employees to change their default method",
    CHANGE_AUTHENTICATION_METHOD_TEXT: "Allow employees to change Authentication Method",
    ALLOW_EMPLOYESS_TO_CHANGE_DEFAULT_METHOD: "allowEmployeesToChangeDefaultMethod",
    ALLOW_EMPLOYESS_TO_CHANGE_AUTH_METHOD: "allowEmployeesToChangeAuthMethod",
    CHANGE_AUTHENTICATION_METHOD_WARNING_TEXT:
        "Turning this off will revert all user's authentication methods to the company's default. Do you want to continue?",
    RETENTION_PERIOD: "Retention Period",
    RETENTION_PERIOD_HELPER_TEXT: "Set the default period for the below mode of transfers.",
    RETENTION_PERIOD_QNA: "Question and Answer",
    RETENTION_PERIOD_ACCESS_CODE: "Access Code",
    RETENTION_PERIOD_LOGIN: "Login",
    RETENTION_PERIOD_DROP_OFF: "Drop Off",
    DROP_OFF_LINKS: "Drop Off Links",
    DROP_OFF_LINKS_HELPER_TEXT: "Set which drop off link to be displayed in mail notification.",
    SELECT_DROP_OFF_LINK: "Select Drop Off link",
    NOTIFICATION_OPTION: "Notification Settings",
    NOTIFICATION_OPTION_HELPER_TEXT: "Manage your message and request level notifications.",
    MESSAGE_ACCORDION_HEADER: "Message Notifications",
    MESSAGE_ACCORDION_HELPER_TEXT: "Manage notifications for Messages.",
    EXPIRATION: "Expiration",
    EXPIRATION_HELPER_TEXT: "Choose if users will receive a notification when file is about to expire.",
    CHANGE_NOTIFY_EXPIRY_TEXT: "Notify recipients when file(s) are about to expire",
    ALLOW_EMPLOYEES_TO_NOTIFY_EXPIRY_TEXT: "Allow employees to update their Expiry Notification setting",
    DOWNLOAD: "Download",
    DOWNLOAD_HELPER_TEXT: "Choose if users will receive a notification when a recipient downloads a file.",
    CHANGE_NOTIFY_DOWNLOAD_TEXT: "Notify when file(s) are downloaded",
    ALLOW_EMPLOYEES_TO_NOTIFY_DOWNLOAD_TEXT: "Allow employees to update their Download Notification setting",
    IS_EXPIRY_NOTIFICATION_ENABLE: "isExpiryNotificationEnable",
    IS_USER_ALLOWED_TO_CHANGE_EXPIRY_NOTIFICATION: "isUserAllowedToChangeExpiryNotification",
    IS_DOWNLOAD_NOTIFICATION_ENABLE: "isDownloadNotificationEnable",
    IS_USER_ALLOWED_TO_CHANGE_DOWNLOAD_NOTIFICATION: "isUserAllowedToChangeDownloadNotification",
};
export const MessageNotificationConstants = {
    MESSAGE_NOTIFICATION_TITLE_TEXT: "Message Notifications",
    MESSAGE_NOTIFICATION_HELPER_TEXT:
        "You can set your default authentication mode and retention period. It will reflect in composing, replying, or forwarding a message.",
};

export const RequestNotificationConstants = {
    REQUEST_COMPLETIONS: "Request Completions",
    REQUEST_REOPEN: "Re Opened Requests",
    REQUEST_NOTIFICATION_HELPER_TEXT: "Manage notifications for Document Requests.",
    REQUEST_NOTIFICATION_COMPLETION_HELPER_TEXT:
        "Choose if users will receive a notification when the request is complete.",
    REQUEST_NOTIFICATION_REOPEN_HELPER_TEXT:
        "Choose if users will receive a notification when the request is reopened.",
    REQUEST_NOTIFICATION_NOTIFY_CPA_WHEN_REQUEST_IS_COMPLETE: "Notify CPA when request(s) are completed",
    REQUEST_NOTIFICATION_ALLOW_EMPLOYEES_TO_UPDATE_COMPLETION_NOTIFICATION:
        "Allow employees to update their Completion Notification setting",
    REQUEST_NOTIFICATION_NOTIFY_TP_WHEN_REQUEST_IS_REOPEN: "Notify recipients when request(s) are reopened",
    REQUEST_NOTIFICATION_ALLOW_EMPLOYEES_TO_UPDATE_REOPEN_NOTIFICATION:
        "Allow employees to update their Re Opened Notification setting",
    IS_REQUEST_COMPLETION_NOTIFICATION_ENABLED: "isRequestCompletionNotificationEnabled",
    IS_REQUEST_COMPLETION_SETTING_ENABLED: "isDocumentRequestCompletionNotificationEnabled",
    IS_REQUEST_REOPENED_SETTING_ENABLED: "isDocumentRequestReopenNotificationEnabled",
    IS_REQUEST_REOPEN_NOTIFICATION_ENABLED: "isRequestReopenNotificationEnabled",
    IS_USER_ALLOWED_TO_CHANGE_REQUEST_COMPLETION_NOTIFICATION_ENABLED:
        "isUserAllowedToChangeRequestCompletionNotification",
    IS_USER_ALLOWED_TO_CHANGE_REQUEST_REOPEN_NOTIFICATION_ENABLED: "isUserAllowedToChangeRequestReopenNotification",
    REQUEST_NOTIFICATION_ACCORDIAN_HEADER: "Request Notifications",
    REQUEST_NOTIFICATION_ACCORDIAN_HELPER_TEXT: "Manage notifications for Document Requests.",
    REQUEST_COMPLETION_USER_SETTINGS: "Completion",
    REQUEST_REOPEN_USER_SETTINGS: "Re Open",
};

export const SupportContactsConstants = {
    SUPPORT_CONTACT_TITLE_TEXT: "Support Contact",
    SUPPORT_CONTACT_HELP_TEXT:
        "Select which user should be the company support contact. This contact information will be displayed in the mails, TP, and Drop off portals.",
    SUPPORT_CONTACT_DROP_DOWN_TEXT: "Choose contact",
    SUPPORT_CONTACT_TOOGLE_SW_TEXT: "Display Phone Number",
};
export const CompanyAuthQuestionsConstants = {
    TITLE_TEXT: "Authentication Questions",
    TITLE_HELPER_TEXT: "Manage security questions for Q&A authentication mode.",
    ALLOW_TO_ADD_OWN_QUESTIONS: "isUserAllowedToAddSecurityQuestion",
    ALLOW_TO_ADD_QUESTION_TOGGLE_TEXT: "Allow employees to add their own questions",
    ALLOW_TO_SET_DEFAULT_QUESTIONS: "isUserAllowedToChangeDefaultSecurityQuestion",
    ALLOW_TO_CHANGE_DEFAULT_QS_TEXT: "Allow employees to change their default questions",
    ADD_QUESTION_MODAL_TITLE: "Add New Question",
    ADD_QUESTION_INPUT_LABEL: "Add Question",
    ADD_QUESTION_TITLE_INPUT_LABEL: "Add Question Title",
    ADD_QUESTION_DEFAULT_TOGGLE_LABEL: "Set as Default",
    ADD_QUESTION_SUCCESS_MESSAGE: "Security Question added successfully.",
    EDIT_QUESTION_MODAL_TITLE: "Edit Question",
    EDIT_QUESTION_INPUT_LABEL: "Edit Question",
    EDIT_QUESTION_TITLE_INPUT_LABEL: "Edit Question Title",
    EDIT_QUESTION_SUCCESS_MESSAGE: "Security Question updated successfully.",
    SET_DEFAULT_QUESTION_SUCCESS_MESSAGE: "Security Question updated as default question successfully.",
    DELETE_QUESTION_MODAL_TITLE: "Delete Question",
    DELETE_QUESTIONS_MODAL_TITLE: "Delete Question(s)",
    DELETE_QUESTION_MODAL_TEXT: "Do you want to delete the selected Question?",
    DELETE_QUESTIONS_MODAL_TEXT: "Do you want to delete the selected Question(s)?",
    DELETE_QUESTION_SUCCESS_MESSAGE: "Selected Question deleted successfully.",
    DELETE_QUESTIONS_SUCCESS_MESSAGE: "Selected Question(s) deleted successfully.",
    INVALID_QUESTION_TEXT: "Question is required",
    INVALID_QUESTION_TITLE_TEXT: "Question Title is required",
    ADD_QUESTION_INPUT_PLACEHOLDER: "Enter Question",
    ADD_QUESTION_TITLE_INPUT_PLACEHOLDER: "Enter Question Title",
    NO_MATCHING_RECORD: "No matching records found",
};

export const CompanySettingsName = {
    SupportContactSettings: "SupportContactSettings",
    SentMessageHistorySettings: "SentMessageHistorySettings",
    ReceivedMessageHistorySettings: "ReceivedMessageHistorySettings",
    RetentionSettings: "RetentionSettings",
    DropOffSettings: "DropOffSettings",
    DropOffUsersSenders: "DropOffUsersSenders",
    DropOffUsersRecipients: "DropOffUsersRecipients",
    RequestHistorySettings: "RequestHistorySettings",
    RequestNotificationSettings: "RequestNotificationSettings",
};

export const MessageHistoryConstants = {
    MESSAGE_HISTORY_TITLE_TEXT: "Message History",
    MESSAGE_HISTORY_HELPER_TEXT:
        "Manage settings for the employees to access the sent and received items historical report.",
    MESSAGE_HISTORY_SENT_ITEMS_PAYLOAD: {
        IS_USER_ALLOWED_TO_VIEW_REPORTS: "isUserAllowedToViewReports",
        IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS: "isUserAllowedToViewTransferOfAllUsers",
        IS_USER_ALLOWED_TO_RESEND_DOCUMENT_ACCESS_LINKS: "isUserAllowedToResendDocumentAccessLinks",
        IS_USER_ALLOWED_TO_VIEW_AUTHENTICATION_QNA: "isUserAllowedToViewAuthenticationQnA",
    },
    MESSAGE_HISTORY_RECEIVED_ITEMS_PAYLOAD: {
        IS_USER_ALLOWED_TO_VIEW_RECEIVED_MESSAGE_HISTORICAL_REPORT:
            "isUserAllowedToViewReceivedMessageHistoricalReport",
        IS_USER_ALLOWED_TO_VIEW_TRANSFER_OF_ALL_USERS_FOR_RECEIVED_ITEMS: "isUserAllowedToViewTransferOfAllUsers",
    },
    SENT_ITEMS_HISTORY: "Sent Items History",
    SENT_ITEMS_HISTORY_HELP_TEXT: "Manage access and settings for Sent Items History report.",
    VIEW_SENT_ITEMS: "View the Sent Items Historical report",
    VIEW_TRANSFER_OF_USERS: "View transfers of all users (does not provide access to links)",
    RESEND_DOCUMENT_ACCESS_LINKS: "Resend Document Access Links (Also applies to My Portal Inbox)",
    VIEW_QNA: "View Authentication Questions and Answers",
    RECEIVED_ITEMS_HISTORY: "Received Items History",
    RECEIVED_ITEMS_HISTORY_HELP_TEXT: "Manage access and settings for Received Items History report.",
    VIEW_RECEIVED_ITEMS: "View the Received Items Historical report",
};

export const RequestHistoryConstants = {
    REQUEST_HISTORY_HELP_TEXT: "Manage access and settings for Document Requests History report.",
    REQUEST_HISTORY_VIEW_DOCUMENT_REQUEST_HISTORY_TOGGLE_TEXT: "View the Document Requests History",
    REQUEST_HISTORY_VIEW_DR_SENT_BY_ALL_USERS_TEXT: "View Document Requests sent by all users",
    REQUEST_HISTORY_SETTINGS_PAYLOAD: {
        IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORICAL_REPORT: "isUserAllowedToViewDocumentRequestHistoricalReport",
        IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORY_REPORT_SENT_BY_ALL_USERS:
            "isUserAllowedToViewDocumentRequestSentByAllUsers",
    },
};

export const DropOffConfigConstants = {
    DROP_OFF_TITLE_TEXT: "Drop Off Configuration",
    DROP_OFF_HELPER_TEXT: "Manage access, recipients and file restrictions in this setting.",
    DROP_OFF_ALLOW_ADDRESS_BOOL_LOOKUP:
        "Allow Address Book Lookup (if disabled, users will have to enter valid email address)",
    DROP_OFF_ALLOW_PERSONAL_DROP_LINK: "Allow Personal Drop Off Links",
    DROP_OFF_ALLOW_MANAGE_LINK_IN_MAIL_NOTIFICATION: "Allow users to manage drop off links in mail notification",
    DROP_OFF_SEND_DOWNLOAD_NOTIFICATION: "Send download notification to the sender/client",
    DROP_OFF_ENABLE_SPAM_FILTERING:
        "Enable Spam Filtering (if enabled, incoming Email id will be validated with System contact list)",
    FILE_TYPE_RESTRICTION_TITLE: "File Type Restriction",
    FILE_TYPE_RESTRICTION_INFO:
        "Set file format(s) that must be restricted for the user during upload in Drop Off portal",
    FILE_TYPE_RESTRICTION_HELPER_TEXT:
        "Manage file types that must be restricted during upload. Add file extensions format as 'pdf', 'docx', 'xls' etc.",
    IS_EMAIL_ADDRESS_LOOKUP_ALLOWED: "isEmailAddressLookupAllowed",
    IS_PERSONAL_DROP_OFF_LINK_TO_DISPLAY: "isPersonalDropOffLinkToDisplay",
    IS_DROP_OFF_LINK_TO_DISPLAY_IN_MAIL: "isUsersAllowedToManageDropOffLink",
    IS_DOWNLOAD_NOTIFICATION_TO_SEND: "isDownloadNotificationToSend",
    IS_SPAM_FILTERING_REQUIRED: "isSpamFilteringRequired",
    FILE_TYPE_SAVED_SUCCESSFULLY: "Restricted file type(s) updated successfully.",
};

export const DropOffUsersConstants = {
    DROP_OFF_HELPER_TEXT: "Manage access of drop off senders and recipients.",
    ACCORDIAN_TITLE_SENDERS: "Senders",
    SENDER_ACCORDIAN_HELP_TEXT:
        "Manage whitelisting and blacklisting of external users who use drop off for sharing files/messages. \nNote: All Clients in Client Management are already whitelisted and will not be listed here.",
    RECIPIENTS_ACCORDIAN_HELP_TEXT: "Manage Drop Off recipients.",
    ACCORDIAN_TITLE_RECIPIENTS: "Recipients",
    DEFAULT_BUTTON_LABEL: "Default",
    SET_DEFAULT_BUTTON_LABEL: "Set as Default",
    REMOVE_DEFAULT_BUTTON_LABEL: "Remove Default",
    ENABLE_DROP_OFF: "Enable",
    DISABLE_DROP_OFF: "Disable",
    SENDER_SEARCH_PLACEHOLDER: "Sender",
    RECIPIENT_SEARCH_PLACEHOLDER: "Recipient",
    NO_DATA_TEXT: "No matching records found",
    IS_DROPOFF_ENABLED: "isDropOffEnabled",
    IS_BLACKLISTED: "isBlacklisted",
    IS_DEFAULT: "isDefault",
    SENDER_WHITELIST_USERS_BUTTON: "Whitelist",
    SENDER_BLACKLIST_USERS_BUTTON: "Blacklist",
    USER_STATUS_TABLE_LABEL: "Status",
    ENABLE_DROPOFF_ICON: "enable-dropOff-icon",
    DISABLE_DROPOFF_ICON: "disable-dropOff-icon",
    WHITELIST_ICON: "whitelist-icon",
    BLACKLIST_ICON: "blacklist-icon",
};
export const DropOffModalConstants = {
    DROP_OFF_SENDER__DELETE_MODAL_TITLE: "Delete Sender",
    DROP_OFF_SENDERS__DELETE_MODAL_TITLE: "Delete Sender(s)",
    DROP_OFF_SENDER_DELETE_MODAL_BODY: "Do you wish to delete the selected user?",
    DROP_OFF_SENDERS_DELETE_MODAL_BODY: "Do you wish to delete the selected user(s)?",
    DROP_OFF_ADD_MODAL_TITLE: "Add Sender",
    DROP_OFF_EDIT_MODAL_TITLE: "Edit Sender",
    ADD_SENDER_HELP_TEXT: "Mark as Blacklisted user",
    EDIT_SENDER_EMAIL_PLACEHOLDER: "Enter email address",
    EDIT_SENDER_EMAIL_SUCCESS_MESSAGE: "Sender email address updated successfully.",
    ADD_SENDER_SUCCESS_MESSAGE: "Sender added successfully.",
    DROP_OFF_SENDER__DELETED_SUCCESS: "The selected sender deleted successfully.",
    DROP_OFF_SENDERS__DELETED_SUCCESS: "The selected sender(s) deleted successfully.",
    DROP_OFF_SENDER__BLACKLIST_SUCCESS: "The selected sender blacklisted successfully.",
    DROP_OFF_SENDERS__BLACKLIST_SUCCESS: "The selected sender(s) blacklisted successfully.",
    DROP_OFF_SENDER__WHITELIST_SUCCESS: "The selected sender whitelisted successfully.",
    DROP_OFF_SENDERS__WHITELIST_SUCCESS: "The selected sender(s) whitelisted successfully.",
    DROP_OFF_RECIPIENTS_ENABLED_SUCCESS: "Drop Off Enabled for selected recipient(s) successfully.",
    DROP_OFF_RECIPIENT_ENABLED_SUCCESS: "Drop Off Enabled for selected recipient successfully.",
    DROP_OFF_RECIPIENTS_DISABLED_SUCCESS: "Drop Off Disabled for selected recipient(s) successfully.",
    DROP_OFF_RECIPIENT_DISABLED_SUCCESS: "Drop Off Disabled for selected recipient successfully.",
    DROP_OFF_RECIPIENT_SET_AS_DEFAULT_SUCCESS: "Selected recipient updated as default recipient successfully.",
    DROP_OFF_RECIPIENT_REMOVE_AS_DEFAULT_SUCCESS: "The default option for the selected recipient has been removed.",
    DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL:
        "It is necessary to have at least one enabled drop-off recipient. Please unselect the intended recipient and try again.",
    DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL_ONE_RECIPIENT:
        "It is necessary to have at least one enabled drop-off recipient.",
};

export const DropOffDropDownConstants = {
    SENDER_DROPDOWN_ALL_USERS: "All Users",
    SENDER_DROPDOWN_WHITELIST_USERS: "Whitelisted",
    SENDER_DROPDOWN_BLACKLIST_USERS: "Blacklisted",
    RECIPIENT_DROPDOWN_ENABLED_USERS: "Drop Off Enabled",
    RECIPIENT_DROPDOWN_DISABLED_USERS: "Drop Off Disabled",
};

export const RecipientQnAPlaceholder = {
    QUESTION_NAME: "Enter question name",
    QUESTION: "Write your question here",
    ANSWER: "Enter answer",
};

export const initialPagination: IPagination = {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    totalRecords: 0,
};
export const initialDocumentPagination = {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
};

export const userSettingsTitle: { [key: string]: string } = {
    authentication_questions: "Authentication Questions",
    message_options: "Message Options",
    mailbox_delegation: "Mailbox Delegation",
    notifications: "Notifications",
};

export const userSettingsConstant = {
    TITLE: "My Settings",
    DESCRIPTION: "You can set which users of the company can access your mailbox.",
    MAILBOX_DELEGATION_TITLE: "Mailbox Delegation",
    ENABLE_MAILBOX_DELEGATION: "Enable other users to access my Mailbox",
    DELEGATEE_LIST_TEXT: "Select User(s) to access your Mailbox",
    NO_DELEGATEE_TEXT: "No user found",
    DELEGATEE_SEARCH_PLACEHOLDER: "Search",
    APPLY_CHANGES_SUCCESS: "Your settings have been updated.",
};

export const reportProblemConstants = {
    TITLE: "Report a Problem",
    PRODUCT_TYPE_LABEL: "Product Type",
    PRODUCT_TYPE_PLACEHOLDER: "SafeSend Exchange",
    CPA_FIRM_LABEL: "CPA Firm",
    PHONE_LABEL: "Phone",
    USER_LABEL: "Logged in User",
    EMAIL_LABEL: "Email",
    DESCRIPTION_LABEL: "Description",
    ATTACHMENT_LABEL: "Add Attachment",
    EMPTY_ERROR_MESSAGE: "This field can't be empty",
    INVALID_EMAIL_ERROR_MESSAGE: "Email is invalid",
    MAXIMUM_FILE_SIZE: "15728640",
    FILE_SIZE_ERROR_MESSAGE: "Upload failed. Please attach file less than 15 MB.",
    SUPPOERTED_FILE_FORMATE: ".png, .jpg, .jpeg, .doc, .docx, .pdf",
    FILE_TYPE_ERROR_MESSAGE: "Unsupported file type. Only doc, docx, pdf, png, jpg and jpeg file types are allowed.",
    ATTACHMENT_INFO_TEXT: "Maximum file size 15MB; Allowed file types: jpeg, jpg, png, doc, docx and pdf",
    SUBMIT_SUCCESS_MESSAGE: "Problem reported successfully. Your tracking ID is",
};

export const reportAttachmentFormats = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];

export const ViewInboxConstants = {
    VIEW_INBOX_DROPDOWN_TITLE: "View Inbox",
};

export const LoginHistoryConstants = {
    TITLE: "Login History",
    PROMPT_TEXT: "Enable Last login prompt",
    PROMPT_INFO: "If enabled, you will see the last login notification every time you login.",
    LAST_LOGIN_MODAL_TITLE: "Information",
    LAST_LOGIN: "Last Login",
    LAST_LOGIN_PROMPT_TEXT: "Don't show this again",
    LAST_LOGIN_PROMPT_INFO: "To turn back on, go to Login History",
    COUNT_PER_PAGE: 15,
};
export const UnlockLinkConstants = {
    UNLOCK_HEADER_TITLE_TEXT: "Unlock",
    UNLOCK_ROUTE: "/unlock",
    UNLOCK_HEADER_HELPER_TEXT: "Manage locked-out links for the users to access/upload their file(s).",
    UNLOCK_BUTTON_TITLE: "Unlock",
    UNLOCK_BUTTON_HOVER_TEXT: "Unlock link for the selected user(s).",
    UNLOCK_SUCCESS_MESSAGE: "Link(s) unlocked successfully",
    NO_DATA_TO_DISPLAY: "There are no items to show here",
};

export const DraftMessageConstants = {
    DRAFT_MODAL_TITLE: "Save Draft",
    DRAFT_MODAL_TEXT: "Do you want to save this message as draft?",
    SAVING_MESSAGE: "Saving message to draft...",
    SUCCESS_MESSAGE: "Message saved to draft successfully!",
    SAVE_TO_DRAFT: "save To Draft",
    SEND_MESSAGE: "send Message",
};

export const ReportsFilterConstants = {
    FILTER_MODAL_TITLE: "Filters",
    FILTER_MODAL_CONFIRM_BUTTON_NAME: "Save",
    FILTER_MODAL_CANCEL_BUTTON_NAME: "Cancel",
    FILTER_MODAL_ADDITIONAL_BUTTON_NAME: "Apply",
    FILTER_DROPDOWN_PLACEHOLDER_TEXT: "Select",
    FILTER_DELETE_TITLE: "Delete Filter",
    FILTER_RESET_TITLE: "Reset Filter",
    FILTER_MODAL_SENDER_ACCORDION_TITLE: "Sender",
    FILTER_MODAL_RECIPIENT_ACCORDION_TITLE: "Recipient",
    FILTER_MODAL_SENT_DATE_ACCORDION_TITLE: "Sent Date",
    FILTER_MODAL_RECEIVED_DATE_ACCORDION_TITLE: "Received Date",
    FILTER_MODAL_LINK_STATUS_ACCORDION_TITLE: "Link Status",
    FILTER_MODAL_TRANSFER_MODE_ACCORDION_TITLE: "Transfer Mode",
    FILTER_MODAL_ACTIVITY_LOG_ACCORDION_TITLE: "Activity Log",
    FILTER_MODAL_SENDER_SEARCH_PLACEHOLDER: "Search sender",
    FILTER_MODAL_SENDER_RECIPIENT_PLACEHOLDER: "Search recipient",
    FILTER_MODAL_SENDER_SEARCH_NOT_FOUND: "User not found",
    FILTER_MODAL_SEARCH_ALL: "All",
    FILTER_MODAL_SEARCH_ALL_TEXT: "All (select all search results)",
    FILTER_NAME_MAX_LENGTH: 50,
    SAVE_FILTER_MODAL_TITLE: "Save filter",
    DELETE_FILTER_MODAL_TITLE: "Delete filter",
    DELETE_FILTER_MODAL_TEXT: "Do you wish to delete the selected filter?",
    FILTER_LINK_STATUS: {
        ACTIVE: "Active",
        CANCELLED: "Cancelled",
        EXPIRED: "Expired",
    },
    FILTER_TRANSFER_MODES: {
        DROP_OFF: "Drop Off",
        PORTAL_QA: "Portal QA",
        PORTAL_ACCESS_CODE: "Portal Access Code",
        WEB_ADD_IN_QA: "Web Add-In QA",
        WEB_ADD_IN_ACCESS_CODE: "Web Add-In Access Code",
        Folder_DROP_OFF: "Folder Link",
    },
    FILTER_REQUEST_STATUS: {
        NONE: "None",
        PENDING_UPLOAD: "Pending Upload",
        PARTIALLY_UPLOADED: "Partially Uploaded",
        OVERDUE: "Overdue",
        COMPLETED: "Completed",
        Expired: "Expired",
        Cancelled: "Canceled",
    },
    DATE_TYPES: {
        ALL: "All",
        LAST_30_DAYS: "Last 30 days",
        LAST_12_MONTHS: "Last 12 months",
        CUSTOM: "Custom",
    },
    REPORT_MIN_DATE: "2010-08-03T05:40:46.171Z",
    REPORT_MIN_VALID_DATE: "2001-01-01T05:40:46.171Z",
    FILTER_UPDATE_SUCCESS: "Filter saved successfully",
    FILTER_ADD_SUCCESS: "Filter added successfully",
    FILTER_REMOVE_SUCCESS: "Filter removed successfully.",
    FILTER_DELETE_SUCCESS: "Selected filter deleted successfully.",
    FILTER_APPLY_SUCCESS: "Filter applied successfully",
    REQUIRED_FIELDS_WARNING_MESSAGE: "Please enter all fields in the highlighted section(s).",
    DUPLICATE_FILTER_VALIDATION: "This filter name already exists. Try another one.",
};

export const ReportConstants = {
    FILE_POPOVER_TITLE: "Files",
    DOCUMENT_POPOVER_TITLE: "Documents",
    RECIPIENTS_POPOVER_TITLE: "Recipients",
    ACTIVITY_LOG_POPOVER_TITLE: "Activity Log",
    UPLOADED_FILES_POPOVER_TITLE: "Uploaded Files",
    SIH_TITLE: "Sent Items History",
    RIH_TITLE: "Received Items History",
    DR_TITLE: "Document Requests History",
    CONTEXT_MENU_RESEND_LINK: "Resend Link to",
    CONTEXT_MENU_CANCEL_TRANSFER: "Cancel Transfer",
    CANCEL_MESSAGES_SUCCESS: "The selected message(s) cancelled successfully.",
    RESEND_LINK_SUCCESS: "Access link resent successfully",
    APPLY_FILTER_SUCCESS: "Filter applied successfully.",
    SHOW_SAVED_FILTER_TEXT: "Showing results for ",
    SHOW_CUSTOM_FILTER_TEXT: "Showing results based on filter",
    NO_DATA_MESSAGE: "There are no items to show here",
    SIH_METRICS_IDS: ["totalMessagesSent", "totalMessagesSentViaAccessCode", "totalMessagesSentViaQA"],
    SIH_METRICS_TITLES: ["Messages Sent", "Sent via Access Code", "Sent via Q&A"],
    RIH_METRICS_IDS: [
        "totalMessagesReceived",
        "totalMessagesReceivedViaDropOff",
        "totalMessagesReceivedViaFolderDropOff",
    ],
    RIH_METRICS_TITLES: ["Messages Received", "Received via Drop Off", "Received via Folder link"],
    DRH_METRICS_IDS: ["totalRequestSent", "totalFilesReceived"],
    DRH_METRICS_TITLES: ["Requests Sent", "Files Received"],
};

export const FeatureDisabledConstants = {
    DISABLED_TITLE: "Feature Disabled",
    DENIED_TITLE: "Access Denied",
    DISABLED_BY_TIER_DESCRIPTION:
        "This product is not supported on your current subscription tier," + "\n" + "please contact your ",
    DISABLED_BY_TIER_CUSTOMER_SUCCESS_REPRESENTATIVE: "Customer Success Representative ",
    DISABLED_BY_TIER_ENDING: "for access.",
    PRODUCT_DISABLED_IN_SETTINGS_DESCRIPTION:
        "We are sorry, but this feature is currently not available for your use." +
        "\n" +
        "Please contact your System Administrator for more information.",
    NO_PERMISSION_DESCRIPTION: "We are sorry, you do not have permission to access ",
    PRODUCT_NAME: "Safesend Exchange.",
    NO_PERMISSION_CONTACT_ADMINISTRATOR: "\n" + "Please contact your system administrator to update your user profile.",
};

export const AccessDeniedConstants = {
    ACCESS_DENIED_TITLE: "Access Denied",
    ACCESS_DENIED_DESCRIPTION: "Please consult your system" + "\n" + "administrator for assistance.",
    ACCESS_DENIED_BUTTON_TEXT: "Sign in as Different User",
};

export const ReportDownloadContansts = {
    DOWNLOAD_PROGRESS: "Download in Progress",
    REPORT_FILE_FORMAT: "csv",
    DEFAULT_FILE_NAME_SIH: "SafeSend_SIHreport_",
    DEFAULT_FILE_NAME_RIH: "SafeSend_RIHreport_",
    DATE_FORMAT_FILE_NAME: "YYYY_MM_DD",
    MULTIPLE_ATTACHMENT_FILE_NAME: "Safesend_",
};

export const MyAccountTitle: { [key: string]: string } = {
    profile: "Profile",
    password: "Password",
};

export const ProfileInformation = {
    TITLE: "Profile Information",
    DESCRIPTION: "View and manage your profile information here.",
    LABEL_TITLE: "Title",
    LABEL_EMAIL: "Email Address",
    LABEL_FIRST_NAME: "First Name",
    LABEL_LAST_NAME: "Last Name",
    LABEL_PHONE_NUMBER: "Phone Number",
    LABEL_EXTENSION: "Extension",
    LABEL_FAX_NUMBER: "Fax Number",
    LABEL_PTIN: "PTIN",
    LABEL_MOBILE_NUMBER: "Enter Mobile Number",
    PLACEHOLDER_TITLE: "Enter Title",
    PLACEHOLDER_EMAIL: "Enter Email Address",
    PLACEHOLDER_FIRST_NAME: "Enter First Name",
    PLACEHOLDER_LAST_NAME: "Enter Last Name",
    PLACEHOLDER_PHONE_NUMBER: "Enter Phone Number",
    PLACEHOLDER_EXTENSION: "Enter Phone Extension",
    PLACEHOLDER_FAX_NUMBER: "Enter Fax Number",
    PLACEHOLDER_PTIN: "Enter PTIN",
    PLACEHOLDER_MOBILE_NUMBER: "Enter Mobile Number",
    VERIFY_TITLE: "Verify",
    VERIFY_HELP_TEXT:
        "You can skip the verification step. On next login, you will be prompted to verify the mobile number",
    LABEL_ACCESS_CODE: "Enter the access code sent to",
    PLACEHOLDER_ACCESS_CODE: "Access code",
    OK_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    FAX_LENGTH: 10,
    PTIN_LENGTH: 9,
    PHONE_NUMBER_LENGTH: 10,
    EXTENSION_LENGTH: 7,
    MAX_CHARACTER_LENGTH: 100,
};

export const ChangePasswordConstants = {
    TITLE: "Change Password",
    DESCRIPTION: "Update your password for SafeSend login here.",
    LABEL_CURRENT_PASSWORD: "Current Password",
    LABEL_NEW_PASSWORD: "New Password",
    LABEL_CONFIRM_PASSWORD: "Confirm Password",
    OK_BUTTON: "Update Password",
    CANCEL_BUTOTN: "Cancel",
    PASSWORD_POLICY_TITLE: "Password Policies",
    PASSWORD_LENGTH: "Minimum Number of characters: ",
    PASSWORD_REQUIRED:
        "Required character types: Your passsword must include one of each of the following character types:",
    PASSWORD_SPECIAL_CHARACTERS: "(View list of available special characters)",
    PASSWORD_CHARACTERS_LIST: "~ ! @ # $ % ^ & * _",
    PLACEHOLDER_CURRENT_PASSWORD: "Enter Current Password",
    PLACEHOLDER_NEW_PASSWORD: "Enter New Password",
    PLACEHOLDER_CONFIRM_PASSWORD: "Repeat New Password",
};

export const LoadingScreenConstants = {
    LOADING: "Loading",
};

export const AppInsightsConstants = {
    Role: "SSE-CPAPortalUI",
    Product: "SafeSend Exchange",
};

export const MyDownloadConstants = {
    MAX_FILE_SIZE: 209715200,
    MODAL_TITLE: "Download File(s)",
    DOWNLOAD_NOW: "Download Now",
    DOWNLOAD_LATER: "Download Later",
    DOWNLOAD_NOW_DESCRIPTION: "Use this option to download the selected file(s) to your local device promptly.",
    DOWNLOAD_LATER_DESCRIPTION:
        // eslint-disable-next-line quotes
        'The selected file(s) will be downloaded in the background and can be accessed under "My Downloads".',
    DOWNLOAD_PROGRESS: "Download In Progress",
    INFO_TEXT: "Select any one option to download",
    DOWNLOAD_LATER_SUCCESS: "Download in Progress. Once ready, go to 'My Downloads' to access your file(s)'.",
    DELETE_SUCCESS: "File Deleted Successfully",
};

export const MyDownloadType = {
    DOWNLOAD_NOW: 1,
    DOWNLOAD_LATER: 2,
};

export const MyDownloadsModalConstants = {
    TITLE: "My Downloads",
    DESCRIPTION: "You can access your downloaded files from here",
    DELETE_WARNING:
        "Before proceeding with deletion, please verify if you have any downloads in progress to My Downloads.",
    PAGE_SIZE: 5,
    DELETE_TITLE: "Delete",
    DELETE_FILE_DESCRIPTION: "Are you sure you want to delete the selected item?",
};

export const DateFormatConstants = {
    DEFAULT_DATE_FORMAT: "MM/DD/YYYY",
    REPORTS_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss a",
    LOGIN_TABLE_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss a",
    LAST_LOGIN_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss A",
    SAME_DAY_TIME_FORMAT: "HH:mm",
    CURRENT_WEEK_DATE_FORMAT: "ddd MM-DD",
    MESSAGE_DETAIL_DATE_FORMAT: "ddd MM-DD-YYYY HH:mm",
    DEFAULT_TIME_FORMAT: "HH:mm:ss A",
    DOCUMENT_EXPIRY_FORMAT: "MM-DD-YYYY",
};

export const HeaderConstants = {
    PROFILE: "Profile",
    MY_SETTINGS: "My Settings",
    DROP_OFF_LINKS: "Drop Off Links",
    REPORT_PROBLEM: "Report Problem",
    MY_DOWNLOADS: "My Downloads",
    LOGIN_HISTORY: "Login History",
    SS_SUITE: "SafeSend Suite",
    ACCOUNT_MANAGEMENT: "Account Management",
    HELP: "Help Centre",
    TOOLS: "Tools",
};

export const TemplateListingConstants = {
    TITLE_HELPER_TEXT: "Save time on your request process by using or creating your own customized templates.",
    ADD_TEMPLATE_MODAL_TITLE: "New Template",
    EDIT_TEMPLATE_MODAL_TITLE: "Edit Template",
    REQUEST_MORE_MODAL_TITLE: "New Request",
    MODAL_NAME_INPUT_LABEL: "Template's Name",
    MODAL_NAME_INPUT_PLACEHOLDER: "Enter Template Name",
    MODAL_DESCRIPTION_INPUT_LABEL: "Description",
    MODAL_DESCRIPTION_INPUT_PLACEHOLDER: "Enter Description",
    MODAL_YEAR_INPUT_LABEL: "Tax Year",
    MODAL_YEAR_INPUT_PLACEHOLDER: "YYYY",
    MODAL_DUE_DATE_INPUT_LABEL: "Due Date",
    MODAL_FILES_REQUEST_INPUT_LABEL: "Document List",
    MODAL_FILE_NAME_PLACEHOLDER: "Enter Document Name",
    MODAL_FILE_DESCRIPTION_PLACEHOLDER: "Enter Document Description",
    INVALID_QUESTION_TITLE_TEXT: "Question Title is required",
    TEMPLATE_NAME_MAX_LENGTH: 200,
    TEMPLATE_DUPLICATE_NAME_CONDITION: 195,
    FILE_NAME_MAX_LENGTH: 50,
    FILE_DESCRIPTION_MAX_LENGTH: 100,
    NO_TABLE_DATA: "No template to display",
    NO_CATEGORY_TEXT: "(No category selected)",
};
export const TemplateModalConstants = {
    DELETE_TEMPLATE_MODAL_TITLE: "Delete Template",
    DELETE_TEMPLATES_MODAL_TITLE: "Delete Template(s)",
    DELETE_TEMPLATE_MODAL_BODY: "Do you wish to delete the selected template?",
    DELETE_TEMPLATES_MODAL_BODY: "Do you wish to delete the selected template(s)?",
    DELETE_TEMPLATE_SUCCESS: "Selected Template deleted successfully",
    DELETE_TEMPLATES_SUCCESS: "Selected Template(s) deleted successfully",
    ADD_TEMPLAE_SUCCESS: "Template added successfully",
    EDIT_TEMPLAE_SUCCESS: "Template updated successfully",
    DUPLICATE_TEMPLAE_SUCCESS: "Template duplicated successfully",
    DUPLICATE_TEMPLAE_NAME_ERROR: "Sorry! Unable to duplicate the selected template. Name character limit exceeded.",
};
export const TemplateValidationConstants = {
    NAME_REQUIRED_TEXT: "Template name is required",
    DESCRIPTION_REQUIRED_TEXT: "Description is required",
    YEAR_REQUIRED_TEXT: "Year is required",
    DOCUMENT_NAME_TEXT: "Document Name is required",
};
export const DocumentRequestConstants = {
    REQUEST_DOCUMENT_TITLE_TEXT: "Request Documents",
    REQUEST_DOCUMENT_HELPER_TEXT: "Send, View and Manage document requests sent to the taxpayer.",
    TITLE_REQUEST_MORE_MODAL: "Request More",
    NO_DOCUMENT_REQUESTS: "No request(s) here.",
    SELECT_DOCUMENT_REQUESTS: "No request selected.",
    PLACEHOLDER_SEARCH_TEXT: "Search by email",
};

export const DocumentToolbarConstants = {
    TITLE_DELETE: "Delete",
    DELETE_REQUEST: "Delete Request",
    DELETE_DOCUMENT: "Delete All File(s)",
    TITLE_SEND_REMINDER: "Send Reminder",
    TITLE_RESEND_ACCESS_LINK: "Resend Request",
    TITLE_CANCEL_REQUEST: "Cancel Request",
    TITLE_CHANGE_RETENTION: "Change Retention Period",
    TITLE_DOWNLOAD: "Download",
    TITLE_CHANGE_STATUS: "Change Status",
    CHANGE_STATUS_TO_COMPLETE_MESSAGE:
        "You are about to mark this request as complete. The taxpayer won't be able to submit or modify any more files after confirmation.\nContinue?",
    CHANGE_STATUS_TO_RE_OPEN_MESSAGE:
        "You are about to reopen this request. The taxpayer will be able to submit or modify files once reopened.\nContinue?",
    LISTING_DELETE_REQUEST: "Are you sure you want to delete the selected Request(s)?",
    LISTING_DELETE_SUCCESS_REQUEST: "Request(s) deleted successfully.",
    DETAILED_DELETE_SUCCESS_REQUEST: "Request deleted successfully.",
    DETAILED_DELETE_DOCUMENT_SUCCESS: "Document deleted successfully.",
    DETAILED_DELETE_FILE_SUCCESS: "File deleted successfully.",
    DELETE_DOCUMENT_SUCCESS: "Document deleted successfully.",
    DETAILED_DELETE_REQUEST: "Are you sure you want to delete the selected Request?",
    DETAILED_DELETE_DOCUMENT: "This action will delete all the files in this document. Do you wish to continue?",
    DETAILED_CANCEL_REQUEST_MESSAGE:
        "The link will no longer be accessible to taxpayers if this is canceled. Do you wish to proceed?",
    DETAILED_CANCEL_REQUEST_SUCCESS_MESSAGE: "Request cancelled Successfully",
    SEND_REMINDER_SUCCESS_MESSAGE: "Reminder sent successfully",
    SEND_REMINDER_LIST_SUCCESS_MESSAGE:
        "Reminder sent successfully.\n Note: This will not apply to Completed Request(s)",
    UPDATE_DR_TO_COMPLETED_STATUS_SUCCESS_MESSAGE: "Request completed successfully",
    UPDATE_DR_TO_OPEN_STATUS_SUCCESS_MESSAGE: "Request reopened successfully",
    OPEN_STATUS: "Open",
    REOPEN_STATUS: "Re-Open",
    COMPLETED_STATUS: "Completed",
    COMPLETE_STATUS: "Complete",
    SEND_REMINDER_SPECIFIC_FILE: "Document reminder sent successfully.",
    RESEND_ACCESS_LINK_SUCCESS_MESSAGE: "Request resent successfully",
    SEND_DR_SUCCESS_MESSAGE: "Request sent successfully",
    RETENTIONPERIOD_DR_SUCCESS_MESSAGE: "Request Retention Period updated successfully",
    SEND_DR_TO_RESEND_MESSAGE: "Additional documents request sent successfully.",
    DR_ZIP_FILE_NAME: "SafesendDR",
    REQUEST_DOCUMENT_PRINT_TITLE_TEXT: "REQUEST DOCUMENTS MESSAGE",
};

export const UserGroupConstants = {
    ADMIN: "Admin",
    STAFF: "Staff",
};

export const NewDRConstants = {
    MODAL_TITLE: "New Request",
    TO_LABEL: "To",
    SUBJECT_LABEL: "Subject",
    APPLY_TEMPLATE_LABEL: "Applying template",
    MESSAGE_LABEL: "Message",
    YEAR_LABEL: "Year",
    DUE_DATE_LABEL: "Due Date",
    RETENTION_PERIOD_LABEL: "Retention Period",
    AUTHENTICATION_METHOD_LABEL: "Authentication Method",
    QUESTION_LABEL: "Question",
    QUESTION_DESCRIPTION_LABEL: "Question Description",
    ANSWER_LABEL: "Answer",
    FILE_REQUEST_LIST_LABEL: "Document List",
    OK_BUTTON_TEXT: "Send Request",
    CANCEL_BUTTON_TEXT: "Cancel",
    NOTIFY_ON_COMPLETION: "Notify on Completion",
    YEAR_PLACEHOLDER_TEXT: "Year",
    ADD_FILES_TEXT: "Add Documents",
    SUBJECT_ERROR_TEXT: "Subject is required",
    RECIPIENT_ERROR_TEXT: "Recipient is required",
    MESSAGE_ERROR_TEXT: "Message is required",
    ANSWER_ERROR_TEXT: "Answer is required",
    QUESTION_ERROR_TEXT: "Question is required",
    CLOSE_CONFIRMATION_TITLE: "Exit Request",
    WARNING_TEXT: "Warning!",
    CLOSE_CONFIRMATION_TEXT_1: "Changes made to this request will be lost. ",
    CLOSE_CONFIRMATION_TEXT_2: "Are you sure you want to leave this section?",
    NO_TEMPLATE_TEXT: "(No template selected)",
    MAX_DUE_DATE: 7,
    DOWNLOAD: "Download",
    DOWNLOAD_ALL: "Download All",
    SEND_REMINDER: "Send Reminder",
    DELETE: "Delete",
    DELETE_ALL: "Delete All",
    REQUEST_MORE_TEXT: "Request More",
    REQUEST_MORE_DISABLED_TITLE: "Reopen this request to get more documents.",
    DISABLED_PREVIEW_BUTTON: "Document preview is for PDF files only",
};

export const UserAutoLogoutConstants = {
    PermissionChanged: "Permission Changed",
};

export const UserAutoLogoutMessageConstants = {
    PermissionChanged:
        "Your SafeSend permissions have been changed. You must login again for the changes to take effect.",
};

export const SideMenuConstants = {
    ADMIN_SETTINGS: "Settings",
    HELP: "Help",
    MESSAGE_HISTORY: "History Reports",
    UNLOCK: "Unlock",
    SENT_ITEMS: "Sent Messages",
    RECEIVED_ITEMS: "Received Messages",
    DOCUMENT_REQUESTS: "Document Requests",
    NEW_MESSAGE_BUTTON_TEXT: "New Message",
    NEW_MESSAGE_BUTTON_ICON: "PlusIcon",
    ADMIN_SETTINGS_ROUTE: "admin-settings",
    ADMIN_SETTINGS_AUTHENTICATION_QUESTIONS_ROUTE: "/admin-settings/authentication-questions",
    SENT_ITEMS_HISTORY_ROUTE: "/sent-item-history",
    RECEIVED_ITEMS_HISTORY_ROUTE: "/receive-item-history",
    DOCUMENT_REQUESTS_HISTORY_ROUTE: "/document-request-history",
};

export const LogEventConstants = {
    Common: {
        UserLogout: "User Logout",
    },
};

export const EditConstants = {
    EDIT_FOLDER_NAME: "Edit Folder Name",
    EDIT_DESCRPTION: "Edit Description",
    EDIT_PLACEHOLDER_NAME: "Enter Folder name",
    EDIT_PLACEHOLDER_DESCRPTION: "Add Description",
};

export const RadioButtonConstants = {
    PRIVATE_NAME: "Private",
    SHARED_NAME: "Shared",
    PRIVATE_INFO: "Accessible only to you.",
    SHARED_INFO: "Accessible to shared users.",
};

export const MyFilesConstants = {
    MY_FILES_NAME: "My Files",
    MY_FOLDER_NUMBER: "0",
};

export const RetentionPeriodModal = {
    CHANGE_RETENTION_PERIOD_TITLE: "Change Retention Period",
    SELECT_RETENTION_PERIOD: "Select retention period",
};

export enum FolderEventType {
    None = 0,
    Create = 1,
    Edit = 2,
}

export const AdminSettingsConstants = {
    GENERAL_SETTING_TITLE: "General Settings",
    DROPOFF_SETTING_TITLE: "Drop Off Settings",
    REPORT_SETTING_TITLE: "History Report Settings",
    NOTIFICATION_SETTING_TITLE: "Notification Settings",
    DOCUMENT_REQUEST_SETTING_TITLE: "Document Request Settings",
};
export const TabConstants = {
    GENERAL_MESSAGE_OPTIONS: "Message Options",
    GENERAL_SUPPORT_CONTACT: "Support Contact",
    GENERAL_AUTHENTICATION_QUESTIONS: "Authentication Questions",
    DROPOFF_CONFIGURATION: "Configuration",
    DROPOFF_USERS: "Users",
    REPORT_MESSAGE_HISTORY: "Message History",
    REPORT_REQUEST_HISTORY: "Request History",
    NOTIFICATION_MESSAGE: "Message Notifications",
    NOTIFICATION_REQUEST: "Request Notifications",
    DOCUMENT_REQUEST_CATEGORY: "Document Category",
    DOCUMENT_REQUEST_TEMPLATE: "Request Templates",
};

export const ForeThoughtConstants = {
    FORETHOGHT_WIDGET: "widget",
    FORETHOGHT_WIDGET_MESSAGE: "message",
    FORETHOGHT_WIDGET_VISIBLE: "isForethoughtWidgetVisible",
    FORETHOGHT_WIDGET_CLOSED: "forethoughtWidgetClosed",
};

export const DocumentCategoryConstants = {
    DOCUMENT_CATEGORY_HELPER_TEXT: "Manage Document Category for the requests in this setting.",
    ADD_DOCUMENT_CATEGORY: " + Add Category",
    CATEGORY_ADD_SUCCESS_MESSAGE: "Document Category added successfully",
    CATEGORY_EDIT_SUCCESS_MESSAGE: "Document Category updated successfully",
    CATEGORY_DELETE_SUCCESS_MESSAGE: "Document Category deleted successfully",
    CATEGORIES_DELETE_SUCCESS_MESSAGE: "Document Category(s) deleted successfully",
    NO_DATA_MESSAGE: "There are no items to show here",
    EDIT_BUTTON_TEXT: "Edit",
    DELETE_BUTTON_TEXT: "Delete",
    CATEGORY_ALREADY_EXSITS: "This document category already exists.",
    CATEGORY_ALREADY_EXSIST_MESSAGE: "This category name already exists. Please try another one.",
};

export const DocumentCategoryModalConstants = {
    ADD_CATEGORY_MODAL_TITLE: "Add Document Category",
    EDIT_CATEGORY_MODAL_TITLE: "Edit Document Category",
    DELETE_CATEGORY_MODAL_TITLE: "Delete Document Category",
    DELETE_CATEGORY_MODAL_TEXT: "Do you want to delete the selected Document Category?",
    DELETE_CATEGORIES_MODAL_TEXT: "Do you want to delete the selected Document Category(s)?",
};
