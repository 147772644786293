import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "react-dropdown";
const _ = require("lodash");

import {
    deleteUploadedFile,
    detailDeleteDocumentRequest,
    downloadAllUploadedDocument,
    downloadUploadedFile,
    sendSpecificFileReminder,
    updateDocumentStatus,
    previewUploadedDocument,
    deleteAllUploadedFiles,
    downloadUploadedFiles,
} from "../../../actionCreators/documentRequestActionCreators";
import { DetailedStatus } from "../../../core/common/enums";
import {
    IDocumentRequestFile,
    IDocumentRequestModel,
    IDocumentRequestMoreModel,
    initialNewDocumentState,
    initialDocumentRequestMoreModel,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { IDocumentRequestTemplateFile } from "../../../core/viewModels/template/TemplateModel";
import { DocumentToolbarConstants, MessageToolbarConstants, NewDRConstants } from "../../../helper/Constants";
import { ChangeRetentionPeriodModal } from "../../common/ui/Modals/FolderModal/ChangeRetentionPeriodModal";
import {
    getDateForDocumentExpiryView,
    getFormattedDueDate,
    getFormattedSentDateForDetailedView,
    getYearFromDate,
} from "../../../helper/DateHelperFunctions";
import { requestNewDocument } from "../../../actionCreators/documentRequestActionCreators";
import { convertTextToHtml, isDelegatedUser } from "../../../helper/HelperFunctions";
import ConfirmModal from "../../common/ui/Modals/ConfirmModal";
import { DownloadIcon } from "../../svg/HeaderMenuIcons";
import { DeleteIcon, ChangeExpiryIcon } from "../../svg/ToolbarIcons";
import { DocumentRow } from "./DocumentRow";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { BellIcon, PlusCircleIcon } from "../../svg/IconCollection";
import CustomToggleSwitch from "../../common/ui/switch/CustomToggleSwitch";
import { ColorConstants } from "../../../assets/custom/colors";
import { DocumentRequestMore } from "./DocumentRequestMore";
import { PreviewUplodedFileModal } from "../../Preview/PreviewUploadedFileModal";
import { ApplicationState } from "../../../store";
import { fetchRequestNotificationsSettings } from "../../../actionCreators/adminSettingsActionCreators";
import { fetchUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import { PrintComponent } from "../../common/print/PrintComponent";
import { DocumentRequestPrint } from "./DocumentRequestPrint";

interface IDocumentRequestDetailedProps {
    document: IDocumentRequestModel;
    resetSelection: () => void;
    retentionOptions: Option[];
    detailedActionSuccessCallback: (documentId: number) => void;
    handleDocumentResendAccessLink: (documentRequestIds: number[], isListingAction?: boolean) => void;
    handleDocumentSendReminder: (documentRequestIds: number[], isListingAction: boolean, resourceId: string) => void;
    handleGetDocumentDetail: (documentRequestId: number) => void;
}

interface IPreviewFileDetails {
    fileType: string;
    fileGuid: string;
    fileName: string;
}

export const DocumentRequestDetailed: React.FC<IDocumentRequestDetailedProps> = ({
    document,
    resetSelection,
    handleDocumentSendReminder,
    retentionOptions,
    detailedActionSuccessCallback,
    handleGetDocumentDetail,
}) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);
    const [showDeleteRequest, toggleShowDeleteRequest] = useState(false);
    const [filesData, setFilesData] = useState<IDocumentRequestTemplateFile[]>(initialNewDocumentState.requestedFiles);
    const [showDeleteDocument, toggleShowDeleteDocument] = useState(false);
    const [showChangeStatus, toggleChangeStatus] = useState(false);
    const [showStatusDropdown, toggleStatusDropdown] = useState(false);
    const [documentRequestFileId, setDocumentRequestFileId] = useState(0);
    const [notifyOnCompletion, setNotifyOnCompletion] = useState(true);
    const [showChangeRetentionModal, setShowChangeRetentionModal] = useState(false);
    const [showRequestMore, toggleRequestMore] = useState(false);
    const [documentRequestMore, setDocumentRequestMore] = useState<IDocumentRequestMoreModel>(
        initialDocumentRequestMoreModel
    );
    const { requestNotificationSettings } = useSelector((appState: ApplicationState) => appState.adminSettingsState);
    const [isRequestMore, setIsRequestMore] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [pdfUrlData, setPdfUrlData] = useState("");
    const previewFileObject = { fileName: "", fileType: "", fileGuid: "" };
    const [previewFileDetails, setPreviewFileDetails] = useState<IPreviewFileDetails>(previewFileObject);
    const [activeDocuments, setActiveDocuments] = useState<number[]>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [collapsibleItems, setCollapsibleItems] = useState<number[]>([]);
    const userSettingsStore = useSelector((state: ApplicationState) => state.userSettingsState);
    const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);

    const accordionRef = React.useRef<any>(null);
    accordionRef.current = activeDocuments;

    useEffect(() => {
        toggleStatusDropdown(false);
        setIsExpanded(false);
        setActiveDocuments([]);
        const collapsibleItems: number[] = [];
        let totalUploadedFiles = 0;
        document?.requestedFiles?.forEach((file: IDocumentRequestFile) => {
            if (file.uploadedFiles.length > 0) {
                collapsibleItems.push(file.documentRequestFileId);
                totalUploadedFiles += file.uploadedFiles.length;
            }
        });
        setUploadedFilesCount(totalUploadedFiles);
        setCollapsibleItems(collapsibleItems);
    }, [document]);

    useEffect(() => {
        dispatch(fetchRequestNotificationsSettings());
        dispatch(fetchUserSettings());
    }, []);

    useEffect(() => {
        if (isRequestMore && !validated) {
            dispatch(
                requestNewDocument(documentRequestMore, ResourceIdLocators.DRL.DRLDtlBtnRequestMore, () => {
                    setIsRequestMore(false);
                    toggleRequestMore(false);
                    handleGetDocumentDetail(documentRequestMore.documentRequestId);
                })
            );
        }
    }, [documentRequestMore]);

    useEffect(() => {
        const notify = !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
            ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
            : userSettingsStore?.userSettings?.documentRequestNotificationsSettings
                  ?.isDocumentRequestCompletionNotificationEnabled;
        setNotifyOnCompletion(notify ?? !!notify);
    }, [requestNotificationSettings, userSettingsStore?.userSettings?.documentRequestNotificationsSettings]);

    const onClickDeleteDocument = (fileId: number, isFileLevelOperation?: boolean) => {
        setDocumentRequestFileId(fileId);
        if (isFileLevelOperation) {
            deleteUploadedDocumentSubmit(isFileLevelOperation, fileId);
            return;
        }
        toggleShowDeleteDocument(true);
    };

    const deleteUploadedDocumentSubmit = (isFileLevelOperation?: boolean, fileId?: number) => {
        if (isFileLevelOperation && fileId) {
            dispatch(
                deleteUploadedFile(fileId, ResourceIdLocators.DRL.DRLBtnDeleteDocument, deleteUploadedDocumentCallback)
            );
        } else {
            dispatch(
                deleteAllUploadedFiles(
                    document.documentRequestId,
                    documentRequestFileId,
                    ResourceIdLocators.DRL.DRLBtnDeleteDocument,
                    deleteUploadedDocumentCallback
                )
            );
        }
    };

    useEffect(() => {
        if (!showRequestMore) {
            setFilesData(initialNewDocumentState.requestedFiles);
        }
    }, [showRequestMore]);

    const onClickRemoveRow = (index: number) => {
        const filesDataCopy = [...filesData];
        filesDataCopy.splice(index, 1);
        setFilesData(filesDataCopy);
    };

    const onClickAddFiles = () => {
        const filesDataCopy = [...filesData];
        filesDataCopy.push({
            name: "",
            description: "",
        });
        setFilesData(filesDataCopy);
    };

    const handleFilesFormChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { id, value } = e.target;

        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index][id as keyof IDocumentRequestTemplateFile] = value as any;
        setFilesData(filesDataCopy);
    };

    const handleCategoryChange = (value: any, index: number) => {
        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index].documentCategory = value as any;
        setFilesData(filesDataCopy);
    };

    const onClickAddMore = () => {
        const documentRequestFile: IDocumentRequestFile[] = [];
        setValidated(false);
        filesData.forEach(x => {
            const tempData: any = {};

            if (x.name.trim() == "") {
                setValidated(true);
            }

            tempData.name = x.name;
            tempData.description = x.description;
            tempData.documentCategory = x.documentCategory;
            tempData.documentRequestId = document.documentRequestId;
            documentRequestFile.push(tempData);
        });

        setIsRequestMore(true);

        setDocumentRequestMore({
            documentRequestModel: documentRequestFile,
            documentRequestId: document.documentRequestId,
            subject: document.subject,
            dueDate: document.dueDate,
            recipientEmail: document.recipient?.email,
            documentRequestGuid: document.documentRequestGuid,
        });
    };

    const deleteDocumentRequestSubmit = () => {
        dispatch(
            detailDeleteDocumentRequest(
                document.documentRequestId,
                ResourceIdLocators.DRL.DRLDtlBtnDeleteRequest,
                successCallbackAction
            )
        );
    };

    const handleDocumentStatusChange = () => {
        toggleChangeStatus(true);
    };

    const handleChangeDocumentStatusSubmit = () => {
        dispatch(
            updateDocumentStatus(
                document.documentRequestId,
                document.status === DetailedStatus.OPEN || document.status === DetailedStatus.REOPEN
                    ? DetailedStatus.COMPLETED
                    : DetailedStatus.REOPEN,
                ResourceIdLocators.DRL.DRLBtnChangeState,
                document.status === DetailedStatus.COMPLETED ? notifyOnCompletion : false,
                successCallbackAction
            )
        );
    };

    const deleteUploadedDocumentCallback = () => {
        toggleShowDeleteDocument(false);
        setDocumentRequestFileId(0);
        detailedActionSuccessCallback(document.documentRequestId);
    };
    const successCallbackAction = () => {
        toggleShowDeleteRequest(false);
        toggleChangeStatus(false);
        resetSelection();
    };
    const onClickDownloadFile = (fileName: string, fileGuid: string) => {
        dispatch(
            downloadUploadedFile(
                fileName,
                fileGuid,
                getYearFromDate(document.sentDate),
                ResourceIdLocators.DRL.DRLBtnDownloadFile,
                openSaasLinkInNewTab
            )
        );
    };
    const onClickDownloadAllFiles = (file: IDocumentRequestFile) => {
        dispatch(
            downloadUploadedFiles(
                file,
                getYearFromDate(document.sentDate),
                ResourceIdLocators.DRL.DRLBtnDownloadFiles,
                openSaasLinkInNewTab
            )
        );
    };
    const onClickDownloadAllDocuments = () => {
        if (document.requestedFiles) {
            dispatch(
                downloadAllUploadedDocument(
                    document.requestedFiles,
                    getYearFromDate(document.sentDate),
                    ResourceIdLocators.DRL.DRLBtnDownloadFiles,
                    openSaasLinkInNewTab
                )
            );
        }
    };

    const openSaasLinkInNewTab = (link: string) => {
        window.open(link, "_blank");
    };

    const onClickUpdateRetentionPeriod = () => {
        setShowChangeRetentionModal(true);
    };
    const handleChangeRetentionSubmit = () => {
        setShowChangeRetentionModal(false);
    };
    const onClickDocumentSendReminder = () => {
        handleDocumentSendReminder([document.documentRequestId], false, ResourceIdLocators.DRL.DRLDtlBtnSendReminder);
    };
    const onClickSpecificFileSendReminder = (fileName: string) => {
        dispatch(
            sendSpecificFileReminder(
                document.documentRequestId,
                fileName,
                ResourceIdLocators.DRL.DRLDtlBtnDocSendReminder
            )
        );
    };

    const showDownloadAll = (): boolean => {
        let isDisabled = true;
        if (document.requestedFiles) {
            document.requestedFiles.some((file: IDocumentRequestFile) => {
                if (file.uploadedFiles.length > 0) {
                    isDisabled = false;
                    return true;
                }
                return false;
            });
        }
        //added comment
        return isDisabled;
    };
    const getDropdownToogleLabel = () => {
        switch (document.status) {
            case DetailedStatus.OPEN:
                return DocumentToolbarConstants.OPEN_STATUS;
            case DetailedStatus.REOPEN:
                return DocumentToolbarConstants.REOPEN_STATUS;
            case DetailedStatus.COMPLETED:
                return DocumentToolbarConstants.COMPLETED_STATUS;
        }
    };

    const NotifyCompletion = (
        <div className="notify-container">
            <CustomToggleSwitch
                label={NewDRConstants.NOTIFY_ON_COMPLETION}
                handleChange={() => {
                    setNotifyOnCompletion(value => !value);
                }}
                switched={
                    !requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification
                        ? requestNotificationSettings?.isRequestCompletionNotificationEnabled
                        : notifyOnCompletion
                }
                disabled={!requestNotificationSettings?.isUserAllowedToChangeRequestCompletionNotification}
            />
        </div>
    );

    const onRequestMoreModalHide = () => {
        setValidated(false);
        toggleRequestMore(false);
    };

    const onClickPreviewUploadedDocument = (fileName: string, fileGuid: string, fileType: string) => {
        setShowPreviewModal(true);
        setPreviewFileDetails({ ...previewFileDetails, fileName: fileName, fileGuid: fileGuid, fileType: fileType });
        dispatch(previewUploadedDocument(fileName, fileGuid, getYearFromDate(document.sentDate), localPeview));
    };
    const onClosePreviewModal = () => {
        setPdfUrlData("");
        setShowPreviewModal(false);
    };

    const onShowPreviewModal = () => setShowPreviewModal(true);
    const localPeview = (urlData: string) => {
        setPdfUrlData(urlData);
    };
    const onHideChangeStatusModal = () => {
        toggleChangeStatus(false);
    };
    const getHeightForScrollBar = (isHeaderBig: boolean, subject: string): string => {
        if (isHeaderBig) {
            if (subject.length > 50) {
                return "calc(100vh - 438px)";
            }
            return "calc(100vh - 473px)";
        }
        return "calc(100vh - 486px)";
    };

    const onAccordionClick = (documentRequestId: number) => {
        if (activeDocuments.includes(documentRequestId)) {
            const index = activeDocuments.indexOf(documentRequestId);
            if (index > -1) {
                activeDocuments.splice(index, 1);
            }
        } else {
            activeDocuments.push(documentRequestId);
        }
        if (activeDocuments.length === collapsibleItems.length) {
            setIsExpanded(true);
        }
        if (activeDocuments.length === 0) {
            setIsExpanded(false);
        }
        setActiveDocuments(activeDocuments);
    };

    const hasUploadedFiles = (document: IDocumentRequestModel): boolean => {
        return document.requestedFiles &&
            document.requestedFiles.length > 0 &&
            document.requestedFiles.some(x => x.uploadedFiles && x.uploadedFiles.length > 0)
            ? true
            : false;
    };

    const onExpandAllClick = () => {
        if (hasUploadedFiles(document)) {
            if (activeDocuments.length > 0 && isExpanded) {
                setActiveDocuments([]);
                setIsExpanded(false);
            } else {
                const documentRequestIds: number[] = [];
                document &&
                    document?.requestedFiles?.forEach((file: IDocumentRequestFile) => {
                        if (file.uploadedFiles.length > 0) {
                            documentRequestIds.push(file.documentRequestFileId);
                        }
                    });
                setActiveDocuments(documentRequestIds);
                setIsExpanded(true);
            }
        }
    };

    let isExpandClassName = isDelegatedUser() ? "top-padding" : "";
    isExpandClassName = !hasUploadedFiles(document) ? `disabled ${isExpandClassName}` : isExpandClassName;

    return (
        <div className="message-pane document-pane">
            <div className="message-pane-top">
                <div className="message-pane-subject">{document.subject}</div>
                <ButtonGroup className="message-pane-action" aria-label="Message pane actions">
                    {
                        <Button variant="link" className="btn-only" title="Print Request">
                            <PrintComponent showPrintIcon>
                                <DocumentRequestPrint
                                    document={document}
                                    uploadedFilesCount={uploadedFilesCount}
                                    titleText={DocumentToolbarConstants.REQUEST_DOCUMENT_PRINT_TITLE_TEXT}
                                />
                            </PrintComponent>
                        </Button>
                    }
                    {!isDelegatedUser() && (
                        <Button
                            variant="link"
                            className="btn-only"
                            title={DocumentToolbarConstants.TITLE_DELETE}
                            data-resource-id={ResourceIdLocators.DRL.DRLDtlBtnDeleteRequest}
                            onClick={() => toggleShowDeleteRequest(true)}
                        >
                            <DeleteIcon height={15} width={15} />
                        </Button>
                    )}

                    {!isDelegatedUser() && (
                        <Button
                            variant="link"
                            className="btn-only"
                            disabled={!(document.status !== DetailedStatus.COMPLETED)}
                            title={DocumentToolbarConstants.TITLE_CHANGE_RETENTION}
                            data-resource-id={ResourceIdLocators.DRL.DRLBtnChangeExpiry}
                            onClick={() => onClickUpdateRetentionPeriod()}
                        >
                            <ChangeExpiryIcon height={15} width={15} />
                        </Button>
                    )}
                    <Button
                        variant="link"
                        className="btn-only"
                        disabled={!(document.status !== DetailedStatus.COMPLETED)}
                        title={DocumentToolbarConstants.TITLE_SEND_REMINDER}
                        data-resource-id={ResourceIdLocators.DRL.DRLDtlBtnSendReminder}
                        onClick={() => onClickDocumentSendReminder()}
                    >
                        <BellIcon height={18} width={18} />
                    </Button>

                    {!showDownloadAll() && (
                        <Button
                            data-resource-id={ResourceIdLocators.DRL.DRLBtnDownloadFiles}
                            onClick={onClickDownloadAllDocuments}
                            variant="link"
                            className="btn-only"
                            title={DocumentToolbarConstants.TITLE_DOWNLOAD}
                        >
                            <DownloadIcon height={19} width={19} />
                        </Button>
                    )}
                </ButtonGroup>
            </div>
            <div className="document-pane-header">
                <div>
                    <p>
                        <b>To:</b> {document.recipient?.email}
                    </p>

                    <p>
                        <b>Sent On:</b>
                        <span> {getFormattedSentDateForDetailedView(document.sentDate)}</span>
                    </p>

                    {(document.status === DetailedStatus.OPEN || document.status === DetailedStatus.REOPEN) &&
                        document.dueDate && (
                            <p>
                                <b>Due On:</b>
                                <span> {getFormattedDueDate(document.dueDate)}</span>
                            </p>
                        )}
                    {document.status === DetailedStatus.COMPLETED && (
                        <p>
                            <b>Expires On:</b>
                            <span> {getDateForDocumentExpiryView(document.updatedDate, document.expiryDays)}</span>
                        </p>
                    )}
                </div>
                <div className="status-dropdown-wrapper">
                    <p>Status:</p>
                    <div data-resource-id={ResourceIdLocators.DRL.DRLBtnChangeState}>
                        <Dropdown
                            className={`secondary-dropdown document-status-dropdown ${
                                isDelegatedUser() ? "toolbar-icon-disabled" : ""
                            }`}
                            show={showStatusDropdown}
                            onToggle={() => toggleStatusDropdown(!showStatusDropdown)}
                        >
                            <Dropdown.Toggle className={"Dropdown-control"} disabled={isDelegatedUser()}>
                                {getDropdownToogleLabel()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="Dropdown-menu">
                                <Dropdown.Item className="Dropdown-option" onClick={handleDocumentStatusChange}>
                                    {document.status !== DetailedStatus.COMPLETED
                                        ? DocumentToolbarConstants.COMPLETE_STATUS
                                        : DocumentToolbarConstants.REOPEN_STATUS}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="document-body-wrapper">
                {document.message && (
                    <div
                        className="client-note"
                        dangerouslySetInnerHTML={{
                            __html: convertTextToHtml(document.message),
                        }}
                    ></div>
                )}
            </div>
            <div
                className="document-row-list-wrapper"
                style={{
                    maxHeight: getHeightForScrollBar(
                        (document.status === DetailedStatus.OPEN && Boolean(document.dueDate)) ||
                            document.status === DetailedStatus.COMPLETED,
                        document.subject
                    ),
                }}
            >
                {document.requestedFiles &&
                    document.requestedFiles.length > 0 &&
                    document.requestedFiles.map(file => {
                        return (
                            <DocumentRow
                                key={file.documentRequestFileId}
                                id={file.documentRequestFileId}
                                file={file}
                                activeDocuments={accordionRef.current}
                                isExpanded={isExpanded}
                                setActiveDocument={onAccordionClick}
                                onClickDeleteDocument={onClickDeleteDocument}
                                onClickDownloadAllFiles={onClickDownloadAllFiles}
                                onClickDownloadFile={onClickDownloadFile}
                                onClickSpecificFileSendReminder={onClickSpecificFileSendReminder}
                                onClickPreviewUploadedDocument={onClickPreviewUploadedDocument}
                                status={document.status}
                            />
                        );
                    })}
            </div>
            <div className="document-pane-footer">
                {!isDelegatedUser() && (
                    <button
                        tabIndex={0}
                        disabled={document.status === DetailedStatus.COMPLETED}
                        title={
                            document.status === DetailedStatus.COMPLETED
                                ? NewDRConstants.REQUEST_MORE_DISABLED_TITLE
                                : ""
                        }
                        className={"button-primary-blue-2 request-more-btn"}
                        data-resource-id={ResourceIdLocators.DRL.DRLDtlBtnRequestMore}
                        onClick={() => {
                            toggleRequestMore(true);
                        }}
                    >
                        <PlusCircleIcon color={ColorConstants.white} />
                        {NewDRConstants.REQUEST_MORE_TEXT}
                    </button>
                )}

                <span className={isExpandClassName} onClick={onExpandAllClick}>
                    {isExpanded ? "Collapse All" : "Expand All"}
                </span>
            </div>
            <ConfirmModal
                className="request-more-modal"
                show={showRequestMore}
                title={NewDRConstants.REQUEST_MORE_TEXT}
                confirmButtonName={NewDRConstants.OK_BUTTON_TEXT}
                onSubmit={onClickAddMore}
                onHide={() => onRequestMoreModalHide()}
                size="sm"
            >
                <DocumentRequestMore
                    documentRequestId={document.documentRequestId}
                    onFilesFormChange={handleFilesFormChange}
                    filesData={filesData}
                    onClickAddFiles={onClickAddFiles}
                    onClickRemoveRow={onClickRemoveRow}
                    validated={validated}
                    onCategoryChange={handleCategoryChange}
                />
            </ConfirmModal>
            <ConfirmModal
                className="delete-message-modal status-change-modal"
                show={showChangeStatus}
                title={DocumentToolbarConstants.SEND_DR_SUCCESS_MESSAGE}
                onHide={() => toggleChangeStatus(false)}
                additionalFooterElement={""}
                onSubmit={handleChangeDocumentStatusSubmit}
                size="sm"
                confirmButtonName="Yes"
                cancelButtonName="No"
            >
                <span>
                    {document.status === DetailedStatus.OPEN || document.status === DetailedStatus.REOPEN
                        ? DocumentToolbarConstants.CHANGE_STATUS_TO_COMPLETE_MESSAGE
                        : DocumentToolbarConstants.CHANGE_STATUS_TO_RE_OPEN_MESSAGE}
                </span>
            </ConfirmModal>
            <ConfirmModal
                className="delete-message-modal"
                show={showDeleteRequest}
                title={DocumentToolbarConstants.DELETE_REQUEST}
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                onSubmit={deleteDocumentRequestSubmit}
                onHide={() => toggleShowDeleteRequest(false)}
                size="sm"
            >
                <span>{DocumentToolbarConstants.DETAILED_DELETE_REQUEST}</span>
            </ConfirmModal>
            <ConfirmModal
                className="delete-message-modal status-change-modal"
                show={showChangeStatus}
                title={DocumentToolbarConstants.TITLE_CHANGE_STATUS}
                onHide={onHideChangeStatusModal}
                additionalFooterElement={document.status === DetailedStatus.COMPLETED ? NotifyCompletion : ""}
                onSubmit={handleChangeDocumentStatusSubmit}
                size="sm"
                confirmButtonName="Yes"
                cancelButtonName="No"
            >
                <span>
                    {document.status === DetailedStatus.OPEN || document.status === DetailedStatus.REOPEN
                        ? DocumentToolbarConstants.CHANGE_STATUS_TO_COMPLETE_MESSAGE
                        : DocumentToolbarConstants.CHANGE_STATUS_TO_RE_OPEN_MESSAGE}
                </span>
            </ConfirmModal>
            <ConfirmModal
                className="delete-message-modal"
                show={showDeleteDocument}
                title={DocumentToolbarConstants.DELETE_DOCUMENT}
                onSubmit={deleteUploadedDocumentSubmit}
                onHide={() => toggleShowDeleteDocument(false)}
                size="sm"
            >
                <span>{DocumentToolbarConstants.DETAILED_DELETE_DOCUMENT}</span>
            </ConfirmModal>

            {showChangeRetentionModal && (
                <ChangeRetentionPeriodModal
                    showState={showChangeRetentionModal}
                    retentionOptions={retentionOptions}
                    changeRtnResourceId={ResourceIdLocators.DRL.DRLBtnChangeExpiry}
                    onHide={() => setShowChangeRetentionModal(false)}
                    onSubmit={handleChangeRetentionSubmit}
                    document={document}
                />
            )}
            {showPreviewModal && (
                <PreviewUplodedFileModal
                    url={pdfUrlData}
                    fileType={previewFileDetails.fileType}
                    fileName={previewFileDetails.fileName}
                    fileGuid={previewFileDetails.fileGuid}
                    handleClose={onClosePreviewModal}
                    handleShow={onShowPreviewModal}
                    onClickDownloadUploadedDocument={onClickDownloadFile}
                />
            )}
        </div>
    );
};
