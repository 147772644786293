import React from "react";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import { DocumentCategoryModalConstants } from "../../../../../helper/Constants";
import { Form } from "react-bootstrap";

type DeleteCategoryProps = {
    show: boolean;
    onClose: () => void;
    handleSubmit: () => void;
    isMultiple: boolean;
};

const DeleteCategoryModal = ({ show, onClose, handleSubmit, isMultiple }: DeleteCategoryProps) => {
    return (
        <CustomModal
            show={show}
            onHide={() => {
                onClose();
            }}
            onSubmit={handleSubmit}
            cancelButtonName="Cancel"
            confirmButtonName="Ok"
            title={DocumentCategoryModalConstants.DELETE_CATEGORY_MODAL_TITLE}
            className="settings-modal"
        >
            <Form className="settings-form" onSubmit={e => e.preventDefault()}>
                <Form.Group className="marB15" controlId="AddSenderId">
                    <Form.Label>
                        {isMultiple
                            ? DocumentCategoryModalConstants.DELETE_CATEGORIES_MODAL_TEXT
                            : DocumentCategoryModalConstants.DELETE_CATEGORY_MODAL_TEXT}
                    </Form.Label>
                </Form.Group>
            </Form>
        </CustomModal>
    );
};

export default DeleteCategoryModal;
