import React, { ChangeEvent, MouseEvent, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Option } from "react-dropdown";
import { isFolderNameValid } from "../../../../../helper/Validations";
import { IFolderModel, initialFolder } from "../../../../../core/viewModels/folder/FolderModel";
import { ArchiveFolderType } from "../../../../../core/common/enums";
import {
    ArchiveConstants,
    CreateFolderModalConstants,
    FolderEventType,
    FolderToolbarConstants,
    MyFilesConstants,
    RadioButtonConstants,
    RetentionPeriodModal,
} from "../../../../../helper/Constants";
import { useDispatch } from "react-redux";
import { addNewFolder, updateFolderName } from "../../../../../actionCreators/folderActionCreator";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { AutomationIdConstants } from "../../../../../helper/AutomationConstants";
import { ColorConstants } from "../../../../../assets/custom/colors";
import { debounce } from "../../../../../helper/HelperFunctions";

interface ICreateFolderModalProps {
    show: boolean;
    onHide: () => void;
    onSubmit: (folder?: IFolderModel, showSharedFolderPopUp?: boolean) => void;
    retentionOptions?: Option[];
    currentFolder: IFolderModel;
    modalType: FolderEventType;
    renameBtnResourceId?: string;
    isSharedFolderDisabled?: boolean;
    setFolderName?: (name: string) => void;
    setDescription?: (name: string) => void;
}

export const CreateFolderModal: React.FC<ICreateFolderModalProps> = props => {
    const [folderName, setFolderName] = useState(
        props.currentFolder.folderName === MyFilesConstants.MY_FILES_NAME || props.modalType === FolderEventType.Create ? "" : props.currentFolder.folderName || ""
    );
    const [parentFolderIds, setparentFolderIds] = useState(
        props.currentFolder.parentFolderIds === MyFilesConstants.MY_FOLDER_NUMBER || props.modalType === FolderEventType.Create ? "" : props.currentFolder.parentFolderIds || ""
    );
    const [description, setDescription] = useState(
        props.currentFolder.description === MyFilesConstants.MY_FILES_NAME || props.modalType === FolderEventType.Create ? "" : props.currentFolder.description || ""
    );
    const [validationMessage, setValidationMessage] = useState("");
    const [dropDownValidationMessage, setDropDownValidationMessage] = useState("");
    const [selectedRetention, setRetention] = useState<string>("");
    const [privateFolder, setPrivateFolder] = useState(true);
    const [showNextButton, setShowNextButton] = useState(false);
    const [, setShowAdditionalFields] = useState(false);
    const dispatch = useDispatch();

    const isFolderValid = (): boolean => {
        let isValid = true;

        const trimmedFolderName = folderName.trim();
        if (trimmedFolderName === "") {
            isValid = false;
            setValidationMessage(ArchiveConstants.AddNewFolder.FOLDER_NAME_CANNOT_BE_EMPTY_ERROR_MSG);
        } else if (!isFolderNameValid(trimmedFolderName)) {
            isValid = false;
            setValidationMessage(ArchiveConstants.AddNewFolder.FOLDER_NAME_CANNOT_HAVE_SPECIAL_CHARS_ERROR_MSG);
        }
        if (selectedRetention === "" && props.modalType == FolderEventType.Create) {
            isValid = false;
            setDropDownValidationMessage(ArchiveConstants.AddNewFolder.SUB_FOLDER_RETENTION_ERROR_MSG);
        }

        return isValid;
    };

    const onRetentionDropdownChange = (e: any) => {
        setRetention(e.target.value);
        setDropDownValidationMessage("");
    };

    const formReset = () => {
        setFolderName("");
        setparentFolderIds("");
        setDescription("");
        setRetention("");
        setValidationMessage("");
        setDropDownValidationMessage("");
        setPrivateFolder(true);
    };

    const handleFormSubmit = (showSharedFolderPopUp?: boolean) => {
        if (isFolderValid()) {
            switch (props.modalType) {
                case FolderEventType.Create:
                    if (!validationMessage && !dropDownValidationMessage) {
                        dispatch(
                            addNewFolder(
                                {
                                    folderName: folderName.trim(),
                                    parentFolderIds: parentFolderIds.trim(),
                                    description: description,
                                    isPrivate: privateFolder,
                                    folderType: ArchiveFolderType.UNKNOWN,
                                    parentId: props.currentFolder?.folderId || 0,
                                    expiresDays: +selectedRetention,
                                    createdBy: initialFolder.createdBy,
                                    createdDate: initialFolder.createdDate,
                                    folderId: initialFolder.folderId,
                                    owner: initialFolder.owner,
                                },
                                (newFolder: IFolderModel) => {
                                    if (newFolder) {
                                        newFolder.folderId &&
                                            AppNotifier.Success(ArchiveConstants.AddNewFolder.SUCCESS_MSG);
                                        props.onSubmit(newFolder, showSharedFolderPopUp ?? false);
                                        formReset();
                                    }
                                }
                            )
                        );
                    }
                    break;
                case FolderEventType.Edit:
                    if (!validationMessage) {
                        const updatedFolder: IFolderModel = { ...props.currentFolder, folderName:folderName.trim(), description:description.trim()};
                        dispatch(
                            updateFolderName(
                                updatedFolder,
                                (updatedFolder: IFolderModel) => {
                                    if (updatedFolder) {
                                        AppNotifier.Success(FolderToolbarConstants.RENAMED_FOLDER_SUCCESS);
                                        props.setFolderName && props.setFolderName(updatedFolder.folderName);
                                        setDescription(updatedFolder.description);
                                        props.onSubmit(updatedFolder);
                                        setValidationMessage("");
                                        setFolderName(folderName.trim());
                                        setparentFolderIds(parentFolderIds.trim());
                                    }
                                },
                                props?.renameBtnResourceId
                            )
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isPrivate = event.target.value === "Private";
        setPrivateFolder(isPrivate);
        if (!isPrivate) {
            setShowAdditionalFields(true);
            setShowNextButton(true);
        } else {
            setShowAdditionalFields(false);
            setShowNextButton(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFolderName(e.target.value);
        setValidationMessage("");
    };

    const handleHide = () => {
        setPrivateFolder(true);
        setDescription("");
        props.onHide();
        formReset();
    };

    const debouncedHandleFormSubmit = debounce(handleFormSubmit, 500);
    return (
        <Modal
            className={`create-folder-modal ${props.modalType === FolderEventType.Edit ? "edit-folder-modal" : ""}`}
            dialogClassName="create-folder-modal-container"
            show={props.show}
            onHide={handleHide}
            onClick={(e: MouseEvent) => {
                e.stopPropagation();
            }}
        >
            <ModalHeader closeButton closeVariant="white">
                <Modal.Title
                    className="custom-modal-header"
                    data-auto-test={AutomationIdConstants.addFolderModal.HeaderCloseBtn}
                >
                    {props.modalType === FolderEventType.Create
                        ? CreateFolderModalConstants.CREATE_FOLDER_MODAL_TITLE
                        : CreateFolderModalConstants.EDIT_FOLDER_MODAL_TITLE}
                </Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Group className="create-folder-input">
                        <Form.Label>{CreateFolderModalConstants.FOLDER_NAME_LABEL}</Form.Label>
                        <Form.Control
                            className={`folder-input-text ${validationMessage ? "error" : ""}`}
                            data-auto-test={AutomationIdConstants.addFolderModal.FolderName}
                            maxLength={ArchiveConstants.AddNewFolder.FOLDER_NAME_MAXLENGTH}
                            value={folderName}
                            onChange={handleInputChange}
                            type="text"
                            placeholder={CreateFolderModalConstants.FOLDER_NAME_PLACEHOLDER}
                        />

                        {<p className="text-danger">{validationMessage}</p>}
                    </Form.Group>
                    {props.modalType === FolderEventType.Create && (
                        <div>
                            <Form.Group className="create-folder-input">
                                <div className="radio-options">
                                    <div className="radio-option">
                                        <label
                                            className={`custom-radio ${
                                                privateFolder ? "selected-radio-label" : "radio-label"
                                            }`}
                                        >
                                            <input
                                                className="radio-text pointer-cursor"
                                                type="radio"
                                                name="privacy"
                                                value="Private"
                                                checked={privateFolder}
                                                onChange={handleRadioChange}
                                            />
                                            <span
                                                className={`radio-label-text ${
                                                    privateFolder ? "bold-text" : "less-bold-text"
                                                }`}
                                                data-auto-test={AutomationIdConstants.addFolderModal.Private}
                                                style={{ pointerEvents: "none" }}
                                            >
                                                {RadioButtonConstants.PRIVATE_NAME}
                                            </span>
                                        </label>
                                        <div className="additional-info">
                                            <span className="additional-info-label">
                                                {RadioButtonConstants.PRIVATE_INFO}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`radio-option ${props?.isSharedFolderDisabled ? "disabled" : ""}`}>
                                        <label
                                            className={`custom-radio ${
                                                !privateFolder ? "selected-radio-label" : "radio-label"
                                            }`}
                                        >
                                            <input
                                                type="radio"
                                                name="privacy"
                                                value="Shared"
                                                className={`${props?.isSharedFolderDisabled ? "disabled" : ""}`}
                                                disabled={props?.isSharedFolderDisabled}
                                                checked={!privateFolder}
                                                onChange={handleRadioChange}
                                            />
                                            <span
                                                className={`radio-label-text ${
                                                    !privateFolder ? "bold-text" : "less-bold-text"
                                                }
                                                ${props?.isSharedFolderDisabled ? "disabled" : ""}`}
                                                data-auto-test={AutomationIdConstants.addFolderModal.Shared}
                                            >
                                                {RadioButtonConstants.SHARED_NAME}
                                            </span>
                                        </label>
                                        <div className={`additional-info  ${props?.isSharedFolderDisabled ? "disabled" : ""}`}>
                                            <span className="additional-info-label">
                                                {RadioButtonConstants.SHARED_INFO}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group
                                className="create-folder-input"
                                data-auto-test={AutomationIdConstants.addFolderModal.RetentionPeriod}
                            >
                                <Form.Label>{CreateFolderModalConstants.FOLDER_RETENTION_PERIOD_LABEL}</Form.Label>
                                <div>
                                    <Form.Select
                                        className={`folder-retention-dropdown ${dropDownValidationMessage ? "error" : ""}`}
                                        style={{color:selectedRetention?ColorConstants.black:ColorConstants.grey_placeholder}}
                                        value={selectedRetention}
                                        onChange={onRetentionDropdownChange}
                                    >
                                        <option value="" style={{ display: "none", color:ColorConstants.grey_placeholder}}>
                                            {RetentionPeriodModal.SELECT_RETENTION_PERIOD}
                                        </option>
                                        {props.retentionOptions?.map((option, index) => (
                                            <option key={index} value={option.value}  style={{color: ColorConstants.black}}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {<p className="text-danger">{dropDownValidationMessage}</p>}
                            </Form.Group>
                        </div>
                    )}

                    <Form.Group
                        className="create-folder-input-text"
                        data-auto-test={AutomationIdConstants.addFolderModal.Description}
                    >
                        <Form.Label>{CreateFolderModalConstants.FOLDER_DESCRIPTION_LABEL}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            maxLength={ArchiveConstants.AddNewFolder.FOLDER_DESCRIPTION_MAXLENGTH}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            type="text"
                            placeholder={CreateFolderModalConstants.FOLDER_DESCRIPTION_PLACEHOLDER}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleHide}
                    data-auto-test={AutomationIdConstants.addFolderModal.CancelBtn}
                >
                    Cancel
                </button>
                {props.modalType === FolderEventType.Create && privateFolder && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            debouncedHandleFormSubmit(false);
                        }}
                        data-auto-test={AutomationIdConstants.addFolderModal.CreateFolderBtn}
                    >
                        Create
                    </button>
                )}
                {props.modalType === FolderEventType.Edit && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            debouncedHandleFormSubmit(true);
                        }}
                        data-auto-test={AutomationIdConstants.addFolderModal.SaveBtn}
                    >
                        Save
                    </button>
                )}
                {showNextButton && props.modalType === FolderEventType.Create && !privateFolder && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            debouncedHandleFormSubmit(true);
                        }}
                        data-auto-test={AutomationIdConstants.addFolderModal.NextBtn}
                    >
                        Next
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
