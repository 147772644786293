import { INotificationsSettings, IRequestNotificationsSettings } from "../../../../reducers/adminSettingsReducer";

export const initialCompanyNotificationSettings: INotificationsSettings = {
    isExpiryNotificationEnable: true,
    isUserAllowedToChangeExpiryNotification: false,
    isDownloadNotificationEnable: false,
    isUserAllowedToChangeDownloadNotification: false,
};

export const intialCompanyRequestNotificationSettings: IRequestNotificationsSettings = {
    isRequestCompletionNotificationEnabled : true,
    isUserAllowedToChangeRequestCompletionNotification : false,
    isRequestReopenNotificationEnabled: true,
    isUserAllowedToChangeRequestReopenNotification: false,
};