import { AppThunkAction } from "../store";
import { actionTypes as reportActions } from "../types/reportsActionsTypes";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import axios from "axios";
import { completedDeleteFilter, completedReportFilters, completedSaveFilter, completedSendersList, receiveReportFilters, receiveSaveFilter, receiveSendersList, requestDeleteFilter, requestReportFilters, requestSaveFilter, requestSendersList } from "../actions/reportsActions";
import { IFilterModel } from "../core/viewModels/report/reportViewModel";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { logger } from "../oidcClient/authProvider";
import { appInsightsData } from "../helper/AppInsightsData";

export type DispatchActions = requestReportFilters
  | receiveReportFilters
  | completedReportFilters
  | requestSaveFilter
  | receiveSaveFilter
  | completedSaveFilter
  | requestDeleteFilter
  | completedDeleteFilter
  | requestSendersList
  | receiveSendersList
  | completedSendersList;

export type KnownActions = DispatchActions | NotificationAction;

export const getAllFilters =
  (
    filterSource: number,
    mailboxUserEmailId = getMailboxUserEmailId()

  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: reportActions.REQUEST_REPORT_FILTERS
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/Filter/GetAllFilters?filterSource=${filterSource}&mailboxUserEmailId=${mailboxUserEmailId}`,
        )
        .then((response) => {
          const data = response.data;
          dispatch({
            type: reportActions.RECEIVE_REPORT_FILTERS,
            payload: data
          });

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: reportActions.COMPLETED_REPORT_FILTERS,
          });
        });
    };

export const createFilter =
  (
    payload: IFilterModel,
    pageTitle?: string,
    callback?: (status: number) => void
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: reportActions.REQUEST_SAVE_FILTERS
      });
      logger.trackEvent({
        name: `${pageTitle} - ${appInsightsData.MessageHistory.Operation.FilterSaved}`,
        properties: {
          page: pageTitle,
          ...payload
        }
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/Filter/CreateFilter`,
          payload
        )
        .then((response) => {
          const data = response;
          if (data) {
            dispatch({
              type: reportActions.RECEIVE_SAVE_FILTERS
            });
          }
          if (callback) {
            callback(response.status);
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: reportActions.COMPLETED_SAVE_FILTERS,
          });
        });
    };

export const updateFilter =
  (
    payload: IFilterModel,
    pageTitle?: string,
    callback?: (status: number) => void
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: reportActions.REQUEST_SAVE_FILTERS
      });
      logger.trackEvent({
        name: `${pageTitle} - ${appInsightsData.MessageHistory.Operation.FilterUpdated}`,
        properties: {
          page: pageTitle,
          filterDate: payload
        }
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/Filter/UpdateFilter`,
          payload
        )
        .then((response) => {
          const data = response;
          if (data) {
            dispatch({
              type: reportActions.RECEIVE_SAVE_FILTERS
            });
          }
          if (callback) {
            callback(response.status);
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: reportActions.COMPLETED_SAVE_FILTERS,
          });
        });
    };
export const deleteFilter =
  (
    filterId: number,
    callback?: (data: number) => void
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: reportActions.REQUEST_DELETE_FILTERS
      });
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/Filter/DeleteFilter/${filterId}`
        )
        .then((response) => {
          const data = response;

          if (callback) {
            callback(data.status);
          }


        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => 
          dispatch({
            type: reportActions.COMPLETED_DELETE_FILTERS,
          })
        );
    };

    export const getSendersList =
    (
      filterSource: number,  
    ): AppThunkAction<KnownActions> =>
      (dispatch) => {
        dispatch({
          type: reportActions.REQUEST_SENDERS_LIST
        });
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/Filter/getAllSenders?filterSource=${filterSource}`,
          )
          .then((response) => {
            const data = response.data;
            dispatch({
              type: reportActions.RECEIVE_SENDERS_LIST,
              payload: data
            });
  
          })
          .catch((error: Error) => {
            dispatch({
              type: notificationActions.NOTIFICATION,
              statusMessage: error.message,
              statusType: StatusType.Error,
            });
          })
          .finally(() => {
            dispatch({
              type: reportActions.COMPLETED_SENDERS_LIST,
            });
          });
      };
