import React, { useEffect, useState } from "react";
import { DatePicker } from "rc-datepicker";
import MaskedInput from "react-text-mask";
import moment from "moment";

// Import the default style
import "rc-datepicker/lib/style.css";
import { getCurrentDate } from "../../../helper/DateHelperFunctions";

interface ICustomDatePickerProps {
  value?: string;
  onChange?: (date?: Date) => void;
  disabled?: boolean;
  calendarContainer?: any;
  calendarPlacement?: string;
  minDate?: Date;
  maxDate?: Date;
  clearFilter?: any;
  resetDateValues?: boolean;
  className?: string;
  clearDatePicker: any;
  onSubmit: () => void;
}

const DATE_FORMAT = "MM/DD/YYYY";

const CustomDatePicker: React.FC<ICustomDatePickerProps> = (props) => {
  const DatePickerPart = DatePicker as any;
  const [state, setState] = useState({
    value: getCurrentDate(),
    inputDate: props.value ?? "",
    calendarState: false,
    cachedPrevProp: {} as ICustomDatePickerProps,
  });

  useEffect(() => {
    props.clearDatePicker.current = clearDate;
  }, []);

  useEffect(() => {
    if (props.resetDateValues) {
      setState({ ...state, value: getCurrentDate(), inputDate: "" });
    }
  }, [props.resetDateValues]);

  useEffect(() => {
    if (props.value) {
      setState({
        ...state,
        inputDate: props.value
      });
    }
  }, [props.value]);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateVal = e.target.value;

    if (dateVal.length === 10) {
      const splitDate = dateVal.split("/");
      const formattedDate: any = moment(
        splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1] + "T00:00:00Z"
      ).toISOString(true);

      if (splitDate[2].charAt(3) !== "_") {
        setState({
          ...state,
          value: formattedDate,
          inputDate: dateVal,
          calendarState: false,
        });
        if (props.onChange) {
          props.onChange(formattedDate && new Date(formattedDate));
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") props.onSubmit();
  };

  const onChangeCustom = (date: Date) => {
    const formattedDate = moment(date.toString()).format("MM/DD/YYYY");

    const splitDate = formattedDate.split("/");
    const finalDate: string = moment(
      splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1] + "T00:00:00Z"
    ).toISOString(true);

    setState({
      ...state,
      value: date,
      inputDate: formattedDate,
      calendarState: false,
    });
    if (props.onChange) {
      props.onChange((finalDate && new Date(finalDate)) || undefined);
    }
  };

  const clearDate = () => {
    setState({ ...state, value: getCurrentDate(), inputDate: "" });
    if (props.clearFilter) {
      props.clearFilter();
      return;
    }
    if (props.onChange) {
      props.onChange(getCurrentDate());
    }
  };

  const handleBlur = () => {
    setState({ ...state, calendarState: false });
  };

  const calendarRender = (DatePickerComp: any) => {
    const MaskComp = MaskedInput as any;
    return (
      <div
        className={`calendarContainer ${props.className}`}
        onBlur={handleBlur}
      >
        <div className={`calendarClass ${props.disabled ? "disabled" : ""}`}>
          <MaskComp
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            placeholder={DATE_FORMAT}
            type="text"
            value={state.inputDate}
            onClick={() => {
              setState({ ...state, calendarState: true });
            }}
            className={`inputClass form-control ${props.disabled ? "calender-input-disabled" : ""}`}
            onKeyUp={null}
            onChange={changeInput}
            onKeyDown={handleKeyDown}
          />
          <span className="minusClass" onClick={clearDate}>
            x
          </span>
        </div>
        {state.calendarState && (
          <div
            className="calendarInput"
            id="dateId"
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <DatePickerComp
              value={state.value}
              disabled={props.disabled}
              onChange={onChangeCustom}
              className="group-input dateClass"
              minDate={props.minDate}
              maxDate={props.maxDate}
              closeOnClickOutside={false}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="datepicker-control-section" id="datepicker_input">
          {calendarRender(DatePickerPart)}
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
