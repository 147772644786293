import React, { FC, useRef, useState } from "react";
import { Alert, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { PasswordOptions } from "../../../core/viewModels/company/CompanySettingsViewModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import { ChangePasswordConstants, ChangeSecurityConstants, ValidationContants } from "../../../helper/Constants";
import { getPasswordOptionLabel } from "../../../helper/HelperFunctions";
import { ApplicationState } from "../../../store";
import ConfirmModal from "../../common/ui/Modals/ConfirmModal";
import * as Validation from "../../../helper/Validations";
import { IMyAccountLayout } from "./MyAccountLayout";
import { savePassword } from "../../../actions/userProfileActions";
import { IPasswordState  } from "../../../core/viewModels/profile/ProfileViewModel";
import { logger } from "../../../oidcClient/authProvider";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { WithLogout, WithLogoutProps } from "../../../oidcClient/withLogout";

interface IVisibility {
    current: boolean;
    new: boolean;
    confirm: boolean;
}
interface IPasswordForm {
    current: string;
    new: string;
    confirm: string;
}
interface IMyPasswordState {
    alert: string;
    message: string;
    showAlert: boolean;
    saving: boolean;
}
interface IChangePasswordModal extends IMyAccountLayout, WithLogoutProps {
    hideProfileModal?: () => void
}

const Password: FC<IChangePasswordModal> = props => {
    const ref = useRef<HTMLFormElement>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const [toggleVisibility, setToggleVisibility] = useState<IVisibility>({
        current: false,
        new: false,
        confirm: false,
    });
    const [passwordData, setPasswordData] = useState<IPasswordForm>({ current: "", new: "", confirm: "" });
    const profile = useSelector((state: ApplicationState) => state.userProfile);
    const passwordPolicySetting = useSelector((state: ApplicationState) => state.passwordPolicySettingState);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [state, setState] = useState<IMyPasswordState>({
        alert: "",
        message: "",
        showAlert: false,
        saving: false,
    });
    const dispatch = useDispatch();

    const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        e.stopPropagation();

        const form = ref.current;
        if (form) {
            setValidated(false);
            submitChanges();
        } else {
            setValidated(true);
        }
    };

    const handleVisibility = (name: string) => {
        setToggleVisibility({
            ...toggleVisibility,
            [name]: !toggleVisibility[name as keyof IVisibility],
        });
    };

    const handleOnChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        const data = { ...passwordData, [element.id]: element.value };
        setPasswordData(data);
    };
    const passwordoptions = () => {
        const items: any[] = [];
        const selectedOptions = Object.keys(PasswordOptions)
            .filter(key => !isNaN(Number(key)))
            .map(key => Number(key))
            .filter(
                option =>
                    option > 0 &&
                    (option & passwordPolicySetting.passwordSettings.passwordOptions) === option
            );

        selectedOptions.forEach(option => {
            const label = getPasswordOptionLabel(option);
            items.push(<li> {label}</li>);
        });

        return <div>{items}</div>;
    };

    const submitChanges = () => {
        if (!passwordData.current || passwordData.current.trim() === "") {
            setState({
                ...state,
                message: ValidationContants.OldPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (!passwordData.new || passwordData.new.trim() === "") {
            setState({
                ...state,
                message: ValidationContants.NewPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (!passwordData.confirm || passwordData.confirm.trim() === "") {
            setState({
                ...state,
                message: ValidationContants.ConfirmPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        const validationResult = Validation.validatePasswordBasedOnPolicy(
            passwordData.new,
            passwordPolicySetting.passwordSettings
        );
        if (validationResult.isError) {
            setState({
                ...state,
                message: validationResult.errorDescription,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (passwordData.new.trim() === passwordData.confirm.trim()) {
            setShowConfirmationModal(true);
        } else {
            setState({
                ...state,
                message: ValidationContants.PasswordNotMatchedWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }
    };

    const handlePasswordConfirmation = () => {
        setShowConfirmationModal(false);
        setState({
            alert: "",
            message: "",
            showAlert: false,
            saving: true,
        });
        logger.trackEvent({
            name: `${appInsightsData.MyAccount.PasswordPageTitle} - ${appInsightsData.MyAccount.Operation.UpdatePasswordClick}`,
            properties: {
                page: appInsightsData.MyAccount.PasswordPageTitle,
                email: profile.emailAddress,
            },
        });
        const passwordstate: IPasswordState = { password: passwordData.current, newPassword: passwordData.new };
        dispatch(
            savePassword(passwordstate, false, (response: any, error: any) => {
                setState({ ...state, saving: false });
                if (error) {
                    AppNotifier.Error(error.response.data ?? ChangeSecurityConstants.SECURITY_UPDATE_FAILED);
                } else {
                    if (response) {
                        AppNotifier.Success(ChangeSecurityConstants.SECURITY_UPDATE_SUCCESS);
                        setState({
                            ...state,
                            alert: "",
                            message: "",
                            showAlert: false,
                        });
                        setPasswordData({
                            current: "",
                            new: "",
                            confirm: "",
                        });
                        props.onHide();
                        props.onLogout();
                    } else {
                        setState({
                            ...state,
                            message: response,
                            alert: "warning",
                            showAlert: true,
                        });
                    }
                }
            })
        );
    };

    const hideModal: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        e.stopPropagation();
        props.onHide();
    };
    return (
        <div>
            <h3 className="heading-blue-1" data-auto-test={AutomationIdConstants.userSettingsMyAccount.PasswordTitleChangePassword}>{ChangePasswordConstants.TITLE}</h3>
            <p className="text-black-50 margin-bottom-20-px">{ChangePasswordConstants.DESCRIPTION}</p>
            <LoadingOverlay style={{ height: "100%" }}>
                <Loader loading={state.saving} />
                {state.showAlert && (
                    <Alert variant={state.alert}>
                        <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
                        {state.message}
                    </Alert>
                )}
                <Row>
                    <Col>
                        <Form ref={ref} id="change-password-form" noValidate validated={validated}>
                            <Form.Group controlId="current">
                                <Form.Label className="margin-bottom-15-px" data-auto-test={AutomationIdConstants.userSettingsMyAccount.PasswordTitleCurrentPassword}>
                                    {ChangePasswordConstants.LABEL_CURRENT_PASSWORD}
                                </Form.Label>
                                <InputGroup className="margin-bottom-20-px">
                                    <Form.Control
                                        type={toggleVisibility["current"] ? "text" : "password"}
                                        onChange={handleOnChange}
                                        placeholder={ChangePasswordConstants.PLACEHOLDER_CURRENT_PASSWORD}
                                    />
                                    <InputGroup.Text onClick={() => handleVisibility("current")}>
                                        {
                                            <i
                                                className={`fas ${
                                                    toggleVisibility["current"] ? "fa-eye" : "fa-eye-slash"
                                                }`}
                                            />
                                        }
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="new">
                                <Form.Label className="margin-bottom-15-px" data-auto-test={AutomationIdConstants.userSettingsMyAccount.PasswordTitleNewPassword}>
                                    {ChangePasswordConstants.LABEL_NEW_PASSWORD}
                                </Form.Label>
                                <InputGroup className="margin-bottom-20-px">
                                    <Form.Control
                                        type={toggleVisibility["new"] ? "text" : "password"}
                                        onChange={handleOnChange}
                                        placeholder={ChangePasswordConstants.PLACEHOLDER_NEW_PASSWORD}
                                    />
                                    <InputGroup.Text onClick={() => handleVisibility("new")}>
                                        {<i className={`fas ${toggleVisibility["new"] ? "fa-eye" : "fa-eye-slash"}`} />}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="confirm">
                                <Form.Label className="margin-bottom-15-px" data-auto-test={AutomationIdConstants.userSettingsMyAccount.TitleConfirmPasswordText}>
                                    {ChangePasswordConstants.LABEL_CONFIRM_PASSWORD}
                                </Form.Label>
                                <InputGroup className="margin-bottom-20-px">
                                    <Form.Control
                                        type={toggleVisibility["confirm"] ? "text" : "password"}
                                        onChange={handleOnChange}
                                        placeholder={ChangePasswordConstants.PLACEHOLDER_CONFIRM_PASSWORD}
                                    />
                                    <InputGroup.Text onClick={() => handleVisibility("confirm")}>
                                        {
                                            <i
                                                className={`fas ${
                                                    toggleVisibility["confirm"] ? "fa-eye" : "fa-eye-slash"
                                                }`}
                                            />
                                        }
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="padding-top-20-px">
                                <button className="margin-right-4 button-underlined" onClick={hideModal}>
                                    {ChangePasswordConstants.CANCEL_BUTOTN}
                                </button>
                                <button
                                    className="button-primary-green-rounded"
                                    type="submit"
                                    form="report-form"
                                    onClick={handleSubmit}
                                    data-auto-test={AutomationIdConstants.userSettingsMyAccount.PasswordChangeOkBtn}
                                >
                                    {ChangePasswordConstants.OK_BUTTON}
                                </button>
                            </div>
                        </Form>
                    </Col>
                    <Col className="password-policies">
                        <h5 className="margin-bottom-10-px padding-left-10-px">
                            <i className="fas fa-exclamation-triangle margin-right-10-px" />
                            <span>{ChangePasswordConstants.PASSWORD_POLICY_TITLE}</span>
                        </h5>
                        <ol>
                            <li className="margin-bottom-10-px">
                                {ChangePasswordConstants.PASSWORD_LENGTH}
                                {passwordPolicySetting.passwordSettings.length}
                            </li>
                            {passwordPolicySetting.passwordSettings.passwordOptions !=
                                PasswordOptions.None && (
                                <li>
                                    {ChangePasswordConstants.PASSWORD_REQUIRED}
                                    <ul className="margin-top-10-px">{passwordoptions()}</ul>
                                    {(passwordPolicySetting.passwordSettings.passwordOptions &
                                        PasswordOptions.SpecialCharacters) ===
                                        PasswordOptions.SpecialCharacters && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>{ChangePasswordConstants.PASSWORD_CHARACTERS_LIST}</Tooltip>
                                            }
                                        >
                                            <div className="margin-top-20-px tooltip-target">
                                                {ChangePasswordConstants.PASSWORD_SPECIAL_CHARACTERS}
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                </li>
                            )}
                        </ol>
                    </Col>
                </Row>
                <ConfirmModal
                    show={showConfirmationModal}
                    title={ChangeSecurityConstants.SECURITY_CHANGE_MODAL_TITLE}
                    confirmButtonName={ChangeSecurityConstants.CONFIRM_BUTTON_NAME}
                    onSubmit={handlePasswordConfirmation}
                    onHide={() => {
                        setShowConfirmationModal(false);
                    }}
                    size="sm"
                    className="modal-confirm-delete"
                >
                    <span>{ChangeSecurityConstants.SECURITY_CHANGE_LOGOUT_WARNING}</span>
                </ConfirmModal>
            </LoadingOverlay>
        </div>
    );
};

export default WithLogout(Password);

