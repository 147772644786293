import { Action, Reducer } from "redux";
import produce from "immer";
import * as DropOffActions from "../actionCreators/dropOffActionCreators";
import { actionTypes } from "../types/dropOffTypes";
import { IDropOffRecipient, IDropOffSender } from "../core/viewModels/company/settings/DropOffSettings";


export interface IDropOffLinks {
  companyDropOffLink: string;
  personalDropOffLink: string;
}

export interface IDropOffStore {
  isLoading: boolean;
  dropOffLinks: IDropOffLinks;

  dropOffSendersData: {
    isLoading:boolean
    dropOffSenders:IDropOffSender[]
  }
  dropOffRecipientsData: {
    isLoading:boolean
    dropOffRecipients:IDropOffRecipient[]
  }

}

const initialState: IDropOffStore = {
  isLoading: false,
  dropOffLinks: {
      companyDropOffLink: "",
      personalDropOffLink: ""
  },
  dropOffSendersData: {
    isLoading:false,
    dropOffSenders:[]
  },
  dropOffRecipientsData: {
    isLoading:false,
    dropOffRecipients:[]
  }
};

export const reducer: Reducer<IDropOffStore> = produce(
  (draft: IDropOffStore = initialState, incomingAction: Action) => {
    const action = incomingAction as DropOffActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_DROP_OFF_LINKS:
        draft.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_DROP_OFF_LINKS:
        draft.isLoading = false;
        draft.dropOffLinks = action.payload;
        return draft;
      case actionTypes.RESET_DROP_OFF_LINKS:
        draft.dropOffLinks = {
          companyDropOffLink: "",
          personalDropOffLink: ""
      };
        return draft;
      
      case actionTypes.REQUEST_DROP_OFF_SENDERS:
          draft.dropOffSendersData.isLoading = true;
          return draft;
      case actionTypes.RECEIVE_DROP_OFF_SENDERS:
        draft.dropOffSendersData.isLoading  = false;
        draft.dropOffSendersData.dropOffSenders = action.payload;
          return draft;
      case actionTypes.COMPLETE_DROP_OFF_SENDERS:
        draft.dropOffSendersData.isLoading = false;
        return draft;
      
      case actionTypes.REQUEST_DROP_OFF_RECIPIENTS:
          draft.dropOffRecipientsData.isLoading = true;
          return draft;
      case actionTypes.RECEIVE_DROP_OFF_RECIPIENTS:
        draft.dropOffRecipientsData.isLoading  = false;
        draft.dropOffRecipientsData.dropOffRecipients = action.payload;
          return draft;
      case actionTypes.COMPLETE_DROP_OFF_RECIPIENTS:
        draft.dropOffRecipientsData.isLoading = false;
        return draft;
      
      default:
        return draft;
    }
  }
);
