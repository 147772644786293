import React, { useEffect, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import "./styles.scss";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import { initialLockLinkObject, ILockLinkModel } from "../../../core/viewModels/unlock/UnlockModel";
import { DateFormatConstants, DEFAULT_PAGE_SIZE, UnlockLinkConstants, viewingOthersInboxLabel } from "../../../helper/Constants";
import { getUnlockLinks, updateUnlockLinks } from "../../../actionCreators/unlockActionCreator";
import { ApplicationState } from "../../../store";
import paginationFactory from "react-bootstrap-table2-paginator";
import { UnlockIcon } from "../../svg/IconCollection";
import { ColorConstants } from "../../../assets/custom/colors";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import SwitchButtonGroup from "../../common/ui/switch/SwitchButtonGroup";
import { UnlockType } from "../../../core/common/enums";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";

export const Unlock: React.FC = () => {
    const dispatch = useDispatch();
    const [unlockLinks, setUnlockLinks] = useState<ILockLinkModel[]>([initialLockLinkObject]);
    const [selectedUnlockLinks, setSelectedUnlockLinks] = useState<ILockLinkModel[] | []>([]);
    const [pagination, setPagination] = useState({
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: 1,
        totalRecords: 0,
    });
    const [reload, setReload] = useState(false);
    const unlockStoreData = useSelector((state: ApplicationState) => state.unlockState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const tablePaginationOptions = {
        totalSize: pagination.totalRecords,
        sizePerPage: pagination.pageSize,
        page: pagination.currentPage,
        paginationTotalRenderer: undefined,
        onPageChange: (page: number) => handleOnPageChange(page),
        showTotal: false,
        withFirstAndLast: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
    };

    const [unlockToggle, setUnlockToggle]= useState<UnlockType>(UnlockType.Messages);

    const isAnySelected = selectedUnlockLinks.length > 0;

    useEffect(() => {
        dispatch(getRestrictedFileTypes());
        logger.trackPageView(appInsightsData.Unlock.PageTitle);
    },[]);

    useEffect(() => {
        // Fetch unlock links dispatch call
        dispatch(getUnlockLinks(unlockToggle));
    }, [reload]);

    useEffect(() => {
        if (unlockStoreData.unlockLinks) {
            setUnlockLinks(unlockStoreData.unlockLinks);
            setPagination({
                ...pagination,
                totalRecords: unlockStoreData.unlockLinks.length,
            });
        }
    }, [unlockStoreData]);

    const emailAddressFormatter = (cell: string): JSX.Element => {
        return (
            <span className="ellipsis email-cell" title={cell}>
                {cell}
            </span>
        );
    };

    const subjectFormatter = (cell: string): JSX.Element => {
        return (
            <span className="ellipsis subject-cell" title={cell}>
                {cell}
            </span>
        );
    };

    const sentDateFormatter = (cell: string, row: ILockLinkModel): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span className="ellipsis date-cell">
                    {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
                </span>
                {unlockFormatter(row)}
            </div>
        );
    };

    const unlockFormatter = (row: ILockLinkModel): JSX.Element => {
        return (
            <div className="inline-actions-bar-container">
                <button
                    className={"button-secondary-blue-outlined"}
                    onClick={e => {
                        e.stopPropagation();
                        handleInlineUnlockClick(row);
                    }}
                >
                    {"Unlock"}
                </button>
            </div>
        );
    };

    const handleInlineUnlockClick = (row: ILockLinkModel) => {
        setSelectedUnlockLinks([row]);
        handleUnlockClick([row]);
    };

    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action drop-off-header">
                <span
                    onClick={() =>isAnySelected && handleUnlockClick([])}
                    title={UnlockLinkConstants.UNLOCK_BUTTON_HOVER_TEXT}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <UnlockIcon
                        width={18}
                        height={18}
                        icon="UnlockIcon"
                        color={ColorConstants.brand_pimary_blue_2}
                        disabled={!isAnySelected}
                    />
                </span>
            </div>
        );
    };

    const columns: ColumnDescription[] = [
        {
            text: "Email Address",
            dataField: "emailAddress",
            sort: true,
            formatter: emailAddressFormatter,
        },
        {
            text: "Subject",
            dataField: "subject",
            sort: true,
            formatter: subjectFormatter,
        },
        {
            text: "Date Sent",
            dataField: "dateSent",
            sort: true,
            formatter: sentDateFormatter,
        },
        {
            text: "",
            dataField: "unlock",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const setNoContent = () => {
        if (!unlockStoreData.isLoading && unlockStoreData.unlockLinks.length === 0) {
            return UnlockLinkConstants.NO_DATA_TO_DISPLAY;
        }
    };

    const handleOnSelect = (row: ILockLinkModel, isSelect: boolean) => {
        let selectedLinksCopy = [...selectedUnlockLinks];

        if (isSelect) {
            selectedLinksCopy.push(row);
        } else {
            selectedLinksCopy = selectedLinksCopy.filter(
                item => item.sentMessageRecipientId != row.sentMessageRecipientId
            );
        }
        setSelectedUnlockLinks(selectedLinksCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: ILockLinkModel[]) => {
        if (isSelect) {
            setSelectedUnlockLinks(rows);
        } else {
            setSelectedUnlockLinks([]);
        }
    };

    const handleUnlockClick = (rows?: ILockLinkModel[]) => {
        const payload = rows && rows.length > 0 ? rows : selectedUnlockLinks;
        // Unlock links dispatch call
        dispatch(
            updateUnlockLinks(payload,unlockToggle, (result: boolean) => {
                if (result) {
                    // Go to previous page if all the links are unlocked from that page
                    if (selectedUnlockLinks.length === pagination.pageSize && pagination.currentPage > 1) {
                        setPagination({
                            ...pagination,
                            currentPage: pagination.currentPage - 1,
                        });
                    }
                    setReload(reload => !reload);
                    setSelectedUnlockLinks([]);
                }
            })
        );
    };

    const handleOnPageChange = async (pageNo: number) => {
        setPagination({
            ...pagination,
            currentPage: pageNo,
        });
    };

const handleChangeUnlockType =(unlock:UnlockType) => {    
    switch(unlock)
    {
        case UnlockType.Messages:
                unlock = UnlockType.Requests;
                break;
            case UnlockType.Requests:
                unlock = UnlockType.Messages;
                break;
            default:
                unlock = UnlockType.Messages;
                break;
    }
    setUnlockToggle(unlock);
    dispatch(getUnlockLinks(unlock));
};
    const selectRow: SelectRowProps<any> = {
        mode: "checkbox",
        clickToSelect: true,
        nonSelectable: [],
        selected: selectedUnlockLinks.map(link => link.sentMessageRecipientId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedUnlockLinks.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={""}
            />
        ),
    };

    return (
        <LoadingOverlay className="unlock-loader">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <div className="unlock-parent-container">
                <div>
                    <header>
                        <h3>{UnlockLinkConstants.UNLOCK_HEADER_TITLE_TEXT}
                        <div className="unlock-switch-div">
                        <SwitchButtonGroup
                                selected={UnlockType[unlockToggle]}
                                onSwitchChange={()=>{ handleChangeUnlockType(unlockToggle);}}                                
                                option1Text={UnlockType[UnlockType.Messages]}
                                option2Text={UnlockType[UnlockType.Requests]}
                            />                        
                        </div>
                        </h3>
                        
                        <span>{UnlockLinkConstants.UNLOCK_HEADER_HELPER_TEXT}</span>
                    </header>
                </div>
                <div className="unlock-table-container">
                    <BootstrapTable
                        classes="settings-table"
                        keyField="sentMessageRecipientId"
                        columns={columns}
                        data={unlockLinks}
                        selectRow={selectRow}
                        noDataIndication={setNoContent()}
                        pagination={paginationFactory(tablePaginationOptions)}
                        bordered={false}
                    />
                </div>
            </div>
            <Loader loading={unlockStoreData.isLoading} />
        </LoadingOverlay>
    );
};
