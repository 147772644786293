import React from "react";
import { Modal } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { IModalProps } from "../../../core/viewModels/profile/ProfileViewModel";
import { WithLogout, WithLogoutProps } from "../../../oidcClient/withLogout";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { UserManagementConstants } from "../../../helper/Constants";


interface LogoutState {
    saving: boolean;
}

export class Logout extends React.Component<IModalProps & WithLogoutProps, LogoutState> {
    constructor(props: any) {
        super(props);
        this.state = {
            saving: false
        };
    }
    private onLogout = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        this.setState({
            saving: true
        });

        this.props.onLogout();
    };

    public render() {
        return <Modal
            show={this.props.showState}
            contentClassName="border-radius-5"
            dialogClassName="common-modal">

            <Modal.Header className="common-modal-header">
                <span className='common-modal-header-text'>
                    Log Out
                </span>
            </Modal.Header>
            <Modal.Body className="common-modal-content">
                <LoadingOverlay className='full-height'>
                    {UserManagementConstants.UserLogoutWarnigMessage}
                    <Loader loading={this.state.saving} text="Log Out in progress..." />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className="common-modal-footer">
                <button
                    className="modal-secondary-button secondary-btn-bg"
                    onClick={this.props.onHide}
                    data-test-auto={AutomationIdConstants.logoutModal.LogoutCancelBtn}
                >No, Discard</button>
                <button
                    className="modal-primary-button red-btn-bg"
                    onClick={this.onLogout}
                    disabled={this.state.saving}
                    data-test-auto={AutomationIdConstants.logoutModal.LogoutSubmitBtn}
                >Yes, Log Out</button>
            </Modal.Footer>
        </Modal>;
    }
}
export default WithLogout(Logout);