import produce from "immer";
import { Action, Reducer } from "redux";
import { actionTypes } from "../types/userSettingsTypes";
import * as UserSettingsActions from "../actionCreators/userSettingsActionCreators";
import {
    IDelegateeUser,
    IDropOffSettings,
    INotificationsSettings,
    IDocumentRequestNotificationSettings,
    IUserSettingsModel,
} from "../core/viewModels/user/UserViewModel";
import { DropOffLinkType } from "../core/common/enums";

export interface IUserSettingsStore {
    isLoading: boolean;
    delegateeList: IDelegateeUser[];
    userSettings: IUserSettingsModel | null;
    defaultSettings: { enableLastLogin: boolean };
    dropOffSettings: IDropOffSettings;
    notificationsSettings: INotificationsSettings;
    documentRequestNotificationsSettings: IDocumentRequestNotificationSettings;
}

export const initialState: IUserSettingsStore = {
    isLoading: false,
    delegateeList: [],
    userSettings: null,
    defaultSettings: { enableLastLogin: false },
    dropOffSettings: {
        isMailNotificationToSend: false,
        dropOffLinkType: DropOffLinkType.COMPANY,
    },
    notificationsSettings: {
        isDownloadNotificationEnable: false,
        isExpiryNotificationEnable: false,
    },
    documentRequestNotificationsSettings:{
        isDocumentRequestCompletionNotificationEnabled: false,
        isDocumentRequestReopenNotificationEnabled: false,
    }
};

export const reducer: Reducer<IUserSettingsStore> = produce(
    (draft: IUserSettingsStore = initialState, incomingAction: Action) => {
        const action = incomingAction as UserSettingsActions.DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_USER_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_USER_SETTINGS:
                draft.isLoading = false;
                draft.userSettings = action.payload;
                return draft;
            case actionTypes.COMPLETE_USER_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_DELEGATEE_LIST:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_DELEGATEE_LIST:
                draft.isLoading = false;
                draft.delegateeList = action.payload;
                return draft;
            case actionTypes.COMPLETE_DELEGATEE_LIST:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_UPDATE_USER_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_UPDATE_USER_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.COMPLETE_UPDATE_USER_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.UPDATE_USER_SETTINGS:
                draft.userSettings = action.payload;
                return draft;
            case actionTypes.RECEIVE_LAST_LOGIN_ENABLE:
                draft.defaultSettings = action.payload;
                return draft;
            case actionTypes.REQUEST_DROPOFF_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVED_DROPOFF_SETTINGS:
                draft.isLoading = false;
                draft.dropOffSettings = action.payload;
                return draft;
            case actionTypes.COMPLETED_DROPOFF_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_UPDATE_DROPOFF_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVED_UPDATE_DROPOFF_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.COMPLETED_UPDATE_DROPOFF_SETTINGS:
                draft.isLoading = false;
                return draft;
            default:
                return draft;
        }
    }
);
