export enum actionTypes {
  REQUEST_DOWNLOAD_SINGLE_ATTACHMENT = "request_download_single_attachment",
  RECEIVED_DOWNLOAD_SINGLE_ATTACHMENT = "received_download_single_attachment",
  COMPLETED_DOWNLOAD_SINGLE_ATTACHMENT = "completed_download_single_attachment",
  REQUEST_DOWNLOAD_ALL_ATTACHMENTS = "request_download_all_attachments",
  RECEIVED_DOWNLOAD_ALL_ATTACHMENTS = "received_download_all_attachments",
  COMPLETED_DOWNLOAD_ALL_ATTACHMENTS = "completed_download_all_attachments",
  REQUEST_DOWNLOAD_WEB_ADD_IN_MANIFEST = "request_download_web_add_in_manifest",
  RECEIVED_DOWNLOAD_WEB_ADD_IN_MANIFEST = "received_download_web_add_in_manifest",
  COMPLETED_DOWNLOAD_WEB_ADD_IN_MANIFEST = "completed_download_web_add_in_manifest",
}
