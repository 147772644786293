import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";
import { debounce } from "throttle-debounce";

import { fetchComposeRecipients } from "../../../../actionCreators/composeActionCreators";
import { RecipientType } from "../../../../core/common/enums";
import { IRecipientModel } from "../../../../core/viewModels/message/RecipientModel";
import { createOption } from "../../../../helper/HelperFunctions";

interface IRecipientProps {
  setRecipient: (data: any, type: RecipientType) => void;
  toRecipients: any;
  ccRecipients: any;
  hideToLabel?: boolean;
}

const Recipient: React.FC<IRecipientProps> = (props) => {
  const dispatch = useDispatch();
  const [recipientOptions, setRecipientOptions] = useState<any[]>([]);
  const [toRecipients, setToRecipients] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    updateRecipientOptions();
  }, [props.toRecipients, props.ccRecipients]);

  const updateRecipientOptions = () => {
    const toRecipients = props.toRecipients?.map((item: IRecipientModel) => {
      const label =
        item.firstName || item.lastName ?
          `${item.firstName ?? ""} ${item.lastName ?? ""} <${item.emailAddress}>`
          : item.emailAddress;
      return createOption(`${item.emailAddress}`, label);
    });
    setToRecipients(toRecipients);
  };

  const handleRecipientsSuccessCallback = (data: IRecipientModel[]) => {
    const options = data.map((item: IRecipientModel) =>
      createOption(
        `${item.emailAddress}`,
        item.firstName || item.lastName ?
          `${item.firstName ?? ""} ${item.lastName ?? ""} <${item.emailAddress}>`
          : item.emailAddress
      )
    );
    setRecipientOptions(options);
    setLoading(false);
  };

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length < 3) return;
    else {
      setLoading(true);
      dispatch(
        fetchComposeRecipients(inputValue, (data: IRecipientModel[]) => {
          handleRecipientsSuccessCallback(data);
        })
      );
    }
  };

  const handleToChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    props.setRecipient(actionMeta, RecipientType.ToRecipient);
  };

  return (
    <div className="recipient-wrapper">
      {!props.hideToLabel &&
        <button className="button-white">
          To
        </button>}
      <CreatableSelect
        isMulti
        cacheOptions
        isClearable={false}
        placeholder=""
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        allowCreateWhileLoading={true}
        options={recipientOptions}
        onChange={handleToChange}
        onInputChange={debounce(1000, handleInputChange)}
        formatCreateLabel={(value: any) => value}
        theme={(theme) => {
          return { ...theme, borderRadius: 0 };
        }}
        components={{
          DropdownIndicator: null,
        }}
        styles={creatableStyle}
        value={toRecipients}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Recipient;

export const creatableStyle = {
  control: (base: any) => ({
    ...base,
    border: "none",
    borderBottom: "solid 1px #ced4da",
    minHeight: "32px",
    fontSize: "14px",
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: "13px",
  }),
};
