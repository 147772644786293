import React, { useEffect } from "react";
import { storeUser, postAuthLoading, userExpired } from "../actions/authActions";
import { ILogger } from "../Logger/Logger";
import { TelemetryLogger } from "../Logger/TelemetryLogger";
import { fetchRbacResourceList } from "../actionCreators/rbacActionCreator";
import { LocalStorageConstants } from "../helper/Constants";
import { isAdminUser } from "../helper/HelperFunctions";
import userManager from "./userService";

export let logger: ILogger;

export default function AuthProvider({
  userManager: manager,
  store,
  children,
}: any) {
  useEffect(() => {
    const onUserLoaded = (user: any) => {
      console.log("user loaded:", user);
      store.dispatch(storeUser(user));
      localStorage.setItem(
        LocalStorageConstants.IsAdminKey,
        isAdminUser(user)
      );
      store.dispatch(fetchRbacResourceList());
      logger = TelemetryLogger.getInstance();
    };

    const onUserUnloaded = () => {
      //setAuthHeader(null)
      console.log("user unloaded");
    };

    const onAccessTokenExpiring = () => {
      console.log("user token expiring");
      userManager
        .signinSilent()
        .then((user) => {
          store.dispatch(postAuthLoading);
          store.dispatch(storeUser(user));
        })
        .catch(() => {
          store.dispatch(postAuthLoading);
          userManager
            .getUser()
            .then((user) => store.dispatch(storeUser(user)));
        });
    };

    const onAccessTokenExpired = () => {
      console.log("user token expired");
        store.dispatch(userExpired());
    };

    const onUserSignedOut = () => {
      console.log("user signed out");
    };

    // events for user

    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeAccessTokenExpiring(
        onAccessTokenExpiring
      );
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [manager, store]);

  return React.Children.only(children);
}
