import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchLastLoginEnable, updateLastLoginEnable } from "../../actionCreators/userSettingsActionCreators";
import { IProfileDropdownModalProps } from "../../core/viewModels/layout/HeaderModel";
import { appInsightsData } from "../../helper/AppInsightsData";
import { LoginHistoryConstants } from "../../helper/Constants";
import { logger } from "../../oidcClient/authProvider";
import { ApplicationState } from "../../store";
import CustomToggleSwitch from "../common/ui/switch/CustomToggleSwitch";
import LoginHistoryTable from "./parts/LoginHistroyTable";

const LoginHistory: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const [enablePrompt, setEnablePrompt] = useState(false);
    const dispatch = useDispatch();
    const { enableLastLogin } = useSelector((state: ApplicationState) => state.userSettingsState.defaultSettings);
    const handleCancel = () => {
        onHide();
    };
    const handleToggleChange = (enable: boolean) => {
        dispatch(updateLastLoginEnable(!enable));
        setEnablePrompt(!enable);
    };
    useEffect(() => {
        setEnablePrompt(enableLastLogin);
    }, [enableLastLogin]);
    useEffect(() => {
        if (show) {
            dispatch(fetchLastLoginEnable());
            logger.trackPageView(appInsightsData.LoginHistory.PageTitle);
        }
    }, [show]);
    return (
        <Modal show={show} onHide={handleCancel} dialogClassName="custom-bootstrap-modal login-history-modal">
            <Modal.Header closeButton closeVariant="white">
                {LoginHistoryConstants.TITLE}
            </Modal.Header>
            <Modal.Body className="padding-top-bottom-0">
                <LoginHistoryTable />
            </Modal.Body>
            <Modal.Footer className="space-between">
                <div className="prompt-control">
                    <CustomToggleSwitch
                        label={LoginHistoryConstants.PROMPT_TEXT} 
                        switched={enablePrompt} 
                        handleChange={() => handleToggleChange(enablePrompt)} 
                    />
                      <i
                        className="fas fa-exclamation-circle margin-left-5-px"
                        title={LoginHistoryConstants.PROMPT_INFO}
                    />
                </div>
                <button type="button" className="btn btn-light margin-right-10-px" onClick={handleCancel}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default LoginHistory;
