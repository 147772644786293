import React from "react";
import Checkbox, { CheckBoxSize } from "../../../common/ui/Checkbox";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { getFormattedSentDate } from "../../../../helper/DateHelperFunctions";
import { convertHtmlStringToText, isFolderOwner } from "../../../../helper/HelperFunctions";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { ShareFolderTypes } from "../../../../core/common/enums";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

interface IMessageListItemProps {
    message: IReceivedMessageModel;
    selectedMessageId: number;
    index: number;
    isChecked: boolean;
    onSelected: (message: IReceivedMessageModel, e: any) => void;
    onClick: (message: IReceivedMessageModel, e: any) => void;
    folder?: IFolderModel;
}

const MessageListItem: React.FC<IMessageListItemProps> = props => {
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const handleCheckboxOnClick = (e: any) => {
        e.stopPropagation();
    };

    const handleCheckboxOnChange = (e: any) => {
        e.stopPropagation();
        props.onSelected(props.message, e);
    };

    const enableEditPermission = (folder?: IFolderModel) => {
        if (!folder || isFolderOwner(folder, user) || folder.permissionGroupId == ShareFolderTypes.EDIT) {
            return true;
        } else if (folder.permissionGroupId == ShareFolderTypes.VIEW) {
            return false;
        }
    };

    return (
        <div
            className={`message-item ${
                props.selectedMessageId === props.message.receivedMessageId && "message-item-selected"
            }`}
            key={props.index}
            onClick={e =>
                props.selectedMessageId !== props.message.receivedMessageId && props.onClick(props.message, e)
            }
        >
            <div className="message-item-body" data-test-auto={AutomationIdConstants.reportProblem.MessageListItem}>
                <div className="message-item-checkbox">
                    <Checkbox
                        id={`chkMessage_${props.message.receivedMessageId}_${props.message.messageType}`}
                        text=""
                        size={CheckBoxSize.sm}
                        checked={props.isChecked}
                        onChange={handleCheckboxOnChange}
                        onClick={handleCheckboxOnClick}
                        disabled={!enableEditPermission(props.folder)}
                    />
                </div>
                <div className="message-item-group">
                    <div className="row message-item-group-row">
                        <div className="col-lg-7 col-xs-5 col-sm-3 col-md-5">
                            <span className="message-item-title text-ellipsis ellipsis">{`${props.message.senderFirstName} ${props.message.senderLastName}`}</span>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-7 col-lg-5 text-end message-item-date-div">
                            <span className="message-item-datetime">
                                {getFormattedSentDate(props.message.sentDate)}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div
                                className="message-item-subject ellipsis"
                                style={{ maxWidth: "250px", maxHeight: "20px" }}
                            >
                                {props.message.subject || <span>&nbsp;</span>}
                            </div>
                            <div
                                className="message-item-messagebody ellipsis"
                                style={{ maxWidth: "250px", maxHeight: "20px" }}
                            >
                                {convertHtmlStringToText(props.message.message)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageListItem;
