import { TabType } from "../../../../core/common/enums";
import { ITabModel } from "../../../../core/viewModels/settings/Settings";
import "./style.scss";
import { DropOffConfig } from "../tabs/DropOff/DropOffConfig";
import { DropOffUsers } from "../tabs/DropOff/DropOffUsers";
import { MessageHistory } from "../tabs/History/MessageHistory";
import RequestHistory from "../tabs/History/RequestHistory";
import MessageNotification from "../tabs/Notification/MessageNotification";
import RequestNotification from "../tabs/Notification/RequestNotification";
import { TemplateListing } from "../tabs/Templates.tsx/TemplateListing";
import DocumentCategory from "../tabs/Document Request/DocumentCategory";
import ContactSupport from "../tabs/General/ContactSupport";
import { AuthenticationQuestions } from "../tabs/General/AuthenticationQuestions";
import { MessageOptions } from "../tabs/General/MessageOptions";
import "../../adminSettings/style.scss";
import React from "react";

export interface ITabProps {
    tabs: ITabModel[];
    handleTabClick: (type: TabType) => void;
    activeTab: TabType | undefined;
}

const Tab = ({ tabs, handleTabClick, activeTab }: ITabProps) => {
    const isActiveTab = (tabData: ITabModel): boolean => {
        return tabData.isActive === true;
    };

    const GetTabContent = () => {
        switch (activeTab) {
            case TabType.Configuration:
                return <DropOffConfig />;
            case TabType.User:
                return <DropOffUsers />;
            case TabType.MessageHistory:
                return <MessageHistory />;
            case TabType.RequestHistory:
                return <RequestHistory />;
            case TabType.MessageNotification:
                return <MessageNotification />;
            case TabType.RequestNotification:
                return <RequestNotification />;
            case TabType.DocumentCategory:
                return <DocumentCategory />;
            case TabType.RequestTemplate:
                return <TemplateListing />;
            case TabType.SupportContact:
                return <ContactSupport />;
            case TabType.AuthenticationQuestions:
                return <AuthenticationQuestions />;
            case TabType.MessageOption:
                return <MessageOptions />;
        }
    };

    return (
        <div className="tab-container">
            <div className="tab-header-container">
                <div className="tab-header-wrapper">
                    {tabs.map((tab, index) => (
                        <div key={index} className={isActiveTab(tab) == true ? "tab-active" : ""}>
                            <div className="tab-wrapper">
                                <p
                                    className={isActiveTab(tab) == true ? "tab-selected" : "tab-default"}
                                    onClick={() => handleTabClick(tab.type)}
                                >
                                    {tab.title}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="tab-content">{GetTabContent()}</div>
        </div>
    );
};

export default Tab;
