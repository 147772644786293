import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { DropOffModalConstants, ValidationContants } from "../../../../../helper/Constants";
import { isValidEmailAddress } from "../../../../../helper/Validations";
import Checkbox, { CheckBoxSize } from "../../Checkbox";
import { CustomModal } from "../CustomModal";

interface IAddSenderModalProps {
    showAddSenderModal: boolean;
    clearModalState: () => void;
    handlAddSenderSubmission: (emailId: string, isBlackListed: boolean) => void;
}

export const AddSenderModal: React.FC<IAddSenderModalProps> = ({
    showAddSenderModal,
    clearModalState,
    handlAddSenderSubmission,
}) => {
    const [emailId, setEmailId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value);
        if (validationMessage) {
            setValidationMessage("");
        }
    };
    const isModalDataValid = () => {
        let isValid = true;

        if (emailId.trim().length < 1) {
            setValidationMessage(ValidationContants.EmailAdrressWarning);
            isValid = false;
        } else if (!isValidEmailAddress(emailId)) {
            setValidationMessage(ValidationContants.ValidEmailAddressWarning);
            isValid = false;
        }
        return isValid;
    };

    const handleOnSubmit = () => {
        if (isModalDataValid()) {
            handlAddSenderSubmission(emailId, isChecked);
        }
    };
    return (
        <CustomModal
            show={showAddSenderModal}
            onHide={() => {
                clearModalState();
            }}
            onSubmit={handleOnSubmit}
            cancelButtonName="Cancel"
            confirmButtonName="Ok"
            title={DropOffModalConstants.DROP_OFF_ADD_MODAL_TITLE}
            className="settings-modal add-sender-modal"
        >
            <Form className="settings-form" onSubmit={e => e.preventDefault()} validated={validationMessage !== ""}>
                <Form.Group className="marB15" controlId="AddSenderId">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        onChange={handleOnChange}
                        value={emailId}
                        className="add-sender-input"
                        type="email"
                        placeholder="Enter Email Address"
                    />
                    <h6 className="text-danger">{validationMessage}</h6>
                    <div className="toggle-container mark-blacklist-wrapper">
                        <span onClick={() => setIsChecked(!isChecked)}>
                            <Checkbox
                                id="mpiCheckbox"
                                size={CheckBoxSize.sm}
                                checked={isChecked}
                                onClick={e => e.stopPropagation()}
                                onChange={e => e.stopPropagation()}
                            />
                        </span>
                        <h6>{DropOffModalConstants.ADD_SENDER_HELP_TEXT}</h6>
                    </div>
                </Form.Group>
            </Form>
        </CustomModal>
    );
};
