import { AuthenticationMethod } from "../../../common/enums";

export interface IAuthenticationMethodSettings {
    authenticationMethods: AuthenticationMethod[];
    defaultAuthenticationMethod: AuthenticationMethod;
    allowEmployeesToChangeDefaultMethod: boolean;
    allowEmployeesToChangeAuthMethod: boolean;
}

export const authMethods = [
    AuthenticationMethod.QUESTIONANSWER,
    AuthenticationMethod.ACCESSCODE,
    // AuthenticationMethod.LOGINPASSWORD
];

export const initialCompanyAuthenticationSettings: IAuthenticationMethodSettings = {
    authenticationMethods: authMethods,
    defaultAuthenticationMethod: AuthenticationMethod.QUESTIONANSWER,
    allowEmployeesToChangeDefaultMethod: true,
    allowEmployeesToChangeAuthMethod: true,
};
