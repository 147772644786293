import axios, { AxiosResponse } from "axios";
import {
    ArchiveFolderReloadAction,
    CompletedAddToGroupAction,
    CompletedRemoveFromGroupAction,
    CompletedSignalRAction,
    CompletedWebSocketUrlAction,
    CurrentFolderIdChanged,
    CurrentPageChanged,
    InboxPageChangeAction,
    MoveToFolderPopupToggleAction,
    MyDownloadsPageChangeAction,
    ReceivedAddToGroupAction,
    ReceivedRemoveFromGroupAction,
    ReceivedSignalRAction,
    ReceivedWebSocketUrlAction,
    RefreshSharedFolderRecipientAction,
    RequestAddToGroupAction,
    RequestRemoeFromGroupAction,
    RequestSignalRAction,
    RequestWebSocketUrlAction,
    SentPageChangeAction,
    SpamPageChangeAction,
    UserAutoLogoutCauseAction,
} from "../actions/signalRActions";
import { actionTypes as signalRAction } from "../types/signalRTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { AppThunkAction } from "../store";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { getWebSocketGroup } from "../helper/HelperFunctions";
import { ISignalRConnectionInfo, LogoutCause } from "../core/viewModels/signalR/SignalRModel";
import { FolderType } from "../core/common/enums";

export type DispatchActions =
    | RequestSignalRAction
    | ReceivedSignalRAction
    | CompletedSignalRAction
    | RequestAddToGroupAction
    | ReceivedAddToGroupAction
    | CompletedAddToGroupAction
    | RequestRemoeFromGroupAction
    | ReceivedRemoveFromGroupAction
    | CompletedRemoveFromGroupAction
    | RequestWebSocketUrlAction
    | ReceivedWebSocketUrlAction
    | CompletedWebSocketUrlAction
    | InboxPageChangeAction
    | SentPageChangeAction
    | SpamPageChangeAction
    | MyDownloadsPageChangeAction
    | UserAutoLogoutCauseAction
    | CurrentPageChanged
    | CurrentFolderIdChanged
    | ArchiveFolderReloadAction
    | RefreshSharedFolderRecipientAction
    | MoveToFolderPopupToggleAction;

export type KnownActions = DispatchActions | NotificationAction;

export const initiateSignalRConnection =
    (callback?: (result: ISignalRConnectionInfo) => void): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        const user = getState().userAuth?.user;
        dispatch({
            type: signalRAction.REQUEST_SIGNAL_R_CONNECTION,
        });
        axios
            .post(`${getState().signalRState.signalRApiBaseUrl}/signalr/negotiate`, {
                headers: {
                    "x-ms-signalr-userid": `${user?.profile.user_id}_${user?.profile.company_id}`,
                    Authorization: `Bearer ${user?.access_token}`,
                    ClientType: "SSE-CPA-UI",
                },
            })
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: signalRAction.RECEIVED_SIGNAL_R_CONNECTION,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: signalRAction.COMPLETED_SIGNAL_R_CONNECTION,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const addToGroup =
    (callback?: (result: AxiosResponse) => void): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        const user = getState().userAuth?.user;
        dispatch({
            type: signalRAction.REQUEST_ADD_TO_GROUP,
        });
        axios
            .post(
                `${getState().signalRState.signalRApiBaseUrl}/signalr/AddToGroup`,
                {
                    recipient: `${user?.profile.company_id}_${user?.profile.user_id}`,
                    groupname: getWebSocketGroup(user?.profile.company_id),
                },
                {
                    headers: {
                        "x-ms-signalr-userid": `${user?.profile.user_id}_${user?.profile.company_id}`,
                        Authorization: `Bearer ${user?.access_token}`,
                        ClientType: "SSE-CPA-UI",
                    },
                }
            )
            .then((response: AxiosResponse) => {
                const data = response;
                dispatch({
                    type: signalRAction.RECEIVED_ADD_TO_GROUP,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: signalRAction.COMPLETED_ADD_TO_GROUP,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const getWebSocketConnectionUrl =
    (callback?: (result: string) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.REQUEST_WEB_SOCKET_URL,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/WebSocket/GetWebSocketConnectionUrl`)
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: signalRAction.RECEIVED_WEB_SOCKET_URL,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: signalRAction.COMPLETED_WEB_SOCKET_URL,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const inboxPageChanged =
    (currentPage: number): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.INBOX_PAGE_CHANGE,
            pageNumber: currentPage,
        });
    };

export const sentPageChanged =
    (currentPage: number): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.SENT_PAGE_CHANGE,
            pageNumber: currentPage,
        });
    };

export const spamPageChanged =
    (currentPage: number): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.SPAM_PAGE_CHANGE,
            pageNumber: currentPage,
        });
    };

export const myDownloadsPageChanged =
    (currentPage: number): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.MY_DOWNLOADS_PAGE_CHANGE,
            pageNumber: currentPage,
        });
    };

export const userPrivilegesChanged =
    (logoutCause: LogoutCause): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.USER_PRIVILEGES_CHANGE,
            logoutCause: logoutCause,
            showModal: true,
        });
    };

export const currentPageChanged =
    (currentPage: FolderType): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.CURRENT_PAGE_CHANGE,
            currentPage: currentPage,
        });
    };

export const currentFolderIdChanged =
    (currentFolderId: number): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.CURRENT_FOLDER_ID_CHANGE,
            folderId: currentFolderId,
        });
    };

export const archiveFolderReload = (): AppThunkAction<DispatchActions> => dispatch => {
    dispatch({
        type: signalRAction.ARCHIVE_FOLDER_RELOAD,
    });
};

export const refreshSharedFolderRecipient = (): AppThunkAction<DispatchActions> => dispatch => {
    dispatch({
        type: signalRAction.REFRESH_SHARED_FOLDER_RECIPIENTS,
    });
};

export const isMoveToFolderPopupOpen =
    (isOpen: boolean): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: signalRAction.MOVE_TO_FOLDER_POUP_TOGGLE,
            isMoveToFolderPopupOpen: isOpen,
        });
    };
