
export interface ICompanySettings {
  passwordPolicySetting: IPasswordPolicySettings;
  mfaSetting: ICompanyMfaSettings;
}

export interface IPasswordPolicySettings {
  passwordPolicy: PasswordPolicyType;
  passwordSettings: IPasswordSettings;
  modifiedDate: Date;
}

export interface IPasswordSettings {
  passwordOptions: PasswordOptions;
  length: number;
  passwordAge: number;
}

export interface ICompanyMfaSettings {
  isMfaEnabled: boolean;
  trustedDeviceExpiryDays: number;
  isAllowUsertoRememberTrustedDevice: boolean;
  skipMfaForIP: boolean;
  iSTPMfaEnabled: boolean;
}

export enum AuthenticationProvider {
  None,
  AzureAD,
  SAML,
  Google,
}

export enum PasswordPolicyType {
  NoPolicy = "NoPolicy",
  SSRDefault = "SSRDefault",
  FirmDefinedPolicy = "FirmDefinedPolicy",
}

export enum PasswordOptions {
  None = 0,
  UpperCase = 1,
  LowerCase = 2,
  Numbers = 4,
  SpecialCharacters = 8,
}

export const initialMfaSetting: ICompanyMfaSettings = {
    isMfaEnabled: false,
    trustedDeviceExpiryDays: 0,
    isAllowUsertoRememberTrustedDevice: false,
    skipMfaForIP: false,
    iSTPMfaEnabled: false
};

export const initialPasswordPolicySetting: IPasswordPolicySettings = {
    passwordPolicy: PasswordPolicyType.NoPolicy,
    modifiedDate: new Date(),
    passwordSettings: {
        passwordOptions: PasswordOptions.None,
        length: 8,
        passwordAge: 0,
    }
};
export const initialCompanySettings: ICompanySettings = {
    passwordPolicySetting: initialPasswordPolicySetting,
    mfaSetting: initialMfaSetting,
};

