import React from "react";
import { ArchiveFolderType } from "../../core/common/enums";

export interface IFolderIconsProps {
    height?: number;
    width?: number;
    className?: string;
    color?: string;
    disabled?: boolean;
}
interface IFolderTypeIconProps extends IFolderIconsProps {
    folderType?: ArchiveFolderType;
}

export const FolderTypeIcon: React.FC<IFolderTypeIconProps> = props => {
    let folderIcon;
    if (props.folderType) {
        switch (props.folderType) {
            case ArchiveFolderType.FOLDER:
                folderIcon = <FolderIcon {...props}></FolderIcon>;
                break;
            case ArchiveFolderType.MESSAGE:
                folderIcon = <FolderWithMessageIcon {...props}></FolderWithMessageIcon>;
                break;
            default:
                folderIcon = <FolderIcon {...props}></FolderIcon>;
                break;
        }
        return folderIcon;
    } else return <FolderIcon {...props}></FolderIcon>;
};

export const FolderCloseIcon: React.FC<IFolderIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={props.width || 24}
            height={props.height || 24}
            enableBackground="new 0 0 386.972 386.972"
            version="1.1"
            viewBox="0 0 386.972 386.972"
            xmlSpace="preserve"
        >
            <path d="M25.99 0v386.972l334.991-193.486L25.99 0zm30 51.972l245.009 141.514L55.99 335V51.972z"></path>
        </svg>
    );
};

export const FolderOpenIcon: React.FC<IFolderIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={props.width || 24}
            height={props.height || 24}
            enableBackground="new 0 0 96.155 96.155"
            version="1.1"
            viewBox="0 0 96.155 96.155"
            xmlSpace="preserve"
        >
            <path d="M20.972 95.594l57.605-45.951c.951-.76.951-2.367 0-3.127L20.968.56c-.689-.547-1.716-.709-2.61-.414a2.67 2.67 0 00-.436.186 2.002 2.002 0 00-1.056 1.764v91.967c0 .736.405 1.414 1.056 1.762.109.06.253.127.426.185.903.295 1.933.134 2.624-.416z"></path>
        </svg>
    );
};

export const FolderIcon: React.FC<IFolderIconsProps> = props => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 58 58"
            enableBackground="new 0 0 58 58"
            xmlSpace="preserve"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path
                fill="#EFCE4A"
                d="M46.324,52.5H1.565c-1.03,0-1.779-0.978-1.51-1.973l10.166-27.871
c0.184-0.682,0.803-1.156,1.51-1.156H56.49c1.03,0,1.51,0.984,1.51,1.973L47.834,51.344C47.65,52.026,47.031,52.5,46.324,52.5z"
            />
            <g>
                <path
                    fill="#EBBA16"
                    d="M50.268,12.5H25l-5-7H1.732C0.776,5.5,0,6.275,0,7.232V49.96c0.069,0.002,0.138,0.006,0.205,0.01
 l10.015-27.314c0.184-0.683,0.803-1.156,1.51-1.156H52v-7.268C52,13.275,51.224,12.5,50.268,12.5z"
                />
            </g>
        </svg>
    );
};
export const FolderWithMessageIcon: React.FC<IFolderIconsProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 58 58"
            enableBackground="new 0 0 58 58"
            xmlSpace="preserve"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path
                style={{
                    fill: "#efce4a",
                }}
                d="M46.324 52.5H1.565a1.564 1.564 0 0 1-1.51-1.973l10.166-27.871a1.564 1.564 0 0 1 1.51-1.156H56.49c1.03 0 1.51.984 1.51 1.973L47.834 51.344a1.564 1.564 0 0 1-1.51 1.156z"
            />
            <path
                style={{
                    fill: "#f4efdc",
                }}
                d="M10.221 22.656a1.563 1.563 0 0 1 1.51-1.156H53v-9H9l-6 6v23.953l7.221-19.797z"
            />
            <path
                style={{
                    fill: "#ebba16",
                }}
                d="m3 18.5 6-6h16l-5-7H1.732C.776 5.5 0 6.275 0 7.232V49.96c.069.002.138.006.205.01L3 42.349V18.5z"
            />
            <path
                style={{
                    fill: "#d1bf86",
                }}
                d="M28 17.5h2v4h-2zM35 17.5h2v4h-2zM42 17.5h2v4h-2zM14 17.5h2v4h-2zM21 17.5h2v4h-2zM9 18.5v-6l-6 6z"
            />
        </svg>
    );
};
