import React from "react";
import * as Bs from "react-bootstrap";
import { Button } from "react-bootstrap";
import { IDropOffRecipient } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffUsersConstants } from "../../../../../../../helper/Constants";
import "../Sender/style.scss";
import { ActionDropDownButtonIcon } from "../../../../../../svg/IconCollection";

interface IRecipientsInlineActions {
    selectedRecipient: IDropOffRecipient;
    handleEnableDropOffRecipients: (selectedRecipients: IDropOffRecipient[]) => void;
    handleDisableDropOffRecipients: (selectedRecipients: IDropOffRecipient[]) => void;
    handleSetAsDefaultRecipient: (selectedRecipients: IDropOffRecipient[]) => void;
    handleRemoveAsDefaultRecipient: (selectedRecipients: IDropOffRecipient[]) => void;
}

const RecipientsInlineActions: React.FC<IRecipientsInlineActions> = ({
    selectedRecipient,
    handleEnableDropOffRecipients,
    handleDisableDropOffRecipients,
    handleSetAsDefaultRecipient,
    handleRemoveAsDefaultRecipient
}) => {
    return (
        <div
            style={{
               visibility: `${selectedRecipient.isDefault ? "visible" : "collapse"}`,
            }}
            className={"inline-actions-bar-container recipient-action-bar"}
        >
            <button
                style={{
                    cursor: `${selectedRecipient.isDefault ? "default" : "pointer"}`,
                    pointerEvents:`${selectedRecipient.isDefault ? "none" : "auto"}`
                }}
                onClick={
                   selectedRecipient.isDropOffEnabled 
                   ? () => handleDisableDropOffRecipients([selectedRecipient]) 
                   : () => handleEnableDropOffRecipients([selectedRecipient])
                }
                className={
                    selectedRecipient.isDefault ? "button-green-default button-Layout-default" : "button-blue-enable-disable button-Layout-default"
                }>
                {selectedRecipient.isDefault
                    ? DropOffUsersConstants.DEFAULT_BUTTON_LABEL
                    : 
                    selectedRecipient.isDropOffEnabled
                    ? DropOffUsersConstants.DISABLE_DROP_OFF
                    : DropOffUsersConstants.ENABLE_DROP_OFF
                }
            </button>
            <Bs.ButtonToolbar className="btn-group-vertical">
            <Bs.DropdownButton 
                size="sm"
                title={<ActionDropDownButtonIcon />}
                id="recipients-inline-action-dropdown"
                align={"end"}
                >
                  {selectedRecipient.isDefault ?
                    <li
                       >
                        <Button  variant="none" type="button" className="action-dropdown-btn"  onClick={() => handleRemoveAsDefaultRecipient([selectedRecipient])} >
                            <span className='filter-dropdown-text'>{DropOffUsersConstants.REMOVE_DEFAULT_BUTTON_LABEL}</span>
                        </Button>
                    </li> 
                    :  
                    <li
                    >
                        <Button  variant="none" type="button" className="action-dropdown-btn" onClick={() => handleSetAsDefaultRecipient([selectedRecipient])} >
                            <span className='filter-dropdown-text'>{DropOffUsersConstants.SET_DEFAULT_BUTTON_LABEL}</span>
                        </Button>
                    </li>
                }
                </Bs.DropdownButton>
            </Bs.ButtonToolbar>
        </div>
    );
};

export default RecipientsInlineActions;
