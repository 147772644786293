export enum actionTypes {
    REQUEST_SIGNAL_R_CONNECTION = "request_signal_r_connection",
    RECEIVED_SIGNAL_R_CONNECTION = "received_signal_r_connection",
    COMPLETED_SIGNAL_R_CONNECTION = "completed_signal_r_connection",

    REQUEST_ADD_TO_GROUP = "request_add_to_group",
    RECEIVED_ADD_TO_GROUP = "received_add_to_group",
    COMPLETED_ADD_TO_GROUP = "completed_add_to_group",

    REQUEST_REMOVE_FROM_GROUP = "request_remove_from_group",
    RECEIVED_REMOVE_FROM_GROUP = "received_remove_from_group",
    COMPLETED_REMOVE_FROM_GROUP = "completed_remove_from_group",

    REQUEST_WEB_SOCKET_URL = "request_web_socket_url",
    RECEIVED_WEB_SOCKET_URL = "received_web_socket_url",
    COMPLETED_WEB_SOCKET_URL = "completed_web_socket_url",

    INBOX_PAGE_CHANGE = "inbox_page_change",
    SENT_PAGE_CHANGE = "sent_page_change",
    SPAM_PAGE_CHANGE = "spam_page_change",
    MY_DOWNLOADS_PAGE_CHANGE = "my_downloads_page_change",

    USER_PRIVILEGES_CHANGE = "user_privileges_change",
    CURRENT_PAGE_CHANGE = "current_page_change",

    CURRENT_FOLDER_ID_CHANGE = "current_folder_id_change",
    ARCHIVE_FOLDER_RELOAD = "archive_folder_reload",
    REFRESH_SHARED_FOLDER_RECIPIENTS = "refresh_shared_folder_recipients",

    MOVE_TO_FOLDER_POUP_TOGGLE = "move_to_folder_poup_toggle",

}
