import produce from "immer";
import { Reducer, Action } from "redux";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { DispatchActions } from "../actionCreators/draftMessageActionCreator";
import { actionTypes } from "../types/draftMessageTypes";
import { IDraftMailMessage, IDraftMessageModel, initialDraftMessage } from "../core/viewModels/message/DraftMailMessage";

export interface IDraftMessageState {
  data: IMessageResponseModel<IDraftMessageModel>;
  isLoading: boolean;
  isSending: boolean;
  mailData: {
    data: IDraftMailMessage;
    isLoading: boolean;
  };
}

export const initialState: IDraftMessageState = {
  isLoading: false,
  isSending: false,
  data: {
    messages: [],
    totalRecords: 0,
  },
  mailData: {
    data: {
      messageDetail: initialDraftMessage,
      attachments: [],
      recipients: [],
      recipientSecurityQuestions: []
    },
    isLoading: false,
  },
};

export const reducer: Reducer<IDraftMessageState> = produce(
  (draft: IDraftMessageState = initialState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_DRAFT_MESSAGES:
        draft.isLoading = true;
        return draft;
      case actionTypes.RECEIVED_DRAFT_MESSAGES:
        draft.data = action.payload;
        return draft;
      case actionTypes.COMPLETED_DRAFT_MESSAGES:
        draft.isLoading = false;
        return draft;
      case actionTypes.REQUEST_DRAFT_MESSAGE_DETAIL:
        draft.mailData.isLoading = true;
        return draft;
      case actionTypes.RECEIVED_DRAFT_MESSAGE_DETAIL:
        draft.mailData.data = action.payload;
        return draft;
      case actionTypes.COMPLETED_DRAFT_MESSAGE_DETAIL:
        draft.mailData.isLoading = false;
        return draft;
      case actionTypes.REQUEST_UPDATE_DRAFT_MESSAGE:
        draft.isSending = true;
        return draft;
      case actionTypes.COMPLETED_UPDATE_DRAFT_MESSAGE:
        draft.isSending = false;
        return draft;
      case actionTypes.REQUEST_DELETE_DRAFT_MESSAGE:
        draft.isLoading = true;
        return draft;
      case actionTypes.COMPLETED_DELETE_DRAFT_MESSAGE:
        draft.isLoading = false;
        return draft;
      case actionTypes.DRAFT_MESSAGE_DELETED:
        draft.isLoading = false;
        draft.data.messages = draft.data.messages.filter(x => !action.messageIds.includes(x.draftedMessageId));
        return draft;   
      default:
        return draft;
    }
  }
);
