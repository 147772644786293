import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchMailboxUserList } from "../../actionCreators/commonActionCreators";
import { IDelegateeUser } from "../../core/viewModels/user/UserViewModel";
import { LocalStorageConstants, userSettingsConstant, ViewInboxConstants } from "../../helper/Constants";
import { ApplicationState } from "../../store";
import SearchInput from "../common/ui/SearchInput";
import { ViewInboxIcon, ViewInboxIconFilled } from "../svg/HeaderMenuIcons";
import { Loader } from "react-overlay-loader";
import { appInsightsData } from "../../helper/AppInsightsData";
import { logger } from "../../oidcClient/authProvider";
import { ResourceIdLocators } from "../../helper/ResourceIdLocators";
const ViewInboxDropDown: React.FunctionComponent = () => {
    const [list, setList] = useState<IDelegateeUser[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const { MailboxUserList, isLoading } = useSelector((state: ApplicationState) => state.commonState);
    const getName = (contact: IDelegateeUser): string => `${contact?.firstName} ${contact?.lastName || ""}`;

    const onFilter = (e: any) => {
        const value = e.target.value;
        setSearchText(value);
        const filteredList = MailboxUserList.filter(data =>
            `${getName(data).toLocaleLowerCase()}`.includes(value.toLocaleLowerCase())
        );
        setList(filteredList);
    };
    useEffect(() => {
        setList(MailboxUserList);
    }, [MailboxUserList]);

    const openInNewTabHandler = (data: any) => {
        logger.trackEvent({
            name: `${appInsightsData.ViewInbox.PageTitle} - ${appInsightsData.ViewInbox.Operation.UserClick}`,
            properties: {
                page: appInsightsData.ViewInbox.PageTitle,
                selectedUserEmail: data.email,
                selectedUserFirstName: data.firstName,
                selectedUserLastName: data.lastName,
            },
        });

        var newWindow = window.open("/", "_blank"); //eslint-disable-line
        newWindow?.sessionStorage.setItem(LocalStorageConstants.otherUser, JSON.stringify(data));
    };
    const popperConfigObj = {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [120, 15],
                },
            },
        ],
    };
    const onClickToggleHandler = () => {
        dispatch(fetchMailboxUserList());
        setIsOpen(!isOpen);
    };
    return (
        <Dropdown show={isOpen} onToggle={onClickToggleHandler} className="viewInbox-dropdown-wrapper">
            <Dropdown.Toggle
                as={Button}
                data-resource-id={ResourceIdLocators.Global.ViewOtrInboxBtn}
                className="viewInbox-dropdown-title app-header-menu-item"
                title={ViewInboxConstants.VIEW_INBOX_DROPDOWN_TITLE}
            >
                {isOpen ? <ViewInboxIconFilled /> : <ViewInboxIcon />}
            </Dropdown.Toggle>

            <Dropdown.Menu popperConfig={popperConfigObj}>
                <div className="padding-top-bottom-2 padding-left-right-3 mb-2 dropdown-search">
                    <SearchInput placeholder="Search" value={searchText} onChange={onFilter} />
                </div>
                <div className="padding-bottom-2">
                    {list?.length ? (
                        list.map((data, index) => (
                            <Dropdown.Item
                                title={getName(data)}
                                eventKey={index}
                                onClick={() => openInNewTabHandler(data)}
                                key={index}
                                className="viewInbox-dropdown-item"
                                data-email={data.email}
                            >
                                {getName(data)}
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item>{userSettingsConstant.NO_DELEGATEE_TEXT}</Dropdown.Item>
                    )}
                </div>
                <Loader loading={isLoading} />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ViewInboxDropDown;
