import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, SelectRowProps, TableChangeHandler } from "react-bootstrap-table-next";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { DeleteIcon } from "../../../../svg/ToolbarIcons";
import { ColorConstants } from "../../../../../assets/custom/colors";
import { getFormattedLocalDateTime, getFormattedSentDate } from "../../../../../helper/DateHelperFunctions";
import { DateFormatConstants, DEFAULT_PAGE_SIZE, TemplateListingConstants } from "../../../../../helper/Constants";
import { TemplateInlineActions } from "./TemplateInlineActions";
import {
    IDocumentRequestTemplate,
    initialTemplateTableRequestObject,
    ITemplateTableDataRequestModel,
} from "../../../../../core/viewModels/template/TemplateModel";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SortOrder, TemplateColumnName } from "../../../../../core/common/enums";
import { convertHtmlStringToText, getTemplateModelColumns } from "../../../../../helper/HelperFunctions";
interface ITemplateListingTable {
    isContentLoading: boolean;
    data: IDocumentRequestTemplate[];
    selectedTemplate: IDocumentRequestTemplate[];
    currentPageNumber: number;
    count: number;
    updateSelectedTemplate: (selectedTemplate: IDocumentRequestTemplate[]) => void;
    handleOnAddTemplate: () => void;
    handleOnEditTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
    handleOnDuplicateTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
    handleOnDelete: (selectedTemplate: IDocumentRequestTemplate[]) => void;
    handleTableSorting: (sortColumn: TemplateColumnName, sortOrder: SortOrder, pageNumber:number) => void;
    setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
    fetchTemplateData: (payload: ITemplateTableDataRequestModel) => void;
    sortOrder: SortOrder;
    sortColumn: TemplateColumnName;
    setSortOrder: (sortOrder: SortOrder) => void;
    setSortColumn: (sortColumn: TemplateColumnName) => void;
    
}
export const TemplateListingTable: React.FC<ITemplateListingTable> = ({
    isContentLoading,
    data,
    selectedTemplate,
    updateSelectedTemplate,
    handleOnAddTemplate,
    handleOnEditTemplate,
    handleOnDuplicateTemplate,
    handleOnDelete,
    handleTableSorting,
    setCurrentPageNumber,
    fetchTemplateData,
    currentPageNumber,
    count,
    sortOrder,
    sortColumn,
    setSortOrder,
    setSortColumn
}) => {
    const [tableData, setTableData] = useState(data);

    const isAnySelected = selectedTemplate.length > 0;
    useEffect(() => {
        if (currentPageNumber) {
            getTableData();
        }
    }, [currentPageNumber]);
    useEffect(() => {
        setTableData(data);
    }, [data]);
    const getTableData = () => {
        const payload = {
            ...initialTemplateTableRequestObject,
            pageNumber: currentPageNumber,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
        };
        fetchTemplateData({
            ...payload,
        });
    };
    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action">
                {
                    <span
                        onClick={() => handleOnDelete(selectedTemplate)}
                        title={"Delete"}
                        className={isAnySelected ? "pointer" : "isDisabled"}
                    >
                        <DeleteIcon width={18} height={18} color={ColorConstants.grey} disabled={!isAnySelected} />
                    </span>
                }

                <button onClick={handleOnAddTemplate} className="add-question-btn">
                    Add Template
                </button>
            </div>
        );
    };

    const InlineActionsBar = (row: IDocumentRequestTemplate): JSX.Element => {
        return (
            <TemplateInlineActions
                handleOnDelete={handleOnDelete}
                selectedTemplate={row}
                handleOnDuplicateTemplate={handleOnDuplicateTemplate}
                handleOnEditTemplate={handleOnEditTemplate}
            />
        );
    };
    const nameFormatter = (cell: string): JSX.Element => {
        return (
            <span title={cell} className={"table-name-cell overflowText"}>
                {cell}
            </span>
        );
    };
    const descriptionFormatter = (cell: string): JSX.Element => {
        return (
            <span className={"table-name-cell overflowText"}>
                {convertHtmlStringToText(cell)}
            </span>
        );
    };
    const createdOnFormatter = (cell: Date): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span>{getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}</span>
            </div>
        );
    };
    const modifiedOnFormatter = (cell: Date, row: IDocumentRequestTemplate): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span>{getFormattedSentDate(cell)}</span>
                {InlineActionsBar(row)}
            </div>
        );
    };
    const columns: ColumnDescription[] = [
        {
            text: "Template Name",
            dataField: "name",
            sort: true,
            style: { width: "20%" },
            formatter: nameFormatter,
        },
        {
            text: "Description",
            dataField: "description",
            sort: true,
            style: { width: "15%" },
            formatter: descriptionFormatter,
        },
        {
            text: "Created On",
            dataField: "createdDate",
            sort: true,
            style: { width: "15%" },
            formatter: createdOnFormatter,
        },
        {
            text: "Modified On",
            dataField: "modifiedDate",
            sort: true,
            formatter: modifiedOnFormatter,
        },
        {
            text: "",
            dataField: "toolbar",
            headerFormatter: HeaderActionsBar,
        },
    ];
    const customTotal = (from: number, to: number) =>
        count > 20 ? (
            <span className="total-count">
                Showing <b>{from}</b> to <b>{to}</b> of <b>{count}</b> entries
            </span>
        ) : (
            <></>
        );

    const options = {
        totalSize: count ?? 0,
        sizePerPage: DEFAULT_PAGE_SIZE,
        page: currentPageNumber,
        showTotal: true,
        withFirstAndLast: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
        paginationTotalRenderer: customTotal,
    };
    const handleOnSelect = (row: IDocumentRequestTemplate, isSelect: boolean) => {
        let selectedTemplateCopy = [...selectedTemplate];

        if (isSelect) {
            selectedTemplateCopy.push(row);
        } else {
            selectedTemplateCopy = selectedTemplateCopy.filter(
                template => template.documentRequestTemplateId != row.documentRequestTemplateId
            );
        }

        updateSelectedTemplate(selectedTemplateCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IDocumentRequestTemplate[]) => {
        if (isSelect) {
            updateSelectedTemplate(rows);
        } else {
            updateSelectedTemplate([]);
        }
    };
    const selectRow: SelectRowProps<IDocumentRequestTemplate> = {
        mode: "checkbox",
        clickToSelect: true,
        selected: selectedTemplate.map((template: IDocumentRequestTemplate) => template.documentRequestTemplateId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedTemplate.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text=""
            />
        ),
    };
    const setNoContent = () => {
        if (isContentLoading) {
            return (
                <LoadingOverlay style={{ height: 400 }}>
                    <Loader loading={isContentLoading} />
                </LoadingOverlay>
            );
        } else {
            return TemplateListingConstants.NO_TABLE_DATA;
        }
    };
    const handleTableChange: TableChangeHandler<any> | undefined = (type, { sortField, sortOrder, page }) => {
        // Remote sorting will be handled here
        if (type === "sort") {
            const order = sortOrder === "asc" ? SortOrder.ASC : SortOrder.DESC;
            const column = getTemplateModelColumns(sortField);
            handleTableSorting(column, order, page);
            setSortColumn(column);
            setSortOrder(order);
        }
        setCurrentPageNumber(page);
    };
    return (
        <BootstrapTable
            classes="settings-table"
            keyField="documentRequestTemplateId"
            columns={columns}
            data={tableData}
            remote
            selectRow={selectRow}
            noDataIndication={setNoContent()}
            bordered={false}
            pagination={paginationFactory(options)}
            onTableChange={handleTableChange}
        />
    );
};

export default TemplateListingTable;
