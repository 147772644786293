export enum actionTypes {

    REQUEST_PRODUCT_ENABLED = "request_product_enabled",
    RECEIVE_PRODUCT_ENABLED = "receive_product_enabled",
    COMPLETE_PRODUCT_ENABLED = "complete_produtc_enabled",

    REQUEST_PRODUCT_SUBSCRIBED = "request_product_subscribed",
    RECEIVE_PRODUCT_SUBSCRIBED = "receive_product_subscribed",
    COMPLETE_PRODUCT_SUBSCRIBED = "complete_produtc_subscribed",

    SET_EXCHANGE_ENABLE_STATUS = "set_exchange_enable_status",

    SET_PAGE_LEVEL_ACCESS = "set_page_level_access"
}