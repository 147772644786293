import * as React from "react";

interface IPagingInfoProps {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
}

const PagingInfo: React.FC<IPagingInfoProps> = (props) => {
  const getPageStart = (pageSize: number, pageNo: number) => {
    return pageSize * pageNo;
  };

  const getPageLabel = (total: number, pageSize: number, pageNo: number) => {
    const start = Math.max(getPageStart(pageSize, pageNo - 1), 0);
    const end = Math.min(getPageStart(pageSize, pageNo), total);

    return `${start + 1}-${end}`;
  };

  return (
    <span className="paging-info">
      {getPageLabel(props.totalRecords, props.pageSize, props.currentPage)}
      &nbsp; of {props.totalRecords}
    </span>
  );
};

export default PagingInfo;
