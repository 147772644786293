import { Action, Reducer } from "redux";

import {
  IUserProfile,
} from "../core/viewModels/profile/ProfileViewModel";
import { actionTypes } from "../types/userProfileActionTypes";
import * as profileActions from "../actions/userProfileActions";
import { IMyAccountUserProfile, initialUserFormState } from "../components/navigation/parts/Profile";

export interface IUserProfileData {
  user: IUserProfile;
  isLoading: boolean;
}

export interface UserProfileState {
  user: IUserProfile;
}

const unloadedState: IMyAccountUserProfile = initialUserFormState;

export const reducer: Reducer<IMyAccountUserProfile> = (
  state: IMyAccountUserProfile = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as profileActions.DispatchActions;
  switch (action.type) {
    case actionTypes.RECEIVE_USER_PROFILE:
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      if (action.user.userId === state.userId || state.firstName === "") {
        return {
          ...action.user,
          fullName() {
            return action.user.firstName + " " + action.user.lastName;
          },
        } as IMyAccountUserProfile;
      } else {
        return {
          ...state,
          fullName() {
            return this.firstName + " " + this.lastName;
          },
        } as IMyAccountUserProfile;
      }
    case actionTypes.UPDATE_USER_PROFILE:
      let received: IMyAccountUserProfile = { ...state };
      received = action.userDetails;
      return received;
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      // const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};
