import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { fetchUsersList } from "../../../actionCreators/userSettingsActionCreators";
import { ApplicationState } from "../../../store";
import CustomToggleSwitch from "../../common/ui/switch/CustomToggleSwitch";
import DelegationList from "./DelegationList";
import { userSettingsConstant } from "../../../helper/Constants";
import { actionTypes } from "../../../types/userSettingsTypes";

const MailboxDelegation: FC = () => {
    const dispatch = useDispatch();
    const { isLoading, userSettings } = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const defaultEnable = !!userSettings?.mailboxDelegationSettings?.length;
    const [enableDelegation, setEnableDelegation] = useState<boolean>(defaultEnable);

    useEffect(() => {
        dispatch(fetchUsersList());
    }, []);

    useEffect(() => {
        defaultEnable && setEnableDelegation(defaultEnable);
    }, [defaultEnable]);

    const handleOnChange = (e: Event) => {
        setEnableDelegation((e.target as HTMLInputElement).checked);
        !(e.target as HTMLInputElement).checked &&
            dispatch({
                type: actionTypes.UPDATE_USER_SETTINGS,
                payload: { ...userSettings, mailboxDelegationSettings: [] },
            });
    };

    return (
        <>
            <LoadingOverlay />
            <header>
                <h3>{userSettingsConstant.MAILBOX_DELEGATION_TITLE}</h3>
                <span>{userSettingsConstant.DESCRIPTION}</span>
            </header>
            <section className="top-grey-border">
                <div className="settings-label-toggle-wrapper">
                    <CustomToggleSwitch 
                        label={userSettingsConstant.ENABLE_MAILBOX_DELEGATION}
                        handleChange={(e: Event) => handleOnChange(e)} 
                        switched={enableDelegation} 
                    />
                </div>
                {enableDelegation && (
                    <>
                        <div className="heading-black-md">{userSettingsConstant.DELEGATEE_LIST_TEXT}</div>
                        <DelegationList />
                    </>
                )}
            </section>

            <Loader loading={isLoading} />
        </>
    );
};

export default MailboxDelegation;
