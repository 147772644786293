import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { convertHtmlStringToText } from "../../../../helper/HelperFunctions";

interface TinyMCEComponentProps {
    messageBody: string;
    changeStateTinymceBody: (event: any) => void;
    readOnly?: number;
    onDropFiles?: (e: any) => void;
    onDragOver?: () => void;
    height?: number;
}

const TinyMCEComponent: React.FC<TinyMCEComponentProps> = props => {
    const editorRef: any = useRef(null);
    const [messageBody] = useState(props.messageBody);
    const [showTinyMCE, setShowTinyMCE] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const editor = editorRef?.current;
        if (editor?.readonly) {
            setShowTinyMCE(false);
        }
    });

    if (!showTinyMCE) {
        return (
            <textarea
                className="tinymce-textarea"
                value={convertHtmlStringToText(props.messageBody)}
                onChange={e => props.changeStateTinymceBody(e.target.value)}
            />
        );
    }

    return (
        <LoadingOverlay style={{ height: "100%" }}>
            <div>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setIsLoading(false);
                    }}
                    initialValue={messageBody}
                    value={props.messageBody}
                    onEditorChange={props.changeStateTinymceBody}
                    init={{
                        height: props.height || 245,
                        menubar: false,
                        branding: false,
                        statusbar: false,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "preview",
                            "help",
                            "wordcount",
                        ],
                        toolbar:
                            "bold italic textcolor backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        setup: function (editor) {
                            editor.on("dragover", function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                props.onDragOver && props.onDragOver();
                            });
                        },
                    }}
                />
            </div>
            <Loader classNamePrefix="sm" text="" loading={isLoading} />
        </LoadingOverlay>
    );
};

export default TinyMCEComponent;
