import React from "react";
import { ExchangeIcon } from "../../svg/LogoCollection";
import "./MessagePrint.scss";
import { convertTextToHtml, formatBytes } from "../../../helper/HelperFunctions";
import { IFileModel } from "../../../core/viewModels/file/FileModel";
import { PaperClipIcon } from "../../svg/IconCollection";
import { getExpiryDateFromDays, getFormattedSentDateForDetailedView } from "../../../helper/DateHelperFunctions";
import { DateFormatConstants } from "../../../helper/Constants";
import { IReceivedMailMessage } from "../../../core/viewModels/message/ReceivedMailMessageModel";
import { ISentMailMessage } from "../../../core/viewModels/message/SentMailMessageModel";

interface IMessagePrintProps {
  message?: IReceivedMailMessage | ISentMailMessage | any;
  messageTypeText?: string;
}

/* eslint-disable react/display-name */
export const MessagePrint = React.forwardRef((props: IMessagePrintProps, ref: any) => {
  const { message } = props;

  return (
    <div className="print-message-container" ref={ref}>
      <header>
        <ExchangeIcon />
      </header>
      <hr />
      <div className="print-message-info">
        <p>{props?.messageTypeText}</p>

        <h4>{message?.messageDetail?.subject ? message?.messageDetail?.subject : "(no subject)"}</h4>
        <h5>{message.messageDetail?.senderFirstName?.trim()} {message.messageDetail?.senderLastName?.trim()} {"<" + message.messageDetail?.senderEmail?.trim() + ">"}</h5>

        <p>{getFormattedSentDateForDetailedView(message?.messageDetail?.sentDate)}</p>
        <p>Expires On: {getExpiryDateFromDays(
          message?.messageDetail?.sentDate,
          message?.messageDetail?.expiresDays,
          DateFormatConstants.DOCUMENT_EXPIRY_FORMAT
        )} </p>
        <span className="to-recipients">
          <span>To: </span>
          <span>
            {message?.recipients.map((item: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  {`${item.firstName ?? ""} ${item.lastName ?? ""}`.trim()}
                  {(item.firstName || item.lastName) && ` <${item.email}>`}
                  {!(item.firstName || item.lastName) && ` ${item.email}`}
                  {i !== message?.recipients.length - 1 && <span>, </span>}
                </React.Fragment>
              );
            })}
          </span>
        </span>
      </div>
      {message?.attachments?.length > 0 ? <div className="attachment-list-container">
        <span className="attachment-list-title">
          <PaperClipIcon />
          <span style={{ marginLeft: "7px" }}> {message?.attachments?.length ?? 0} attachments</span>

        </span>
        <div>
          <ul>
            {
              message?.attachments && message?.attachments.map((attachment: IFileModel, index: number) => {
                return (
                  <li key={index} className="attachment">
                    <span>{attachment.name}</span>
                    <span>{"(" + formatBytes(attachment.size) + ")"} <br /> </span>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div> : null}

      {message?.messageDetail?.message?.length > 0 ? <div className="message-container">
        <div
          dangerouslySetInnerHTML={{
            __html: convertTextToHtml(message?.messageDetail?.message ?? ""),
          }}
        ></div>
      </div> : null}

    </div>
  );
});
