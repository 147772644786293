import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "react-dropdown";
import { Col, Row } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import {
    initialDocumentPagination,
    DocumentRequestConstants,
    viewingOthersInboxLabel,
} from "../../../helper/Constants";
import { DocumentRequestList } from "./DocumentRequestList";
import { DocumentRequestDetailed } from "./DocumentRequestDetailed";
import { getRetentionOptions, isDelegatedUser } from "../../../helper/HelperFunctions";
import { fetchRetentionPeriods } from "../../../actionCreators/folderActionCreator";
import {
    IDocumentRequestListModel,
    IDocumentRequestListResponseModel,
    IDocumentRequestModel,
    IDocumentRequestSearchModel,
    initialDocumentListState,
    initialDocumentSearchState,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { FileEarMarkArrowPlus } from "../../svg/IconCollection";
import {
    fetchDocumentRequestList,
    getDocumentRequestDetail,
    resendDocumentAccesLink,
    resendDocumentAccesLinkDetailed,
    sendDocumentReminder,
} from "../../../actionCreators/documentRequestActionCreators";
import { ApplicationState } from "../../../store";
import ComposeDocumentRequestModal from "./ComposeDocumentRequestModal";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";
import "./style.scss";
import { getDocumentCategoriesDropdown} from "../../../actionCreators/documentCategoryActionCreator";

export const DocumentRequest = () => {
    const [showComposeDocumentRequestModal, setShowComposeDocumentRequestModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState(initialDocumentPagination);
    const [selectedDocumentList, setSelectedDocumentList] = useState<IDocumentRequestListModel[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<IDocumentRequestListModel>(initialDocumentListState);
    const [showDocumentDetail, toggleShowDocumentDetail] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const [resetSortAndFilterOptions, callResetSortAndFilter] = useState(false);

    const documentState = useSelector((state: ApplicationState) => state.documentState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);
    const dispatch = useDispatch();

    const fetchDocumentRequests = (documentRequestSearchModel: IDocumentRequestSearchModel) => {
        dispatch(fetchDocumentRequestList(documentRequestSearchModel, onFetchDocumentRequestComplete));
    };

    useEffect(() => {
        dispatch(fetchRetentionPeriods());
        dispatch(getRestrictedFileTypes());
        dispatch(getDocumentCategoriesDropdown());
    }, []);

    useEffect(() => {
        setRetentionPeriods(getRetentionOptions(retentionData.retentionPeriods));
    }, [retentionData]);


    const onFetchDocumentRequestComplete = (result: IDocumentRequestListResponseModel) => {
        if (result && result.documentRequests && result.documentRequests.length > 0) {
            setSelectedDocument(result.documentRequests[0]);
            dispatch(
                getDocumentRequestDetail(result.documentRequests[0].documentRequestId, documentRequestDetailCallback)
            );
        } else toggleShowDocumentDetail(false);
    };

    const documentRequestDetailCallback = (result: IDocumentRequestModel) => {
        if (result) toggleShowDocumentDetail(true);
        else toggleShowDocumentDetail(false);
    };

    const handleGetDocumentDetail = (documentRequestId: number) => {
        dispatch(getDocumentRequestDetail(documentRequestId, documentRequestDetailCallback));
    };

    const resetSelection = (drlRefresh?: boolean) => {
        setSelectedDocumentList([]);
        const payload =
            drlRefresh === true
                ? {
                      ...initialDocumentSearchState,
                      pageNumber: initialDocumentPagination.currentPage,
                      pageSize: pagination.pageSize,
                  }
                : {
                      ...initialDocumentSearchState,
                      pageNumber: pagination.currentPage,
                      pageSize: pagination.pageSize,
                  };

        setTimeout(() => {
            fetchDocumentRequests(payload);
            drlRefresh && setPagination(initialDocumentPagination);
        }, 1000);
        toggleShowDocumentDetail(false);
        setSelectedDocument(initialDocumentListState);
        setShowComposeDocumentRequestModal(false);
        callResetSortAndFilter(prevState => !prevState);
    };

    const detailedActionSuccessCallback = (documentId: number) => {
        handleGetDocumentDetail(documentId);
    };

    const handleDocumentResendAccessLink = (documentRequestIds: number[], isListingAction?: boolean) => {
        if (isListingAction) {
            dispatch(resendDocumentAccesLink(documentRequestIds, ResourceIdLocators.DRL.DRLListBtnResendAccess));
        } else {
            dispatch(resendDocumentAccesLinkDetailed(documentRequestIds, ResourceIdLocators.DRL.DRLDtlBtnResendAccess));
        }
    };
    
    const handleDocumentSendReminder = (documentRequestIds: number[], isListingAction = false, resourceId: string) => {
        dispatch(sendDocumentReminder(documentRequestIds, isListingAction, resourceId));
    };

    return (
        <div className="row height-100-percent width-100-percent margin-left-0">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <Row className="padding-0-px margin-0-px">
                <div className="request-document-header-wrapper">
                    <header>
                        <h3>{DocumentRequestConstants.REQUEST_DOCUMENT_TITLE_TEXT}</h3>
                        <span>{DocumentRequestConstants.REQUEST_DOCUMENT_HELPER_TEXT}</span>
                    </header>
                    {!isDelegatedUser() && (
                        <button
                            className="new-request-button"
                            onClick={() => setShowComposeDocumentRequestModal(true)}
                            data-resource-id={ResourceIdLocators.DRL.DRLBtnNewRequest}
                        >
                            <FileEarMarkArrowPlus color="white" />
                            New Request
                        </button>
                    )}
                </div>
            </Row>
            <Row className="padding-right-0-px">
                <Col sm={4} className="no-padding-left">
                    <DocumentRequestList
                        documents={documentState.documentRequestList.documentRequests}
                        totalDocuments={documentState.documentRequestList.totalRecords}
                        pagination={pagination}
                        selectedDocument={selectedDocument}
                        setSelectedDocument={setSelectedDocument}
                        selectedDocumentList={selectedDocumentList}
                        setPagination={setPagination}
                        getDocumentDetail={handleGetDocumentDetail}
                        handleDocumentResendAccessLink={handleDocumentResendAccessLink}
                        handleDocumentSendReminder={handleDocumentSendReminder}
                        fetchDocumentRequests={fetchDocumentRequests}
                        updateSelectedDocumentList={setSelectedDocumentList}
                        resetSelection={resetSelection}
                        resetSortAndFilterOptions={resetSortAndFilterOptions}
                    />
                </Col>
                <Col className="padding-right-0-px document-request-detailed" sm={8}>
                    <LoadingOverlay style={{ height: "100%" }}>
                        {showDocumentDetail ? (
                            <DocumentRequestDetailed
                                document={documentState.documentRequestDetailed.data}
                                retentionOptions={retentionPeriods}
                                handleDocumentResendAccessLink={handleDocumentResendAccessLink}
                                handleDocumentSendReminder={handleDocumentSendReminder}
                                resetSelection={resetSelection}
                                detailedActionSuccessCallback={detailedActionSuccessCallback}
                                handleGetDocumentDetail={handleGetDocumentDetail}
                            />
                        ) : (
                            documentState.documentRequestDetailed.isLoading || (
                                <div className="no-message-selected">
                                    {DocumentRequestConstants.SELECT_DOCUMENT_REQUESTS}
                                </div>
                            )
                        )}

                        <Loader loading={documentState.documentRequestDetailed.isLoading} />
                    </LoadingOverlay>
                </Col>
            </Row>
            {showComposeDocumentRequestModal && (
                <ComposeDocumentRequestModal
                    show={showComposeDocumentRequestModal}
                    onHide={() => setShowComposeDocumentRequestModal(false)}
                    resetSelection={resetSelection}
                />
            )}
            <Loader loading={false} />
        </div>
    );
};
