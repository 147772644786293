import React, { ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface IConfirmModalProps {
  show: boolean;
  cancelButtonName?: string;
  confirmButtonName?: string;
  additionalActionButtonName?: string;
  title?: string;
  onSubmit: () => void;
  onHide: () => void;
  children?: React.ReactNode;
  onAdditionalAction?: () => void;
  additionalActionResourceId?: string;
  size?: any;
  className?: string;
  additionalFooterElement?: ReactNode;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  return (
    <Modal
      className={"overlayPopup " + props.className + " confirm-modal"}
      show={props.show}
      onHide={props.onHide}
      size={props.size || "sm"}
    >
      {props.title && (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      )}
      {props.children && <Modal.Body>{props.children}</Modal.Body>}
      <Modal.Footer className={props.additionalFooterElement ? "d-flex justify-content-between" : ""}>
        {props.additionalFooterElement &&
          <div className="additional-action">
            {props.additionalFooterElement}
          </div>
        }
        <div>
          <Button variant="outline-secondary" onClick={props.onHide}>
            {props.cancelButtonName || "Cancel"}
          </Button>
          {props.onAdditionalAction && (
            <Button
              variant="primary"
              resource-data-id={props.additionalActionResourceId}
              onClick={props.onAdditionalAction}
            >
              {props.additionalActionButtonName}
            </Button>
          )}
          <Button variant="primary" onClick={props.onSubmit}>
            {props.confirmButtonName || "Ok"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
