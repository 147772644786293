import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import React from "react";
import store from "../../../configureStore";
import { ILayoutModals } from "../../../core/viewModels/layout/HeaderModel";
import { HeaderConstants, SideMenuConstants } from "../../../helper/Constants";
import { checkIfPermissionIsAllowed, getAccessibleSideMenuItems } from "../../../helper/HelperFunctions";
import { RBACPermission, ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

export const headerConfig = (
    showModal: ILayoutModals,
    setShowModal: React.Dispatch<React.SetStateAction<ILayoutModals>>,
    headerChildren: React.ReactNode,
    navigate: any
): IHeaderProps => {
    const state = store.getState();
    const { firstName } = state.userProfile;
    const { company_name } = state.userAuth.user.profile;
    const { widgetData, suiteUrl } = state.layoutState;
    const { mailboxUserData } = state.commonState;
    const { exchangeEnabled } = state.postAuthState;

    const getProfileDropdownHeader = (): string =>
        `Hi, ${mailboxUserData ? mailboxUserData.firstName.trim() : firstName.trim()}!`;

    const handleBackClick = () => {
        window.open(suiteUrl, "_blank");
    };

    const getProfileDropdownItems = () => {
        if (exchangeEnabled) {
            if (!mailboxUserData) {
                return [
                    {
                        caption: HeaderConstants.PROFILE,
                        onClick: () => setShowModal({ ...showModal, myAccount: true }),
                    },
                    {
                        caption: HeaderConstants.MY_SETTINGS,
                        // THE BELOW FUNCTION WILL BE CHANGED DURING THE IMPLEMENTATION
                        onClick: () => setShowModal({ ...showModal, mySettings: true }),
                    },
                    {
                        caption: HeaderConstants.DROP_OFF_LINKS,
                        dataResourceId: ResourceIdLocators.Global.DropOffLinkBtn,
                        // THE BELOW FUNCTION WILL BE CHANGED DURING THE IMPLEMENTATION
                        onClick: () => setShowModal({ ...showModal, dropOff: true }),
                    },
                    {
                        caption: HeaderConstants.MY_DOWNLOADS,
                        // THE BELOW FUNCTION WILL BE CHANGED DURING THE IMPLEMENTATION
                        dataResourceId: ResourceIdLocators.Global.MyDownloadsBtn,
                        onClick: () => setShowModal({ ...showModal, myDownloads: true }),
                    },
                    {
                        caption: HeaderConstants.LOGIN_HISTORY,
                        onClick: () => setShowModal({ ...showModal, loginHistory: true }),
                    },
                ];
            } else {
                return [
                    {
                        caption: HeaderConstants.MY_DOWNLOADS,
                        // THE BELOW FUNCTION WILL BE CHANGED DURING THE IMPLEMENTATION
                        dataResourceId: ResourceIdLocators.Global.MyDownloadsBtn,
                        onClick: () => setShowModal({ ...showModal, myDownloads: true }),
                    },
                ];
            }
        } else {
            return [
                {
                    caption: HeaderConstants.PROFILE,
                    onClick: () => setShowModal({ ...showModal, myAccount: true }),
                },
            ];
        }
    };

    enum ProductType {
        None = 0,
        Returns = 1,
        SignaturesOneOff = 2,
        SignaturesMailMerge = 3,
        Organizers = 4,
        Extensions = 5,
        Exchange = 6,
        SSSuitePortal = 1001,
    }

    return {
        productName: ProductType.Exchange,
        companyName: company_name,
        children: headerChildren,
        onClickProductLogo: () => navigate("/"),

        profileDropDown: {
            dropdownHeader: getProfileDropdownHeader(),
            dropdownHeaderTitle: `${mailboxUserData ? mailboxUserData.firstName : firstName}`,
            onClickLogout: () => setShowModal(value => ({ ...value, logOut: true })),
            hideLogout: !!mailboxUserData,
            profileDropDownItems: getProfileDropdownItems(),
        },
        widgetData: {
            hideWidget: !!mailboxUserData,
            onBackClick: handleBackClick,
            dropDownData: widgetData,
        },
    };
};

export const getSideMenuData = (
    showModal: ILayoutModals,
    setShowModal: React.Dispatch<React.SetStateAction<ILayoutModals>>,
    navigate: any
): ISideMenuProps | undefined => {
    const state = store.getState();
    const { exchangeEnabled } = state.postAuthState;
    const { mailboxUserData } = state.commonState;
    const { sideMenuData } = useSelector((state: ApplicationState) => state.layoutState);
    const messageHistoryStore = useSelector(
        (state: ApplicationState) => state.adminSettingsState.messageHistorySettings
    );
    const requestHistory = useSelector((state: ApplicationState) => state.adminSettingsState.requestHistorySettings);
    const rbacResourceList = useSelector((state: ApplicationState) => state.rbacState.resourceList);
    const topMenuData: IMenuSection[] = [];
    const bottomMenuData: IMenuSection[] = [];

    sideMenuData &&
        sideMenuData.length > 1 &&
        sideMenuData.map((group: IMenuSection) => {
            if (group?.items && group.items[0].isAtBottomOfLeftMenu) {
                bottomMenuData.push(group);
            } else {
                const adminMenuIndex = group.items.findIndex(item => item.caption === SideMenuConstants.ADMIN_SETTINGS);
                const messageHistoryIndex = group.items.findIndex(
                    item => item.caption === SideMenuConstants.MESSAGE_HISTORY
                );
                if (adminMenuIndex >= 0 && group.items[adminMenuIndex].disabled) {
                    // This condition will remove Admin settings from SideMenu if permission is disabled
                    // Below variable is taken since items in group are sealed and therefore splice was not working
                    const items = [...group.items];
                    items.splice(adminMenuIndex, 1);
                    topMenuData.push({ ...group, items: [...items] });
                } else if (
                    messageHistoryIndex >= 0 &&
                    (messageHistoryStore.sentItemsSettings || messageHistoryStore.receivedItemsSettings)
                ) {
                    // This condition will remove History Items from SideMenu based on permission and Admin settings message history toggle
                    const messageHistoryItem = { ...group.items[messageHistoryIndex] };
                    const items = [...(messageHistoryItem.items ?? [])];
                    const groupItems = [...group.items];
                    messageHistoryItem.disabled = items[0].disabled && items[1].disabled;
                    if (!messageHistoryStore.sentItemsSettings?.isUserAllowedToViewReports) {
                        const sihIndex = items?.findIndex(item => item.caption === SideMenuConstants.SENT_ITEMS) ?? 0;
                        items.splice(sihIndex, 1);
                    }
                    if (
                        !messageHistoryStore.receivedItemsSettings?.isUserAllowedToViewReceivedMessageHistoricalReport
                    ) {
                        const rihIndex =
                            items?.findIndex(item => item.caption === SideMenuConstants.RECEIVED_ITEMS) ?? 0;
                        items.splice(rihIndex, 1);
                    }
                    //DR report show/hide
                    if (!requestHistory?.isUserAllowedToViewDocumentRequestHistoricalReport) {
                        const index =
                            items?.findIndex(item => item.caption === SideMenuConstants.DOCUMENT_REQUESTS) ?? 0;
                        items.splice(index, 1);
                    }
                    messageHistoryItem.items = items;
                    groupItems.splice(messageHistoryIndex, 1, messageHistoryItem);
                    items.length > 0 ? topMenuData.push({ ...group, items: groupItems }) : [];
                } else {
                    topMenuData.push(group);
                }
            }
        });

    const showNewMessageButton = checkIfPermissionIsAllowed(rbacResourceList, ResourceIdLocators.Global.NewMessageBtn);
    const sideMenuAPIResponse = {
        buttonText: SideMenuConstants.NEW_MESSAGE_BUTTON_TEXT,
        buttonIcon: SideMenuConstants.NEW_MESSAGE_BUTTON_ICON,
        hasButton: true,
        disableButton: showNewMessageButton,
        buttonTitle: showNewMessageButton ? RBACPermission.operation : SideMenuConstants.NEW_MESSAGE_BUTTON_TEXT,
        isMenuToggled: false,
        topMenuData: topMenuData,
        bottomMenuData: bottomMenuData,
        onNavigate: (route: string) => {
            navigate(route);
        },
    };
    return exchangeEnabled
        ? {
              ...sideMenuAPIResponse,
              topMenuData: mailboxUserData
                  ? getAccessibleSideMenuItems(sideMenuAPIResponse.topMenuData)
                  : sideMenuAPIResponse.topMenuData,
              hasButton: !mailboxUserData ? true : false,
              onButtonClick: () => setShowModal({ ...showModal, composeModal: true }),
              onNavigate: (route: string) => {
                  navigate(route);
              },
          }
        : undefined;
};
