import React, { useEffect, useState } from "react";
import {
    fetchRequestNotificationsSettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../../../../store";
import { RequestNotificationConstants } from "../../../../../helper/Constants";
import { intialCompanyRequestNotificationSettings } from "../../../../../core/viewModels/company/settings/NotificationsSettings";
import { CompanySettingsName } from "../../../../../core/common/enums";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";

const RequestNotification = () => {
    const [requestNotificationsSettingsState, setRequestNotificationsSettingsState] = useState(
        intialCompanyRequestNotificationSettings
    );
    const { requestNotificationSettings, isLoading } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRequestNotificationsSettings());
    }, []);

    useEffect(() => {
        if (requestNotificationSettings) {
            setRequestNotificationsSettingsState({
                ...requestNotificationsSettingsState,
                ...requestNotificationSettings,
            });
        }
    }, [requestNotificationSettings]);

    const handleRequestNotificationToggleChange = (e: any, name: string) => {
        const value = e.target.checked;

        setRequestNotificationsSettingsState(prevState => {
            // Update the state based on the previous state
            const updatedState = {
                ...prevState,
                [name]: value,
                isUserAllowedToChangeRequestCompletionNotification:
                    name === RequestNotificationConstants.IS_REQUEST_COMPLETION_NOTIFICATION_ENABLED && !value
                        ? false
                        : name ===
                          RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_COMPLETION_NOTIFICATION_ENABLED
                        ? value
                        : prevState.isUserAllowedToChangeRequestCompletionNotification,
                isUserAllowedToChangeRequestReopenNotification:
                    name === RequestNotificationConstants.IS_REQUEST_REOPEN_NOTIFICATION_ENABLED && !value
                        ? false
                        : name ===
                          RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_REOPEN_NOTIFICATION_ENABLED
                        ? value
                        : prevState.isUserAllowedToChangeRequestReopenNotification,
            };

            // Dispatch action with the updated state
            dispatch(updateAdminSettings(CompanySettingsName.RequestNotificationSettings, updatedState));

            // Return the updated state
            return updatedState;
        });
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{RequestNotificationConstants.REQUEST_NOTIFICATION_HELPER_TEXT}</span>
                </header>
                <section className="margin-top-10-px">
                    <div className="notification-option-container">
                        <section className="heading-section">
                            <h3 className="heading-black-md text-bold">
                                {RequestNotificationConstants.REQUEST_COMPLETIONS}
                            </h3>
                            <span className="notification-helper-text">
                                {RequestNotificationConstants.REQUEST_NOTIFICATION_COMPLETION_HELPER_TEXT}
                            </span>
                        </section>
                        <div className="settings-label-toggle-wrapper toggle-wrapper">
                            <CustomToggleSwitch
                                label={
                                    RequestNotificationConstants.REQUEST_NOTIFICATION_NOTIFY_CPA_WHEN_REQUEST_IS_COMPLETE
                                }
                                handleChange={handleRequestNotificationToggleChange}
                                name={RequestNotificationConstants.IS_REQUEST_COMPLETION_NOTIFICATION_ENABLED}
                                switched={requestNotificationsSettingsState.isRequestCompletionNotificationEnabled}
                            />
                        </div>
                        {requestNotificationsSettingsState.isRequestCompletionNotificationEnabled && (
                            <div className="settings-label-toggle-wrapper sub-list-settings-item">
                                <Checkbox
                                    id={"checkbox-manage-expiry-notification"}
                                    value={
                                        RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_COMPLETION_NOTIFICATION_ENABLED
                                    }
                                    className="primary-checkbox"
                                    text={
                                        RequestNotificationConstants.REQUEST_NOTIFICATION_ALLOW_EMPLOYEES_TO_UPDATE_COMPLETION_NOTIFICATION
                                    }
                                    size={CheckBoxSize.sm}
                                    checked={
                                        requestNotificationsSettingsState.isUserAllowedToChangeRequestCompletionNotification
                                    }
                                    onChange={e =>
                                        handleRequestNotificationToggleChange(
                                            e,
                                            RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_COMPLETION_NOTIFICATION_ENABLED
                                        )
                                    }
                                />
                            </div>
                        )}
                        {/* Re open Request notification settings */}
                        <section className="margin-top-10-px">
                            <h3 className="heading-black-md text-bold">
                                {RequestNotificationConstants.REQUEST_REOPEN}
                            </h3>
                            <span className="notification-helper-text">
                                {RequestNotificationConstants.REQUEST_NOTIFICATION_REOPEN_HELPER_TEXT}
                            </span>
                        </section>
                        <div className="settings-label-toggle-wrapper toggle-wrapper ">
                            <CustomToggleSwitch
                                label={
                                    RequestNotificationConstants.REQUEST_NOTIFICATION_NOTIFY_TP_WHEN_REQUEST_IS_REOPEN
                                }
                                handleChange={handleRequestNotificationToggleChange}
                                name={RequestNotificationConstants.IS_REQUEST_REOPEN_NOTIFICATION_ENABLED}
                                switched={requestNotificationsSettingsState.isRequestReopenNotificationEnabled}
                            />
                        </div>
                        {requestNotificationsSettingsState.isRequestReopenNotificationEnabled && (
                            <div className="settings-label-toggle-wrapper  sub-list-settings-item">
                                <Checkbox
                                    id={"checkbox-manage-download-notification"}
                                    value={
                                        RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_REOPEN_NOTIFICATION_ENABLED
                                    }
                                    className="primary-checkbox"
                                    text={
                                        RequestNotificationConstants.REQUEST_NOTIFICATION_ALLOW_EMPLOYEES_TO_UPDATE_REOPEN_NOTIFICATION
                                    }
                                    size={CheckBoxSize.sm}
                                    checked={
                                        requestNotificationsSettingsState.isUserAllowedToChangeRequestReopenNotification
                                    }
                                    onChange={e =>
                                        handleRequestNotificationToggleChange(
                                            e,
                                            RequestNotificationConstants.IS_USER_ALLOWED_TO_CHANGE_REQUEST_REOPEN_NOTIFICATION_ENABLED
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                </section>
            </>
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default RequestNotification;
