import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserSettings, updateUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import { IUserSettingsModel } from "../../../core/viewModels/user/UserViewModel";
import { userSettingsConstant } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { actionTypes } from "../../../types/userSettingsTypes";
import UserSettingLayout from "./UserSettingsLayout";
import "./styles.scss";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

const MySettings: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const dispatch = useDispatch();
    const { userSettings } = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const [userSettingsCopy, setUserSettingsCopy] = useState<IUserSettingsModel>();

    useEffect(() => {
        if (show) {
            dispatch(
                fetchUserSettings(data => {
                    setUserSettingsCopy(data);
                })
            );
            logger.trackPageView(appInsightsData.MySettings.AuthQuestions.PageTitle);
        }
    }, [show]);

    const handleApplySettings = () => {
        if (userSettings) {
            dispatch(updateUserSettings(userSettings));
            onHide();
        }
    };

    const handleCancel = () => {
        dispatch({
            type: actionTypes.UPDATE_USER_SETTINGS,
            payload: userSettingsCopy,
        });
        onHide();
    };

    return (
        <Modal size="xl" show={show} className="user-settings-modal" onHide={handleCancel}>
            <Modal.Header closeButton closeVariant="white">
                {userSettingsConstant.TITLE}
            </Modal.Header>
            <Modal.Body className="padding-0">
                <UserSettingLayout />
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-light"
                    onClick={handleCancel}
                    data-auto-test={AutomationIdConstants.deleteBtnModal.CancelBtn}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleApplySettings}
                    data-auto-test={AutomationIdConstants.deleteBtnModal.ConfirmBtn}
                >
                    Apply
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MySettings;
