import { QuestionType } from "../../common/enums";

export interface IMessageSecurityQuestion {
  messageSecurityQuestionId: number;
  userId: number;
  companyId: number;
  question: string;
  questionTitle: string;
  questionType: QuestionType;
  isDefault: boolean;
}
export interface IMessageSecuritySettings {
  defaultSecurityQuestionId: number
  isUserAllowedToAddSecurityQuestion: boolean
  isUserAllowedToChangeDefaultSecurityQuestion: boolean
}

export const initialMessageSecuritySettings = {
  defaultSecurityQuestionId: 1,
  isUserAllowedToAddSecurityQuestion: true,
  isUserAllowedToChangeDefaultSecurityQuestion: true
};

export interface ISecurityQuestionAnswer {
  securityQuestionId: number;
  recipientEmail: string;
  answer: string;
  question: string;
}
