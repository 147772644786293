import React from "react";

export enum CheckBoxSize {
  sm = 0,
  lg = 1,
}

interface ICheckboxProps {
  checked?: boolean;
  onChange?: (event: any) => void;
  text?: string;
  datatestAuto?: string;
  disabled?: boolean;
  value?: string | string[] | number;
  onClick?: (event: any) => void;
  cancelledCheckBox?: string;
  id: string;
  className?: string;
  size?: CheckBoxSize;
  indeterminate?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = (props) => {
  let checkBoxClass = "magic-checkbox";

  switch (props.size) {
    case CheckBoxSize.lg:
      checkBoxClass = props.indeterminate
        ? "magic-checkbox magic-checkbox-indeterminate"
        : "magic-checkbox";
      break;

    case CheckBoxSize.sm:
      checkBoxClass = props.indeterminate
        ? "magic-checkbox-sm magic-checkbox-sm-indeterminate"
        : "magic-checkbox-sm";
      break;
  }

  return (
    <div className={props.className}>
      <div className="display-checkBox">
        <input
          className={checkBoxClass}
          type="checkbox"
          id={props.id}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          data-test-auto={props.datatestAuto}
          disabled={props.disabled}
          onClick={props.onClick}
        ></input>
        <label data-test-auto={props?.cancelledCheckBox}htmlFor={props.id} className={props.checked ? "checked" : ""}>
          <span>{props.text}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
