import produce from "immer";
import { Action, Reducer } from "redux";
import { DispatchActions } from "../actionCreators/postAuthActionCreators";
import { actionTypes } from "../types/postAuthTypes";

export interface IPostAuthState {
    postAuthLoading: boolean;
    exchangeEnabled: boolean;
    productEnabled: boolean;
    productSubscribed: boolean;
    isProductSubscribedLoading: boolean;
    exchangeEnabledLoading: boolean
    isPageAccessDenied: boolean;
    isPageAccessDeniedLoading: boolean;
}

const initialState: IPostAuthState = {
    postAuthLoading: false,
    exchangeEnabled: false,
    productEnabled: false,
    productSubscribed: false,
    isProductSubscribedLoading: false,
    exchangeEnabledLoading: true,
    isPageAccessDenied: false,
    isPageAccessDeniedLoading: true,
};

export const reducer: Reducer<IPostAuthState> = produce(
    (draftState: IPostAuthState = initialState,
        incomingAction: Action
    ) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_PRODUCT_ENABLED:
                draftState.postAuthLoading = true;
                break;
            case actionTypes.RECEIVE_PRODUCT_ENABLED:
                draftState.productEnabled = action.payload;
                break;
            case actionTypes.COMPLETE_PRODUCT_ENABLED:
                draftState.postAuthLoading = false;
                break;
            case actionTypes.REQUEST_PRODUCT_SUBSCRIBED:
                draftState.isProductSubscribedLoading = true;
                break;
            case actionTypes.RECEIVE_PRODUCT_SUBSCRIBED:
                draftState.productSubscribed = action.payload;
                break;
            case actionTypes.COMPLETE_PRODUCT_SUBSCRIBED:
                draftState.isProductSubscribedLoading = false;
                break;
            case actionTypes.SET_EXCHANGE_ENABLE_STATUS:
                draftState.exchangeEnabled = action.payload;
                draftState.exchangeEnabledLoading = false;
                break;
            case actionTypes.SET_PAGE_LEVEL_ACCESS:
                draftState.isPageAccessDenied = action.payload;
                draftState.isPageAccessDeniedLoading = false;
                break;
            default:
                return draftState;
        }
        return draftState;
    }
);