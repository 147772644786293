import axios from "axios";
import { LocalStorageConstants } from "../helper/Constants";
import { AppThunkAction } from "../store";
import {
  STORE_USER,
  USER_SIGNED_OUT,
  USER_EXPIRED,
  STORE_USER_ERROR,
  LOADING_USER,
  RECEIVED_SESSION_IDLE_MINUTES,
} from "../types/authTypes";
import { RequestSource } from "../core/common/enums";
import { actionTypes } from "../types/postAuthTypes";
import { renderTokenElement } from "../oidcClient/helpers";

export function storeUser(user: any) {
    localStorage.setItem(LocalStorageConstants.UserIdKey, user.profile.user_id);
    renderTokenElement(user.access_token);
  return {
    type: STORE_USER,
    payload: user,
  };
}
export const postAuthLoading = ()=>
{
return {
    type: actionTypes.REQUEST_PRODUCT_SUBSCRIBED,
  };
};

export function loadingUser() {
  return {
    type: LOADING_USER,
  };
}

export function storeUserError() {
  return {
    type: STORE_USER_ERROR,
  };
}

export function userExpired() {
  return {
    type: USER_EXPIRED,
  };
}

export function userSignedOut() {
  return {
    type: USER_SIGNED_OUT,
  };
}

export const requestSessionIdleMinutes =
  (): AppThunkAction<any> => (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/KeyVault/GetSessionTimeOutMinutes?requestSource=${RequestSource.Portal}`
      )
      .then((response: any) => {
        const data = response.data;
        dispatch({
          type: RECEIVED_SESSION_IDLE_MINUTES,
          payload: data,
        });
      });
  };

  export const updateLogoutDate = async () => {
      await axios.put(`${process.env.REACT_APP_API_URL_SUITE}/api/login-history/update-logout-date`).catch(error => {
          //should or will be removed when there is logger implementation
          console.log(`updateLogoutDate API failed with error ${error}`);
      });
  };
  