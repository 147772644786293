export enum actionTypes {
    REQUEST_HELP_URL = "request_help_url",
    RECEIVE_HELP_URL = "receive_help_url",
    COMPLETE_HELP_URL = "complete_help_url",
    REQUEST_PRODUCT_URLS = "request_product_urls",
    RECEIVE_PRODUCT_URLS = "receive_product_urls",
    COMPLETE_PRODUCT_URLS = "complete_product_urls",
    REQUEST_SIDE_MENU_DATA = "request_side_menu_data",
    RECEIVE_SIDE_MENU_DATA = "receive_side_menu_data",
    COMPLETE_SIDE_MENU_DATA = "complete_side_menu_data",
    RECEIVE_SUITE_URL = "receive_suite_url"
}
