import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchSharedFolderMessageFileViewData, requestDownloadLater, requestDownloadNow } from "../../../actionCreators/folderActionCreator";
import { IMessageFileViewModel } from "../../../core/viewModels/message/MessageFileViewModel";
import { MyDownloadConstants, MyDownloadType, initialPagination } from "../../../helper/Constants";
import { getMessagePayload } from "../../../helper/HelperFunctions";
import { DownloadLaterIcon, DownloadNowIcon } from "../../svg/IconCollection";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";

interface IDownloadModalProps {
    show: boolean;
    onHide: () => void;
    selectedFiles: IMessageFileViewModel[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IMessageFileViewModel[]>>;
    folder: IFolderModel;
    resourceId?:string
}

const DownloadModal: FC<IDownloadModalProps> = ({
    show,
    onHide,
    selectedFiles,
    setSelectedFiles,
    folder: { folderId,folderName },
    resourceId
}) => {
    const [selectedType, setSelectedType] = useState<number>(0);
    const dispatch = useDispatch();

    const downloadCallback=()=>{
        dispatch(
            fetchSharedFolderMessageFileViewData(
                folderId,
                initialPagination.currentPage,
                initialPagination.pageSize,
                "",
                ""
            ));
            setSelectedFiles([]); 
    };
    const handleOk: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (selectedType === MyDownloadType.DOWNLOAD_NOW) {
            dispatch(requestDownloadNow(getMessagePayload(selectedFiles), () => downloadCallback(),resourceId));
                       handleOnHide();
        } else if (selectedType === MyDownloadType.DOWNLOAD_LATER) {
            dispatch(
                requestDownloadLater(getMessagePayload(selectedFiles), folderName, () => downloadCallback())
            );
            handleOnHide();
        }
    };
    const handleOnHide = () => {
        onHide();
        setSelectedType(0);
    };
    const handleTypeSelection = (type: number) => {
        setSelectedType(type);
    };
    return (
        <Modal size="sm" show={show} onHide={handleOnHide} dialogClassName="custom-bootstrap-modal my-downloads">
            <Modal.Header closeButton closeVariant="white">
                {MyDownloadConstants.MODAL_TITLE}
            </Modal.Header>
            <Modal.Body>
                <div className="description">
                    <i className="fas fa-info-circle margin-right-5-px" />
                    <span>{MyDownloadConstants.INFO_TEXT}</span>
                </div>
                <div className="download-type-container">
                    <div
                        className={`download-card ${selectedType === MyDownloadType.DOWNLOAD_NOW ? "selected" : ""}`}
                        onClick={() => handleTypeSelection(MyDownloadType.DOWNLOAD_NOW)}
                    >
                        <div className="title">{MyDownloadConstants.DOWNLOAD_NOW}</div>
                        <DownloadNowIcon height={60} width={60} />
                        <div className="download-description">{MyDownloadConstants.DOWNLOAD_NOW_DESCRIPTION}</div>
                    </div>
                    <div
                        className={`download-card ${selectedType === MyDownloadType.DOWNLOAD_LATER ? "selected" : ""}`}
                        onClick={() => handleTypeSelection(MyDownloadType.DOWNLOAD_LATER)}
                    >
                        <div className="title">{MyDownloadConstants.DOWNLOAD_LATER}</div>
                        <DownloadLaterIcon height={60} width={60} />
                        <div className="download-description">{MyDownloadConstants.DOWNLOAD_LATER_DESCRIPTION}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={onHide}
                    data-auto-test={AutomationIdConstants.deleteBtnModal.CancelBtn}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleOk}
                    disabled={!selectedType}
                    data-auto-test={AutomationIdConstants.deleteBtnModal.ConfirmBtn}
                >
                    Ok
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DownloadModal;
