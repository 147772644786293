export const ColorConstants = {
    grey: "#737373 ",
    grey_600:"#565A5E",
    white: "#ffffff",
    black:"#000000",
    dark_grey: "#606060",
    light_blue: "#337ab7",
    sidemenu_green: "#85A966",
    sidemenu_light_grey: "#00000080",
    brand_pimary_blue_1: "#05386b",
    brand_pimary_blue_2: "#0973ba",
    icon_hover_color:"#737373",
    orange_500:"#d36d13",
    header_blue:"#011120",
    button_black:"#rgba(0, 0, 0, 0.68)",
    grey_disabled:"#939393",
    grey_placeholder: "#898d91",
};  