import { IMetricsCardModel } from "../core/viewModels/report/reportViewModel";
import * as documentRequestHistory from "../actionCreators/documentRequestHistoryActionCreator";
import produce from "immer";
import { actionTypes } from "../types/documentRequestHistoryType";
import { IDocumentRequestHistoryModel } from "../core/viewModels/report/documentRequestHistoryModel";
import { Action, Reducer } from "redux";

export interface IDocumentRequestHistoryStore {
    isLoading: boolean;
    data: IDocumentRequestHistoryModel[];
    metricsData: IMetricsCardModel[];
}

const initialState: IDocumentRequestHistoryStore = {
    isLoading: false,
    metricsData: [],
    data: [],
};

export const reducer: Reducer<IDocumentRequestHistoryStore> = produce(
    (state: IDocumentRequestHistoryStore = initialState, incomingAction: Action) => {
        const action = incomingAction as documentRequestHistory.KnownActions;
        switch (action.type) {
            case actionTypes.REQUEST_DOCUMENT_REQUEST_HISTORY:
                return { ...state, isLoading: true };
            case actionTypes.RECEIVE_DOCUMENT_REQUEST_HISTORY:
                return { ...state, isLoading: false, data: action.payload };
            case actionTypes.COMPLETED_DOCUMENT_REQUEST_HISTORY:
                return { ...state, isLoading: false };
            case actionTypes.REQUEST_DOCUMENT_REQUEST_HISTORY_METRICS:
                return { ...state, isLoading: true };
            case actionTypes.RECEIVE_DOCUMENT_REQUEST_HISTORY_METRICS:
                return { ...state, metricsData: action.payload, isLoading: false };
            case actionTypes.COMPLETED_DOCUMENT_REQUEST_HISTORY_METRICS:
                return { ...state, isLoading: false };
            default:
                return state;
        }
    }
);
