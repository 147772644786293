import React, { FC, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { ColorConstants } from "../../../assets/custom/colors";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { userSettingsTitle } from "../../../helper/Constants";
import { logger } from "../../../oidcClient/authProvider";
import { AdminSettingsSideMenuIcon, MenuIcon } from "../../svg/AdminSettingsIconsCollection";
import UserSettingsContent from "./UserSettingsContent";

const iconKey: { [key: string]: MenuIcon } = {
    authentication_questions: "AuthQuestions",
    message_options: "MessageOptions",
    mailbox_delegation: "MailboxDelegation",
    notifications: "Notification",
};

const UserSettingLayout: FC = () => {
    const menuKeys = Object.keys(userSettingsTitle),
        [selectedTab, setSelecteTab] = useState<string>(menuKeys[0]);
    const handleTabSelect = (key: string) => {
        setSelecteTab(key);
        if (key === iconKey.authentication_questions) {
            logger.trackPageView(appInsightsData.MySettings.AuthQuestions.PageTitle);
        } else if (key === iconKey.message_options) {
            logger.trackPageView(appInsightsData.MySettings.MessageOption.PageTitle);
        } else if (key === iconKey.mailbox_delegation) {
            logger.trackPageView(appInsightsData.MySettings.MessageDelegation.PageTitle);
        }
    };
    return (
        <Tab.Container
            defaultActiveKey={menuKeys[0]}
            onSelect={key => key && handleTabSelect(key)}
            activeKey={selectedTab}
        >
            <div className="settings-wrapper my-settings-wrapper">
                <nav>
                    <Nav className="flex-column">
                        {menuKeys.map((data: string, index: number) => (
                            <Nav.Link
                                key={`${data}-${index}`}
                                className={"settings-menu-item nav-link"}
                                eventKey={data}
                            >
                                <span className={"me-3"}>
                                    <AdminSettingsSideMenuIcon
                                        color={
                                            selectedTab === data
                                                ? ColorConstants.sidemenu_green
                                                : ColorConstants.sidemenu_light_grey
                                        }
                                        width={22}
                                        height={22}
                                        icon={iconKey[data]}
                                    />
                                </span>
                                {userSettingsTitle[data]}
                            </Nav.Link>
                        ))}
                    </Nav>
                </nav>
                <main>
                    <Tab.Content>
                        {menuKeys.map((data: string, index: number) => (
                            <Tab.Pane eventKey={data} key={`${data}-${index}`}>
                                <UserSettingsContent eventKey={data} />
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </main>
            </div>
        </Tab.Container>
    );
};

export default UserSettingLayout;
