import { Action, Reducer } from "redux";
import { produce } from "immer";
import { actionTypes } from "../types/layoutTypes";
import { DispatchActions, IWidgetData } from "../actions/layoutActions";
import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";

export interface ILayoutState {
    isLoading: boolean;
    helpUrl: string;
    suiteUrl: string;
    widgetData: IWidgetData[];
    sideMenuData: IMenuSection[];
}

export const initialState: ILayoutState = {
    isLoading: false,
    helpUrl: "",
    suiteUrl: "",
    widgetData: [],
    sideMenuData: []
};

export const reducer: Reducer<ILayoutState> = produce(
    (draftState: ILayoutState = initialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_PRODUCT_URLS:
                draftState.isLoading = true;
                return draftState;
            case actionTypes.RECEIVE_PRODUCT_URLS:
                draftState.isLoading = false;
                draftState.widgetData = action.payload;
                return draftState;
            case actionTypes.COMPLETE_PRODUCT_URLS:
                draftState.isLoading = false;
                return draftState;
            case actionTypes.RECEIVE_HELP_URL:
                draftState.helpUrl = action.payload;
                return draftState;
            case actionTypes.RECEIVE_SIDE_MENU_DATA:
                draftState.sideMenuData = action.payload;
                return draftState;
            case actionTypes.RECEIVE_SUITE_URL:
                draftState.suiteUrl = action.url;
                return draftState;
        }
        return draftState;
    }
);
