import produce from "immer";
import { Reducer, Action } from "redux";
import { DispatchActions } from "../actionCreators/documentRequestActionCreators";
import {
    IDocumentRequestListResponseModel,
    IDocumentRequestModel,
    initialDocumentState,
} from "../core/viewModels/documentRequest/DocumentRequestModel";
import { actionTypes as documentRequestActions } from "../types/documentRequestTypes";

export interface IDocumentRequestState {
    isLoading: boolean;
    isComposeLoading: boolean;
    documentRequestList: IDocumentRequestListResponseModel;
    documentRequestDetailed: {
        isLoading: boolean;
        data: IDocumentRequestModel;
    };   
}

export const initialState: IDocumentRequestState = {
    isLoading: false,
    isComposeLoading: false,
    documentRequestList: {
        totalRecords: 0,
        documentRequests: [],
    },
    documentRequestDetailed: {
        isLoading: false,
        data: initialDocumentState,
    },
};

export const reducer: Reducer<IDocumentRequestState> = produce(
    (draft: IDocumentRequestState = initialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case documentRequestActions.START_LISTING_LOADER:
                draft.isLoading = true;
                return draft;
            case documentRequestActions.RECEIVED_DOCUMENT_REQUEST_LIST:
                draft.documentRequestList = action.payload;
                return draft;
            case documentRequestActions.STOP_LISTING_LOADER:
                draft.isLoading = false;
                return draft;
            case documentRequestActions.START_DETAILED_LOADER:
                draft.documentRequestDetailed.isLoading = true;
                return draft;
            case documentRequestActions.RECEIVED_DOCUMENT_REQUEST_DETAIL:
                draft.documentRequestDetailed.data = action.payload;
                return draft;
            case documentRequestActions.STOP_DETAILED_LOADER:
                draft.documentRequestDetailed.isLoading = false;
                return draft;
            case documentRequestActions.START_COMPOSE_LOADER:
                draft.isComposeLoading = true;
                return draft;
            case documentRequestActions.STOP_COMPOSE_LOADER:
                draft.isComposeLoading = false;
                return draft;
            case documentRequestActions.COMPLETED_RETENTION_PERIODS:
                draft.documentRequestDetailed.isLoading = false;
                if (action.result && draft.documentRequestDetailed.data.documentRequestId == action.documentRequestId) {
                    draft.documentRequestDetailed.data.expiryDays = action.expiryDays;
                }
                return draft;        
            default:
                return draft;
        }
    }
);
